// @flow

import React from "react";
import { colorsBCV } from "../../../../styling";
import RaisedButton from "material-ui/RaisedButton";
import { errorHandler, ErrorHandler } from "../../../../containers";
import { colors } from "../../../../styling";
import { serializePostalCode } from "../../../../utils";
import { UPDATE_ADDRESS_MUTATION } from "../../../../components/address/updateAddressDialog";
import { gql } from "@apollo/client";
import {
  UpdateAddressMutation,
  UpdateAddressMutationVariables,
} from "generated/graphql";
import { graphql } from "@apollo/client/react/hoc";
import { DomesticAddressInput } from "components/address/domesticAddressInput";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
};

type PropsGraphQL = {
  updateAddress: (
    input: UpdateAddressMutationVariables["input"]
  ) => Promise<any>;
};

type PropsParent = {
  handleClose: () => void;
  data: {
    insuredPerson: {
      id: string;
    };
  };
};

type Props = PropsGraphQL & PropsParent & ErrorHandler;

type State = {
  state:
    | {
        completed: true;
        postalCode: string;
        houseNumber: number;
        suffix: string | null;
        country: string;
        street: string;
        city: string;
      }
    | {
        completed: false;
      };
};

class AddressDialog extends React.Component {
  state: State;
  props: Props;

  constructor(props: Props) {
    super(props);
    this.state = {
      state: {
        completed: false,
      },
    };
  }

  _close() {
    this.setState({
      state: {
        completed: false,
      },
    });
    this.props.handleClose();
  }

  render() {
    const { error, updateAddress } = this.props;
    const insuredPersonId = this.props.data.insuredPerson.id;
    const { state } = this.state;

    const disabled = state.completed === false;
    return (
      <div style={styles.container}>
        <h2>Wijzig het adres</h2>
        <DomesticAddressInput
          color={colorsBCV.secondary}
          onCompleted={(address) =>
            this.setState({
              state: {
                completed: true,
                ...address,
              },
            })
          }
          onIncomplete={() =>
            this.setState({
              state: {
                completed: false,
              },
            })
          }
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "30px",
          }}
        >
          <RaisedButton
            label="Annuleer"
            primary={false}
            onClick={() => {
              this._close();
            }}
          />
          <RaisedButton
            label="Bevestig"
            backgroundColor={colorsBCV.secondary}
            labelColor={colors.white}
            disabled={disabled}
            onClick={async () => {
              if (state.completed) {
                const {
                  city,
                  street,
                  postalCode,
                  houseNumber,
                  suffix,
                  country,
                } = state;
                try {
                  await updateAddress({
                    postalCode: serializePostalCode(postalCode),
                    houseNumber,
                    suffix,
                    city,
                    street,
                    country,
                    insuredPersonIds: [insuredPersonId],
                  });
                  this._close();
                } catch (e) {
                  error.display();
                }
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export const AddressFragment = gql`
  fragment AddressDialog on Policy {
    insuredPerson {
      id
    }
  }
`;

const addressMutation = graphql<
  PropsParent,
  UpdateAddressMutation,
  UpdateAddressMutationVariables,
  PropsGraphQL
>(UPDATE_ADDRESS_MUTATION, {
  props: ({ mutate }) => ({
    updateAddress: (input) => mutate({ variables: { input } }),
  }),
});

const Wrapper = addressMutation(errorHandler(AddressDialog));

export default Wrapper;
