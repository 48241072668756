//@flow

import React from "react";
import { colors, colorsBCV } from "./../../../styling";
import Paper from "material-ui/Paper";
import { parseAmount, formatPrice } from "../../../utils";
import { Frequency } from "generated/graphql";
import { formatFrequency2 } from "utils/formatter";

const styles: { [key: string]: React.CSSProperties } = {
  paperElement: {
    width: "100%",
    textAlign: "left",
    padding: "25px",
    fontWeight: 300,
    fontSize: "16px",
  },
  titleContainer: {
    display: "flex",
    paddingTop: "12px",
    margin: "10px",
    textAlign: "left",
    fontSize: "30px",
    color: colorsBCV.secondary,
  },
};

type Props = {
  policyData: {
    birthDate: string;
    premium: number;
    lumpsum: number;
    frequency: Frequency;
    packageName: string;
    insuredAmount: number;
    firstName: string;
    lastNamePrefix: string;
    lastName: string;
    initials: string;
    gender: "MALE" | "FEMALE";
    postalCode: string;
    houseNumber: number;
    houseNumberSuffix: string | null;
    city: string;
    street: string;
    iban: string;
    accountName: string;
  };
};

const createTitleRow = (key: string, value: string) => {
  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          flex: 1,
          paddingLeft: 8,
          paddingRight: 10,
          color: colors.lightGrey,
        }}
      >
        {key}
      </div>
      <div
        style={{
          flex: 1,
        }}
      >
        {value}
      </div>
    </div>
  );
};

const SignUpCardPolicy = (props: Props) => {
  const { policyData } = props;

  const { firstName, lastNamePrefix, lastName } = policyData;

  const name = `${firstName} ${lastNamePrefix} ${lastName}`;
  return (
    <Paper style={styles.paperElement} zDepth={1}>
      <h2 style={{ color: colorsBCV.secondary }}>Gegevens aankomend lid</h2>
      {createTitleRow("Naam", name)}
      {createTitleRow("Geboortedatum", policyData.birthDate)}
      <br />
      {createTitleRow("Straat", policyData.street)}
      {createTitleRow(
        "Huisnummer",
        String(policyData.houseNumber) +
          " " +
          (policyData.houseNumberSuffix ? policyData.houseNumberSuffix : "")
      )}
      {createTitleRow("Woonplaats", policyData.city)}
      {createTitleRow("Postcode", policyData.postalCode)}
      <br />
      {createTitleRow(
        "Contributie",
        formatPrice(parseAmount(policyData.premium))
      )}
      {createTitleRow("Koopsom", formatPrice(parseAmount(policyData.lumpsum)))}
      {createTitleRow(
        "Pakketwaarde",
        formatPrice(parseAmount(policyData.insuredAmount))
      )}
      <br />
      {createTitleRow("Betaalperiode", formatFrequency2(policyData.frequency))}
      {createTitleRow("Rekeningnummer", policyData.iban)}
      {createTitleRow("Naam rekeninghouder", policyData.accountName)}
    </Paper>
  );
};

export default SignUpCardPolicy;
