import MenuItem from "material-ui/MenuItem";
import { FormControl, InputLabel, Select } from "@mui/material";

export enum SearchType {
  NO_FILTER = "NO_FILTER",
  POSTAL_CODE = "POSTAL_CODE",
  MEMBER_ID = "MEMBER_ID",
  ADDRESS = "ADDRESS",
}

type Props = {
  selectedFilter: SearchType;
  onChangeFilter: (selectedFilter: SearchType) => void;
};

const SearchTypeSelector = (props: Props) => {
  return (
    <FormControl variant="standard" sx={{ width: "256px" }}>
      <InputLabel>Zoeken op</InputLabel>
      <Select
        value={props.selectedFilter}
        onChange={(event) =>
          props.onChangeFilter(event.target.value as SearchType)
        }
      >
        <MenuItem value={SearchType.NO_FILTER}>Alles</MenuItem>
        <MenuItem value={SearchType.POSTAL_CODE}>Alleen postcode</MenuItem>
        <MenuItem value={SearchType.ADDRESS}>Alleen adres</MenuItem>
        <MenuItem value={SearchType.MEMBER_ID}>Alleen lidnummer</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SearchTypeSelector;
