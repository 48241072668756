import React from "react";
import ExpandablePage from "../../components/expandablePageSection";

const OpzeggenLidmaatschapBCB = () => (
  <ExpandablePage title="Opzeggen lidmaatschap">
    <p>
      Hoewel de wet van Dam niet van toepassing is op een vereniging (zoals de
      onze) willen wij desondanks opzeggen van het lidmaatschap niet moeilijker
      maken dan strikt noodzakelijk. Opzeggen kan conform artikel 5 van onze
      statuten per einde van het kalenderjaar, waarbij de opzegging moet zijn
      ontvangen voor 30 november van het lopende jaar.
    </p>
  </ExpandablePage>
);

export default OpzeggenLidmaatschapBCB;
