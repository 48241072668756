import { useState } from "react";
import { colors } from "../../../../styling";
import RaisedButton from "material-ui/RaisedButton";
import SectionTitle from "../components/sectionTitle";
import { getCountryNameFromCode } from "../../../../utils";
import { Row } from "../components/row";
import TitleContainer from "../components/titleContainer";
import { FragmentType, gql, useFragment } from "generated";
import { AddressDetailsFragment } from "generated/graphql";
import { UpdateAddressDialog } from "components/address/updateAddressDialog";

export type AddressDetailsType = {
  id: string;
  postalCode: string;
  houseNumber: number;
  suffix: string;
  street: string;
  city: string;
  countryCode: string;
};

type Props = {
  data: FragmentType<typeof addressDetailsFragment>;
  disabled: boolean;
};

const showSuffix = (suffix: string | null) => suffix != null && suffix !== "";

const parseCountryCode = (countryCode: string) => {
  const countryName = getCountryNameFromCode(countryCode);
  return countryName ? countryName : "Onbekend";
};

const AddressDetails = (props: Props) => {
  const { disabled } = props;
  const [show, setShow] = useState(false);
  const close = () => setShow(false);
  const data = useFragment<AddressDetailsFragment>(
    addressDetailsFragment,
    props.data
  );

  const { postalCode, houseNumber, suffix, street, city, countryCode } =
    data.address;

  const title = (
    <TitleContainer>
      <SectionTitle title="Adres" />
      <div>
        <RaisedButton
          label="WIJZIG"
          backgroundColor={colors.green}
          labelStyle={{ color: "white" }}
          onClick={() => setShow(true)}
          disabled={disabled}
        />
      </div>
    </TitleContainer>
  );

  return (
    <div>
      <div>
        {title}
        <Row name="Postcode" value={postalCode} />
        <Row name="Huisnummer" value={String(houseNumber)} />
        {showSuffix(suffix) && (
          // @ts-ignore
          <Row id="suffix" name="Toevoeging" value={suffix} />
        )}
        <Row name="Straatnaam" value={street} />
        <Row name="Plaats" value={city} />
        {/* @ts-ignore */}
        <Row id="country" name="Land" value={parseCountryCode(countryCode)} />
      </div>
      <UpdateAddressDialog
        open={show}
        handleClose={close}
        insuredPersonIds={[data.id]}
      />
    </div>
  );
};

export const addressDetailsFragment = gql(`
  fragment AddressDetails on InsuredPerson {
    id
    address {
      id
      postalCode
      houseNumber
      suffix
      street
      city
      countryCode
    }
  }
`);

export default AddressDetails;
