// @flow

import React from "react";

import { KeyValueRow as Row } from "../../components/KeyValue";
import SectionTitle from "../../components/sectionTitle";
import { formatToEuro, parseDate } from "../../../../../utils";
import RowSeparator from "../../components/rowSeparator";
import { formatFrequency2 } from "utils/formatter";
import { Frequency } from "generated/graphql";

type AdditionalInsuranceType = {
  insuredAmount: string;
  premium: string;
  startDate: string;
  endDate: string;
  frequency: Frequency;
};

export const AdditionalInsuranceInfo = ({
  data,
}: {
  data: AdditionalInsuranceType;
}) => {
  return (
    <div>
      <SectionTitle>Additionele verzekering</SectionTitle>
      <Row name="Verzekerd bedrag" value={formatToEuro(data.insuredAmount)} />
      <RowSeparator />
      <Row
        name="Premie"
        value={data.premium ? formatToEuro(data.premium) : "Geen"}
      />
      <RowSeparator />
      <Row
        name="Betalingsfrequentie"
        value={data.frequency ? formatFrequency2(data.frequency) : "Onbekend"}
      />
      <RowSeparator />
      <Row
        name="Ingangsdatum"
        value={data.startDate ? parseDate(data.startDate) : "Onbekend"}
      />
      <RowSeparator />
      {data.endDate && (
        <Row
          name="Einddatum"
          value={data.endDate ? parseDate(data.endDate) : "Niet gezet"}
        />
      )}
    </div>
  );
};
