import React from "react";
import { filter } from "graphql-anywhere";
import RaisedButton from "material-ui/RaisedButton";

import { Title } from "../../../../../components/dialog";
import PersonalInfo, { memberDetailsFragment } from "./personal";
import AddressInfo from "./address";
import PaymentInfo, { paymentDetailsFragment } from "./payment";
import PackageDetails, { packageDetailsFragment } from "./package";
import LapseMembership from "./lapseMembership";
import TransferMembership from "./transferMembership";
import { AdditionalInsuranceInfo } from "./additionalInsurance";
import RowSeparator from "../../components/rowSeparator";
import { gql } from "@apollo/client";

type Props = {
  data: any;
  addressComponent: () => void;
  ibanComponent: () => void;
  lapseComponent: () => void;
  onTransferMembership: () => void;
  onEditPackage: () => void;
  handleClose: () => void;
};

class DetailsComponent extends React.Component {
  props: Props;

  render() {
    const { data, lapseComponent, onEditPackage, onTransferMembership } =
      this.props;
    return (
      <div data-test="policy_details_dialog">
        <Title>Details Lidmaatschap</Title>
        <PersonalInfo data={filter(memberDetailsFragment, data)} />
        <PackageDetails
          data={filter(packageDetailsFragment, data)}
          onTapPackage={onEditPackage}
        />
        {data.additionalInsurance && (
          <AdditionalInsuranceInfo data={data.additionalInsurance} />
        )}
        <AddressInfo
          data={filter(AddressInfo.fragments.addressInfo, data)}
          addressComponent={this.props.addressComponent}
        />
        <PaymentInfo
          data={filter(paymentDetailsFragment, data)}
          onTapEditPayment={this.props.ibanComponent}
        />
        <RowSeparator />
        <RowSeparator />

        <TransferMembership onTapTransfer={onTransferMembership} />
        <RowSeparator />
        <LapseMembership onTapLapse={lapseComponent} />
        <RowSeparator />
        <RaisedButton
          // @ts-ignore
          id="back"
          label="Annuleer"
          onClick={() => this.props.handleClose()}
        />
      </div>
    );
  }
}

export const DetailsFragment = gql`
  fragment DetailsPolicyFragment on Policy {
    ...MemberPaymentDetails
    ...AddressInfo
    ...PolicyPackageDetails
    ...MemberDetails
    additionalInsurance {
      id
      insuredAmount
      premium
      lumpsum
      startDate
      endDate
      frequency
    }
  }
  ${AddressInfo.fragments.addressInfo}
  ${paymentDetailsFragment}
  ${packageDetailsFragment}
  ${memberDetailsFragment}
`;

export default DetailsComponent;
