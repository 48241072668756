// @flow

import React from "react";
import App from "./screens/app";
import "./index.css";
import raven from "raven-js";
import config from "./config";
import { createRoot } from "react-dom/client";
import "./websiteConfig";

raven
  //@ts-ignore
  .config(config.logging.sentry_dsn, {
    captureUnhandledRejections: true,
    environment: config.environment,
  })
  .install();

raven.context(() => {
  const container = document.getElementById("root")!!;
  const root = createRoot(container);
  root.render(<App />);
});
