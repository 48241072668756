// @flow

import type { NavbarAction } from "../actions";
export type State = boolean;

const navBarReducer = (opened: State = false, action: NavbarAction): State => {
  switch (action.type) {
    case "OPEN_NAVIGATIONBAR":
      return true;
    case "CLOSE_NAVIGATIONBAR":
      return false;
    default:
      return opened;
  }
};

export default navBarReducer;
