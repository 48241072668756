// @flow
/**
 * Created by Jim Bemelen on 6-10-2016.
 */

import React from "react";
import { AreaChart, Area, ResponsiveContainer } from "recharts";
import { colors } from "../../styling";

const data = [
  { value: 1.4 },
  { value: 1.6 },
  { value: 0.74 },
  { value: 1.18 },
  { value: 1.32 },
  { value: 1.69 },
  { value: 0.33 },
  { value: 0.88 },
  { value: 1.52 },
  { value: 1.61 },
  { value: 1.04 },
  { value: 1.6 },
  { value: 1.24 },
];

/**
 * This is the static area chart contained in the header of the CEO portal
 */
const HeaderChart = () => (
  <div style={{ paddingTop: "120px", height: "100px" }}>
    {/* @ts-ignore */}
    <ResponsiveContainer>
      {/* @ts-ignore */}
      <AreaChart data={data} margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={colors.blue} stopOpacity={1.0} />
            <stop offset="100%" stopColor={colors.green} stopOpacity={1.0} />
          </linearGradient>
        </defs>
        <Area
          type="basis"
          dataKey="value"
          // @ts-ignore
          fillOpacity={1}
          stroke={colors.blue}
          fill="url(#colorUv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  </div>
);

export default HeaderChart;
