import { ChangeEvent, useEffect, useRef, useState } from "react";

type Validation = (value: string) => undefined | string;

export type ErrorRecord<T> = Partial<Record<keyof T, string>>;

type Validations<T extends {}> = Partial<Record<keyof T, Validation>>;

export const useForm = <T extends Record<keyof T, any> = {}>(options?: {
  validations?: Validations<T>;
  initialValues?: Partial<T>;
}) => {
  const [data, setData] = useState<T>((options?.initialValues || {}) as T);
  const [errors, setErrors] = useState<ErrorRecord<T>>({});
  const submitPressed = useRef(false);
  const touched = useRef({} as T);
  const validations = useRef((options?.validations || {}) as Validations<T>);

  const validate = () => {
    const newErrors: ErrorRecord<T> = {};
    for (const key in validations.current) {
      if (touched.current[key] || submitPressed.current) {
        const value = data[key];
        const validation = validations.current[key];

        if (validation) {
          const message = validation(value);
          newErrors[key] = message;
        }
      }
    }

    if (!checkEquals(errors, newErrors)) {
      setErrors(newErrors);
    }
  };

  useEffect(() => {
    validate();
    // eslint-disable-next-line
  }, [data]);

  // Needs to extend unknown so we can add a generic to an arrow function
  const handleChange =
    <S extends unknown>(key: keyof T, sanitizeFn?: (value: string) => S) =>
    (e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
      const value = sanitizeFn ? sanitizeFn(e.target.value) : e.target.value;
      setData({
        ...data,
        [key]: value,
      });
    };

  const handleBlur = (key: keyof T) => (e: any) => {
    touched.current = {
      ...touched.current,
      [key]: true,
    };

    validate();
  };

  const handleSubmit = (onSubmit?: (data?: T) => void) => {
    submitPressed.current = true;

    const newErrors: ErrorRecord<T> = {};
    for (const key in validations.current) {
      const value = data[key];
      const validation = validations.current[key];

      if (validation) {
        const message = validation(value);
        newErrors[key] = message;
      }
    }

    if (hasNonNull(newErrors)) {
      setErrors(newErrors);
      return;
    }

    setErrors({});

    if (onSubmit) {
      onSubmit(data);
    }
  };

  return {
    values: data,
    handleSubmit,
    errors,
    setErrors: (err: ErrorRecord<T>) =>
      setErrors({
        ...errors,
        ...err,
      }),
    setData: (dataNew: Partial<T>) =>
      setData({
        ...data,
        ...dataNew,
      }),
    registerField: (name: keyof T, options?: { validation: Validation }) => {
      if (options?.validation) {
        validations.current[name] = options?.validation;
      }
      return {
        value: data[name],
        onChange: handleChange(name),
        onBlur: handleBlur(name),
        name,
      };
    },
  };
};

function hasNonNull(target: any) {
  for (var member in target) {
    // eslint-disable-next-line
    if (target[member] != null) return true;
  }
  return false;
}

function checkEquals(obj1: any, obj2: any) {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  for (var key in obj1) {
    // eslint-disable-next-line
    if (obj1[key] != obj2[key]) {
      return false;
    }
  }

  return true;
}

export const textFieldErrorProps =
  <T>(errors: ErrorRecord<T>) =>
  (fieldName: keyof T) => ({
    error: errors[fieldName] != null,
    helperText: errors[fieldName],
  });
