// @flow

import { Component } from "react";
import { errorHandler } from "containers";
import type { ErrorHandler } from "containers";
import { VerticalSeparator } from "components/layout";
import { mustBeIban } from "components/form";
import TextField from "components/textfield";
import type { PropsPaymentInfo } from "./updatePaymentDialog";
import { gql } from "generated";
import { graphql } from "@apollo/client/react/hoc";
import {
  UpdateToInvoiceMutation,
  UpdateToInvoiceMutationVariables,
} from "generated/graphql";
import { DialogActions } from "@mui/material";
import {
  DialogCancelButton,
  DialogConfirmButton,
} from "components/button/buttons";

const valueOrNull = (value: string) => (value.length > 0 ? value : null);

type PropsParent = {
  handleClose(): void;
  insuredPersonIds: string[];
  paymentInfo: PropsPaymentInfo | null;
};
type PropsGraphQL = {
  updateToInvoice: (input: {
    iban: string | null;
    bic: string | null;
    accountHolder: string | null;
  }) => Promise<any>;
};

type Props = PropsParent & PropsGraphQL & ErrorHandler;

type State = {
  confirm: boolean;
  iban: string;
  bic: string;
  accountHolder: string;
  ibanError: string | null;
  loading: boolean;
};

class Invoice extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const payInfo = props.paymentInfo;

    this.state = {
      confirm: false,
      iban: payInfo ? payInfo.iban : "",
      bic: payInfo ? payInfo.bic : "",
      accountHolder: payInfo ? payInfo.accountHolderName : "",
      ibanError: null,
      loading: false,
    };
  }

  async _checkInput() {
    const { iban } = this.state;

    let ibanError;
    if (iban.length > 0) {
      ibanError = mustBeIban(iban);
    }

    this.setState({
      ibanError,
    });

    if (!ibanError) {
      this.setState({
        confirm: true,
      });
    }
  }

  _updateToInvoice = async () => {
    const { iban, bic, accountHolder } = this.state;

    const { updateToInvoice, handleClose, error } = this.props;

    try {
      this.setState({ loading: true });

      await updateToInvoice({
        accountHolder: valueOrNull(accountHolder),
        bic: valueOrNull(bic),
        iban: valueOrNull(iban),
      });

      handleClose();
    } catch (e) {
      this.setState({
        loading: false,
      });

      error.display();
    }
  };

  render() {
    let { handleClose } = this.props;
    const { iban, bic, accountHolder, ibanError, loading } = this.state;

    let content;
    if (this.state.confirm) {
      content = (
        <div style={{ textAlign: "center" }}>
          Weet u zeker dat u de betalingsgegevens wilt wijzigen?
        </div>
      );
    } else {
      content = (
        <div>
          <TextField
            data-test="iban_textfield"
            // @ts-ignore
            floatingLabelText={"IBAN (optioneel)"}
            hintText={"Voer optioneel een iban in"}
            value={iban}
            name="iban"
            fullWidth={true}
            errorText={ibanError}
            onChange={(event: any) => {
              this.setState({
                iban: event.target.value.toUpperCase(),
              });
            }}
          />
          <TextField
            data-test="bic_textfield"
            // @ts-ignore
            floatingLabelText={"BIC (optioneel)"}
            hintText="Voer optioneel een bic code in"
            value={bic}
            name="bic"
            fullWidth={true}
            onChange={(event: any) => {
              this.setState({
                bic: event.target.value,
              });
            }}
          />
          <TextField
            data-test="accountholder_textfield"
            // @ts-ignore
            floatingLabelText={"Rekeninghouder (optioneel)"}
            hintText="Voer optioneel de naam van de rekeninghouder in"
            value={accountHolder}
            name="rekeninghouder"
            fullWidth={true}
            onChange={(event: any) => {
              this.setState({ accountHolder: event.target.value });
            }}
          />
        </div>
      );
    }
    return (
      <div>
        {content}
        <VerticalSeparator distance={2} />
        <DialogActions>
          <DialogCancelButton
            disabled={loading}
            onClick={() => {
              if (!this.state.confirm) {
                handleClose();
              } else {
                this.setState({ confirm: false });
              }
            }}
          />
          <DialogConfirmButton
            disabled={loading}
            onClick={() => {
              if (this.state.confirm) {
                this._updateToInvoice();
              } else {
                this._checkInput();
              }
            }}
          >
            Bevestig
          </DialogConfirmButton>
        </DialogActions>
      </div>
    );
  }
}

export const updateToInvoice = gql(`
  mutation updateToInvoice($input: UpdateToInvoiceInput!) {
    updateToInvoice(input: $input) {
      payments {
        id
        paymentMethod
        iban
        bic
        accountHolder
        signatureDate
        mandateId
      }
    }
  }
`);

const withMutation = graphql<
  PropsParent,
  UpdateToInvoiceMutation,
  UpdateToInvoiceMutationVariables,
  PropsGraphQL
>(updateToInvoice, {
  props: ({ mutate, ownProps }) => ({
    updateToInvoice: (input: {
      iban: string | null;
      bic: string | null;
      accountHolder: string | null;
    }) =>
      mutate({
        variables: {
          input: {
            insuredPersonIds: ownProps.insuredPersonIds,
            ...input,
          },
        },
      }),
  }),
  options: {
    onCompleted: () => window.location.reload(),
  },
});
export const UpdateToInvoice = withMutation(errorHandler(Invoice));
