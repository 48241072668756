//@flow

import React, { Component } from "react";
import FlatButton from "material-ui/FlatButton";
import Paper from "material-ui/Paper";
import {
  getHasDismissedCookieMessage,
  setHasDismissedCookieMessage,
} from "../../utils";

type State = {
  show: boolean;
};

const styles: { [key: string]: React.CSSProperties } = {
  paperElement: {
    zIndex: 101,
    position: "fixed",
    width: "60%",
    bottom: 0,
    left: "20%",
    display: "flex",
    color: "grey",
    textAlign: "center",
  },
  closeButton: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    width: 100,
  },
};

export default class CookieMessage extends Component<{}, State> {
  constructor(props: {}) {
    super(props);

    const cookieDismissed = getHasDismissedCookieMessage();
    const show = !cookieDismissed;

    this.state = {
      show,
    };
  }

  _dismissCookieMessage() {
    this.setState({ show: false });
    setHasDismissedCookieMessage(true);
  }

  render() {
    if (!this.state.show) return null;
    else
      return (
        <Paper style={styles.paperElement}>
          <div style={{ flex: 1, padding: 20 }}>
            Deze website gebruikt cookies en vergelijkbare technieken. Door
            gebruik te maken van deze website gaat u hiermee akkoord. De website
            bevat functionele en analytische cookies om u een optimale
            bezoekerservaring te bieden.
          </div>
          <div style={styles.closeButton}>
            <FlatButton
              label="Sluiten"
              primary={true}
              onClick={() => this._dismissCookieMessage()}
            />
          </div>
        </Paper>
      );
  }
}
