// @flow

import React from "react";
import RaisedButton from "material-ui/RaisedButton";
import { colorsBCV } from "../../../../styling";

type Props = {
  label: string;
  onClick: () => void;
  colored?: boolean;
};

const editButton = (props: Props) => (
  <RaisedButton
    label={props.label}
    onClick={props.onClick}
    backgroundColor={props.colored ? colorsBCV.secondary : null}
  />
);

export default editButton;
