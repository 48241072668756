//@flow
/**
 * Created by Joshua Scheidt on 25-01-2017
 */

import React from "react";
import Header from "../../screens/ledenPortaal/header/homeHeader";
import Stepper from "../../screens/policySignup/signupStepperNewAccount/signupStepper";
import Divider from "material-ui/Divider";

const styles: { [key: string]: React.CSSProperties } = {
  outerContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: "64px",
    flexDirection: "column",
  },
  textContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "200px",
    fontSize: "26px",
    fontWeight: "100",
    textAlign: "center",
  },
  stepperContainer: {
    width: "100%",
    minWidth: "680px",
    display: "flex",
    justifyContent: "center",
  },
};

const SignUpStepperNewAccount = () => (
  <div>
    <Header />
    <div style={styles.outerContainer}>
      <div style={styles.textContainer}>
        Fijn dat u zich bij ons wilt aansluiten! Hieronder vindt u de stappen
        voor het afsluiten van een lidmaatschap.
      </div>
      <Divider style={{ width: "100%" }} />
      <div style={{ paddingTop: "30px" }} />
      <div style={styles.stepperContainer}>
        <Stepper />
      </div>
    </div>
  </div>
);

export default SignUpStepperNewAccount;
