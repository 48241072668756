// @flow

export enum AssociationType {
  BCB = "BCB",
  PIETERSRADE = "PIETERSRADE",
  HULP_EN_STEUN = "HULP_EN_STEUN",
}

export type Config = {
  api: string;
  redirectUrl: string;
  associationId: string;
  association: AssociationType;
  environment: "production" | "development" | "test";
  logging: {
    sentry_dsn: string | null;
  };
};
