import React from "react";
import ExpandablePage from "../../components/expandablePageSection";
import Link from "../../../link";

const PrivacyBCB = () => (
  <ExpandablePage title="Privacyverklaring">
    <p>
      Begrafenis- en crematievereniging Bleijerheide is verantwoordelijk voor de
      verwerking van persoonsgegevens zoals weergegeven in deze
      privacyverklaring.
    </p>
    <p>
      Afdeling technisch van het bestuur {"("}
      <Link href="mailto:bcb.technisch@gmail.com">bcb.technisch@gmail.com</Link>
      {")"} is de Functionaris Gegevensbescherming van Begrafenis en
      Crematievereniging Bleijerheide.
    </p>

    <h4>Verwerking van persoonsgegevens</h4>
    <p>
      Begrafenis en Crematievereniging Bleijerheide verwerkt uw persoonsgegevens
      doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons
      verstrekt.
    </p>

    <h4>Persoonsgegevens die wij van u verwerken</h4>
    <ul>
      <li>Voor- en achternaam</li>
      <li>Geslacht</li>
      <li>Geboortedatum</li>
      <li>Adresgegevens</li>
      <li>Telefoonnummer</li>
      <li>E-mailadres</li>
      <li>
        Overige persoonsgegevens die u actief verstrekt bijvoorbeeld door een
        profiel op deze website aan te maken, in correspondentie en telefonisch
      </li>
      <li>Bankrekeningnummer</li>
    </ul>

    <h4>Waar worden uw persoonsgegevens voor gebruikt?</h4>
    <ul>
      <li>Het leveren van de dienst</li>
      <li>Het aanmaken van een gebruikersaccount</li>
      <li>Het afhandelen van uw betaling</li>
      <li>Het verzenden van onze nieuwsbrief en/of lidmaatschapsbijdrage</li>
      <li>
        Om u te kunnen bellen of e-mailen indien dit nodig is om onze
        dienstverlening uit te kunnen voeren
      </li>
      <li>Om u te informeren over wijzigingen van onze diensten</li>
    </ul>

    <h4>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h4>
    <p>
      Onze website en/of dienst heeft niet de intentie gegevens te verzamelen
      over websitebezoekers die jonger zijn dan 16 jaar, tenzij zij toestemming
      hebben van ouders of voogd. We kunnen echter niet controleren of een
      bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn
      bij de online activiteiten van hun kinderen, om zo te voorkomen dat er
      gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als
      u er van overtuigd bent dat wij zonder die toestemming persoonlijke
      gegevens hebben verzameld over een minderjarige, neem dan contact met ons
      op via {"("}
      <Link href="mailto:bcb.technisch@gmail.com">bcb.technisch@gmail.com</Link>
      {")"}, dan verwijderen wij deze informatie.
    </p>

    <h4>
      Met welk doel en op basis van welke grondslag wij persoonsgegevens
      verwerken
    </h4>
    <p>
      Begrafenis en Crematievereniging Bleijerheide verwerkt uw persoonsgegevens
      voor de volgende doelen: lidmaatschap van de vereniging.
    </p>

    <h4>Geautomatiseerde besluitvorming</h4>
    <p>
      Begrafenis en Crematievereniging Bleijerheide neemt niet op basis van
      geautomatiseerde verwerkingen besluiten over zaken die (aanzienlijke)
      gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die
      worden genomen door computerprogramma's of -systemen, zonder dat daar een
      mens (bijvoorbeeld een medewerker van Begrafenis en Crematievereniging
      Bleijerheide) tussen zit.
    </p>

    <h4>Hoe lang bewaren we persoonsgegevens</h4>
    <p>
      Begrafenis en Crematievereniging Bleijerheide bewaart uw persoonsgegevens
      niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw
      gegevens worden verzameld. Wij hanteren de volgende bewaartermijn voor de
      persoonsgegevens: zolang als fiscaal noodzakelijk is (in de regel tot 7
      jaar na einde lidmaatschap).
    </p>

    <h4>Delen van persoonsgegevens met derden</h4>
    <p>
      Begrafenis en Crematievereniging Bleijerheide verstrekt uitsluitend
      informatie aan derden als dit nodig is voor de uitvoering van onze
      overeenkomst met u of om te voldoen aan een wettelijke verplichting.
    </p>

    <h4>Cookies, of vergelijkbare technieken</h4>
    <p>
      Begrafenis en Crematievereniging Bleijerheide gebruikt alleen functionele
      cookies en analytische cookies die geen inbreuk maken op uw privacy. Een
      cookie is een klein tekstbestand dat bij het eerste bezoek aan deze
      website wordt opgeslagen op uw computer, tablet of smartphone. De cookies
      die wij gebruiken zijn noodzakelijk voor de technische werking van de
      website en uw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren
      werkt en onthouden bijvoorbeeld uw voorkeursinstellingen. Ook kunnen wij
      hiermee onze website optimaliseren. U kunt zich afmelden voor cookies door
      uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat.
      Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de
      instellingen van uw browser verwijderen.
    </p>

    <h4>Gegevens inzien, aanpassen of verwijderen</h4>
    <p>
      U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te
      verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming voor
      de gegevensverwerking in te trekken of bezwaar te maken tegen de
      verwerking van uw persoonsgegevens door Begrafenis en Crematievereniging
      Bleijerheide en heeft u het recht op gegevensoverdraagbaarheid. Dat
      betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens
      die wij van u beschikken in een computerbestand naar u of een ander, door
      u genoemde organisatie, te sturen.
    </p>
    <p>
      U kunt een verzoek tot inzage, correctie, verwijdering,
      gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking van
      uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen
      naar {"("}
      <Link href="mailto:bcb.technisch@gmail.com">bcb.technisch@gmail.com</Link>
      {")"}.
    </p>
    <p>
      Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan,
      vragen wij u een kopie van uw identiteitsbewijs met het verzoek mee te
      sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de
      strook met nummers onderaan het paspoort), paspoortnummer en
      Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. We
      reageren zo snel mogelijk, maar binnen vier weken, op uw verzoek.
    </p>
    <p>
      Begrafenis en Crematievereniging Bleijerheide wil u er tevens op wijzen
      dat u de mogelijkheid heeft om een klacht in te dienen bij de nationale
      toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via{" "}
      <Link href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons">
        deze
      </Link>{" "}
      link.
    </p>

    <h4>Beveiliging van uw persoonsgegevens</h4>
    <p>
      Begrafenis en Crematievereniging Bleijerheide neemt de bescherming van uw
      gegevens serieus en neemt passende maatregelen om misbruik, verlies,
      onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging
      tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd
      zijn of er aanwijzingen zijn van misbruik, neem dan contact via {"("}
      <Link href="mailto:bcb.technisch@gmail.com">bcb.technisch@gmail.com</Link>
      {")"}
    </p>
  </ExpandablePage>
);

export default PrivacyBCB;
