// @flow

import React, { useEffect, useState } from "react";
import RaisedButton from "material-ui/RaisedButton";

import { colors } from "../../../../../styling/index";
import TextField from "../../../../../components/textfield/index";
import { VerticalSeparator } from "../../../../../components/layout/index";
import { NavButtonContainer, Title } from "../../../../../components/dialog";
import { useUpdatePolicyMutation } from "./graphQLUpdateFrequency";
import { errorHandler, ErrorHandler } from "../../../../../containers/index";
import { serializeAmount } from "../../../../../utils";
import { FrequencySelector2 } from "../../shared/frequency";
import { getPremiumForFrequency } from "./graphQLPremiumForFrequency";
import { FragmentType, gql, useFragment } from "generated";
import { useForm } from "utils/useForm";
import { Frequency, UpdateFrequencyInsurerFragment } from "generated/graphql";
import { composeValidators, mustBeAmount, required } from "utils/validator";
import { DialogContainer2 } from "components/dialog/container";

type Props = {
  handleClose: () => void;
  data: FragmentType<typeof UpdateFrequencyInsurerFragmentDef>;
} & ErrorHandler;

const hintTextPremium = "Nieuwe premie";

type FormData = { premium: string };

export const UpdateFrequency = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const data = useFragment(UpdateFrequencyInsurerFragmentDef, props.data);
  const [selectedFrequency, setSelectedFrequency] = useState(
    data.frequency || getUpdateableFrequency(data)[0]
  );
  const [updateFrequency, { loading: loadingMutation }] =
    useUpdatePolicyMutation({
      onCompleted: () => window.location.reload(),
      onError: () => props.error.display(),
    });

  const {
    registerField,
    setData,
    errors: errorsForm,
    handleSubmit,
  } = useForm<FormData>();

  const totalLoading = loading || loadingMutation;

  useEffect(() => {
    const getPrem = async () => {
      try {
        setLoading(true);

        const premium = await getPremiumForFrequency({
          targetFrequency: selectedFrequency,
          policyId: data.id,
        });

        setData({
          premium: premium ? premium : "",
        });
      } catch (err) {
        props.error.display();
      } finally {
        setLoading(false);
      }
    };

    getPrem();
    //eslint-disable-next-line
  }, [selectedFrequency]);

  return (
    <DialogContainer2 data-test="update_frequency_dialog">
      <Title>Update Betalingsfrequentie</Title>
      <p>
        Selecteer een nieuwe betalingsfrequentie, update de premie en druk dan
        op bevestig om de frequentie te updaten.
      </p>
      <FrequencySelector2
        frequency={selectedFrequency}
        frequencies={getUpdateableFrequency(data)}
        setFrequency={setSelectedFrequency}
      />
      <TextField
        // @ts-ignore
        id="premium_field"
        label="Nieuwe premie"
        data-test="update_frequency_premium"
        fullWidth
        hintText={hintTextPremium}
        floatingLabelText={hintTextPremium}
        errorText={errorsForm.premium}
        disabled={totalLoading}
        {...registerField("premium", {
          validation: composeValidators(required, mustBeAmount),
        })}
      />
      <VerticalSeparator distance={2} />
      <NavButtonContainer>
        <RaisedButton
          // @ts-ignore
          id="cancel"
          data-test="update_frequency_cancel_button"
          label="Annuleren"
          primary={false}
          onClick={props.handleClose}
          disabled={totalLoading}
        />
        <RaisedButton
          // @ts-ignore
          id="confirm"
          data-test="update_frequency_confirm_button"
          label="Bevestig"
          labelStyle={{ color: "white" }}
          backgroundColor={colors.green}
          onClick={() =>
            handleSubmit((formData) =>
              updateFrequency({
                variables: {
                  input: {
                    policyId: data.id,
                    frequency: selectedFrequency,
                    premium: serializeAmount(formData.premium),
                  },
                },
              })
            )
          }
          disabled={totalLoading}
        />
      </NavButtonContainer>
    </DialogContainer2>
  );
};

const getUpdateableFrequency = (
  data: UpdateFrequencyInsurerFragment
): Array<Frequency> => {
  const { package: currentPackage } = data;
  const frequencies = currentPackage
    ? currentPackage.frequencies
    : [Frequency.Yearly, Frequency.SemiAnnually, Frequency.Quarterly];

  return frequencies;
};

export const UpdateFrequencyInsurerFragmentDef = gql(`
fragment UpdateFrequencyInsurer on Policy {
  id
  frequency
  premium
  package {
    id
    frequencies
  }
}`);

export default errorHandler(UpdateFrequency);
