// @flow

import React, { PropsWithChildren } from "react";
import { Route, IndexRoute } from "react-router";
import { store } from "../store";
import type { State } from "../reducers";
import type { AccountPermission } from "../api";
import HomePage from "./homePage/homePage";
import AccountPage from "./ledenPortaal/accountPage/accountPage";
import PoliciesPage from "./ledenPortaal/policiesPage/policyPage";
import BestuurderAccountPage from "./bestuurderAccountPage";
import BestuurderSignupAccount from "./bestuurderSignupAccount";
import BestuurderSignupClient from "./bestuurderSignupClient";
import Login from "./login";
import SignupNewUser from "./policySignup/signupStepperPageNewAccount";
import SignupExistingUser from "./policySignup/signupStepperPageExistingAccount";
import SignupSuccessPage from "./policySignup/signupSuccessPage";
import AdministrationPage from "./administrationPage";
import Dashboard from "./dashboard";
import ClientChecker from "./clientChecker";
import RegistrationPage from "./registrationPage";
import NotFoundPage from "./notFoundPage";
import ContactPage from "./homePage/contactPage";
import SterfgevalPage from "./homePage/sterfgevalMelden";
import PackagesPage from "./homePage/packagesPage";
import InformationPage from "./homePage/informationPage";
import BestuursPage from "./homePage/bestuursPage";
import ResetPasswordPage from "./resetPasswordPage";
import ActivateAccountPage from "./activatePage";
import policyDetailsPage from "./policyDetailsPage";
import MigrateLogin from "./migrationPage/migrationLogin";
import MigratePage from "./migrationPage/migrationOverview";
import Communication from "./communication";
import { Footer } from "../components/footer";
import { resetSignupState } from "../actions";
import "./routes.css";
import config, { AssociationType } from "../config";
import { AccountsPage } from "./accountPage";
import { AccountDetails } from "./accountDetails";
import { InsuredPeopleGroupPage } from "./insuredPeopleGroup";
import { SettingsScreen } from "./settingsScreen";
import { EventTimelineScreen } from "./eventTimeline";
import { FAQPage } from "./homePage/faqPage";

// TODO: This is temporary for pietersrade only.
const showSignupRoute =
  config.association !== AssociationType.PIETERSRADE &&
  config.association !== AssociationType.HULP_EN_STEUN;

const checkClientAuth = (nextState: any, replace: any) => {
  const state: State = store.getState();
  const { loggedIn, permissions } = state.login;

  //You can only log into these routes if you are a USER.
  if (!(loggedIn && permissions.includes("USER"))) {
    replace({
      pathname: "/inloggen",
      state: {
        nextPathname: nextState.location.pathname,
      },
    });
  }
};

const checkInsurerAuth = (nextState: any, replace: any) => {
  const state: State = store.getState();
  const { loggedIn, permissions } = state.login;

  const allowedPermissions: Array<AccountPermission> = [
    "ADMINISTRATION",
    "MEMBER_CHECK",
    "FINANCE",
    "PRODUCT_MANAGER",
  ];

  //You can only log into these routes if you have one of the allowed permissions.
  if (
    !(loggedIn && allowedPermissions.some((perm) => permissions.includes(perm)))
  ) {
    replace({
      pathname: "/inloggen",
      state: {
        nextPathname: nextState.location.pathname,
      },
    });
  }
};

/**
 * Automatically reroutes user to the portal if they try are
 * already authenticated and try to go the login page.
 */
const loginRedirectAuth = (nextState: any, replace: any) => {
  const state: State = store.getState();
  const { loggedIn, permissions } = state.login;

  const allowedPermissions: Array<AccountPermission> = [
    "ADMINISTRATION",
    "MEMBER_CHECK",
    "FINANCE",
    "PRODUCT_MANAGER",
  ];

  if (nextState.location.pathname === "/inloggen" && loggedIn) {
    if (permissions.includes("USER")) {
      replace({
        pathname: "/account/lidmaatschappen",
      });
    } else if (allowedPermissions.some((perm) => permissions.includes(perm))) {
      replace({
        pathname: "/bestuurder",
      });
    }
  }
};

//Function that resets the signup store after someone leaves the signup page.
const resetSignupStore = () => {
  const dispatch = store.dispatch;
  dispatch(resetSignupState());
};

export const clientRoutes = (
  // @ts-ignore
  <Route onEnter={checkClientAuth}>
    <Route path="/account" component={AccountPage} />
    <Route path="/account/lidmaatschappen" component={PoliciesPage} />
    {showSignupRoute && (
      <Route
        path="/account/nieuw-lid"
        // @ts-ignore
        onLeave={resetSignupStore}
        component={SignupExistingUser}
      />
    )}
    <Route path="/account/koppel" component={MigratePage} />
  </Route>
);

/*
Function that creates an on enter hook for routes that have specific permissions associated with them.
If the user doesn't have the correct permissions then show the not found route.
 */
const checkPermission =
  (permissionForRoute: Array<AccountPermission>) =>
  (nextState: any, replace: any) => {
    const state: State = store.getState();
    const { permissions } = state.login;

    if (!permissionForRoute.some((perm) => permissions.includes(perm))) {
      replace({
        pathname: "/niet-gevonden",
      });
    }
  };

export const insurerPaths = {
  accountsPage: "/bestuurder/accounts",
  accountsDetails: {
    route: "/bestuurder/accounts/:id",
    path: (accountId: string) => `/bestuurder/accounts/${accountId}`,
  },
  bestuurder: {
    signupMember: "/bestuurder/inschrijven_lid",
    signupAccount: "/bestuurder/inschrijven_account",
  },
  group: {
    route: "/bestuurder/groep/:id",
    path: (insuredPersonId: string) => `/bestuurder/groep/${insuredPersonId}`,
  },
  administrationPage: "/bestuurder/leden",
  policyDetailsPage: {
    route: "/bestuurder/leden/:id",
    path: (policyId: string) => `/bestuurder/leden/${policyId}`,
  },
  timeline: "/bestuurder/tijdslijn",
  settingsPage: "/bestuurder/instellingen",
};

export const insurerRoutes = (
  // @ts-ignore
  <Route onEnter={checkInsurerAuth}>
    <Route path="/bestuurder" component={BestuurderAccountPage} />
    <Route
      path={insurerPaths.settingsPage}
      component={SettingsScreen}
      // @ts-ignore
      onEnter={checkPermission(["ADMINISTRATION"])}
    />
    <Route
      path={insurerPaths.accountsPage}
      component={AccountsPage}
      // @ts-ignore
      onEnter={checkPermission(["ADMINISTRATION"])}
    />
    <Route
      path={insurerPaths.accountsDetails.route}
      component={AccountDetails}
      // @ts-ignore
      onEnter={checkPermission(["ADMINISTRATION"])}
    />
    <Route
      path={insurerPaths.administrationPage}
      component={AdministrationPage}
      // @ts-ignore
      onEnter={checkPermission(["ADMINISTRATION"])}
    />
    <Route
      path={insurerPaths.policyDetailsPage.route}
      component={policyDetailsPage}
      // @ts-ignore
      onEnter={checkPermission(["ADMINISTRATION"])}
    />
    <Route
      path={insurerPaths.group.route}
      component={InsuredPeopleGroupPage}
      // @ts-ignore
      onEnter={checkPermission(["ADMINISTRATION"])}
    />
    <Route
      path="/bestuurder/dashboard"
      component={Dashboard}
      // @ts-ignore
      onEnter={checkPermission(["FINANCE"])}
    />
    <Route
      path="/bestuurder/leden-checker"
      component={ClientChecker}
      // @ts-ignore
      onEnter={checkPermission(["MEMBER_CHECK"])}
    />
    <Route
      path="/bestuurder/aanmeldingen"
      component={RegistrationPage}
      // @ts-ignore
      onEnter={checkPermission(["ADMINISTRATION"])}
    />
    <Route
      path={insurerPaths.bestuurder.signupAccount}
      component={BestuurderSignupAccount}
      // @ts-ignore
      onEnter={checkPermission(["ADMINISTRATION"])}
    />
    <Route
      path={insurerPaths.bestuurder.signupMember}
      component={BestuurderSignupClient}
      // @ts-ignore
      onEnter={checkPermission(["ADMINISTRATION"])}
    />
    <Route
      path="/bestuurder/communicatie"
      component={Communication}
      // @ts-ignore
      onEnter={checkPermission(["ADMINISTRATION"])}
    />
    <Route
      path={insurerPaths.timeline}
      component={EventTimelineScreen}
      // @ts-ignore
      onEnter={checkPermission(["ADMINISTRATION"])}
    />
  </Route>
);

/**
 * Displays a footer at the bottom of the page in all the nested routes.
 */
const WithFooter = (props: PropsWithChildren) => (
  <div className="container">
    <div className="content">{props.children}</div>
    <Footer />
  </div>
);

export const publicRoutes = (
  <div>
    <Route component={WithFooter} path="/">
      <IndexRoute component={HomePage} />
      <Route path="/contact" component={ContactPage} />
      <Route path="/vragen" component={FAQPage} />
      <Route path="/pakketten" component={PackagesPage} />
      <Route path="/informatie" component={InformationPage} />
      <Route path="/bestuur" component={BestuursPage} />
      <Route path="/sterfgeval" component={SterfgevalPage} />
    </Route>
    {/*// @ts-ignore */}
    <Route path="/inloggen" component={Login} onEnter={loginRedirectAuth} />
    {showSignupRoute && (
      <Route
        path="/inschrijven"
        // @ts-ignore
        onLeave={resetSignupStore}
        component={SignupNewUser}
      />
    )}
    <Route path="/inschrijven/succes" component={SignupSuccessPage} />
    <Route path="/migreer" component={MigrateLogin} />
    <Route path="/activeren" component={ActivateAccountPage} />
    <Route path="/herstel-wachtwoord" component={ResetPasswordPage} />
    <Route path="/niet-gevonden" component={NotFoundPage} />
  </div>
);
