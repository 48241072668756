import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { FragmentType, gql, useFragment } from "generated";
import { WithRouterProps, withRouter } from "react-router";
import { insurerPaths } from "screens/routes";
import {
  formatFrequency2,
  formatGender,
  formatPolicyState,
  formatToEuro,
  parseDate,
} from "utils/formatter";
import { pushWithPreviousPath } from "utils/routing";

type Props = {
  data: FragmentType<typeof InsuredPeopleFragment>;
  goToPolicy: (policyId: string) => void;
} & WithRouterProps;

export const PolicyTable = withRouter((props: Props) => {
  const data = useFragment(InsuredPeopleFragment, props.data);

  const anyAV = data.insuredPeople.some((ip) =>
    ip.policies.some((pol) => pol.additionalInsurance != null)
  );

  const showPhone =
    data.phone == null && data.insuredPeople.some((ip) => ip.phone != null);
  const showAccount =
    data.account == null && data.insuredPeople.some((ip) => ip.account != null);
  return (
    <Box>
      <Paper elevation={3}>
        <TableContainer>
          <EnhancedTableToolbar />
          <Table padding="normal" sx={{ minWidth: "650px" }}>
            <TableHead>
              <TableRow>
                <TableCell>Lidnummer</TableCell>
                <TableCell>Naam</TableCell>
                <TableCell>Geboortedatum</TableCell>
                <TableCell>Geslacht</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Premie</TableCell>
                <TableCell>Inschrijfdatum</TableCell>
                <TableCell>Pakket</TableCell>
                {showPhone && <TableCell>Telefoonnumer</TableCell>}
                {showAccount && (
                  <>
                    <TableCell>Email</TableCell>
                    <TableCell />
                  </>
                )}
                {anyAV && (
                  <>
                    <TableCell>AV</TableCell>
                    <TableCell>AV Premie</TableCell>
                    <TableCell>AV Verzekerd bedrag</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.insuredPeople
                .flatMap((ip) =>
                  ip.policies.map((pol) => ({
                    ip,
                    pol,
                  }))
                )
                .map(({ ip, pol }) => {
                  const {
                    id,
                    premium,
                    signupDate,
                    signOffDate,
                    state,
                    frequency,
                    insuredAmount,
                    additionalInsurance: ai,
                  } = pol;

                  const tableCellProps = {
                    onClick: () => {
                      props.goToPolicy(pol.id);
                    },
                  };

                  const frequencyFormatted =
                    formatFrequency2(frequency).toLowerCase();

                  let packageName = "Geen pakket";

                  if (pol.package) packageName = pol.package.name;
                  else if (insuredAmount)
                    packageName =
                      "Verzekerd bedrag " + formatToEuro(insuredAmount);

                  let signOffDateText = "";
                  if (signOffDate)
                    signOffDateText = "op " + parseDate(signOffDate);

                  let avText = "";

                  if (ai) {
                    if (ai.endDate == null)
                      avText = "Vanaf " + parseDate(ai.startDate);
                    else
                      avText = `${parseDate(ai.startDate)} - ${parseDate(
                        ai.endDate
                      )}`;
                  }

                  let ageText = "";
                  if (ip.age) ageText = ` (${ip.age} jaar)`;

                  return (
                    <TableRow key={id} hover>
                      <TableCell {...tableCellProps}>{ip.memberId}</TableCell>
                      <TableCell {...tableCellProps}>{ip.fullName}</TableCell>
                      <TableCell {...tableCellProps}>
                        {parseDate(ip.birthdate) + ageText}
                      </TableCell>
                      <TableCell {...tableCellProps}>
                        {formatGender(ip.sex)}
                      </TableCell>
                      <TableCell {...tableCellProps}>
                        {formatPolicyState(state)} {signOffDateText}
                      </TableCell>
                      <TableCell {...tableCellProps}>
                        {formatToEuro(premium)} {frequencyFormatted}
                      </TableCell>
                      <TableCell {...tableCellProps}>
                        {parseDate(signupDate)}
                      </TableCell>
                      <TableCell {...tableCellProps}>{packageName}</TableCell>
                      {showPhone && (
                        <TableCell {...tableCellProps}>
                          {ip.phone || ""}
                        </TableCell>
                      )}
                      {showAccount && (
                        <>
                          <TableCell {...tableCellProps}>
                            {ip.account?.username || ""}
                          </TableCell>
                          <TableCell>
                            {ip.account?.id && (
                              <Button
                                variant="outlined"
                                onClick={() =>
                                  pushWithPreviousPath(
                                    props,
                                    insurerPaths.accountsDetails.path(
                                      ip.account.id
                                    )
                                  )
                                }
                              >
                                Bekijk account
                              </Button>
                            )}{" "}
                          </TableCell>
                        </>
                      )}
                      {anyAV && (
                        <>
                          <TableCell {...tableCellProps}>{avText}</TableCell>
                          <TableCell {...tableCellProps}>
                            {ai
                              ? `${formatToEuro(ai.premium)} ${formatFrequency2(
                                  ai.frequency
                                ).toLowerCase()}`
                              : ""}
                          </TableCell>
                          <TableCell {...tableCellProps}>
                            {ai ? formatToEuro(ai.insuredAmount) : ""}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
});

function EnhancedTableToolbar(props: {}) {
  return (
    <Toolbar>
      <Typography variant="h6" id="tableTitle" component="div">
        Leden in groep
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
    </Toolbar>
  );
}

const InsuredPeopleFragment = gql(`
fragment InsuredPeopleGroupingList on InsuredPeopleGrouping {
    phone
    account { id }
    insuredPeople {
        id
        memberId
        phone
        fullName
        sex
        phone
        birthdate
        age
        account {
            id
            username
            phone
        }
        policies {
            id
            state
            package {
              id
              name
            }
            signupDate
            insuredAmount
            signOffDate
            premium
            frequency
            additionalInsurance {
              id
              startDate
              endDate
              premium
              insuredAmount
              frequency
            }
      }
    }
      
}`);
