// @flow

import React, { Component } from "react";
import SearchFields from "./searchFields";
import PolicyList, { policyListFragment } from "./policyList";
import { errorHandler } from "../../containers";
import { colors } from "../../styling";
import RaisedButton from "material-ui/RaisedButton";
import PlusIcon from "material-ui/svg-icons/content/add-circle";
import { filter } from "graphql-anywhere";
import CircularProgress from "material-ui/CircularProgress";
import Warning from "./warning";
import { DataValue, graphql } from "@apollo/client/react/hoc";
import {
  MemberIdQueryQuery,
  MemberIdQueryQueryVariables,
} from "generated/graphql";
import { gql } from "@apollo/client";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    paddingTop: "60px",
  },
  rotateIcon: {
    width: "30px",
    height: "30px",
    paddingRight: "14px",
    paddingTop: "14px",
    msTransform: "rotate(45deg)",
    transform: "rotate(45deg)",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  icon: {
    width: "30px",
    height: "30px",
  },
  resultBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
};

type PropsGrapQLVerifyMember = {
  verifyMember: (
    memberId: string | null,
    lastName: string | null,
    birthdate: string | null
  ) => void;
  data: DataValue<MemberIdQueryQuery, MemberIdQueryQueryVariables>;
  loadMore: () => void;
};
type Props = PropsGrapQLVerifyMember;

type State = {
  checked: boolean;
};

export class LedenChecker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this._handleScroll = this._handleScroll.bind(this);
    this._setCheck = this._setCheck.bind(this);

    this.state = {
      checked: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this._handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._handleScroll);
  }

  _handleScroll() {
    const { data, loadMore } = this.props;
    const { viewer, loading } = data;

    if (viewer && !loading) {
      const hasNextPage = viewer.verifyMember.pageInfo.hasNextPage;
      if (hasNextPage) {
        const body = document.body;
        if (!body) throw new Error("Body is not defined");

        const { scrollHeight, scrollTop } = body;
        const { innerHeight } = window;

        if (scrollHeight === scrollTop + innerHeight) {
          loadMore();
        }
      }
    }
  }

  _setCheck(checkVal: boolean) {
    this.setState({ checked: checkVal });
  }

  render() {
    let { loading, error, viewer } = this.props.data;
    const { checked } = this.state;

    if (error) {
      return (
        <div id="error" style={{ ...styles.center, flexDirection: "column" }}>
          <p style={{ fontSize: "24px", textAlign: "center" }}>
            <span>Oeps, er is iets fout gegaan.</span>
            <br />
            <span>
              Ververs de pagina en probeer het opnieuw. Als deze fout zich
              blijft voordoen, neem dan contact met ons op.
            </span>
          </p>
          <RaisedButton
            label="PROBEER OPNIEUW"
            labelStyle={{ color: "white" }}
            backgroundColor={colors.green}
            onClick={() => {
              this.props.verifyMember("", null, null);
              this._setCheck(false);
            }}
          />
        </div>
      );
    }

    let content;
    if (checked) {
      if (loading) {
        content = (
          <div id="loading" style={styles.center}>
            <CircularProgress color={colors.green} />
          </div>
        );
      } else if (viewer) {
        const lengthEdges = viewer.verifyMember.edges.length;
        const totalCount = viewer.verifyMember.totalCount;
        if (totalCount > 0) {
          content = (
            <div id="found" style={styles.resultBox}>
              <span style={{ fontWeight: "bold", paddingBottom: "5px" }}>
                {lengthEdges} van de {totalCount} leden gevonden
              </span>
              <Warning />
              <PolicyList
                data={filter(policyListFragment, viewer.verifyMember)}
              />
            </div>
          );
        } else {
          content = (
            <div id="not_found" style={styles.resultBox}>
              <PlusIcon style={styles.rotateIcon} color={colors.red} />
              <div>Er is geen lid gevonden met de opgegeven informatie.</div>
            </div>
          );
        }
      }
    } else {
      if (loading) {
        return (
          <div id="loading" style={styles.center}>
            <CircularProgress color={colors.green} />
          </div>
        );
      }
    }

    return (
      <div style={styles.container}>
        <div
          style={{
            paddingTop: "30px",
            paddingBottom: "10px",
            fontSize: "20px",
            width: "500px",
            textAlign: "center",
          }}
        >
          Vul een of meer van de velden in om te verifiëren of een persoon lid
          is.
        </div>
        <SearchFields
          verifyMember={this.props.verifyMember}
          setCheck={this._setCheck}
        />
        <div
          style={{ ...styles.container, height: "150px", paddingTop: "20px" }}
        >
          <div id="content" style={{ width: "100%", height: "100%" }}>
            {content}
          </div>
        </div>
      </div>
    );
  }
}

export const MEMBER_QUERY = gql`
  query memberIdQuery(
    $memberId: String
    $lastName: String
    $birthdate: Date
    $after: String
  ) {
    viewer {
      id
      verifyMember(
        memberId: $memberId
        lastName: $lastName
        birthdate: $birthdate
        first: 50
        after: $after
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
        edges {
          node {
            id
          }
        }
        ...PolicyListFragment
      }
    }
  }
  ${policyListFragment}
`;

export const withMutation = graphql<
  {},
  MemberIdQueryQuery,
  MemberIdQueryQueryVariables,
  PropsGrapQLVerifyMember
>(MEMBER_QUERY, {
  props({ data }) {
    return {
      data,
      verifyMember: (memberId, lastName, birthdate) => {
        return data.refetch({
          memberId,
          lastName,
          birthdate,
          after: null,
        });
      },
      loadMore: () => {
        return data.fetchMore({
          variables: {
            memberId: data.variables.memberId,
            lastName: data.variables.lastName,
            birthdate: data.variables.birthdate,
            after: data.viewer.verifyMember.pageInfo.endCursor,
          },
          updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
            const newEdges = fetchMoreResult.viewer.verifyMember.edges;
            return {
              viewer: {
                ...fetchMoreResult.viewer,
                verifyMember: {
                  ...fetchMoreResult.viewer.verifyMember,
                  edges: [
                    ...previousResult.viewer.verifyMember.edges,
                    ...newEdges,
                  ],
                },
              },
            };
          },
        });
      },
    };
  },
  options: {
    variables: {
      memberId: "",
      lastName: null,
      birthdate: null,
      after: null,
    },
    //This is required to add the loading state for a refetch
    notifyOnNetworkStatusChange: true,
  },
});

export default errorHandler(withMutation(LedenChecker));
