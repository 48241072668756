// @flow

import React from "react";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";

import { colors } from "../../../../styling";
import { capitalizeFirstLetter } from "../../../../utils";
import GenderPicker from "../../../genderPicker";
import { UPDATE_INSURED_PERSON_MUTATION } from "./graphql";
import { useForm } from "utils/useForm";
import { required } from "utils/validator";
import { useMutationForApp } from "utils/withApollo";
import { FragmentType, gql, useFragment } from "generated";
import { DialogContainer2 } from "components/dialog/container";
import { NavButtonContainer } from "components/dialog";
const { green } = colors;

type PropsFragment = {
  data: FragmentType<typeof PersonDialogFragment>;
};

type PropsParent = {
  handleClose: () => void;
} & PropsFragment;

type Props = PropsParent & PropsFragment;

type FormData = {
  firstName: string;
  initials: string;
  namePreposition: string;
  lastName: string;
  sex: "MALE" | "FEMALE";
};

const PersonUpdater = (props: Props) => {
  const { id, firstName, initials, namePreposition, lastName, sex } =
    useFragment(PersonDialogFragment, props.data);

  const { registerField, errors, handleSubmit, setData, values } =
    useForm<FormData>({
      initialValues: {
        firstName,
        initials,
        namePreposition,
        lastName,
        sex,
      },
    });

  const [updatePerson, { loading }] = useMutationForApp(
    UPDATE_INSURED_PERSON_MUTATION,
    {
      onCompleted: () => window.location.reload(),
    }
  );

  return (
    <DialogContainer2>
      <TextField
        floatingLabelText={"Voornaam"}
        hintText="Voer de nieuwe voornaam in."
        errorText={errors.firstName}
        fullWidth
        {...registerField("firstName")}
        onChange={(event: any) => {
          setData({ firstName: capitalizeFirstLetter(event.target.value) });
        }}
      />

      <TextField
        floatingLabelText={"Initialen"}
        hintText="Voer de nieuwe initialen in."
        errorText={errors.initials}
        {...registerField("initials", { validation: required })}
        fullWidth
        onChange={(event: any) =>
          setData({ initials: event.target.value.toUpperCase() })
        }
      />

      <TextField
        floatingLabelText={"Tussenvoegsel"}
        hintText="Voer eventueel een nieuw tussenvoegsel in."
        errorText={errors.namePreposition}
        {...registerField("namePreposition")}
        fullWidth
      />

      <TextField
        floatingLabelText={"Achternaam"}
        hintText="Voer de nieuwe achternaam in."
        errorText={errors.lastName}
        {...registerField("lastName", { validation: required })}
        fullWidth
        onChange={(event: any) =>
          setData({ lastName: capitalizeFirstLetter(event.target.value) })
        }
      />

      <div style={{ paddingTop: 20 }}>
        <GenderPicker
          value={values.sex}
          onChange={(sex) => setData({ sex })}
          color={colors.green}
        />
      </div>

      <NavButtonContainer>
        <RaisedButton
          label="Annuleren"
          disabled={loading}
          primary={false}
          onClick={props.handleClose}
        />
        <RaisedButton
          label="Bevestig"
          disabled={loading}
          backgroundColor={green}
          labelStyle={{ color: "white" }}
          onClick={() => {
            handleSubmit(() =>
              updatePerson({
                variables: {
                  input: {
                    firstName:
                      values.firstName === "" ? null : values.firstName,
                    lastName: values.lastName,
                    initials: values.initials,
                    namePreposition:
                      values.namePreposition === ""
                        ? null
                        : values.namePreposition,
                    insuredPersonIds: [id],

                    // @ts-ignore
                    sex: values.sex,
                  },
                },
              })
            );
          }}
        />
      </NavButtonContainer>
    </DialogContainer2>
  );
};

export const PersonDialogFragment = gql(`
  fragment personDialogFragment on InsuredPerson {
    id
    firstName
    initials
    namePreposition
    lastName
    sex
  }
`);

export default PersonUpdater;
