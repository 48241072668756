import {
  Box,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";
import { AccountsOverviewQuery } from "generated/graphql";

type Props = {
  goToAccount: (accountId: string) => void;
  data: AccountsOverviewQuery;
  paginationComponent: any;
  loading: boolean;
};

export const AccountsTable = (props: Props) => {
  const { goToAccount, data } = props;

  return (
    <Box>
      {props.paginationComponent}

      <TableContainer>
        <Table padding="normal" sx={{ minWidth: "650px" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "30%" }}>Email</TableCell>
              <TableCell sx={{ width: "30%" }}>Naam</TableCell>
              <TableCell sx={{ width: "20%" }}>Telefoonnummer</TableCell>
              <TableCell sx={{ width: "20%" }}>Is geverifieerd?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.accounts.accounts.map((acc) => {
              const { username, fullName, phone, verified, id } = acc;
              return (
                <TableRow onClick={() => goToAccount(id)} key={id} hover>
                  <TableCell sx={{ width: "30%" }}>{username}</TableCell>
                  <TableCell sx={{ width: "30%" }}>{fullName}</TableCell>
                  <TableCell sx={{ width: "20%" }}>{phone}</TableCell>
                  <TableCell sx={{ width: "20%" }}>
                    {verified === true ? "Ja" : "Nee"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
