// @flow

import React from "react";
import DeleteConfirmationDialog from "./deleteConfirmationDialog";
import { PropsGraphQLDeleteAccount } from "./container";
import { ErrorHandler } from "containers";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

type Props = PropsGraphQLDeleteAccount & ErrorHandler;

type State = {
  open: boolean;
  loading: boolean;
};

export default class DeleteAccountContainer extends React.Component<
  Props,
  State
> {
  state: State = {
    open: false,
    loading: false,
  };

  closeDialog = () => this.setState({ open: false });
  openDialog = () => this.setState({ open: true });

  deleteAccount = async () => {
    const { error, deleteAccount } = this.props;

    try {
      this.setState({ loading: true, open: false });

      await deleteAccount();

      this.setState({ loading: false });
      window.location.reload();
    } catch (e) {
      this.setState({ loading: false });
      error.display();
    }
  };

  render() {
    return (
      <>
        <Tooltip title="Verwijder het account">
          <IconButton
            color="inherit"
            onClick={this.openDialog}
            disabled={this.state.loading}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <DeleteConfirmationDialog
          open={this.state.open}
          onClose={this.closeDialog}
          confirmDelete={this.deleteAccount}
        />
      </>
    );
  }
}
