// @flow

/**
 * Function used to retrieve the base url. For example,
 * it allows us to find http://localhost:3000 from URL http://localhost:3000/home.
 *
 * Source: http://stackoverflow.com/questions/1420881/how-to-extract-base-url-from-a-string-in-javascript
 *
 * Use as follows:
 *
 * const url = getBaseURL(location.href);
 *
 * @param url The url from which the base url is retrieved.
 */
export const getBaseURL = (url: string) => {
  const pathArray = url.split("/");
  const protocol = pathArray[0];
  const host = pathArray[2];
  return protocol + "//" + host;
};
