// @flow

const downloadLink = (uri: string) => {
  var link = document.createElement("a");
  // The download property is set to prevent message:
  // Resource interpreted as Document but transferred with MIME type application/x-csv
  link.download = uri;
  link.href = uri;
  // $FlowFixMe
  document.body.appendChild(link);
  link.click();
  // $FlowFixMe
  document.body.removeChild(link);
};
const download = async (url: string) => {
  const a = document.createElement("a");
  /// @ts-ignore
  a.download = url;
  a.href = url;
  a.style.display = "none";
  document.body.append(a);
  a.click();

  // Chrome requires the timeout
  await delay(100);
  a.remove();
};

export default downloadLink;

const delay = (milliseconds: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });

export async function downloadLinks(urls: string[]) {
  // @ts-ignore
  for (const [index, url] of urls.entries()) {
    await delay(index * 1000);
    download(url);
  }
}
