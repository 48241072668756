// @flow
import React from "react";
import { gql } from "@apollo/client";

import SectionTitle from "../../components/sectionTitle";
import ContentRow from "../../components/contentRow";
import KeyLabel from "../../components/keyLabel";
import ValueLabel from "../../components/valueLabel";
import Separator from "../../components/rowSeparator";
import EditButton from "../../components/editButton";
import type { PaymentMethod } from "../../../../../types";
import { formatPaymentMethod } from "../../../../../utils";

import "./details.css";
import { formatFrequency2 } from "utils/formatter";
import { Frequency } from "generated/graphql";

type Props = {
  data: {
    frequency: Frequency | null;
    insuredPerson: {
      payment: {
        iban: string | null;
        paymentMethod: PaymentMethod | null;
        accountHolder: string | null;
      } | null;
    };
  };
  onTapEditPayment: () => void;
};

const convertFrequencyToText = (frequency: Frequency | null) =>
  frequency ? formatFrequency2(frequency) : "Onbekend";

const PaymentInfo = (props: Props) => {
  const { data, onTapEditPayment } = props;
  const {
    frequency,
    insuredPerson: { payment },
  } = data;

  const { accountHolder } = payment;
  return (
    <div>
      <Title onTapEdit={onTapEditPayment} />
      <FrequencyInfo frequency={convertFrequencyToText(frequency)} />
      <Separator />
      {payment ? (
        <div>
          {payment.paymentMethod && (
            <div>
              <PaymentMethodInfo paymentMethod={payment.paymentMethod} />
              <Separator />
            </div>
          )}
          <IbanInfo iban={payment.iban} />
          <Separator />

          {accountHolder && (
            <ContentRow>
              <KeyLabel>Naam rekeninghouder</KeyLabel>
              <ValueLabel>{accountHolder}</ValueLabel>
            </ContentRow>
          )}
        </div>
      ) : null}
    </div>
  );
};

const paymentDetailsFragment = gql`
  fragment MemberPaymentDetails on Policy {
    id
    frequency
    insuredPerson {
      id
      payment {
        id
        iban
        paymentMethod
        accountHolder
      }
    }
  }
`;

const Title = (props: { onTapEdit: () => void }) => (
  <div className="horizontal-align">
    <SectionTitle>Betalingsgegevens</SectionTitle>
    <EditButton
      // @ts-ignore
      id="edit"
      onClick={props.onTapEdit}
      data-test="edit_iban_button"
    />
  </div>
);

const FrequencyInfo = (props: { frequency: string }) => (
  <ContentRow>
    <KeyLabel>Betaalperiode</KeyLabel>
    <ValueLabel data-test={"policy_details_frequency_value"}>
      {props.frequency}
    </ValueLabel>
  </ContentRow>
);

const IbanInfo = ({ iban }: { iban: string }) => (
  <ContentRow>
    <KeyLabel>IBAN</KeyLabel>
    <ValueLabel data-test="iban_value">{iban ? iban : "Onbekend"}</ValueLabel>
  </ContentRow>
);

const PaymentMethodInfo = (props: { paymentMethod: PaymentMethod }) => {
  return (
    <ContentRow>
      <KeyLabel>Betalingsmethode</KeyLabel>
      <ValueLabel data-test="payment_method_value">
        {formatPaymentMethod(props.paymentMethod)}
      </ValueLabel>
    </ContentRow>
  );
};

export default PaymentInfo;
export { paymentDetailsFragment };
