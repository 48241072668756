import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  DialogCancelButton,
  DialogConfirmButton,
} from "components/button/buttons";
import { gql } from "generated";
import { useState } from "react";
import { textFieldErrorProps, useForm } from "utils/useForm";
import { required } from "utils/validator";
import { useMutationForApp } from "utils/withApollo";
import EditButton from "../components/editButton";

export const UpdateMemberIdButton = ({
  insuredPersonId,
}: {
  insuredPersonId: string;
}) => {
  const [open, setOpen] = useState(false);
  const { registerField, errors, setErrors, handleSubmit } = useForm<{
    memberId: string;
  }>();

  const fieldErrors = textFieldErrorProps(errors);

  const [updateMemberId, { loading }] = useMutationForApp(mutation, {
    onCompleted: (data) => {
      const { exists } = data.updateMemberId;
      if (exists === true)
        setErrors({
          memberId:
            "Het opgegeven lidnummer bestaat al. Geef een ander lidnummer op.",
        });
      else window.location.reload();
    },
  });

  return (
    <>
      <EditButton onClick={() => setOpen(true)} />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Update lidnummer</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Nieuw lidnummer"
            {...registerField("memberId", { validation: required })}
            {...fieldErrors("memberId")}
          />
        </DialogContent>
        <DialogActions>
          <DialogCancelButton
            disabled={loading}
            onClick={() => setOpen(false)}
          />
          <DialogConfirmButton
            disabled={loading}
            onClick={() =>
              handleSubmit(({ memberId }) =>
                updateMemberId({
                  variables: {
                    input: { insuredPersonId, newMemberId: memberId },
                  },
                })
              )
            }
          >
            Aanpassen
          </DialogConfirmButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mutation = gql(`
mutation updateMemberId($input: UpdateMemberIdInput!) {
  updateMemberId(input: $input) {
    exists
  }
}`);
