// @flow

import type { ActionsUserInfo, SignupUserInfoError } from "../../actions";

export type State = {
  data: {
    firstName: string;
    lastNamePrefix: string;
    lastName: string;
    gender: "MALE" | "FEMALE" | null;
    initials: string;
  };
  errors: Array<SignupUserInfoError>;
  isFetching: boolean;
};

const initialState: State = {
  data: {
    firstName: "",
    lastNamePrefix: "",
    lastName: "",
    gender: null,
    initials: "",
  },
  errors: [],
  isFetching: false,
};

const StepperUserInfo = (
  state: State = initialState,
  action: ActionsUserInfo
): State => {
  switch (action.type) {
    case "ADD_SIGNUP_FIRSTNAME": {
      return {
        ...state,
        data: {
          ...state.data,
          firstName: action.firstName,
        },
        errors: state.errors.filter(
          (element) => ["EMPTY_FIRST_NAME_ERROR"].indexOf(element) === -1
        ),
      };
    }
    case "ADD_SIGNUP_LASTNAME_PREFIX": {
      return {
        ...state,
        data: {
          ...state.data,
          lastNamePrefix: action.lastNamePrefix,
        },
      };
    }
    case "ADD_SIGNUP_LASTNAME": {
      return {
        ...state,
        data: {
          ...state.data,
          lastName: action.lastName,
        },
        errors: state.errors.filter(
          (element) => ["EMPTY_LAST_NAME_ERROR"].indexOf(element) === -1
        ),
      };
    }
    case "ADD_SIGNUP_GENDER": {
      return {
        ...state,
        data: {
          ...state.data,
          gender: action.gender,
        },
        errors: state.errors.filter(
          (element) => ["EMPTY_GENDER_ERROR"].indexOf(element) === -1
        ),
      };
    }
    case "ADD_SIGNUP_INITIALS": {
      return {
        ...state,
        data: {
          ...state.data,
          initials: action.initials,
        },
        errors: state.errors.filter(
          (element) =>
            ["EMPTY_INITIALS_ERROR", "INVALID_INITIALS_ERROR"].indexOf(
              element
            ) === -1
        ),
      };
    }
    case "UPDATE_ERRORS_SIGNUP_USERINFO": {
      return {
        ...state,
        errors: action.errors,
      };
    }
    default:
      return state;
  }
};

export default StepperUserInfo;
