// @flow

import React from "react";
import ExpandablePage from "../../components/expandablePageSection";

const UitgangsPuntePietersrade = () => (
  <ExpandablePage title="Uitgangspunten St. Pietersrade">
    <p>
      Als grote regionale vereniging met circa 5000 leden kunnen wij u het
      vertrouwen geven dat wij uw nabestaanden kunnen ontzorgen bij de uitvaart
      van u of één van uw naasten.
    </p>
    <p>
      Daarnaast werken wij zonder winstoogmerk, wat betekent dat u als lid een
      uitgebreid dienstenpakket ontvangt voor een zo laag mogelijke premie.
    </p>
  </ExpandablePage>
);

export default UitgangsPuntePietersrade;
