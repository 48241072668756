// @flow

import React from "react";
import { PolicyCard, PolicyCardFragment } from "./policyCard";
import { colors } from "../../styling";
import { gql } from "@apollo/client";
import { filter } from "graphql-anywhere";

const stylesList = {
  gridRowContainer: {
    display: "flex",
    width: "900px",
    paddingBottom: "30px",
  },
  emptyScreen: {
    color: colors.grey,
    fontSize: "40px",
    fontWeight: 300,
  },
};

type Props = {
  data: {
    edges: Array<{
      node: {
        id: string;
      };
    }>;
  };
};

/**
 * Component that displays a list of policies.
 */
const PolicyList = (props: Props) => {
  if (props.data == null) return null;

  let rows: Array<React.ReactElement> = [];

  let numPolicies = props.data.edges.length;

  if (numPolicies === 0) {
    return (
      <div style={stylesList.emptyScreen}>
        We hebben geen leden kunnen vinden
      </div>
    );
  }

  for (let i = 0; i < numPolicies; ) {
    if (numPolicies - i > 1) {
      rows.push(
        <div key={i} style={stylesList.gridRowContainer}>
          <div style={{ flex: 1, paddingRight: "15px" }}>
            <PolicyCard
              data={filter(PolicyCardFragment, props.data.edges[i].node)}
            />
          </div>
          <div style={{ flex: 1, paddingLeft: "15px" }}>
            <PolicyCard
              data={filter(PolicyCardFragment, props.data.edges[i + 1].node)}
            />
          </div>
        </div>
      );
    } else {
      rows.push(
        <div key={i} style={stylesList.gridRowContainer}>
          <div style={{ flex: 1, paddingRight: "15px" }}>
            <PolicyCard
              data={filter(PolicyCardFragment, props.data.edges[i].node)}
            />
          </div>
          <div style={{ flex: 1, paddingRight: "15px" }} />
        </div>
      );
      break;
    }

    i += 2;
  }

  return <div>{rows}</div>;
};

export const policyListFragment = gql`
  fragment PolicyListFragment on PolicyConnection {
    edges {
      node {
        ... on Policy {
          id
          ...PolicyCardFragment
        }
      }
    }
  }
  ${PolicyCardFragment}
`;

export default PolicyList;
