// @flow

import React, { Component } from "react";
import { filter } from "graphql-anywhere";
import CircularProgress from "material-ui/CircularProgress";

import { Step, Stepper, StepButton, StepLabel } from "material-ui/Stepper";

import Premium, {
  fragment as PremiumFragment,
} from "../signupStepperComponents/signupPremium";
import YourInfo from "../../../containers/policySignup/signupStepperComponents/signupYourInfo";
import { SignupAddress } from "../signupStepperComponents";
import Payment from "../../../containers/policySignup/signupStepperComponents/signupPayment";
import Overview, {
  fragment as overviewFragment,
} from "./signupOverviewExistingAccount";
import EligibilityQuestions, {
  fragment as eligibilityFragment,
} from "../signupStepperComponents/signupEligibility";
import type { Dispatch, State as ReduxState } from "../../../reducers";
import { connect } from "react-redux";
import { setStepperIndex } from "../../../actions";
import config from "../../../config";
import { Error } from "../../../components/error";
import { graphqlQuery } from "utils/withApollo";
import { gql } from "@apollo/client/core";
import { AddPolicySignupQuery } from "generated/graphql";
import { DataProps } from "@apollo/client/react/hoc";

type Association = {
  id: string;
  eligibilityQuestions: Array<{ id: string }>;
};

const hasEligibilityQuestions = (association: Association) =>
  association.eligibilityQuestions.length > 0;

type Props = {
  setStepperIndex: (index: number) => void;
  index: number;
} & DataProps<AddPolicySignupQuery>;

const styles: { [key: string]: React.CSSProperties } = {
  stepContainer: {
    display: "flex",
    textAlign: "left",
    justifyContent: "center",
    minWidth: "300px",
  },
};

type State = {
  address: {
    postalCode: string;
    houseNumber: number;
    suffix: string | null;
    street: string;
    city: string;
    country: string;
  } | null;
};

class SignupStepperExistingAccount extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      address: null,
    };
  }

  _backPressed = () => {
    const { index } = this.props;
    if (index > 0) this.props.setStepperIndex(index - 1);
  };

  _renderStep = (association: Association) => {
    const { index, setStepperIndex } = this.props;
    const { address } = this.state;

    const hasEligibility = hasEligibilityQuestions(association);

    const premium = <Premium data={filter(PremiumFragment, association)} />;

    const eligibility = (
      <EligibilityQuestions
        backPressed={this._backPressed}
        data={filter(eligibilityFragment, association)}
      />
    );

    const personal = <YourInfo backPressed={this._backPressed} />;

    const addressInput = (
      <SignupAddress
        backPressed={this._backPressed}
        nextPressed={(address) => {
          this.setState({
            address,
          });
          setStepperIndex(index + 1);
        }}
        inputAddress={this.state.address}
      />
    );

    const payment = <Payment backPressed={this._backPressed} />;

    let overview = null;

    if (address) {
      overview = (
        <Overview
          backPressed={this._backPressed}
          address={address}
          data={filter(overviewFragment, association)}
        />
      );
    }

    const steps = [premium];

    if (hasEligibility) {
      steps.push(eligibility);
    }

    steps.push(personal, addressInput, payment);

    if (overview) {
      steps.push(overview);
    }

    return steps[index];
  };

  _createStepLabels(hasEligibilityQuestions: boolean) {
    const titles = ["Contributie"];

    if (hasEligibilityQuestions) {
      titles.push("Gezondsheidsverklaring");
    }

    titles.push("Persoonsgegevens", "Adres", "Betaalgegevens", "Overzicht");

    const steps = titles.map((title, index) =>
      this._createStepLabelItem(index, title)
    );
    return steps;
  }

  _createStepLabelItem(index: number, title: string) {
    const { setStepperIndex } = this.props;
    return (
      <Step key={index}>
        <StepButton onClick={() => setStepperIndex(index)}>
          <StepLabel style={{ fontSize: "16px", fontWeight: "bold" }}>
            {title}
          </StepLabel>
        </StepButton>
      </Step>
    );
  }

  render() {
    const {
      index,
      data: { loading, error, viewer },
    } = this.props;

    if (loading) {
      return <CircularProgress />;
    } else if (error || !viewer) {
      return <Error />;
    } else {
      const association = viewer.association;

      return (
        <div style={{ paddingBottom: "40px" }}>
          <Stepper activeStep={index} linear={true} orientation="horizontal">
            {this._createStepLabels(hasEligibilityQuestions(association))}
          </Stepper>
          <div style={styles.stepContainer}>
            {this._renderStep(viewer.association)}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state: ReduxState) => {
  const {
    signup: { stepper },
  } = state;
  return {
    index: stepper.stepperIndex,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setStepperIndex: (index: number) => dispatch(setStepperIndex(index)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const QUERY = gql`
  query AddPolicySignup {
    viewer {
      id
      association {
        id
        eligibilityQuestions {
          id
        }
        ...SignupPremiumFragment
        ...EligibilityQuestionFragment
        ...FinalCardFragment
      }
    }
  }

  ${PremiumFragment}
  ${eligibilityFragment}
  ${overviewFragment}
`;

const queryHOC = graphqlQuery<{}, AddPolicySignupQuery>(QUERY, {
  options: () => ({ variables: { associationId: config.associationId } }),
});

export default queryHOC(connector(SignupStepperExistingAccount));
