// @flow

import React from "react";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import { colorsBCV, colors } from "../../../../styling";
import { errorHandler, ErrorHandler } from "../../../../containers";
import { UPDATE_ACCOUNT_MUTATION } from "./mutation";
import { graphql } from "@apollo/client/react/hoc";
import {
  UpdateAccountMemberMutation,
  UpdateAccountMemberMutationVariables,
} from "generated/graphql";
import { NavButtonContainer } from "components/dialog";
import { DialogContainer2 } from "components/dialog/container";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  infoTitle: {
    fontSize: "18px",
    color: colorsBCV.secondary,
  },
};

type PropsParent = {
  accountId: string;
  handleClose: () => void;
};

type PropsGraphQL = {
  updateAccount: (lastName: string) => Promise<any>;
};

type Props = PropsGraphQL & ErrorHandler & PropsParent;

type State = { newLastName: string; lastNameError: string | null };

class UpdateLastName extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      newLastName: "",
      lastNameError: null,
    };
  }

  async _checkInput() {
    const { error, handleClose, updateAccount } = this.props;
    const { newLastName } = this.state;
    let lastNameError = null;

    if (newLastName.length === 0) {
      lastNameError = "Er is geen naam ingevuld.";
    }

    this.setState({
      lastNameError,
    });

    if (lastNameError == null) {
      try {
        await updateAccount(newLastName);
        this.setState({
          lastNameError: "",
        });
        handleClose();
      } catch (e) {
        error.display();
      }
    }

    this.setState({
      lastNameError,
    });

    if (lastNameError == null) {
      try {
        await updateAccount(newLastName);
        this.setState({
          lastNameError: "",
        });
        handleClose();
      } catch (e) {
        error.display();
      }
    }
  }

  render() {
    const { handleClose } = this.props;
    const { newLastName } = this.state;

    return (
      <DialogContainer2>
        <span style={styles.infoTitle}>Wat is uw nieuwe achternaam?</span>
        <TextField
          id="lastName"
          defaultValue={newLastName}
          errorText={this.state.lastNameError}
          name="lastName"
          fullWidth={true}
          type="text"
          onChange={(event: any) => {
            this.setState({ newLastName: event.target.value });
          }}
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this._checkInput();
            }
          }}
        />
        <NavButtonContainer>
          <RaisedButton
            // @ts-ignore
            id="back"
            label="Annuleren"
            primary={false}
            onClick={() => {
              this.setState({ newLastName: "", lastNameError: null });
              handleClose();
            }}
          />
          <RaisedButton
            // @ts-ignore
            id="confirm"
            label="Bevestig"
            backgroundColor={colorsBCV.secondary}
            labelColor={colors.white}
            onClick={() => {
              this._checkInput();
            }}
          />
        </NavButtonContainer>
      </DialogContainer2>
    );
  }
}

const withMutation = graphql<
  PropsParent,
  UpdateAccountMemberMutation,
  UpdateAccountMemberMutationVariables,
  PropsGraphQL
>(UPDATE_ACCOUNT_MUTATION, {
  props: ({ mutate, ownProps }) => {
    const { accountId } = ownProps;
    if (!accountId) {
      throw new Error(
        `Expected accountId to be passed in the props but got ${accountId}`
      );
    }
    return {
      updateAccount: (lastName) =>
        mutate({
          variables: { input: { lastName } },
          optimisticResponse: {
            // @ts-ignore
            profile: {
              id: accountId,
              lastName,
            },
          },
        }),
    };
  },
});

export default withMutation(errorHandler(UpdateLastName));
