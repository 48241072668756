//@flow
import { Router, browserHistory, Route } from "react-router";
// See https://github.com/HiThereCommunity/BCV-Client/issues/11
// $FlowFixMe
//import injectTapEventPlugin from "react-tap-event-plugin";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { blueHiThere, colorsBCV, colorsInsurer } from "./../styling/index";
import AlertDialog from "./alertDialog";
import NotFoundPage from "./notFoundPage";
import CookieMessage from "./cookieMessage";

import { clientRoutes, insurerRoutes, publicRoutes } from "./routes";

import { store } from "../store";
import { MuiTheme } from "material-ui/styles";
import { PropsWithChildren } from "react";
import { ApolloProvider } from "@apollo/client";
import { clientNew } from "api/apollo2";
import { ErrorHandlerProvider } from "containers/errorDialog";
import { Provider } from "react-redux";
import { createTheme, GlobalStyles, ThemeProvider } from "@mui/material";
import { nlNL } from "@mui/material/locale";
import { ErrorBoundary } from "components/ErrorBoundary";

//With this object you are able to overwrite the MU Theme
const muiThemeClient = getMuiTheme({
  palette: {
    primary1Color: colorsBCV.secondary,
    primary2Color: colorsBCV.primary,
    accent1Color: colorsBCV.secondary,
  },
});

const muiThemeInsurer = getMuiTheme({
  palette: {
    primary1Color: colorsInsurer.secondary,
    primary2Color: colorsInsurer.primary,
    accent1Color: colorsInsurer.secondary,
  },
});

// Needed for onClick
// http://stackoverflow.com/a/34015469/988941
//injectTapEventPlugin();

function handleUpdate() {
  // @ts-ignore
  let { action } = this.state.location;
  if (action === "PUSH" || action === "REPLACE") {
    window.scrollTo(0, 0);
  }
}

const createMuiThemeWrapper = (theme: MuiTheme) => (props: PropsWithChildren) =>
  (
    <MuiThemeProvider muiTheme={theme}>
      <ErrorBoundary>
        <ErrorHandlerProvider>
          <div style={{ height: "100%", width: "100%" }}>
            <CookieMessage />
            <AlertDialog />
            {props.children}
          </div>
        </ErrorHandlerProvider>
      </ErrorBoundary>
    </MuiThemeProvider>
  );

const Themer = (props: PropsWithChildren) => (
  <ThemeProvider
    theme={createTheme(
      {
        palette: {
          primary: {
            main: colorsInsurer.secondary,
            contrastText: "white",
          },
          secondary: {
            main: blueHiThere,
            contrastText: "white",
          },
        },
        // typography: {
        //   fontWeightRegular: 300
        // },
        components: {
          MuiSelect: {
            defaultProps: {
              MenuProps: {
                disableScrollLock: true,
              },
            },
          },
          MuiTextField: {
            defaultProps: {
              variant: "standard",
            },
          },
          MuiAppBar: {
            defaultProps: {
              color: "secondary",
            },
          },
          MuiButton: {
            defaultProps: {
              size: "medium",
              variant: "contained",
            },
          },
        },
      },
      nlNL
    )}
  >
    {/* <CssBaseline /> */}
    <GlobalStyles
      styles={
        {
          // Override Cssbaseline
          // html: {
          //   "WebkitFontSmoothing": "auto"
          // },
        }
      }
    />
    {props.children}
  </ThemeProvider>
);

const app = () => (
  <Themer>
    {/* @ts-ignore */}
    <Provider store={store}>
      <ApolloProvider client={clientNew}>
        <Router onUpdate={handleUpdate} history={browserHistory}>
          <Route component={createMuiThemeWrapper(muiThemeClient)}>
            {publicRoutes}
            {clientRoutes}
          </Route>
          <Route component={createMuiThemeWrapper(muiThemeInsurer)}>
            {insurerRoutes}
          </Route>
          <Route path="*" component={NotFoundPage} />
        </Router>
      </ApolloProvider>
    </Provider>
  </Themer>
);

export default app;
