// @flow

import React, { Component } from "react";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import { colors } from "../../../../../styling";
import Custom from "./custom";
import Standard from "./standard";

type State = {
  state: "STANDARD" | "CUSTOM";
};

type Props = {
  selectedPackageId: string;
  policyId: string;
};
export default class Contribution extends Component<Props, State> {
  _custom: Custom | null = null;
  _standard: Standard | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      state: "STANDARD",
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.selectedPackageId !== this.props.selectedPackageId) {
      if (this._standard) {
        this._standard.resetPremium();
      }
    }
  }

  getInput(): {
    premium: number;
    lumpsum: number;
  } {
    const { state } = this.state;

    let premiumData;

    if (state === "CUSTOM") {
      premiumData = this._custom!!.getInput();
    } else {
      premiumData = this._standard!!.getInput();
    }
    return premiumData;
  }

  /**
   * Validates the input
   * @returns true if the input is correct
   */
  validate(): boolean {
    const { state } = this.state;

    let correctInput = true;
    if (state === "STANDARD") {
      const standardCorrect = this._standard!!.validate();
      if (!standardCorrect) correctInput = false;
    } else if (state === "CUSTOM") {
      const customCorrect = this._custom!!.validate();
      if (!customCorrect) correctInput = false;
    }

    return correctInput;
  }

  _setState = (value: string) => {
    if (value === "STANDARD") {
      this.setState({ state: "STANDARD" });
    } else if (value === "CUSTOM") {
      this.setState({ state: "CUSTOM" });
    }
  };

  render = () => {
    const { state } = this.state;
    const { policyId, selectedPackageId } = this.props;

    return (
      <div>
        <Separator />
        <h3>Bepaal de contributie</h3>
        <RadioButtonGroup
          // @ts-ignore
          id="group"
          name="createClient"
          defaultSelected={state}
          onChange={(event: any) => this._setState(event.target.value)}
        >
          <RadioButton
            id="standard"
            value="STANDARD"
            label="Standaard"
            style={{ paddingBottom: "10px" }}
            iconStyle={{ fill: colors.green }}
          />
          <RadioButton
            id="custom"
            value="CUSTOM"
            label="Aangepaste contributie opgeven"
            iconStyle={{ fill: colors.green }}
          />
        </RadioButtonGroup>
        <Separator />
        {state === "CUSTOM" ? (
          <Custom ref={(ref) => (this._custom = ref)} />
        ) : (
          <Standard
            policyId={policyId}
            selectedPackageId={selectedPackageId}
            ref={(ref) => (this._standard = ref)}
          />
        )}
      </div>
    );
  };
}

const Separator = () => <div style={{ paddingTop: "30px" }} />;
