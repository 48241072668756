// @flow

import React, { Component } from "react";
import { colors } from "../../../../styling";
import { serializeDate } from "../../../../utils";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import { errorHandler } from "../../../../containers";
import type { ErrorHandler } from "../../../../containers";
import { isDate } from "../../../../utils";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import {
  AddSignatureDateMutation,
  AddSignatureDateMutationVariables,
} from "generated/graphql";
import { DialogContainer2 } from "components/dialog/container";
import { NavButtonContainer } from "components/dialog";

type GraphQLProps = {
  addSignatureDate: (
    input: AddSignatureDateMutationVariables["input"]
  ) => Promise<any>;
};

type PropsParents = {
  insuredPersonId: string;
  handleClose(): void;
};

type Props = PropsParents & ErrorHandler & GraphQLProps;

type State = {
  signatureDate: string;
  signatureDateError: string | null;
};

class SignatureDateUpdater extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      signatureDate: "",
      signatureDateError: null,
    };
  }

  async _checkInput() {
    let { signatureDate } = this.state;
    let { addSignatureDate } = this.props;
    let signatureDateError = null;

    if (signatureDate.length === 0)
      signatureDateError = "Er is geen ondertekeningsdatum ingevoerd.";
    else if (!isDate(signatureDate))
      signatureDateError =
        "De opgegeven datum voldoet niet aan het format DD-MM-JJJJ";

    this.setState({
      signatureDateError,
    });

    if (signatureDateError != null) return;

    const input = {
      signatureDate: serializeDate(signatureDate),
      insuredPersonId: this.props.insuredPersonId,
    };

    try {
      await addSignatureDate(input);
      // Reload to reset the cache.
      window.location.reload();
    } catch (err) {
      this.props.error.display();
    }
  }

  render() {
    const { handleClose } = this.props;
    return (
      <DialogContainer2>
        <TextField
          floatingLabelText={"Ondertekeningsdatum"}
          hintText="DD-MM-JJJJ"
          floatingLabelFocusStyle={{ color: colors.green }}
          underlineFocusStyle={{ borderColor: colors.green }}
          name="signatureDate"
          fullWidth={true}
          type="text"
          errorText={this.state.signatureDateError}
          errorStyle={{ color: "red" }}
          onChange={(event: any) => {
            this.setState({ signatureDate: event.target.value });
          }}
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this._checkInput();
            }
          }}
        />
        <NavButtonContainer>
          <RaisedButton
            label="Annuleren"
            primary={false}
            onClick={() => {
              this.setState({
                signatureDateError: null,
              });
              handleClose();
            }}
          />
          <RaisedButton
            label="Bevestig"
            backgroundColor={colors.green}
            labelColor={colors.white}
            onClick={() => {
              this._checkInput();
            }}
          />
        </NavButtonContainer>
      </DialogContainer2>
    );
  }
}

const updatePayment = gql`
  mutation addSignatureDate($input: AddSignatureDateInput!) {
    addSignatureDate(input: $input) {
      payment {
        id
        signatureDate
      }
    }
  }
`;

const UserMutation = graphql<
  PropsParents,
  AddSignatureDateMutation,
  AddSignatureDateMutationVariables,
  GraphQLProps
>(updatePayment, {
  props: ({ mutate }) => ({
    addSignatureDate: (input) => mutate!!({ variables: { input } }),
  }),
})(errorHandler(SignatureDateUpdater));
export default UserMutation;
