import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { withRouter, WithRouterProps } from "react-router";
import { insurerPaths } from "screens/routes";
import { BackButton } from "../../components/header";
import { DeleteAccountButton } from "./components/deleteAccount";

type Props = { accountId: string | null } & WithRouterProps;

export const Header = withRouter((props: Props) => {
  const { accountId, location } = props;

  const previousPath =
    location.query && location.query.prevPath
      ? location.query.prevPath
      : insurerPaths.accountsPage;

  const goBack = () => props.router.push(previousPath);

  return (
    <AppBar position="static">
      <Toolbar>
        <BackButton onClick={() => goBack()} />
        <Typography variant="h6" component="div">
          Account details
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {accountId && (
          <DeleteAccountButton accountId={accountId} goBack={goBack} />
        )}
      </Toolbar>
    </AppBar>
  );
});
