//@flow

/**
 * Created by Joshua Scheidt on 26-01-2017.
 */

import React, { Component } from "react";
import TextField from "material-ui/TextField";
import { colors } from "./../../styling/index";
import { validateIban } from "../../utils";

const styles: { [key: string]: React.CSSProperties } = {
  inputFieldDiv: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
};

type State = {
  iban: string;
  accountName: string;
  errorIban: string | null;
  errorAccountName: string | null;
};

type Props = {
  onEnter: Function;
};

class Payment extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      iban: "",
      accountName: "",
      errorIban: null,
      errorAccountName: null,
    };
  }

  getInput(): {
    iban: string;
    accountName: string;
  } {
    const { iban, accountName } = this.state;

    return {
      iban,
      accountName,
    };
  }

  /**
   * Test whether the components of this class are valid.
   * Within the class, we check for errors, thus return !error = valid.
   */
  validate(): boolean {
    const { iban, accountName } = this.state;

    let error = false;

    let errorIban,
      errorAccountName = null;

    if (iban === "") {
      errorIban = "IBAN is niet ingevuld.";
      error = true;
    } else if (!validateIban(iban)) {
      errorIban = "De opgegeven IBAN is ongeldig.";
      error = true;
    }

    if (accountName === "") {
      errorAccountName = "Rekeninghouder naam niet ingevuld.";
      error = true;
    }

    this.setState({
      errorIban,
      errorAccountName,
    });

    return !error;
  }

  render() {
    const { iban, accountName, errorIban, errorAccountName } = this.state;
    return (
      <div style={styles.inputFieldDiv}>
        <TextField
          value={iban}
          hintText="NL99BANK0123456789"
          floatingLabelText="IBAN nummer"
          floatingLabelFocusStyle={{ color: colors.green }}
          underlineFocusStyle={{ borderColor: colors.green }}
          onChange={(event: any) =>
            this.setState({
              iban: event.target.value.toUpperCase(),
              errorIban: null,
            })
          }
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this.props.onEnter();
            }
          }}
          errorText={errorIban}
          fullWidth
        />
        <TextField
          defaultValue={accountName}
          floatingLabelText="Naam van de rekeninghouder"
          floatingLabelFocusStyle={{ color: colors.green }}
          underlineFocusStyle={{ borderColor: colors.green }}
          onChange={(event: any) =>
            this.setState({
              accountName: event.target.value,
              errorAccountName: null,
            })
          }
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this.props.onEnter();
            }
          }}
          errorText={errorAccountName}
          fullWidth
        />
      </div>
    );
  }
}

export default Payment;
