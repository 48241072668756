import { Button } from "@mui/material";
import { FragmentType, gql, useFragment } from "generated";
import { useState } from "react";
import { withRouter, WithRouterProps } from "react-router";
import { AccountSignupNew } from "screens/bestuurderSignupAccount/accountSignUpNew";
import { useLinkPersonToAccount } from "./graphql";

type Props = WithRouterProps & {
  insuredPerson: FragmentType<typeof fragmentIP>;
};

export const LinkToNewAccount = withRouter((props: Props) => {
  const { id, firstName, initials, lastName, namePreposition, phone } =
    useFragment(fragmentIP, props.insuredPerson);

  const [open, setOpen] = useState(false);

  const [addToAccount] = useLinkPersonToAccount(props);

  let firstnameInput = "";
  if (firstName) firstnameInput = firstName;
  else if (initials) firstnameInput = initials;

  return (
    <>
      <Button onClick={() => setOpen(true)}>koppel aan nieuw account</Button>
      <AccountSignupNew
        title="Koppel lid aan een nieuw account"
        onClose={() => setOpen(false)}
        open={open}
        initialState={{
          firstName: firstnameInput,
          namePreposition: namePreposition,
          phoneNumber: phone,
          lastName: lastName,
        }}
        onRegistered={(accountId, _) =>
          addToAccount({
            variables: {
              input: {
                accountId: accountId,
                insuredPersonId: id,
              },
            },
          })
        }
      />
    </>
  );
});

const fragmentIP = gql(`
fragment InsuredPersonAccountDetails on InsuredPerson {
  id
  initials
  firstName
  lastName
  phone
  namePreposition
}`);
