// @flow

import React from "react";
import FlatButton from "material-ui/FlatButton";
import { colors } from "../../styling";
type Props = {
  title: string;
  touchTap: Function;
};

const TitleButton = ({ title, touchTap }: Props) => (
  <div
    style={{
      paddingTop: "5px",
    }}
  >
    <FlatButton
      // @ts-ignore
      id="titleButton"
      style={{ color: colors.white }}
      label={title}
      onClick={() => touchTap()}
    />
  </div>
);

export default TitleButton;
