// @flow

import type { ActionsStepper } from "../../actions";

export type State = {
  stepperIndex: number;
};

const initialState: State = {
  stepperIndex: 0,
};

const stepperReducer = (
  state = initialState,
  action: ActionsStepper
): State => {
  switch (action.type) {
    case "SET_STEPPER_INDEX": {
      return {
        stepperIndex: action.stepperIndex,
      };
    }
    case "INCREASE_STEPPER_INDEX": {
      return {
        stepperIndex: state.stepperIndex + 1,
      };
    }
    default:
      return state;
  }
};

export default stepperReducer;
