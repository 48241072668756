// @flow

import type { ActionsAccount, SignupAccountError } from "../../actions";

export type State = {
  data: {
    firstName: string;
    lastName: string;
    lastNamePrefix: string;
    phone: string;
    email: string;
    password: string;
    passwordConfirm: string;
  };
  errors: Array<SignupAccountError>;
  isFetching: boolean;
};

const initialState: State = {
  data: {
    firstName: "",
    lastName: "",
    lastNamePrefix: "",
    phone: "",
    email: "",
    password: "",
    passwordConfirm: "",
  },
  errors: [],
  isFetching: false,
};

const StepperAccount = (
  state: State = initialState,
  action: ActionsAccount
): State => {
  switch (action.type) {
    case "ADD_ACCOUNT_EMAIL": {
      return {
        ...state,
        data: {
          ...state.data,
          email: action.email,
        },
        errors: state.errors.filter(
          (element) =>
            [
              "INVALID_EMAIL_ERROR",
              "EMPTY_EMAIL_ERROR",
              "DUPLICATE_EMAIL_ERROR",
            ].indexOf(element) === -1
        ),
      };
    }
    case "ADD_ACCOUNT_FIRSTNAME": {
      return {
        ...state,
        data: {
          ...state.data,
          firstName: action.firstName,
        },
        errors: state.errors.filter(
          (element) => ["EMPTY_FIRST_NAME_ERROR"].indexOf(element) === -1
        ),
      };
    }
    case "ADD_ACCOUNT_LASTNAME": {
      return {
        ...state,
        data: {
          ...state.data,
          lastName: action.lastName,
        },
        errors: state.errors.filter(
          (element) => ["EMPTY_LAST_NAME_ERROR"].indexOf(element) === -1
        ),
      };
    }
    case "ADD_ACCOUNT_LASTNAME_PREFIX": {
      return {
        ...state,
        data: {
          ...state.data,
          lastNamePrefix: action.lastNamePrefix,
        },
      };
    }
    case "ADD_ACCOUNT_PASSWORD": {
      return {
        ...state,
        data: {
          ...state.data,
          password: action.password,
        },
        errors: state.errors.filter(
          (element) =>
            ["EMPTY_PASSWORD_ERROR", "INVALID_PASSWORD"].indexOf(element) === -1
        ),
      };
    }
    case "ADD_ACCOUNT_PASSWORDCONFIRM": {
      return {
        ...state,
        data: {
          ...state.data,
          passwordConfirm: action.passwordConfirm,
        },
        errors: state.errors.filter(
          (element) =>
            [
              "EMPTY_PASSWORD_CONFIRM_ERROR",
              "INCONSISTENT_PASSWORD_ERROR",
            ].indexOf(element) === -1
        ),
      };
    }
    case "ADD_ACCOUNT_PHONE": {
      return {
        ...state,
        data: {
          ...state.data,
          phone: action.phone,
        },
        errors: state.errors.filter(
          (element) =>
            ["EMPTY_PHONE_ERROR", "INVALID_PHONE_ERROR"].indexOf(element) === -1
        ),
      };
    }
    case "UPDATE_ERRORS_SIGNUP_ACCOUNT": {
      return {
        ...state,
        errors: action.errors,
      };
    }
    default:
      return state;
  }
};

export default StepperAccount;
