// @flow

import React from "react";
import { List, ListItem } from "material-ui/List";
import Divider from "material-ui/Divider";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import IconButton from "material-ui/IconButton";

type Props = {
  files: Array<File>;
  onDeleteFile: (index: number) => void;
};

const FileList = (props: Props) => {
  const { files, onDeleteFile } = props;

  if (files.length === 0) {
    return <p>Geen bijlages gekozen</p>;
  }

  return (
    <List style={{ width: "500px" }}>
      {files.map((file, index) => (
        <div key={index}>
          <ListItem
            disabled
            primaryText={file.name}
            rightIconButton={
              <IconButton
                tooltip="Bijlage verwijderen"
                onClick={() => onDeleteFile(index)}
              >
                <DeleteIcon />
              </IconButton>
            }
          />
          <Divider />
        </div>
      ))}
    </List>
  );
};

export default FileList;
