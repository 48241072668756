// @flow

import React, { Component } from "react";
import Paper from "material-ui/Paper";
import { gql } from "@apollo/client";
import { filter } from "graphql-anywhere";
import RaisedButton from "material-ui/RaisedButton";
import { colors } from "../../../styling";
import Userinfo, { userInfoFragment } from "./policyCardUserInfo";
import Policyinfo, { policyInfoFragment } from "./policyCardPolicyInfo";
import ReportDeath, { reportDeathFragment } from "../reportDeath";
import { Dialog } from "@mui/material";

const stylesCard: { [key: string]: React.CSSProperties } = {
  paperElement: {
    height: "100%",
    width: "100%",
    textAlign: "center",
    paddingTop: "20px",
    paddingLeft: "28px",
    paddingRight: "28px",
    paddingBottom: "28px",
    fontWeight: 300,
    fontSize: "16px",
  },
  policyInfoRowContainer: {
    paddingTop: "12px",
    marginLeft: "40px",
    marginRight: "10px",
    textAlign: "left",
  },
  firstLinesHeight: {
    display: "flex",
    flexDirection: "row",
  },
};

type PropsCard = {
  data: {
    id: string;
    state: string;
  };
};

type State = {
  reportDeathDialogOpen: boolean;
};

/**
 * Represents a 'card' that contains the data for a single policy.
 */
class PolicyCardComponent extends Component<PropsCard, State> {
  constructor(props: PropsCard) {
    super(props);
    this.state = {
      reportDeathDialogOpen: false,
    };
  }

  render() {
    const props = this.props;

    const { reportDeathDialogOpen } = this.state;

    return (
      <Paper
        data-test={`policy_info_${props.data.id}`}
        style={stylesCard.paperElement}
        zDepth={1}
      >
        <div style={stylesCard.firstLinesHeight}>
          <Userinfo data={filter(userInfoFragment, props.data)} />
        </div>
        <div
          style={{
            ...stylesCard.policyInfoRowContainer,
            paddingTop: "16px",
            color: colors.green,
          }}
        >
          <div style={{ paddingBottom: "8px" }}>Lidmaatschap </div>
          <div style={{ height: "1px", backgroundColor: "lightgray" }} />
        </div>
        <Policyinfo data={filter(policyInfoFragment, props.data)} />
        <Spacer />
        <RaisedButton
          label="Meld overlijden"
          onClick={() => this.setState({ reportDeathDialogOpen: true })}
        />
        <Dialog open={reportDeathDialogOpen}>
          <ReportDeath
            data={filter(reportDeathFragment, props.data)}
            onClose={() => this.setState({ reportDeathDialogOpen: false })}
          />
        </Dialog>
      </Paper>
    );
  }
}

const Spacer = () => <div style={{ marginTop: "16px" }} />;

export const PolicyCardFragment = gql`
  fragment PolicyCardFragment on Policy {
    id
    insuredPerson {
      id
      association {
        id
        name
      }
    }
    state
    ...clientCheckUser
    ...clientCheckPolicy
    ...ReportDeathFragment
  }
  ${userInfoFragment}
  ${policyInfoFragment}
  ${reportDeathFragment}
`;

export const PolicyCard = PolicyCardComponent;
