// @flow

import React from "react";
import AppBar from "material-ui/AppBar";
import IconButton from "material-ui/IconButton";
import ArrowBackIcon from "material-ui/svg-icons/navigation/arrow-back";
import { withRouter } from "react-router";
import { colors } from "../../styling";

const styles: { [key: string]: React.CSSProperties } = {
  appBar: {
    backgroundColor: colors.blue,
    position: "fixed",
  },
  centerTitle: {
    position: "fixed",
    zIndex: 1110,
    fontSize: "24px",
    color: "white",
    textAlign: "center",
    width: "40%",
    height: "64px",
    lineHeight: "64px",
    marginLeft: "30%",
    marginRight: "30%",
  },
};

type Props = {
  router: {
    goBack: Function;
  };
};

const Header = (props: Props) => (
  <div>
    <AppBar
      style={styles.appBar}
      iconElementLeft={
        <IconButton
          onClick={() => {
            props.router.goBack();
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      }
    />
    <div style={styles.centerTitle}>Lid registreren</div>
  </div>
);

export default withRouter(Header);
