//@flow

import React from "react";
import Paper from "material-ui/Paper";
import { colorsBCV } from "./../../../styling";
import TermsAndConditions from "../../../components/termsAndConditions";
import { MailCheck, ContributionCheck } from "../../../components/gdpr/index";

const styles: { [key: string]: React.CSSProperties } = {
  paperElement: {
    padding: "25px",
  },
};

type Props = {
  statutesUrl: string;
  associationName: string;
  onCheckTerms: (isInputChecked: boolean) => void;
  onCheckMail: (isInputChecked: boolean) => void;
  onCheckContribution: (isInputChecked: boolean) => void;
};

const SignUpCardFinalStatement = (props: Props) => {
  return (
    <Paper style={styles.paperElement} zDepth={1}>
      <h2 style={{ color: colorsBCV.secondary }}>Slotverklaring</h2>
      <TermsAndConditions
        // @ts-ignore
        id="termsAndConditions"
        statutesUrl={props.statutesUrl}
        onCheck={props.onCheckTerms}
      />
      <Separator />
      <MailCheck
        associationName={props.associationName}
        onCheck={props.onCheckMail}
      />
      <ContributionCheck
        associationName={props.associationName}
        onCheck={props.onCheckContribution}
      />
    </Paper>
  );
};

const Separator = () => <div style={{ height: 25 }} />;

export default SignUpCardFinalStatement;
