// @flow

import React from "react";
import ExpandablePage from "../../components/expandablePageSection";

const RoyerenPietersrade = () => (
  <ExpandablePage title="Royeren">
    <p>
      Leden zijn verplicht hun contributie binnen de daarvoor gestelde termijn
      te voldoen. Na niet op tijd betaald te hebben, ontvangt het lid een
      herinneringsbrief. Als ook de gestelde termijn in de herinneringsbrief
      niet in acht genomen wordt, kan het bestuur besluiten het betreffende lid
      te royeren.
    </p>
    <p>
      Een royement is onherroepelijk en een geroyeerd lid kan geen aanspraak
      meer maken op diensten van de vereniging. Na een royement vindt ook nooit
      teruggave plaats van in het verleden betaalde premies.
    </p>
  </ExpandablePage>
);

export default RoyerenPietersrade;
