// @flow

import React from "react";
import RaisedButton from "material-ui/RaisedButton";
import { colorsBCV } from "../../../../styling";

type Props = {
  onClick: () => void;
  "data-test"?: string | null;
};

const editButton = (props: Props) => (
  <RaisedButton
    label="WIJZIG"
    data-test={props["data-test"]}
    backgroundColor={colorsBCV.secondary}
    labelStyle={{ color: "white" }}
    onClick={props.onClick}
  />
);

export default editButton;
