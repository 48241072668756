import { useLazyQuery } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useErrorHandler } from "containers/errorDialog";
import { gql } from "generated";
import {
  SearchAccountByUsernameQuery,
  SearchAccountByUsernameQueryVariables,
} from "generated/graphql";
import { useState } from "react";
import { withRouter, WithRouterProps } from "react-router";
import { useLinkPersonToAccount } from "./graphql";

type Props = {
  insuredPersonId: string;
} & WithRouterProps;

export const LinkToExistingAccount = withRouter((props: Props) => {
  const showError = useErrorHandler();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");

  const [fetchAccount, { loading, data }] = useLazyQuery<
    SearchAccountByUsernameQuery,
    SearchAccountByUsernameQueryVariables
  >(query, {
    onError: () => showError(),
  });

  const [addToAccount, { loading: loadingAddAcount }] =
    useLinkPersonToAccount(props);

  const close = () => {
    setShow(false);
  };

  const getAccount = () => fetchAccount({ variables: { username: email } });

  const account = data?.accountForUsername;

  const validEmail = email.length > 0;

  let errorText: string | null = null;
  if (data && account == null) errorText = "Geen account gevonden voor email";

  return (
    <>
      <Button onClick={() => setShow(true)}>koppel aan bestaand account</Button>
      <Dialog maxWidth="sm" fullWidth open={show} onClose={close}>
        <DialogTitle>Koppel lid aan een bestaand account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vul de email in van het account en koppel het lid hieraan.
          </DialogContentText>
          <Stack direction="row" spacing={2}>
            <TextField
              placeholder="Vul een email in"
              fullWidth
              value={email}
              error={errorText != null}
              helperText={errorText}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && validEmail && getAccount()}
            />
            <Box>
              <Button
                size="small"
                disabled={!validEmail}
                variant="outlined"
                onClick={() => getAccount()}
              >
                Zoek
              </Button>
            </Box>
          </Stack>
          <Box sx={{ mt: 2 }} />
          {account && (
            <Typography component="div">
              Account gevonden met naam accounthouder <b>{account.fullName}</b>
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" disabled={loading} onClick={close}>
            Annuleren
          </Button>
          <Button
            variant="contained"
            disabled={account == null || loadingAddAcount}
            onClick={() => {
              if (account != null)
                addToAccount({
                  variables: {
                    input: {
                      insuredPersonId: props.insuredPersonId,
                      accountId: account.id,
                    },
                  },
                });
            }}
          >
            Koppel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

const query = gql(`
query searchAccountByUsername($username: String!) {
  accountForUsername(username: $username) {
    id
    fullName
  }
}`);
