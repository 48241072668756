import React from "react";
import PageSection from "../components/pageSection";
import Link from "../../link";

const ContactPietersrade = () => (
  <PageSection title="Contact">
    <p>
      Heeft u vragen of wilt u een wijziging doorgeven neem dan contact met ons
      op:
    </p>
    <p>
      Postbus 444 <br />
      6460 AK Kerkrade <br />
      Telefoon: 045-5456368 <br />
      E-mail:{" "}
      <Link href="mailto:st.pietersrade@home.nl">st.pietersrade@home.nl</Link>
      <br />
      <br />
      IBAN: NL04RABO0125282176
      <br />
      BIC: RABONL2U
      <br />
      Incassant ID: NL39ZZZ140175330000
      <br />
      AFM : 17000515
      <br />
      KVK: 14017533
    </p>
  </PageSection>
);

export default ContactPietersrade;
