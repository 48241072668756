import React from "react";
import TextField from "material-ui/TextField";
import { colors } from "../../styling";
import { isEmail, validatePhone } from "../../utils";
import { isEmailUnique } from "../../api";
import { errorHandler, ErrorHandler } from "../../containers";
import config from "../../config";
import { capitalizeFirstLetter } from "../../utils";
import { gql } from "generated";
import {
  NewAccountMutation,
  NewAccountMutationVariables,
} from "generated/graphql";
import { graphql } from "@apollo/client/react/hoc";
import { FetchResult } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

type State = {
  name: string;
  errorName: string | null;
  prefix: string;
  lastName: string;
  errorLastName: string | null;
  phone: string;
  errorPhone: string | null;
  email: string;
  errorEmail: string | null;
  loading: boolean;
};

type PropsGraphQL = {
  addAccount: (
    input: NewAccountMutationVariables["input"]
  ) => Promise<FetchResult<NewAccountMutation>>;
};

type PropsParent = {
  onRegistered: (accountId: string, email: string) => Promise<any> | void;
  open: boolean;
  onClose: () => void;
  title: string;
  initialState?: {
    firstName: string;
    namePreposition: string | null;
    lastName: string;
    phoneNumber: string;
  };
};

type Props = PropsParent & ErrorHandler & PropsParent & PropsGraphQL;

class NewClient extends React.Component<Props, State> {
  state: State;

  constructor(props: Props) {
    super(props);

    const initialState = props.initialState || {
      firstName: "",
      namePreposition: "",
      lastName: "",
      phoneNumber: "",
    };
    this.state = {
      name: initialState.firstName,
      errorName: null,
      prefix: initialState.namePreposition,
      lastName: initialState.lastName,
      errorLastName: null,
      phone: initialState.phoneNumber,
      errorPhone: null,
      email: "",
      errorEmail: null,
      loading: false,
    };
  }

  _validate = async () => {
    const { onRegistered, addAccount } = this.props;
    const { name, lastName, phone, email, prefix } = this.state;
    let error = false;

    if (email === "") {
      this.setState({ errorEmail: "Het e-mailadres mag niet leeg zijn." });
      error = true;
    } else if (!isEmail(email)) {
      this.setState({ errorEmail: "Het e-mailadres is niet geldig." });
      error = true;
    } else {
      try {
        const unique = await isEmailUnique(email);
        if (!unique) {
          this.setState({ errorEmail: "Dit e-mailadres is al in gebruik." });
          error = true;
        } else {
          this.setState({ errorEmail: null });
        }
      } catch (e) {
        this.props.error.display();
      }
    }

    if (name === "") {
      this.setState({ errorName: "De roepnaam mag niet leeg zijn." });
      error = true;
    } else {
      this.setState({ errorName: null });
    }

    if (lastName === "") {
      this.setState({ errorLastName: "De achternaam mag niet leeg zijn." });
      error = true;
    } else {
      this.setState({ errorLastName: null });
    }


    if (!error) {
      try {
        this.setState({ loading: true });
        const response = await addAccount({
          username: email,
          firstName: name,
          lastName,
          namePreposition: prefix,
          phone,
          redirectUrl: `${config.redirectUrl}/activeren`,
        });

        const { account } = response.data.addAccount;
        await onRegistered(account.id, account.username);

        this.setState({ loading: false });
      } catch (e) {
        this.props.error.display();
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  render() {
    let {
      errorName,
      errorLastName,
      errorPhone,
      errorEmail,
      loading,
      name,
      lastName,
      prefix,
      phone,
      email,
    } = this.state;

    const { open, onClose, title } = this.props;

    const close = () => {
      if (!loading) onClose();
    };

    return (
      <Dialog open={open} onClose={close}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Na het registreren zal er een email verzonden worden waarmee het
            emailadres geverifieerd kan worden en een mail met een tijdelijk
            wacthwoord om op het portaal in te loggen.
          </DialogContentText>
          <TextField
            id="name"
            value={name}
            floatingLabelText="Roepnaam"
            floatingLabelFocusStyle={{ color: colors.green }}
            underlineFocusStyle={{ borderColor: colors.green }}
            onChange={(event: any) =>
              this.setState({
                name: capitalizeFirstLetter(event.target.value),
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorText={errorName}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />
          <TextField
            // @ts-ignore
            id="prefix"
            value={prefix}
            floatingLabelText="Tussenvoegsel (optioneel)"
            floatingLabelFocusStyle={{ color: colors.green }}
            underlineFocusStyle={{ borderColor: colors.green }}
            onChange={(event: any) =>
              this.setState({
                prefix: event.target.value,
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />

          <TextField
            // @ts-ignore
            id="lastName"
            value={lastName}
            floatingLabelText="Achternaam"
            floatingLabelFocusStyle={{ color: colors.green }}
            underlineFocusStyle={{ borderColor: colors.green }}
            onChange={(event: any) =>
              this.setState({
                lastName: capitalizeFirstLetter(event.target.value),
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorText={errorLastName}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />

          <TextField
            // @ts-ignore
            id="phoneNum"
            value={phone}
            floatingLabelText="Telefoonnummer"
            floatingLabelFocusStyle={{ color: colors.green }}
            underlineFocusStyle={{ borderColor: colors.green }}
            onChange={(event: any) =>
              this.setState({
                phone: event.target.value,
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorText={errorPhone}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />

          <TextField
            // @ts-ignore
            id="email"
            value={email}
            floatingLabelText="E-mailadres"
            floatingLabelFocusStyle={{ color: colors.green }}
            underlineFocusStyle={{ borderColor: colors.green }}
            onChange={(event: any) =>
              this.setState({
                email: event.target.value,
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorText={errorEmail}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} variant="outlined">
            Sluiten
          </Button>
          <Button disabled={loading} onClick={() => this._validate()}>
            Account registreren
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const ADD_ACCOUNT_MUTATION = gql(`
mutation newAccount($input: AddAccountInput!) {
  addAccount(input: $input) {
    account {
      id
      username
    }
  }
}
`);

export const withMutation = graphql<
  PropsParent,
  NewAccountMutation,
  NewAccountMutationVariables,
  PropsGraphQL
>(ADD_ACCOUNT_MUTATION, {
  props: ({ mutate }) => ({
    addAccount: (input) => mutate({ variables: { input } }),
  }),
});

export const AccountSignupNew = withMutation(errorHandler(NewClient));
