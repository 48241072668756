// @flow

import React from "react";
import SvgIcon from "material-ui/SvgIcon";

const MaleIcon = (props: any) => (
  <SvgIcon {...props}>
    <path d="M12,6 C13.11,6 14,5.11 14,4 C14,2.89 13.11,2 12,2 C10.89,2 10,2.89 10,4 C10,5.11 10.89,6 12,6 L12,6 Z" />
    <path d="M10,22 L10,14.5 L8.5,14.5 L8.5,9 C8.5,7.9 9.4,7 10.5,7 L13.5,7 C14.6,7 15.5,7.9 15.5,9 L15.5,14.5 L14,14.5 L14,22 L10,22 L10,22 Z" />
  </SvgIcon>
);

export default MaleIcon;
