import React from "react";
import TextField from "material-ui/TextField";
import CircularProgress from "material-ui/CircularProgress";
import { colors } from "../../styling";
import RaisedButton from "material-ui/RaisedButton";
import { isEmail, validatePhone } from "../../utils";
import { isEmailUnique } from "../../api";
import { errorHandler, ErrorHandler } from "../../containers";
import config from "../../config";
import { capitalizeFirstLetter } from "../../utils";
import { gql } from "generated";
import {
  NewAccountMutation,
  NewAccountMutationVariables,
} from "generated/graphql";
import { graphql } from "@apollo/client/react/hoc";
import { FetchResult } from "@apollo/client";

type State = {
  name: string;
  errorName: string | null;
  prefix: string;
  lastName: string;
  errorLastName: string | null;
  phone: string;
  errorPhone: string | null;
  email: string;
  errorEmail: string | null;
  loading: boolean;
};

type PropsGraphQL = {
  addAccount: (
    input: NewAccountMutationVariables["input"]
  ) => Promise<FetchResult<NewAccountMutation>>;
};

type PropsParent = {
  onRegistered: (accountId: string, email: string) => void;
};

type Props = ErrorHandler & PropsParent & PropsGraphQL;

const styles: { [key: string]: React.CSSProperties } = {
  inputFieldDiv: {
    display: "flex",
    flexDirection: "column",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
};

export class NewClient extends React.Component<Props, State> {
  state: State = {
    name: "",
    errorName: null,
    prefix: "",
    lastName: "",
    errorLastName: null,
    phone: "",
    errorPhone: null,
    email: "",
    errorEmail: null,
    loading: false,
  };

  _validate = async () => {
    const { onRegistered, addAccount } = this.props;
    const { name, lastName, phone, email, prefix } = this.state;
    let error = false;

    if (email === "") {
      this.setState({ errorEmail: "Het e-mailadres mag niet leeg zijn." });
      error = true;
    } else if (!isEmail(email)) {
      this.setState({ errorEmail: "Het e-mailadres is niet geldig." });
      error = true;
    } else {
      try {
        const unique = await isEmailUnique(email);
        if (!unique) {
          this.setState({ errorEmail: "Dit e-mailadres is al in gebruik." });
          error = true;
        } else {
          this.setState({ errorEmail: null });
        }
      } catch (e) {
        this.props.error.display();
      }
    }

    if (name === "") {
      this.setState({ errorName: "De roepnaam mag niet leeg zijn." });
      error = true;
    } else {
      this.setState({ errorName: null });
    }

    if (lastName === "") {
      this.setState({ errorLastName: "De achternaam mag niet leeg zijn." });
      error = true;
    } else {
      this.setState({ errorLastName: null });
    }

    if (phone === "") {
      this.setState({ errorPhone: "Het telefoonnummer mag niet leeg zijn." });
      error = true;
    } else if (!validatePhone(phone)) {
      this.setState({ errorPhone: "Het telefoonnummer is niet geldig." });
      error = true;
    } else {
      this.setState({ errorPhone: null });
    }

    if (!error) {
      try {
        this.setState({ loading: true });
        const response = await addAccount({
          username: email,
          firstName: name,
          lastName,
          namePreposition: prefix,
          phone,
          redirectUrl: `${config.redirectUrl}/activeren`,
        });

        this.setState({ loading: false });
        const { account } = response.data.addAccount;
        onRegistered(account.id, account.username);
      } catch (e) {
        this.setState({ loading: false });
        this.props.error.display();
      }
    }
  };

  render() {
    let {
      errorName,
      errorLastName,
      errorPhone,
      errorEmail,
      loading,
      name,
      lastName,
      prefix,
      phone,
      email,
    } = this.state;

    if (loading) {
      return (
        <div style={styles.center}>
          <CircularProgress color={colors.green} />
        </div>
      );
    } else {
      return (
        <div style={styles.inputFieldDiv}>
          <TextField
            // @ts-ignore
            id="name"
            value={name}
            floatingLabelText="Roepnaam"
            floatingLabelFocusStyle={{ color: colors.green }}
            underlineFocusStyle={{ borderColor: colors.green }}
            onChange={(event: any) =>
              this.setState({
                name: capitalizeFirstLetter(event.target.value),
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorText={errorName}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />
          <TextField
            // @ts-ignore
            id="prefix"
            value={prefix}
            floatingLabelText="Tussenvoegsel (optioneel)"
            floatingLabelFocusStyle={{ color: colors.green }}
            underlineFocusStyle={{ borderColor: colors.green }}
            onChange={(event: any) =>
              this.setState({
                prefix: event.target.value,
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />

          <TextField
            // @ts-ignore
            id="lastName"
            value={lastName}
            floatingLabelText="Achternaam"
            floatingLabelFocusStyle={{ color: colors.green }}
            underlineFocusStyle={{ borderColor: colors.green }}
            onChange={(event: any) =>
              this.setState({
                lastName: capitalizeFirstLetter(event.target.value),
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorText={errorLastName}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />

          <TextField
            // @ts-ignore
            id="phoneNum"
            value={phone}
            floatingLabelText="Telefoonnummer"
            floatingLabelFocusStyle={{ color: colors.green }}
            underlineFocusStyle={{ borderColor: colors.green }}
            onChange={(event: any) =>
              this.setState({
                phone: event.target.value,
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorText={errorPhone}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />

          <TextField
            // @ts-ignore
            id="email"
            value={email}
            floatingLabelText="E-mailadres"
            floatingLabelFocusStyle={{ color: colors.green }}
            underlineFocusStyle={{ borderColor: colors.green }}
            onChange={(event: any) =>
              this.setState({
                email: event.target.value,
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorText={errorEmail}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />

          <div
            style={{
              paddingTop: "30px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <RaisedButton
              // @ts-ignore
              id="create"
              label="Aanmelden"
              backgroundColor={colors.green}
              labelColor={colors.white}
              onClick={() => this._validate()}
            />
          </div>
        </div>
      );
    }
  }
}

export const ADD_ACCOUNT_MUTATION = gql(`
mutation newAccount($input: AddAccountInput!) {
  addAccount(input: $input) {
    account {
      id
      username
    }
  }
}
`);

export const withMutation = graphql<
  PropsParent,
  NewAccountMutation,
  NewAccountMutationVariables,
  PropsGraphQL
>(ADD_ACCOUNT_MUTATION, {
  props: ({ mutate }) => ({
    addAccount: (input) => mutate({ variables: { input } }),
  }),
});

const Wrapper = withMutation(errorHandler(NewClient));
export default Wrapper;
