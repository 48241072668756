// @flow

import React from "react";
import RaisedButton from "material-ui/RaisedButton";
import { NavButtonContainer } from "../../../../../../components/dialog";
import { colors } from "../../../../../../styling";

const Warning = (props: { toState: string }) => {
  return (
    <div>
      <h2>Let op!</h2>
      <span>
        Door op bevestig te klikken verandert de status van het lid van{" "}
        <strong>Actief</strong> naar <strong>{props.toState}</strong>
        , dit kan niet ongedaan worden gemaakt.
        <br />
        <br />
        Als u de status wilt veranderen druk op <i>bevestig</i>, anders druk op{" "}
        <i>annuleer</i>.
      </span>
    </div>
  );
};

const NavButtons = (props: {
  onCancel: () => void;
  onConfirm: () => void;
  disabled?: boolean;
}) => (
  <NavButtonContainer>
    <RaisedButton
      // @ts-ignore
      id="back"
      label="Annuleer"
      primary={false}
      disabled={props.disabled}
      onClick={props.onCancel}
    />
    <RaisedButton
      // @ts-ignore
      id="confirm"
      label="Bevestig"
      backgroundColor={colors.green}
      labelStyle={{ color: "white" }}
      disabled={props.disabled}
      onClick={props.onConfirm}
    />
  </NavButtonContainer>
);

export { Warning, NavButtons };
