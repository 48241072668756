import React from "react";
import { colors } from "../../styling";
import RaisedButton from "material-ui/RaisedButton";

type Props = {
  email: string;
  onConfirm: () => void;
};

const RegisteredAccount = ({ email, onConfirm }: Props) => (
  <div style={{ textAlign: "center", paddingTop: "40px" }}>
    <p>
      <span>
        Het account is aangemeld! Er is een activatie mail gestuurd naar{" "}
        <span style={{ fontWeight: "bold" }}>{email}</span>.
      </span>
    </p>
    <p>
      <span>Ga verder om een lid te registreren voor dit account.</span>
    </p>
    <div
      style={{
        paddingTop: "30px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <RaisedButton
        // @ts-ignore
        id="verify"
        label="Volgende"
        backgroundColor={colors.green}
        labelColor={colors.white}
        onClick={onConfirm}
      />
    </div>
  </div>
);

export default RegisteredAccount;
