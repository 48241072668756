import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  Button,
} from "@mui/material";
import {
  DialogCancelButton,
  DialogConfirmButton,
} from "components/button/buttons";
import { FragmentType, gql, useFragment } from "generated";
import { PolicyState } from "generated/graphql";
import { useState } from "react";
import { useMutationForApp } from "utils/withApollo";

export const ReactivatePolicyButton = (props: {
  data: FragmentType<typeof fragment>;
}) => {
  const [open, setOpen] = useState(false);

  const [reactivate, { loading }] = useMutationForApp(mutation, {
    refetchQueries: "active",
    onCompleted: () => setOpen(false),
  });

  const data = useFragment(fragment, props.data);
  const { state } = data;

  if (
    state === PolicyState.Active ||
    state === PolicyState.Accepted ||
    state === PolicyState.Pending
  )
    return null;

  return (
    <>
      <Button color="error" onClick={() => setOpen(true)} disabled={loading}>
        Heractiveer lid
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Heractiveer lidmaatschap</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Weet u zeker dat u het lidmaatschap wilt heractiveren?
          </DialogContentText>
          <DialogActions>
            <DialogCancelButton onClick={() => setOpen(false)} />
            <DialogConfirmButton
              onClick={() =>
                reactivate({
                  variables: {
                    input: {
                      policyId: data.id,
                    },
                  },
                })
              }
            >
              Heractiveer
            </DialogConfirmButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mutation = gql(`
mutation reactivePolicy($input: ReactivatePolicyInput!) {
    reactivatePolicy(input: $input) {
        clientMutationId
    }
}
`);
const fragment = gql(`
fragment ReactivatePolicy on Policy {
    id
    state   
}`);
