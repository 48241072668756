import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { gql } from "generated";
import {
  AddToAccountMutation,
  AddToAccountMutationVariables,
} from "generated/graphql";
import { useState } from "react";
import { useForm } from "utils/useForm";
import { required } from "utils/validator";
import { useLazyQueryForApp, useMutationForApp } from "utils/withApollo";

export const LinkMemberButton = ({ accountId }: { accountId: string }) => {
  const [open, setOpen] = useState(false);

  const { registerField, values, handleSubmit } = useForm<{
    memberId: string;
  }>();

  const [findMember, { loading, data }] = useLazyQueryForApp(
    FIND_MEMBER_QUERY,
    {
      variables: {
        memberId: values.memberId,
      },
    }
  );

  const [addMemberToAccount, { loading: loadingAddMember }] = useMutationForApp<
    AddToAccountMutation,
    AddToAccountMutationVariables
  >(ADD_TO_ACCOUNT_MUTATION, {
    onCompleted: () => window.location.reload(),
  });

  const disable = loading || loadingAddMember;

  const close = () => {
    if (disable === false) setOpen(false);
  };

  const findMemberFn = () => handleSubmit((_) => findMember());

  const insuredPersonForMemberId = data?.insuredPersonForMemberId;
  const account = insuredPersonForMemberId?.account;

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={() => close()}>
        <DialogTitle>Koppel een lid aan het account</DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={2}>
            <TextField
              onKeyDown={(e) => {
                if (e.key === "Enter") findMemberFn();
              }}
              placeholder="Vul het lidnummer in van het te koppelen lid"
              fullWidth
              {...registerField("memberId", { validation: required })}
              onBlur={() => findMemberFn()}
            />
            <Button
              disabled={disable}
              variant="outlined"
              onClick={() => findMemberFn()}
            >
              Zoek
            </Button>
          </Stack>
          <Box height={"26px"}>
            {data && insuredPersonForMemberId == null && (
              <DialogContentText color={"error"}>
                Geen lid gevonden
              </DialogContentText>
            )}
            {insuredPersonForMemberId && (
              <>
                <DialogContentText fontWeight={"medium"}>
                  Gevonden lid: {insuredPersonForMemberId.fullName}
                </DialogContentText>
                {account != null && (
                  <DialogContentText color={"error"}>
                    Dit lid is al gekoppeld aan account {account.username}.
                  </DialogContentText>
                )}
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={disable} variant="outlined" onClick={() => close()}>
            Annuleer
          </Button>
          <Button
            disabled={!(insuredPersonForMemberId && account === null)}
            onClick={() => {
              addMemberToAccount({
                variables: {
                  input: {
                    insuredPersonId: insuredPersonForMemberId.id,
                    accountId,
                  },
                },
              });
            }}
          >
            Koppel lid
          </Button>
        </DialogActions>
      </Dialog>
      <Button onClick={() => setOpen(true)}>Koppel lid</Button>
    </>
  );
};

export const ADD_TO_ACCOUNT_MUTATION = gql(`
mutation addToAccount($input: AddInsuredPersonToAccountInput!) {
    addInsuredPersonToAccount(input: $input) {
      clientMutationId
      account {
          id
      }
    }
  }`);

const FIND_MEMBER_QUERY = gql(`
query findMember($memberId: ID!) {
    insuredPersonForMemberId(memberId: $memberId) {
      id
      memberId
      fullName
      birthdate
      account {
        id
        username
      }
    }
}`);
