//@flow

export type ShowAlertAction = {
  type: "SHOW_ALERT";
  message: string | null | undefined;
  title: string | null | undefined;
};

export type HideAlertAction = {
  type: "HIDE_ALERT";
};

export type Action = ShowAlertAction | HideAlertAction;

/**
 * Show an alert dialog on the screen.
 */
export const showAlertDialog = (
  message?: string | null,
  title?: string | null
): Action => ({
  type: "SHOW_ALERT",
  message,
  title,
});

/**
 * Hide the alert dialog.
 */
export const hideAlertDialog = (): Action => ({
  type: "HIDE_ALERT",
});
