// @flow

import React from "react";
import { withRouter, WithRouterProps } from "react-router";
import { errorHandler, ErrorHandler } from "../../containers";
import Success from "./successReset";
import Reset from "./resetPassword";
import Error from "./error";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
};

type Props = {
  location: {
    query: Object;
  };
  router: {
    push: (route: string) => void;
  };
} & ErrorHandler &
  WithRouterProps;

type State = {
  showSucces: boolean;
};

class ResetPasswordPage extends React.Component<Props, State> {
  state: State = {
    showSucces: false,
  };

  render() {
    const { showSucces } = this.state;
    const { location, router, error } = this.props;

    const { id, username } = location.query;

    if (!id || !username) {
      // Show error
      return (
        <div style={styles.container}>
          <Error onGoHome={() => router.push("/")} />
        </div>
      );
    }

    return (
      <div style={styles.container}>
        {showSucces ? (
          <Success onGoLogin={() => router.push("/inloggen")} />
        ) : (
          <Reset
            username={username}
            resetPasswordKey={id}
            onGoLogin={() => router.push("/inloggen")}
            onSuccess={() => this.setState({ showSucces: true })}
            onError={() => error.display()}
          />
        )}
      </div>
    );
  }
}

export default errorHandler(withRouter(ResetPasswordPage));
