import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  DialogCancelButton,
  DialogConfirmButton,
  EditButton,
} from "components/button/buttons";
import { useState } from "react";
import { FileSelectButton } from "./UploadButton";
import uploadFiles from "screens/communication/api/uploadFiles";
import { BucketType } from "generated/graphql";
import { useMutationForApp } from "utils/withApollo";
import { gql } from "generated";

export const UpdateTemplateButton = () => {
  const [show, setShow] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const [updateAssociation] = useMutationForApp(mutation, {
    onCompleted: () => window.location.reload(),
  });

  let error: string | null = null

  if (file && isDocx(file.name) === false)
    error = "Geselecteerde bestand moet een docx (word) bestand zijn";
  return (
    <>
      <EditButton onClick={() => setShow(true)} />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={show}
        onClose={() => setShow(false)}
      >
        <DialogTitle>Pas ledenkaart template aan</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Selecteer een .docx bestand met daarin het template wat gebruikt
            moet worden om ledenkaarten aan te maken.
          </DialogContentText>
          <FileSelectButton onAddFile={setFile} />
          <br />
          {error ? (
            <Typography color="error">{error}</Typography>
          ) : file ? (
            `Geselecteerd bestand: ${file.name}`
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <DialogCancelButton onClick={() => setShow(false)} />
          <DialogConfirmButton
            disabled={file == null || error != null}
            onClick={async () => {
              const key = (
                await uploadFiles([file], BucketType.Association)
              )[0];
              updateAssociation({
                variables: {
                  input: {
                    membershipCardTemplateKey: key,
                  },
                },
              });
            }}
          >
            Pas aan
          </DialogConfirmButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const isDocx = (filename: string) => filename.split(".").pop() === "docx";

const mutation = gql(`
mutation updateAssociation($input: UpdateAssociationInput!) {
    updateAssociation(input: $input) {
      clientMutationId
    }
  }`);
