// @flow

import { validateApolloResponse } from "../../../api";
import { gql } from "@apollo/client";

async function downloadAddressesLink(client: any): Promise<string> {
  const response = await client.query({
    query: gql`
      {
        downloadAddresses
      }
    `,
  });

  const validatedResponse = validateApolloResponse(response);

  return validatedResponse.downloadAddresses;
}

export default downloadAddressesLink;
