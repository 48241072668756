import {
  Box,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow
} from "@mui/material";
import { EventsTimelineQuery } from "generated/graphql";
import { parseDateTime } from "utils";
import { deserializeEventType } from "utils/formatter";
import "./eventtable.css";

type Props = {
  goToPolicy: (policyId: string) => void;
  data: EventsTimelineQuery["getEvents"]["events"];
  paginationComponent: any;
  loading: boolean;
  seeDescription: boolean;
};

export const EventsTable = (props: Props) => {
  const { goToPolicy, data, seeDescription } = props;

  return (
    <Box sx={{ width: "100%" }}>
      {props.paginationComponent}

      <TableContainer className="fillScreenTable">
        <Table stickyHeader padding="normal" sx={{ minWidth: "650px" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "17%" }}>Soort</TableCell>
              <TableCell sx={{ width: "17%" }}>Datum</TableCell>
              <TableCell sx={{ width: "17%" }}>Lid</TableCell>
              <TableCell sx={{ width: "17%" }}>Doorgevoerd door</TableCell>
              {seeDescription && (
                <TableCell sx={{ width: "32%" }}>Beschrijving</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((event) => {
              const { id, type, description, creator, date, insuredPerson } =
                event;

              let insuredPersonText = insuredPerson
                ? `${insuredPerson.memberId} - ${insuredPerson.fullName}`
                : "";
              const policies = insuredPerson?.policies;

              return (
                <TableRow
                  onClick={() => {
                    if (policies != null && policies.length > 0) {
                      goToPolicy(policies[policies.length - 1].id);
                    }
                  }}
                  key={id}
                  hover
                >
                  <TableCell>{deserializeEventType(type)}</TableCell>
                  <TableCell>{parseDateTime(date)}</TableCell>
                  <TableCell>{insuredPersonText}</TableCell>
                  <TableCell>{creator ? creator.fullName : ""}</TableCell>
                  {seeDescription && (
                    <TableCell
                      sx={{
                        width: "40%",
                        overflow: "hidden",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {description}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
