//@flow
import type { SignupAddressError, ActionsAddress } from "../../actions";
export type State = {
  data: {
    postalCode: string;
    houseNumber: string;
    houseNumberSuffix: string;
    city: string;
    street: string;
  };
  errors: Array<SignupAddressError>;
};

const initialState: State = {
  data: {
    postalCode: "",
    houseNumber: "",
    houseNumberSuffix: "",
    city: "",
    street: "",
  },
  errors: [],
};

const StepperAddress = (
  state: State = initialState,
  action: ActionsAddress
): State => {
  switch (action.type) {
    case "ADD_SIGNUP_POSTALCODE": {
      return {
        ...state,
        data: {
          ...state.data,
          postalCode: action.postalCode,
        },
        errors: state.errors.filter(
          (element) =>
            ["EMPTY_POSTAL_CODE_ERROR", "INVALID_POSTAL_CODE_ERROR"].indexOf(
              element
            ) === -1
        ),
      };
    }
    case "ADD_SIGNUP_HOUSENUMBER": {
      return {
        ...state,
        data: {
          ...state.data,
          houseNumber: action.houseNumber,
        },
        errors: state.errors.filter(
          (element) =>
            ["EMPTY_HOUSE_NUMBER_ERROR", "INVALID_HOUSE_NUMBER_ERROR"].indexOf(
              element
            ) === -1
        ),
      };
    }
    case "ADD_SIGNUP_HOUSENUMBER_SUFFIX": {
      return {
        ...state,
        data: {
          ...state.data,
          houseNumberSuffix: action.houseNumberSuffix,
        },
      };
    }
    case "ADD_SIGNUP_CITY": {
      return {
        ...state,
        data: {
          ...state.data,
          city: action.city,
        },
        errors: state.errors.filter(
          (element) => ["EMPTY_CITY_ERROR"].indexOf(element) === -1
        ),
      };
    }
    case "ADD_SIGNUP_STREET": {
      return {
        ...state,
        data: {
          ...state.data,
          street: action.street,
        },
        errors: state.errors.filter(
          (element) => ["EMPTY_STREET_ERROR"].indexOf(element) === -1
        ),
      };
    }
    case "UPDATE_ERRORS_SIGNUP_ADDRESS": {
      return {
        ...state,
        errors: action.errors,
      };
    }
    default:
      return state;
  }
};

export default StepperAddress;
