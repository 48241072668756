// @flow

import React from "react";
import { colorsBCV } from "../styling";

const style: { [key: string]: React.CSSProperties } = {
  link: {
    color: colorsBCV.secondary,
  },
};

// type Props = PropsWithChildren & { title?: string, href?: string, rel?: string, target?: string, style?:  {[key: string]: React.CSSProperties} }

const Link = (props: any) => (
  <a {...props} style={{ ...props.style, ...style.link }}>
    {props.children}
  </a>
);

export default Link;
