//@flow

import React, { Component } from "react";
import { gql } from "@apollo/client";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import RaisedButton from "material-ui/RaisedButton";
import MenuItem from "material-ui/MenuItem";
import PackagePicker from "./../signupPremiumComponents/packagePicker";
import type { PremiumError } from "../../../actions";
import type { Dispatch, State as ReduxState } from "../../../reducers";
import { connect, ConnectedProps } from "react-redux";
import { increaseStepperIndex } from "../../../actions";
import { formatToEuro } from "../../../utils";
import {
  addSignupBirthDate,
  addSignupPackageId,
  addSignupFrequency,
  calculatePremium,
} from "../../../actions";
import { VerticalSeparator } from "../../../components/layout";
import { Frequency } from "generated/graphql";
import { formatFrequency2 } from "utils/formatter";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: "350px",
  },
  customWidth: { width: 350 },
  packageError: {
    width: "100%",
    justifyContent: "left",
    paddingTop: "5px",
    color: "red",
    height: "15px",
    fontSize: "12px",
  },
  valueSelecter: {
    color: "grey",
    textAlign: "left",
    alignItems: "left",
    justifyContent: "left",
  },
  buttonContainer: {
    width: "100%",
    textAlign: "center",
    paddingTop: 30,
  },
};

type ErrorMessages = {
  birthError: string;
  packageError: string;
  frequencyError: string;
};

type Quote =
  | {
      quoteExists: true;
      data: {
        quoteId: string;
        birthDate: string;
        premium: number;
        lumpsum: number;
        frequency: Frequency;
        packageId: string;
        packageName: string;
        insuredAmount: number;
      };
    }
  | {
      quoteExists: false;
    };

type Props = ReduxProps & {
  data: {
    packages: Array<{
      id: string;
      name: string;
      insuredAmount: number;
      frequencies: Array<Frequency>;
    }>;
  };
};

const getErrorMessages = (errors: Array<PremiumError>): ErrorMessages => {
  return {
    birthError:
      errors.indexOf("BIRTH_ERROR") < 0
        ? ""
        : "Er is geen valide geboortedatum opgegeven. Geef een datum uit het verleden op in format DD-MM-JJJJ",
    packageError:
      errors.indexOf("PACKAGE_ERROR") < 0 ? "" : "Er is geen pakket gekozen.",
    frequencyError:
      errors.indexOf("FREQUENCY_ERROR") < 0
        ? ""
        : "Er is geen betaalperiode gekozen.",
  };
};

const formatPremiumLowercase = (frequency: Frequency) =>
  formatFrequency2(frequency).toLocaleLowerCase();

class PremiumSignupStepper extends Component<Props> {
  _handleChangePacket = (event: Event, value: string) => {
    this.props.setFrequency(null);
    this.props.setPackageId(value);
  };

  _handleChangeFrequency = (event: any, index: number, value: Frequency) =>
    this.props.setFrequency(value);

  _handleChangeBirthDate = (event: any) =>
    this.props.setBirthDate(event.target.value);

  render() {
    const {
      birthDate,
      packageId,
      quote,
      data: { packages: packets },
    } = this.props;
    const errorMessages = getErrorMessages(this.props.errors);
    const { birthError, packageError } = errorMessages;

    return (
      <div style={styles.container}>
        <TextField
          data-test="birthdate_textfield"
          defaultValue={birthDate}
          floatingLabelText="Wat is de geboortedatum van het nieuwe lid?"
          hintText="DD-MM-JJJJ"
          style={styles.customWidth}
          floatingLabelStyle={styles.valueSelecter}
          inputStyle={styles.valueSelecter}
          onChange={(e) => this._handleChangeBirthDate(e)}
          onKeyDown={(event: any) => {
            if (event.key === "Enter" && this.props.packageId) {
              quote
                ? this.props.nextPressed()
                : this.props.calculatePremium(
                    this.props.birthDate,
                    this.props.packageId,
                    this.props.frequency
                  );
            }
          }}
          errorText={birthError}
          errorStyle={{ color: "red" }}
        />
        <PackagePicker
          selectedPackage={packageId}
          onChange={this._handleChangePacket}
          packages={packets}
        />
        <div style={styles.packageError}>{packageError}</div>
        {this._getFrequencySelectField()}
        <div style={styles.buttonContainer}>
          {quote
            ? this._getNextButton(quote)
            : this._getCalculatePremiumButton()}
        </div>
      </div>
    );
  }

  _getPackages() {
    return this.props.data.packages;
  }

  _getFrequencySelectField() {
    const { packageId, frequency } = this.props;

    const { frequencyError } = getErrorMessages(this.props.errors);

    if (!packageId) {
      return null;
    }

    const selectedPackage = this._getPackages().find(
      ({ id }) => id === packageId
    );

    if (!selectedPackage) {
      throw new Error(`No package for selected package id ${packageId}`);
    }

    const menuItems = selectedPackage.frequencies.map((freq, index) => (
      <MenuItem
        value={freq}
        label={formatFrequency2(freq)}
        key={index}
        primaryText={formatFrequency2(freq)}
      />
    ));

    return (
      <SelectField
        data-test="frequency_select"
        value={frequency}
        onChange={this._handleChangeFrequency}
        floatingLabelText="Per welke tijdsperiode wilt u betalen?"
        floatingLabelStyle={styles.valueSelecter}
        labelStyle={styles.valueSelecter}
        style={styles.customWidth}
        errorText={frequencyError}
        errorStyle={{ color: "red" }}
      >
        {menuItems}
      </SelectField>
    );
  }

  _getCalculatePremiumButton() {
    return (
      <RaisedButton
        data-test="calculate_contribution_button"
        label="Bepaal contributie"
        disableTouchRipple={true}
        disableFocusRipple={true}
        primary={true}
        onClick={() => {
          const { birthDate, frequency, packageId } = this.props;
          this.props.calculatePremium(birthDate, packageId, frequency);
        }}
      />
    );
  }

  _getNextButton(quote: Quote) {
    if (quote.quoteExists) {
      const { data } = quote;

      let lumpsumText;

      if (data.lumpsum > 0) {
        lumpsumText = <p>De koopsom is {formatToEuro(data.lumpsum)}</p>;
      }
      return (
        <div>
          <p>
            De contributie is {formatToEuro(data.premium)}{" "}
            {formatPremiumLowercase(data.frequency)}.
          </p>
          {lumpsumText}
          <VerticalSeparator distance={3} />
          <RaisedButton
            label="Volgende"
            data-test="next_step_premium_button"
            disableTouchRipple={true}
            disableFocusRipple={true}
            primary={true}
            onClick={this.props.nextPressed}
          />
        </div>
      );
    } else {
      return (
        <p>
          Gegeven de opgegeven leeftijd is het niet mogelijk om een standaard
          contributie uit te rekenen. Neem alstublieft contact met ons op voor
          meer informatie.
        </p>
      );
    }
  }
}

const mapStateToProps = (state: ReduxState) => {
  const {
    signup: { stepper, premium },
  } = state;
  return {
    index: stepper.stepperIndex,
    birthDate: premium.policyInfo.birthDate,
    packageId: premium.policyInfo.packageId,
    frequency: premium.policyInfo.frequency,
    quote: premium.policyInfo.quote,
    errors: premium.errors,
    isFetching: premium.isFetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setBirthDate: (birthDate: string) => dispatch(addSignupBirthDate(birthDate)),
  setPackageId: (packageId: string) => dispatch(addSignupPackageId(packageId)),
  setFrequency: (frequency: Frequency | null) =>
    dispatch(addSignupFrequency(frequency)),
  calculatePremium: (
    birthDate: string,
    packageId: string | null,
    frequency: Frequency | null
    // @ts-ignore
  ): void => dispatch(calculatePremium(birthDate, packageId, frequency)),
  nextPressed: () => dispatch(increaseStepperIndex()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(PremiumSignupStepper);

export const fragment = gql`
  fragment SignupPremiumFragment on Association {
    packages(state: ACTIVE) {
      id
      name
      insuredAmount
      frequencies
    }
  }
`;
