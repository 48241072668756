// @flow

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export enum MatchType {
  EXACT = "EXACT",
  STARTS_WITH = "STARTS_WITH",
}

type Props = {
  selectedFilter: MatchType;
  onChangeFilter: (selectedFilter: MatchType) => void;
};

const MatchTypeSelector = (props: Props) => {
  return (
    <FormControl variant="standard" sx={{ width: "256px" }}>
      <InputLabel>Zoektype</InputLabel>
      <Select
        value={props.selectedFilter}
        onChange={(event) =>
          props.onChangeFilter(event.target.value as MatchType)
        }
      >
        <MenuItem value={MatchType.EXACT}>Is gelijk aan</MenuItem>
        <MenuItem value={MatchType.STARTS_WITH}>Start met</MenuItem>
      </Select>
    </FormControl>
  );
};
export default MatchTypeSelector;
