//@flow
/**
 * Created by Joshua Scheidt on 31-01-2017.
 */
import React from "react";
import { Link, withRouter, WithRouterProps } from "react-router";
import PolicyList from "./policyList";
import Header from "../../../containers/ledenPortaal/header/clientHeader";
import Navbar from "../../../containers/ledenPortaal/header/navbar";
import Loader from "../../loader";
import FloatingActionButton from "material-ui/FloatingActionButton";
import ContentAdd from "material-ui/svg-icons/content/add";
import { filter } from "graphql-anywhere";
import { ActivateAccountPrompt } from "../components";
import config, { AssociationType } from "../../../config";
import { DataProps } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import { MyPoliciesQuery, MyPoliciesQueryVariables } from "generated/graphql";
import { graphqlQuery } from "utils/withApollo";
const { association } = config;

const styles: { [key: string]: React.CSSProperties } = {
  outerContainer: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "100px",
  },
  floatingActionButton: {
    position: "fixed",
    bottom: 50,
    right: 50,
  },
  greeting: { fontSize: "36px" },
};

type Props = DataProps<MyPoliciesQuery, MyPoliciesQueryVariables> &
  WithRouterProps;

export const PolicyPage = (props: Props) => {
  const { loading, error, viewer } = props.data;
  let currenthour = new Date(Date.now()).getHours();
  let message = "";
  if (currenthour < 6) message = "Goedenacht ";
  else if (currenthour < 12) message = "Goedemorgen ";
  else if (currenthour < 18) message = "Goedendag ";
  else message = "Goedenavond ";
  return (
    <div>
      <Header nameHeader="Mijn lidmaatschappen" />
      <Navbar />
      <Loader isFetching={loading} error={error} fullScreen={true}>
        {viewer ? (
          <div style={styles.outerContainer}>
            <div style={styles.greeting}>
              {message}
              {viewer.fullName}!
            </div>
            <p>
              Uw email en telefoonnummer zijn <b>{viewer.username}</b> en{" "}
              <b>{viewer.phone}</b>. U kunt dit{" "}
              <b>
                <Link to="/account">hier</Link>
              </b>{" "}
              aanpassen.
            </p>
            <div
              style={{
                fontSize: "24px",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              Ledenoverzicht
            </div>
            <PolicyList
              data={filter(PolicyList.fragments.policyListInfo, viewer)}
            />
            {viewer.verified ? null : <ActivateAccountPrompt />}
          </div>
        ) : null}
      </Loader>
      {association === AssociationType.BCB && (
        <FloatingActionButton
          style={styles.floatingActionButton}
          onClick={() => props.router.push("/account/nieuw-lid")}
        >
          <ContentAdd />
        </FloatingActionButton>
      )}
    </div>
  );
};

export const USER_QUERY = gql`
  query myPolicies {
    viewer {
      id
      verified
      username
      phone
      fullName
      ...PolicyListInfo
    }
  }
  ${PolicyList.fragments.policyListInfo}
`;
const Wrapped = graphqlQuery<{}, MyPoliciesQuery, MyPoliciesQueryVariables>(
  USER_QUERY
)(withRouter(PolicyPage));

export default Wrapped;
