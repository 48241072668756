// @flow

import React from "react";
import RaisedButton from "material-ui/RaisedButton";
import { colors } from "../../../../../styling";

import { Title, NavButtonContainer } from "../../../../../components/dialog";
import { DialogContainer2 } from "components/dialog/container";
const { errorRed, white } = colors;

type Props = {
  onCancel: () => void;
  confirmDelete: () => Promise<void>;
};

const DeleteConfirmationDialog = (props: Props) => {
  return (
    <DialogContainer2>
      <Title>Verwijder Lid</Title>
      <div style={{ paddingBottom: "30px" }}>
        <b>
          Door op bevestig te klikken wordt alle informatie van dit lid
          permanent verwijderd
        </b>
        . Dit kan niet ongedaan worden gemaakt. Het account waaraan dit lid
        gekoppeld is wordt niet verwijderd.
        <br />
        <br />
        Als u het lid wilt verwijderen druk op <i>bevestig</i>, anders druk op{" "}
        <i>annuleer</i>.
      </div>
      <NavButtonContainer>
        <RaisedButton
          data-test="cancel"
          label="Annuleer"
          onClick={props.onCancel}
        />
        <RaisedButton
          data-test="confirm"
          label="Bevestig"
          onClick={props.confirmDelete}
          backgroundColor={errorRed}
          labelColor={white}
        />
      </NavButtonContainer>
    </DialogContainer2>
  );
};

export default DeleteConfirmationDialog;
