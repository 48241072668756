// @flow

import React from "react";
import DefaultPage from "../components/defaultPage";
import ContactBCB from "./contactBCB";
import ContactPietersrade from "./contactPietersrade";
import ContactHulpEnSteunPage from "./contactHulpEnSteun";
import config, { AssociationType } from "../../../config";

const { association } = config;

const ContactPage = () => {
  let contactContent;
  switch (association) {
    case AssociationType.BCB:
      contactContent = <ContactBCB />;
      break;
    case AssociationType.PIETERSRADE:
      contactContent = <ContactPietersrade />;
      break;
    case AssociationType.HULP_EN_STEUN:
      contactContent = <ContactHulpEnSteunPage />;
      break;
    default:
      throw new Error(`Unknown association type ${association}`);
  }

  return <DefaultPage>{contactContent}</DefaultPage>;
};

export default ContactPage;
