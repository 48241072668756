// @flow

import config from "config";
import { gql } from "generated";
import { clientNew } from "./apollo2";

/**
 * Sends the forgot password mutation
 * @param username the username for which the forgot password call is performed.
 * @param redirectUrl the url containing the reset password page.
 * @returns Flag whether the username exists or not
 */
const forgotPasswordAPI = async (
  username: string,
  redirectUrl: string
): Promise<boolean> => {
  const response = await clientNew.mutate({
    mutation: gql(`
      mutation forgot($input: ForgotPasswordInput!) {
        forgotPassword(input: $input) {
          exists
        }
      }
    `),
    variables: {
      input: {
        username,
        redirectUrl,
        associationId: config.associationId
      },
    },
  });

  return response.data.forgotPassword.exists;
};

export default forgotPasswordAPI;
