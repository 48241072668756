// @flow

import { Frequency } from "generated/graphql";
import type { ActionsPremium, PremiumError } from "../../actions";

type Quote =
  | {
      quoteExists: true;
      data: {
        quoteId: string;
        birthDate: string;
        premium: number;
        lumpsum: number;
        frequency: Frequency;
        packageId: string;
        packageName: string;
        insuredAmount: number;
      };
    }
  | {
      quoteExists: false;
    }
  | null;

export type State = {
  errors: Array<PremiumError>;
  isFetching: boolean;
  policyInfo: {
    quote: Quote;
    birthDate: string;
    frequency: Frequency | null;
    packageId: string | null;
  };
};

const initialState: State = {
  errors: [],
  isFetching: false,
  policyInfo: {
    quote: null,
    birthDate: "",
    frequency: null,
    packageId: null,
  },
};

const premiumReducer = (state = initialState, action: ActionsPremium) => {
  switch (action.type) {
    case "ADD_SIGNUP_BIRTHDATE": {
      return {
        ...state,
        policyInfo: {
          ...state.policyInfo,
          birthDate: action.birthDate,
          quote: null,
        },
        errors: state.errors.filter((element) => element !== "BIRTH_ERROR"),
      };
    }
    case "ADD_SIGNUP_PACKAGEID": {
      return {
        ...state,
        policyInfo: {
          ...state.policyInfo,
          packageId: action.packageId,
          quote: null,
        },
        errors: state.errors.filter((element) => element !== "PACKAGE_ERROR"),
      };
    }
    case "ADD_SIGNUP_FREQUENCY": {
      return {
        ...state,
        policyInfo: {
          ...state.policyInfo,
          frequency: action.frequency,
          quote: null,
        },
        errors: state.errors.filter((element) => element !== "FREQUENCY_ERROR"),
      };
    }
    case "ADD_SIGNUP_QUOTE": {
      const { quoteExists, quote } = action.data;

      let quoteData;

      if (quoteExists) {
        if (!quote) {
          throw new Error(
            `Expected quote from GraphQL response but got ${String(quote)}`
          );
        }
        quoteData = {
          quoteExists: true,
          data: {
            quoteId: quote.id,
            birthDate: quote.birthdate,
            premium: quote.premium,
            lumpsum: quote.lumpsum,
            frequency: quote.frequency,
            packageId: quote.package.id,
            packageName: quote.package.name,
            insuredAmount: quote.package.insuredAmount,
          },
        };
      } else {
        quoteData = {
          quoteExists: false,
        };
      }
      return {
        ...state,
        policyInfo: {
          ...state.policyInfo,
          // @ts-ignore
          quote: quoteData,
        },
      };
    }
    case "UPDATE_ERRORS_SIGNUP_PREMIUM": {
      return {
        ...state,
        errors: action.errors,
      };
    }
    default:
      return state;
  }
};

export default premiumReducer;
