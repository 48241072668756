import { Button } from "@mui/material";

export const FilterGhostAccounts = ({
  ghostAccountsOn,
  toggle,
}: {
  ghostAccountsOn: boolean;
  toggle: () => void;
}) => (
  <Button onClick={() => toggle()} variant="outlined" color="inherit">
    {ghostAccountsOn ? "Bekijk alle accounts" : "Bekijk accounts zonder lid"}
  </Button>
);
