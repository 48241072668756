// @flow

import React from "react";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import { colors } from "../../../../../styling";

export enum PremiumInput {
  STANDARD = "STANDARD",
  CUSTOM = "CUSTOM",
}

export const PremiumInputPicker = ({
  selected,
  onChange,
}: {
  selected: PremiumInput;
  onChange: (selected: PremiumInput) => void;
}) => {
  return (
    <RadioButtonGroup
      // @ts-ignore
      id="group"
      name="createClient"
      valueSelected={selected}
      onChange={(event: any) => onChange(event.target.value)}
    >
      <RadioButton
        id="standard"
        value={PremiumInput.STANDARD}
        label="Premie via premietabel berekenen"
        style={{ paddingBottom: "10px" }}
        iconStyle={{ fill: colors.green }}
      />
      <RadioButton
        id="custom"
        value={PremiumInput.CUSTOM}
        label="Aangepaste premie opgeven"
        iconStyle={{ fill: colors.green }}
      />
    </RadioButtonGroup>
  );
};
