// @flow

/**
 * Created by Jim Bemelen and Joshua Scheidt on 5-10-2016.
 */

import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { colorArray } from "../../../styling";

type PropType = {
  /**
   * The data array for the line chart component
   */
  data: Array<{
    name: string;
    [key: string]: any;
  }>;
  /**
   * Method used for formatting the values in the bar chart into strings. This is used
   * for formatting the values in the tooltip and the y-axis.
   */
  valueFormatter?: (value: number) => string;

  //Number of x axis ticks (string) per label. If set 0, all the ticks will be shown.
  xAxisInterval?: number;
  //Y axis: Object that specifies the minimum and maximum y axis value labels that should be rendered on the y-axis of the line chart,
  //if not specified, the line chart component automatically calculates the min and max value.
  yAxis?: {
    min?: number;
    max?: number;
  };
};

/**
 * Finds all the lines which should be drawn from the given data set.
 *
 * @param data the data set
 * @returns {Array} of lines
 *
 * @author Joshua Scheidt
 */
function _getLines(data: Array<Object>): Array<React.ReactElement> {
  var keys = Object.keys(data[0]);
  var lines = [];

  var count = 0;
  for (var i = 0; i < keys.length; i++) {
    if (keys[i] !== "name") {
      lines[count] = (
        <Line
          type="linear"
          // @ts-ignore
          strokeWidth={1.7}
          dot={false}
          dataKey={keys[i]}
          stroke={colorArray[count]}
          key={i}
        />
      );
      count++;
    }
  }
  return lines;
}

/**
 * The KC line chart.
 */
const KCLineChart = (props: PropType) => {
  let { data, valueFormatter } = props;
  //Translate the data into Line components.
  let lines = _getLines(data);
  //Construct a Legend if and only if the number of lines is larger than 1
  let legend =
    lines.length > 1 ? (
      <Legend
        verticalAlign="top"
        height={30}
        align="right"
        iconSize={22}
        wrapperStyle={{ fontSize: 10 }}
      />
    ) : null;

  const interval = props.xAxisInterval != null ? props.xAxisInterval : 1;
  const yMin =
    props.yAxis && props.yAxis.min != null ? props.yAxis.min : "auto";
  const yMax =
    props.yAxis && props.yAxis.min != null ? props.yAxis.max : "auto";

  return (
    // @ts-ignore
    <ResponsiveContainer>
      {/* @ts-ignore */}
      <LineChart
        data={data}
        margin={{ top: 10, right: 50, left: 20, bottom: 15 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        {/* @ts-ignore */}
        <Tooltip formatter={valueFormatter} />
        {/* @ts-ignore */}
        <XAxis dataKey="name" interval={interval} fontSize={10} />
        <YAxis
          domain={[yMin, yMax]}
          unit={100}
          fontSize={10}
          // @ts-ignore
          tickFormatter={valueFormatter}
        />
        {legend}
        {lines}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default KCLineChart;
