// @flow
import { AccountPermission } from "../api";
import { LoginAction, LoginErrorEnum } from "../actions";
import { loggedIn, getPermissions } from "../utils";
export type State = {
  // The username in the login screen
  username: string;
  // The password in the login screen field
  password: string;
  // A boolean indicating whether we are authenticating with the server
  loading: boolean;
  // Boolean indicating whether the user is logged in.
  loggedIn: boolean;
  // The permissions of the user that is currently logged in.
  permissions: Array<AccountPermission>;
  // Any errors that occur during signup.
  error: LoginErrorEnum | null;
};

const permissions = getPermissions();

const initialState: State = {
  username: "",
  password: "",
  loading: false,
  loggedIn: loggedIn(),
  permissions: permissions ? permissions : [],
  error: null,
};

const loginReducer = (
  state: State = initialState,
  action: LoginAction
): State => {
  switch (action.type) {
    case "UPDATE_AUTH":
      return {
        ...state,
        loggedIn: action.loggedIn,
        permissions: action.permissions,
      };
    case "UPDATE_USERNAME":
      return {
        ...state,
        username: action.newState,
      };
    case "UPDATE_PASSWORD":
      return {
        ...state,
        password: action.newState,
      };
    case "UPDATE_LOADING":
      return {
        ...state,
        loading: action.newState,
      };
    case "SHOW_LOGIN_ERROR":
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default loginReducer;
