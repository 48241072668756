import Link from "../../../link";
import ExpandablePage from "../../components/expandablePageSection";

const DeadOutsideWorkingArea = () => (
  <ExpandablePage title="Overlijden buiten het werkgebied">
    <p>
      Als vereniging mogen wij geen geldsommen vergoeden aan leden ten behoeve
      van een begrafenis of crematie.*
    </p>
    <p>
      Wij maken het bedrag (behorend bij pakket A, B of C) over aan de
      uitvaartondernemer, mits:
    </p>
    <ul>
      <li>
        de vereniging een kopie van het uittreksel uit de overlijdensakte (van
        de betreffende overledene) heeft ontvangen;
      </li>
      <li>
        de vereniging een kopie-nota van de uitvaart van de betreffende
        overledene heeft ontvangen;
      </li>
      <li>de vereniging een akte van cessie heeft ontvangen.</li>
    </ul>
    <p>
      Uiteraard kan de uitvaart vervolgens met de uitvaartondernemer ter plaatse
      worden afgewikkeld.
    </p>
    * Bij overlijden in het buitenland: gelieve voor de pakketvergoeding contact
    op nemen met de penningmeester {"("}
    <Link href="mailto:bcb.penningmeester@gmail.com">
      bcb.penningmeester@gmail.com
    </Link>
    {")"}.
  </ExpandablePage>
);
export default DeadOutsideWorkingArea;
