// @flow

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

type Props = {
  open: boolean;
  onClose: () => void;
  confirmDelete: () => Promise<void>;
};

const DeleteConfirmationDialog = (props: Props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Verwijder Account</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <b>
            Door op bevestig te klikken wordt dit account permanent verwijderd.
          </b>{" "}
          Deze actie kan niet ongedaan worden gemaakt. De leden die gekoppeld
          zijn aan het account worden niet verwijderd.
          <br />
          <br />
          Als u het account definitief wilt verwijderen druk dan op{" "}
          <i>bevestig</i>, druk anders op <i>annuleer</i>.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => props.onClose()}>
          Annuleer
        </Button>
        <Button onClick={props.confirmDelete} color="error">
          Bevestig
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
