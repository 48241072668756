// @flow
/**
 * Created by Joshua Scheidt on 01-02-2017
 */

import React from "react";
import PieChart from "../charts/pieChart";
import { gql } from "@apollo/client";
import { parseDate } from "../../../utils";

type AssetInfo = {
  node: {
    name: string;
    value: number;
    category: string;
  };
};

type Props = {
  data: {
    date: string;
    assets: {
      edges: Array<AssetInfo>;
    };
  };
};

function transformData(data: {
  date: string;
  assets: { edges: Array<AssetInfo> };
}) {
  let aggregateArray = [];
  if (data.assets.edges != null && data.assets.edges.length > 0) {
    let array = [];
    for (var i = 0; i < data.assets.edges.length; i++) {
      array.push({
        name: data.assets.edges[i].node.category,
        value: data.assets.edges[i].node.value,
      });
    }

    let aggregateObject: any = {};
    array.forEach((item) => {
      if (aggregateObject[item.name]) aggregateObject[item.name] += item.value;
      else aggregateObject[item.name] = item.value;
    });

    for (var key in aggregateObject) {
      aggregateArray.push({ name: key, value: aggregateObject[key] });
    }
  }
  return aggregateArray;
}

const AssetPortfolio = (props: Props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <span>{parseDate(props.data.date)}</span>
      <div style={{ flex: 1, width: "100%" }}>
        <PieChart data={transformData(props.data)} />
      </div>
    </div>
  );
};

export default AssetPortfolio;

export const AssetPortfolioFragment = gql`
  fragment assetPortfolio on AssetPortfolio {
    id
    date
    currency
    assets {
      edges {
        node {
          id
          name
          value
          category
        }
      }
    }
  }
`;
