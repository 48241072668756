import { MutationHookOptions } from "@apollo/client";
import { gql } from "generated";
import {
  UpdateFrequencyMutationMutation,
  UpdateFrequencyMutationMutationVariables,
} from "generated/graphql";
import { useMutationForApp } from "utils/withApollo";

const UPDATE_POLICY_MUTATION = gql(`
mutation updateFrequencyMutation($input: UpdatePolicyInput!) {
  updatePolicy(input: $input) {
    policy {
      id
      premium
      frequency
    }
  }
}
`);

export const useUpdatePolicyMutation = (
  options?: MutationHookOptions<
    UpdateFrequencyMutationMutation,
    UpdateFrequencyMutationMutationVariables
  >
) =>
  useMutationForApp<
    UpdateFrequencyMutationMutation,
    UpdateFrequencyMutationMutationVariables
  >(UPDATE_POLICY_MUTATION, options);
