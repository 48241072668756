import { EventType } from "generated/graphql";
import { useEffect } from "react";
import { WithRouterProps } from "react-router";
import { allEventTypes } from "./components/EventTypeSelectionDialog";

enum EventTimelinePageParams {
  PAGE = "page",
  FIRST = "first",
  FILTER = "filter",
  SHOW_DESCRIPTION = "description",
}

export const useUpdateQueryParam = (
  page: number,
  first: number,
  filter: EventType[],
  seeDescription: boolean,
  { router, location }: WithRouterProps
) => {
  useEffect(() => {
    router.push({
      pathname: location.pathname,
      query: {
        [EventTimelinePageParams.PAGE]: page,
        [EventTimelinePageParams.FIRST]: first,
        [EventTimelinePageParams.FILTER]: JSON.stringify(filter),
        [EventTimelinePageParams.SHOW_DESCRIPTION]:
          JSON.stringify(seeDescription),
      },
    });
    // eslint-disable-next-line
  }, [page, first, filter, seeDescription]);
};

export const getInitialState = ({ location }: WithRouterProps) => {
  const getQueryParam = (name: EventTimelinePageParams) =>
    location && location.query && location.query[name]
      ? location.query[name]
      : "";

  const page = getQueryParam(EventTimelinePageParams.PAGE);
  const first = getQueryParam(EventTimelinePageParams.FIRST);
  const filter = getQueryParam(EventTimelinePageParams.FILTER);
  const seeDescription = getQueryParam(
    EventTimelinePageParams.SHOW_DESCRIPTION
  );

  return {
    page: page != null ? Number(page) : 0,
    first: first ? Number(first) : 25,
    filter: filter ? (JSON.parse(filter) as EventType[]) : allEventTypes,
    seeDescription: seeDescription
      ? (JSON.parse(seeDescription) as boolean)
      : false,
  };
};
