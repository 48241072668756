//@flow

import React, { Component } from "react";
import { gql } from "@apollo/client";

import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import SignupEligibilityQuestion, {
  EligibilityQuestionsComponent,
} from "./signupEligibilityQuestion";
import { setEligibilityState } from "../../../actions";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "../../../reducers";

type PropsParent = {
  backPressed: () => void;
  data: {
    eligibilityQuestions: Array<{
      id: string;
      question: string;
    }>;
  };
};
type Props = PropsParent & PropsRedux;

export class EligibilityQuestions extends Component<Props> {
  eligibilityChild: EligibilityQuestionsComponent | null | undefined = null;
  constructor(props: Props) {
    super(props);
    this._nextPressed = this._nextPressed.bind(this);
  }

  _nextPressed() {
    if (this.eligibilityChild) {
      try {
        const answers = this.eligibilityChild.validateAnswers();
        this.props.nextPressed(answers);
      } catch (err) {
        //Do nothing
      }
    }
  }

  render() {
    return (
      <div style={{ maxWidth: 350 }}>
        <h2>Gezondsheidsverklaring</h2>
        <SignupEligibilityQuestion
          questions={this.props.data.eligibilityQuestions}
          onRef={(input) => {
            this.eligibilityChild = input;
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "20px",
          }}
        >
          <div style={{ flex: 1, textAlign: "center" }}>
            <FlatButton
              label="Vorige"
              disableTouchRipple={true}
              disableFocusRipple={true}
              onClick={this.props.backPressed}
            />
          </div>
          <div style={{ flex: 1, textAlign: "center" }}>
            <RaisedButton
              label="Volgende"
              data-test="next_step_eligibility_button"
              disableTouchRipple={true}
              disableFocusRipple={true}
              primary={true}
              onClick={this._nextPressed}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // @ts-ignore
  nextPressed: (input: Array<Answer>) => dispatch(setEligibilityState(input)),
});

const connector = connect(null, mapDispatchToProps);
const Wrapper = connector(EligibilityQuestions);

type PropsRedux = ConnectedProps<typeof connector>;

export default Wrapper;

export const fragment = gql`
  fragment EligibilityQuestionFragment on Association {
    id
    eligibilityQuestions {
      question
      id
    }
  }
`;
