import React from "react";
import DefaultPage from "../../components/defaultPage";
import TasksBoard from "./tasksBoard";
import DOWA from "./deadOutsideWorkingArea";
import Royeren from "./royeren";
import VermisteLeden from "./vermisteLeden";
import OpzeggenLidmaatschap from "./opzeggenLidmaatschap";
import Financien from "./financien";
import Statuten from "./statuten";
import QA from "./QA";
import Privacy from "./privacy";

const InformationPageBCB = () => {
  return (
    <DefaultPage>
      <br />
      <br />
      <h3>
        In onderstaande secties kunt u informatie vinden over onze vereniging. U
        kunt de informatie zichtbaar maken door op de oranje kopteksten te
        klikken van de sectie die u wil lezen.
      </h3>
      <TasksBoard />
      <DOWA />
      <Royeren />
      <VermisteLeden />
      <OpzeggenLidmaatschap />
      <Financien />
      <Privacy />
      <Statuten />
      <QA />
    </DefaultPage>
  );
};

export default InformationPageBCB;
