// @flow

import { createStore, applyMiddleware, Store } from "redux";
import thunk from "redux-thunk";
import RavenMiddleware from "redux-raven-middleware";
import config from "./config";

import "./index.css";
import { reducer, State } from "./reducers";

/**
 * We export the store here because we need to reuse the same instance across multiple apollo providers.
 * Currently apollo does not allow us to make queries to multiple endpoints. Therefore, we are forced to create
 * multiple ApolloProvider instances in our code, we need to make sure that these make use of the same redux store.
 */
let middleware;

if (config.environment === "test") {
  middleware = applyMiddleware(thunk);
} else {
  middleware = applyMiddleware(
    // @ts-ignore
    RavenMiddleware(config.logging.sentry_dsn),
    thunk
  );
}

export const store: Store<State> = createStore(reducer, middleware);
