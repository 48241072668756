// @flow

import React from "react";
import { resetPassword } from "../../api";
import TextField from "material-ui/TextField";
import { colorsBCV as colors } from "../../styling";
import CircularProgress from "material-ui/CircularProgress";
import { isValidPassword } from "../../utils";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  } as React.CSSProperties,
  title: {
    fontSize: 30,
    fontWeight: "100",
  },
  fieldContainer: {
    width: "300px",
  },
  button: {
    backgroundColor: "white",
    border: "1px solid",
    borderColor: colors.secondary,
    color: colors.secondary,
    height: 30,
    fontSize: "14px",
  },
};

type Props = {
  onGoLogin: () => void;
};

const AlreadyResetError = ({ onGoLogin }: Props) => (
  <div style={styles.container}>
    <span style={styles.title}>
      U hebt uw wachtwoord al eerder geupdate. <br />U kunt een nieuw wachtwoord
      opvragen via de login pagina.
    </span>
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }} />
    <button style={{ ...styles.button, width: "150px" }} onClick={onGoLogin}>
      Ga naar login
    </button>
  </div>
);

type FieldError =
  | "NOT_DUPLICATE_PASS"
  | "INVALID_PASS"
  | "MISSING_FIELD"
  | "ALREADY_RESET";

type State = {
  error: FieldError | null;
  newPass: string;
  confirmPass: string;
  loading: boolean;
};

type PropsResetPasswordPage = {
  onGoLogin: () => void;
  onSuccess: () => void;
  onError: () => void;
  username: string;
  resetPasswordKey: string;
};

export default class ResetPasswordPage extends React.Component<
  PropsResetPasswordPage,
  State
> {
  state: State = {
    error: null,
    newPass: "",
    confirmPass: "",
    loading: false,
  };

  reset = async () => {
    const { newPass, confirmPass } = this.state;
    const { username, resetPasswordKey, onSuccess, onError } = this.props;

    if (newPass.length === 0 || confirmPass.length === 0) {
      this.setState({ error: "MISSING_FIELD" });
      return;
    }

    if (!isValidPassword(newPass)) {
      this.setState({
        error: "INVALID_PASS",
      });
      return;
    }

    if (newPass !== confirmPass) {
      this.setState({ error: "NOT_DUPLICATE_PASS" });
      return;
    }

    this.setState({ loading: true });
    try {
      const active = await resetPassword(username, resetPasswordKey, newPass);
      if (active === true) {
        onSuccess();
      } else {
        this.setState({
          loading: false,
          error: "ALREADY_RESET",
        });
      }
    } catch (e) {
      onError();
      this.setState({ loading: false });
    }
  };

  render() {
    const { newPass, confirmPass, error, loading } = this.state;
    const { username, onGoLogin } = this.props;

    if (loading) {
      return <CircularProgress />;
    }

    let newPassError, confirmPassError;

    if (error) {
      switch (error) {
        case "NOT_DUPLICATE_PASS":
          newPassError = "Nieuw en bevestigd wachtwoord verschillen.";
          confirmPassError = newPassError;
          break;
        case "MISSING_FIELD":
          if (newPass.length === 0) newPassError = "Veld is leeg.";
          if (confirmPass.length === 0) confirmPassError = "Veld is leeg.";
          break;
        case "INVALID_PASS":
          newPassError =
            "Het nieuwe wachtwoord moet minstens 8 tekens bevatten, waarvan minstens 1 letter en 1 cijfer.";
          break;
        case "ALREADY_RESET":
          return <AlreadyResetError onGoLogin={onGoLogin} />;
        default:
      }
    }

    return (
      <div style={styles.container}>
        <span style={styles.title}>
          Stel een nieuw wachtwoord in voor{" "}
          <span style={{ color: colors.secondary }}>{username}</span>.
        </span>
        <div style={styles.fieldContainer}>
          <TextField
            fullWidth
            type="password"
            floatingLabelText="Nieuw wachtwoord"
            errorText={newPassError}
            onChange={(event, newPass) => this.setState({ newPass })}
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this.reset();
              }
            }}
          />
          <TextField
            fullWidth
            type="password"
            floatingLabelText="Bevestig wachtwoord"
            errorText={confirmPassError}
            onChange={(event, confirmPass) => this.setState({ confirmPass })}
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this.reset();
              }
            }}
          />
          <div style={{ paddingTop: "15px", paddingBottom: "15px" }} />
          <button
            style={{ ...styles.button, width: "100%" }}
            onClick={this.reset}
          >
            Update wachtwoord
          </button>
        </div>
      </div>
    );
  }
}
