import { BucketType } from "generated/graphql";
import sendMail from "./sendMail";
import uploadAttachments from "./uploadFiles";

type MailInput = {
  subject: string;
  files: Array<File>;
  body: string;
};

const SendMailFromInput = async (client: any, input: MailInput) => {
  const { subject, files, body } = input;

  const attachmentKeys = await uploadAttachments(files, BucketType.Attachment);

  await sendMail(client, {
    subject,
    attachmentKeys,
    body,
  });
};

export { default as downloadAddressesLink } from "./downloadAddresses";
export default SendMailFromInput;
