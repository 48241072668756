//@flow

import React from "react";
import Checkbox from "material-ui/Checkbox";

type Props = {
  onCheck: (checked: boolean) => void;
  associationName: string;
};

const MailCheck = (props: Props) => {
  const label = (
    <div>
      <b>
        {`Ik geef ${props.associationName} toestemming om mij per mail informatie over mijn lidmaatschap te versturen: `}
      </b>
      {`hiermee geeft u toestemming aan ${props.associationName} om u per mail te laten informeren wanneer er wijzigingen optreden aan uw lidmaatschap.`}
    </div>
  );
  return (
    <div>
      <Checkbox
        data-test="mail_check"
        id="checkmark"
        label={label}
        onCheck={(event: any) => props.onCheck(event.target.checked)}
      />
    </div>
  );
};

export default MailCheck;
