import { PolicyStateFilter } from "./components/policyStateFilter";
import {
  GraphQLPolicyStateType,
  GraphQLSearchType,
  GraphQLMatchType,
} from "../../types";
import { SearchType } from "./components/searchType";
import { MatchType } from "./components/MatchTypeFilter";

const serializeStateFilter = (
  filter: PolicyStateFilter
): Array<GraphQLPolicyStateType> | null => {
  switch (filter) {
    case PolicyStateFilter.ALL:
      return null;
    case PolicyStateFilter.ACTIVE:
      return [GraphQLPolicyStateType.ACTIVE];
    case PolicyStateFilter.INACTIVE:
      return [
        GraphQLPolicyStateType.PENDING,
        GraphQLPolicyStateType.CANCELLED,
        GraphQLPolicyStateType.DEAD,
        GraphQLPolicyStateType.ACCEPTED,
        GraphQLPolicyStateType.REJECTED,
        GraphQLPolicyStateType.DISCHARGE,
      ];
    default:
      throw new Error(`Unknown state filter ${filter}`);
  }
};

const serializeSearchType = (
  searchType: SearchType
): GraphQLSearchType | null => {
  switch (searchType) {
    case SearchType.MEMBER_ID:
      return GraphQLSearchType.MEMBER_ID;
    case SearchType.POSTAL_CODE:
      return GraphQLSearchType.POSTAL_CODE;
    case SearchType.ADDRESS:
      return GraphQLSearchType.ADDRESS;
    case SearchType.NO_FILTER:
      return null;
    default:
      throw new Error(`Unknown search type ${searchType}`);
  }
};

const serializeMatchType = (matchType: MatchType): GraphQLMatchType => {
  switch (matchType) {
    case MatchType.EXACT:
      return GraphQLMatchType.EXACT;
    case MatchType.STARTS_WITH:
      return GraphQLMatchType.STARTS_WITH;
    default:
      throw new Error(`Unknown match type ${matchType}`);
  }
};

export { serializeStateFilter, serializeSearchType, serializeMatchType };
