//@flow

import React, { Component } from "react";
import PackageUpdate from "./updatePackage";
import { Title } from "../../../../../components/dialog";
import RaisedButton from "material-ui/RaisedButton";
import { PackageFragment } from "./updatePackage";
import { filter } from "graphql-anywhere";
import { gql } from "@apollo/client";

type State = {
  isComplete: boolean;
};

type Props = {
  handleClose: () => void;
  data: Object;
};

export default class UpdatePackageDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isComplete: false,
    };
  }

  render() {
    const { isComplete } = this.state;
    const { handleClose, data } = this.props;
    const packageUpdate = (
      <PackageUpdate
        // @ts-ignore
        id="packageUpdate"
        data={filter(PackageFragment, data)}
        handleConfirm={() => this.setState({ isComplete: true })}
        handleClose={handleClose}
      />
    );
    const confirmUpdate = (
      // @ts-ignore
      <ConfirmComponent id="confirmComponent" handleClose={handleClose} />
    );

    return (
      <div>
        <Title>Wijzig Pakket</Title>
        {isComplete ? confirmUpdate : packageUpdate}
      </div>
    );
  }
}

export const ConfirmComponent = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  return (
    <div>
      <p>Wij hebben de pakketwijzigingaanvraag ontvangen.</p>
      <p>
        Er is een bevestigingsmail van de pakketwijzigingsaanvraag naar uw
        e-mailadres verstuurd.
      </p>
      <Separator />
      <RaisedButton
        // @ts-ignore
        id="close"
        label="Sluiten"
        primary={false}
        onClick={handleClose}
      />
    </div>
  );
};

const Separator = () => <div style={{ paddingTop: "24px" }} />;

export const UpdatePackageFragment = gql`
  fragment UpdatePackage on Policy {
    id
    ...PackageDialog
  }
  ${PackageFragment}
`;
