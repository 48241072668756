// @flow

import { gql } from "@apollo/client";

type MailContent = {
  subject: string;
  attachmentKeys: Array<String>;
  body: string;
};

const SendMail = async (client: any, content: MailContent) => {
  const { subject, attachmentKeys, body } = content;

  const { errors } = await client.mutate({
    mutation: gql`
      mutation sendMail(
        $attachmentKeys: [ID!]
        $subject: String
        $body: String!
      ) {
        sendMail(
          attachmentKeys: $attachmentKeys
          subject: $subject
          body: $body
        )
      }
    `,
    variables: {
      attachmentKeys,
      subject,
      body,
    },
  });

  if (errors) {
    throw errors;
  }
};

export default SendMail;
