import React from "react";
import ExpandablePage from "../../components/expandablePageSection";
import Link from "../../../link";
import AssociationQuery from "../associationQuery";

const FinancienBCB = () => (
  <ExpandablePage title="Financiën">
    <p>
      Als vereniging doen wij een dringend beroep op u om de vereniging een
      machtiging te geven om alle contributies middels incasso te kunnen innen.
    </p>
    <p>De voordelen zijn evident:</p>
    <ul>
      <li>Kosten voor de vereniging zijn laag (geen acceptgiro).</li>
      <li>De betaling wordt niet vergeten.</li>
      <li>
        Indien u het niet eens bent met een incasso, dan kunt u die eenvoudig
        terugdraaien.
      </li>
    </ul>
    <AssociationQuery
      errorMessage={
        "Er is een fout opgetreden met het ophalen van het sepa formulier."
      }
      render={(association) => (
        <p>
          Klik{" "}
          <Link title="Incassomachtiging" href={association.sepaUrl} download>
            hier
          </Link>{" "}
          om onze incassomachting te downloaden, druk deze af, vul deze in en
          verstuur deze ondertekend naar de penningmeester:
        </p>
      )}
    />
    <p>
      Peschbeemdenstraat 11 <br />
      6462 RX Kerkrade <br />
      E-mail:{" "}
      <Link href="mailto:bcb.penningmeester@gmail.com">
        bcb.penningmeester@gmail.com
      </Link>
    </p>
    <p>
      Let u er ook op dat u alle mutaties doorgeeft? Denk hierbij aan verhuizen,
      huwelijk of samenwonen en gezinsuitbreiding. Wijzigingen kunt u middels
      een bericht aan,{" "}
      <Link href="mailto:bcb.secretaris@gmail.com?subject=wijzigingen">
        bcb.secretaris@gmail.com
      </Link>
      , doorgeven aan onze secretaris.
    </p>
    <p>
      Hieronder onze bankgegevens, mocht u zelf geld willen overmaken: <br />
      IBAN-code: NL10 RABO 0125208057 en BIC-code: RABONL2U <br />
      Bij het zelf overmaken graag het betalingskenmerk, zoals dat op de
      acceptgiro staat, vermelden.
    </p>
  </ExpandablePage>
);

export default FinancienBCB;
