// @flow

import React from "react";
import RaisedButton from "material-ui/RaisedButton";
import { connect } from "react-redux";
import { hideAlertDialog } from "../../actions";
import { State, Dispatch } from "../../reducers";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

type StateProps = {
  message?: string | null;
  title?: string | null;
  show: boolean;
};

type DispatchProps = {
  hide: () => void;
};

type Props = StateProps & DispatchProps;

export const AlertDialog = ({ show, message, title, hide }: Props) => (
  <Dialog id="dialog" open={show} style={{ zIndex: 9999 }}>
    <DialogTitle>
      {" "}
      {title ? title : "Oeps, er is iets fout gegaan."}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {message ? (
          <p>{message}</p>
        ) : (
          <p>
            Ververs de pagina en probeer het opnieuw.
            <br />
            <br />
            Als deze fout zich blijft voordoen, neem dan contact met ons op.
          </p>
        )}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <RaisedButton label="Oké" primary={true} onClick={hide} />
    </DialogActions>
  </Dialog>
);

const mapStateToProps = ({ alert }: State): StateProps => ({
  message: alert.message,
  title: alert.title,
  show: alert.show,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  hide: () => dispatch(hideAlertDialog()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AlertDialog);
