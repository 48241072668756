// @flow

import { gql } from "@apollo/client";
import { clientNew } from "api/apollo2";
import { BucketType, UploadUrlQuery, UploadUrlQueryVariables } from "generated/graphql";

const uploadFiles = (
  files: Array<File>,
  bucketType: BucketType,
): Promise<Array<string>> => {
  const attachmentKeys = files.map(async (file) => {
    const { data, errors } = await clientNew.query<UploadUrlQuery, UploadUrlQueryVariables>({
      query,
      variables: {
        filename: file.name,
        bucketType
      },
    });

    if (errors) {
      throw errors;
    } else if (!data) {
      throw new Error(`No data for file ${file.name}`);
    }

    const { getUploadUrl } = data;

    if (!getUploadUrl) {
      throw new Error("No upload URL generated");
    }

    const { url, key } = getUploadUrl;

    await uploadToS3(file, url);

    return key;
  });

  return Promise.all(attachmentKeys);
};

function uploadToS3(file: File, url: string) {
  const options = {
    method: "PUT",
    body: file,
  };

  return fetch(url, options).then((response) => {
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
  });
}

const query = gql(`
query uploadUrl($filename: String!, $bucketType: BucketType!) {
  getUploadUrl(filename: $filename, bucketType: $bucketType) {
    url
    key
  }
}
`)

export default uploadFiles;
