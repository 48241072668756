// @flow

import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import { AddressInputType } from "./addressInput";

type Props = {
  selection: AddressInputType;
  onSelectForeign: () => void;
  onSelectDomestic: () => void;
};

export const AddressTypePicker = (props: Props) => (
  <RadioButtonGroup
    // @ts-ignore
    id="picker"
    name="AddressInpuTypePicker"
    valueSelected={props.selection}
    onChange={(event: any) => {
      const value = event.target.value;
      if (value === AddressInputType.DOMESTIC) {
        props.onSelectDomestic();
      } else {
        props.onSelectForeign();
      }
    }}
  >
    <RadioButton value={AddressInputType.DOMESTIC} label="binnenland" />
    <RadioButton value={AddressInputType.FOREIGN} label="buitenland" />
  </RadioButtonGroup>
);
