// @flow
/**
 * Created by Joshua Scheidt on 25-01-2017.
 */

import {
  addSignupIban,
  addSignupAccountName,
  setPaymentState,
  PaymentState,
} from "../../../actions";
import { connect } from "react-redux";
import type { Dispatch, State } from "../../../reducers";
import SignupScreen from "../../../screens/policySignup/signupStepperComponents/signupPayment";

const mapStateToProps = (state: State) => {
  const {
    signup: {
      payment: { data, errors },
    },
  } = state;
  return {
    iban: data.iban,
    accountName: data.accountName,
    errors: errors,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIban: (iban: string) => dispatch(addSignupIban(iban)),
  setAccountName: (accountName: string) =>
    dispatch(addSignupAccountName(accountName)),
  // @ts-ignore
  nextPressed: (input: PaymentState) => dispatch(setPaymentState(input)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const Wrapper = connector(SignupScreen);

export default Wrapper;
