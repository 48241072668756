// @flow

import React, { PropsWithChildren } from "react";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import { colorsBCV } from "../../../styling";
import { withRouter, WithRouterProps } from "react-router";

const LoginButton = (props: WithRouterProps) => {
  return (
    <HighlightedButton
      label={"Inloggen"}
      onClick={() => props.router.push("/inloggen")}
    />
  );
};

const MigrateButton = (props: WithRouterProps) => {
  return (
    <HighlightedButton
      label={"Account maken / lidmaatschap koppelen"}
      onClick={() => props.router.push("/migreer")}
    />
  );
};

const Button = (
  props: PropsWithChildren & { onClick?: React.MouseEventHandler<{}> }
) => {
  return (
    <FlatButton
      style={{
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: colorsBCV.secondary,
        width: "200px",
        height: 40,
        backgroundColor: "white",
        color: colorsBCV.secondary,
        outline: "none",
      }}
      onClick={props.onClick}
    >
      {props.children}
    </FlatButton>
  );
};

const HighlightedButton = (props: {
  label: string;
  onClick?: React.MouseEventHandler<{}>;
}) => {
  return (
    <RaisedButton
      style={{
        width: 320,
        height: 40,
        outline: "none",
      }}
      backgroundColor={colorsBCV.secondary}
      labelStyle={{
        color: "white",
        fontWeight: "300",
        fontSize: "100%",
        textTransform: "none",
      }}
      label={props.label}
      onClick={props.onClick}
    />
  );
};

const PackageInfoButton = (props: WithRouterProps) => {
  return (
    <Button
      onClick={() => {
        props.router.push("pakketten");
      }}
    >
      Meer pakketinformatie
    </Button>
  );
};

const BecomeMemberButton = (props: WithRouterProps) => {
  return (
    <Button onClick={() => props.router.push("/inschrijven")}>Word lid</Button>
  );
};
const ContactInfoButton = (props: WithRouterProps) => {
  return (
    <Button onClick={() => props.router.push("contact")}>
      Contactinformatie
    </Button>
  );
};

const NotifyDeathButton = (props: WithRouterProps) => {
  return (
    <Button onClick={() => props.router.push("sterfgeval")}>
      Sterfgeval melden
    </Button>
  );
};

const LoginButtonWithRouter = withRouter(LoginButton);
const MigrationButtonWithRouter = withRouter(MigrateButton);
const PackageInfoButtonWithRouter = withRouter(PackageInfoButton);
const BecomeMemberButtonWithRouter = withRouter(BecomeMemberButton);
const ContactInfoButtonWithRouter = withRouter(ContactInfoButton);
const NotifyDeathButtonWithRouter = withRouter(NotifyDeathButton);

export {
  LoginButtonWithRouter as LoginButton,
  MigrationButtonWithRouter as MigrateButton,
  PackageInfoButtonWithRouter as PackageInfoButton,
  BecomeMemberButtonWithRouter as BecomeMemberButton,
  ContactInfoButtonWithRouter as ContactInfoButton,
  NotifyDeathButtonWithRouter as NotifyDeathButton,
};
