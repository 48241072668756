//@flow

import raven from "raven-js";
import config from "../../config";
import { browserHistory } from "react-router";
import { addAccount, login, addPolicy } from "../../api";
import { storeToken, serializeIban, serializePostalCode } from "../../utils";
import { showAlertDialog } from "../alert";
import { updateAuthState } from "../login";
import { resetSignupState } from "./signup";
import { Dispatch } from "../../reducers";
import { DocumentNode } from "graphql";
import { Frequency } from "generated/graphql";

type ActionUpdateLoadingStateUserInfo = {
  type: "UPDATE_LOADING_SIGNUP_OVERVIEW";
  loading: boolean;
};

export type Action = ActionUpdateLoadingStateUserInfo;

type AccountState = {
  firstNameAccount: string;
  lastNameAccount: string;
  lastNamePrefixAccount: string;
  phone: string;
  email: string;
  password: string;
  passwordConfirm: string;
};

export type PolicyState = {
  quoteId: string;
  birthDate: string;
  premium: number;
  lumpsum: number;
  frequency: Frequency;
  packageId: string;
  packageName: string;
  insuredAmount: number;
  firstName: string;
  lastNamePrefix: string;
  lastName: string;
  initials: string;
  gender: "MALE" | "FEMALE";
  postalCode: string;
  houseNumber: number;
  houseNumberSuffix: string | null;
  city: string;
  street: string;
  iban: string;
  accountName: string;
  eligibility: Array<{
    questionId: string;
    response: boolean;
    explanation: string;
  }>;
};

export type PolicyAccountState = PolicyState & AccountState;

export const updateLoadingState = (loading: boolean): Action => ({
  type: "UPDATE_LOADING_SIGNUP_OVERVIEW",
  loading,
});

/**
 * Register a policy
 * @param  input the data required to create the policy.
 * @param  refetchQuery the query that needs to be refetched after policy registration is complete.
 */
export const registerPolicy =
  (input: PolicyAccountState, refetchQuery: DocumentNode) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateLoadingState(true));

    const {
      email,
      password,
      firstNameAccount,
      lastNameAccount,
      lastNamePrefixAccount,
      phone,
    } = input;
    const inputAddAccount = {
      username: email,
      password,
      phone,
      firstName: firstNameAccount,
      lastName: lastNameAccount,
      namePreposition: lastNamePrefixAccount,
      associationId: config.associationId,
      redirectUrl: `${config.redirectUrl}/activeren`,
    };
    const inputAddPolicy = {
      quoteId: input.quoteId,
      sex: input.gender,
      firstName: input.firstName,
      namePreposition: input.lastNamePrefix,
      lastName: input.lastName,
      initials: input.initials,
      address: {
        postalCode: serializePostalCode(input.postalCode),
        // @ts-ignore
        houseNumber: parseInt(input.houseNumber, 10),
        suffix: input.houseNumberSuffix,
        street: input.street,
        city: input.city,
        country: "NL",
      },
      payment: {
        iban: serializeIban(input.iban),
        accountName: input.accountName,
      },
      eligibility: input.eligibility.map((eligibility) => ({
        questionId: eligibility.questionId,
        response: eligibility.response,
        explanation:
          eligibility.explanation && eligibility.explanation.length > 0
            ? eligibility.explanation
            : null,
      })),
    };

    try {
      await addAccount(inputAddAccount);
      const response = await login(email, password);

      if (response && response.value && response.permissions) {
        await storeToken(response.value, response.permissions);
        dispatch(updateAuthState(true, response.permissions));
        await addPolicy(inputAddPolicy, [refetchQuery]);
      } else
        throw new Error(
          "Login response does not contain a value and permissions"
        );

      dispatch(updateLoadingState(false));
      browserHistory.push("/inschrijven/succes");
      dispatch(resetSignupState());
    } catch (err) {
      dispatch(updateLoadingState(false));
      dispatch(showAlertDialog());
    }
  };

/**
 * Register a policy for an existing account
 * @param  input the data required to create the policy.
 * @param  refetchQuery the query that needs to be refetched after policy registration is complete.
 */
export const registerPolicyExistingAccount =
  (input: PolicyState, refetchQuery: DocumentNode) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateLoadingState(true));

    const inputAddPolicy = {
      quoteId: input.quoteId,
      sex: input.gender,
      firstName: input.firstName,
      namePreposition: input.lastNamePrefix,
      lastName: input.lastName,
      initials: input.initials,
      address: {
        postalCode: serializePostalCode(input.postalCode),
        // @ts-ignore
        houseNumber: parseInt(input.houseNumber, 10),
        suffix: input.houseNumberSuffix,
        street: input.street,
        city: input.city,
        country: "NL",
      },
      payment: {
        iban: serializeIban(input.iban),
        accountName: input.accountName,
      },
      eligibility: input.eligibility.map((eligibility) => ({
        questionId: eligibility.questionId,
        response: eligibility.response,
        explanation:
          eligibility.explanation && eligibility.explanation.length > 0
            ? eligibility.explanation
            : null,
      })),
    };

    try {
      await addPolicy(inputAddPolicy, [refetchQuery]);
      dispatch(updateLoadingState(false));
      browserHistory.push("/account/lidmaatschappen");
      dispatch(resetSignupState());
    } catch (err) {
      dispatch(updateLoadingState(false));
      dispatch(showAlertDialog());
      raven.captureException(err);
    }
  };
