//@flow

import React from "react";
import Checkbox from "material-ui/Checkbox";

type Props = {
  onCheck: (checked: boolean) => void;
  statutesUrl: string;
};

const TermsAndConditions = (props: Props) => {
  return (
    <div>
      Met het afsluiten van dit lidmaatschap verklaar ik dat:
      <br />
      <ul>
        <li>
          Alle vragen juist, volledig en naar waarheid zijn ingevuld. Als dit
          niet zo is dan kan het lidmaatschap nietig gemaakt worden.
        </li>
        <li>
          Ik bekend ben met de geldende {/* eslint-disable-next-line */}
          <a href={props.statutesUrl} target="_blank">
            statuten en reglementen
          </a>{" "}
          van de vereniging.
        </li>
        <li>
          Als de nabestaanden de verzorging van de begrafenis of crematie niet
          aan de vereniging wensen op te dragen, dan is de vereniging rechtens
          tot niets gebonden.
        </li>
      </ul>
      <Checkbox
        data-test="terms_check"
        id="checkmark"
        label={<b>Ik verklaar hiermee akkoord te zijn.</b>}
        onCheck={(event: any) => props.onCheck(event.target.checked)}
      />
    </div>
  );
};

export default TermsAndConditions;
