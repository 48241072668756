//@flow

/**
 * Created by Joshua Scheidt on 26-01-2017.
 */

import React, { Component } from "react";
import TextField from "material-ui/TextField";
import { colors } from "./../../styling/index";
import GenderPicker from "../genderPicker";

import { capitalizeFirstLetter } from "../../utils";
import { Sex } from "generated/graphql";
import { serializeSex } from "utils/formatter";

const styles: { [key: string]: React.CSSProperties } = {
  inputFieldDiv: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
};

type State = {
  firstName: string;
  initials: string;
  prefix: string;
  lastName: string;
  gender: "MALE" | "FEMALE" | null;
  errorInitials: string | null;
  errorLastName: string | null;
  errorGender: string | null;
};

type Props = {
  onEnter: Function;
};

class PersonalInfo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      firstName: "",
      initials: "",
      prefix: "",
      lastName: "",
      gender: null,
      errorInitials: null,
      errorLastName: null,
      errorGender: null,
    };
  }

  getInput(): {
    firstName: string | null;
    lastName: string;
    namePreposition: string | null;
    initials: string;
    sex: Sex;
  } {
    const { firstName, lastName, prefix, initials, gender } = this.state;
    if (gender) {
      return {
        firstName: firstName === "" ? null : firstName,
        lastName,
        namePreposition: prefix,
        initials,
        sex: serializeSex(gender),
      };
    } else {
      throw new Error("Cannot call getInput for an undefined gender.");
    }
  }

  /**
   * Test whether the components of this class are valid.
   * Within the class, we check for errors, thus return !error = valid.
   */
  validate(): boolean {
    const { lastName, initials, gender } = this.state;
    let error = false;

    let errorLastName,
      errorInitials,
      errorGender = null;

    if (lastName === "") {
      errorLastName = "De achternaam is niet ingevuld.";
      error = true;
    }

    if (initials === "") {
      errorInitials = "De initialen zijn niet ingevuld.";
      error = true;
    }
    if (gender == null) {
      errorGender = "Het geslacht is niet ingevuld.";
      error = true;
    }

    this.setState({
      errorLastName,
      errorInitials,
      errorGender,
    });

    return !error;
  }

  render() {
    const {
      firstName,
      initials,
      prefix,
      lastName,
      gender,
      errorInitials,
      errorLastName,
      errorGender,
    } = this.state;

    return (
      <div style={styles.inputFieldDiv}>
        <TextField
          // @ts-ignore
          id="firstName"
          value={firstName}
          floatingLabelText="Voornaam"
          floatingLabelFocusStyle={{ color: colors.green }}
          underlineFocusStyle={{ borderColor: colors.green }}
          onChange={(event: any) =>
            this.setState({
              firstName: capitalizeFirstLetter(event.target.value),
            })
          }
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this.props.onEnter();
            }
          }}
          fullWidth
        />
        <TextField
          // @ts-ignore
          id="initials"
          value={initials}
          floatingLabelText="Initialen"
          floatingLabelFocusStyle={{ color: colors.green }}
          underlineFocusStyle={{ borderColor: colors.green }}
          onChange={(event: any) =>
            this.setState({
              initials: event.target.value.toUpperCase(),
              errorInitials: null,
            })
          }
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this.props.onEnter();
            }
          }}
          errorText={errorInitials}
          fullWidth
        />
        <TextField
          // @ts-ignore
          id="prefix"
          value={prefix}
          floatingLabelText="Tussenvoegsel (optioneel)"
          floatingLabelFocusStyle={{ color: colors.green }}
          underlineFocusStyle={{ borderColor: colors.green }}
          onChange={(event: any) =>
            this.setState({ prefix: event.target.value })
          }
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this.props.onEnter();
            }
          }}
          fullWidth
        />
        <TextField
          // @ts-ignore
          id="lastName"
          value={lastName}
          floatingLabelText="Achternaam"
          floatingLabelFocusStyle={{ color: colors.green }}
          underlineFocusStyle={{ borderColor: colors.green }}
          onChange={(event: any) =>
            this.setState({
              lastName: capitalizeFirstLetter(event.target.value),
              errorLastName: null,
            })
          }
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this.props.onEnter();
            }
          }}
          errorText={errorLastName}
          fullWidth
        />
        <div style={{ textAlign: "center", paddingTop: 20 }}>
          <GenderPicker
            value={gender}
            color={colors.green}
            onChange={(gender) => {
              this.setState({
                gender,
                errorGender: null,
              });
            }}
          />
          {errorGender ? (
            <p style={{ color: "red", fontSize: "12px" }}>{errorGender}</p>
          ) : null}
        </div>
      </div>
    );
  }
}

export default PersonalInfo;
