// @flow

/**
 * File contains the cookie that signals whether
 * or not the viewer has accepted the standard cookie message.
 */
import Cookies from "js-cookie";

const COOKIE_KEY = "cookieDismissed";

const getHasDismissedCookieMessage = (): boolean => {
  const cookie = Cookies.get(COOKIE_KEY);
  if (!cookie) return false;
  return JSON.parse(cookie);
};

const setHasDismissedCookieMessage = (dimissed: boolean) => {
  Cookies.set(COOKIE_KEY, JSON.stringify(dimissed), { expires: 365 });
};

export { getHasDismissedCookieMessage, setHasDismissedCookieMessage };
