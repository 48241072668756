import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { textFieldErrorProps, useForm } from "utils/useForm";
import { required } from "utils/validator";
import { useUpdateAccountMutation } from "../graphql";
import { EditButton } from "../../../components/button/buttons";

type Props = {
  accountId: string;
  firstName: string;
  namePreposition: string | null;
  lastName: string;
};

type Form = {
  firstName: string;
  namePreposition: string;
  lastName: string;
};

export const UpdateAccountNameButton = (props: Props) => {
  const [open, setOpen] = useState(false);

  const { values, registerField, handleSubmit, errors } = useForm<Form>({
    initialValues: {
      firstName: props.firstName,
      namePreposition: props.namePreposition || "",
      lastName: props.lastName,
    },
  });

  const errorsPerField = textFieldErrorProps(errors);

  const [updateAccount, { loading }] = useUpdateAccountMutation(
    props.accountId,
    {
      input: {
        firstName: values.firstName,
        lastName: values.lastName,
        namePreposition: values.namePreposition,
      },
    }
  );

  const close = () => {
    if (!loading) setOpen(false);
  };
  return (
    <>
      <EditButton onClick={() => setOpen(true)} />
      <Dialog maxWidth="sm" fullWidth open={open} onClose={close}>
        <DialogTitle>Pas de naam van de accounthouder aan</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <TextField
              label="Voornaam"
              fullWidth
              {...registerField("firstName", { validation: required })}
              {...errorsPerField("firstName")}
            />
            <TextField
              label="Tussenvoegsel"
              fullWidth
              {...registerField("namePreposition")}
              {...errorsPerField("namePreposition")}
            />
            <TextField
              label="Achternaam"
              fullWidth
              {...registerField("lastName", { validation: required })}
              {...errorsPerField("lastName")}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            Annuleer
          </Button>
          <Button
            disabled={loading}
            onClick={() =>
              handleSubmit(() => {
                updateAccount();
              })
            }
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
