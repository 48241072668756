// @flow

import { gql } from "@apollo/client";
import React from "react";
import { colorsBCV } from "../../../styling";
import IconButton from "material-ui/IconButton";
import Pencil from "material-ui/svg-icons/content/create";
import UpdateFirstName from "./dialogs/firstnameDialog";
import UpdateLastName from "./dialogs/lastnameDialog";
import { DocumentNode } from "graphql";
import { Dialog } from "@mui/material";

const styles: { [key: string]: React.CSSProperties } = {
  userInfo: {
    flexDirection: "column",
    textAlign: "left",
    justifyContent: "left",
    alignItems: "left",
  },
  infoTitle: {
    paddingTop: "10px",
    fontSize: "18px",
    color: colorsBCV.secondary,
  },
  buttonDiv: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
};

type Props = {
  infoProfile: {
    id: string;
    firstName: string;
    lastName: string;
  };
};

type State = {
  openFirstName: boolean;
  openLastName: boolean;
};

class ProfileInfo extends React.Component {
  state: State;
  props: Props;
  static fragments: {
    infoProfile: DocumentNode;
  };

  constructor(props: Props) {
    super(props);
    this.state = { openFirstName: false, openLastName: false };
    this._handleCloseFirstname = this._handleCloseFirstname.bind(this);
    this._handleCloseLastname = this._handleCloseLastname.bind(this);
  }

  /**
   * Closes the firstname dialog.
   */
  _handleCloseFirstname: () => void = () => {
    this.setState({ openFirstName: false });
  };

  /**
   * Closes the lastname dialog.
   */
  _handleCloseLastname: () => void = () => {
    this.setState({ openLastName: false });
  };

  render() {
    let { infoProfile } = this.props;

    return (
      <div style={styles.userInfo}>
        <div style={{ fontSize: "24px" }}>Accountoverzicht</div>
        <div style={styles.infoTitle}>Voornaam</div>
        <div style={{ display: "flex", paddingBottom: "24px" }}>
          <div style={{ flex: 1 }}>{infoProfile.firstName}</div>
          <div style={{ ...styles.buttonDiv, flex: 1 }}>
            <IconButton
              title="Wijzig voornaam"
              onClick={() => {
                this.setState({ openFirstName: true });
              }}
            >
              <Pencil color={colorsBCV.secondary} />
            </IconButton>
          </div>
        </div>
        <div style={styles.infoTitle}>Achternaam</div>
        <div style={{ display: "flex", paddingBottom: "24px" }}>
          <div style={{ flex: 1 }}>{infoProfile.lastName}</div>
          <div style={{ ...styles.buttonDiv, flex: 1 }}>
            <IconButton
              title="Wijzig achternaam"
              onClick={() => {
                this.setState({ openLastName: true });
              }}
            >
              <Pencil color={colorsBCV.secondary} />
            </IconButton>
          </div>
        </div>
        <Dialog
          open={this.state.openFirstName}
          onClose={this._handleCloseFirstname}
        >
          <UpdateFirstName
            handleClose={this._handleCloseFirstname}
            accountId={infoProfile.id}
          />
        </Dialog>
        <Dialog
          open={this.state.openLastName}
          onClose={this._handleCloseLastname}
        >
          <UpdateLastName
            handleClose={this._handleCloseLastname}
            accountId={infoProfile.id}
          />
        </Dialog>
      </div>
    );
  }
}

export const ProfileInfoFragmentDef = gql`
  fragment ProfileInfo on Account {
    id
    firstName
    lastName
  }
`;

export default ProfileInfo;
