//@flow

import React from "react";
import { colorsBCV } from "./../../../styling/index";
import Paper from "material-ui/Paper";
import MailIcon from "material-ui/svg-icons/content/mail";
import PhoneIcon from "material-ui/svg-icons/communication/phone";
import PersonIcon from "material-ui/svg-icons/action/account-box";

const styles: { [key: string]: React.CSSProperties } = {
  paperElement: {
    width: "100%",
    textAlign: "left",
    paddingTop: "20px",
    paddingLeft: "28px",
    paddingRight: "28px",
    paddingBottom: "28px",
    fontWeight: 300,
    fontSize: "16px",
  },
  titleContainer: {
    display: "flex",
    paddingTop: "12px",
    margin: "10px",
    textAlign: "left",
    fontSize: "30px",
    color: colorsBCV.secondary,
  },
};

type Props = {
  accountData: {
    firstNameAccount: string;
    lastNamePrefixAccount: string;
    lastNameAccount: string;
    phone: string;
    email: string;
  };
};

const createIconRow = (Icon: any, value: string) => {
  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          height: "40px",
          width: "30px",
          display: "flex",
          alignItems: "center",
          paddingLeft: 8,
          paddingRight: 10,
        }}
      >
        <Icon style={{ width: "30px", height: "30px" }} />
      </div>
      <div
        style={{
          height: "40px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {value}
      </div>
    </div>
  );
};

const SignUpCardAccount = (props: Props) => {
  const { accountData } = props;
  const name =
    accountData.firstNameAccount +
    " " +
    accountData.lastNamePrefixAccount +
    " " +
    accountData.lastNameAccount;

  return (
    <Paper style={styles.paperElement} zDepth={1}>
      <h2 style={{ color: colorsBCV.secondary }}>Accountgegevens</h2>
      {createIconRow(PersonIcon, name)}
      {createIconRow(MailIcon, accountData.email)}
      {createIconRow(PhoneIcon, accountData.phone)}
    </Paper>
  );
};

export default SignUpCardAccount;
