// @flow

import React from "react";
import config, { AssociationType } from "../../../config";
import { FAQHulpEnSteun } from "./hulpEnSteun";

const { association } = config;

export const FAQPage = () => {
  switch (association) {
    case AssociationType.BCB:
      return null;
    case AssociationType.PIETERSRADE:
      return null;
    case AssociationType.HULP_EN_STEUN:
      return <FAQHulpEnSteun />;
    default:
      throw new Error(`Unknown assocation type ${association}`);
  }
};
