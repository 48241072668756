import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { MenuButton } from "components/header";
import SideBar from "components/header/sideBar";

export const Header = () => {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <MenuButton />
          <Typography variant="h6" component="div">
            Instellingen
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
      </AppBar>
      <SideBar />
    </>
  );
};
