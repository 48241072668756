import { useEffect } from "react";
import { WithRouterProps } from "react-router";

enum AccountPageParams {
  PAGE = "page",
  FIRST = "first",
  QUERY = "query",
  GHOST_ACCOUNTS = "ghost_accounts",
}

export const useUpdateQueryParam = (
  page: number,
  first: number,
  query: string | null,
  ghostAccountsOn: boolean,
  { router, location }: WithRouterProps
) => {
  useEffect(() => {
    router.push({
      pathname: location.pathname,
      query: {
        [AccountPageParams.PAGE]: page,
        [AccountPageParams.FIRST]: first,
        [AccountPageParams.QUERY]: query || "",
        [AccountPageParams.GHOST_ACCOUNTS]: JSON.stringify(ghostAccountsOn),
      },
    });
    // eslint-disable-next-line
  }, [page, first, query, ghostAccountsOn]);
};

export const getInitialState = ({ location }: WithRouterProps) => {
  const getQueryParam = (name: AccountPageParams) =>
    location && location.query && location.query[name]
      ? location.query[name]
      : "";

  const page = getQueryParam(AccountPageParams.PAGE);
  const first = getQueryParam(AccountPageParams.FIRST);
  const ghostAccountsOn = getQueryParam(AccountPageParams.GHOST_ACCOUNTS);

  return {
    page: page != null ? Number(page) : 0,
    first: first ? Number(first) : 15,
    query: String(getQueryParam(AccountPageParams.QUERY)),
    ghostAccountOn: ghostAccountsOn
      ? (JSON.parse(ghostAccountsOn) as boolean)
      : false,
  };
};
