// @flow

import React from "react";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";

import { colors } from "../../../../styling";
import { gql } from "generated";

type Props = {
  data: {
    id: string;
    upgradeablePackages: Array<{
      id: string;
      name: string;
    } | null> | null;
  };
  onSelectedPackage: (selectedPackageId: string) => void;
};

const PackagePicker = (props: Props) => {
  const { data, onSelectedPackage } = props;
  const { upgradeablePackages } = data;

  let content;

  if (!upgradeablePackages) {
    content = (
      <p style={{ color: colors.red }}>
        Er is een fout opgetreden waardoor het niet mogelijk is om het pakket te
        wijzigen. Neem contact op met ons als dit zich blijft voordoen.
      </p>
    );
  } else {
    const filteredPackages = upgradeablePackages.filter(Boolean);
    if (filteredPackages.length === 0) {
      content = (
        <p style={{ color: colors.red }}>
          Er zijn geen andere pakketten beschikbaar waartussen gewisseld kan
          worden.
        </p>
      );
    } else {
      content = (
        <RadioButtonGroup
          name="Pakketten"
          onChange={(event, value) => {
            onSelectedPackage(value);
          }}
        >
          {filteredPackages.map((pack, i) => (
            <RadioButton
              value={pack.id}
              label={pack.name}
              key={i}
              iconStyle={{ fill: colors.green }}
            />
          ))}
        </RadioButtonGroup>
      );
    }
  }

  return (
    <div>
      <h3>Kies een pakket</h3>
      {content}
    </div>
  );
};

const fragment = gql(`
fragment UpgradeablePackages on Policy {
  id
  upgradeablePackages {
    id
    name
  }
}
`);

export default PackagePicker;
export { fragment };
