// @flow

import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";
import "./layout.css";

const VerticalSeparator = (props: { distance: number }) => (
  <div style={{ paddingTop: props.distance * 8 }} />
);

const Centered = (props: any) => {
  return <div className="centered">{props.children}</div>;
};

const CenterInScreen = (props: PropsWithChildren) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
      position: "absolute",
    }}
  >
    {props.children}
  </Box>
);

export const ScreenContainer = (props: PropsWithChildren) => (
  <Box
    display={"flex"}
    justifyContent="center"
    sx={{ paddingTop: "8px", paddingBottom: "30px" }}
  >
    <Box width="90%">{props.children}</Box>
  </Box>
);

export { VerticalSeparator, Centered, CenterInScreen };
