// @flow

import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";

type Package = {
  id: string;
  name: string;
  insuredAmount: number;
};

type Props = {
  packages: Array<Package>;
  selectedPackage?: Package | null;
  onSelectPackage: (pack: Package) => void;
};

const PackagePicker = (props: Props) => {
  const { packages, selectedPackage } = props;

  return (
    <RadioButtonGroup
      name="Pakketten"
      valueSelected={selectedPackage?.id}
      onChange={(event, value) => {
        const selected = packages.find((item) => item.id === value)!!;

        props.onSelectPackage(selected);
      }}
    >
      {props.packages.map((pack, i) => (
        <RadioButton
          value={pack.id}
          label={pack.name}
          key={i}
          data-test={`package_option_${pack.id}`}
        />
      ))}
    </RadioButtonGroup>
  );
};

export default PackagePicker;
