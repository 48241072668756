// @flow
import React from "react";
const style: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
};

type Props = {
  children?: any;
};

const contentRow = (props: Props) => (
  <div style={style}>
    {React.Children.map(props.children, (child) => (
      <div
        style={{
          flex: 1,
        }}
      >
        {child}
      </div>
    ))}
  </div>
);
export default contentRow;
