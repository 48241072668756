// @flow

import { useState } from "react";

import { filter } from "graphql-anywhere";
import RaisedButton from "material-ui/RaisedButton";
import { withRouter, WithRouterProps } from "react-router";

import { errorHandler, ErrorHandler } from "../../../../containers";
import { colors } from "../../../../styling";
import Eligibility from "./eligibility/eligibility";
import Contribution from "./contribution";
import { NavButtonContainer, Title } from "../../../../components/dialog";
import PackagePicker, {
  fragment as PackagePickerFragment,
} from "./packagePicker";
import { useUpdatePackagePolicyMutation } from "./updatePackageMutationGraphQL";
import { FragmentType, gql, useFragment } from "generated";
import { UpdatePackageInsurerFragment } from "generated/graphql";
import { DialogContainer2 } from "components/dialog/container";

type PropsParent = {
  handleClose(): void;
  data: FragmentType<typeof UpdatePackageFragmentDef>;
};

type Props = PropsParent & ErrorHandler & WithRouterProps;

const PackageDialog = (props: Props) => {
  const [selectedPackageId, setSelectedPackageId] = useState(null);

  const data = useFragment<UpdatePackageInsurerFragment>(
    UpdatePackageFragmentDef,
    props.data
  );

  const [updatePackagePolicy, { loading }] = useUpdatePackagePolicyMutation({
    onCompleted: (data) =>
      props.router.push(
        "/bestuurder/leden/" + data.updatePackagePolicy.updatedPolicy.id
      ),
    onError: () => props.error.display(),
  });

  var contribution: Contribution | null = null;
  var eligibility: Eligibility | null = null;

  const mutate = () => {
    const validContribution = contribution!!.validate();
    const validEligibility = eligibility!!.validate();
    if (validContribution && validEligibility && selectedPackageId) {
      const contributionData = contribution!!.getInput();
      const eligibilityData = eligibility!!.getInput();

      const { id } = data;
      const { premium, lumpsum } = contributionData;

      const input = {
        policyId: id,
        packageId: selectedPackageId,
        eligibility: eligibilityData,
        premium: premium,
        lumpsum: lumpsum,
      };

      updatePackagePolicy({
        variables: { input },
      });
    }
  };

  return (
    <DialogContainer2 data-test="update_package_dialog">
      <Title>Pakket wijzigen</Title>
      <PackagePicker
        data={filter(PackagePickerFragment, data)}
        onSelectedPackage={setSelectedPackageId}
      />
      {selectedPackageId && (
        <Contribution
          selectedPackageId={selectedPackageId}
          policyId={data.id}
          ref={(ref) => (contribution = ref)}
        />
      )}
      <Eligibility
        ref={(ref) => (eligibility = ref)}
        questions={data.insuredPerson.association.eligibilityQuestions}
      />
      <br />
      <NavButtonContainer>
        <RaisedButton
          label="Annuleren"
          primary={false}
          disabled={loading}
          onClick={props.handleClose}
        />
        <RaisedButton
          label="Bevestig"
          labelStyle={{ color: "white" }}
          disabled={loading}
          backgroundColor={colors.green}
          onClick={() => mutate()}
        />
      </NavButtonContainer>
    </DialogContainer2>
  );
};

export const UpdatePackageFragmentDef = gql(`
  fragment UpdatePackageInsurer on Policy {
    id
    insuredPerson {
      id
      association {
        id
        eligibilityQuestions {
          id
          question
        }
      }
    }
    ...UpgradeablePackages
  }
`);

const Wrapper = withRouter(errorHandler(PackageDialog));

export default Wrapper;
