import { useEffect } from "react";
import { WithRouterProps } from "react-router";
import { MatchType } from "./components/MatchTypeFilter";
import { PolicyStateFilter } from "./components/policyStateFilter";
import { SearchType } from "./components/searchType";

export const shouldScrollMore = (onNextPage: () => void) => {
  //scrollHeight is the height of the scrollable content (the whole page).
  //scrollTop is the height of the content at the top that is currently invisible (the height from the top of the page to the current scroll position).
  //innerHeight is the height of the window.

  const scrollHeight =
    document.body.scrollHeight || document.documentElement.scrollHeight;

  const scrollTop =
    document.body.scrollTop || document.documentElement.scrollTop;

  const { innerHeight } = window;

  //Determine when the height of the scrollable content is smaller than the scroll position + the window height plus one:
  if (scrollHeight <= scrollTop + innerHeight + 1) {
    onNextPage();
  }
};

export const useShouldScrollToNextPage = (
  hasNextPage: boolean | null,
  loading: boolean,
  endCursor: string,
  scrollToNextPage: () => void
) =>
  useEffect(() => {
    const handleScroll = () => {
      if (hasNextPage != null && !loading) {
        if (hasNextPage) {
          shouldScrollMore(() => scrollToNextPage());
        }
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    //eslint-disable-next-line
  }, [hasNextPage, loading, endCursor]);

export const SEARCH_QUERY_PARAM = "search";
export const POLICY_STATE_FILTER_PARAM = "policyStateFilter";
export const SEARCH_TYPE_PARAM = "searchType";
export const MATCH_PARAM = "matchType";

export const getQueryFromLocation = ({
  query,
}: WithRouterProps["location"]): string =>
  query[SEARCH_QUERY_PARAM] ? query[SEARCH_QUERY_PARAM] : "";

export const getPolicyStateFilterFromLocation = ({
  query,
}: WithRouterProps["location"]): PolicyStateFilter =>
  query[POLICY_STATE_FILTER_PARAM]
    ? query[POLICY_STATE_FILTER_PARAM]
    : PolicyStateFilter.ACTIVE;

export const getSearchTypeFromLocation = ({
  query,
}: WithRouterProps["location"]): SearchType =>
  query[SEARCH_TYPE_PARAM] ? query[SEARCH_TYPE_PARAM] : SearchType.NO_FILTER;

export const getMatchTypeFromLocation = ({
  query,
}: WithRouterProps["location"]): MatchType =>
  query[MATCH_PARAM] ? query[MATCH_PARAM] : MatchType.EXACT;
