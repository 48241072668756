// @flow

import { Component } from "react";
import { errorHandler, ErrorHandler } from "../../../../../../containers";
import { Warning, NavButtons } from "./components";
import TextField from "../../../../../../components/textfield";
import { VerticalSeparator } from "../../../../../../components/layout";
import { currentDate } from "./utils";
import { serializeDate } from "../../../../../../utils";

import {
  required,
  mustBeDate,
  composeValidators,
} from "../../../../../../components/form";
import {
  CancelPolicyMutation,
  CancelPolicyMutationVariables,
} from "generated/graphql";
import { graphql } from "@apollo/client/react/hoc";
import { gql } from "generated";
import { DialogContainer2 } from "components/dialog/container";

const cancellationDateValidator = composeValidators(required, mustBeDate);

type PropsParent = {
  handleBack: () => void;
  handleClose: () => void;
  policyId: string;
};

type PropsGraphQLCancel = { cancelPolicy(cancellationDate: string): void };
type Props = PropsParent & PropsGraphQLCancel & ErrorHandler;
type State = {
  cancellationDate: string;
  cancellationDateError: string | null;
  loading: boolean;
};
class CancelDialog extends Component<Props, State> {
  state = {
    cancellationDate: currentDate(),
    cancellationDateError: "",
    loading: false,
  };

  _updateState = async () => {
    const { cancelPolicy, error } = this.props;
    const { cancellationDate } = this.state;

    const cancellationDateError = cancellationDateValidator(cancellationDate);

    this.setState({
      cancellationDateError,
    });

    if (!cancellationDateError) {
      this.setState({ loading: true });
      try {
        await cancelPolicy(serializeDate(cancellationDate));
        // Reload to reset the cache.
        window.location.reload();
      } catch (e) {
        error.display();
        this.setState({ loading: false });
      }
    }
  };

  render() {
    const { handleBack } = this.props;
    const { cancellationDate, cancellationDateError, loading } = this.state;

    return (
      <DialogContainer2>
        <Warning toState={"Opgezegd"} />
        <TextField
          data-test="cancellation_date_input"
          // @ts-ignore
          floatingLabelText={"Opzegdatum"}
          hintText="Voer de opzegdatum in (DD-MM-YYYY)"
          errorText={cancellationDateError}
          value={cancellationDate}
          fullWidth
          onChange={(event: any) =>
            this.setState({ cancellationDate: event.target.value })
          }
        />
        <VerticalSeparator distance={2} />
        <NavButtons
          onCancel={handleBack}
          onConfirm={() => {
            this._updateState();
          }}
          disabled={loading}
        />
      </DialogContainer2>
    );
  }
}

export const CANCEL_POLICY_MUTATION = gql(`
  mutation cancelPolicy($input: CancelPolicyInput!) {
    cancelPolicy(input: $input) {
      policy {
        id
        cancellationDate
        state
        signOffDate
        __typename
      }
    }
  }
`);

export const withMutationCancel = graphql<
  PropsParent,
  CancelPolicyMutation,
  CancelPolicyMutationVariables,
  PropsGraphQLCancel
>(CANCEL_POLICY_MUTATION, {
  props: ({ ownProps, mutate }) => ({
    cancelPolicy: (cancellationDate: string) =>
      mutate({
        variables: { input: { policyId: ownProps.policyId, cancellationDate } },
      }),
  }),
});
const Wrapper = withMutationCancel(errorHandler(CancelDialog));

export default Wrapper;
