// @flow

import React, { Component } from "react";
import { colors } from "../../../../styling";
import { parseDate, formatPaymentMethod } from "../../../../utils";
import { PaymentMethod } from "../../../../types";
import RaisedButton from "material-ui/RaisedButton";
import MandateIdUpdater from "./mandateIdUpdater";
import SignatureDateUpdater from "./signatureDateUpdater";
import SectionTitle from "../components/sectionTitle";
import { gql } from "generated";
import { Row } from "../components/row";
import TitleContainer from "../components/titleContainer";
import EditButton from "../components/editButton";
import { Dialog } from "@mui/material";
import { UpdatePaymentDialog } from "components/payment/updatePaymentDialog";

type Payment = {
  id: string;
  iban: string | null;
  mandateId: string | null;
  accountHolder: string | null;
  signatureDate: string | null;
  paymentMethod: PaymentMethod | null;
  bic: string | null;
};

type Props = {
  data: {
    id: string;
    payment: Payment | null;
  };
  disabled: boolean;
  policyId: string;
};

type State = {
  showPayment: boolean;
  showSignatureDate: boolean;
  showMandateId: boolean;
};

class PaymentDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showPayment: false,
      showSignatureDate: false,
      showMandateId: false,
    };
    this._handleClosePayment = this._handleClosePayment.bind(this);
    this._handleCloseSignatureDate = this._handleCloseSignatureDate.bind(this);
  }
  _handleClosePayment: () => void = () => {
    this.setState({ showPayment: false });
  };
  _handleCloseSignatureDate: () => void = () => {
    this.setState({ showSignatureDate: false });
  };

  _createRowData = (data: Payment) => {
    const {
      iban,
      accountHolder,
      mandateId,
      signatureDate,
      paymentMethod,
      bic,
    } = data;

    let rows = [
      <Row
        name="Betalingsmethode"
        value={paymentMethod ? formatPaymentMethod(paymentMethod) : "Onbekend"}
        key={"Betalingsmethode"}
        data-test="payment_method_value"
      />,
      <Row
        name="BIC"
        value={bic ? bic : "Onbekend"}
        key={"BIC"}
        data-test="bic_value"
      />,
      <Row
        name="IBAN"
        value={iban ? iban : "Onbekend"}
        key={"IBAN"}
        data-test="iban_value"
      />,
      <Row
        name="Naam rekeninghouder"
        value={accountHolder ? accountHolder : "Onbekend"}
        key={"Naam rekeninghouder"}
        data-test="accountholder_value"
      />,
    ];

    if (paymentMethod === PaymentMethod.DIRECT_DEBIT) {
      rows.push(
        <Row
          name="Mandaat ID"
          value={mandateId ? mandateId : "Onbekend"}
          key={"Mandaat ID"}
          data-test="mandate_id_value"
          button={
            <EditButton
              data-test="update_mandate_id_button"
              onClick={() => this.setState({ showMandateId: true })}
              disabled={this.props.disabled}
            />
          }
        />
      );
      if (signatureDate) {
        rows.push(
          <Row
            name="Ondertekeningsdatum"
            value={parseDate(signatureDate)}
            button={
              <EditButton
                onClick={() => this.setState({ showSignatureDate: true })}
                disabled={this.props.disabled}
              />
            }
            key={"Ondertekeningsdatum"}
          />
        );
      } else {
        rows.push(
          <div key={"noSignatureDate"}>
            <p>Deze machtiging bevat nog geen ondertekeningsdatum.</p>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 2, textAlign: "center" }}>
                <RaisedButton
                  label="Toevoegen"
                  backgroundColor={colors.green}
                  labelStyle={{ color: "white" }}
                  onClick={() => this.setState({ showSignatureDate: true })}
                  disabled={this.props.disabled}
                />
              </div>
              <div style={{ flex: 1 }} />
            </div>
          </div>
        );
      }
    }

    return rows;
  };

  render() {
    const { data } = this.props;
    const { payment } = data;

    const title = (
      <TitleContainer>
        <SectionTitle title="Betaalinformatie" />
        <div>
          <RaisedButton
            label="WIJZIG"
            data-test="edit_payment_button"
            backgroundColor={colors.green}
            labelStyle={{ color: "white" }}
            onClick={() => this.setState({ showPayment: true })}
            disabled={this.props.disabled}
          />
        </div>
      </TitleContainer>
    );

    let content;
    if (!payment)
      content = (
        <p style={{ fontSize: "18px" }}>
          Er is geen betalingsinformatie beschikbaar voor dit lid.
        </p>
      );
    else
      content = (
        <div>
          {this._createRowData(payment)}
          <Dialog
            open={this.state.showSignatureDate}
            onClose={this._handleCloseSignatureDate}
            maxWidth="md"
            fullWidth
          >
            <SignatureDateUpdater
              handleClose={this._handleCloseSignatureDate}
              insuredPersonId={this.props.data.id}
            />
          </Dialog>
          <MandateIdUpdater
            paymentId={payment.id}
            onClose={() => this.setState({ showMandateId: false })}
            open={this.state.showMandateId}
          />
        </div>
      );

    return (
      <div>
        {title}
        {content}
        <UpdatePaymentDialog
          open={this.state.showPayment}
          insuredPersonIds={[this.props.data.id]}
          handleClose={this._handleClosePayment}
          paymentInfo={
            data.payment != null
              ? {
                  iban: data.payment.iban || "",
                  bic: data.payment.bic || "",
                  accountHolderName: data.payment.accountHolder || "",
                }
              : null
          }
        />
      </div>
    );
  }
}

export const paymentDetailsFragment = gql(`
  fragment PaymentDetails on InsuredPerson {
    id
    payment {
      id
      bic
      iban
      mandateId
      accountHolder
      signatureDate
      paymentMethod
    }
  }
`);

export default PaymentDetails;
