// @flow

import React, { Component } from "react";
import Title from "../title";
import Content from "../content";
import Arrow from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import { colorsBCV } from "../../../../styling";
import transitions from "material-ui/styles/transitions";
import { PropsWithChildren } from "react";

const style: { [key: string]: React.CSSProperties } = {
  arrow: {
    color: colorsBCV.secondary,
    paddingTop: "32px",
  },
  titleDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  text: {
    overflow: "auto",
    maxHeight: 3000,
    transition: transitions.create(
      "max-height",
      "1200ms",
      "0ms",
      "ease-in-out"
    ),
  },
  textRetracted: {
    maxHeight: 0,
  },
};

type State = {
  expand: boolean;
};

type PropType = {
  title: string;
} & PropsWithChildren;

class ExpandablePageSection extends Component<PropType, State> {
  handleTouchTap = () => {
    this.setState({
      expand: !this.state.expand,
    });
  };

  state = {
    expand: false,
  };

  render() {
    const { title, children } = this.props;

    let codeStyle = { ...style.text, ...style.textRetracted };
    let tooltip = "Klap sectie uit";

    if (this.state.expand) {
      codeStyle = style.text;
      tooltip = "Klap sectie in";
    }
    return (
      <div>
        <div
          onClick={this.handleTouchTap}
          style={style.titleDiv}
          title={tooltip}
        >
          <Title title={title} /> <Arrow style={style.arrow} />
        </div>
        <div style={codeStyle}>
          <Content>{children}</Content>
        </div>
      </div>
    );
  }
}

export default ExpandablePageSection;
