// @flow

import React, { Component } from "react";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import { graphql } from "@apollo/client/react/hoc";
import { colors } from "../../../../styling";
import { errorHandler } from "../../../../containers";
import type { ErrorHandler } from "../../../../containers";
import { gql } from "generated";
import {
  UpdateMemoMutation,
  UpdateMemoMutationVariables,
} from "generated/graphql";
import { DialogContainer2 } from "components/dialog/container";
import { NavButtonContainer } from "components/dialog";
const { green } = colors;

type PropsParent = {
  handleClose: () => void;
  memo: string | null;
  insuredPersonId: string;
};
type PropsGraphQL = {
  update: (memo: string) => Promise<any>;
};
type Props = PropsParent & PropsGraphQL & ErrorHandler;

type State = {
  memoInput: string;
  error: string | null;
};

export class MemoUpdater extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { memo } = props;
    this.state = { memoInput: memo ? memo : "", error: null };
  }

  updateMemo = async () => {
    const { update } = this.props;
    const { memoInput } = this.state;

    try {
      await update(memoInput);
      // Reload to reset the cache.
      window.location.reload();
    } catch (error) {
      this.props.error.display();
    }
  };

  render() {
    const { handleClose } = this.props;
    const { memoInput, error } = this.state;
    return (
      <DialogContainer2>
        <TextField
          id="memoInput"
          floatingLabelText={"Notitie"}
          hintText="Voeg een notitie toe"
          multiLine
          rowsMax={12}
          name="Notitie"
          errorText={error}
          value={memoInput}
          floatingLabelFocusStyle={{ color: colors.green }}
          underlineFocusStyle={{ borderColor: colors.green }}
          fullWidth
          onChange={(event: any) =>
            this.setState({ memoInput: event.target.value })
          }
        />
        <NavButtonContainer>
          <RaisedButton
            // @ts-ignore
            id="back"
            label="Annuleren"
            primary={false}
            onClick={handleClose}
          />
          <RaisedButton
            // @ts-ignore
            id="confirm"
            label="Bevestig"
            backgroundColor={green}
            labelStyle={{ color: "white" }}
            onClick={this.updateMemo}
          />
        </NavButtonContainer>
      </DialogContainer2>
    );
  }
}

export const UPDATE_MEMO = gql(`
mutation updateMemo($input: UpdateInsuredPersonInput!) {
  updateInsuredPerson(input: $input) {
    insuredPeople {
      id
      memo
    }
  }
}
`);

export const withMutation = graphql<
  PropsParent,
  UpdateMemoMutation,
  UpdateMemoMutationVariables,
  PropsGraphQL
>(UPDATE_MEMO, {
  props: ({ ownProps, mutate }) => ({
    update: (memo: string) =>
      mutate!!({
        variables: {
          input: { insuredPersonIds: [ownProps.insuredPersonId], memo },
        },
        optimisticResponse: {
          // @ts-ignore
          insuredPerson: {
            id: ownProps.insuredPersonId,
            memo,
          },
        },
      }),
  }),
});

const Wrapped = withMutation(errorHandler(MemoUpdater));

export default Wrapped;
