//@flow

import { Dispatch } from "../../reducers";
import { validateInitials } from "../../utils";
import { increaseStepperIndex } from "./index";

export type UserState = {
  firstName: string;
  lastNamePrefix: string;
  lastName: string;
  gender: "MALE" | "FEMALE" | null;
  initials: string;
};

type ActionAddSignupFirstName = {
  type: "ADD_SIGNUP_FIRSTNAME";
  firstName: string;
};
type ActionAddSignupLastNamePrefix = {
  type: "ADD_SIGNUP_LASTNAME_PREFIX";
  lastNamePrefix: string;
};
type ActionAddSignupLastName = {
  type: "ADD_SIGNUP_LASTNAME";
  lastName: string;
};
type ActionAddSignupGender = {
  type: "ADD_SIGNUP_GENDER";
  gender: "MALE" | "FEMALE";
};
type ActionAddSignupInitials = {
  type: "ADD_SIGNUP_INITIALS";
  initials: string;
};

export type SignupUserInfoError =
  | "EMPTY_FIRST_NAME_ERROR"
  | "EMPTY_LAST_NAME_ERROR"
  | "EMPTY_GENDER_ERROR"
  | "EMPTY_INITIALS_ERROR"
  | "INVALID_INITIALS_ERROR";

type ActionUpdateErrorsSignupUserInfo = {
  type: "UPDATE_ERRORS_SIGNUP_USERINFO";
  errors: Array<SignupUserInfoError>;
};

type ActionUpdateLoadingStateUserInfo = {
  type: "UPDATE_LOADING_SIGNUP_USERINFO";
  loading: boolean;
};

export type Action =
  | ActionAddSignupFirstName
  | ActionAddSignupLastNamePrefix
  | ActionAddSignupLastName
  | ActionAddSignupGender
  | ActionAddSignupInitials
  | ActionUpdateErrorsSignupUserInfo
  | ActionUpdateLoadingStateUserInfo;

export const addSignupFirstName = (firstName: string): Action => ({
  type: "ADD_SIGNUP_FIRSTNAME",
  firstName,
});

export const addSignupLastNamePrefix = (lastNamePrefix: string): Action => ({
  type: "ADD_SIGNUP_LASTNAME_PREFIX",
  lastNamePrefix,
});

export const addSignupLastName = (lastName: string): Action => ({
  type: "ADD_SIGNUP_LASTNAME",
  lastName,
});

export const addSignupGender = (gender: "MALE" | "FEMALE"): Action => ({
  type: "ADD_SIGNUP_GENDER",
  gender,
});

export const addSignupInitials = (initials: string): Action => ({
  type: "ADD_SIGNUP_INITIALS",
  initials,
});

export const updateLoadingState = (loading: boolean): Action => ({
  type: "UPDATE_LOADING_SIGNUP_USERINFO",
  loading,
});

export const updateUserInfoErrors = (
  errors: Array<SignupUserInfoError>
): Action => {
  return {
    type: "UPDATE_ERRORS_SIGNUP_USERINFO",
    errors,
  };
};

export const setUserInfoState = (input: UserState) => (dispatch: Dispatch) => {
  dispatch(updateLoadingState(true));
  let errors: Array<SignupUserInfoError> = [];

  if (!input.firstName) errors.push("EMPTY_FIRST_NAME_ERROR");

  if (!input.lastName) errors.push("EMPTY_LAST_NAME_ERROR");

  if (!input.gender) errors.push("EMPTY_GENDER_ERROR");

  if (!input.initials) errors.push("EMPTY_INITIALS_ERROR");
  else if (!validateInitials(input.initials))
    errors.push("INVALID_INITIALS_ERROR");

  if (errors.length > 0) {
    dispatch(updateUserInfoErrors(errors));
    dispatch(updateLoadingState(false));
  } else {
    dispatch(increaseStepperIndex());
    dispatch(updateLoadingState(false));
  }
};
