import React, { useEffect, useState } from "react";
import { filter } from "graphql-anywhere";
import { withRouter, WithRouterProps } from "react-router";
import { AdministrationHeader as Header } from "./components/header";
import { PolicyList, searchFragment } from "./components/policyList";
import { CircularProgress } from "@mui/material";
import PolicyStateFilterSelector from "./components/policyStateFilter";
import SearchTypeSelector, { SearchType } from "./components/searchType";
import MatchTypeSelector from "./components/MatchTypeFilter";
import {
  serializeStateFilter,
  serializeSearchType,
  serializeMatchType,
} from "./serializers";
import { gql } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { insurerPaths } from "screens/routes";
import { useLazyQueryForApp } from "utils/withApollo";
import { NavBarSearchField } from "components/textfield/SearchField";
import { useEffectWithDelay } from "utils/reactExtensions";
import {
  getMatchTypeFromLocation,
  getPolicyStateFilterFromLocation,
  getQueryFromLocation,
  getSearchTypeFromLocation,
  MATCH_PARAM,
  POLICY_STATE_FILTER_PARAM,
  SEARCH_QUERY_PARAM,
  SEARCH_TYPE_PARAM,
  useShouldScrollToNextPage,
} from "./utils";
import { Box } from "@mui/system";
import { FloatingAddButton } from "components/button/buttons";

const styles: { [key: string]: React.CSSProperties } = {
  searchBarContainer: {
    position: "fixed",
    backgroundColor: "white",
    zIndex: 1000,
    width: "100%",
    paddingTop: "8px",
    paddingBottom: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    position: "absolute",
  },
  loadMore: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: "20px",
  },
  floatingActionButton: {
    position: "fixed",
    bottom: 50,
    right: 50,
    //Need to add this otherwise the FAB goes below the content on the screen.
    zIndex: 1,
  },
};

export const AdministrationPage = withRouter((props: WithRouterProps) => {
  const { router, location } = props;

  const [policyStateFilter, setPolicyStateFilter] = useState(
    getPolicyStateFilterFromLocation(location)
  );
  const [searchType, setSearchType] = useState(
    getSearchTypeFromLocation(location)
  );
  const [matchType, setMatchType] = useState(
    getMatchTypeFromLocation(location)
  );

  const [query, setQuery] = useState(getQueryFromLocation(location));

  const [loadData, { data, loading, fetchMore }] = useLazyQueryForApp(
    ADMINISTRATION_QUERY,
    {
      variables: {
        query,
        states: serializeStateFilter(policyStateFilter),
        searchType: serializeSearchType(searchType),
        matchType: serializeMatchType(matchType),
        after: null,
      },
    }
  );

  const fetchRecords = () => {
    const body = document.body;
    body.scrollTop = 0;

    router.push({
      pathname: location.pathname,
      query: {
        [SEARCH_QUERY_PARAM]: query,
        [POLICY_STATE_FILTER_PARAM]: policyStateFilter,
        [SEARCH_TYPE_PARAM]: searchType,
        [MATCH_PARAM]: matchType,
      },
    });
    loadData({
      variables: {
        query,
        states: serializeStateFilter(policyStateFilter),
        searchType: serializeSearchType(searchType),
        matchType: serializeMatchType(matchType),
        after: null,
      },
    });
  };

  //eslint-disable-next-line
  useEffect(() => fetchRecords(), []);

  useEffectWithDelay(() => fetchRecords(), 500, [
    query,
    policyStateFilter,
    searchType,
    matchType,
  ]);

  const viewer = data?.viewer;

  const nextPage = viewer?.search?.pageInfo?.endCursor;

  const loadMore = () => {
    fetchMore({
      variables: {
        after: nextPage,
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newEdges = fetchMoreResult.viewer.search.edges;
        return {
          viewer: {
            ...fetchMoreResult.viewer,
            search: {
              ...fetchMoreResult.viewer.search,
              edges: [...previousResult.viewer.search.edges, ...newEdges],
            },
          },
        };
      },
    });
  };

  useShouldScrollToNextPage(
    viewer?.search?.pageInfo?.hasNextPage,
    loading,
    nextPage,
    loadMore
  );

  let content;
  if (!viewer) {
    content = (
      <div style={styles.center}>
        <CircularProgress />
      </div>
    );
  } else {
    content = (
      <>
        <div style={styles.searchBarContainer}>
          <Box width="900px">
            <Typography textAlign={"center"} sx={{ paddingTop: "10px" }}>
              {viewer.search.edges.length} van de {viewer.search.totalCount}{" "}
              gevonden leden
            </Typography>
            <Stack spacing={4} direction="row" sx={{ paddingTop: "10px" }}>
              <PolicyStateFilterSelector
                selectedFilter={policyStateFilter}
                onChangeFilter={(selectedFilter) =>
                  setPolicyStateFilter(selectedFilter)
                }
              />
              <SearchTypeSelector
                selectedFilter={searchType}
                onChangeFilter={(searchType) => setSearchType(searchType)}
              />
              {searchType === SearchType.MEMBER_ID && (
                <MatchTypeSelector
                  selectedFilter={matchType}
                  onChangeFilter={(matchType) => setMatchType(matchType)}
                />
              )}
            </Stack>
          </Box>
        </div>

        <div style={{ paddingTop: "140px", ...styles.loadMore }}>
          <PolicyList data={filter(searchFragment, viewer.search)} />
          {loading && <CircularProgress color="primary" />}
        </div>
      </>
    );
  }

  return (
    <>
      <Header
        canDownloadMembershipCard={
          data?.viewer?.association?.membershipCardTemplateKey != null
        }
        searchField={
          <Stack direction={"row"}>
            <NavBarSearchField
              value={query}
              onChange={(e: any) => setQuery(e.target.value)}
            />
            {loading && (
              <Box display={"flex"} alignItems="center">
                <CircularProgress size={"1.5rem"} color="inherit" />
              </Box>
            )}
          </Stack>
        }
      />
      {content}
      <FloatingAddButton
        onClick={() => router.push(insurerPaths.bestuurder.signupMember)}
      />
    </>
  );
});

export const ADMINISTRATION_QUERY = gql`
  query searchAdministration(
    $query: String!
    $after: String
    $states: [PolicyState!]
    $searchType: SearchType
    $matchType: MatchType
  ) {
    viewer {
      id
      association {
        id
        membershipCardTemplateKey
      }
      search(
        query: $query
        first: 10
        after: $after
        states: $states
        searchType: $searchType
        matchType: $matchType
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
        ...search
      }
    }
  }
  ${searchFragment}
`;

export default AdministrationPage;
