// @flow

import { serializeDate, parseJSDate } from "./formatter";
import {
  validatePhone,
  isNumber,
  isDate,
  isValidPassword,
  isAmount,
  isEmail,
} from "./validation";

export type Validator = (value?: string | null) => string | undefined;

export const required: Validator = (value) =>
  value ? undefined : "Dit veld is verplicht";

export const mustBePhone: Validator = (value) =>
  value && validatePhone(value)
    ? undefined
    : "Dit veld moet een geldig telefoonnummer bevatten";

export const mustBeNumber: Validator = (value) =>
  value && isNumber(value) ? undefined : "Dit veld moet een geldig bedrag zijn";

export const mustBeLargerThanOrEqualTo: (lowerBound: number) => Validator =
  (lowerBound: number) => (value) =>
    value && Number(value) >= lowerBound
      ? undefined
      : "Dit veld moet groter of gelijk zijn aan " + lowerBound;

export const mustBeSmallerThanOrEqualTo: (upperBound: number) => Validator =
  (upperBound: number) => (value) =>
    value && Number(value) <= upperBound
      ? undefined
      : "Dit veld moet kleiner of gelijk zijn aan " + upperBound;

export const mustBeDate: Validator = (value) =>
  value && isDate(value)
    ? undefined
    : "Moet een datum zijn in vorm DD-MM-YYYY zoals '25-01-2020'";

export const mustBeAfterDate: (baseDate: Date) => Validator =
  (baseDate) => (value) =>
    value && new Date(serializeDate(value)) > baseDate
      ? undefined
      : `Datum moet na datum ${parseJSDate(baseDate)} zijn`;

export const mustBePassword: Validator = (value) =>
  value && isValidPassword(value)
    ? undefined
    : "Het nieuwe wachtwoord moet minstens 8 tekens bevatten, waarvan minstens 1 letter en 1 cijfer.";

export const mustBeAmount: Validator = (value) =>
  value && isAmount(value) ? undefined : "Dit veld moet een geldig bedrag zijn";

export const mustBeEmail: Validator = (value) =>
  value && isEmail(value) ? undefined : "Dit veld moet een geldige email zijn";

export const composeValidators =
  (...validators: Array<Validator>): Validator =>
  (value) =>
    validators.reduce(
      (error: string | undefined, validator: Validator) =>
        error || validator(value),
      undefined
    );
