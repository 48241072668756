// @flow

import React from "react";
import DefaultPage from "../components/defaultPage";
import SterfgevalPietersrade from "./sterfgevalMeldenPietersrade";
import SterfgevalMeldenHulpEnSteun from "./sterfgevalMeldenHulpEnSteun";

import config, { AssociationType } from "../../../config";

const { association } = config;

const SterfgevalMelden = () => {
  let content;
  switch (association) {
    case AssociationType.BCB:
      content = null;
      break;
    case AssociationType.PIETERSRADE:
      content = <SterfgevalPietersrade />;
      break;
    case AssociationType.HULP_EN_STEUN:
      content = <SterfgevalMeldenHulpEnSteun />;
      break;
    default:
      throw new Error(`Unknown association type ${association}`);
  }
  return <DefaultPage>{content}</DefaultPage>;
};

export default SterfgevalMelden;
