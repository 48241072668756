//@flow

import Header from "../../screens/ledenPortaal/header/homeHeader";
import RaisedButton from "material-ui/RaisedButton";
import { withRouter, WithRouterProps } from "react-router";

const styles: { [key: string]: React.CSSProperties } = {
  outerContainer: {
    textAlign: "center",
    paddingTop: 164,
    paddingLeft: 40,
    paddingRight: 40,
    flexDirection: "column",
    fontSize: "26px",
  },
};

type Props = WithRouterProps;

/**
 * Shows the stepper for signing up.
 *
 * @author Florentijn Hogerwerf
 */
export const SignupSuccessPage = (props: Props) => {
  const { router } = props;
  return (
    <div>
      <Header />
      <div style={styles.outerContainer}>
        Uw account en het gekozen lidmaatschap zijn succesvol geregistreerd.
        <br /> <br />
        U ontvangt binnen enkele minuten een email met daarin informatie over uw
        aanmelding. Deze email bevat ook een link om uw account te activeren.
        <br />
        <br />
        Klik op de onderstaande knop om in te loggen in uw persoonlijke
        omgeving.
        <br />
        <br />
        <RaisedButton
          label="Inloggen"
          disableTouchRipple={true}
          disableFocusRipple={true}
          primary={true}
          labelStyle={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            router.push("/account/lidmaatschappen");
          }}
        />
      </div>
    </div>
  );
};

const wRouter = withRouter(SignupSuccessPage);
export default wRouter;
