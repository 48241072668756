// @flow
/**
 * Created by Dirk-Jan Rutten on 01/11/16.
 */

import React, { PropsWithChildren } from "react";
import Userinfo, { userInfoFragment } from "./policyCardUserInfo";
import Policyinfo, { policyInfoFragment } from "./policyCardPolicyInfo";
import { withRouter, WithRouterProps } from "react-router";
import { gql } from "@apollo/client";
import { filter } from "graphql-anywhere";
import { colors } from "../../../../styling/index";
import MultiPoliciesDialog, {
  MultiPoliciesDialogFragment,
} from "./multiplePoliciesDialog";
import { Box, Dialog, Paper } from "@mui/material";
import SplitButton from "./DetailsButton";
import { pushWithPreviousPath } from "utils/routing";
import { insurerPaths } from "screens/routes";

const styles: { [key: string]: React.CSSProperties } = {
  paperElement: {
    height: "100%",
    width: "100%",
    textAlign: "center",
    position: "relative",
  },
  policyInfoRowContainer: {
    paddingTop: "12px",
    marginLeft: "40px",
    marginRight: "10px",
    textAlign: "left",
  },
  // firstLinesHeight: {
  //   display: "flex",
  //   flexDirection: "row",
  // },
  multiDialog: {
    width: "800px",
  },
};

type Props = {
  data: any;
} & WithRouterProps;

type State = {
  show: boolean;
};
/**
 * Represents a 'card' that contains the data for a single policy.
 */
class PolicyCardComponent extends React.Component<Props, State> {
  state: State = {
    show: false,
  };

  handleClose() {
    this.setState({
      show: false,
    });
  }

  render() {
    const { data } = this.props;

    const pushRoute = (route: string) =>
      pushWithPreviousPath(this.props, route);

    const button = (
      <div
        style={{
          position: "absolute",
          textAlign: "right",
          top: "30px",
          right: "30px",
        }}
      >
        <SplitButton
          onDetailsAccount={
            data.account &&
            (() =>
              pushRoute(insurerPaths.accountsDetails.path(data.account.id)))
          }
          onDetailsMember={() => {
            const length = data.policies.length;
            if (length === 0) return;
            else if (length === 1)
              pushRoute(
                insurerPaths.policyDetailsPage.path(data.policies[0].id)
              );
            else this.setState({ show: true });
          }}
          onDetailsGroup={() => pushRoute(insurerPaths.group.path(data.id))}
        />
      </div>
    );

    if (data.policies.length === 0) {
    }
    if (data.policies.length === 1) {
      return (
        <PaperContainer>
          <div style={styles.firstLinesHeight}>
            <Userinfo data={filter(userInfoFragment, data)} />
            {button}
          </div>
          <div
            style={{
              ...styles.policyInfoRowContainer,
              paddingTop: "16px",
              color: colors.green,
            }}
          >
            <div style={{ paddingBottom: "8px" }}>Lidmaatschap </div>
            <div style={{ height: "1px", backgroundColor: "lightgray" }} />
          </div>
          <Policyinfo data={filter(policyInfoFragment, data.policies[0])} />
        </PaperContainer>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PaperContainer>
            <div style={styles.firstLinesHeight}>
              <Userinfo data={filter(userInfoFragment, data)} />
              {button}
            </div>
            <div
              style={{
                ...styles.policyInfoRowContainer,
                paddingTop: "16px",
                color: colors.green,
              }}
            >
              <div style={{ paddingBottom: "8px" }}>Lidmaatschap </div>
              <div style={{ height: "1px", backgroundColor: "lightgray" }} />
              <div
                style={{
                  color: colors.lightGrey,
                  textAlign: "left",
                }}
              >
                <p>{data.policies.length} lidmaatschappen gevonden.</p>
                <p>Klik op details om ze te bekijken.</p>
              </div>
            </div>

            <Dialog
              open={this.state.show}
              onClose={() => this.handleClose()}
              scroll={"paper"}
              fullWidth
              maxWidth="md"
            >
              <MultiPoliciesDialog
                data={filter(MultiPoliciesDialogFragment, data)}
                handleClose={() => this.handleClose()}
              />
            </Dialog>
          </PaperContainer>
        </div>
      );
    }
  }
}

const PaperContainer = (props: PropsWithChildren<{}>) => (
  <Paper elevation={2} sx={styles.paperElement}>
    <Box sx={{ height: "100%", padding: "20px", paddingTop: "0px" }}>
      {props.children}
    </Box>
  </Paper>
);

export const cardFragment = gql`
  fragment card on InsuredPerson {
    id
    account {
      id
    }
    policies {
      id
      ...policy
    }
    ...user
    ...multiPoliciesDialog
  }

  ${userInfoFragment}
  ${policyInfoFragment}
  ${MultiPoliciesDialogFragment}
`;

export const PolicyCard = withRouter(PolicyCardComponent);
