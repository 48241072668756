// @flow

import React from "react";
import ExpandablePage from "../../components/expandablePageSection";

import Link from "../../../link";
import StatutesQuery from "../associationQuery";

const StatutenPietersrade = () => {
  return (
    <ExpandablePage title="Statuten en huishoudelijk reglement">
      <StatutesQuery
        errorMessage={
          "Er is een fout opgetreden met het ophalen van de statuten."
        }
        render={(node) => (
          <p>
            Download de statuten door op {/* @ts-ignore */}
            <Link href={node.statutesUrl}>deze</Link> link te klikken.
          </p>
        )}
      />
    </ExpandablePage>
  );
};

export default StatutenPietersrade;
