import { Box, ListItem, ListItemText } from "@mui/material";
import { FragmentType, gql, useFragment } from "generated";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { UpdateAccountPhoneButton } from "./updatePhoneButton";
import { UpdateAccountNameButton } from "./updateName";
import { UpdateAccountEmailButton } from "./updateEmail";

type Props = {
  data: FragmentType<typeof AccountInfoFragment>;
};

export const AccountInfo = (props: Props) => {
  const data = useFragment(AccountInfoFragment, props.data);

  const dataList = [
    {
      key: "Naam",
      value: data.fullName,
      edit: (
        <UpdateAccountNameButton
          accountId={data.id}
          firstName={data.firstName}
          lastName={data.lastName}
          namePreposition={data.namePreposition}
        />
      ),
    },
    {
      key: "Email",
      value: data.username || "niet gezet",
      edit: <UpdateAccountEmailButton accountId={data.id} />,
    },
    {
      key: "Telefoonnummer",
      value: data.phone || "niet gezet",
      edit: (
        <UpdateAccountPhoneButton
          accountId={data.id}
          currentPhone={data.phone}
        />
      ),
    },
    {
      key: "Is email geverifieerd?",
      value: data.verified === true ? "Ja" : "Nee",
    },
  ];

  return (
    <Box display="flex" justifyContent={"center"}>
      <Grid container spacing={2} width="600px">
        {dataList.map(({ key, value, edit }) => (
          <Grid xs={6} key={key}>
            <ListItem>
              <ListItemText primary={key} secondary={value} />
              {edit}
            </ListItem>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const AccountInfoFragment = gql(`
fragment AccountDetailsInfo on Account {
    id
    username
    verified
    fullName
    firstName
    lastName
    namePreposition
    phone
}
`);
