//@flow

import React from "react";
import Checkbox from "material-ui/Checkbox";

type Props = {
  onCheck: (checked: boolean) => void;
  associationName: string;
};

const ContributionCheck = (props: Props) => {
  const label = (
    <div>
      <b>
        {`Ik geef toestemming aan ${props.associationName} om doorlopende incasso-opdrachten te sturen naar mijn bank om een bedrag van mijn rekening af te schrijven en aan mijn bank om doorlopend een bedrag van uw rekening af te schrijven overeenkomstig de opdracht van ${props.associationName}`}
      </b>
    </div>
  );
  return (
    <div>
      <Checkbox
        data-test="contribution_check"
        id="checkmark"
        label={label}
        onCheck={(event: any) => props.onCheck(event.target.checked)}
      />
    </div>
  );
};

export default ContributionCheck;
