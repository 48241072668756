// @flow

import React from "react";
import ExpandablePage from "../../components/expandablePageSection";

const Aanpassingen = () => (
  <ExpandablePage title="Aanpassingen op uw lidmaatschap">
    <p>U kunt als lid een wijziging doorvoeren aan uw lidmaatschap.</p>
    <p>
      Zo is het mogelijk om over te stappen van begraven en cremeren en vice
      versa. Voor bestaande leden tot en met leeftijd 64 jaar is dit mogelijk
      d.m.v. een aanpassing van de premie.
    </p>
    <p>
      Vanaf 65 jaar kan alleen gewisseld worden van begraven naar crematie door
      crematie inkoop. U betaalt dan éénmalig een bepaald bedrag en de
      contributie voor begraven blijft gehandhaafd.
    </p>
    <p>
      Leden zijn zelf verantwoordelijk voor het melden van veranderingen, zoals
      huwelijk, verhuizing, etc. De vereniging kan nooit aansprakelijk worden
      gesteld voor uw verzuim om wijzigingen door te geven en/of het doorgeven
      van foutieve gegevens. Wij verzoeken u daarom indringend om uw
      persoonlijke gegevens regelmatig te controleren, zo nodig te corrigeren of
      fouten aan de ledenadministratie te melden.
    </p>
  </ExpandablePage>
);

export default Aanpassingen;
