// @flow

import { Component } from "react";
import { serializePostalCode } from "../../utils";
import { AddressInput } from "./addressInput";
import { errorHandler, ErrorHandler } from "../../containers";
import { graphql } from "@apollo/client/react/hoc";
import { gql } from "generated";
import {
  UpdateAddressMutation,
  UpdateAddressMutationVariables,
} from "generated/graphql";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  DialogCancelButton,
  DialogConfirmButton,
} from "components/button/buttons";

type PropsParent = {
  handleClose: () => void;
  insuredPersonIds: string[];
  open: boolean;
  description?: string;
};
type PropsGraphQL = {
  updateAddress: (
    input: UpdateAddressMutationVariables["input"]
  ) => Promise<any>;
};
type Props = PropsParent & ErrorHandler & PropsGraphQL;

type State = {
  state:
    | {
        completed: true;
        postalCode: string;
        houseNumber: number;
        suffix: string | null;
        street: string;
        city: string;
        country: string;
      }
    | {
        completed: false;
      };
};

export class AddressDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      state: {
        completed: false,
      },
    };
  }

  async _updateAddress() {
    const { state } = this.state;
    if (state.completed) {
      const { street, city, postalCode, houseNumber, suffix, country } = state;
      const { error, updateAddress, insuredPersonIds } = this.props;
      const input = {
        postalCode: serializePostalCode(postalCode),
        houseNumber,
        suffix,
        city,
        street,
        country,
        insuredPersonIds,
      };

      try {
        await updateAddress(input);
        window.location.reload();
      } catch (e) {
        error.display();
      }
    }
  }

  render() {
    const { state } = this.state;
    const disabled = !state.completed;
    const { open, handleClose } = this.props;

    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Wijzig Adres</DialogTitle>
        <DialogContent>
          {this.props.description && (
            <>
              <DialogContentText>{this.props.description}</DialogContentText>
              <br />
            </>
          )}
          <AddressInput
            onCompleted={(input) => {
              this.setState({
                state: {
                  completed: true,
                  ...input,
                },
              });
            }}
            onIncomplete={() => {
              this.setState({
                state: {
                  completed: false,
                },
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <DialogCancelButton
            onClick={() => {
              this.setState({
                state: {
                  completed: false,
                },
              });
              this.props.handleClose();
            }}
          />
          <DialogConfirmButton
            disabled={disabled}
            onClick={() => this._updateAddress()}
          >
            Bevestig
          </DialogConfirmButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export const UPDATE_ADDRESS_MUTATION = gql(`
  mutation updateAddress($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      addresses {
        id
        postalCode
        houseNumber
        suffix
        street
        city
        countryCode
      }
    }
  }
`);

export const withMutation = graphql<
  PropsParent,
  UpdateAddressMutation,
  UpdateAddressMutationVariables,
  PropsGraphQL
>(UPDATE_ADDRESS_MUTATION, {
  props: ({ mutate }): PropsGraphQL => ({
    updateAddress: (input) => mutate({ variables: { input } }),
  }),
});

export const UpdateAddressDialog = withMutation(errorHandler(AddressDialog));
