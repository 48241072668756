import { PolicyCard, cardFragment } from "./policyCard/index";
import { colors } from "../../../styling";
import { gql } from "@apollo/client";
import { filter } from "graphql-anywhere";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/material";

const stylesList = {
  gridRowContainer: {
    display: "flex",
    width: "900px",
    paddingBottom: "30px",
  },
  emptyScreen: {
    color: colors.grey,
    fontSize: "40px",
    fontWeight: 300,
  },
};

type Props = {
  data: {
    edges: Array<{
      node: {
        id: string;
      };
    }>;
  };
};

/**
 * Component that displays a list of policies.
 */
export const PolicyList = (props: Props) => {
  if (props.data == null) return null;

  const numPolicies = props.data.edges.length;
  if (numPolicies === 0) {
    return (
      <div style={stylesList.emptyScreen}>
        We hebben geen leden kunnen vinden
      </div>
    );
  }

  return (
    <Box sx={stylesList.gridRowContainer}>
      <Grid container spacing={2} sx={{ width: "100%" }}>
        {props.data.edges.map((edge, index) => (
          <Grid xs={6} key={index}>
            <PolicyCard data={filter(cardFragment, edge.node)} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export const searchFragment = gql`
  fragment search on SearchResultConnection {
    edges {
      node {
        ... on InsuredPerson {
          id
          ...card
        }
      }
    }
  }
  ${cardFragment}
`;
