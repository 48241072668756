import { Box, Typography } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { SideBar } from "components/header";
import { CenterInScreen, ScreenContainer } from "components/layout";
import { withRouter, WithRouterProps } from "react-router";
import { AccountInfo } from "./components/accountInfo";
import { InsuredPeopleTable } from "./components/insuredPeopleTable";
import { useAccountQuery } from "./graphql";
import { Header } from "./header";

type Props = WithRouterProps;

const AccountDetailsInner = (props: Props) => {
  const accountId = props.params.id;
  const { data, loading } = useAccountQuery(accountId);

  return (
    <div>
      <Header accountId={data?.account?.id} />
      <SideBar />
      {loading && (
        <CenterInScreen>
          <CircularProgress />
        </CenterInScreen>
      )}
      <ScreenContainer>
        {!loading && !data?.account && (
          <CenterInScreen>
            <Typography variant="h2">Geen account gevonden</Typography>
          </CenterInScreen>
        )}
        {data?.account && !loading && (
          <>
            <AccountInfo data={data.account} />
            <Box sx={{ paddingTop: "40px" }} />
            <InsuredPeopleTable
              data={data.account}
              goToPolicy={(policyId) =>
                props.router.push({
                  pathname: `/bestuurder/leden/${policyId}`,
                  query: {
                    // eslint-disable-next-line
                    prevPath: location.pathname + String(location.search),
                  },
                })
              }
            />
          </>
        )}
      </ScreenContainer>
    </div>
  );
};

export const AccountDetails = withRouter(AccountDetailsInner);
