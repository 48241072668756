// @flow

import React, { Component } from "react";
import AppBar from "material-ui/AppBar";
import { withRouter, WithRouterProps } from "react-router";
import FlatButton from "material-ui/FlatButton";
import HeaderLogo from "./logo";
import config, { AssociationType } from "../../../config";

const { association } = config;

const showSignupMember =
  association !== AssociationType.PIETERSRADE &&
  association !== AssociationType.HULP_EN_STEUN;
const showBestuurOption = association !== AssociationType.HULP_EN_STEUN;
const showDeceasedOption = association !== AssociationType.BCB;

const showFAQ = association === AssociationType.HULP_EN_STEUN;
const onlyLoginBestuur = association === AssociationType.HULP_EN_STEUN;
const styles: { [key: string]: React.CSSProperties } = {
  appBar: {
    backgroundColor: "#484848",
    top: 0,
  },
  buttons: {
    backgroundColor: "transparent",
    color: "white",
    display: "flex",
  },
};

type Props = WithRouterProps;

const getWidth = () => window.innerWidth;

type State = {
  width: number;
};

/**
 * This is the header displayed at the top of the page.
 *
 * Note, this is a reponsive element. It is sticky in full screen mode but not when the screen is reduced in
 * size. This allows for a scrollable website when the size of the screen is reduced to below 1024px.
 */
class Header extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // $FlowFixMe
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {
      width: getWidth(),
    };
  }

  updateDimensions() {
    this.setState({
      width: getWidth(),
    });
  }
  componentWillMount() {
    this.updateDimensions();
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  render() {
    let { router } = this.props;
    const { width } = this.state;

    let rightButtons = (
      <div>
        <FlatButton
          label="Home"
          labelStyle={{ color: "white" }}
          onClick={() => {
            router.push("/");
          }}
        />
        {showFAQ && (
          <FlatButton
            label="Veel gestelde vragen"
            labelStyle={{ color: "white" }}
            onClick={() => {
              router.push("/vragen");
            }}
          />
        )}
        <FlatButton
          label="Informatie"
          labelStyle={{ color: "white" }}
          onClick={() => {
            router.push("/informatie");
          }}
        />
        <FlatButton
          label="Pakketten"
          labelStyle={{ color: "white" }}
          onClick={() => {
            router.push("/pakketten");
          }}
        />
        {showSignupMember && (
          <FlatButton
            label="Lid worden"
            labelStyle={{ color: "white" }}
            onClick={() => {
              router.push("/inschrijven");
            }}
          />
        )}
        {showBestuurOption && (
          <FlatButton
            label="Bestuur"
            labelStyle={{ color: "white" }}
            onClick={() => {
              router.push("/bestuur");
            }}
          />
        )}
        {showDeceasedOption && (
          <FlatButton
            label="Sterfgeval melden"
            labelStyle={{ color: "white" }}
            onClick={() => {
              router.push("/sterfgeval");
            }}
          />
        )}
        <FlatButton
          label="Contact"
          labelStyle={{ color: "white" }}
          onClick={() => {
            router.push("/contact");
          }}
        />
        <FlatButton
          label={onlyLoginBestuur ? "Inloggen bestuur" : "Inloggen"}
          labelStyle={{ color: "white" }}
          onClick={() => {
            router.push("/inloggen");
          }}
        />
      </div>
    );

    return (
      <div>
        <AppBar
          style={{
            ...styles.appBar,
            position: width > 1024 ? "fixed" : "relative",
          }}
          iconElementLeft={<HeaderLogo />}
          iconElementRight={rightButtons}
          iconStyleRight={{
            marginTop: "auto",
            marginBottom: "auto",
            height: "100%",
          }}
        />
      </div>
    );
  }
}

const DecoratedHeader = withRouter(Header);

export default DecoratedHeader;
