// @flow

import React from "react";
import { colors } from "../../../../styling";

const styles: { [key: string]: React.CSSProperties } = {
  keyValueContainer: {
    display: "flex",
    paddingTop: "20px",
    fontSize: "18px",
  },
  key: {
    alignItems: "center",
    color: colors.green,
    flex: 1,
  },
  value: {
    flex: 1,
    textAlign: "right",
  },
};

type Props = {
  name: string;
  value: string;
  button?: any;
  "data-test"?: string | null;
};

const Row = (props: Props) => (
  <div style={styles.keyValueContainer}>
    <div style={styles.key}>{props.name}:</div>
    <div style={styles.value} data-test={props["data-test"]}>
      {props.value}
    </div>
    <div style={{ flex: 1 }}>{props.button}</div>
  </div>
);

export { Row };
