import { Alert } from "@mui/material";

export const InfoAlert = () => (
  <Alert severity="info">
    Alle mutaties worden getoond, om te filteren op specifieke mutaties klik op
    de filterknop rechtsboven in het scherm.
  </Alert>
);

export const FilterActiveAlert = ({ count }: { count: number }) => (
  <Alert severity="warning">
    Let op, er {count === 1 ? "is" : "zijn"} {count} filter
    {count === 1 ? "" : "s"} toegepast. Klik op de filterknop rechtsbovenin om
    dit aan te passen.
  </Alert>
);
