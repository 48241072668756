// @flow

import React, { Component } from "react";
import { gql } from "@apollo/client";

import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import CircularProgress from "material-ui/CircularProgress";
import { SignupCardAccount } from "../signupStepperComponents";
import { SignupCardPolicy } from "../signupStepperComponents";
import { SignupCardFinalStatement } from "../signupStepperComponents";
import type { PolicyAccountState } from "../../../actions";
import { connect } from "react-redux";
import type { Dispatch, State as ReduxState } from "../../../reducers";
import { registerPolicy } from "../../../actions";
import { USER_QUERY } from "../../ledenPortaal/policiesPage/policyPage";
import { Frequency } from "generated/graphql";

type PropsReduxState = {
  policyData: {
    quoteId: string;
    birthDate: string;
    premium: number;
    lumpsum: number;
    frequency: Frequency;
    packageId: string;
    packageName: string;
    insuredAmount: number;
    firstName: string;
    lastNamePrefix: string;
    lastName: string;
    initials: string;
    gender: "MALE" | "FEMALE";
    iban: string;
    accountName: string;
    firstNameAccount: string;
    lastNameAccount: string;
    lastNamePrefixAccount: string;
    phone: string;
    email: string;
    password: string;
    eligibility: Array<{
      questionId: string;
      response: boolean;
      explanation: string;
    }>;
  };
  isFetching: boolean;
};

type PropsParents = {
  address: {
    street: string;
    city: string;
    postalCode: string;
    houseNumber: number;
    suffix: string | null;
  };
  data: {
    statutesUrl: string;
    name: string;
  };
  backPressed: () => void;
};

type PropsDispatch = {
  registerPolicy(input: PolicyAccountState): void;
};

type Props = PropsParents & PropsDispatch & PropsReduxState;
type State = {
  isChecked: boolean;
  isMailChecked: boolean;
  isContributionChecked: boolean;
};

class OverviewStep extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isChecked: false,
      isMailChecked: false,
      isContributionChecked: false,
    };
  }

  _performMutation() {
    const { address, policyData } = this.props;

    this.props.registerPolicy({
      firstNameAccount: policyData.firstNameAccount,
      lastNameAccount: policyData.lastNameAccount,
      lastNamePrefixAccount: policyData.lastNamePrefixAccount,
      phone: policyData.phone,
      email: policyData.email,
      password: policyData.password,
      passwordConfirm: policyData.password,
      quoteId: policyData.quoteId,
      birthDate: policyData.birthDate,
      premium: policyData.premium,
      lumpsum: policyData.lumpsum,
      frequency: policyData.frequency,
      packageId: policyData.packageId,
      packageName: policyData.packageName,
      insuredAmount: policyData.insuredAmount,
      firstName: policyData.firstName,
      lastNamePrefix: policyData.lastNamePrefix,
      lastName: policyData.lastName,
      initials: policyData.initials,
      gender: policyData.gender,
      postalCode: address.postalCode,
      houseNumber: address.houseNumber,
      houseNumberSuffix: address.suffix,
      city: address.city,
      street: address.street,
      iban: policyData.iban,
      accountName: policyData.accountName,
      eligibility: policyData.eligibility,
    });
  }

  render() {
    const { backPressed, isFetching, policyData, address, data } = this.props;
    const { isChecked, isMailChecked, isContributionChecked } = this.state;
    const nextDisabled = !isChecked || !isContributionChecked || !isMailChecked;

    if (isFetching) return <CircularProgress />;
    else {
      return (
        <div style={{ width: "100%", maxWidth: "650px" }}>
          <SignupCardAccount accountData={policyData} />
          <br />
          <SignupCardPolicy
            policyData={{
              birthDate: policyData.birthDate,
              premium: policyData.premium,
              lumpsum: policyData.lumpsum,
              frequency: policyData.frequency,
              packageName: policyData.packageName,
              insuredAmount: policyData.insuredAmount,
              firstName: policyData.firstName,
              lastNamePrefix: policyData.lastNamePrefix,
              lastName: policyData.lastName,
              initials: policyData.initials,
              gender: policyData.gender,
              postalCode: address.postalCode,
              houseNumber: address.houseNumber,
              houseNumberSuffix: address.suffix,
              city: address.city,
              street: address.street,
              iban: policyData.iban,
              accountName: policyData.accountName,
            }}
          />
          <br />
          <SignupCardFinalStatement
            onCheckTerms={(isChecked) => {
              this.setState({ isChecked });
            }}
            onCheckMail={(isMailChecked) => {
              this.setState({ isMailChecked });
            }}
            onCheckContribution={(isContributionChecked) => {
              this.setState({ isContributionChecked });
            }}
            statutesUrl={data.statutesUrl}
            associationName={data.name}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "20px",
            }}
          >
            <div style={{ flex: 1, textAlign: "center" }}>
              <FlatButton
                label="Vorige"
                disableTouchRipple={true}
                disableFocusRipple={true}
                onClick={() => backPressed()}
              />
            </div>
            <div style={{ flex: 1, textAlign: "center" }}>
              <RaisedButton
                label="Aanmelden"
                disableTouchRipple={true}
                disableFocusRipple={true}
                primary={true}
                onClick={() => this._performMutation()}
                disabled={nextDisabled}
              />
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state: ReduxState): PropsReduxState => {
  const { signup } = state;

  const { quote } = signup.premium.policyInfo;

  if (!signup.userInfo.data.gender) {
    throw new Error(`Gender cannot be null`);
  }

  if (quote && quote.quoteExists) {
    const { data } = quote;
    const {
      quoteId,
      birthDate,
      premium,
      lumpsum,
      frequency,
      packageId,
      packageName,
      insuredAmount,
    } = data;
    return {
      policyData: {
        quoteId,
        birthDate,
        premium,
        lumpsum,
        frequency,
        packageId,
        packageName,
        insuredAmount,
        firstName: signup.userInfo.data.firstName,
        lastNamePrefix: signup.userInfo.data.lastNamePrefix,
        lastName: signup.userInfo.data.lastName,
        initials: signup.userInfo.data.initials,
        gender: signup.userInfo.data.gender,
        firstNameAccount: signup.account.data.firstName,
        lastNamePrefixAccount: signup.account.data.lastNamePrefix,
        lastNameAccount: signup.account.data.lastName,
        phone: signup.account.data.phone,
        email: signup.account.data.email,
        password: signup.account.data.password,
        iban: signup.payment.data.iban,
        accountName: signup.payment.data.accountName,
        eligibility: signup.eligibility.answers,
      },
      isFetching: signup.overview.isFetching,
    };
  } else throw new Error("Quote object is null.");
};

const mapDispatchToProps = (dispatch: Dispatch): PropsDispatch => ({
  registerPolicy: (input: PolicyAccountState) =>
    // @ts-ignore
    dispatch(registerPolicy(input, USER_QUERY)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const Wrapper = connector(OverviewStep);

const Wrapped = (props: PropsParents) => <Wrapper {...props} />;

export default Wrapped;
export const fragment = gql`
  fragment FinalCardFragment on Association {
    id
    statutesUrl
    name
  }
`;
