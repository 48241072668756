//@flow
import raven from "raven-js";

import { addQuote, type AddQuote } from "../../api";
import { isValidBirthdate, serializeDate } from "../../utils";
import { showAlertDialog } from "../alert";
import { Dispatch } from "../../reducers";
import { AnyAction } from "redux";
import { Frequency } from "generated/graphql";

export type PremiumError = "BIRTH_ERROR" | "PACKAGE_ERROR" | "FREQUENCY_ERROR";
export type Package = {
  id: string;
  name: string;
  insuredAmount: number;
  frequencies: Array<Frequency>;
};

interface ActionAddSignupBirthDate extends AnyAction {
  type: "ADD_SIGNUP_BIRTHDATE";
  birthDate: string;
}
interface ActionAddSignupPackageId extends AnyAction {
  type: "ADD_SIGNUP_PACKAGEID";
  packageId: string;
}
interface ActionAddSignupFrequency extends AnyAction {
  type: "ADD_SIGNUP_FREQUENCY";
  frequency: Frequency | null;
}

interface ActionAddSignUpQuote extends AnyAction {
  type: "ADD_SIGNUP_QUOTE";
  data: AddQuote;
}

interface ActionUpdateLoadingSignup extends AnyAction {
  type: "UPDATE_LOADING_SIGNUP_PREMIUM";
  loading: boolean;
}

interface ActionUpdateErrorsSignupPremium extends AnyAction {
  type: "UPDATE_ERRORS_SIGNUP_PREMIUM";
  errors: Array<PremiumError>;
}

export type Action =
  | ActionAddSignupBirthDate
  | ActionAddSignupPackageId
  | ActionAddSignupFrequency
  | ActionAddSignUpQuote
  | ActionUpdateLoadingSignup
  | ActionUpdateErrorsSignupPremium;

export const addSignupBirthDate = (birthDate: string): Action => ({
  type: "ADD_SIGNUP_BIRTHDATE",
  birthDate,
});

export const addSignupPackageId = (packageId: string): Action => ({
  type: "ADD_SIGNUP_PACKAGEID",
  packageId,
});

export const addSignupFrequency = (frequency: Frequency | null): Action => ({
  type: "ADD_SIGNUP_FREQUENCY",
  frequency,
});

export const addSignupQuote = (input: AddQuote): ActionAddSignUpQuote => ({
  type: "ADD_SIGNUP_QUOTE",
  data: input,
});

export const updateLoadingState = (loading: boolean): Action => ({
  type: "UPDATE_LOADING_SIGNUP_PREMIUM",
  loading,
});

export const updatePremiumErrors = (errors: Array<PremiumError>): Action => {
  return {
    type: "UPDATE_ERRORS_SIGNUP_PREMIUM",
    errors,
  };
};

export const calculatePremium =
  (birthDate: string, packageId: string | null, frequency: Frequency | null) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateLoadingState(true));

    const validBirthdate = isValidBirthdate(birthDate);

    if (!validBirthdate || frequency == null || packageId == null) {
      let errors: Array<PremiumError> = [];

      if (frequency == null) {
        errors.push("FREQUENCY_ERROR");
      }
      if (packageId == null) {
        errors.push("PACKAGE_ERROR");
      }
      if (!validBirthdate) {
        errors.push("BIRTH_ERROR");
      }

      dispatch(updatePremiumErrors(errors));
      dispatch(updateLoadingState(false));
    } else {
      try {
        const result = await addQuote(
          serializeDate(birthDate),
          packageId,
          frequency
        );
        dispatch(addSignupQuote(result));
      } catch (e) {
        dispatch(showAlertDialog());
        raven.captureException(e);
      }
      dispatch(updateLoadingState(false));
    }
  };
