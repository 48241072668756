import { gql } from "@apollo/client";

export const UPDATE_ACCOUNT_MUTATION = gql`
  mutation updateAccountMember($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      user {
        id
        lastName
      }
    }
  }
`;
