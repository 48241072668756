// @flow

import RaisedButton from "material-ui/RaisedButton";
import { colors } from "../../../../styling";
import UpdatePackage from "./update";
import UpdateFrequency from "./updateFrequency";
import SectionTitle from "../components/sectionTitle";
import { formatToEuro } from "../../../../utils";
import { Row as RowEntry } from "../components/row";
import TitleContainer from "../components/titleContainer";
import EditButton from "../components/editButton";
import { FragmentType, gql, useFragment } from "generated";
import { useState } from "react";
import { Frequency } from "generated/graphql";
import { formatFrequency2 } from "utils/formatter";
import { Dialog } from "@mui/material";
import { UpdateConstributionButton } from "./updateContribution";

type Props = {
  data: FragmentType<typeof packageDetailsFragmentDef>;
  disabled: boolean;
};

const convertFrequencyToText = (frequency: Frequency | null) =>
  frequency ? formatFrequency2(frequency) : "Onbekend";

const convertLumpsumToText = (lumpsum: number | null) =>
  lumpsum != null ? formatToEuro(lumpsum) : "Onbekend";

const PackageDetails = (props: Props) => {
  const { disabled } = props;
  const [show, setShow] = useState<
    "UPDATE_PACKAGE" | "UPDATE_FREQUENCY" | null
  >(null);

  const data = useFragment(packageDetailsFragmentDef, props.data);

  const close = () => setShow(null);

  let contentsForDialog = null;
  if (show === "UPDATE_PACKAGE") {
    contentsForDialog = <UpdatePackage handleClose={close} data={data} />;
  } else if (show === "UPDATE_FREQUENCY") {
    contentsForDialog = <UpdateFrequency handleClose={close} data={data} />;
  }

  const editButton = (
    <RaisedButton
      label="WIJZIG"
      data-test="update_package_button"
      backgroundColor={colors.green}
      labelStyle={{ color: "white" }}
      onClick={() => setShow("UPDATE_PACKAGE")}
      disabled={disabled}
    />
  );

  const frequencyButton = (
    <EditButton
      data-test="update_frequency_button"
      onClick={() => setShow("UPDATE_FREQUENCY")}
      disabled={disabled}
    />
  );

  const title = (
    <TitleContainer>
      <SectionTitle title="Pakketinformatie" />
      <div>{editButton}</div>
    </TitleContainer>
  );

  return (
    <div>
      <div>
        {title}
        <RowEntry
          name="Pakket"
          value={data.package ? data.package.name : "Geen pakket"}
        />
        {data.insuredAmount && (
          <RowEntry
            name="Verzekerd bedrag"
            value={formatToEuro(data.insuredAmount)}
          />
        )}
        <RowEntry
          name="Contributie"
          value={formatToEuro(data.premium)}
          button={
            <UpdateConstributionButton
              disabled={disabled}
              policyId={data.id}
              premium={data.premium}
            />
          }
        />
        <RowEntry
          data-test="payment_frequency_value"
          name="Betalingsfrequentie"
          // @ts-ignore
          value={convertFrequencyToText(data.frequency)}
          button={frequencyButton}
        />
        <RowEntry
          name="Koopsom"
          value={convertLumpsumToText(data.lumpsum)}
          data-test="lumpsum_value"
        />
      </div>
      <Dialog
        open={show != null}
        onClose={close}
        scroll="paper"
        fullWidth
        maxWidth="md"
      >
        {contentsForDialog}
      </Dialog>
    </div>
  );
};

export const packageDetailsFragmentDef = gql(`
  fragment PackageDetails on Policy {
    id
    premium
    lumpsum
    frequency
    package {
      id
      name
    }
    insuredAmount
    ...UpdatePackageInsurer
    ...UpdateFrequencyInsurer
  }
`);

export default PackageDetails;
