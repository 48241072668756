// @flow

import type { ActionsOverview } from "../../actions";

export type State = {
  isFetching: boolean;
};

const initialState: State = {
  isFetching: false,
};

const StepperOverview = (
  state: State = initialState,
  action: ActionsOverview
): State => {
  switch (action.type) {
    case "UPDATE_LOADING_SIGNUP_OVERVIEW": {
      return {
        isFetching: action.loading,
      };
    }
    default:
      return state;
  }
};

export default StepperOverview;
