// @flow

import React from "react";
import CircularProgress from "material-ui/CircularProgress";
import { Error as ErrorText } from "../../components/error";

import { Centered } from "../../components/layout";

export const Error = () => {
  return (
    <Centered>
      <ErrorText />
    </Centered>
  );
};

export const Loading = () => {
  return (
    <Centered>
      <CircularProgress />
    </Centered>
  );
};
