import { WithRouterProps } from "react-router";

export const pushWithPreviousPath = (
  { router, location }: WithRouterProps,
  path: string
) => {
  router.push({
    pathname: path,
    query: {
      // eslint-disable-next-line
      prevPath: location.pathname + String(location.search),
    },
  });
};
