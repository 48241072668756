//@flow

import { FragmentType, gql, useFragment } from "generated";
import SectionTitle from "../components/sectionTitle";
import DeleteInsuredPerson from "./deleteInsuredPerson";
import { ReactivatePolicyButton } from "./reactivate";
import { Box, Stack } from "@mui/material";

const DangerZone = (props: { data: FragmentType<typeof fragment> }) => {
  const data = useFragment(fragment, props.data);

  return (
    <Stack spacing={1}>
      <SectionTitle title="Gevarenzone" />
      <DeleteInsuredPerson insuredPersonId={data.insuredPerson.id} />
      <Box>
        <ReactivatePolicyButton data={data} />
      </Box>
    </Stack>
  );
};

const fragment = gql(`
fragment DangerZoneFragment on Policy {
  id
  insuredPerson {
    id
  }
  ...ReactivatePolicy
}`);

export default DangerZone;
