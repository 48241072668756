// @flow

import { gql } from "generated";
import React from "react";
import { colors } from "../../../styling";
import SectionTitle from "./components/sectionTitle";

const { lightGrey } = colors;

const textStyle = {
  fontSize: "18px",
};
type Props = {
  data: {
    eligibility: Array<{
      question: {
        id: string;
        question: string;
      };
      response: boolean;
      explanation: string | null;
    }>;
  };
};

const EligibilityContent = ({ data }: Props) => {
  let content;

  if (data.eligibility.length === 0) {
    content = (
      <p style={textStyle}>Er is geen verklaring beschikbaar voor dit lid.</p>
    );
  } else {
    content = data.eligibility.map((entry, index) => (
      <div key={index} style={textStyle}>
        <p>{entry.question.question}</p>
        <div style={{ margin: "10px" }} />
        <p style={{ color: lightGrey }}>
          {entry.response === true ? "Ja" : "Nee"}
        </p>
        <div style={{ margin: "10px" }} />
        <p style={{ color: lightGrey }}>{entry.explanation}</p>
      </div>
    ));
  }

  return (
    <div>
      <SectionTitle title="Gezondheidsverklaring" />
      {content}
    </div>
  );
};

export default EligibilityContent;

export const fragment = gql(`
  fragment Eligibility on Policy {
    eligibility {
      id
      question {
        id
        question
      }
      response
      explanation
    }
  }
`);
