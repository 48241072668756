//@flow

import React from "react";
import { NavButtonContainerRightAligned } from "../../../components/dialog";
import RaisedButton from "material-ui/RaisedButton";

type Props = {
  onClose: () => void;
  associationName: string;
};

const CompletedMessage = (props: Props) => {
  return (
    <div>
      <p>Er is een bericht verzonden naar {props.associationName}.</p>
      <NavButtonContainerRightAligned>
        <RaisedButton label="sluiten" onClick={props.onClose} />
      </NavButtonContainerRightAligned>
    </div>
  );
};

export default CompletedMessage;
