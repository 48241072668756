// @flow
import { ApolloClient } from "@apollo/client";
import config from "config";
import { gql } from "generated";
import raven from "raven-js";
export type AccountPermission =
  | "USER"
  | "ADMINISTRATION"
  | "MEMBER_CHECK"
  | "FINANCE"
  | "PRODUCT_MANAGER";

type AuthResponse = {
  value: string;
  permissions: Array<AccountPermission>;
};

/**
 * Call the login API.
 * @param username the username of the account
 * @param password the password of the account
 * @param client the apollo client used to perform the request
 * @return Promise that resolves to the access token or null if authentication failed.
 */
const loginAPI = async (
  username: string,
  password: string,
  client: ApolloClient<{}>
): Promise<AuthResponse | null> => {
  const response = await client.query({
    query,
    variables: {
      username,
      password,
      associationId: config.associationId,
    },
  });

  const token = response.data.authenticate;

  if (!token) {
    return null;
  }
  raven.setUserContext({
    username: username,
    permissions: token.permissions,
  });

  return {
    value: token.value,
    // @ts-ignore
    permissions: token.permissions,
  };
};

const query = gql(`
query auth($username: String!, $password: String!, $associationId: ID!) {
  authenticate(username: $username, password: $password, associationId: $associationId) {
    value
    permissions
    association {
      id
    }
  }
}
`);

export default loginAPI;
