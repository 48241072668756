import { BackButton } from "../../components/header";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import { withRouter, WithRouterProps } from "react-router";
import { insurerPaths } from "screens/routes";
import { pushWithPreviousPath } from "utils/routing";

type Props = WithRouterProps & { insuredPersonId: string | null };

export const Header = withRouter((props: Props) => {
  const { location } = props;

  const previousPath =
    location.query && location.query.prevPath
      ? location.query.prevPath
      : insurerPaths.administrationPage;

  const goBack = () => props.router.push(previousPath);

  return (
    <AppBar position="static">
      <Toolbar>
        <BackButton onClick={() => goBack()} />
        <Typography variant="h6" component="div">
          Details lidmaatschap
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {props.insuredPersonId && (
          <Tooltip title="Bekijk alle leden met hetzelfde adres en betalingsgegevens">
            <Button
              variant="text"
              color="inherit"
              onClick={() =>
                pushWithPreviousPath(
                  props,
                  insurerPaths.group.path(props.insuredPersonId)
                )
              }
            >
              Bekijk groepering
            </Button>
          </Tooltip>
        )}
      </Toolbar>
    </AppBar>
  );
});

export default Header;

// const styles: { [key: string]: React.CSSProperties} = {
//   appBar: {
//     backgroundColor: blueHiThere,
//     //TODO: Remove this position fixed, it is not required. However, this requires
//     //us to remove the padding top in the policy details page itself.
//     position: "fixed"
//   }
// };

// type PropsRouter = {
//   router: {
//     push: (path: string) => void
//   }
// };
// type PropsParent = {
//   previousRoute: string
// };

// type Props = PropsRouter & PropsParent;

// const Header = (props: Props) => (
//   <AppBar
//     title={<Title title="Details lidmaatschap" />}
//     style={styles.appBar}
//     iconElementLeft={
//       <BackButton onClick={() => props.router.push(props.previousRoute)} />
//     }
//   />
// );

// const Wrapper = withRouter(Header);
// const Wrapped = (props: PropsParent) => <Wrapper {...props} />;
// export default Wrapped
