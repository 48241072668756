import DefaultPage from "screens/homePage/components/defaultPage";
import Title from "screens/homePage/components/title";

export const FAQHulpEnSteun = () => {
  return (
    <DefaultPage>
      <Title title="Veel gestelde vragen" />
      <p>
        Vragen omtrent uw lidmaatschap worden in beginsel beantwoord door de
        ledenadministratie. Mocht de ledenadministratie uw vragen niet kunnen
        beantwoorden wordt u doorverwezen naar een andere functionaris binnen
        onze vereniging.
      </p>
      <p>
        Email:{" "}
        <a href="mailto:ledenadministratie@hulpensteun.com">
          ledenadministratie@hulpensteun.com
        </a>
      </p>
      <h5>
        1) Ik ben verzekerd voor het pakket begraven maar wil overstappen naar
        het verzekeringspakket crematie. Kan dat?
      </h5>
      <p>
        U kunt overstappen tot en met de leeftijd van 59 jaar tegen een
        overstappremie. Een overstappremie is een opslag op de basispremie. Een
        mailtje naar de ledenadministratie is voldoende.
      </p>
      <h5>
        2) Ik ben verzekerd voor begraven maar wil gecremeerd worden. Kan dat?
      </h5>
      <p>
        Voor de leden blijft steeds de mogelijkheid open hun keuze voor begraven
        of crematie te herzien, ongeacht of u voor begraven of crematie
        verzekerd bent.{" "}
      </p>
      <p>
        U krijgt de uitvaartkosten (op basis van nota’s) tot maximaal de waarde
        van het soort pakket waarvoor u verzekerd bent, vergoed.
      </p>
      <p>
        Het verschil in waarde tussen het begrafenis- en crematiepakket is voor
        uw eigen rekening.
      </p>
      <h5>
        3) Ik ben gewijzigd van verzekeringsvorm. Kan ik meteen aanspraak doen
        gelden op de gewijzigde voorziening?
      </h5>
      <p>
        Elk lid dat wijzigt van verzekeringsvorm kan na 12 maanden aanspraak
        doen gelden op de gewijzigde voorzieningen. Bij verandering van het
        pakket crematie naar begraven zal de betaalde premie niet worden
        terugbetaald.
      </p>
      <h5>
        4) Ik ben een nieuw lid. Kan ik meteen aanspraak doen gelden op de
        voorzieningen?
      </h5>
      <p>
        Elk nieuw lid kan na 12 maanden aanspraak doen gelden op de
        voorzieningen.
      </p>
      <h5>5) Tot welke leeftijd kan ik mij verzekeren?</h5>
      <p>Tot en met de leeftijd van 59 jaar kan u een verzekering afsluiten.</p>
      <h5>6) Waarom kost een begrafenis meer dan een crematie?</h5>
      <p>
        Dit heeft te maken met andere kosten die gemaakt dienen te worden. Het
        verschil tussen de kosten van begraven en cremeren wordt met name
        veroorzaakt door de hogere crematiekosten in vergelijking met de kosten
        van grafdelving. De overige kosten zijn gelijk.
      </p>
      <p>
        Daarnaast dient u ook rekening te houden dat u bij een begrafenis nog
        een aantal extra kosten heeft die niet meeverzekerd zijn, zoals:
        <ul>
          <li>de grafsteen</li>
          <li>de grafrechten</li>
          <li>onderhoud van het graf</li>
        </ul>
        <p>
          Bij een crematie heeft men éénmalige crematiekosten en eventuele
          bijzettingskosten in een columbarium.
        </p>
      </p>
      <h5>
        7) Kan ik mijn (klein)kind(eren) op mijn polis laten bijschrijven?
      </h5>
      <p>
        U kunt uw (klein)kind(eren) op uw polis laten bijschrijven mits 1 ouder
        lid is. Tot 9 jaar zijn zij gratis verzekerd.
      </p>
      <h5>
        8) Onze kinderen zijn het huis uit. Kunnen zij toch op onze polis
        blijven staan?
      </h5>
      <p>
        De kinderen kunnen op de polis blijven staan. Zij zijn verzekerd als er
        voor hen wordt betaald, ongeacht waar zij wonen. Uiteraard kunnen de
        kinderen ook voor een eigen polis kiezen. Opgebouwde rechten gaan niet
        verloren.
      </p>
      <h5>9) Waar kan ik wijzigingen doorgeven?</h5>
      <p>
        Wijzigingen over adres, gezinssamenstelling, nieuw bankrekeningnummer,
        automatische incasso, telefoonnummer, e-mail of andere wijzigingen dient
        u schriftelijk door te geven aan de ledenadministratie.
      </p>
      <p>Vergeet uw lidnummer niet te vermelden</p>
      <h5>
        10) Ik woon buiten het verzorgingsgebied – 50 km vanuit Landgraaf - van
        de door de vereniging aangewezen uitvaartondernemer? Wat wordt er
        vergoed?
      </h5>
      <p>
        De nabestaanden ontvangen een uitkering gelijk aan de maximale waarde
        van het pakket. De kosten moeten dan worden aangetoond.
      </p>
      <h5>
        11) Kan ik het verzekeringspakket premie vrijmaken door te stoppen met
        het betalen van de premie of het betalen van een eenmalige som?
      </h5>
      <p>
        Nee. Hulp en Steun is een natura-uitvaartverzekeraar en kent geen
        mogelijkheid om de verzekering premievrij te maken.
      </p>
      <h5>12) Kan ik mijn verzekering afkopen?</h5>
      <p>
        Nee Hulp en Steun kent geen mogelijkheid om de verzekering af te kopen.
        Voor deze optie is door de leden niet gekozen omdat dit zou leiden tot
        een aanzienlijke premieverhoging.{" "}
      </p>
      <h5>
        13) Kan ik zelf wijzigingen - door in te loggen - aanbrengen in de
        ledenadministratie via de website van Hulp en Steun?
      </h5>
      <p>
        Nee dat kan niet. Via de website en/of jaarlijkse nieuwsbrief zullen wij
        u z.s.m. informeren wanneer het mogelijk is.
      </p>
      <h5>
        14) Op mijn bankafschriften staan vreemde teksten bij afschrijving van
        de premie. Hoe kan ik controleren of dat premie is die bestemd is voor
        Hulp en Steun?
      </h5>
      <p>
        De omschrijving wordt door de diverse banken afgekort tot ca 15-20
        karakters. Wij hebben daar als Hulp en Steun geen invloed op.
      </p>
      Teksten als:
      <ol>
        <li> R.K. Onderlinge Begr of</li>
        <li> Begr en Crem of</li>
        <li> Crem Steun </li>
      </ol>
      <p>
        zijn bestemd voor Hulp en Steun. U kunt controleren door het
        bankrekeningnummer te vergelijken. NL25 ABNA 046 78 99 592 is van de
        premieadministratie van Hulp en Steun.
      </p>
      <h5>15) Wat valt niet onder het begrafenis- of crematiepakket?</h5>
      <p>
        <b>
          <u>
            Aan deze bedragen kunnen geen rechten worden ontleend. Veel
            voorkomende diensten. Prijspeil 2023-2024.
          </u>
        </b>
      </p>
      <ul>
        <li>Grafsteen</li>
        <li>Grafhuur</li>
        <li>Asverstrooiing op urnenveld met familie € 120,=</li>
        <li>Koffietafel € 17,= per persoon</li>
        <li>Advertentie € 500,=</li>
        <li>Bloemen € 350,=</li>
        <li>Speciale kist</li>
        <li>Condoleanceboek € 86,=</li>
        <li>Maken PowerPoint door uitvaartverzorger € 50,=</li>
        <li>USB+Livestream € 135,=</li>
        <li>Extra tijdelijke (energie)toeslagen € 150,= bij crematie</li>
        <li>Dienst in opbaarcentrum € 350,= + koffietafel € 430,=</li>
        <li>
          Kosten deeltoespraak door uitvaartleider € 150,= volledige toespraak €
          280,=
        </li>
        <li>Dienst in kerk € 500,=. Koor/organist R.K. € 150,=</li>
        <li>
          Begeleiding door priester naar crematorium dan wel een andere
          begraafplaats na een voorgaande kerkdienst € 75,=
        </li>
        <li>Kosten CMO. Opbaring in ziekenhuis direct na overlijden € 250,=</li>
        <li>Kosten vingerafdruk € 25,=</li>
        <li>Administratiekosten € 70,=</li>
      </ul>
      <p>
        Voor deze kosten kunt u een aanvullende verzekering bij Hulp en Steun
        afsluiten.
      </p>
      <h5>
        16) De inning van de premies wordt afgerond op een veelvoud van 5 cent
        en kan afwijken van de op de polis vermelde bedragen. Hoe kan dat?
      </h5>
      <p>Het verschil zit in afrondingsverschillen.</p>
      <h5>
        17) Bestaat er een boekje waarin ik vast kan leggen wat ik wens bij mijn
        uitvaart?
      </h5>
      <p>
        Een “wensenboekje” kunt u verkrijgen bij uitvaartzorg van Bree.{" "}
        <a
          href="https://uitvaartzorg-vanbree.nl/uitvaartwensen/"
          target="_blank"
          rel="noreferrer"
        >
          https://uitvaartzorg-vanbree.nl/uitvaartwensen/
        </a>
      </p>
    </DefaultPage>
  );
};
