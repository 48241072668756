import { Box, List, ListItem, ListItemText } from "@mui/material";
import { useQueryForApp } from "utils/withApollo";
import { gql } from "generated";
import { Header } from "./components/Header";
import { ScreenContainer } from "components/layout";
import { DownloadButton } from "components/button/buttons";
import { UpdateTemplateButton } from "./components/UpdateTemplateButton";
import { downloadLink } from "utils";

export const SettingsScreen = () => {
  const { data } = useQueryForApp(query);

  const association = data?.viewer?.association;

  return (
    <>
      <Header />
      <ScreenContainer>
        <Box display="flex" justifyContent="center">
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            <ListItem>
              <ListItemText
                primary="Template ledenkaart"
                secondary={formatFile(association?.membershipCardTemplateKey)}
              />
              <UpdateTemplateButton />
              {association?.memberShipCardDownloadUrl && (
                <DownloadButton
                  onClick={() => {
                    downloadLink(association.memberShipCardDownloadUrl);
                  }}
                />
              )}
            </ListItem>
          </List>
        </Box>
      </ScreenContainer>
    </>
  );
};

const formatFile = (file: string | null) => {
  if (file == null) return "Niet gezet";
  else {
    const parts = file.split("/");
    return parts[parts.length - 1];
  }
};

const query = gql(`
query associationDetails {
    viewer {
      association {
        id
        name
        membershipCardTemplateKey
        memberShipCardDownloadUrl
      }
    }
  }
`);
