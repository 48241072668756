// @flow

import React, { Component } from "react";
import RaisedButton from "material-ui/RaisedButton";
import { colors } from "../../../../../../styling";
import { errorHandler } from "../../../../../../containers";
import type { ErrorHandler } from "../../../../../../containers";
import { ACCEPTANCE_QUERY } from "../../../../../registrationPage";
import { NavButtonContainer } from "../../../../../../components/dialog";
import { Centered } from "../../../../../../components/layout";

import CircularProgress from "material-ui/CircularProgress";
import { ACTIVATE_POLICY_MUTATION } from "../accepted/acceptedDialog";
import {
  ActivatePolicyMutation,
  ActivatePolicyMutationVariables,
  RejectPolicyMutation,
  RejectPolicyMutationVariables,
} from "generated/graphql";
import { graphql } from "@apollo/client/react/hoc";
import { gql } from "generated";
import { DialogContainer2 } from "components/dialog/container";

const { green } = colors;

type PropsParent = {
  handleClose: () => void;
  policyId: string;
  newState: "ACCEPTED" | "REJECTED";
};

type PropsGraphQLReject = { rejectPolicy(): void };
type PropsGraphQLAccept = { acceptPolicy(): void };
type Props = PropsParent &
  PropsGraphQLReject &
  PropsGraphQLAccept &
  ErrorHandler;

type State = {
  loading: boolean;
};

class PendingDialogAbstract extends Component<Props, State> {
  state = {
    loading: false,
  };

  _updateState = async () => {
    const { rejectPolicy, acceptPolicy, newState, error } = this.props;

    this.setState({
      loading: true,
    });
    try {
      if (newState === "ACCEPTED") {
        await acceptPolicy();
      } else if (newState === "REJECTED") {
        await rejectPolicy();
      }

      // Reload to reset the cache.
      window.location.reload();
    } catch (e) {
      error.display();
      this.setState({ loading: false });
    }
  };

  _toDutchPolicyState(state: "ACCEPTED" | "REJECTED") {
    if (state === "ACCEPTED") {
      return "Geactiveerd";
    } else {
      return "Afgewezen";
    }
  }

  render() {
    const { handleClose, newState } = this.props;
    const { loading } = this.state;

    const stateString = this._toDutchPolicyState(newState);

    let content;
    if (loading) {
      content = (
        <Centered>
          <CircularProgress color={colors.green} />
        </Centered>
      );
    } else {
      content = (
        <div>
          <h2>Let op!</h2>
          <p>
            Door op bevestig te klikken verandert de status van het lid van{" "}
            <strong>In afwachting</strong> naar <strong>{stateString}</strong>
            , dit kan niet ongedaan worden gemaakt.
            <br />
            <br />
            Als u de status wilt veranderen druk op <i>bevestig</i>, anders druk
            op <i>annuleer</i>.
          </p>
        </div>
      );
    }

    return (
      <DialogContainer2>
        {content}
        <NavButtonContainer>
          <RaisedButton
            // @ts-ignore
            id="back"
            label="Annuleer"
            primary={false}
            onClick={handleClose}
            disabled={loading}
          />
          <RaisedButton
            // @ts-ignore
            id="confirm"
            label="Bevestig"
            backgroundColor={green}
            labelStyle={{ color: "white" }}
            onClick={this._updateState}
            disabled={loading}
          />
        </NavButtonContainer>
      </DialogContainer2>
    );
  }
}

export const REJECT_POLICY = gql(`
  mutation rejectPolicy($input: RejectPolicyInput!) {
    rejectPolicy(input: $input) {
      policy {
        id
        __typename
        rejectionDate
      }
    }
  }
`);
export const withMutationReject = graphql<
  PropsParent & PropsGraphQLAccept,
  RejectPolicyMutation,
  RejectPolicyMutationVariables,
  PropsGraphQLReject
>(REJECT_POLICY, {
  props: ({ ownProps, mutate }): PropsGraphQLReject => ({
    rejectPolicy: () =>
      mutate!!({
        refetchQueries: [{ query: ACCEPTANCE_QUERY }],
        variables: { input: { policyId: ownProps.policyId } },
      }),
  }),
});
const WrappedRejected = PendingDialogAbstract;

export const withMutationAccept = graphql<
  PropsParent,
  ActivatePolicyMutation,
  ActivatePolicyMutationVariables,
  PropsGraphQLAccept
>(ACTIVATE_POLICY_MUTATION, {
  props: ({ ownProps, mutate }) => ({
    acceptPolicy: () =>
      mutate!!({
        refetchQueries: [{ query: ACCEPTANCE_QUERY }],
        variables: { input: { policyId: ownProps.policyId } },
      }),
  }),
});
const WrappedRejectedAccepted = withMutationAccept(
  withMutationReject(errorHandler(WrappedRejected))
);

export default WrappedRejectedAccepted;
