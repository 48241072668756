// @flow

/**
 * The reducer classes are the methods within Redux which will performed the required actions. This will be done according to some type checking.
 * The types are received from the actions.
 * See the implementing classes for the actual methods, e.g. (type:CLOSE_NAVIGATIONBAR-> return false) in NavigationBar.
 *
 * If more reducers are added, also add them to this class in the combineReducers array.
 */

import { combineReducers, Reducer, Dispatch as DispatchRedux } from "redux";
import navbar, { State as NavbarState } from "./navbar";
import alert, { State as AlertState } from "./alert";
import signup, { State as SignupState } from "./signup/index";
import login, { State as LoginState } from "./login";

export const reducer: Reducer<State> = combineReducers({
  navbar,
  alert,
  signup,
  // @ts-ignore
  login,
});

export type Dispatch = DispatchRedux<State>;

export type State = {
  login: LoginState;
  signup: SignupState;
  alert: AlertState;
  navbar: NavbarState;
};
