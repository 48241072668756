// @flow

import React from "react";
import Header from "./header";
import { SideBar } from "../../components/header";
import LedenChecker from "./ledenChecker";

const ClientChecker = () => (
  <div
    style={{
      height: "100%",
      width: "100%",
    }}
  >
    <Header />
    <SideBar />
    <LedenChecker />
  </div>
);

export default ClientChecker;
