// @flow
/**
 * Created by Dirk-Jan Rutten on 01/11/16.
 */

import React, { Component } from "react";
import Paper from "material-ui/Paper";
import Userinfo from "./userInfo";
import Lidmaatschapinfo from "./membershipInfo";
import RaisedButton from "material-ui/RaisedButton";
import { gql } from "@apollo/client";
import { colors } from "../../../styling/index";
import { filter } from "graphql-anywhere";

const stylesCard: { [key: string]: React.CSSProperties } = {
  paperElement: {
    height: "100%",
    width: "100%",
    textAlign: "center",
    paddingTop: "20px",
    paddingLeft: "28px",
    paddingRight: "28px",
    paddingBottom: "28px",
    fontWeight: 300,
    fontSize: "16px",
  },
  policyInfoRowContainer: {
    paddingTop: "12px",
    marginLeft: "40px",
    marginRight: "10px",
    textAlign: "left",
  },
  firstLinesHeight: {
    display: "flex",
    flexDirection: "row",
  },
};

type Props = {
  aanmeldInfo: {
    id: string;
  };
  onDetails: (policyId: string) => void;
};

/**
 * Represents a 'card' that contains the data for a single policy.
 */
export default class AanmeldingenCard extends Component<Props> {
  static fragments: {
    aanmeldInfo: string;
  };

  render() {
    const { onDetails, aanmeldInfo } = this.props;

    return (
      <Paper
        data-test={`policy_info_${aanmeldInfo.id}`}
        style={stylesCard.paperElement}
        zDepth={1}
      >
        <div style={stylesCard.firstLinesHeight}>
          <Userinfo user={filter(Userinfo.fragments.userInfo, aanmeldInfo)} />
          <div
            style={{
              flex: 1,
              textAlign: "right",
              marginRight: "10px",
              paddingTop: "12px",
            }}
          >
            <RaisedButton
              label="DETAILS"
              backgroundColor={colors.green}
              labelStyle={{ color: "white" }}
              onClick={() => onDetails(aanmeldInfo.id)}
            />
          </div>
        </div>
        <div
          style={{
            ...stylesCard.policyInfoRowContainer,
            paddingTop: "16px",
            color: colors.green,
          }}
        >
          <div style={{ paddingBottom: "8px" }}>lidmaatschap </div>
          <div style={{ height: "1px", backgroundColor: "lightgray" }} />
        </div>
        <Lidmaatschapinfo
          lidmaatschap={filter(
            Lidmaatschapinfo.fragments.lidmaatschapInfo,
            this.props.aanmeldInfo
          )}
        />
      </Paper>
    );
  }
}

export const RegistrationCardFragment = gql`
  fragment RegistrationCardFragment on Policy {
    id
    ...Userinfo
    ...Lidmaatschapinfo
  }
  ${Userinfo.fragments.userInfo}
  ${Lidmaatschapinfo.fragments.lidmaatschapInfo}
`;
