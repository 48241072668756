import { useState } from "react";
import EditButton from "../components/editButton";
import { useUpdatePolicyMutation } from "./updateFrequency/graphQLUpdateFrequency";
import { textFieldErrorProps, useForm } from "utils/useForm";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { composeValidators } from "components/form";
import { mustBeAmount, required } from "utils/validator";
import {
  DialogCancelButton,
  DialogConfirmButton,
} from "components/button/buttons";
import { parseAmount, serializeAmount } from "utils";

type FormData = {
  premium: string;
};

export const UpdateConstributionButton = ({
  disabled,
  policyId,
  premium,
}: {
  disabled: boolean;
  policyId: string;
  premium: number;
}) => {
  const [show, setShow] = useState(false);

  const [updateContribution, { loading }] = useUpdatePolicyMutation({
    onCompleted: () => window.location.reload(),
  });

  const { registerField, errors, handleSubmit } = useForm<FormData>({
    initialValues: {
      premium: parseAmount(premium),
    },
  });

  return (
    <>
      <EditButton
        data-test="update_frequency_button"
        onClick={() => setShow(true)}
        disabled={disabled}
      />
      <Dialog
        maxWidth={"sm"}
        fullWidth
        onClose={() => setShow(false)}
        open={show}
      >
        <DialogTitle>Pas de contributie aan</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Nieuwe contributie"
            {...registerField("premium", {
              validation: composeValidators(required, mustBeAmount),
            })}
            {...textFieldErrorProps(errors)("premium")}
          />
        </DialogContent>
        <DialogActions>
          <DialogCancelButton
            disabled={loading}
            onClick={() => setShow(false)}
          />
          <DialogConfirmButton
            disabled={loading}
            onClick={() =>
              handleSubmit((data) =>
                updateContribution({
                  variables: {
                    input: {
                      policyId,
                      premium: serializeAmount(data.premium),
                    },
                  },
                })
              )
            }
          >
            Pas aan
          </DialogConfirmButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
