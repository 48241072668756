import { useErrorHandler } from "containers/errorDialog";
import {
  ApolloCache,
  DefaultContext,
  DocumentNode,
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { clientNew } from "api/apollo2";
import { DataProps, graphql, OperationOption } from "@apollo/client/react/hoc";
import { useRef } from "react";

export function useMutationForApp<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, TContext, TCache>
): MutationTuple<TData, TVariables, TContext, TCache> {
  const showError = useErrorHandler();

  const result = useMutation<TData, TVariables, TContext, TCache>(mutation, {
    client: clientNew,
    onError: (e) => showError(),
    ...options,
  });

  return result;
}

export function useLazyQueryForApp<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>
): LazyQueryResultTuple<TData, TVariables> {
  const dataRef = useRef<TData | null>(null);

  const showError = useErrorHandler();

  const [call, { data, ...others }] = useLazyQuery<TData, TVariables>(query, {
    onError: () => showError(),
    ...options,
  });

  if (data != null) dataRef.current = data;

  return [call, { data: dataRef.current, ...others }];
}

export function useQueryForApp<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
) {
  const dataRef = useRef<TData | null>(null);
  const {
    data: dataFromQuery,
    loading,
    error,
    ...others
  } = useQuery<TData, TVariables>(query, options);

  if (dataFromQuery != null) dataRef.current = dataFromQuery;

  if (error) throw error;

  return {
    loading,
    data: dataRef.current,
    ...others,
  };
}

export function graphqlQuery<
  TProps extends TGraphQLVariables | {} = {},
  TData extends object = {},
  TGraphQLVariables extends OperationVariables = {},
  TChildProps extends object = DataProps<TData, TGraphQLVariables>
>(
  document: DocumentNode,
  operationOptions?: OperationOption<
    TProps,
    TData,
    TGraphQLVariables,
    TChildProps
  >
) {
  return graphql<TProps, TData, TGraphQLVariables, TChildProps>(
    document,
    operationOptions
  );
}
