// @flow

import React from "react";
import DefaultPage from "../../components/defaultPage";
import UitgangsPunten from "./uitgangspunten";
import Product from "./product";
import Kinderen from "./kinderen";
import Werkgebied from "./werkgebied";
import Aanpassing from "./aanpassing";
import Royeren from "./royeren";
import Opzeggen from "./opzeggen";
import Financien from "./financien";
import Privacy from "./privacy";
import Statutes from "./statutes";

const InformationPagePietersrade = () => (
  <DefaultPage>
    <br />
    <br />
    <h3>
      In onderstaande secties kunt u informatie vinden over onze vereniging. U
      kunt de informatie zichtbaar maken door op de kopteksten te klikken van de
      sectie die u wilt lezen.
    </h3>
    <UitgangsPunten />
    <Product />
    <Kinderen />
    <Werkgebied />
    <Aanpassing />
    <Royeren />
    <Opzeggen />
    <Financien />
    <Privacy />
    <Statutes />
  </DefaultPage>
);

export default InformationPagePietersrade;
