//@flow

import React, { Component } from "react";
import MaleIcon from "../../images/Male.png";
import FemaleIcon from "../../images/Female.png";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";

type Sex = "MALE" | "FEMALE";

type Props = {
  color?: string;
  onChange: (gender: Sex) => void;
  value?: Sex | null;
};

type State = {
  gender: Sex | null;
};

export default class GenderPicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      gender: props.value ? props.value : null,
    };
  }

  componentWillReceiveProps(props: Props) {
    if (props.value && props.value !== this.state.gender) {
      this.setState({ gender: props.value });
    }
  }

  render() {
    const { color, onChange } = this.props;
    return (
      <div id="genderPicker" style={{ textAlign: "left", minWidth: 200 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center", width: 80 }}>
            <img src={MaleIcon} alt="" />
          </div>
          <div style={{ textAlign: "center", width: 80 }}>
            <img src={FemaleIcon} alt="" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <RadioButtonGroup
            // @ts-ignore
            id="buttonGroup"
            valueSelected={this.state.gender}
            onChange={(event: any) => {
              const { value } = event.target;
              if (value !== "MALE" && value !== "FEMALE") {
                throw new Error(
                  `Expected value FEMALE or MALE but got ${value}`
                );
              }
              this.setState({
                gender: value,
              });
              onChange(value);
            }}
            name="genderButtons"
            style={{
              display: "flex",
              paddingLeft: "17px",
            }}
          >
            <RadioButton
              data-test={"gender_picker_male"}
              value="MALE"
              iconStyle={color ? { fill: color } : null}
              style={{ width: "80px" }}
            />
            <RadioButton
              data-test={"gender_picker_female"}
              value="FEMALE"
              iconStyle={color ? { fill: color } : null}
              style={{ width: "auto" }}
            />
          </RadioButtonGroup>
        </div>
      </div>
    );
  }
}
