// @flow

import React from "react";
import DefaultPage from "../../components/defaultPage";
import PageSection from "../../components/pageSection";
import Link from "../../../link";

const BoardPage = () => (
  <DefaultPage>
    <PageSection title="Bestuur contacteren?">
      <p>
        Het bestuur van Onderlinge Begrafenis- en Crematievereniging ‘St.
        Pietersrade’ bestaat uit 5 bestuursleden, te weten:
      </p>
      <ul>
        <li>
          Dhr. M.J.L. Stevens (voorzitter) ,{" "}
          <Link href="mailto:stevens-m@home.nl?subject=voorzitter">
            {" "}
            stevens-m@home.nl{" "}
          </Link>{" "}
        </li>
        <li>
          Dhr. E.K.J. Vandermeulen (Secretaris),{" "}
          <Link href="mailto:ekjvandermeulen@home.nl?subject=secretaris">
            ekjvandermeulen@home.nl
          </Link>{" "}
        </li>
        <li>
          Dhr. J.M.E. Tilmans (Penningmeester),{" "}
          <Link href="mailto:sjef.tilmans@home.nl?subject=penningmeester">
            sjef.tilmans@home.nl
          </Link>{" "}
        </li>
        <li>
          Mevr. Rachella Limpens-Feenstra (bestuurslid),{" "}
          <Link href="mailto:famlimpens@outlook.com">
            famlimpens@outlook.com
          </Link>{" "}
        </li>
        <li>
          Mevr. Chantalle Theunissen-Salemink (bestuurslid),{" "}
          <Link href="mailto:info@ceremoniespreker-chantalle.nl">
            info@ceremoniespreker-chantalle.nl
          </Link>{" "}
        </li>
      </ul>

      <p>
        Contacten met St. Pietersrade verlopen in alle gevallen via het
        telefoonnummer: <b>045-5456368</b>. U wordt dan altijd verbonden met een
        antwoordapparaat. Als u uw naam, lidmaatschapsnummer en telefoonnummer
        inspreekt wordt u op korte termijn teruggebeld.
      </p>
      <p>
        Ook is het mogelijk een mail te sturen naar:{" "}
        <Link href="mailto:st.pietersrade@home.nl?subject=penningmeester">
          st.pietersrade@home.nl
        </Link>
      </p>
    </PageSection>
  </DefaultPage>
);

export default BoardPage;
