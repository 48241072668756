import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { gql } from "generated";
import {
  RemoveFromAccountMutation,
  RemoveFromAccountMutationVariables,
} from "generated/graphql";
import { useState } from "react";
import { useMutationForApp } from "utils/withApollo";

type Props = {
  memberId: string;
  fullName: string;
  accountId: string;
  insuredPersonId: string;
};

export const RemoveFromAccountButton = ({
  memberId,
  fullName,
  accountId,
  insuredPersonId,
}: Props) => {
  const [open, setOpen] = useState(false);

  const [removeFromAccount] = useMutationForApp<
    RemoveFromAccountMutation,
    RemoveFromAccountMutationVariables
  >(MUTATION, {
    onCompleted: () => window.location.reload(),
    variables: {
      input: {
        accountId,
        insuredPersonId,
      },
    },
  });

  const close = () => setOpen(false);

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Loskoppelen
      </Button>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Lid loskoppelen van account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Weet u zeker dat u lid <b>{fullName}</b> met lidnummer{" "}
            <b>{memberId}</b> los wilt koppelen van het account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} variant="outlined">
            Annuleer
          </Button>
          <Button onClick={() => removeFromAccount()}>Loskoppelen</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const MUTATION = gql(`
mutation removeFromAccount($input: RemoveInsuredPersonFromAccountInput!) {
    removeInsuredPersonFromAccount(input: $input) {
      clientMutationId
    }
}`);
