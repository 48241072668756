// @flow

import raven from "raven-js";
import { isEmail, clearToken, storeToken } from "../utils";
import { login as loginReq, AccountPermission } from "../api";
import { browserHistory } from "react-router";
import { showAlertDialog } from "./alert";
import { Dispatch } from "../reducers";
import { clientNew } from "api/apollo2";

export type LoginErrorEnum =
  | "INVALID_LOGIN"
  | "FIELDS_MISSING"
  | "INVALID_EMAIL";

type UpdateAuthStateAction = {
  type: "UPDATE_AUTH";
  loggedIn: boolean;
  permissions: Array<AccountPermission>;
};

type UpdateUsernameAction = {
  type: "UPDATE_USERNAME";
  newState: string;
};

type UpdatePasswordAction = {
  type: "UPDATE_PASSWORD";
  newState: string;
};

type UpdateLoadingStateAction = {
  type: "UPDATE_LOADING";
  newState: boolean;
};

type ShowErrorStateAction = {
  type: "SHOW_LOGIN_ERROR";
  error: LoginErrorEnum | null;
};

export type LoginAction =
  | UpdateAuthStateAction
  | UpdateUsernameAction
  | UpdatePasswordAction
  | UpdateLoadingStateAction
  | ShowErrorStateAction;

export const updateAuthState = (
  loggedIn: boolean,
  permissions: Array<AccountPermission>
): UpdateAuthStateAction => ({
  type: "UPDATE_AUTH",
  loggedIn,
  permissions,
});

export const updateUsername = (username: string): UpdateUsernameAction => ({
  type: "UPDATE_USERNAME",
  newState: username,
});

export const updatePassword = (password: string): UpdatePasswordAction => ({
  type: "UPDATE_PASSWORD",
  newState: password,
});

export const updateLoadingState = (
  loading: boolean
): UpdateLoadingStateAction => ({
  type: "UPDATE_LOADING",
  newState: loading,
});

export const showError = (
  error: LoginErrorEnum | null
): ShowErrorStateAction => ({
  type: "SHOW_LOGIN_ERROR",
  error,
});

/**
 * Perform a login
 * @param username The username
 * @param password The password
 * @param nextPathname An optional next path name to which the app should redirect after a successful login.
 */
export const login =
  (username: string, password: string, nextPathname: string | null) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(showError(null));
    //Show the loading indicator
    dispatch(updateLoadingState(true));

    if (username.length === 0 || password.length === 0) {
      dispatch(showError("FIELDS_MISSING"));
      dispatch(updateLoadingState(false));
      return;
    }

    if (!isEmail(username)) {
      dispatch(showError("INVALID_EMAIL"));
      dispatch(updateLoadingState(false));
      return;
    }
    try {
      const token = await loginReq(username, password);
      dispatch(updateLoadingState(false));
      if (!token) {
        dispatch(updateAuthState(false, []));
        dispatch(showError("INVALID_LOGIN"));
        return;
      } else {
        if (token.permissions.length === 0) {
          dispatch(
            showAlertDialog("U heeft onvoldoende rechten om in te loggen.")
          );
          return;
        }
        raven.setUserContext({
          username,
          permissions: token.permissions,
        });

        dispatch(updateAuthState(true, token.permissions));
        storeToken(token.value, token.permissions);
        if (nextPathname) {
          browserHistory.push(nextPathname);
        } else {
          if (token.permissions.includes("USER")) {
            browserHistory.push("/account/lidmaatschappen");
          } else {
            browserHistory.push("/bestuurder/");
          }
        }

        dispatch(updateUsername(""));
        dispatch(updatePassword(""));
        return;
      }
    } catch (e) {
      dispatch(updateLoadingState(false));
      dispatch(
        showAlertDialog("Er is iets fout gegaan, probeer het nog een keer.")
      );
      raven.captureException(e);
    }
  };

export const logout = () => (dispatch: Dispatch) => {
  raven.setUserContext({
    username: null,
    permissions: null,
  });
  dispatch(updateAuthState(false, []));
  clearToken();
  browserHistory.replace("/inloggen");

  // The set timeout is required to prevent a bug.
  //
  // From apollo docs:
  //
  // ***
  // It is important to remember that `resetStore()` *will* refetch any active
  // queries. This means that any components that might be mounted will execute
  // their queries again using your network interface. If you do not want to
  // re-execute any queries then you should make sure to stop watching any
  // active queries.
  // ***
  //
  // To unwatch the active queries we need to unmount the components that are listening.
  // This means that we need to wait for the replace method of browser history to finish executing
  // before resetting the stores.
  //
  // In order to do this we use the setTimeout method to add the call
  // to the resetStores() to the end of the current execution queue.
  //
  // From stackoverflow thread:
  // http://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful
  //
  // ***
  // setTimeout pushes the callback function to the end of the execution queue.
  // If after setTimeout(callback, 0) you have blocking code which takes
  // several seconds to run, the callback will not be executed for several
  // seconds, until the blocking code has finished.
  // ***
  //
  setTimeout(() => {
    clientNew.resetStore();
  }, 0);

  return;
};

export const removeLoginState = () => (dispatch: Dispatch) => {
  dispatch(updateUsername(""));
  dispatch(updatePassword(""));
  dispatch(showError(null));
};
