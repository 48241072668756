import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import config from "../config";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { getToken } from "../utils";

import { logout } from "../actions";
import { store } from "../store";
import { showAlertDialog } from "../actions";

import possibleTypes from "possibleTypes.json";

const logoutFromApp = () => {
  // We need to add an ignore statement here since
  // Redux-Thunk is not defined in the redux flow-types;
  //@ts-ignore
  store.dispatch(logout());
};

const httpLink = createHttpLink({
  uri: config.api,
});

const authLink = setContext((_, { headers }) => {
  const credentials = getToken();

  return {
    headers: {
      ...headers,
      authorization: credentials ? "Bearer " + credentials : null,
    },
  };
});

const logoutLink = onError(({ networkError }) => {
  // @ts-ignore
  const status = networkError?.statusCode;

  if (!networkError) return;

  switch (status) {
    case 400:
      store.dispatch(showAlertDialog());
      //Bad request. Occurs when the data sent to the server is wrong.
      break;
    case 401:
      // Authentication failed most likely because session has expired.
      logoutFromApp();
      store.dispatch(
        showAlertDialog("Log alstublieft opnieuw in.", "Uw sessie is verlopen")
      );
      break;
    case 403:
      //Account does not have permission to view the data
      logoutFromApp();
      store.dispatch(
        showAlertDialog(
          "Uw account heeft niet de geldige rechten om de data te bekijken. Log alstublieft opnieuw in."
        )
      );
      break;
    case 404:
      // Account associated with the token does not exist.
      logoutFromApp();
      store.dispatch(
        showAlertDialog(
          "Uw account kon niet gevonden worden. Log alstublieft opnieuw in."
        )
      );
      break;
    case 500:
      // Internal server error.
      store.dispatch(showAlertDialog("Er is een onbekende fout opgetreden."));
      break;
    default:
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache({ possibleTypes }),

  link: from([authLink, logoutLink, httpLink]),
});

export { client as clientNew };
