//@flow
import React from "react";
import TextField from "material-ui/TextField";
import CircularProgress from "material-ui/CircularProgress";
import { colors, colorsBCV } from "../../styling";
import RaisedButton from "material-ui/RaisedButton";
import {
  isEmail,
  validatePhone,
  capitalizeFirstLetter,
  isValidPassword,
  storeToken,
} from "../../utils";
import { isEmailUnique, login } from "../../api";
import { errorHandler } from "../../containers";
import type { ErrorHandler } from "../../containers";
import config from "../../config";
import { updateAuthState } from "../../actions/login";
import { store } from "../../store";
import { gql } from "generated";
import {
  NewAccountMutation,
  NewAccountMutationVariables,
} from "generated/graphql";
import { graphql } from "@apollo/client/react/hoc";
import { FetchResult } from "@apollo/client";

type State = {
  name: string;
  errorName: string | null;
  prefix: string;
  lastName: string;
  errorLastName: string | null;
  phone: string;
  errorPhone: string | null;
  email: string;
  errorEmail: string | null;
  password: string;
  errorPassword: string | null;
  passwordConfirm: string;
  errorPasswordConfirm: string | null;
  loading: boolean;
};

type PropsGraphQL = {
  addAccount: (
    input: NewAccountMutationVariables["input"]
  ) => Promise<FetchResult<NewAccountMutation>>;
};

type PropsParent = {
  onRegistered: (email: string) => void;
};

type Props = ErrorHandler & PropsParent & PropsGraphQL;

const styles: { [key: string]: React.CSSProperties } = {
  inputFieldDiv: {
    display: "flex",
    flexDirection: "column",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
};

export class NewClient extends React.Component<Props, State> {
  state: State = {
    name: "",
    errorName: null,
    prefix: "",
    lastName: "",
    errorLastName: null,
    phone: "",
    errorPhone: null,
    email: "",
    errorEmail: null,
    loading: false,
    password: "",
    errorPassword: null,
    passwordConfirm: "",
    errorPasswordConfirm: null,
  };

  _validate = async () => {
    const { onRegistered, addAccount } = this.props;
    const { name, lastName, phone, email, prefix, password, passwordConfirm } =
      this.state;
    let {
      errorEmail,
      errorName,
      errorLastName,
      errorPhone,
      errorPassword,
      errorPasswordConfirm,
    } = this.state;

    if (email === "") {
      errorEmail = "Er is geen e-mailadres ingevuld.";
    } else if (!isEmail(email)) {
      errorEmail = "Het e-mailadres is niet geldig.";
    } else {
      try {
        const unique = await isEmailUnique(email);
        if (!unique) {
          errorEmail = "Dit e-mailadres is al in gebruik.";
        } else {
          errorEmail = null;
        }
      } catch (e) {
        this.props.error.display();
      }
    }

    if (name === "") {
      errorName = "Er is geen roepnaam ingevuld.";
    } else {
      errorName = null;
    }

    if (lastName === "") {
      errorLastName = "De achternaam mag niet leeg zijn.";
    } else {
      errorLastName = null;
    }

    if (phone === "") {
      errorPhone = "Het telefoonnummer mag niet leeg zijn.";
    } else if (!validatePhone(phone)) {
      errorPhone = "Het telefoonnummer is niet geldig.";
    } else {
      errorPhone = null;
    }

    if (password === "" || !isValidPassword(password)) {
      errorPassword =
        "Het wachtwoord moet minimaal 8 tekens bevatten, waarvan minimaal 1 letter en 1 cijfer.";
    } else {
      errorPassword = null;
    }

    if (passwordConfirm !== password) {
      errorPasswordConfirm = "De wachtwoorden zijn niet gelijk.";
    } else {
      errorPasswordConfirm = null;
    }

    this.setState({
      errorEmail,
      errorName,
      errorLastName,
      errorPhone,
      errorPassword,
      errorPasswordConfirm,
    });

    if (
      !errorEmail &&
      !errorName &&
      !errorLastName &&
      !errorPhone &&
      !errorPassword &&
      !errorPasswordConfirm
    ) {
      try {
        this.setState({ loading: true });
        const response = await addAccount({
          username: email,
          password,
          firstName: name,
          lastName,
          namePreposition: prefix,
          phone,
          associationId: config.associationId,
          redirectUrl: `${config.redirectUrl}/activeren`,
        });

        this.setState({ loading: false });
        const { account } = response.data.addAccount;
        onRegistered(account.username);

        const responseLogin = await login(email, password);

        if (responseLogin && responseLogin.value && responseLogin.permissions) {
          await storeToken(responseLogin.value, responseLogin.permissions);
          //$FlowFixMe
          store.dispatch(updateAuthState(true, responseLogin.permissions));
        } else
          this.props.error.display(
            "Het inloggen is niet gelukt. Probeer opnieuw met het zojuist gemaakte account."
          );
      } catch (e) {
        this.setState({ loading: false });
        this.props.error.display();
      }
    }
  };

  render() {
    let {
      errorName,
      errorLastName,
      errorPhone,
      errorEmail,
      errorPassword,
      errorPasswordConfirm,
      loading,
      name,
      lastName,
      prefix,
      phone,
      email,
      password,
      passwordConfirm,
    } = this.state;

    if (loading) {
      return (
        <div style={styles.center}>
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <div style={styles.inputFieldDiv}>
          <TextField
            id="email"
            value={email}
            floatingLabelText="Wat is uw e-mailadres?"
            onChange={(event: any) =>
              this.setState({
                email: event.target.value,
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorText={errorEmail}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />

          <TextField
            id="name"
            value={name}
            floatingLabelText="Wat is uw voornaam ..."
            onChange={(event: any) =>
              this.setState({
                name: capitalizeFirstLetter(event.target.value),
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorText={errorName}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />
          <TextField
            id="prefix"
            value={prefix}
            floatingLabelText="... uw mogelijke tussenvoegsel ..."
            onChange={(event: any) =>
              this.setState({
                prefix: event.target.value,
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />

          <TextField
            id="lastName"
            value={lastName}
            floatingLabelText="... en uw achternaam?"
            onChange={(event: any) =>
              this.setState({
                lastName: capitalizeFirstLetter(event.target.value),
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorText={errorLastName}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />

          <TextField
            id="phoneNum"
            value={phone}
            floatingLabelText="Wat is uw telefoonnummer?"
            onChange={(event: any) =>
              this.setState({
                phone: event.target.value,
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorText={errorPhone}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />

          <TextField
            id="password"
            value={password}
            floatingLabelText="Kies een wachtwoord ..."
            onChange={(event: any) =>
              this.setState({
                password: event.target.value,
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorText={errorPassword}
            errorStyle={{ color: "red" }}
            fullWidth={true}
            type="password"
          />

          <TextField
            id="passwordConfirm"
            value={passwordConfirm}
            floatingLabelText="... en bevestig deze."
            onChange={(event: any) =>
              this.setState({
                passwordConfirm: event.target.value,
              })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._validate();
              }
            }}
            errorText={errorPasswordConfirm}
            errorStyle={{ color: "red" }}
            fullWidth={true}
            type="password"
          />

          <div
            style={{
              paddingTop: "30px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <RaisedButton
              // @ts-ignore
              id="create"
              label="Aanmelden"
              backgroundColor={colorsBCV.secondary}
              labelColor={colors.white}
              onClick={() => this._validate()}
            />
          </div>
        </div>
      );
    }
  }
}

export const ADD_ACCOUNT_MUTATION = gql(`
mutation newAccount($input: AddAccountInput!) {
  addAccount(input: $input) {
    account {
      id
      username
    }
  }
}
`);

export const withMutation = graphql<
  PropsParent,
  NewAccountMutation,
  NewAccountMutationVariables,
  PropsGraphQL
>(ADD_ACCOUNT_MUTATION, {
  props: ({ mutate }) => ({
    addAccount: (input) => mutate({ variables: { input } }),
  }),
});

const Wrapper = withMutation(errorHandler(NewClient));
export default Wrapper;
