//@flow

import React, { Component } from "react";
import { DomesticAddressInput as AddressAutofill } from "../../../components/address/domesticAddressInput";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import { colorsBCV } from "../../../styling";

type Props = {
  nextPressed: (input: {
    postalCode: string;
    houseNumber: number;
    suffix: string | null;
    street: string;
    city: string;
    country: string;
  }) => void;
  inputAddress: {
    postalCode: string;
    houseNumber: number;
    suffix: string | null;
    street: string;
    city: string;
    country: string;
  } | null;
  backPressed(): void;
};

type State = {
  state:
    | {
        completed: true;
        postalCode: string;
        houseNumber: number;
        suffix: string | null;
        street: string;
        city: string;
        country: string;
      }
    | {
        completed: false;
      };
};

class AddressInfoStep extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    if (props.inputAddress) {
      this.state = {
        state: {
          completed: true,
          ...props.inputAddress,
        },
      };
    } else {
      this.state = {
        state: {
          completed: false,
        },
      };
    }
  }

  render() {
    const { nextPressed } = this.props;
    const { state } = this.state;

    const disabled = state.completed === false;
    return (
      <div style={{ maxWidth: "350px" }}>
        <AddressAutofill
          address={state.completed ? state : null}
          color={colorsBCV.secondary}
          onCompleted={(input) =>
            this.setState({
              state: {
                completed: true,
                ...input,
              },
            })
          }
          onIncomplete={() =>
            this.setState({
              state: {
                completed: false,
              },
            })
          }
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            paddingTop: "20px",
          }}
        >
          <FlatButton
            label="Vorige"
            disableTouchRipple={true}
            disableFocusRipple={true}
            onClick={this.props.backPressed}
          />
          <RaisedButton
            label="Volgende"
            disableTouchRipple={true}
            disableFocusRipple={true}
            primary={true}
            disabled={disabled}
            onClick={() => {
              if (state.completed) {
                nextPressed({
                  ...state,
                });
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export default AddressInfoStep;
