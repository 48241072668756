import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";

export const EventTypeFilter = (
  props: React.ComponentProps<typeof IconButton>
) => {
  return (
    <Tooltip title="Filter list">
      <IconButton color="inherit" {...props}>
        <FilterListIcon />
      </IconButton>
    </Tooltip>
  );
};
