// @flow
/**
 * Created by Dirk-Jan Rutten on 01/11/16.
 */

import React from "react";
import { gql } from "@apollo/client";
import { colors } from "../../../styling/index";
import { parseAmount, formatPrice } from "../../../utils";
import { formatFrequency2 } from "utils/formatter";
import { Frequency } from "generated/graphql";

const stylesCard = {
  paperElement: {
    height: "100%",
    width: "100%",
    textAlign: "center",
    paddingTop: "20px",
    paddingLeft: "28px",
    paddingRight: "28px",
    paddingBottom: "28px",
    fontWeight: 300,
    fontSize: "16px",
  },
  personInfoRowContainer: {
    paddingTop: "12px",
    marginLeft: "10px",
    marginRight: "10px",
    textAlign: "left",
  } as React.CSSProperties,
  personInfoText: {
    display: "flex",
    alignItems: "flex-end",
  },
  policyInfoRowContainer: {
    paddingTop: "12px",
    marginLeft: "40px",
    marginRight: "10px",
    textAlign: "left",
  } as React.CSSProperties,
  policyInfoLabel: {
    display: "flex",
    alignItems: "center",
    color: colors.lightGrey,
  },
  icon: {
    width: "16px",
    height: "16px",
  },
};

type LidmaatschapType = {
  package: {
    name: string;
  };
  premium: number;
  frequency: Frequency;
};

type PropsCard = {
  lidmaatschap: LidmaatschapType;
};

function propsMapper(props: PropsCard) {
  let { lidmaatschap } = props;
  let infoArray = [
    { info: lidmaatschap.package.name, text: "Pakket" },
    {
      info:
        formatPrice(parseAmount(lidmaatschap.premium)) +
        " " +
        formatFrequency2(lidmaatschap.frequency).toLowerCase(),
      text: "Contributie",
    },
  ];

  return infoArray.map(function (object) {
    return (
      <div
        key={object.text}
        style={{ display: "flex", ...stylesCard.policyInfoRowContainer }}
      >
        <div style={{ flex: 1, ...stylesCard.policyInfoLabel }}>
          {object.text}
        </div>
        <div style={{ flex: 1 }}>{object.info}</div>
      </div>
    );
  });
}

/**
 * Represents a 'card' that contains the data for a single policy.
 */
const Lidmaatschapinfo = (props: PropsCard) => <div>{propsMapper(props)}</div>;

Lidmaatschapinfo.fragments = {
  lidmaatschapInfo: gql`
    fragment Lidmaatschapinfo on Policy {
      id
      package {
        id
        name
      }
      premium
      frequency
    }
  `,
};

export default Lidmaatschapinfo;
