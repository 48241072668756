// @flow

import React from "react";

import PackagesBCB from "./bcb";
import PackagesPietersrade from "./pietersrade";
import PackagesHulpEnSteun from "./hulpEnSteun";

import config, { AssociationType } from "../../../config";

const { association } = config;

const PackagesPage = () => {
  switch (association) {
    case AssociationType.BCB:
      return <PackagesBCB />;
    case AssociationType.PIETERSRADE:
      return <PackagesPietersrade />;
    case AssociationType.HULP_EN_STEUN:
      return <PackagesHulpEnSteun />;
    default:
      throw new Error(`Unknown assocation type ${association}`);
  }
};

export default PackagesPage;
