import { AccountSignupNew } from "screens/bestuurderSignupAccount/accountSignUpNew";
import { useState } from "react";
import { FloatingAddButton } from "components/button/buttons";

type Props = {
  goToAccount: (accountId: string) => void;
};

export const AddAccountButton = (props: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <FloatingAddButton onClick={() => setOpen(true)} />
      <AccountSignupNew
        title="Nieuw account registreren"
        open={open}
        onClose={() => setOpen(false)}
        onRegistered={(accountId, _) => {
          props.goToAccount(accountId);
        }}
      />
    </>
  );
};
