// @flow
/**
 * Created by Dirk-Jan Rutten on 01/11/16.
 */

import React from "react";
import Person from "material-ui/svg-icons/social/person";
import Cake from "material-ui/svg-icons/social/cake";
import Mail from "material-ui/svg-icons/content/mail";
import Gender from "material-ui/svg-icons/notification/wc";
import { gql } from "@apollo/client";
import { colors } from "../../../styling/index";
import { parseDate } from "../../../utils";

const stylesCard = {
  paperElement: {
    height: "100%",
    width: "100%",
    textAlign: "center",
    paddingTop: "20px",
    paddingLeft: "28px",
    paddingRight: "28px",
    paddingBottom: "28px",
    fontWeight: 300,
    fontSize: "16px",
  },
  personInfoRowContainer: {
    paddingTop: "12px",
    marginLeft: "10px",
    marginRight: "10px",
    textAlign: "left",
  } as React.CSSProperties,
  policyInfoRowContainer: {
    paddingTop: "12px",
    marginLeft: "40px",
    marginRight: "10px",
    textAlign: "left",
  },
  policyInfoLabel: {
    display: "flex",
    alignItems: "center",
    color: colors.lightGrey,
  },
  icon: {
    width: "16px",
    height: "16px",
  },
};

type UserType = {
  insuredPerson: {
    fullName: string;
    sex: "MALE" | "FEMALE";
    birthdate: string | null;
    account: {
      username: string;
    } | null;
  };
};

type PropsCard = {
  user: UserType;
};

function toDutch(sex: "MALE" | "FEMALE") {
  if (sex === "MALE") {
    return "Man";
  } else {
    return "Vrouw";
  }
}
const convertBirthdateToText = (birthdate: string) =>
  birthdate ? parseDate(birthdate) : "De geboortedatum is niet bekend";

function propsMapper(props: PropsCard) {
  let { fullName, birthdate, sex, account } = props.user.insuredPerson;

  let email;
  if (!account) {
    email = "Geen account gevonden";
  } else {
    email = account.username;
  }

  let infoArray = [
    {
      info: fullName,
      icon: <Person style={stylesCard.icon} color={"lightgray"} />,
    },
    {
      info: email,
      icon: <Mail style={stylesCard.icon} color={"lightgray"} />,
    },
    {
      info: birthdate && convertBirthdateToText(birthdate),
      icon: <Cake style={stylesCard.icon} color={"lightgray"} />,
    },
    {
      info: toDutch(sex),
      icon: <Gender style={stylesCard.icon} color={"lightgray"} />,
    },
  ];

  return infoArray.map((object) => {
    return (
      <div
        key={object.info}
        style={{ display: "flex", ...stylesCard.personInfoRowContainer }}
      >
        <div style={{ width: "30px", display: "flex", alignItems: "center" }}>
          {object.icon}
        </div>
        {object.info === fullName ? (
          <div
            style={{
              flex: 1,
              fontSize: "18px",
              color: colors.green,
            }}
          >
            {object.info}
          </div>
        ) : (
          <div
            style={{
              flex: 1,
              wordWrap: "break-word",
              width: "250px",
            }}
          >
            {object.info}
          </div>
        )}
      </div>
    );
  });
}

/**
 * Represents a 'card' that contains the data for a single policy.
 */
const Userinfo = (props: PropsCard) => <div>{propsMapper(props)}</div>;

Userinfo.fragments = {
  userInfo: gql`
    fragment Userinfo on Policy {
      id
      insuredPerson {
        id
        fullName
        sex
        birthdate
        account {
          id
          username
        }
      }
    }
  `,
};

export default Userinfo;
