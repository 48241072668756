// @flow

import React from "react";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  "data-test"?: string | null;
}>;

const DialogContainer = (props: Props) => (
  <div
    data-test={props["data-test"]}
    style={{
      paddingLeft: "10%",
      paddingRight: "10%",
    }}
  >
    {props.children}
  </div>
);

export const DialogContainer2 = (props: Props) => (
  <div
    data-test={props["data-test"]}
    style={{
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: "5%",
      paddingBottom: "5%",
    }}
  >
    {props.children}
  </div>
);

export default DialogContainer;
