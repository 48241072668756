// @flow
/**
 * Created by Ellissa Verseput on 19-01-2017.
 */

import React, { Component } from "react";
import AppBar from "material-ui/AppBar";
import IconButton from "material-ui/IconButton";
import MenuIcon from "material-ui/svg-icons/navigation/menu";
import { withRouter, WithRouterProps } from "react-router";
import HeaderLogo from "./logo";

const styles: { [key: string]: React.CSSProperties } = {
  appBar: {
    backgroundColor: "#484848",
    position: "fixed",
  },
  centerTitle: {
    position: "fixed",
    zIndex: 1110,
    fontSize: "24px",
    color: "white",
    textAlign: "center",
    width: "40%",
    height: "64px",
    lineHeight: "64px",
    marginLeft: "30%",
    marginRight: "30%",
  },
  buttons: {
    backgroundColor: "transparent",
    color: "white",
    display: "flex",
  },
};

type Props = {
  openNavigationBar: () => void;
  open: boolean;
  nameHeader: string;
} & WithRouterProps;

/**
 * This is the header displayed at the top of the page.
 *
 * This component also contains the navbar shown on the left of the screen that opens when the
 * user presses the hamburger button contained in the app bar.
 */
class Header extends Component<Props> {
  render() {
    let { nameHeader, openNavigationBar } = this.props;

    return (
      <div>
        <AppBar
          style={styles.appBar}
          iconElementLeft={
            <IconButton onClick={openNavigationBar}>
              <MenuIcon />
            </IconButton>
          }
          iconElementRight={<HeaderLogo />}
        />
        <div style={styles.centerTitle}>{nameHeader}</div>
      </div>
    );
  }
}

const DecoratedHeader = withRouter(Header);

export default DecoratedHeader;
