import { useState } from "react";
import { EventTypeFilter } from "./buttons";
import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import {
  DialogCancelButton,
} from "components/button/buttons";
import { EventType } from "generated/graphql";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { deserializeEventType } from "utils/formatter";

// @ts-ignore
export const allEventTypes: EventType[] = Object.values(EventType);

export const oftenUserEventTypes = [EventType.DeceasedPolicy];

export const statusEventTypes = [
  EventType.CancelledPolicy,
  EventType.DeceasedPolicy,
  EventType.DischargedPolicy,
  EventType.UpdatedPackage,
  EventType.RegisteredCustomPolicy,
  EventType.RegisteredPolicy,
  EventType.UpdatedAddress,
  EventType.UpdatedPolicy,
  EventType.UpdatedInsuredPerson,
  EventType.UpdatedPayment,
];

export const otherEventTypes = allEventTypes.filter(
  (type) => !statusEventTypes.includes(type)
);

export const EventTypeSelectionDialogButton = ({
  selection,
  setSelection,
}: {
  selection: EventType[];
  setSelection: (selection: EventType[]) => void;
}) => {
  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleToggle = (value: EventType) => () => {
    const currentIndex = selection.indexOf(value);
    const newChecked = [...selection];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelection(newChecked);
  };

  const SelectionGrid = ({ data }: { data: EventType[] }) => (
    <Grid container spacing={2}>
      {data.map((type) => {
        return (
          <Grid xs={4} key={type}>
            <ListItemButton role={undefined} onClick={handleToggle(type)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selection.indexOf(type) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={deserializeEventType(type)} />
            </ListItemButton>
          </Grid>
        );
      })}
    </Grid>
  );

  return (
    <>
      <EventTypeFilter onClick={() => setShow(true)} />

      <Dialog
        maxWidth="lg"
        fullWidth
        open={show}
        onClose={() => setShow(false)}
      >
        <DialogTitle>Kies één of meerdere filter types</DialogTitle>

        <DialogContent>
          <Stack spacing={2} direction="row">
            <Button
              disabled={selection.length === allEventTypes.length}
              onClick={() => setSelection(allEventTypes)}
            >
              Alles selecteren
            </Button>
            <Button
              disabled={selection.length === 0}
              onClick={() => setSelection([])}
            >
              Alles onselecteren
            </Button>
          </Stack>
          <SelectionGrid data={statusEventTypes} />
          <ListItemButton onClick={() => setExpanded(!expanded)}>
            <ListItemIcon>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </ListItemIcon>
            <ListItemText primary="Klik om minder gebruikte filters te zien" />
          </ListItemButton>
          <Collapse in={expanded} onChange={() => setExpanded(!expanded)}>
            <SelectionGrid data={otherEventTypes} />
          </Collapse>
        </DialogContent>
        <DialogActions>
          <DialogCancelButton onClick={() => setShow(false)} />
        </DialogActions>
      </Dialog>
    </>
  );
};
