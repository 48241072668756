// @flow
/**
 * Created by Dirk-Jan Rutten on 01/11/16.
 */

import React from "react";
import { parseDate, parseAmount, formatPrice } from "../../../../utils";
import { gql } from "@apollo/client";
import { colors } from "../../../../styling/index";
import { Frequency } from "generated/graphql";
import { formatFrequency2 } from "utils/formatter";

const stylesCard: { [key: string]: React.CSSProperties } = {
  paperElement: {
    height: "100%",
    width: "100%",
    textAlign: "center",
    paddingTop: "20px",
    paddingLeft: "28px",
    paddingRight: "28px",
    paddingBottom: "28px",
    fontWeight: 300,
    fontSize: "16px",
  },
  personInfoRowContainer: {
    paddingTop: "12px",
    marginLeft: "10px",
    marginRight: "10px",
    textAlign: "left",
  },
  personInfoText: {
    display: "flex",
    alignItems: "flex-end",
  },
  policyInfoRowContainer: {
    paddingTop: "12px",
    marginLeft: "40px",
    marginRight: "10px",
    textAlign: "left",
  },
  policyInfoLabel: {
    display: "flex",
    alignItems: "center",
    color: colors.lightGrey,
  },
  icon: {
    width: "16px",
    height: "16px",
  },
};

type PolicyState =
  | "PENDING"
  | "ACTIVE"
  | "CANCELLED"
  | "DEAD"
  | "ACCEPTED"
  | "REJECTED"
  | "DISCHARGE";
type PropsCard = {
  data: {
    id: string;
    premium: number;
    state: PolicyState;
    signupDate: string;
    package?: {
      id: string;
      name: string;
    };
    frequency: Frequency | null;
  };
};

function toDutchPolicyState(state: PolicyState) {
  switch (state) {
    case "PENDING":
      return "In afwachting";
    case "ACCEPTED":
      return "Geaccepteerd";
    case "REJECTED":
      return "Afgewezen";
    case "ACTIVE":
      return "Actief";
    case "DEAD":
      return "Overleden";
    case "CANCELLED":
      return "Opgezegd";
    case "DISCHARGE":
      return "Geroyeerd";
    default:
      return state;
  }
}

const convertFrequencyToText = (frequency: Frequency) => {
  if (!frequency) {
    return "(Onbekende betalingsfrequentie)";
  }

  return formatFrequency2(frequency).toLowerCase();
};

function propsMapper(props: PropsCard) {
  const { package: dataPackage, frequency, signupDate, state } = props.data;

  let infoArray = [
    {
      info: dataPackage && dataPackage.name ? dataPackage.name : "Geen pakket",
      text: "Pakket",
    },
    {
      info:
        formatPrice(parseAmount(props.data.premium)) +
        (frequency ? ` ${convertFrequencyToText(frequency)}` : ""),
      text: "Contributie",
    },
    { info: parseDate(signupDate), text: "Lid sinds" },
    {
      info: toDutchPolicyState(state),
      text: "Status lidmaatschap",
    },
  ];

  return infoArray.map(function (object) {
    return (
      <div
        key={object.text}
        style={{ display: "flex", ...stylesCard.policyInfoRowContainer }}
      >
        <div style={{ flex: 1, ...stylesCard.policyInfoLabel }}>
          {object.text}
        </div>
        <div style={{ flex: 1 }}>{object.info}</div>
      </div>
    );
  });
}

export const policyInfoFragment = gql`
  fragment policy on Policy {
    id
    package {
      id
      name
    }
    premium
    frequency
    signupDate
    state
  }
`;

/**
 * Represents a 'card' that contains the data for a single policy.
 */
const PolicyCardPolicyInfo = (props: PropsCard) => (
  <div>{propsMapper(props)}</div>
);

export default PolicyCardPolicyInfo;
