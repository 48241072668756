// @flow

import type {
  AddAdditionalInsuranceQuoteMutation,
  AddAdditionalInsuranceQuoteMutationVariables,
  Frequency,
  UpdateAdditionalInsuranceMutation,
  UpdateAdditionalInsuranceMutationVariables,
  UpdateEndDateMutation,
  UpdateEndDateMutationVariables,
} from "generated/graphql";

import { PropsParentsUpdateEndDate } from "./updateEndDate";
import { PropsParentsUpdateAdditionalInsurance } from "./update";
import { gql } from "generated";
import { graphql } from "@apollo/client/react/hoc";

export type AdditionalQuoteResult =
  AddAdditionalInsuranceQuoteMutation["addAdditionalInsuranceQuoteFromPolicy"];

export type PropsGraphQLAdditionalInsuranceQuote = {
  getQuote: (
    input: AddAdditionalInsuranceQuoteMutationVariables["input"]
  ) => Promise<AddAdditionalInsuranceQuoteMutation>;
};

const GET_ADDITIONAL_INSURANCE_QUOTE = gql(`
mutation addAdditionalInsuranceQuote($input: AddAdditionalInsuranceQuoteForPolicyInput!) {
  addAdditionalInsuranceQuoteFromPolicy(input: $input) {
      premium
      quoteExists
  }
}
`);

export const withAdditionalInsuranceQuoteMutation = graphql<
  PropsParentsUpdateAdditionalInsurance & PropsUpdateAdditionalInsurance,
  AddAdditionalInsuranceQuoteMutation,
  AddAdditionalInsuranceQuoteMutationVariables,
  PropsGraphQLAdditionalInsuranceQuote
>(GET_ADDITIONAL_INSURANCE_QUOTE, {
  props: ({ mutate }): PropsGraphQLAdditionalInsuranceQuote => ({
    getQuote: (input) => mutate({ variables: { input } }).then((e) => e.data),
  }),
});

export type PropsUpdateAdditionalInsurance = {
  updateAdditionalInsurance: (input: {
    insuredAmount: number;
    frequency: Frequency;
    premium: number;
    policyId: string;
    startDate: string;
  }) => Promise<any>;
};

const UPDATE_ADDITIONAL_INSURANCE = gql(`
mutation updateAdditionalInsurance($input: UpdateAdditionalInsuranceInput!) {
  updateAdditionalInsurance(input: $input) {
    clientMutationId
  }
}
`);

export const withUpdateAdditionalInsurance = graphql<
  PropsParentsUpdateAdditionalInsurance,
  UpdateAdditionalInsuranceMutation,
  UpdateAdditionalInsuranceMutationVariables,
  PropsUpdateAdditionalInsurance
>(UPDATE_ADDITIONAL_INSURANCE, {
  props: ({ mutate }) => ({
    updateAdditionalInsurance: (input) =>
      mutate({
        variables: { input },
      }),
  }),
});

const UPDATE_END_DATE_ADDITIONAL_INSURANCE_MUTATION = gql(`
mutation updateEndDate($input: CancelAdditionalInsuranceInput!) {
  cancelAdditionalInsurance(input: $input) {
    clientMutationId
  }
}
`);

export type PropsUpdateEndDateAdditionalInsurance = {
  updateEndDate: (input: {
    policyId: string;
    cancellationDate: string;
  }) => Promise<any>;
};

export const withEndAdditionalInsurance = graphql<
  PropsParentsUpdateEndDate,
  UpdateEndDateMutation,
  UpdateEndDateMutationVariables,
  PropsUpdateEndDateAdditionalInsurance
>(UPDATE_END_DATE_ADDITIONAL_INSURANCE_MUTATION, {
  props: ({ mutate }): PropsUpdateEndDateAdditionalInsurance => ({
    updateEndDate: (input) =>
      mutate({
        variables: { input },
      }),
  }),
});
