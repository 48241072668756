//@flow
/**
 * Created by Joshua Scheidt on 06-07-2017.
 */
import React from "react";
import { withRouter, WithRouterProps } from "react-router";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import { colorsBCV, colors } from "../../styling";
import { errorHandler } from "../../containers";
import { ErrorHandler } from "../../containers";
import Header from "../ledenPortaal/header/homeHeader";
import MigrationCode from "./migrationCode";
import MigrationDialog from "./migrationDialog";
import { Dialog } from "@mui/material";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "80px",
    paddingBottom: "50px",
  },
};

type Props = ErrorHandler & WithRouterProps;

type State = {
  codes: Array<{
    value: string;
    name?: string | null;
  }>;
  show: boolean;
};

export class MigratePage extends React.Component {
  props: Props;
  state: State;

  constructor(props: Props) {
    super(props);
    this.state = {
      codes: [],
      show: false,
    };
  }

  render() {
    const { codes } = this.state;
    return (
      <div>
        <Header />
        <div style={styles.container}>
          <h2>Bestaand lidmaatschap koppelen aan een Account</h2>
          <p style={{ textAlign: "center" }}>
            Vul de door u ontvangen migratiecodes/toegangscodes in voor alle
            leden die u wilt toevoegen aan uw account.
            <br />
            Zodra een door u ingevulde code verschijnt, is het bijbehorende
            lidmaatschap gekoppeld aan uw account.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "30px",
              paddingBottom: "30px",
            }}
          >
            {codes.map((el, index) => (
              <MigrationCode key={index} code={this.state.codes[index]} />
            ))}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "20px",
            }}
          >
            <FlatButton
              label="Lidmaatschap toevoegen"
              onClick={() => {
                this.setState({ show: true });
              }}
              backgroundColor={colors.white}
              labelStyle={{ color: colorsBCV.secondary }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <RaisedButton
              label="AFRONDEN"
              // @ts-ignore
              id="finish"
              disabled={codes.length < 1}
              backgroundColor={colorsBCV.secondary}
              labelStyle={{ color: colors.white }}
              onClick={() => {
                this.props.router.push("/account/lidmaatschappen");
              }}
            />
          </div>
        </div>
        <Dialog
          open={this.state.show}
          onClose={() => {
            this.setState({ show: false });
          }}
          fullWidth
          maxWidth="md"
          scroll={"paper"}
        >
          <MigrationDialog
            handleClose={() => {
              this.setState({ show: false });
            }}
            addMigrationCode={(code: {
              value: string;
              name?: string | null;
            }) => {
              let { codes } = this.state;
              codes.push(code);
              this.setState({ codes });
            }}
          />
        </Dialog>
      </div>
    );
  }
}

const Wrapper = withRouter(errorHandler(MigratePage));
export default Wrapper;
