// @flow

export const font = "Roboto";
export const blueHiThere = "#2196F3";
export const colors = {
  green: "#4bc0c0",
  red: "#ff6384",
  blue: "#36a2eb",
  yellow: "#ffce56",
  amber: "#FFC107",
  grey: "#e7e9ed",
  lightGrey: "#9B9B9B",
  white: "#ffffff",
  errorRed: "red",
};
export const colorArray = [
  colors.green,
  colors.red,
  colors.blue,
  colors.yellow,
  colors.grey,
];

export const colorsInsurer = {
  /**
   * Primary color: blue
   * When using a primary color in your palette,
   * this color should be the most widely used across all screens and components.
   */
  primary: "#36a2eb",
  /**
   * Secondary color: green
   * Palettes with a secondary color may use this color to indicate a related action or information.
   * The secondary color may be a darker or lighter variation of the primary color.
   */
  secondary: "#4bc0c0",
};

export { default as colorsBCV } from "./colorsBCV";
