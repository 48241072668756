import { gql } from "generated/gql";

export const UPDATE_INSURED_PERSON_MUTATION = gql(`
mutation updateInsuredPerson($input: UpdateInsuredPersonInput!) {
  updateInsuredPerson(input: $input) {
    insuredPeople {
      id
      firstName
      initials
      namePreposition
      lastName
      sex
      phone
      birthdate
    }
  }
}`);
