// @flow

import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, WithRouterProps } from "react-router";
import Logo from "./logo";
import ForgotPassword from "./forgotPassword";

import { image } from "../shared";
import Header from "../ledenPortaal/header/homeHeader";
import Login from "./login";
import ButtonCustom from "./button";
import { login, removeLoginState } from "../../actions";
import { Dispatch } from "../../reducers";
import { Dialog } from "@mui/material";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  dimmer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage:
      "linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5) ), url(" +
      image +
      ")",
    backgroundSize: "cover",
  },
  subContainer: {
    color: "white",
    textAlign: "center",
  },
  logo: {
    width: "170px",
    height: "120px",
    paddingLeft: "40px",
    paddingTop: "32px",
  },
  underlineFocus: {
    borderColor: "white",
  },
};

type StateType = {
  /**
   * The boolean for showing the password forgot dialog.
   */
  passForgotDialog: boolean;
};

type Props = WithRouterProps & ReduxProps;

/**
 * Component contains the login screen shown when first entering the HiThere webpage.
 *
 * @author Joshua Scheidt
 */
class LoginScreen extends Component<Props, StateType> {
  constructor(props: Props) {
    super(props);

    this.state = {
      passForgotDialog: false,
    };
  }

  componentWillUnmount() {
    this.props.removeState();
  }

  /**
   * Handles a button click when password is forgotten
   */
  _forgotPassword = () => {
    this.setState({ passForgotDialog: true });
  };

  /**
   * Handles the closure of the forgot password dialog.
   */
  _handleCloseForgotPasswordDialog = () => {
    this.setState({ passForgotDialog: false });
  };

  render() {
    const { login, location } = this.props;

    // The next path name is set if a user tries to go to a certain page without being logged in.
    // The value is set in the onEnter hook associated with the authenticated routes.
    let nextPathname: string | null;
    if (location && location.state && location.state.nextPathname) {
      nextPathname = location.state.nextPathname;
    }

    return (
      <div style={styles.container}>
        <Header />
        <div
          style={{
            width: "100%",
            height: "100%",
            flex: "1",
          }}
        >
          <div style={styles.dimmer}>
            <div style={styles.subContainer}>
              <Logo />
              <div style={{ fontSize: "28px" }}>Inloggen portaal</div>
              <Login
                onLogin={(username, password) =>
                  login(username, password, nextPathname)
                }
              />
              <div style={{ paddingTop: "20px" }}>
                <ButtonCustom onClick={this._forgotPassword}>
                  Wachtwoord vergeten?
                </ButtonCustom>
              </div>
            </div>
            <Dialog
              open={this.state.passForgotDialog}
              onClose={this._handleCloseForgotPasswordDialog}
              fullWidth
              maxWidth="sm"
            >
              <ForgotPassword
                onPressClose={this._handleCloseForgotPasswordDialog}
              />
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

const dispatchToProps = (dispatch: Dispatch) => ({
  login: (username: string, password: string, nextPathname: string | null) =>
    // @ts-ignore
    dispatch(login(username, password, nextPathname)),
  // @ts-ignore
  removeState: () => dispatch(removeLoginState()),
});

const loginConnect = connect(null, dispatchToProps);

type ReduxProps = ConnectedProps<typeof loginConnect>;

const loginComponent = loginConnect(LoginScreen);
export default withRouter(loginComponent);
