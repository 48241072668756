// @flow
/**
 * Created by Dirk-Jan Rutten on 01/11/16.
 */

import React from "react";
import { gql } from "@apollo/client";
import Person from "material-ui/svg-icons/social/person";
import Cake from "material-ui/svg-icons/social/cake";
import Gender from "material-ui/svg-icons/notification/wc";
import CreditCard from "material-ui/svg-icons/action/credit-card";
import Home from "material-ui/svg-icons/action/home";

import { colors } from "../../../../styling/index";
import { parseDate } from "../../../../utils";
import { formatAddress } from "utils/formatter";

const stylesCard: { [key: string]: React.CSSProperties } = {
  paperElement: {
    height: "100%",
    width: "100%",
    textAlign: "center",
    paddingTop: "20px",
    paddingLeft: "28px",
    paddingRight: "28px",
    paddingBottom: "28px",
    fontWeight: 300,
    fontSize: "16px",
  },
  personInfoRowContainer: {
    paddingTop: "12px",
    marginLeft: "10px",
    marginRight: "10px",
    textAlign: "left",
  },
  personInfoText: {
    display: "flex",
    alignItems: "flex-end",
  },
  policyInfoRowContainer: {
    paddingTop: "12px",
    marginLeft: "40px",
    marginRight: "10px",
    textAlign: "left",
  },
  policyInfoLabel: {
    display: "flex",
    alignItems: "center",
    color: colors.lightGrey,
  },
  icon: {
    width: "16px",
    height: "16px",
  },
};

type PropsCard = {
  data: {
    id: string;
    memberId: string;
    fullName: string;
    age: number | null;
    birthdate: string | null;
    sex: "MALE" | "FEMALE";
    payment: {
      id: string;
      iban: string | null;
    };
    address: any | null;
  };
};

function toDutch(sex: "MALE" | "FEMALE") {
  if (sex === "MALE") {
    return "Man";
  } else {
    return "Vrouw";
  }
}

export const userInfoFragment = gql`
  fragment user on InsuredPerson {
    id
    memberId
    fullName
    age
    birthdate
    sex
    payment {
      id
      iban
    }
    address {
      id
      houseNumber
      postalCode
      suffix
      street
      city
      countryCode
    }
  }
`;

/**
 * Represents a 'card' that contains the data for a single policy.
 */
const PolicyCardUserInfo = (props: PropsCard) => {
  const { birthdate, fullName, sex, age } = props.data;

  let ageText = age ? ` (${age} jaar)` : "";
  let infoArray = [
    {
      info: fullName,
      icon: <Person style={stylesCard.icon} color={"lightgray"} />,
    },
    {
      info: birthdate
        ? parseDate(birthdate) + ageText
        : "Geboortedatum niet bekend",
      icon: <Cake style={stylesCard.icon} color={"lightgray"} />,
    },
    {
      info: toDutch(sex),
      icon: <Gender style={stylesCard.icon} color={"lightgray"} />,
    },
    {
      info: "lidnummer " + props.data.memberId,
      icon: null,
    },
    {
      info: props.data.payment?.iban || "IBAN niet bekend",
      icon: <CreditCard style={stylesCard.icon} color={"lightgray"} />,
    },
    {
      info: props.data.address
        ? formatAddress(props.data.address)
        : "Adres niet bekend",
      icon: <Home style={stylesCard.icon} color={"lightgray"} />,
    },
  ];

  const content = infoArray.map((object) => {
    return (
      <div
        key={object.info}
        style={{ display: "flex", ...stylesCard.personInfoRowContainer }}
      >
        <div style={{ width: "30px", ...stylesCard.personInfoText }}>
          {object.icon}
        </div>
        {object.info === fullName ? (
          <div
            style={{
              flex: 1,
              fontSize: "18px",
              color: colors.green,
              ...stylesCard.personInfoText,
              height: "40px",
            }}
          >
            {object.info}
          </div>
        ) : (
          <div style={{ flex: 1, ...stylesCard.personInfoText }}>
            {object.info}
          </div>
        )}
      </div>
    );
  });

  return <div>{content}</div>;
};

export default PolicyCardUserInfo;
