// @flow

import Entry from "./entry";
import SectionTitle from "../components/sectionTitle";
import { FragmentType, gql, useFragment } from "generated";

type Props = {
  data: FragmentType<typeof Fragment2>;
};

const HistoryList = (props: Props) => {
  const data = useFragment(Fragment2, props.data);

  const { events } = data;

  let content;
  if (events.length === 0) {
    content = <span>Er is nog geen geschiedenis voor dit lid</span>;
  } else {
    content = events.map((event) => {
      return <Entry data={event} key={event.id} />;
    });
  }

  return (
    <div data-test="history">
      <SectionTitle title="Geschiedenis" />
      {content}
    </div>
  );
};

const Fragment2 = gql(`
fragment HistoryPerson on InsuredPerson {
    id
    events {
        id
        ...EventEntry
    }
}
`);

export default HistoryList;
