// @flow

import React from "react";
import { VerticalSeparator } from "../../../../components/layout";
import Attachment from "./attachmentSelect";
import FileList from "./fileList";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import { red500 } from "material-ui/styles/colors";
import Editor from "./editor";
import VariableButtons from "./variableButtons";

export default class EmailComposer extends React.Component {
  props: {
    subject: string;
    onSetSubject: (subject: string) => void;
    editorState: any;
    onEditorStateChange: (state: any) => void;
    files: Array<File>;
    onAddFile: (file: File) => void;
    onDeleteFile: (index: number) => void;
    sendMail: () => void;
    onInsert: (text: string) => void;
  };

  state: {
    showEmptyMessageError: boolean;
  } = {
    showEmptyMessageError: false,
  };

  render() {
    const {
      subject,
      onSetSubject,
      editorState,
      files,
      onEditorStateChange,
      onAddFile,
      onDeleteFile,
      sendMail,
      onInsert,
    } = this.props;

    const { showEmptyMessageError } = this.state;

    const emptyEditor = !editorState.getCurrentContent().hasText();

    return (
      <div style={{ width: "900px" }}>
        <h2>Onderwerp</h2>
        <TextField
          value={subject}
          onChange={(e: any) => onSetSubject(e.target.value)}
          data-test="subject_field"
          name="subject"
          fullWidth
        />
        <h2>Bericht</h2>
        {showEmptyMessageError ? (
          <p style={{ color: red500 }}>Er is geen bericht geschreven</p>
        ) : null}
        <Editor
          editorState={editorState}
          onEditorStateChange={(state) => {
            this.setState({ showEmptyMessageError: false });
            onEditorStateChange(state);
          }}
        />
        <VerticalSeparator distance={2} />
        <VariableButtons onInsert={onInsert} />
        <VerticalSeparator distance={4} />
        <h2>Bijlages</h2>
        <Attachment onAddFile={onAddFile} />
        <FileList files={files} onDeleteFile={onDeleteFile} />
        <VerticalSeparator distance={4} />

        <RaisedButton
          data-test="send_mail_button"
          primary={true}
          label="Verstuur bericht"
          onClick={() => {
            if (emptyEditor) {
              this.setState({
                showEmptyMessageError: true,
              });
            } else {
              sendMail();
            }
          }}
        />
      </div>
    );
  }
}
