// @flow

import React from "react";
import { withRouter, WithRouterProps } from "react-router";
import CircularProgress from "material-ui/CircularProgress";
import { colorsBCV as colors } from "../../styling";
import { activateAccount } from "../../api";
import Error from "./error";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  title: {
    fontSize: 30,
    fontWeight: "100",
  },
  button: {
    backgroundColor: "white",
    border: "1px solid",
    borderColor: colors.secondary,
    color: colors.secondary,
    height: 30,
    fontSize: "14px",
  },
};

type ErrorType = "ALREADY_ACTIVATED" | "ERROR";

type Props = WithRouterProps<{ id: string | null; username: string | null }>;
type State = {
  errorState: ErrorType | null;
  loading: boolean;
};

export class ActivateAccount extends React.Component<Props, State> {
  state: State = {
    loading: false,
    errorState: null,
  };

  // $FlowFixMe
  componentDidMount = async () => {
    const { location } = this.props;
    const { id, username } = location.query;

    if (!id || !username) {
      this.setState({
        errorState: "ERROR",
      });
      return;
    }

    this.setState({
      loading: true,
    });
    try {
      const activated = await activateAccount(username, id);
      this.setState({
        errorState: activated ? "ALREADY_ACTIVATED" : null,
        loading: false,
      });
    } catch (e) {
      this.setState({
        errorState: "ERROR",
        loading: false,
      });
    }
  };

  render = () => {
    const { loading, errorState } = this.state;
    const { router } = this.props;

    if (loading) {
      return (
        <div style={styles.container}>
          {/* @ts-ignore */}
          <CircularProgress id="circular" />
        </div>
      );
    }

    let alreadyActivated = false;
    if (errorState) {
      switch (errorState) {
        case "ERROR":
          return (
            <div id="error" style={styles.container}>
              <Error onGoHome={() => router.push("/")} />
            </div>
          );
        case "ALREADY_ACTIVATED":
          alreadyActivated = true;
          break;
        default:
      }
    }

    return (
      <div id="noError" style={styles.container}>
        <span style={styles.title}>
          {alreadyActivated
            ? "Uw account is al geactiveerd"
            : "Uw account is geactiveerd, welkom!"}
        </span>
        <div style={{ paddingTop: "15px", paddingBottom: "15px" }} />
        <button
          id="button"
          style={styles.button}
          onClick={() => router.push("/inloggen")}
        >
          Inloggen
        </button>
      </div>
    );
  };
}

export default withRouter(ActivateAccount);
