// @flow

import React, { useState } from "react";
import { colors } from "../../../../styling";
import { parseDate } from "../../../../utils";
import RaisedButton from "material-ui/RaisedButton";
import { formatGender, formatState } from "../../../../utils";
import { ActiveState, PendingState, AcceptedState } from "./stateBox";
import UpdatePersonDialog from "./updatePersonDialog";
import SectionTitle from "../components/sectionTitle";
import TitleContainer from "../components/titleContainer";
import { Row } from "../components/row";
import EditButton from "../components/editButton";
import { PhoneUpdateDialog } from "./phoneUpdate";
import BirthdateUpdate from "./birthdateUpdate";
import { FragmentType, gql, useFragment } from "generated";
import { PersonDetailsFragment, PolicyState } from "generated/graphql";
import { Dialog } from "@mui/material";
import { UpdateSignupDateButton } from "./signupDateUpdate";
import { UpdateMemberIdButton } from "./memberIdUpdate";
import { UpdateSignOffDateButton } from "./signoffDateUpdate";

type Props = {
  data: FragmentType<typeof personDetailsFragment>;
  disabled: boolean;
};

const PersonalDetails = (props: Props) => {
  const data = useFragment<PersonDetailsFragment>(
    personDetailsFragment,
    props.data
  );

  const [show, setShow] = useState(false);
  const [showUpdatePhone, setShowUpdatePhone] = useState(false);
  const [showUpdateBirthdate, setShowUpdateBirthdate] = useState(false);

  const stateBox = createStateBox(data);

  const { id, insuredPerson, signupDate, __typename } = data;

  const {
    memberId,
    firstName,
    initials,
    namePreposition,
    lastName,
    sex,
    birthdate,
    age,
    phone,
  } = insuredPerson;

  const rows = [];
  rows.push(
    createRowItem(
      "Lidnummer",
      memberId,
      <UpdateMemberIdButton insuredPersonId={insuredPerson.id} />
    )
  );
  rows.push(createRowItem("Voornaam", firstName ? firstName : "Niet gezet"));
  rows.push(createRowItem("Initialen", initials));
  if (namePreposition != null && namePreposition !== "") {
    rows.push(createRowItem("Tussenvoegsel", namePreposition));
  }
  rows.push(createRowItem("Achternaam", lastName));

  rows.push(
    createRowItem(
      "Telefoonnummer",
      phone ? phone : "Onbekend",
      <EditButton
        data-test="update_phone_button"
        onClick={() => setShowUpdatePhone(true)}
        disabled={props.disabled}
      />,
      "person_phone_value"
    )
  );

  rows.push(createRowItem("Geslacht", formatGender(sex)));

  rows.push(
    createRowItem(
      "Geboortedatum",
      convertBirthdateToText(birthdate),
      <EditButton
        onClick={() => setShowUpdateBirthdate(true)}
        disabled={props.disabled}
      />,
      "birthdate_value"
    )
  );

  if (age != null) {
    rows.push(createRowItem("Leeftijd", String(age)));
  }

  rows.push(createRowItem("Status lidmaatschap", formatState(__typename)));

  const parsedSignOffDate = data.signOffDate
    ? parseDate(data.signOffDate)
    : "Onbekend";
  rows.push(
    createRowItem(
      "Aangemeld op",
      parseDate(signupDate),
      <UpdateSignupDateButton
        disabled={props.disabled}
        policyId={id}
        signupDate={parseDate(signupDate)}
      />
    )
  );

  const signOffUpdateButton = <UpdateSignOffDateButton data={data} />;

  if (data.state === PolicyState.Dead) {
    rows.push(
      createRowItem("Overleden op", parsedSignOffDate, signOffUpdateButton)
    );
  } else if (data.state === PolicyState.Cancelled) {
    rows.push(
      createRowItem("Opgezegd op", parsedSignOffDate, signOffUpdateButton)
    );
  } else if (data.state === PolicyState.Discharge) {
    rows.push(
      createRowItem("Geroyeerd op", parsedSignOffDate, signOffUpdateButton)
    );
  } else if (data.state === PolicyState.Rejected) {
    rows.push(
      createRowItem("Afgewezen op", parsedSignOffDate, signOffUpdateButton)
    );
  }

  return (
    <div>
      <TitleContainer>
        <SectionTitle title="Persoonsinformatie" />
        <div>
          <RaisedButton
            label="WIJZIG"
            backgroundColor={colors.green}
            labelStyle={{ color: "white" }}
            onClick={() => setShow(true)}
            disabled={props.disabled}
          />
        </div>
      </TitleContainer>
      {rows}
      {stateBox}

      <Dialog open={show} onClose={() => setShow(false)}>
        <UpdatePersonDialog
          handleClose={() => setShow(false)}
          data={data.insuredPerson}
        />
      </Dialog>

      <PhoneUpdateDialog
        open={showUpdatePhone}
        insuredPersonIds={[data.insuredPerson.id]}
        onClose={() => setShowUpdatePhone(false)}
      />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={showUpdateBirthdate}
        onClose={() => setShowUpdateBirthdate(false)}
      >
        <BirthdateUpdate
          insuredPersonId={data.insuredPerson.id}
          birthdate={data.insuredPerson.birthdate}
          onClose={() => setShowUpdateBirthdate(false)}
        />
      </Dialog>
    </div>
  );
};

const createRowItem = (
  key: string,
  value: string,
  button?: any,
  dataTest?: string | null
) => {
  return (
    <Row
      name={key}
      value={value}
      key={key}
      button={button}
      data-test={dataTest}
    />
  );
};

const convertBirthdateToText = (birthdate: string | null) =>
  birthdate ? parseDate(birthdate) : "Onbekend";

const createStateBox = (data: PersonDetailsFragment) => {
  const { state } = data;
  switch (state) {
    case PolicyState.Pending:
      return <PendingState policyId={data.id} />;
    case PolicyState.Accepted:
      return (
        <AcceptedState
          policyId={data.id}
          signatureDate={
            data.insuredPerson.payment
              ? data.insuredPerson.payment.signatureDate
              : null
          }
        />
      );
    case PolicyState.Active:
      return <ActiveState policyId={data.id} />;
    case PolicyState.Cancelled:
      return null;
    case PolicyState.Dead:
      return null;
    case PolicyState.Discharge:
      return null;
    case PolicyState.Rejected:
      return null;
    default:
      throw new Error(`Unknown policy state ${state}`);
  }
};

const personDetailsFragment = gql(`
  fragment PersonDetails on Policy {
    id
    state
    signupDate
    signOffDate
    ...PolicySignOffButton
    __typename
    insuredPerson {
      id
      firstName
      initials
      namePreposition
      phone
      lastName
      sex
      memberId
      birthdate
      age
      payment {
        id
        signatureDate
      }
      ...personDialogFragment
    }
  }
`);

export default PersonalDetails;
