import { colors } from "../../../styling";
import React from "react";

const EmptyContainer = () => {
  return (
    <div
      style={{
        color: colors.grey,
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "20px",
      }}
    >
      Er is geen data beschikbaar
    </div>
  );
};

export default EmptyContainer;
