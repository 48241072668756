import { MenuButton, SideBar } from "../../../components/header";
import { AppBar, CircularProgress, Tooltip, Button } from "@mui/material";

import { downloadLink } from "../../../utils";
import { gql } from "generated";
import { Box, Toolbar, Typography } from "@mui/material";
import { useMutationForApp } from "utils/withApollo";
import { downloadLinks } from "utils/downloadLink";

export const AdministrationHeader = (props: {
  searchField: any;
  canDownloadMembershipCard: boolean;
}) => {
  const [createDump, { loading }] = useMutationForApp(CREATE_DUMP_MUTATION, {
    variables: {
      input: {},
    },
    onCompleted: (data) => downloadLink(data.createDump.url),
  });

  const [downloadCards, { loading: loadingDownloadCard }] = useMutationForApp(
    mutation,
    {
      onCompleted: ({ generateMembershipCard }) =>
        generateMembershipCard?.urls &&
        downloadLinks(generateMembershipCard?.urls),
    }
  );

  const elementRight = loading ? (
    <CircularProgress color="inherit" size={"1.5rem"} />
  ) : (
    <Tooltip title="Download het hele administratiebestand in excel.">
      <Button
        variant="text"
        color="inherit"
        onClick={() => {
          createDump();
        }}
      >
        Download
      </Button>
    </Tooltip>
  );

  return (
    <>
      <AppBar>
        <Toolbar>
          <MenuButton />
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Administratie
          </Typography>
          {props.searchField}

          <Box sx={{ flexGrow: 1 }} />
          {props.canDownloadMembershipCard && !loadingDownloadCard && (
            <Button
              variant="text"
              color="inherit"
              onClick={() => downloadCards()}
            >
              Download ledenkaarten
            </Button>
          )}
          {loadingDownloadCard && (
            <CircularProgress color="inherit" size={"1.5rem"} />
          )}
          {elementRight}
        </Toolbar>
      </AppBar>
      {/* add a second toolbar so the height of the content below is correct. */}
      <Toolbar />
      <SideBar />
    </>
  );
};

export const CREATE_DUMP_MUTATION = gql(`
mutation createDump($input: CreateDumpInput!) {
  createDump(input: $input) {
    url
  }
}
`);

export const mutation = gql(`
mutation generateMembershipCards {
  generateMembershipCard(input: {}) {
    urls
  }
}`);
