// @flow

import DefaultPage from "../../components/defaultPage";
import Title from "../../components/title";

const InformationPageHulpEnSteun = () => {
  return (
    <DefaultPage>
      <Title title="Gegevens wijzigen" />
      <p>
        Leden kunnen gegevens wijzigen door in te loggen op de site of via een
        e-mail naar de ledenadministratie.
      </p>
      
      <Title title="AVG - privacyverklaring" />
      <p>
        Uw privacy wordt door ons gerespecteerd. Hulp en Steun streeft ernaar om
        uw privacy zo goed mogelijk te waarborgen en zal vertrouwelijk omgaan
        met de informatie die u bij ons aanlevert. Bij de verwerking van
        persoonsgegevens nemen wij de geldende wet- en regelgeving op het gebied
        van privacy in acht. In deze privacyverklaring informeren wij u over de
        wijze waarop wij met uw gegevens omgaan.
      </p>
      <h3>Categorieën persoonsgegevens</h3>
      <p>
        In het kader van de uitvoering van de overeenkomst tussen u en Hulp en
        Steun is het noodzakelijk dat u gegevens aan ons verstrekt. Dat kunnen
        persoonsgegevens zijn. Wij bewaren en gebruiken uitsluitend de
        persoonsgegevens die rechtstreeks door u worden opgegeven of waarvan bij
        opgave duidelijk is dat ze aan ons worden verstrekt om te verwerken.
      </p>
      <h3>Wij verzamelen de volgende gegevens</h3>
      <ul>
        <li>Naam adres woonplaats gegevens;</li>
        <li>Geboortedatum;</li>
        <li>Geslacht;</li>
        <li>E-mailadres;</li>
        <li>Telefoonnummer;</li>
        <li>Premie;</li>
        <li>Aanvullende premie;</li>
        <li>Leeftijd;</li>
        <li>Betalingsgegevens;</li>
        <li>
          Het aanmaken van uw ledenkaart en u in te schrijven / uit te
          schrijven;
        </li>
        <li>Het uitvoeren van de gesloten overeenkomst;</li>
        <li>
          Het verwerken van financiële transacties en registratie van premies;
        </li>
        <li>Het bijhouden van door u aangegeven voorkeuren;</li>
        <li>Het versturen van een nieuwsbrief;</li>
        <li>Het verbeteren van onze dienstverlening;</li>
        <li>Het uitvoeren van overige, door u gevraagde, diensten.</li>
        <ul>
          <li>
            Uw persoonsgegevens slaan wij op en gebruiken we zodat wij onze
            dienstverlening aan u kunnen verlenen.
          </li>
          <li>
            Uw gegevens geven wij alleen door aan derden als dat nodig is voor
            het leveren van onze dienst. Indien hiervoor op basis van de wet
            specifieke toestemming is vereist, vragen we die vooraf aan u.
          </li>
          <li>
            U kunt onderdelen van uw persoonsgegevens bekijken. Stuurt u ons dan
            een schriftelijk verzoek.
          </li>
          <li>
            Als u vindt dat de gegevens die wij van u hebben opgeslagen niet
            correct zijn, mag u ons schriftelijk vragen om uw gegevens aan te
            passen.
          </li>
          <li>
            U kunt ons vragen om uw persoonsgegevens te wissen. Als wij dit doen
            kunnen wij niet langer onze diensten aan u verlenen. De wet vereist
            een bewaartermijn van 7 jaar. Daarom slaan we uw gegevens in dat
            geval op in een inactief archief, onzichtbaar en onbruikbaar voor de
            normale gebruiker.
          </li>
        </ul>
      </ul>
      <h3>Grondslag voor gegevensverwerking</h3>
      <p>
        Wij mogen alleen rechtmatig persoonsgegevens van u verwerken als wij dat
        doen op basis van een juridische grondslag. Wij verwerken uw
        persoonsgegevens omdat dit noodzakelijk is voor het uitvoeren van de
        overeenkomst tussen u en Hulp en Steun, zoals neergelegd in artikel 6
        lid 1 sub b van de Algemene Verordening Gegevensbescherming (AVG).
        Daarnaast kunnen wij uw persoonsgegevens verwerken voor een ander
        gerechtvaardigd belang, zoals het informeren van onze leden over
        actualiteiten of wijzigingen in onze dienstverlening. Deze grondslag is
        neergelegd in artikel 6 lid 1 sub f van de AVG.
      </p>
      <p>
        Door het verstrekken van deze gegevens gaat u nadrukkelijk akkoord met
        het vastleggen en verwerken van deze gegevens door Hulp en Steun.
      </p>
      <h3>Doeleinden gegevensverwerking</h3>
      <p>
        De persoonsgegevens die door ons worden verzameld, worden gebruikt voor
        de volgende doeleinden: Verstrekking van uw persoonsgegevens aan derden
      </p>
      <p>
        Wij verstrekken uw persoonsgegevens in beginsel alleen aan derde
        partijen indien u daar zelf toestemming voor heeft gegeven.
        Gegevensverstrekking zonder uw toestemming vindt plaats indien dat
        noodzakelijk is om de overeenkomst tussen u en ons uit te kunnen voeren
        of een wettelijke verplichting ons dat voorschrijft.
      </p>
      <h3>Bewaartermijnen</h3>
      <p>
        Zoals in de Wet bescherming persoonsgegevens (WBP), opgenomen, zullen
        wij uw gegevens niet langer bewaren dan noodzakelijk is voor de in deze
        privacyverklaring beschreven doeleinden, tenzij dat nodig is op grond
        van een wettelijke verplichting. Voor het bewaren van deze gegevens
        hanteren wij de wettelijke bewaartermijn van 7 jaar.
      </p>
      <h3>Digitale opslag</h3>
      <p>
        Uw persoonsgegevens slaan wij digitaal op. Dit doen wij om onze
        dienstverlening aan u te kunnen verlenen. U gaat met die opslag
        (verwerking) akkoord door de overeenkomst voor de dienstverlening met
        ons aan te gaan.
      </p>
      <p>
        U kunt uw toestemming tot onze verwerking van uw persoonsgegevens
        intrekken. Wij kunnen dan onze dienstverlening niet garanderen. Wij
        slaan uw gegevens in dat geval op in een inactief archief. (zie boven)
      </p>
    </DefaultPage>
  );
};

export default InformationPageHulpEnSteun;
