import React from "react";
import DefaultPage from "../../components/defaultPage";
import Board from "./board";
import BoardCalendar from "./boardCalendar";

const BoardPageBCB = () => {
  return (
    <DefaultPage>
      <Board />
      <BoardCalendar />
    </DefaultPage>
  );
};

export default BoardPageBCB;
