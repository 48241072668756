//@flow
import React from "react";
import Header from "../../../containers/ledenPortaal/header/clientHeader";
import Navbar from "../../../containers/ledenPortaal/header/navbar";
import { filter } from "graphql-anywhere";
import Loader from "../../loader";
import PersonalDetails, { PersonalInfoFragmentDef } from "./personalDetails";
import ProfileDetails, { ProfileInfoFragmentDef } from "./profileDetails";
import { ActivateAccountPrompt } from "../components";
import { DataProps, graphql } from "@apollo/client/react/hoc";
import { UserQueryQuery, UserQueryQueryVariables } from "generated/graphql";
import { gql } from "@apollo/client";

const styles: { [key: string]: React.CSSProperties } = {
  outerContainer: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "100px",
  },
  allInfoHolder: {
    width: "500px",
    textAlign: "left",
    justifyContent: "left",
    alignItems: "left",
    lineHeight: 1.5,
  },
};

type Props = DataProps<UserQueryQuery, UserQueryQueryVariables>;

export class Account extends React.Component {
  props: Props;
  render() {
    let { loading, error, viewer } = this.props.data;
    return (
      <div style={{ paddingBottom: "20px" }}>
        <Header nameHeader="Mijn profiel" />
        <Navbar />
        <Loader isFetching={loading} error={error} fullScreen={true}>
          {viewer ? (
            <div style={styles.outerContainer}>
              <div
                style={{
                  ...styles.allInfoHolder,
                  alignItems: "center",
                  paddingLeft: "75px",
                }}
              >
                <ProfileDetails
                  infoProfile={filter(ProfileInfoFragmentDef, viewer)}
                />
                <PersonalDetails
                  infoPersonal={filter(PersonalInfoFragmentDef, viewer)}
                />
              </div>
              {viewer.verified ? null : <ActivateAccountPrompt />}
            </div>
          ) : null}
        </Loader>
      </div>
    );
  }
}

export const ACCOUNT_QUERY = gql`
  query UserQuery {
    viewer {
      id
      verified
      ...ProfileInfo
      ...PersonalInfo
    }
  }
  ${ProfileInfoFragmentDef}
  ${PersonalInfoFragmentDef}
`;
export const withData = graphql<{}, UserQueryQuery, UserQueryQueryVariables>(
  ACCOUNT_QUERY
);
export default withData(Account);
