// @flow

import React, { Component } from "react";
import RaisedButton from "material-ui/RaisedButton";
import { errorHandler, ErrorHandler } from "../../../../../containers";
import { formatToEuro } from "../../../../../utils/index";

import { UpdatePackageQuoteMutationDef } from "../../../../policyDetailsPage/components/package/contribution/standard";
import {
  Frequency,
  UpdatePackageQuoteMutation,
  UpdatePackageQuoteMutationVariables,
} from "generated/graphql";
import { graphql } from "@apollo/client/react/hoc";
import { FetchResult, gql } from "@apollo/client";
import { formatFrequency2 } from "utils/formatter";

type PropsParent = {
  onCalculatedContribution: (quote: { id: string; premium: number }) => void;
  policyId: string;
  packageId: string;
};

type PropsGraphQL = {
  updatePackageQuote: (
    input: UpdatePackageQuoteMutationVariables["input"]
  ) => Promise<FetchResult<UpdatePackageQuoteMutation>>;
};

type Props = PropsParent & ErrorHandler & PropsGraphQL;

type State = {
  loading: boolean;
  emptyQuoteError: boolean;
  quote: UpdatePackageQuoteMutation["updatePackageQuote"]["quote"] | null;
};

export class Contribution extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      emptyQuoteError: false,
      quote: null,
    };
  }

  render() {
    const { loading, emptyQuoteError, quote } = this.state;
    return (
      <div>
        <h2>Bepaal de contributie</h2>
        <div style={{ textAlign: "center" }}>
          <RaisedButton
            // @ts-ignore
            id="calculatePremium"
            label="Bereken"
            primary={true}
            disabled={loading}
            onClick={this._calculateContribution}
          />
          {quote ? (
            <ContributionAmount
              // @ts-ignore
              id="contribution"
              contribution={quote.premium}
              frequency={quote.frequency}
              lumpsum={quote.lumpsum}
            />
          ) : null}
          {/* @ts-ignore */}
          {emptyQuoteError ? <EmptyQuote id="emptyQuote" /> : null}
        </div>
      </div>
    );
  }

  _calculateContribution = async () => {
    const {
      updatePackageQuote,
      onCalculatedContribution,
      policyId,
      packageId,
      error,
    } = this.props;
    try {
      this.setState({
        loading: true,
        emptyQuoteError: false,
        quote: null,
      });
      const quote = await updatePackageQuote({ policyId, packageId });
      const quoteInfo = quote.data.updatePackageQuote;
      if (quoteInfo.quote) {
        // @ts-ignore
        onCalculatedContribution(quoteInfo.quote);
        this.setState({ loading: false, quote: quoteInfo.quote });
      } else {
        this.setState({ loading: false, emptyQuoteError: true });
      }
    } catch (e) {
      this.setState({ loading: false });
      error.display();
    }
  };
}

export const ContributionAmount = ({
  contribution,
  lumpsum,
  frequency,
}: {
  contribution: number;
  lumpsum: number;
  frequency: Frequency;
}) => {
  const frequencyText = formatFrequency2(frequency).toLocaleLowerCase();

  let lumpsumText;

  if (lumpsum > 0) {
    lumpsumText = <p>De koopsom is {formatToEuro(lumpsum)}</p>;
  }

  return (
    <div>
      <p>
        De contributie is {formatToEuro(contribution)} {frequencyText}
      </p>
      {lumpsumText}
    </div>
  );
};

export const EmptyQuote = () => (
  <p>
    Wij hebben geen tarief kunnen vinden voor deze pakketwijziging. Neem gelieve
    contact met ons op.
  </p>
);

export const withMutation = graphql<
  PropsParent,
  UpdatePackageQuoteMutation,
  UpdatePackageQuoteMutationVariables,
  PropsGraphQL
>(UpdatePackageQuoteMutationDef, {
  props: ({ mutate }) => ({
    updatePackageQuote: (input) => mutate({ variables: { input } }),
  }),
});

export const TransferFragment = gql`
  fragment TransferDialog on Policy {
    id
    insuredPerson {
      id
    }
  }
`;

const Wrapped = withMutation(errorHandler(Contribution));

export default Wrapped;
