// @flow
import React from "react";
import { FragmentType, gql, useFragment } from "generated";
import { parseDateTime } from "../../../../utils";

const style: React.CSSProperties = {
  display: "block",
  fontSize: "18px",
};

type Props = {
  data: FragmentType<typeof Fragment>;
};

const HistoryEntryContainer = (props: Props) => {
  const data = useFragment(Fragment, props.data);

  const parsedDateTime = parseDateTime(data.date);

  const name = data.creator ? data.creator.fullName : "Onbekende gebruiker";
  return (
    <HistoryEntry
      name={name}
      date={parsedDateTime}
      description={data.description}
    />
  );
};

const HistoryEntry = (props: any) => {
  return (
    <p>
      <span style={style}>
        {props.name} · {props.date}
      </span>
      <span style={{ color: "grey", whiteSpace: "pre-wrap" }}>
        {props.description}
      </span>
    </p>
  );
};

const Fragment = gql(`
fragment EventEntry on Event {
    id
    date
    description
    creator {
        id
        fullName
    }
}
`);

export default HistoryEntryContainer;
