import { Typography, CircularProgress } from "@mui/material";
import { CenterInScreen, ScreenContainer } from "components/layout";
import { withRouter, WithRouterProps } from "react-router";
import { PolicyTable } from "./components/policyTable";
import { useInsuredPeopleQuery } from "./graphqlQueries";
import { Header } from "./header";
import { pushWithPreviousPath } from "utils/routing";
import { insurerPaths } from "screens/routes";
import { GroupInfo } from "./components/groupInfo";

export const InsuredPeopleGroupPage = withRouter((props: WithRouterProps) => {
  const { loading, data } = useInsuredPeopleQuery(props);

  const group = data?.insuredPeopleGroup;

  return (
    <>
      <Header
        hasMembershipCardTemplate={
          data?.viewer?.association?.membershipCardTemplateKey != null
        }
        insuredPersonIds={group?.insuredPeople?.map((e) => e.id)}
      />
      {loading && (
        <CenterInScreen>
          <CircularProgress />
        </CenterInScreen>
      )}
      <ScreenContainer>
        {!loading && !group && (
          <CenterInScreen>
            <Typography variant="h5">Geen lid gevonden gevonden</Typography>
          </CenterInScreen>
        )}
        {group && !loading && (
          <>
            <GroupInfo data={data.insuredPeopleGroup} />
            <PolicyTable
              data={data.insuredPeopleGroup}
              goToPolicy={(policyId) =>
                pushWithPreviousPath(
                  props,
                  insurerPaths.policyDetailsPage.path(policyId)
                )
              }
            />
          </>
        )}
      </ScreenContainer>
    </>
  );
});
