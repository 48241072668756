// @flow

import React, { Component } from "react";
import TextField from "material-ui/TextField";
import { colors } from "../../styling";
import { isDate, serializeDate } from "../../utils";
import RaisedButton from "material-ui/RaisedButton";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
};

type Props = {
  verifyMember: (
    memberId: string | null,
    lastName: string | null,
    birthdate: string | null
  ) => void;
  setCheck: (checkVal: boolean) => void;
};

type State = {
  name: string;
  birthdate: string;
  idNumber: string;
  nameError: string | null;
  birthdateError: string | null;
  idError: string | null;
};
export default class FillInComponents extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      birthdate: "",
      idNumber: "",
      nameError: null,
      birthdateError: null,
      idError: null,
    };
  }

  _checkPerson() {
    let { idNumber, birthdate, name } = this.state;

    this.setState({
      birthdateError: null,
      idError: null,
      nameError: null,
    });

    if (!(idNumber || birthdate || name)) {
      this.setState({
        idError: "Vul minstens een van de velden in.",
        birthdateError: "Vul minstens een van de velden in.",
        nameError: "Vul minstens een van de velden in.",
      });
      return;
    }

    if (birthdate.length > 0 && !isDate(birthdate)) {
      this.setState({
        birthdateError: "Vul een valide datum in.",
      });
      return;
    }

    const body = document.body;
    if (!body) throw new Error("Body is not defined");
    // Every time that we execute a new query we need to make sure that we
    // scroll to the top of the page.
    body.scrollTop = 0;
    this.props.verifyMember(
      this.state.idNumber === "" ? null : this.state.idNumber,
      this.state.name === "" ? null : this.state.name,
      this.state.birthdate === "" || this.state.birthdate == null
        ? null
        : serializeDate(this.state.birthdate)
    );
    this.props.setCheck(true);
  }

  render() {
    return (
      <div style={styles.container}>
        <TextField
          // @ts-ignore
          id="inputMemberId"
          data-test="input_member_id"
          floatingLabelText="Lidnummer"
          value={this.state.idNumber}
          underlineStyle={{ color: colors.green }}
          errorText={this.state.idError}
          errorStyle={{ color: "red" }}
          floatingLabelStyle={{ color: colors.green }}
          underlineFocusStyle={{ borderColor: colors.green }}
          onChange={(event: any) => {
            this.setState({ idNumber: event.target.value });
            this.props.setCheck(false);
          }}
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this._checkPerson();
            }
          }}
        />
        <TextField
          // @ts-ignore
          id="inputBirthdate"
          floatingLabelText="Geboortedatum (DD-MM-JJJJ)"
          value={this.state.birthdate}
          underlineStyle={{ color: colors.green }}
          errorText={this.state.birthdateError}
          errorStyle={{ color: "red" }}
          floatingLabelStyle={{ color: colors.green }}
          underlineFocusStyle={{ borderColor: colors.green }}
          onChange={(event: any) => {
            this.setState({ birthdate: event.target.value });
            this.props.setCheck(false);
          }}
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this._checkPerson();
            }
          }}
        />
        <TextField
          // @ts-ignore
          id="inputName"
          floatingLabelText="Achternaam"
          value={this.state.name}
          underlineStyle={{ color: colors.green }}
          errorText={this.state.nameError}
          errorStyle={{ color: "red" }}
          floatingLabelStyle={{ color: colors.green }}
          underlineFocusStyle={{ borderColor: colors.green }}
          onChange={(event: any) => {
            this.setState({ name: event.target.value });
            this.props.setCheck(false);
          }}
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this._checkPerson();
            }
          }}
        />
        <div style={{ paddingTop: "30px" }}>
          <RaisedButton
            // @ts-ignore
            id="verify"
            data-test="verify_button"
            labelStyle={{ color: "white" }}
            onClick={() => {
              this._checkPerson();
            }}
            label="Verifiëren"
            backgroundColor={colors.green}
          />
        </div>
      </div>
    );
  }
}
