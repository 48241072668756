//@flow

import React, { Component } from "react";
import { Question, AnswerField, Explanation } from "./eligibilityComponents";

type Props = {
  questions: Array<{
    id: string;
    question: string;
  }>;
};

type Answer = {
  questionId: string;
  question: string;
  answer: boolean;
  explanation: string;
  inputError: boolean;
};

type State = {
  answers: Array<Answer>;
};

export default class EligibilityQuestions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const answers = props.questions.map((question) => ({
      questionId: question.id,
      question: question.question,
      answer: true,
      explanation: "",
      inputError: false,
    }));

    this.state = { answers };
  }

  getInput(): Array<{
    questionId: string;
    response: boolean;
    explanation: string;
  }> {
    return this.state.answers.map((answer) => ({
      questionId: answer.questionId,
      response: answer.answer,
      explanation: answer.explanation,
    }));
  }

  /**
   * Test whether the components of this class are valid.
   * Within the class, we check for errors, thus return !error = valid.
   */
  validate(): boolean {
    const { answers } = this.state;

    let errorPresent = false;
    let answersNew = answers.map((item) => {
      if (item.answer === true && !item.explanation) {
        item.inputError = true;
        errorPresent = true;
      }
      return item;
    });
    this.setState({ answers: answersNew });
    return !errorPresent;
  }

  _setAnswer(questionId: string, answer: boolean) {
    let answers = this.state.answers;
    let answerItem = answers.find((answerItem) => {
      return questionId === answerItem.questionId;
    });

    if (!answerItem) {
      throw new Error(`Expected answer but got ${String(answer)}`);
    }
    answerItem.answer = answer;
    answerItem.explanation = "";
    answerItem.inputError = false;
    this.setState({ answers });
  }

  _setExplanation(questionId: string, explanation: string) {
    let answers = this.state.answers;
    let answer = answers.find((itemAnswer) => {
      return questionId === itemAnswer.questionId;
    });
    if (!answer) {
      throw new Error(`Expected answer but got ${String(answer)}`);
    }

    answer.explanation = explanation;
    answer.inputError = false;
    this.setState({ answers });
  }

  _createQuestion(item: Answer) {
    const errorText = item.inputError ? "Vul een toelichting in." : "";

    return (
      <div key={item.questionId}>
        <Question question={item.question} />
        <AnswerField
          answer={item.answer}
          onAnswer={(answer) => this._setAnswer(item.questionId, answer)}
        />
        {item.answer && (
          <Explanation
            explanation={item.explanation}
            onChange={(explanation) =>
              this._setExplanation(item.questionId, explanation)
            }
            error={errorText}
          />
        )}
      </div>
    );
  }

  _hasQuestions = () => {
    return this.props.questions.length > 0;
  };

  render() {
    const { answers } = this.state;
    const questionComponents = answers.map((item) => {
      return this._createQuestion(item);
    });

    return (
      <div>
        {this._hasQuestions() ? <h3>Gezondsheidsverklaring</h3> : null}
        {questionComponents}
      </div>
    );
  }
}
