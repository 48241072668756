// @flow
/*eslint no-mixed-operators: 0*/

import React, { Component } from "react";
import Footer from "./footer";

type State = {
  show: boolean;
};

type Props = {};

/**
 * This component shows a floating footer that is only viewable only when the user's scroll
 * position is at the top of the page.
 */
export default class FloatingFooter extends Component<Props, State> {
  state: State;

  constructor(props: Props) {
    super(props);
    this._handleScroll = this._handleScroll.bind(this);
    this.state = {
      show: true,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this._handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._handleScroll);
  }

  _handleScroll() {
    const top =
      (document.documentElement && document.documentElement.scrollTop) ||
      (document.body && document.body.scrollTop);

    if (top == null) {
      throw new Error("Top is not supported");
    }
    //Dismiss footer if scroll position from top is more than 10 pixels.
    if (top > 10) this.setState({ show: false });
    //otherwise show footer:
    else this.setState({ show: true });
  }

  render() {
    return this.state.show ? (
      <Footer
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
        }}
      />
    ) : null;
  }
}
