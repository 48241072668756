// @flow

import { gql } from "@apollo/client";
import React from "react";
import { colorsBCV } from "../../../styling";
import { filter } from "graphql-anywhere";
import IconButton from "material-ui/IconButton";
import Pencil from "material-ui/svg-icons/content/create";
import UsernameDialog, { UsernameFragments } from "./dialogs/usernameDialog";
import UpdatePassword from "./dialogs/passwordDialog";
import UpdatePhone from "./dialogs/phoneDialog";
import { DocumentNode } from "graphql";
import { Dialog } from "@mui/material";

const styles: { [key: string]: React.CSSProperties } = {
  userInfo: {
    flexDirection: "column",
    textAlign: "left",
    justifyContent: "left",
    alignItems: "left",
  },
  infoTitle: {
    paddingTop: "10px",
    fontSize: "18px",
    color: colorsBCV.secondary,
  },
  buttonDiv: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
};

type Props = {
  infoPersonal: {
    id: string;
    username: string;
    phone: string;
  };
};

type State = {
  openUsername: boolean;
  openPassword: boolean;
  openPhone: boolean;
};

class PersonalDetails extends React.Component {
  state: State;
  props: Props;
  static fragments: {
    infoPersonal: DocumentNode;
  };

  constructor(props: Props) {
    super(props);
    this.state = { openUsername: false, openPassword: false, openPhone: false };
    this._handleCloseUsername = this._handleCloseUsername.bind(this);
    this._handleClosePassword = this._handleClosePassword.bind(this);
    this._handleClosePhone = this._handleClosePhone.bind(this);
  }

  /**
   * Closes the username dialog.
   */
  _handleCloseUsername: () => void = () => {
    this.setState({ openUsername: false });
  };

  /**
   * Closes the password dialog.
   */
  _handleClosePassword: () => void = () => {
    this.setState({ openPassword: false });
  };

  /**
   * Closes the phone dialog.
   */
  _handleClosePhone: () => void = () => {
    this.setState({ openPhone: false });
  };

  render() {
    let { infoPersonal } = this.props;
    return (
      <div style={styles.userInfo}>
        <div style={styles.infoTitle}>Email</div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>{infoPersonal.username}</div>
          <div style={{ ...styles.buttonDiv, flex: 1 }}>
            <IconButton
              title="Wijzig e-mail"
              onClick={() => {
                this.setState({ openUsername: true });
              }}
            >
              <Pencil color={colorsBCV.secondary} />
            </IconButton>
          </div>
        </div>
        <div style={styles.infoTitle}>Wachtwoord</div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>************</div>
          <div style={{ ...styles.buttonDiv, flex: 1 }}>
            <IconButton
              title="Wijzig wachtwoord"
              onClick={() => {
                this.setState({ openPassword: true });
              }}
            >
              <Pencil color={colorsBCV.secondary} />
            </IconButton>
          </div>
        </div>
        <div style={styles.infoTitle}>Telefoonnummer</div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>{infoPersonal.phone}</div>
          <div style={{ ...styles.buttonDiv, flex: 1 }}>
            <IconButton
              title="Wijzig telefoonnummer"
              onClick={() => {
                this.setState({ openPhone: true });
              }}
            >
              <Pencil color={colorsBCV.secondary} />
            </IconButton>
          </div>
        </div>
        <Dialog
          open={this.state.openUsername}
          onClose={this._handleCloseUsername}
        >
          <UsernameDialog
            infoUsername={filter(UsernameFragments, infoPersonal)}
            handleClose={this._handleCloseUsername}
          />
        </Dialog>
        <Dialog
          open={this.state.openPassword}
          onClose={this._handleClosePassword}
        >
          <UpdatePassword
            handleClose={this._handleClosePassword}
            username={this.props.infoPersonal.username}
          />
        </Dialog>
        <Dialog open={this.state.openPhone} onClose={this._handleClosePhone}>
          <UpdatePhone
            handleClose={this._handleClosePhone}
            accountId={infoPersonal.id}
          />
        </Dialog>
      </div>
    );
  }
}
export const PersonalInfoFragmentDef = gql`
  fragment PersonalInfo on Account {
    id
    username
    phone
    ...UsernameDialog
  }
  ${UsernameFragments}
`;

export default PersonalDetails;
