// @flow

import React, { Component } from "react";

import { Title } from "../../../components/dialog";
import ConfirmMessage from "./confirmMessage";
import CompletedMessage from "./completedMessage";
import { errorHandler, ErrorHandler } from "../../../containers";
import { graphql } from "@apollo/client/react/hoc";
import {
  ReportDeathMutation,
  ReportDeathMutationVariables,
} from "generated/graphql";
import { gql } from "@apollo/client";
import { DialogContainer2 } from "components/dialog/container";

type PropsParent = {
  onClose: () => void;
  data: {
    id: string;
    insuredPerson: {
      association: {
        name: string;
      } | null;
    };
  };
};

type Props = PropsParent & ErrorHandler & ReportDeathMutationProps;

type State = {
  state: "CONFIRM_NOTIFICATION" | "COMPLETED_NOTIFICATION";
  loading: boolean;
};

class ReportDeath extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      state: "CONFIRM_NOTIFICATION",
      loading: false,
    };
  }

  async _reportDeath() {
    const { reportDeath, data, error } = this.props;
    this.setState({ loading: true });

    try {
      await reportDeath(data.id);
      this.setState({ loading: false, state: "COMPLETED_NOTIFICATION" });
    } catch (e) {
      this.setState({ loading: false });
      error.display();
    }
  }

  render() {
    const { state, loading } = this.state;
    const { onClose, data } = this.props;

    const association = data.insuredPerson.association;

    if (!association) {
      throw new Error("Expected association to be retrieved");
    }
    const associationName = association.name;

    let content;

    if (state === "CONFIRM_NOTIFICATION") {
      content = (
        <ConfirmMessage
          onConfirm={() => {
            this._reportDeath();
          }}
          onCancel={onClose}
          disableNavigation={loading}
          associationName={associationName}
        />
      );
    } else if (state === "COMPLETED_NOTIFICATION") {
      content = (
        <CompletedMessage associationName={associationName} onClose={onClose} />
      );
    }
    return (
      <DialogContainer2>
        <Title>Overlijden Melden</Title>
        {content}
      </DialogContainer2>
    );
  }
}

export const reportDeathFragment = gql`
  fragment ReportDeathFragment on Policy {
    id
    insuredPerson {
      id
      association {
        id
        name
      }
    }
  }
`;

export const REPORT_DEATH_MUTATION = gql`
  mutation reportDeath($input: ReportDeathInput!) {
    reportDeath(input: $input) {
      clientMutationId
    }
  }
`;

type ReportDeathMutationProps = {
  reportDeath: (policyId: string) => Promise<any>;
};

const withMutation = graphql<
  PropsParent,
  ReportDeathMutation,
  ReportDeathMutationVariables,
  ReportDeathMutationProps
>(REPORT_DEATH_MUTATION, {
  props: ({ mutate }) => ({
    reportDeath: (policyId) => mutate({ variables: { input: { policyId } } }),
  }),
});

const WrapperComponent = withMutation(errorHandler(ReportDeath));

export default WrapperComponent;
