///@flow

import React from "react";
import Details, { DetailsFragment } from "./details";
import Address, { AddressFragment } from "./address";
import Iban from "./iban";
import Lapse from "./lapse";
import TransferMembership, {
  TransferFragment,
} from "./transferMembershipDialog";
import PackageUpdate, { UpdatePackageFragment } from "./updatePackage";

import { filter } from "graphql-anywhere";
import { gql } from "@apollo/client";
import { DialogContainer2 } from "components/dialog/container";

type State = {
  show: "DETAILS" | "ADDRESS" | "IBAN" | "LAPSE" | "TRANSFER" | "PACKAGE";
};

type Props = {
  data: {
    id: string;
    insuredPerson: {
      id: string;
      association: {
        name: string;
      };
    };
  };
  handleClose: () => void;
};

//This funtion resets the dialog to the center
//position in the screen. This is a bug in material-ui.
//See https://github.com/callemall/material-ui/issues/5793.
const resetDialogPositionInScreen = () => {
  setTimeout(() => {
    window.dispatchEvent(new Event("resize"));
  }, 0);
};

export const DialogComponentFragment = gql`
  fragment DialogComponents on Policy {
    id
    insuredPerson {
      id
    }
    ...TransferDialogDef
    ...DetailsPolicyFragment
    ...AddressDialog
    ...UpdatePackage
  }

  ${TransferFragment}
  ${DetailsFragment}
  ${AddressFragment}
  ${UpdatePackageFragment}
`;

class DialogComponents extends React.Component {
  state: State;
  props: Props;

  constructor(props: Props) {
    super(props);

    this.state = {
      show: "DETAILS",
    };
  }

  _changePolicyDialogState(state: "ADDRESS" | "IBAN" | "DETAILS" | "LAPSE") {
    // This fixes a bug where the dialog would shift to the incorrect position.
    // https://github.com/mui-org/material-ui/issues/5793
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 0);

    this.setState({
      show: state,
    });
  }

  render() {
    let content;
    let { data } = this.props;
    switch (this.state.show) {
      case "DETAILS":
        content = (
          <Details
            data={filter(DialogComponentFragment, data)}
            addressComponent={() => this._changePolicyDialogState("ADDRESS")}
            ibanComponent={() => this._changePolicyDialogState("IBAN")}
            lapseComponent={() => this._changePolicyDialogState("LAPSE")}
            onEditPackage={() =>
              this.setState({
                show: "PACKAGE",
              })
            }
            onTransferMembership={() =>
              this.setState({
                show: "TRANSFER",
              })
            }
            handleClose={() => this.props.handleClose()}
          />
        );
        break;
      case "ADDRESS":
        content = (
          <Address
            data={filter(AddressFragment, data)}
            handleClose={() => this._changePolicyDialogState("DETAILS")}
          />
        );
        break;
      case "IBAN":
        content = (
          <Iban
            handleClose={() => this._changePolicyDialogState("DETAILS")}
            insuredPersonId={data.insuredPerson.id}
            associationName={data.insuredPerson.association.name}
          />
        );
        break;
      case "LAPSE":
        content = (
          <Lapse
            // @ts-ignore
            data={data}
            handleClose={() => this._changePolicyDialogState("DETAILS")}
            handleCloseLapse={this.props.handleClose}
          />
        );
        break;
      case "TRANSFER":
        content = (
          <TransferMembership
            data={filter(TransferFragment, data)}
            handleClose={() => {
              this._changePolicyDialogState("DETAILS");
              resetDialogPositionInScreen();
            }}
          />
        );
        break;
      case "PACKAGE":
        content = (
          <PackageUpdate
            data={filter(UpdatePackageFragment, data)}
            handleClose={() => this._changePolicyDialogState("DETAILS")}
          />
        );
        break;
      default:
        throw Error("Unknown case in ledenportaal dialog:" + this.state.show);
    }

    return <DialogContainer2>{content}</DialogContainer2>;
  }
}

export default DialogComponents;
