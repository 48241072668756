//@flow

type ActionSetStepperIndex = {
  type: "SET_STEPPER_INDEX";
  stepperIndex: number;
};

type ActionIncreaseStepperIndex = {
  type: "INCREASE_STEPPER_INDEX";
};

export type Action = ActionSetStepperIndex | ActionIncreaseStepperIndex;

export const setStepperIndex = (index: number): Action => ({
  type: "SET_STEPPER_INDEX",
  stepperIndex: index,
});

export const increaseStepperIndex = (): Action => ({
  type: "INCREASE_STEPPER_INDEX",
});
