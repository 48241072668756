// @flow

import { validatePhone, isDate, validateIban } from "../../utils";

type Validator = (value?: any) => any | undefined;

const required: Validator = (value) =>
  value ? undefined : "Dit veld is verplicht";

const mustBePhone: Validator = (value) => {
  if (value && !validatePhone(value)) {
    return "De invoer moet een geldig telefoonnummer zijn";
  }
};

const mustBeDate: Validator = (value) => {
  if (value && !isDate(value)) {
    return "De opgegeven datum voldoet niet aan het format DD-MM-JJJJ";
  }
};

const mustBeIban: Validator = (value) => {
  if (value && !validateIban(value)) {
    return "De opgegeven IBAN is onjuist";
  }
};

const composeValidators =
  (...validators: Array<Validator>): Validator =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export { required, mustBePhone, composeValidators, mustBeDate, mustBeIban };
