// @flow
/**
 * Created by Joshua Scheidt on 25-01-2017.
 */

import {
  addSignupFirstNameAccount,
  addSignupLastNameAccount,
  addSignupLastNamePrefixAccount,
  addSignupPhone,
  addSignupEmail,
  addSignupPassword,
  addSignupPasswordConfirm,
  setAccountState,
} from "../../../actions";
import { connect, ConnectedProps } from "react-redux";
import type { Dispatch, State } from "../../../reducers";
import SignupScreen from "../../../screens/policySignup/signupStepperComponents/signupAccount";

import type { AccountState } from "../../../actions";

const mapStateToProps = (state: State) => {
  const {
    signup: {
      account: { data, errors, isFetching },
    },
  } = state;
  return {
    firstName: data.firstName,
    prefix: data.lastNamePrefix,
    lastName: data.lastName,
    phone: data.phone,
    email: data.email,
    password: data.password,
    passwordConfirm: data.passwordConfirm,
    errors,
    isFetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setFirstName: (firstName: string) =>
    dispatch(addSignupFirstNameAccount(firstName)),
  setLastName: (lastName: string) =>
    dispatch(addSignupLastNameAccount(lastName)),
  setLastNamePrefix: (lastNamePrefix: string) =>
    dispatch(addSignupLastNamePrefixAccount(lastNamePrefix)),
  setPhone: (phone: string) => dispatch(addSignupPhone(phone)),
  setEmail: (email: string) => dispatch(addSignupEmail(email)),
  setPassword: (password: string) => dispatch(addSignupPassword(password)),
  setPasswordConfirm: (password: string) =>
    dispatch(addSignupPasswordConfirm(password)),
  // @ts-ignore
  nextPressed: (input: AccountState) => dispatch(setAccountState(input)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type SignUpScreenReduxProps = ConnectedProps<typeof connector>;
const Wrapper = connector(SignupScreen);

export default Wrapper;
