// @flow

import React from "react";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { colors } from "../../../../../styling";

const Question = (props: { question: string }) => <p>{props.question}</p>;

const AnswerField = (props: {
  answer: boolean;
  onAnswer: (answer: boolean) => void;
}) => (
  <SelectField
    value={props.answer}
    onChange={(event, index, value) => {
      props.onAnswer(value);
    }}
    floatingLabelText="Antwoord"
    // @ts-ignore
    floatingLabelFocusStyle={{ color: colors.green }}
    underlineFocusStyle={{ borderColor: colors.green }}
    selectedMenuItemStyle={{ color: colors.green }}
    style={{ width: "100%" }}
  >
    <MenuItem value={true} primaryText={"Ja"} key={1} />
    <MenuItem value={false} primaryText={"Nee"} key={0} />
  </SelectField>
);

const Explanation = (props: {
  onChange: (explanation: string) => void;
  explanation: string;
  error?: string | null;
}) => (
  <TextField
    floatingLabelText="Toelichting"
    multiLine
    rowsMax={12}
    defaultValue={props.explanation}
    floatingLabelFocusStyle={{ color: colors.green }}
    underlineFocusStyle={{ borderColor: colors.green }}
    onChange={(event, explanation) => {
      props.onChange(explanation);
    }}
    errorText={props.error}
    fullWidth
  />
);

export { Question, AnswerField, Explanation };
