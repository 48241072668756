// @flow
/**
 * Created by Florentijn Hogerwerf, Jim Bemelen on 24/10/16.
 */

import React from "react";

import LineChart from "../charts/lineChart";
import { valueFormatter, parseDate } from "../../../utils";
import { gql } from "@apollo/client";
import EmptyContainer from "./emptyContainer";

type interestCurve = {
  date: string;
  rate_10: {
    rate: number;
  } | null;
  rate_30: {
    rate: number;
  } | null;
};

export type Props = {
  data: Array<interestCurve>;
};

function transformData(data: Array<interestCurve>) {
  let array = [];

  for (var i = 0; i < data.length; i++) {
    const { date, rate_10, rate_30 } = data[i];
    if (!rate_10 || !rate_30) {
      return null;
    }

    array.push({
      name: parseDate(date),
      Y10: rate_10.rate,
      Y30: rate_30.rate,
    });
  }

  return array;
}

const InterestRates = (props: Props) => {
  const data = transformData(props.data);
  if (data == null) return <EmptyContainer />;
  else
    return (
      <LineChart
        valueFormatter={(value: number) =>
          valueFormatter(
            // @ts-ignore
            Number(parseFloat(value * 100).toFixed(1)),
            "PERCENTAGE"
          )
        }
        // @ts-ignore
        data={data}
        xAxisInterval={0}
        yAxis={{ min: 0.0, max: 0.05 }}
      />
    );
};
export const InterestCurveFragment = gql`
  fragment interestCurves on InterestCurve {
    id
    date
    rate_10: rate(maturity: 10) {
      rate
      id
    }
    rate_30: rate(maturity: 30) {
      rate
      id
    }
  }
`;

export default InterestRates;
