// @flow

import React from "react";

import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import { colorsBCV, colors } from "../../../../styling";
import { errorHandler, ErrorHandler } from "../../../../containers";
import { UPDATE_ACCOUNT_MUTATION } from "./mutation";
import {
  UpdateAccountMemberMutation,
  UpdateAccountMemberMutationVariables,
} from "generated/graphql";
import { graphql } from "@apollo/client/react/hoc";
import { NavButtonContainer } from "components/dialog";
import { DialogContainer2 } from "components/dialog/container";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  infoTitle: {
    fontSize: "18px",
    color: colorsBCV.secondary,
  },
};

type PropsParent = {
  accountId: string;
  handleClose: () => void;
};

type PropsGraphQL = {
  updateAccount: (firstName: string) => Promise<any>;
};

type Props = PropsGraphQL & ErrorHandler & PropsParent;

type State = { newFirstName: string; firstNameError: string | null };

class UpdateFirstName extends React.Component {
  state: State;
  props: Props;

  constructor(props: Props) {
    super(props);
    this.state = {
      newFirstName: "",
      firstNameError: null,
    };
  }

  async _checkInput() {
    const { error, handleClose, updateAccount } = this.props;
    const { newFirstName } = this.state;
    let firstNameError = null;

    if (newFirstName.length === 0) {
      firstNameError = "Er is geen naam ingevuld.";
    }

    this.setState({
      firstNameError,
    });

    if (firstNameError == null) {
      try {
        await updateAccount(newFirstName);
        this.setState({
          firstNameError: "",
        });
        handleClose();
      } catch (e) {
        error.display();
      }
    }
  }

  render() {
    const { handleClose } = this.props;
    const { newFirstName } = this.state;

    return (
      <DialogContainer2>
        <span style={styles.infoTitle}>Wat is uw nieuwe voornaam?</span>
        <TextField
          // @ts-ignore
          id="firstName"
          defaultValue={newFirstName}
          errorText={this.state.firstNameError}
          name="firstName"
          fullWidth={true}
          type="text"
          onChange={(event: any) => {
            this.setState({ newFirstName: event.target.value });
          }}
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this._checkInput();
            }
          }}
        />
        <NavButtonContainer>
          <RaisedButton
            // @ts-ignore
            id="back"
            label="Annuleren"
            primary={false}
            onClick={() => {
              this.setState({ newFirstName: "", firstNameError: null });
              handleClose();
            }}
          />
          <RaisedButton
            // @ts-ignore
            id="confirm"
            label="Bevestig"
            backgroundColor={colorsBCV.secondary}
            labelColor={colors.white}
            onClick={() => {
              this._checkInput();
            }}
          />
        </NavButtonContainer>
      </DialogContainer2>
    );
  }
}

const withMutation = graphql<
  PropsParent,
  UpdateAccountMemberMutation,
  UpdateAccountMemberMutationVariables,
  PropsGraphQL
>(UPDATE_ACCOUNT_MUTATION, {
  props: ({ mutate, ownProps }) => {
    const { accountId } = ownProps;
    if (!accountId) {
      throw new Error(
        `Expected accountId to be passed in the props but got ${accountId}`
      );
    }
    return {
      updateAccount: (firstName) =>
        mutate({
          variables: { input: { firstName } },
          optimisticResponse: {
            // @ts-ignore
            profile: {
              id: accountId,
              firstName,
            },
          },
        }),
    };
  },
});

const Wrapped = withMutation(errorHandler(UpdateFirstName));
export default Wrapped;
