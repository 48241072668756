// @flow
/**
 * Created by Florentijn Hogerwerf, Jim Bemelen on 24/10/16.
 */

import React from "react";

import LineChart from "../charts/lineChart";
import { valueFormatter, parseDate } from "../../../utils";
import { gql } from "@apollo/client";

type fundingRatioCurve = {
  date: string;
  value: number;
};

type Props = {
  data: Array<fundingRatioCurve>;
};

function transformData(data: Array<fundingRatioCurve>) {
  let array = [];
  for (var i = 0; i < data.length; i++) {
    array[i] = {
      name: parseDate(data[i].date),
      value: data[i].value,
    };
  }
  return array;
}

const FundingRatio = (props: Props) => {
  return (
    <LineChart
      valueFormatter={(value: number) =>
        valueFormatter(
          // @ts-ignore
          Number(parseFloat(value * 100).toFixed(1)),
          "PERCENTAGE"
        )
      }
      // @ts-ignore
      data={transformData(props.data)}
      xAxisInterval={0}
    />
  );
};
export const FundingRatioFragment = gql`
  fragment fundingRatio on FundingRatio {
    id
    date
    value
  }
`;

export default FundingRatio;
