// @flow

import React from "react";
import { SideBar } from "../../components/header";
import { EditorState, convertToRaw, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Centered, VerticalSeparator } from "../../components/layout";
import sendMail from "./api";
import { EmailComposer, Loader, ConfirmSent, Header } from "./ui";
import { errorHandler, ErrorHandler } from "../../containers";

import "./styles.css";
import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";

type State = {
  editorState: any;
  files: Array<File>;
  loading: boolean;
  subject: string;
  showSuccess: boolean;
};
type Props = WithApolloClient<{}> & ErrorHandler;

const initialState: State = {
  editorState: EditorState.createEmpty(),
  files: [],
  loading: false,
  subject: "",
  showSuccess: false,
};

class Communication extends React.Component<Props, State> {
  state: {
    editorState: any;
    files: Array<File>;
    loading: boolean;
    subject: string;
    showSuccess: boolean;
  } = initialState;

  sendMail = async () => {
    const {
      client,
      error: { display },
    } = this.props;
    const { files, subject } = this.state;
    const body = this.getHtml();

    this.setState({ loading: true });

    try {
      await sendMail(client, {
        files,
        subject,
        body,
      });
      this.resetStateAfterSuccess();
    } catch (error) {
      display();
      this.setState({ loading: false });
    }
  };

  resetStateAfterSuccess = () =>
    this.setState({ ...initialState, showSuccess: true });

  getHtml = () =>
    draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));

  insert = (text: string) => {
    const { editorState } = this.state;

    let contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    contentState = Modifier.insertText(contentState, selectionState, text);

    let newState = EditorState.push(
      editorState,
      contentState,
      "insert-characters"
    );

    this.setState({ editorState: newState });
  };

  render() {
    const { editorState, files, subject, loading, showSuccess } = this.state;

    let content;

    if (loading) {
      content = <Loader />;
    } else {
      content = (
        <EmailComposer
          subject={subject}
          onSetSubject={(subj) => this.setState({ subject: subj })}
          editorState={editorState}
          onEditorStateChange={(state) =>
            this.setState({
              editorState: state,
            })
          }
          files={files}
          onAddFile={(file) => {
            this.setState({ files: [...files, file] });
          }}
          onDeleteFile={(index) => {
            files.splice(index, 1);
            this.setState({ files });
          }}
          sendMail={() => {
            this.sendMail();
          }}
          onInsert={this.insert}
        />
      );
    }

    return (
      <div>
        <Header />
        <SideBar />
        <ConfirmSent
          open={showSuccess}
          onClose={() => {
            this.setState({ showSuccess: false });
          }}
        />
        <div style={{ paddingTop: "48px", marginBottom: "24px" }}>
          <VerticalSeparator distance={6} />
          <Centered>{content}</Centered>
        </div>
      </div>
    );
  }
}

export default withApollo(errorHandler(Communication));
