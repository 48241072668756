import { DependencyList } from "react";
import { EffectCallback } from "react";
import { useEffect } from "react";

export const useEffectWithDelay = (
  effect: EffectCallback,
  delayMs: number,
  deps?: DependencyList
) =>
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      effect();
    }, delayMs);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line
  }, [delayMs, ...deps]);
