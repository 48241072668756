// @flow

import React from "react";

const TitleContainer = (props: any) => (
  <div
    style={{
      paddingTop: "30px",
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    {props.children}
  </div>
);

export default TitleContainer;
