// @flow

import React from "react";
import DropDownMenu from "material-ui/DropDownMenu";
import MenuItem from "material-ui/MenuItem";

import { countryCodes } from "../../utils";
import Textfield from "../textfield";
import { getForeignPostalCodeError } from "./validatePostalCode";
import getHouseNumberError from "./validateHouseNumber";
import getStreetError from "./validateStreet";
import getCityError from "./validateCity";

const correctInput = (state: State) => {
  const {
    postalCode,
    houseNumber,
    street,
    city,
    country,
    postalCodeError,
    houseNumberError,
    streetError,
    cityError,
  } = state;

  if (
    postalCodeError ||
    houseNumberError ||
    streetError ||
    cityError ||
    postalCode === "" ||
    houseNumber === "" ||
    street === "" ||
    city === "" ||
    country == null
  ) {
    return false;
  } else {
    return true;
  }
};

const collectInput = (state: State) => {
  const { postalCode, houseNumber, suffix, street, city, country } = state;

  if (!country) {
    throw new Error("Country should be set");
  }
  return {
    postalCode,
    houseNumber: Number(houseNumber),
    suffix: suffix === "" ? null : suffix,
    street,
    city,
    country,
  };
};

const inputCompleteHandler = (state: State, props: Props) => {
  if (correctInput(state)) {
    props.onCompleted(collectInput(state));
  } else {
    props.onIncomplete();
  }
};

type State = {
  postalCode: string;
  houseNumber: string;
  suffix: string;
  street: string;
  city: string;
  country: string | null;
  postalCodeError: string | null;
  houseNumberError: string | null;
  streetError: string | null;
  cityError: string | null;
};

type Props = {
  color: string;
  onCompleted: (input: {
    postalCode: string;
    houseNumber: number;
    suffix: string | null;
    street: string;
    city: string;
    country: string;
  }) => void;
  onIncomplete: () => void;
};

export class ForeignAddressInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      postalCode: "",
      houseNumber: "",
      suffix: "",
      street: "",
      city: "",
      country: null,
      postalCodeError: null,
      houseNumberError: null,
      streetError: null,
      cityError: null,
    };
  }

  render() {
    const {
      postalCode,
      houseNumber,
      suffix,
      street,
      city,
      country,
      postalCodeError,
      houseNumberError,
      streetError,
      cityError,
    } = this.state;

    const { color } = this.props;

    const textFieldStyle = {
      fullWidth: true,
      color,
    };

    return (
      <div>
        <Textfield
          // @ts-ignore
          id="postalCode"
          value={postalCode}
          floatingLabelText="Postcode"
          onChange={this.updatePostalCode}
          onBlur={this.blurPostalCode}
          errorText={postalCodeError}
          {...textFieldStyle}
        />
        <Textfield
          // @ts-ignore
          id="houseNumber"
          value={houseNumber}
          floatingLabelText="Huisnummer"
          onChange={this.updateHouseNumber}
          onBlur={this.blurHouseNumber}
          errorText={houseNumberError}
          {...textFieldStyle}
        />
        <Textfield
          // @ts-ignore
          id="suffix"
          value={suffix}
          floatingLabelText="Mogelijke Toevoeging"
          onChange={this.updateSuffix}
          onBlur={this.blurSuffix}
          {...textFieldStyle}
        />
        <Textfield
          // @ts-ignore
          id="street"
          value={street}
          floatingLabelText="Straat"
          onChange={this.updateStreet}
          onBlur={this.blurStreet}
          errorText={streetError}
          {...textFieldStyle}
        />
        <Textfield
          // @ts-ignore
          id="city"
          value={city}
          floatingLabelText="Woonplaats"
          onChange={this.updateCity}
          onBlur={this.blurCity}
          errorText={cityError}
          {...textFieldStyle}
        />
        <p>Land</p>
        <DropDownMenu
          // @ts-ignore
          id="country"
          value={country}
          autoWidth={false}
          style={{
            width: "400px",
          }}
          maxHeight={300}
          onChange={this.updateCountry}
        >
          {countryCodes.map(({ code, country }, index) => (
            <MenuItem value={code} primaryText={country} key={index} />
          ))}
        </DropDownMenu>
      </div>
    );
  }

  updatePostalCode = (event: any) =>
    this.setState({
      postalCode: event.target.value.toUpperCase(),
      postalCodeError: null,
    });

  updateHouseNumber = (event: any) =>
    this.setState({
      houseNumber: event.target.value,
      houseNumberError: null,
    });

  updateStreet = (event: any) =>
    this.setState({
      street: event.target.value,
      streetError: null,
    });

  updateSuffix = (event: any) =>
    this.setState({
      suffix: event.target.value,
    });

  updateCity = (event: any) =>
    this.setState({
      city: event.target.value,
      cityError: null,
    });

  updateCountry = (event: any, index: any, value: string) => {
    this.setState(
      {
        country: value,
      },
      () => {
        inputCompleteHandler(this.state, this.props);
      }
    );
  };

  blurPostalCode = () => {
    const { postalCode } = this.state;
    const postalCodeError = getForeignPostalCodeError(postalCode);
    this.setState({ postalCodeError }, () => {
      inputCompleteHandler(this.state, this.props);
    });
  };

  blurHouseNumber = () => {
    const { houseNumber } = this.state;
    const houseNumberError = getHouseNumberError(houseNumber);
    this.setState({ houseNumberError }, () => {
      inputCompleteHandler(this.state, this.props);
    });
  };

  blurSuffix = () => {
    inputCompleteHandler(this.state, this.props);
  };

  blurStreet = () => {
    const { street } = this.state;
    const streetError = getStreetError(street);
    this.setState({ streetError }, () => {
      inputCompleteHandler(this.state, this.props);
    });
  };

  blurCity = () => {
    const { city } = this.state;
    const cityError = getCityError(city);
    this.setState({ cityError }, () => {
      inputCompleteHandler(this.state, this.props);
    });
  };
}
