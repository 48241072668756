// @flow

import React from "react";
import { colorsBCV, colors } from "../../../../styling";
import RaisedButton from "material-ui/RaisedButton";
import { errorHandler } from "../../../../containers";
import type { ErrorHandler } from "../../../../containers";
import { CANCEL_POLICY_MUTATION } from "../../../policyDetailsPage/components/person/stateBox/active/cancelDialog";
import { graphql } from "@apollo/client/react/hoc";
import {
  CancelPolicyMutation,
  CancelPolicyMutationVariables,
} from "generated/graphql";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
};

type LapseInfoType = {
  id: string;
  package: {
    name: string;
  };
};

type GraphQLProps = {
  cancelPolicy: (input: CancelPolicyMutationVariables["input"]) => Promise<any>;
};

type PropsParents = {
  handleClose(): void;
  handleCloseLapse(): void;
  data: LapseInfoType;
};

type Props = PropsParents & ErrorHandler & GraphQLProps;

class LapseDialog extends React.Component<Props> {
  render() {
    let { handleClose, handleCloseLapse, data } = this.props;

    return (
      <div style={styles.container}>
        <h2>Lidmaatschap opzeggen</h2>
        <p>
          Weet u zeker dat u het lidmaatschap wilt opzeggen?
          <br />
          <br />
          Door dit te bevestigen heeft het lid geen recht meer op de
          vergoedingen voor de uitvaart die bij {data.package.name} horen.
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <RaisedButton
            label="Annuleer"
            primary={false}
            onClick={() => {
              handleClose();
            }}
          />
          <RaisedButton
            label="Bevestig"
            backgroundColor={colorsBCV.secondary}
            labelColor={colors.white}
            onClick={() => {
              const input = {
                policyId: this.props.data.id,
              };
              this.props
                .cancelPolicy(input)
                .then(() => {
                  handleCloseLapse();
                })
                .catch((error) => {
                  this.props.error.display();
                });
            }}
          />
        </div>
      </div>
    );
  }
}

const CancelPolicyMutationComp = graphql<
  PropsParents,
  CancelPolicyMutation,
  CancelPolicyMutationVariables,
  GraphQLProps
>(CANCEL_POLICY_MUTATION, {
  props: ({ mutate }) => ({
    cancelPolicy: (input) => mutate({ variables: { input } }),
  }),
})(errorHandler(LapseDialog));

export default CancelPolicyMutationComp;
