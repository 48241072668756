// @flow

import React, { Component } from "react";

import { AddressTypePicker } from "./addressInputTypePicker";
import { DomesticAddressInput } from "./domesticAddressInput";
import { ForeignAddressInput } from "./foreignAddressInput";
import { colors } from "styling";

export enum AddressInputType {
  DOMESTIC = "DOMESTIC",
  FOREIGN = "FOREIGN",
}

type Address = {
  city: string;
  country: string;
  houseNumber: number;
  postalCode: string;
  street: string;
  suffix: string | null;
};

type Props = {
  onCompleted: (input: Address) => void;
  onIncomplete: () => void;
};

type State = {
  inputType: AddressInputType;
};

export class AddressInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      inputType: AddressInputType.DOMESTIC,
    };
  }
  render() {
    const { inputType } = this.state;
    const { onCompleted, onIncomplete } = this.props;

    let addressInput;
    if (inputType === AddressInputType.DOMESTIC) {
      addressInput = (
        <DomesticAddressInput
          // @ts-ignore
          id="domestic"
          color={colors.green}
          onCompleted={onCompleted}
          onIncomplete={onIncomplete}
        />
      );
    } else {
      addressInput = (
        <ForeignAddressInput
          // @ts-ignore
          id="foreign"
          color={colors.green}
          onIncomplete={onIncomplete}
          onCompleted={onCompleted}
        />
      );
    }

    return (
      <div>
        <AddressTypePicker
          // @ts-ignore
          id="picker"
          selection={inputType}
          onSelectForeign={this.onSelectForeign}
          onSelectDomestic={this.onSelectDomestic}
        />
        {addressInput}
      </div>
    );
  }

  onSelectDomestic = () => {
    const { inputType } = this.state;
    if (inputType !== "DOMESTIC") {
      this.setState({
        inputType: AddressInputType.DOMESTIC,
      });
      this.props.onIncomplete();
    }
  };

  onSelectForeign = () => {
    const { inputType } = this.state;
    if (inputType !== AddressInputType.FOREIGN) {
      this.setState({
        inputType: AddressInputType.FOREIGN,
      });
      this.props.onIncomplete();
    }
  };
}
