// @flow

import type { AlertAction } from "../actions";

export type State = {
  message: string | null;
  title: string | null;
  show: boolean;
};

const defaultState: State = {
  message: null,
  title: null,
  show: false,
};

const alertReducer = (
  state: State = defaultState,
  action: AlertAction
): State => {
  switch (action.type) {
    case "SHOW_ALERT":
      return {
        message: action.message,
        title: action.title,
        show: true,
      };
    case "HIDE_ALERT":
      return {
        message: null,
        title: null,
        show: false,
      };
    default:
      return state;
  }
};

export default alertReducer;
