// @flow

import React from "react";
import { Tabs, Tab } from "material-ui/Tabs";
import AppBar from "material-ui/AppBar";
import { Title, MenuButton } from "../../components/header";
import { blueHiThere, colors } from "../../styling";

const styles: { [key: string]: React.CSSProperties } = {
  tabs: {
    width: "100%",
    backgroundColor: blueHiThere,
  },
  tab: {
    backgroundColor: blueHiThere,
  },
  appBar: {
    backgroundColor: blueHiThere,
    flexWrap: "wrap",
  },
};

type PropsHeader = {
  onChange: (value: "PENDING" | "ACCEPTED") => void;
  selectedValue: "PENDING" | "ACCEPTED";
  title: string;
};

const Header = (props: PropsHeader) => {
  return (
    // @ts-ignore
    <AppBar
      title={<Title title={props.title} />}
      style={styles.appBar}
      iconElementLeft={<MenuButton />}
    >
      <Tabs
        style={styles.tabs}
        tabItemContainerStyle={styles.tab}
        inkBarStyle={{ background: colors.amber, height: "4px" }}
        onChange={props.onChange}
        value={props.selectedValue}
      >
        <Tab label="In afwachting" value="PENDING" />
        <Tab label="Geaccepteerd" value="ACCEPTED" />
      </Tabs>
    </AppBar>
  );
};

export default Header;
