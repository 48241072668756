// @flow

import React from "react";
import { PropsWithChildren } from "react";

const style: { [key: string]: React.CSSProperties } = {
  textContainer: {
    paddingTop: "20px",
    textAlign: "justify",
  },
};

type PropType = PropsWithChildren;

const HomePageContent = ({ children }: PropType) => (
  <div style={style.textContainer}>
    <div style={{ height: "100%", width: "100%" }}>{children}</div>
  </div>
);

export default HomePageContent;
