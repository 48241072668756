// @flow

import React from "react";
import SectionTitle from "../components/sectionTitle";
import { Row } from "../components/row";
import TitleContainer from "../components/titleContainer";
import { formatToEuro } from "../../../../utils";
import { parseDate } from "../../../../utils";
import UpdateEndDateButton from "./updateEndDate";
import UpdateAdditionalInsuranceButtonContainer from "./update";
import { SinglePolicyQuery } from "generated/graphql";
import { formatFrequency2 } from "utils/formatter";

type GraphQLAdditionalInsurance =
  SinglePolicyQuery["viewer"]["policy"]["additionalInsurance"];

type Props = {
  data: GraphQLAdditionalInsurance | null;
  policyId: string;
  disabled: boolean;
  maxInsuredAmount: number;
  minInsuredAmount: number;
};

export class AdditionalInsuranceInfo extends React.Component {
  props: Props;

  render() {
    const { data } = this.props;

    const title = (
      <TitleContainer>
        <SectionTitle title="Additionele verzekering" />
        <UpdateAdditionalInsuranceButtonContainer
          disabled={this.props.disabled}
          policyId={this.props.policyId}
          initialFrequency={data && data.frequency}
          initialInsuredAmount={data && data.insuredAmount}
          minInsuredAmount={this.props.minInsuredAmount}
          maxInsuredAmount={this.props.maxInsuredAmount}
          startDate={data && data.startDate}
          endDate={data && data.endDate}
          premium={data && data.premium}
        />
      </TitleContainer>
    );

    let content = (
      <p style={{ fontSize: "18px" }}>
        Dit lid heeft nog geen additionele verzekering
      </p>
    );

    if (data != null) {
      const setEndDateButton = (
        <UpdateEndDateButton
          policyId={this.props.policyId}
          startDate={data.startDate}
          disabled={this.props.disabled}
        />
      );
      content = (
        <div>
          <Row
            name="Verzekerd bedrag"
            value={formatToEuro(data.insuredAmount)}
          />
          <Row
            name="Premie"
            value={data.premium ? formatToEuro(data.premium) : "Geen"}
          />
          <Row
            name="Betalingsfrequentie"
            value={
              data.frequency ? formatFrequency2(data.frequency) : "Onbekend"
            }
          />
          <Row
            name="Ingangsdatum"
            value={data.startDate ? parseDate(data.startDate) : "Onbekend"}
          />
          <Row
            name="Einddatum"
            value={data.endDate ? parseDate(data.endDate) : "Niet gezet"}
            button={data.endDate == null ? setEndDateButton : null}
          />
        </div>
      );
    }

    return (
      <div>
        {title}
        {content}
      </div>
    );
  }
}
