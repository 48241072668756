// @flow

import React from "react";

type Props = {
  children?: any;
  style?: Object | null;
};

const sectionTitle = (props: Props) => (
  <h2 style={props.style}>{props.children}</h2>
);

export default sectionTitle;
