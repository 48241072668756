// @flow

import React from "react";

type Props = {
  style?: React.CSSProperties;
  children?: any;
};

export const Title = (props: Props) => (
  <h1 style={props.style}>{props.children}</h1>
);

export const Subtitle = (props: Props) => (
  <h2 style={props.style}>{props.children}</h2>
);
