import MenuItem from "material-ui/MenuItem";
import { FormControl, InputLabel, Select } from "@mui/material";

export enum PolicyStateFilter {
  ALL = "ALL",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

type Props = {
  selectedFilter: PolicyStateFilter;
  onChangeFilter: (selectedFilter: PolicyStateFilter) => void;
};

const PolicyStateFilterComponent = (props: Props) => {
  return (
    <FormControl variant="standard" sx={{ width: "256px" }}>
      <InputLabel>Leden filter</InputLabel>
      <Select
        value={props.selectedFilter}
        onChange={(event) =>
          props.onChangeFilter(event.target.value as PolicyStateFilter)
        }
      >
        <MenuItem value={PolicyStateFilter.ALL}>Alle</MenuItem>
        <MenuItem value={PolicyStateFilter.ACTIVE}>Actief</MenuItem>
        <MenuItem value={PolicyStateFilter.INACTIVE}>Inactief</MenuItem>
      </Select>
    </FormControl>
  );
};

export default PolicyStateFilterComponent;
