import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { withRouter, WithRouterProps } from "react-router";
import { insurerPaths } from "screens/routes";
import { BackButton } from "../../components/header";
import { HelpButton } from "./components/HelpButton";
import { gql } from "generated";
import { useMutationForApp } from "utils/withApollo";
import { downloadLinks } from "utils/downloadLink";

type Props = WithRouterProps & {
  insuredPersonIds: string[];
  hasMembershipCardTemplate: boolean;
};

export const Header = withRouter((props: Props) => {
  const { location, insuredPersonIds } = props;

  const [generateMembershipCards, { loading }] = useMutationForApp(mutation, {
    onCompleted: ({ generateMembershipCard }) => {
      if (generateMembershipCard.urls) {
        downloadLinks(generateMembershipCard.urls);
      }
    },
  });

  const previousPath =
    location.query && location.query.prevPath
      ? location.query.prevPath
      : insurerPaths.administrationPage;

  const goBack = () => props.router.push(previousPath);

  return (
    <AppBar position="static">
      <Toolbar>
        <BackButton onClick={() => goBack()} />
        <Typography variant="h6" component="div">
          Lid groepering
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {insuredPersonIds && props.hasMembershipCardTemplate && (
          <Button
            disabled={loading}
            color="inherit"
            variant="text"
            onClick={() =>
              generateMembershipCards({
                variables: {
                  input: {
                    insuredPersonIds,
                  },
                },
              })
            }
          >
            Genereer kaart
          </Button>
        )}
        <HelpButton />
      </Toolbar>
    </AppBar>
  );
});

const mutation = gql(`
mutation generateMembershipCard($input: GenerateMembershipCardInput!) {
  generateMembershipCard(input: $input) {
    urls
  }
}`);
