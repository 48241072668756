// @flow

import React, { Component } from "react";
import { NavButtonContainer } from "../../../../../components/dialog";
import TermsAndConditions from "../../../../../components/termsAndConditions";
import RaisedButton from "material-ui/RaisedButton";
import Eligibility from "../../../../../components/eligibility/eligibility";
import ContributionContainer from "./updatePackageContribution";
import { errorHandler, ErrorHandler } from "../../../../../containers";
import { Subtitle } from "../../../../../components/dialog";
import { PackagePickerContainer } from "./updatePackagePicker";
import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import {
  RequestPackageUpdateMutation,
  RequestPackageUpdateMutationVariables,
} from "generated/graphql";

type Package = {
  id: string;
  name: string;
  insuredAmount: number;
};

type Quote = {
  id: string;
  premium: number;
};

type Association = {
  statutesUrl: string;
  eligibilityQuestions: Array<{
    id: string;
    question: string;
  }>;
};

type PropsParent = {
  handleClose: () => void;
  handleConfirm: () => void;
  data: {
    id: string;
    upgradeablePackages: Array<Package | null> | null;
    insuredPerson: {
      id: string;
      association: Association;
    };
  };
};

type PropsGraphQL = {
  requestPackageUpdate: (
    input: RequestPackageUpdateMutationVariables["input"]
  ) => Promise<any>;
};

type Props = PropsParent & PropsGraphQL & ErrorHandler;

type State = {
  loading: boolean;
  disabled: boolean;
  termsAndConditionsChecked: boolean;
  selectedPackage: Package | null;
  quote: Quote | null;
};

const hasEligibilityQuestions = (association: Association) =>
  association.eligibilityQuestions.length > 0;

export class UpdatePackage extends Component<Props, State> {
  _eligibility: Eligibility;

  constructor(props: Props) {
    super(props);
    this.state = {
      disabled: true,
      loading: false,
      termsAndConditionsChecked: false,
      selectedPackage: null,
      quote: null,
    };
  }

  render() {
    const { handleClose, data, error } = this.props;
    let { upgradeablePackages } = data;

    const { disabled, loading, selectedPackage } = this.state;

    if (upgradeablePackages == null) {
      error.display();
      handleClose();
      return null;
    }

    upgradeablePackages = upgradeablePackages.filter(Boolean);

    const { association } = data.insuredPerson;

    const contributionContainer = selectedPackage ? (
      <ContributionContainer
        // @ts-ignore
        id="contributionContainer"
        packageId={selectedPackage.id}
        policyId={data.id}
        onCalculatedContribution={(quote: Quote) => {
          this.setState({ quote }, this._validate);
        }}
      />
    ) : null;
    return (
      <div>
        <PackagePickerContainer
          // @ts-ignore
          id="packagePickerContainer"
          onSelectPackage={(selectedPackage) => {
            this.setState({ selectedPackage });
          }}
          packages={upgradeablePackages}
          selectedPackage={selectedPackage}
        />
        {contributionContainer}
        {hasEligibilityQuestions(association) && (
          <Subtitle>Gezondheidsverklaring</Subtitle>
        )}
        <Eligibility
          questions={association.eligibilityQuestions}
          ref={(ref) => (this._eligibility = ref)}
          onEnter={() => {}}
        />
        <TermsAndConditionsContainer
          // @ts-ignore
          id="termsAndConditions"
          statutesUrl={association.statutesUrl}
          onCheck={(checked: boolean) => {
            this.setState(
              { termsAndConditionsChecked: checked },
              this._validate
            );
          }}
        />
        <Separator />
        <BottomButtonContainer
          // @ts-ignore
          id="bottomButtonContainer"
          onClose={handleClose}
          onConfirm={() => {
            this._changePackage();
          }}
          disabled={disabled || loading}
        />
      </div>
    );
  }

  _validate() {
    const { quote, termsAndConditionsChecked } = this.state;
    if (quote && termsAndConditionsChecked) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
  }

  async _changePackage() {
    const isEligibilityValid = this._eligibility.validate();
    if (isEligibilityValid) {
      this.setState({ loading: true });
      const eligibility = this._eligibility.getInput();
      const { quote } = this.state;
      const { requestPackageUpdate, handleConfirm, error } = this.props;

      if (!quote) throw new Error("No quote");
      try {
        await requestPackageUpdate({
          quoteId: quote.id,
          eligibility,
        });
        this.setState({ loading: false });
        handleConfirm();
      } catch (err) {
        this.setState({ loading: false });
        error.display();
      }
    }
  }
}

export const TermsAndConditionsContainer = ({
  onCheck,
  statutesUrl,
}: {
  onCheck: (checked: boolean) => void;
  statutesUrl: string;
}) => {
  return (
    <div>
      <Subtitle>Slotverklaring</Subtitle>
      <TermsAndConditions
        // @ts-ignore
        id="terms"
        onCheck={onCheck}
        statutesUrl={statutesUrl}
      />
    </div>
  );
};

export const BottomButtonContainer = (props: {
  onClose: () => void;
  onConfirm: () => void;
  disabled: boolean;
}) => {
  return (
    <NavButtonContainer>
      <RaisedButton
        // @ts-ignore
        id="back"
        label="Annuleren"
        primary={false}
        onClick={props.onClose}
      />
      <RaisedButton
        // @ts-ignore
        id="confirm"
        label="Bevestig"
        primary={true}
        onClick={props.onConfirm}
        disabled={props.disabled}
      />
    </NavButtonContainer>
  );
};

const Separator = () => <div style={{ paddingTop: "24px" }} />;

export const PackageFragment = gql`
  fragment PackageDialog on Policy {
    id
    upgradeablePackages {
      id
      name
      insuredAmount
    }
    insuredPerson {
      id
      association {
        id
        statutesUrl
        eligibilityQuestions {
          id
          question
        }
      }
    }
  }
`;

export const UPDATE_PACKAGE_POLICY = gql`
  mutation requestPackageUpdate($input: RequestPackageUpdateInput!) {
    requestPackageUpdate(input: $input) {
      clientMutationId
    }
  }
`;

export const withMutation = graphql<
  PropsParent,
  RequestPackageUpdateMutation,
  RequestPackageUpdateMutationVariables,
  PropsGraphQL
>(UPDATE_PACKAGE_POLICY, {
  props: ({ mutate }) => ({
    requestPackageUpdate: (input) => mutate({ variables: { input } }),
  }),
});

const Wrapper = withMutation(errorHandler(UpdatePackage));
export default Wrapper;
