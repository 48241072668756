// @flow

import React from "react";
import RaisedButton from "material-ui/RaisedButton";
import CircularProgress from "material-ui/CircularProgress";
import { filter } from "graphql-anywhere";
import { withRouter, WithRouterProps } from "react-router";
import { SideBar } from "../../components/header";
import RegistrationList from "./registrationList";
import { colors } from "../../styling";
import Header from "./header";
import EmptyScreen from "./emptyScreen";
import { gql } from "@apollo/client";
import { graphqlQuery } from "utils/withApollo";
import { AcceptanceQuery, AcceptanceQueryVariables } from "generated/graphql";
import { DataProps } from "@apollo/client/react/hoc";

type Props = WithRouterProps &
  DataProps<AcceptanceQuery, AcceptanceQueryVariables>;

const styles: { [key: string]: React.CSSProperties } = {
  screen: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
};

type State = {
  selectedState: "PENDING" | "ACCEPTED";
};

class AanmeldingenPortalScreen extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // The current state is encoded in the location query.
    if (props.location && props.location.query && props.location.query.state) {
      this.state = {
        selectedState: props.location.query.state,
      };
    } else {
      this.state = {
        selectedState: "PENDING",
      };
    }
  }

  componentDidMount() {
    this.props.router.push({
      // eslint-disable-next-line
      pathname: location.pathname,
      query: {
        state: this.state.selectedState,
      },
    });
  }

  render() {
    const { data, location, router } = this.props;
    const { loading, error, refetch, viewer } = data;
    const { selectedState } = this.state;

    let content;
    if (error) {
      content = (
        <div style={{ ...styles.center, flexDirection: "column" }}>
          <div style={{ textAlign: "center" }}>
            <h3>Oeps, er is iets fout gegaan.</h3>
            <h3>
              Ververs de pagina en probeer het opnieuw. Als deze fout zich
              blijft voordoen, neem dan contact met ons op.
            </h3>
          </div>
          <RaisedButton
            label="PROBEER OPNIEUW"
            labelStyle={{ color: "white" }}
            backgroundColor={colors.green}
            onClick={() => refetch()}
          />
        </div>
      );
    } else if (loading) {
      content = (
        <div style={styles.center}>
          <CircularProgress color={colors.green} />
        </div>
      );
    } else if (viewer) {
      const policyList =
        selectedState === "ACCEPTED"
          ? viewer.policiesAccepted
          : viewer.policiesPending;

      // @ts-ignore
      if (policyList.totalCount === 0) {
        content = <EmptyScreen state={selectedState} />;
      } else {
        content = (
          <div style={{ paddingTop: "64px" }}>
            <RegistrationList
              data={filter(RegistrationList.fragments.aanmeldList, policyList)}
              state={selectedState}
              onDetails={(policyId) => {
                router.push({
                  pathname: `/bestuurder/leden/${policyId}`,
                  query: {
                    prevPath: location.pathname + String(location.search),
                  },
                });
              }}
            />
          </div>
        );
      }
    }

    return (
      <div style={styles.screen}>
        <div>
          <Header
            onChange={(selectedState) => {
              router.push({
                pathname: location.pathname,
                query: {
                  state: selectedState,
                },
              });
              this.setState({ selectedState });
            }}
            selectedValue={selectedState}
            title={
              selectedState === "ACCEPTED"
                ? "Geaccepteerde Aanvragen"
                : "Aanvragen In Afwachting"
            }
          />
        </div>
        <SideBar />
        {content}
      </div>
    );
  }
}

export const ACCEPTANCE_QUERY = gql`
  query Acceptance {
    viewer {
      id
      policiesAccepted: policies(state: ACCEPTED, first: 50) {
        ...AanmeldingenList
        totalCount
      }
      policiesPending: policies(state: PENDING, first: 50) {
        ...AanmeldingenList
        totalCount
      }
    }
  }
  ${RegistrationList.fragments.aanmeldList}
`;

export default graphqlQuery<{}, AcceptanceQuery, AcceptanceQueryVariables>(
  ACCEPTANCE_QUERY
)(withRouter(AanmeldingenPortalScreen));
