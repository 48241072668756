// @flow

import React from "react";

const style: React.CSSProperties = {
  color: "black",
};

type Props = {
  children?: any;
  style?: React.CSSProperties | null;
  "data-test"?: string | null;
};

const valueLabel = (props: Props) => (
  <span style={{ ...props.style, ...style }} data-test={props["data-test"]}>
    {props.children}
  </span>
);

export default valueLabel;
