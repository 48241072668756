import React from "react";
import ExpandablePage from "../../components/expandablePageSection";
// @ts-ignore
import statutenBCB2000 from "../../../../files/statutenBCB2000.pdf";
// @ts-ignore
import statutenBCB1996 from "../../../../files/statutenBCB1996.pdf";
// @ts-ignore
import statutenwijzigingBCB2000 from "../../../../files/statutenwijzigingBCB2000.pdf";
import Link from "../../../link";
import StatutesQuery from "../associationQuery";

const StatutenBCB = () => (
  <ExpandablePage title="Statuten en huishoudelijk reglement">
    <p>
      Als begrafenis en crematievereniging hebben we uiteraard statuten en een
      huishoudelijk reglement. Klik op de naam om het document te openen.
    </p>
    <p>
      <Link
        title="BCB Statuten 2000"
        href={statutenBCB2000}
        rel="attachment wp-att-218"
      >
        BCB Statuten 2000
      </Link>
    </p>
    <p>
      {/* @ts-ignore */}
      <Link href={statutenBCB1996}>BCB Statuten uit 1996</Link>
    </p>
    <p>
      {/* @ts-ignore */}
      <Link href={statutenwijzigingBCB2000}>
        BCB Statutenwijziging uit 2000
      </Link>
    </p>
    <StatutesQuery
      errorMessage={
        "Er is een fout opgetreden met het ophalen van de statuten."
      }
      render={(node) => (
        <p>
          <Link
            // @ts-ignore
            href={node.statutesUrl}
            target="_blank"
            rel="attachment wp-att-320"
          >
            2014-statuten-huishoudelijk-reglement
          </Link>
        </p>
      )}
    />
  </ExpandablePage>
);

export default StatutenBCB;
