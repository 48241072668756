import React from "react";
import DefaultPage from "../../components/defaultPage";
import Packages from "./packages";

const PackagesBCB = () => {
  return (
    <DefaultPage>
      <Packages />
    </DefaultPage>
  );
};

export default PackagesBCB;
