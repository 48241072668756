// @flow
/**
 * Created by Joshua Scheidt on 22-11-2016.
 */

import React from "react";
import { Table, TableBody, TableRow, TableRowColumn } from "material-ui/Table";
import Divider from "material-ui/Divider";
import { parseAmount, formatPrice, parseDate } from "../../../utils";
import { gql } from "@apollo/client";

const styles: { [key: string]: React.CSSProperties } = {
  assetsLiabsContainer: {
    paddingLeft: "5%",
    paddingRight: "5%",
    display: "flex",
  },
  tableFont: {
    fontSize: "12px",
  },
  titleFont: {
    fontSize: "16px",
    textAlign: "center",
  },
  TableKey: {
    fontWeight: "300",
    textAlign: "left",
    paddingLeft: 10,
    width: "50%",
  },
  TableValue: {
    fontWeight: "300",
    textAlign: "right",
    paddingRight: 10,
    width: "50%",
  },
  TotalKey: {
    fontWeight: "500",
    width: "50%",
    paddingLeft: 10,
    textAlign: "left",
  },
  TotalValue: {
    fontWeight: "500",
    width: "50%",
    paddingRight: 10,
    textAlign: "right",
  },
  divider: {
    marginTop: "2px",
    marginLeft: "5%",
    width: "90%",
    justifyContent: "center",
  },
  row: {
    height: "30px",
  },
};

type Child = {
  node: {
    name: string;
    value: number;
    children: Array<Child> | null;
  };
};

type DataForBS = {
  name: string;
  value: number;
  children: {
    edges: Array<Child> | null;
  };
};

type Props = {
  data: {
    date: string;
    currency: "EUR";
    asset: DataForBS;
    liability: DataForBS;
  };
};

const _getRows = (data: DataForBS) => {
  let rows = [];
  if (data.children.edges != null && data.children.edges.length > 0)
    for (var i = 0; i < data.children.edges.length; i++) {
      let row = (
        <TableRow key={i} style={styles.row}>
          <TableRowColumn
            style={{ ...styles.TableKey, ...styles.tableFont, ...styles.row }}
          >
            {data.children.edges[i].node.name}
          </TableRowColumn>
          <TableRowColumn
            style={{ ...styles.TableValue, ...styles.tableFont, ...styles.row }}
          >
            {formatPrice(parseAmount(data.children.edges[i].node.value))}
          </TableRowColumn>
        </TableRow>
      );

      rows.push(row);
    }
  return rows;
};

const BalanceSheet = (props: Props) => {
  let { asset, liability, date } = props.data;

  const totalValue = (
    <Table>
      <TableBody displayRowCheckbox={false}>
        <TableRow key={1} style={styles.row}>
          <TableRowColumn
            style={{ ...styles.TotalKey, ...styles.tableFont, ...styles.row }}
          >
            Totaal
          </TableRowColumn>
          <TableRowColumn
            style={{ ...styles.TotalValue, ...styles.tableFont, ...styles.row }}
          >
            {formatPrice(parseAmount(asset.value))}
          </TableRowColumn>
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <div style={{ height: "100%" }}>
      <span>{parseDate(date)}</span>
      <div style={styles.assetsLiabsContainer}>
        <div>
          Activa
          <Table>
            <TableBody displayRowCheckbox={false}>{_getRows(asset)}</TableBody>
          </Table>
        </div>
        <div>
          Passiva
          <Table>
            <TableBody displayRowCheckbox={false}>
              {_getRows(liability)}
            </TableBody>
          </Table>
        </div>
      </div>
      <Divider style={styles.divider} />
      <div style={styles.assetsLiabsContainer}>
        {totalValue}
        {totalValue}
      </div>
    </div>
  );
};

// get 2 layers
export const BalanceSheetFragment = gql`
  fragment balanceSheet on BalanceSheet {
    id
    date
    currency
    asset {
      name
      value
      children {
        edges {
          node {
            name
            value
          }
        }
      }
    }
    liability {
      name
      value
      children {
        edges {
          node {
            name
            value
          }
        }
      }
    }
  }
`;

export default BalanceSheet;
