// @flow

import React from "react";
import WarningIcon from "material-ui/svg-icons/alert/warning";

const styles: { [key: string]: React.CSSProperties } = {
  icon: {
    width: "30px",
    height: "30px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
};

const Warning = () => {
  return (
    <div style={styles.container}>
      <div style={styles.iconContainer}>
        <WarningIcon
          style={{
            ...styles.icon,
            color: "red",
            opacity: 0.6,
            paddingRight: "20px",
          }}
        />
        <span style={{ color: "red", opacity: 0.6, fontSize: "14px" }}>
          Let op!
        </span>
      </div>
      <span
        style={{
          color: "red",
          width: "400px",
          opacity: 0.6,
          fontSize: "14px",
          paddingBottom: "30px",
        }}
      >
        Een lid dat de status
        <span style={{ fontWeight: "bold" }}> In afwachting </span>
        of
        <span style={{ fontWeight: "bold" }}> Geaccepteerd </span>
        heeft, heeft geen aanspraak op diensten van de vereniging. Dit geldt ook
        voor een lid dat de status
        <span style={{ fontWeight: "bold" }}> Actief </span>
        heeft, maar waarvan de datum
        <span style={{ fontWeight: "bold" }}> Lid sinds </span>
        korter dan drie maanden geleden is. Neem in deze gevallen contact op met
        de secretaris van de vereniging.
      </span>
    </div>
  );
};

export default Warning;
