// @flow

import React from "react";
import FlatButton from "material-ui/FlatButton";
import { connect } from "react-redux";
import { logout } from "../../actions";
import { colors } from "../../styling";
import { Dispatch } from "../../reducers";
type Props = { dispatch: Dispatch };

const LogoutButton = ({ dispatch }: Props) => (
  <div
    style={{
      paddingTop: "5px",
    }}
  >
    <FlatButton
      style={{ color: colors.white }}
      label="Uitloggen"
      // @ts-ignore
      onClick={() => dispatch(logout())}
    />
  </div>
);

export default connect()(LogoutButton);
