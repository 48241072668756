// @flow

import React from "react";
import { colorsBCV as colors } from "../../styling";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    backgroundColor: "white",
    border: "1px solid",
    borderColor: colors.secondary,
    color: colors.secondary,
    height: 30,
    fontSize: "14px",
  },
  title: {
    fontSize: 30,
    color: "grey",
    fontWeight: "100",
  },
};

type Props = {
  onGoHome: () => void;
};

const Error = ({ onGoHome }: Props) => (
  <div style={styles.container}>
    <span style={styles.title}>
      Er is een fout opgetreden. Neem contact op met ons als dit zich blijft
      voordoen.
    </span>
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }} />
    <button id="button" style={styles.button} onClick={onGoHome}>
      Terug naar home
    </button>
  </div>
);

export default Error;
