import React from "react";
import PageSection from "../components/pageSection";
import Link from "../../link";

const SterfgevalMeldenHulpEnSteun = () => (
  <PageSection title="Sterfgeval melden?">
    <p>
      In geval van overlijden dienen nabestaanden rechtstreeks contact op te
      nemen met van Bree Uitvaartzorg te Landgraaf.
    </p>
    <p>Telefoon: 045-5211337</p>
    {/* @ts-ignore */}
    <p>
      Website:{" "}
      <Link href="http://www.uitvaartzorgvanbree.nl/">
        {" "}
        http://www.uitvaartzorgvanbree.nl/{" "}
      </Link>{" "}
    </p>
  </PageSection>
);

export default SterfgevalMeldenHulpEnSteun;
