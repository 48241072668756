// @flow

import React from "react";
import RaisedButton from "material-ui/RaisedButton";
import { errorHandler } from "../../../../containers";
import type { ErrorHandler } from "../../../../containers";
import { colors } from "../../../../styling";
import TextField from "../../../../components/textfield";
import { NavButtonContainer } from "../../../../components/dialog";
import { required } from "../../../../components/form";
import {
  UpdateMandateIdMutation,
  UpdateMandateIdMutationVariables,
} from "generated/graphql";
import { graphql } from "@apollo/client/react/hoc";
import { gql } from "generated";
import { Dialog } from "@mui/material";
import { DialogContainer2 } from "components/dialog/container";

const { green, white } = colors;

type PropsGraphQL = {
  updateMandateId: (mandateId: string) => Promise<any>;
};

type PropsParent = {
  open: boolean;
  onClose: () => void;
  paymentId: string;
};

type Props = PropsParent & ErrorHandler & PropsGraphQL;

type State = {
  mandateId: string;
  mandateIdError: string | null;
  loading: boolean;
};

class MandateIdUpdater extends React.Component<Props, State> {
  state: State = {
    mandateId: "",
    mandateIdError: null,
    loading: false,
  };

  _updateMandateId = async () => {
    const { mandateId } = this.state;
    const { updateMandateId, error } = this.props;

    const mandateIdError = required(mandateId);

    this.setState({
      mandateIdError,
    });

    if (!mandateIdError) {
      this.setState({
        loading: true,
      });
      try {
        await updateMandateId(mandateId);
        // Reload to reset the cache.
        window.location.reload();
      } catch (e) {
        this.setState({ loading: false });
        error.display();
      }
    }
  };

  render() {
    const { open, onClose } = this.props;
    const { mandateId, mandateIdError, loading } = this.state;

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogContainer2 data-test="update_mandate_id_dialog">
          <TextField
            data-test="update_mandate_id_textfield"
            // @ts-ignore
            floatingLabelText={"Nieuw mandaat ID"}
            hintText="Voer het nieuwe mandaat ID in"
            name="mandateId"
            errorText={mandateIdError}
            value={mandateId}
            fullWidth
            onChange={(event: any) =>
              this.setState({ mandateId: event.target.value })
            }
          />
          <NavButtonContainer>
            <RaisedButton
              // @ts-ignore
              id="back"
              label="Annuleren"
              primary={false}
              onClick={onClose}
            />
            <RaisedButton
              // @ts-ignore
              id="confirm"
              label="Bevestig"
              backgroundColor={green}
              labelColor={white}
              onClick={this._updateMandateId}
              disabled={loading}
            />
          </NavButtonContainer>
        </DialogContainer2>
      </Dialog>
    );
  }
}

const UPDATE_MANDATE_ID_MUTATION = gql(`
    mutation updateMandateId($paymentId: ID!, $mandateId: String!) {
        updateMandateId(input: {
            paymentId: $paymentId,
            mandateId: $mandateId
        }) {
            payment {
                id
                mandateId
            }
        }
    }
`);

const withMutation = graphql<
  PropsParent,
  UpdateMandateIdMutation,
  UpdateMandateIdMutationVariables,
  PropsGraphQL
>(UPDATE_MANDATE_ID_MUTATION, {
  props: ({ mutate, ownProps }) => ({
    updateMandateId: (mandateId: string) =>
      mutate!!({
        variables: { paymentId: ownProps.paymentId, mandateId },
      }),
  }),
});

export default withMutation(errorHandler(MandateIdUpdater));
