import React from "react";
import ExpandablePage from "../../components/expandablePageSection";

const RoyementBCB = () => (
  <ExpandablePage title="Royeren">
    <p>
      Begrafenis- en Crematievereniging Bleijerheide heeft, net als andere
      verenigingen, af en toe te maken met leden die hun contributie niet
      betalen. De redenen hierachter kunnen variëren van niet willen betalen tot
      niet kunnen betalen tot onbereikbare leden, waardoor incasso onmogelijk
      wordt.
    </p>
    <p>Procedureel hanteren we de volgende werkwijze (vanaf 01-01-2014):</p>
    <ul>
      <li>
        Leden krijgen 3 maanden na niet betalen een herinnering om de
        openstaande contributie alsnog te voldoen.
      </li>
      <li>
        Leden die niet binnen (wederom) 3 maanden met een betaling reageren
        worden geroyeerd. Dat betekent dat een lid geen aanspraak meer kan maken
        op de voorzieningen die door de vereniging worden aangeboden.
      </li>
      <li>
        Het lid kan binnen 1 maand (conform statuten) na aanzegging royement
        alsnog reageren met een betaling van de openstaande contributie. Deze
        openstaande contributie wordt echter met € 10 administratiekosten
        verhoogd.
      </li>
      <li>
        De betaaldata die we hanteren zijn altijd omstreeks 20 januari, 20
        april, 20 juli en 20 oktober.
      </li>
    </ul>
    <p>Voorbeeld:</p>
    <p>
      De vereniging stuurt in januari de incasso of acceptgiro naar een lid.
      Normalerwijze dient dan de contributiebetaling op 28 januari op de
      rekening van de vereniging te hebben plaatsgevonden. Het lid dat niet aan
      de betalingsverplichting voldoet krijgt dan uiterlijk 28 april een
      herinnering om alsnog de contributie te voldoen. Het lid dat dan nog
      steeds niet aan de betalingsverplichting voldoet krijgt dan uiterlijk 28
      juli een laatste herinnering die tevens de aanzegging is van het royement
      en kan dan geen recht meer doen gelden op de voorzieningen die door de
      vereniging worden aangeboden. Daarna kan het lid alsnog aan de
      betalingsverplichting voldoen door voor 28 augustus alsnog alle
      openstaande contributies verhoogd met 10 euro te voldoen. Gebeurt dat niet
      dan is het lid definitief geroyeerd en kan deze geen recht meer doen
      gelden op de voorzieningen die door de vereniging worden aangeboden.
    </p>
    <p>Om een royement te voorkomen bevelen wij als vereniging aan:</p>
    <ul>
      <li>zorg voor voldoende saldo als u gebruik maakt van incasso;</li>
      <li>houd in uw financiële planning rekening met onze incassodata;</li>
      <li>
        machtig de vereniging met een incassomachtiging zodat betalingen niet
        worden vergeten;
      </li>
      <li>
        zorg dat uw gegevens bij ons up-to-date blijven zodat we u kunnen
        bereiken;
      </li>
      <li>
        als u problemen hebt met betalen, treed dan in overleg in plaats van de
        zaken op zijn beloop te laten.
      </li>
    </ul>
  </ExpandablePage>
);

export default RoyementBCB;
