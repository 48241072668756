// @flow

import React, { Component } from "react";

import { errorHandler } from "../../containers";
import type { ErrorHandler } from "../../containers";
import { getDomesticPostalCodeError } from "./validatePostalCode";
import getHouseNumberError from "./validateHouseNumber";

import TextField from "../textfield";
import { serializePostalCode } from "../../utils";
import { gql } from "generated";
import { CompleteAddressQuery } from "generated/graphql";
import { clientNew } from "api/apollo2";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    width: "100%",
  },
};

type PropsParents = {
  // An optional initial address to show in the input
  address?: {
    postalCode: string;
    houseNumber: number;
    suffix: string | null;
    street: string;
    city: string;
    country: string;
  } | null;
  color: string;
  onCompleted: (input: {
    postalCode: string;
    houseNumber: number;
    suffix: string | null;
    street: string;
    city: string;
    country: string;
  }) => void;
  onIncomplete: () => void;
};

type Props = PropsParents & ErrorHandler;

type State = {
  postalCode: string;
  houseNumber: string;
  suffix: string;
  city: string;
  street: string;
  postalError: string | undefined;
  houseNumError: string | undefined;
  suffixError: string | undefined;
  streetError: string | undefined;
  cityError: string | undefined;
};

export const query = gql(`
  query completeAddress(
    $postalCode: String!
    $houseNumber: Int!
    $suffix: String
  ) {
    completeAddress(
      postalCode: $postalCode
      houseNumber: $houseNumber
      suffix: $suffix
    ) {
      address {
        city
        street
        postalCode
        country
        houseNumber
        suffix
      }
      unknownSuffix
      unknownPostalCode
      unknownHouseNumber
    }
  }
`);

export class AddressInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { address } = props;
    if (address) {
      this.state = {
        postalCode: address.postalCode,
        houseNumber: String(address.houseNumber),
        suffix: address.suffix ? address.suffix : "",
        city: address.city,
        street: address.street,
        postalError: undefined,
        houseNumError: undefined,
        suffixError: undefined,
        cityError: undefined,
        streetError: undefined,
      };
    } else {
      this.state = {
        postalCode: "",
        houseNumber: "",
        suffix: "",
        city: "",
        street: "",
        postalError: undefined,
        houseNumError: undefined,
        suffixError: undefined,
        cityError: undefined,
        streetError: undefined,
      };
    }
  }

  _shouldAutoComplete() {
    const { houseNumber, postalCode, postalError, houseNumError } = this.state;
    if (!houseNumber || !postalCode) {
      return false;
    }
    if (postalError || houseNumError) {
      return false;
    }
    return true;
  }

  async _completeAddress() {
    const { houseNumber, suffix } = this.state;
    const { onCompleted, error } = this.props;

    let { postalCode } = this.state;

    const shouldAutomcomplete = this._shouldAutoComplete();
    if (shouldAutomcomplete === false) {
      return;
    }
    try {
      postalCode = serializePostalCode(this.state.postalCode);

      const { data } = await clientNew.query<CompleteAddressQuery>({
        query,
        variables: {
          postalCode,
          houseNumber: Number(houseNumber),
          suffix: suffix.length > 0 ? suffix : null,
        },
      });

      const {
        completeAddress: {
          address,
          unknownSuffix,
          unknownPostalCode,
          unknownHouseNumber,
        },
      } = data;

      let suffixError, postalError, houseNumError;

      if (unknownSuffix) {
        suffixError = "Onbekende toevoeging";
      }
      if (unknownPostalCode) {
        postalError = "Onbekende postcode";
      }
      if (unknownHouseNumber) {
        houseNumError = "Onbekend huisnummer";
      }

      this.setState({
        suffixError,
        postalError,
        houseNumError,
      });

      if (!suffixError && !postalError && !houseNumError) {
        const { city, street, country } = address;
        this.setState({
          city,
          street,
        });
        onCompleted({
          postalCode,
          houseNumber: Number(houseNumber),
          suffix,
          city,
          street,
          country,
        });
      }
    } catch (e) {
      error.display();
    }
  }

  validatePostalCode(cb: () => void) {
    const { postalCode } = this.state;
    const postalError = getDomesticPostalCodeError(postalCode);

    this.setState(
      {
        postalError,
      },
      cb
    );
  }

  validateHouseNum(cb: () => void) {
    const { houseNumber } = this.state;
    const houseNumError = getHouseNumberError(houseNumber);
    this.setState(
      {
        houseNumError,
      },
      cb
    );
  }

  render() {
    const {
      postalCode,
      houseNumber,
      suffix,
      city,
      street,
      postalError,
      houseNumError,
      suffixError,
      streetError,
      cityError,
    } = this.state;
    const { onIncomplete, color } = this.props;

    const textfieldStyling = {
      fullWidth: true,
      color,
    };

    return (
      <div style={styles.container}>
        <TextField
          data-test="postalcode_textfield"
          // @ts-ignore
          id="postalcode"
          name="TextFieldPostal"
          value={postalCode}
          floatingLabelText={"Postcode"}
          errorText={postalError}
          onChange={(event: any) => {
            this.setState({
              postalCode: event.target.value.toUpperCase(),
              city: "",
              street: "",
              postalError: null,
            });
            onIncomplete();
          }}
          onBlur={() => {
            this.validatePostalCode(() => {
              this._completeAddress();
            });
          }}
          {...textfieldStyling}
        />
        <TextField
          data-test="house_num_textfield"
          // @ts-ignore
          id="houseNum"
          name="TextFieldHouseNum"
          value={houseNumber}
          floatingLabelText={"Huisnummer"}
          errorText={houseNumError}
          onChange={(event: any) => {
            this.setState({
              houseNumber: event.target.value,
              city: "",
              street: "",
              houseNumError: null,
            });
            onIncomplete();
          }}
          onBlur={() => {
            this.validateHouseNum(() => {
              this._completeAddress();
            });
          }}
          {...textfieldStyling}
        />
        <TextField
          // @ts-ignore
          id="suffix"
          name="TextFieldSuffix"
          value={suffix}
          floatingLabelText={"Mogelijke toevoeging"}
          errorText={suffixError}
          onChange={(event: any) => {
            this.setState({
              suffix: event.target.value,
              city: "",
              street: "",
              suffixError: null,
            });
            onIncomplete();
          }}
          onBlur={() => {
            this._completeAddress();
          }}
          {...textfieldStyling}
        />
        <TextField
          // @ts-ignore
          id="street"
          name="TextFieldAddress"
          value={street}
          floatingLabelText={"De bijbehorende straat"}
          errorText={streetError}
          disabled
          {...textfieldStyling}
        />
        <TextField
          // @ts-ignore
          id="city"
          name="TextFieldCity"
          value={city}
          floatingLabelText={"De bijbehorende woonplaats."}
          errorText={cityError}
          disabled
          {...textfieldStyling}
        />
      </div>
    );
  }
}

export const DomesticAddressInput = errorHandler(AddressInput);
