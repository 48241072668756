import { EditButton } from "components/button/buttons";
import { useState } from "react";
import { PhoneUpdateDialog } from "screens/policyDetailsPage/components/person/phoneUpdate";

export const UpdateGroupPhoneButton = ({
  insuredPersonIds,
}: {
  insuredPersonIds: string[];
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <EditButton onClick={() => setOpen(true)} />
      <PhoneUpdateDialog
        insuredPersonIds={insuredPersonIds}
        open={open}
        onClose={() => setOpen(false)}
        description="Update het telefoonnummer van alle leden in de groep"
      />
    </>
  );
};
