// @flow

import React from "react";
import { colorsBCV as colors } from "../../styling";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 30,
    fontWeight: "100",
  },
  button: {
    backgroundColor: "white",
    border: "1px solid",
    borderColor: colors.secondary,
    color: colors.secondary,
    height: 30,
    fontSize: "14px",
  },
};

type Props = {
  onGoLogin: () => void;
};

const SuccessReset = ({ onGoLogin }: Props) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
    }}
  >
    <span style={styles.title}>Uw wachtwoord is geupdate</span>
    <div style={{ paddingTop: "15px", paddingBottom: "15px" }} />
    <button style={styles.button} onClick={onGoLogin}>
      Terug naar login
    </button>
  </div>
);

export default SuccessReset;
