// @flow

const validateApolloResponse = (response: any) => {
  const { data, errors } = response;

  if (errors) {
    throw errors;
  }

  if (!data) {
    throw new Error("No data found");
  }

  return data;
};

export default validateApolloResponse;
