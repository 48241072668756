//@flow

/**
 * Created by Joshua Scheidt on 26-01-2017.
 */

import React, { Component } from "react";
import TextField from "material-ui/TextField";
import { colors } from "./../../../styling/index";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import type { UserState } from "../../../actions";
import { capitalizeFirstLetter } from "../../../utils";
import GenderPicker from "../../genderPicker";

const styles: { [key: string]: React.CSSProperties } = {
  instructionTitle: {
    color: colors.green,
    textTransform: "uppercase",
    fontSize: "18px",
    fontWeight: "bold",
  },
  instructionTitleGrey: {
    color: "grey",
    textTransform: "uppercase",
    fontSize: "18px",
    fontWeight: "bold",
  },
  valueSelecter: {
    color: "grey",
    textAlign: "left",
    alignItems: "left",
    justifyContent: "left",
  },
  inputFieldDiv: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  maleIcon: { width: "32px", height: "90px" },
  femaleIcon: { width: "40px", height: "90px" },
};

type SignupYourInfoError =
  | "EMPTY_FIRST_NAME_ERROR"
  | "EMPTY_LAST_NAME_ERROR"
  | "EMPTY_GENDER_ERROR"
  | "EMPTY_INITIALS_ERROR"
  | "INVALID_INITIALS_ERROR";

type Props = {
  nextPressed: (input: UserState) => void;
  backPressed(): void;
  setFirstName(firstName: string): void;
  setLastNamePrefix(lastNamePrefix: string): void;
  setLastName(lastName: string): void;
  setGender(gender: "MALE" | "FEMALE"): void;
  setInitials(initials: string): void;
  firstName: string;
  lastNamePrefix: string;
  lastName: string;
  gender: "MALE" | "FEMALE" | null;
  initials: string;
  errors: Array<SignupYourInfoError>;
};

type UserInfoErrors = {
  errorFirstName: string;
  errorLastName: string;
  errorGender: string;
  errorInitials: string;
};

const getErrorMessages = (
  errors: Array<SignupYourInfoError>
): UserInfoErrors => {
  let errorMessages = {
    errorFirstName: "",
    errorLastName: "",
    errorGender: "",
    errorInitials: "",
  };

  if (errors.indexOf("EMPTY_FIRST_NAME_ERROR") >= 0)
    errorMessages.errorFirstName = "Voornaam is niet ingevuld.";

  if (errors.indexOf("EMPTY_LAST_NAME_ERROR") >= 0)
    errorMessages.errorLastName = "Achternaam is niet ingevuld.";

  if (errors.indexOf("EMPTY_GENDER_ERROR") >= 0)
    errorMessages.errorGender = "Geslacht is niet gekozen.";

  if (errors.indexOf("EMPTY_INITIALS_ERROR") >= 0)
    errorMessages.errorInitials = "Initialen zijn niet ingevuld.";
  else if (errors.indexOf("INVALID_INITIALS_ERROR") >= 0)
    errorMessages.errorInitials = "Initialen kunnen enkel uit letters bestaan";

  return errorMessages;
};

class YourInfoStep extends Component<Props> {
  constructor(props: Props) {
    super(props);
    //$FlowFixMe
    this._nextPressed = this._nextPressed.bind(this);
  }

  _nextPressed() {
    const input = {
      firstName: this.props.firstName,
      lastNamePrefix: this.props.lastNamePrefix,
      lastName: this.props.lastName,
      initials: this.props.initials,
      gender: this.props.gender,
    };
    this.props.nextPressed(input);
  }

  render() {
    const { firstName, lastName, lastNamePrefix, gender, initials, setGender } =
      this.props;

    let errors = getErrorMessages(this.props.errors);

    return (
      <div style={{ width: "100%", maxWidth: "350px" }}>
        <div style={styles.inputFieldDiv}>
          <TextField
            data-test="first_name_textfield"
            value={firstName}
            floatingLabelText="Wat is de voornaam van het nieuwe lid..."
            floatingLabelStyle={styles.valueSelecter}
            inputStyle={styles.valueSelecter}
            onChange={(event: any) =>
              this.props.setFirstName(capitalizeFirstLetter(event.target.value))
            }
            errorText={errors.errorFirstName}
            errorStyle={{ color: "red" }}
            fullWidth={true}
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._nextPressed();
              }
            }}
          />

          <TextField
            data-test="initials_textfield"
            value={initials}
            floatingLabelText="... de initialen ..."
            floatingLabelStyle={styles.valueSelecter}
            inputStyle={styles.valueSelecter}
            onChange={(event: any) =>
              this.props.setInitials(event.target.value.toUpperCase())
            }
            errorText={errors.errorInitials}
            errorStyle={{ color: "red" }}
            fullWidth={true}
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._nextPressed();
              }
            }}
          />

          <TextField
            data-test="prefix_textfield"
            value={lastNamePrefix}
            floatingLabelText="... optioneel het tussenvoegsel ..."
            floatingLabelStyle={styles.valueSelecter}
            inputStyle={styles.valueSelecter}
            onChange={(event: any) =>
              this.props.setLastNamePrefix(event.target.value)
            }
            fullWidth={true}
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._nextPressed();
              }
            }}
          />

          <TextField
            data-test="last_name_textfield"
            value={lastName}
            floatingLabelText="... en de achternaam?"
            floatingLabelStyle={styles.valueSelecter}
            inputStyle={styles.valueSelecter}
            onChange={(event: any) =>
              this.props.setLastName(capitalizeFirstLetter(event.target.value))
            }
            errorText={errors.errorLastName}
            errorStyle={{ color: "red" }}
            fullWidth={true}
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._nextPressed();
              }
            }}
          />
          <div style={{ paddingTop: 20 }}>
            <GenderPicker
              value={gender}
              onChange={(gender) => {
                setGender(gender);
              }}
            />
            <div style={{ color: "red", height: "15px", fontSize: "12px" }}>
              {errors.errorGender}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "20px",
          }}
        >
          <div style={{ flex: 1, textAlign: "center" }}>
            <FlatButton
              label="Vorige"
              disableTouchRipple={true}
              disableFocusRipple={true}
              onClick={this.props.backPressed}
            />
          </div>
          <div style={{ flex: 1, textAlign: "center" }}>
            <RaisedButton
              label="Volgende"
              disableTouchRipple={true}
              disableFocusRipple={true}
              primary={true}
              onClick={this._nextPressed}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default YourInfoStep;
