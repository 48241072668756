// @flow

import React from "react";
import RaisedButton from "material-ui/RaisedButton";
import { ErrorHandler, errorHandler } from "../../../../containers";
import {
  required,
  mustBeIban,
  composeValidators,
} from "../../../../components/form";
import TextField from "../../../../components/textfield";
import { ContributionCheck } from "../../../../components/gdpr";
import { graphql } from "@apollo/client/react/hoc";
import {
  UpdateToDirectDebitMutation,
  UpdateToDirectDebitMutationVariables,
} from "generated/graphql";
import { updatePaymentMutation } from "components/payment/toDirectDebit";
type PropsParents = {
  handleClose(): void;
  insuredPersonId: string;
  associationName: string;
};

type PropsGraphQL = {
  updatePayment: (
    input: UpdateToDirectDebitMutationVariables["input"]
  ) => Promise<any>;
};

type Props = ErrorHandler & PropsParents & PropsGraphQL;

type State = {
  newIban: string;
  newAccountHolder: string;
  loading: boolean;
  firstTouchIban: boolean;
  firstTouchAccountHolder: boolean;
  checked: boolean | null;
};

const ibanValidator = composeValidators(required, mustBeIban);
const nameValidator = required;

class IbanComponent extends React.Component<Props, State> {
  state: State = {
    newIban: "",
    newAccountHolder: "",
    loading: false,
    firstTouchIban: false,
    firstTouchAccountHolder: false,
    checked: null,
  };

  render() {
    const {
      newIban,
      newAccountHolder,
      firstTouchIban,
      firstTouchAccountHolder,
      checked,
    } = this.state;

    const ibanError = ibanValidator(newIban);
    const nameError = nameValidator(newAccountHolder);

    return (
      <div style={{ height: "100%", width: "100%" }}>
        <h2>Wijzig uw IBAN</h2>
        <p>
          <TextField
            data-test="iban_textfield"
            // @ts-ignore
            floatingLabelText={"Nieuwe IBAN"}
            value={newIban}
            name="iban"
            fullWidth={true}
            errorText={firstTouchIban ? ibanError : undefined}
            onChange={(event: any) => {
              this.setState({
                newIban: event.target.value.toUpperCase(),
              });
            }}
            onBlur={() => {
              this.setState({ firstTouchIban: true });
            }}
          />
          <TextField
            data-test="accountholder_textfield"
            // @ts-ignore
            floatingLabelText={"Nieuwe naam rekeninghouder"}
            value={newAccountHolder}
            name="rekeninghouder"
            fullWidth={true}
            errorText={firstTouchAccountHolder ? nameError : undefined}
            onChange={(event: any) => {
              this.setState({ newAccountHolder: event.target.value });
            }}
            onBlur={() => {
              this.setState({ firstTouchAccountHolder: true });
            }}
          />
          <br />
          <ContributionCheck
            associationName={this.props.associationName}
            onCheck={(checked: boolean) => this.setState({ checked })}
          />
        </p>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "30px",
          }}
        >
          <RaisedButton
            label="Annuleer"
            primary={false}
            onClick={() => {
              this.props.handleClose();
            }}
          />
          <RaisedButton
            label="Wijzig"
            primary={false}
            disabled={!(ibanError == null && nameError == null && checked)}
            onClick={async () => {
              try {
                this.setState({ loading: true });
                await this.props.updatePayment({
                  insuredPersonIds: [this.props.insuredPersonId],
                  iban: newIban,
                  accountHolder: newAccountHolder,
                });
                window.location.reload();
              } catch (err) {
                this.props.error.display();
              } finally {
                this.setState({ loading: false });
              }
            }}
          />
        </div>
      </div>
    );
  }
}

const withMutation = graphql<
  PropsParents,
  UpdateToDirectDebitMutation,
  UpdateToDirectDebitMutationVariables,
  PropsGraphQL
>(updatePaymentMutation, {
  props: ({ mutate }) => ({
    updatePayment: (input) =>
      mutate({
        variables: { input },
      }),
  }),
});
const Wrapper = withMutation(errorHandler(IbanComponent));

export default Wrapper;
