// @flow

import React, { Component } from "react";
import { errorHandler, ErrorHandler } from "../../../../../../containers";
import { Warning, NavButtons } from "./components";
import TextField from "../../../../../../components/textfield";
import { VerticalSeparator } from "../../../../../../components/layout";
import { currentDate } from "./utils";
import { serializeDate } from "../../../../../../utils";

import {
  required,
  mustBeDate,
  composeValidators,
} from "../../../../../../components/form";
import {
  DischargePolicyMutation,
  DischargePolicyMutationVariables,
} from "generated/graphql";
import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import { DialogContainer2 } from "components/dialog/container";

const dischargeDateValidator = composeValidators(required, mustBeDate);

type PropsParent = {
  handleBack: () => void;
  handleClose: () => void;
  policyId: string;
};

type PropsGraphQLDischarge = { dischargePolicy(dischargeDate: string): void };
type Props = PropsParent & PropsGraphQLDischarge & ErrorHandler;
type State = {
  dischargeDate: string;
  dischargeDateError: string | null;
  loading: boolean;
};
class DischargeDialog extends Component<Props, State> {
  state = {
    dischargeDate: currentDate(),
    dischargeDateError: "",
    loading: false,
  };

  _updateState = async () => {
    const { dischargePolicy, error } = this.props;
    const { dischargeDate } = this.state;

    const dischargeDateError = dischargeDateValidator(dischargeDate);

    this.setState({
      dischargeDateError,
    });

    if (!dischargeDateError) {
      this.setState({ loading: true });
      try {
        await dischargePolicy(serializeDate(dischargeDate));
        // Reload to reset the cache.
        window.location.reload();
      } catch (e) {
        error.display();
        this.setState({ loading: false });
      }
    }
  };

  render() {
    const { handleBack } = this.props;
    const { dischargeDate, dischargeDateError, loading } = this.state;

    return (
      <DialogContainer2>
        <Warning toState={"Geroyeerd"} />
        <TextField
          data-test="discharge_date_input"
          // @ts-ignore
          floatingLabelText={"Royeringsdatum"}
          hintText="Voer de royementsdatum in (DD-MM-YYYY)"
          errorText={dischargeDateError}
          value={dischargeDate}
          fullWidth
          onChange={(event: any) =>
            this.setState({ dischargeDate: event.target.value })
          }
        />
        <VerticalSeparator distance={2} />
        <NavButtons
          onCancel={handleBack}
          onConfirm={() => {
            this._updateState();
          }}
          disabled={loading}
        />
      </DialogContainer2>
    );
  }
}

export const DISCHARGE_POLICY = gql`
  mutation dischargePolicy($input: DischargePolicyInput!) {
    dischargePolicy(input: $input) {
      policy {
        id
        dischargeDate
        __typename
      }
    }
  }
`;
export const withMutationDischarge = graphql<
  PropsParent,
  DischargePolicyMutation,
  DischargePolicyMutationVariables,
  PropsGraphQLDischarge
>(DISCHARGE_POLICY, {
  props: ({ ownProps, mutate }) => ({
    dischargePolicy: (dischargeDate: string) =>
      mutate!!({
        variables: {
          input: { policyId: ownProps.policyId, dischargeDate },
        },
      }),
  }),
});
const Wrapper = withMutationDischarge(errorHandler(DischargeDialog));

export default Wrapper;
