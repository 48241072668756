//@flow
import React from "react";
import HiThereLogo from "../../images/HiThereLogo.png";
import Paper from "material-ui/Paper";

const defaultStyle = {
  zIndex: 100,
  width: "100%",
};

/**
 * Footer containing the hithere logo.
 */
const Footer = ({ style }: { style?: Object }) => (
  <div style={{ ...defaultStyle, ...style }}>
    <Paper
      zDepth={3}
      style={{
        paddingLeft: 35,
        width: "100%",
        height: "90px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <a href="http://hi-there.community">
        <img src={HiThereLogo} style={{ height: 48 }} alt="HiThere" />
      </a>
    </Paper>
  </div>
);

export default Footer;
