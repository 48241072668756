// @flow

import { graphql } from "@apollo/client/react/hoc";
import { gql } from "generated";
import {
  DeleteInsuredPersonMutation,
  DeleteInsuredPersonMutationVariables,
} from "generated/graphql";
import { PropsParentsDeleteInsuredPerson } from "./presentation";

export const DELETE_INSURED_PERSON = gql(`
  mutation deleteInsuredPerson($input: DeleteInsuredPersonInput!) {
    deleteInsuredPerson(input: $input) {
      deletedInsuredPersonId
      __typename
    }
  }
`);

export type PropsDeleteInsuredPersonMutation = {
  deleteInsuredPerson: () => Promise<any>;
};

export const withMutation = graphql<
  PropsParentsDeleteInsuredPerson,
  DeleteInsuredPersonMutation,
  DeleteInsuredPersonMutationVariables,
  PropsDeleteInsuredPersonMutation
>(DELETE_INSURED_PERSON, {
  props: ({ mutate, ownProps }) => ({
    deleteInsuredPerson: () =>
      mutate({
        variables: { input: { insuredPersonId: ownProps.insuredPersonId } },
      }),
  }),
});
