//@flow

import raven from "raven-js";
import { isEmailUnique } from "../../api";
import { isEmail, validatePhone, isValidPassword } from "../../utils";
import { increaseStepperIndex } from "./index";
import { showAlertDialog } from "../alert";
import { Dispatch } from "../../reducers";

export type AccountState = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  passwordConfirm: string;
};

type ActionAddSignupFirstName = {
  type: "ADD_ACCOUNT_FIRSTNAME";
  firstName: string;
};
type ActionAddSignupLastName = {
  type: "ADD_ACCOUNT_LASTNAME";
  lastName: string;
};
type ActionAddSignupLastNamePrefix = {
  type: "ADD_ACCOUNT_LASTNAME_PREFIX";
  lastNamePrefix: string;
};
type ActionAddSignupPhone = {
  type: "ADD_ACCOUNT_PHONE";
  phone: string;
};
type ActionAddSignupEmail = {
  type: "ADD_ACCOUNT_EMAIL";
  email: string;
};
type ActionAddSignupPassword = {
  type: "ADD_ACCOUNT_PASSWORD";
  password: string;
};
type ActionAddSignupPasswordConfirm = {
  type: "ADD_ACCOUNT_PASSWORDCONFIRM";
  passwordConfirm: string;
};

export type SignupAccountError =
  | "INVALID_EMAIL_ERROR"
  | "DUPLICATE_EMAIL_ERROR"
  | "EMPTY_EMAIL_ERROR"
  | "EMPTY_FIRST_NAME_ERROR"
  | "EMPTY_LAST_NAME_ERROR"
  | "EMPTY_PASSWORD_ERROR"
  | "INVALID_PASSWORD"
  | "EMPTY_PASSWORD_CONFIRM_ERROR"
  | "INCONSISTENT_PASSWORD_ERROR"
  | "EMPTY_PHONE_ERROR"
  | "INVALID_PHONE_ERROR";

type ActionUpdateErrorsSignupAccount = {
  type: "UPDATE_ERRORS_SIGNUP_ACCOUNT";
  errors: Array<SignupAccountError>;
};

type ActionUpdateLoadingStateAccount = {
  type: "UPDATE_LOADING_SIGNUP_ACCOUNT";
  loading: boolean;
};

export type Action =
  | ActionAddSignupFirstName
  | ActionAddSignupLastName
  | ActionAddSignupLastNamePrefix
  | ActionAddSignupPhone
  | ActionAddSignupEmail
  | ActionAddSignupPassword
  | ActionAddSignupPasswordConfirm
  | ActionUpdateErrorsSignupAccount
  | ActionUpdateLoadingStateAccount;

export const addSignupEmail = (email: string): Action => ({
  type: "ADD_ACCOUNT_EMAIL",
  email,
});

export const addSignupFirstName = (firstName: string): Action => ({
  type: "ADD_ACCOUNT_FIRSTNAME",
  firstName,
});

export const addSignupLastName = (lastName: string): Action => ({
  type: "ADD_ACCOUNT_LASTNAME",
  lastName,
});

export const addSignupLastNamePrefix = (lastNamePrefix: string): Action => ({
  type: "ADD_ACCOUNT_LASTNAME_PREFIX",
  lastNamePrefix,
});

export const addSignupPassword = (password: string): Action => ({
  type: "ADD_ACCOUNT_PASSWORD",
  password,
});

export const addSignupPasswordConfirm = (passwordConfirm: string): Action => ({
  type: "ADD_ACCOUNT_PASSWORDCONFIRM",
  passwordConfirm,
});

export const addSignupPhone = (phone: string): Action => ({
  type: "ADD_ACCOUNT_PHONE",
  phone,
});

export const updateLoadingState = (loading: boolean): Action => ({
  type: "UPDATE_LOADING_SIGNUP_ACCOUNT",
  loading,
});

export const updateAccountErrors = (
  errors: Array<SignupAccountError>
): Action => {
  return {
    type: "UPDATE_ERRORS_SIGNUP_ACCOUNT",
    errors,
  };
};

export const setAccountState =
  (input: AccountState) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateLoadingState(true));
    let errors: Array<SignupAccountError> = [];

    if (!input.email) errors.push("EMPTY_EMAIL_ERROR");
    else if (!isEmail(input.email)) errors.push("INVALID_EMAIL_ERROR");
    else {
      try {
        const isUnique = await isEmailUnique(input.email);
        if (!isUnique) errors.push("DUPLICATE_EMAIL_ERROR");
      } catch (e) {
        dispatch(showAlertDialog());
        raven.captureException(e);
      }
    }

    if (!input.firstName) errors.push("EMPTY_FIRST_NAME_ERROR");

    if (!input.lastName) errors.push("EMPTY_LAST_NAME_ERROR");

    if (!input.password) errors.push("EMPTY_PASSWORD_ERROR");
    else if (!isValidPassword(input.password)) errors.push("INVALID_PASSWORD");

    if (!input.phone) errors.push("EMPTY_PHONE_ERROR");
    else if (!validatePhone(input.phone)) errors.push("INVALID_PHONE_ERROR");

    if (!input.passwordConfirm) errors.push("EMPTY_PASSWORD_CONFIRM_ERROR");
    else if (input.password && input.password !== input.passwordConfirm)
      errors.push("INCONSISTENT_PASSWORD_ERROR");

    if (errors.length > 0) {
      dispatch(updateAccountErrors(errors));
      dispatch(updateLoadingState(false));
    } else {
      dispatch(increaseStepperIndex());
      dispatch(updateLoadingState(false));
    }
  };
