// @flow

import React from "react";
import { TitleButton } from "../../../components/header";
import CircularProgress from "material-ui/CircularProgress";
import { downloadAddressesLink } from "../api";
import { downloadLink } from "../../../utils";
import { errorHandler, ErrorHandler } from "../../../containers";
import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";

type Props = WithApolloClient<{}> & ErrorHandler;
type State = {
  loading: boolean;
};

class DownloadAddress extends React.Component<Props, State> {
  state: State = {
    loading: false,
  };

  downloadLink = async () => {
    const {
      client,
      error: { display },
    } = this.props;

    this.setState({ loading: true });

    try {
      const link = await downloadAddressesLink(client);

      this.setState({ loading: false });

      downloadLink(link);
    } catch (error) {
      display();
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;

    let content;
    if (loading) {
      content = (
        <CircularProgress
          style={{ paddingTop: "8px" }}
          color={"white"}
          size={30}
        />
      );
    } else {
      content = (
        <TitleButton
          title="Download adressen"
          touchTap={() => {
            this.downloadLink();
          }}
        />
      );
    }

    return <div style={{ textAlign: "center", width: "200px" }}>{content}</div>;
  }
}

export default withApollo(errorHandler(DownloadAddress));
