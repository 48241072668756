// @flow

import { isHouseNum } from "../../utils";

enum HouseNumberError {
  NO_HOUSE_NUMBER = "NO_HOUSE_NUMBER",
  INVALID_HOUSE_NUMBER = "INVALID_HOUSE_NUMBER",
}

const validateHouseNumber = (houseNumber: string): HouseNumberError => {
  if (houseNumber.length === 0) {
    return HouseNumberError.NO_HOUSE_NUMBER;
  } else if (!isHouseNum(houseNumber)) {
    return HouseNumberError.INVALID_HOUSE_NUMBER;
  }
};

const formatHouseNumberError = (error: HouseNumberError): string => {
  switch (error) {
    case HouseNumberError.NO_HOUSE_NUMBER:
      return "Huisnummer is niet ingevuld.";
    case HouseNumberError.INVALID_HOUSE_NUMBER:
      return "Ongeldig huisnummer.";
    default:
      throw new Error(`Unrecognized house number error ${error}`);
  }
};

const getHouseNumberError = (houseNumber: string): string | null => {
  const houseNumberErrors = validateHouseNumber(houseNumber);
  return houseNumberErrors && formatHouseNumberError(houseNumberErrors);
};

export default getHouseNumberError;
