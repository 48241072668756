//@flow
import React from "react";
import { filter } from "graphql-anywhere";

import Header from "./header";
import { SideBar } from "../../components/header";
import { withRouter, WithRouterProps } from "react-router";
import RaisedButton from "material-ui/RaisedButton";
import { colors } from "../../styling";
import Eligibility from "../../components/eligibility/eligibility";

import Payment from "./payment";
import PersonalInfo from "./personalInfo";
import Contribution, {
  fragment as contributionFragment,
} from "./contribution/contribution";
import { errorHandler } from "../../containers";
import type { ErrorHandler } from "../../containers";
import { gql } from "@apollo/client";
import CircularProgress from "material-ui/CircularProgress";
import { serializeDate, serializeIban, serializePostalCode } from "../../utils";
import { ACCEPTANCE_QUERY } from "../registrationPage";
import { Error, Loading } from "./components";
import { DataProps, graphql } from "@apollo/client/react/hoc";
import { FetchResult } from "@apollo/client";
import { graphqlQuery } from "utils/withApollo";
import { PolicyInfo } from "./policyInfo";
import {
  AddPolicyCustomMutationVariables,
  AddPolicyCustomMutation,
  PolicySignupQuery,
  PolicySignupQueryVariables,
} from "generated/graphql";
import { AddressInput } from "components/address/addressInput";

const styles: { [key: string]: React.CSSProperties } = {
  outerContainer: {
    display: "flex",
    // textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "40px",
    paddingBottom: "40px",
  },
};

type State = {
  loading: boolean;
  showErrors: boolean;
  address: {
    postalCode: string;
    houseNumber: number;
    suffix: string | null;
    street: string;
    city: string;
    country: string;
  } | null;
};

type PropsGraphQL = {
  addPolicy: (
    input: AddPolicyCustomMutationVariables["input"]
  ) => Promise<FetchResult<AddPolicyCustomMutation>>;
};

type Props = ErrorHandler &
  PropsGraphQL &
  WithRouterProps &
  DataProps<PolicySignupQuery, PolicySignupQueryVariables>;

const hasEligibilityQuestions = (
  assocation: PolicySignupQuery["viewer"]["association"]
) => assocation.eligibilityQuestions.length > 0;

class RegisterClient extends React.Component<Props, State> {
  _personal: PersonalInfo;
  _contribution: Contribution;
  _payment: Payment;
  _eligibility: Eligibility;
  _policyInfo: PolicyInfo;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      showErrors: false,
      address: null,
    };
  }

  _signup = async () => {
    const { address } = this.state;
    const validPersonal = this._personal.validate();
    const validContribution = this._contribution.validate();
    const validPayment = this._payment.validate();
    const validEligibility = this._eligibility.validate();
    const validPolicyInfo = this._policyInfo.validate();
    if (
      validPersonal &&
      address &&
      validContribution &&
      validPayment &&
      validEligibility &&
      validPolicyInfo
    ) {
      const { addPolicy, error, router } = this.props;
      const personalData = this._personal.getInput();
      const paymentData = this._payment.getInput();
      const contributionData = this._contribution.getInput();
      const eligibilityData = this._eligibility.getInput();
      //Make sure to serialize the address before making the api call:
      address.postalCode = serializePostalCode(address.postalCode);

      const { signupDate } = this._policyInfo.getInput();

      this.setState({ loading: true });
      try {
        const response = await addPolicy({
          ...contributionData,
          ...personalData,
          birthdate: serializeDate(contributionData.birthdate),
          address,
          payment: {
            iban: serializeIban(paymentData.iban),
            accountName: paymentData.accountName,
          },
          eligibility: eligibilityData,
          signupDate,
        });
        const { data } = response;
        this.setState({ loading: false });
        router.push("/bestuurder/leden/" + data.addCustomPolicy.policy.id);
      } catch (e) {
        this.setState({ loading: false });
        error.display();
      }
    } else {
      this.setState({
        showErrors: true,
      });
    }
  };

  render() {
    const { showErrors, address } = this.state;
    const { loading, error, viewer } = this.props.data;

    let content;

    if (loading) {
      content = <Loading />;
    } else if (error || !viewer) {
      content = <Error />;
    } else {
      content = (
        <div style={styles.outerContainer}>
          <div style={{ width: "600px", ...styles.outerContainer }}>
            <h2>Persoonsgegevens</h2>
            <PersonalInfo
              ref={(ref) => (this._personal = ref)}
              onEnter={this._signup}
            />
            <h2>Adres</h2>
            <AddressInput
              onIncomplete={() =>
                this.setState({
                  address: null,
                })
              }
              onCompleted={(address) =>
                this.setState({
                  address,
                })
              }
            />
            {showErrors && !address ? (
              <p style={{ color: "red", fontSize: "12px" }}>
                Er is nog geen adres gevonden.
              </p>
            ) : null}
            <h2>Contributie</h2>
            <Contribution
              data={filter(contributionFragment, viewer.association)}
              ref={(ref) => (this._contribution = ref)}
              validate={this._signup}
            />
            <h2>Betaalinformatie</h2>
            <Payment
              ref={(ref) => (this._payment = ref)}
              onEnter={this._signup}
            />
            {hasEligibilityQuestions(viewer.association) && (
              <h2>Gezondsheidsverklaring</h2>
            )}
            <Eligibility
              questions={viewer.association.eligibilityQuestions}
              ref={(ref) => (this._eligibility = ref)}
              onEnter={this._signup}
            />
            <h2>Polis info</h2>
            <PolicyInfo
              onEnter={this._signup}
              ref={(ref) => (this._policyInfo = ref)}
            />
            <br />
            {this.state.loading ? (
              <CircularProgress color={colors.green} />
            ) : (
              <RaisedButton
                label="Lid Registreren"
                backgroundColor={colors.green}
                labelColor={colors.white}
                onClick={() => {
                  this._signup();
                }}
              />
            )}
          </div>
        </div>
      );
    }

    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Header />
        <SideBar />
        {content}
      </div>
    );
  }
}

export const SIGNUP_POLICY = gql`
  mutation addPolicyCustom($input: AddCustomPolicyInput!) {
    addCustomPolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;

export const VIEWER_QUERY = gql`
  query PolicySignup {
    viewer {
      id
      association {
        id
        eligibilityQuestions {
          id
          question
        }
        ...ContributionFragment
      }
    }
  }
  ${contributionFragment}
`;

export const withMutation = graphql<
  {},
  AddPolicyCustomMutation,
  AddPolicyCustomMutationVariables,
  PropsGraphQL
>(SIGNUP_POLICY, {
  props: ({ mutate }) => ({
    addPolicy: (input) =>
      mutate({
        refetchQueries: [{ query: ACCEPTANCE_QUERY }],
        variables: { input: input },
      }),
  }),
});

export const withQuery = graphqlQuery<
  PropsGraphQL,
  PolicySignupQuery,
  PolicySignupQueryVariables
>(VIEWER_QUERY);

const Wrapped = withMutation(
  withQuery(withRouter(errorHandler(RegisterClient)))
);

export default Wrapped;
