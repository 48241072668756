//@flow
/**
 * Created by Ellissa Verseput on 19-01-2017
 */

import { connect } from "react-redux";
import { openNavigationBar } from "../../../actions/navbar";
import HeaderBar from "../../../screens/ledenPortaal/header/clientHeader";
import { withRouter } from "react-router";
import type { Dispatch, State } from "../../../reducers";

/**
 * Maps the states from 'types' to the correct properties in Header.
 * @param navbar the state 'navigationBar' from 'types'
 *
 * @author Jim Bemelen
 */
const mapStateToProps = ({ navbar }: State) => ({
  open: navbar,
});

/**
 * Maps the dispatch methods to the correct actions in Actions.
 * @param dispatch the dispatched method.
 *
 * @author Jim Bemelen
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  openNavigationBar: () => dispatch(openNavigationBar()),
  // fetchLogout: () => dispatch(fetchLogout())
});

/**
 * Connector object which connects the Header to the methods defined above.
 * Connection is created in the export line.
 *
 * @author Jim Bemelen
 */
const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(HeaderBar));
