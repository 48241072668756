// @flow

import React from "react";
import Pencil from "material-ui/svg-icons/content/create";
import IconButton from "material-ui/IconButton";
import { colors } from "../../../../styling";

const styles: { [key: string]: React.CSSProperties } = {
  pencilStyle: {
    marginLeft: "5px",
    padding: "0px",
    width: "28px",
    height: "28px",
  },
};

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  "data-test"?: string | null;
};

const EditButton = (props: Props) => (
  <IconButton
    style={styles.pencilStyle}
    onClick={props.onClick}
    disabled={props.disabled}
    data-test={props["data-test"]}
  >
    <Pencil color={colors.green} />
  </IconButton>
);

export default EditButton;
