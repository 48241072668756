//@flow
import React from "react";
import TextField from "material-ui/TextField";
import { colors, colorsBCV } from "../../styling";
import RaisedButton from "material-ui/RaisedButton";
import { isEmail } from "../../utils";
import { errorHandler } from "../../containers";
import type { ErrorHandler } from "../../containers";
import { login } from "../../api";
import { storeToken } from "../../utils";
import { updateAuthState } from "../../actions/login";
import { store } from "../../store";
import ForgotPassword from "./../login/forgotPassword";
import { Dialog } from "@mui/material";

type State = {
  email: string;
  errorEmail: string | null;
  pass: string;
  errorPass: string | null;
  errorLogin: string | null;
  passForgotDialog: boolean;
};

const styles: { [key: string]: React.CSSProperties } = {
  inputFieldDiv: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
};

type PropsParent = {
  onConfirm: () => void;
};

type Props = ErrorHandler & PropsParent;

export class ExistingClient extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      errorEmail: null,
      pass: "",
      errorPass: null,
      errorLogin: null,
      passForgotDialog: false,
    };
  }

  _validate = async () => {
    const { onConfirm } = this.props;
    const { email, pass } = this.state;
    let { errorEmail, errorPass } = this.state;

    if (email === "") {
      errorEmail = "Het e-mailadres mag niet leeg zijn.";
    } else if (!isEmail(email)) {
      errorEmail = "Het e-mailadres is niet geldig.";
    } else errorEmail = null;
    if (pass === "") {
      errorPass = "Het wachtwoord mag niet leeg zijn.";
    } else errorPass = null;
    this.setState({
      errorEmail,
      errorPass,
    });

    if (!errorEmail && !errorPass) {
      const response = await login(email, pass);

      if (response && response.value && response.permissions) {
        await storeToken(response.value, response.permissions);
        //$FlowFixMe
        store.dispatch(updateAuthState(true, response.permissions));
        onConfirm();
      } else
        this.setState({
          errorLogin: "Het inloggen is mislukt, probeer opnieuw",
        });
    }
  };

  _forgotPassword = () => {
    this.setState({ passForgotDialog: true });
  };

  /**
   * Handles the closure of the forgot password dialog.
   */
  _handleCloseForgotPasswordDialog = () => {
    this.setState({ passForgotDialog: false });
  };

  render() {
    let { errorEmail, errorPass } = this.state;
    return (
      <div style={styles.inputFieldDiv}>
        <TextField
          // @ts-ignore
          id="email"
          floatingLabelText="E-mailadres"
          onChange={(event: any) =>
            this.setState({
              email: event.target.value,
            })
          }
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              this._validate();
            }
          }}
          errorText={errorEmail}
          fullWidth={true}
        />
        <TextField
          // @ts-ignore
          id="password"
          floatingLabelText="Wachtwoord"
          onChange={(event: any) =>
            this.setState({
              pass: event.target.value,
            })
          }
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              this._validate();
            }
          }}
          type="password"
          errorText={errorPass}
          fullWidth={true}
        />
        <div
          style={{
            paddingTop: "30px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ color: "red", paddingBottom: "0px" }}>
            {this.state.errorLogin}
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <RaisedButton
              onClick={this._forgotPassword}
              label="Wachtwoord vergeten?"
            />
          </div>
          <div>
            <RaisedButton
              // @ts-ignore
              id="verify"
              label="Volgende"
              labelColor={colors.white}
              backgroundColor={colorsBCV.secondary}
              onClick={() => this._validate()}
            />
          </div>
        </div>
        <Dialog
          open={this.state.passForgotDialog}
          onClose={this._handleCloseForgotPasswordDialog}
          fullWidth
          maxWidth="md"
        >
          <ForgotPassword
            onPressClose={this._handleCloseForgotPasswordDialog}
          />
        </Dialog>
      </div>
    );
  }
}

const Wrapper = errorHandler(ExistingClient);
export default Wrapper;
