// @flow
import moment from "moment";
import { PaymentMethod } from "../types";
import { isAmount, validateIban } from "./validation";
import {
  EventType,
  Frequency,
  Frequency as GraphQLFrequency,
  PolicyState,
  Sex,
} from "generated/graphql";
import { PaymentMethod as GraphQLPaymentMethod } from "generated/graphql";

moment.locale("nl");

export const formatAddress = (
  address: {
    postalCode: string;
    houseNumber: number;
    suffix?: string | null;
    street: string;
    city: string;
    countryCode: string;
  } | null
) => {
  const street = address.street;
  const houseNumber =
    address.houseNumber + (address.suffix ? " " + address.suffix : "");

  return (
    street +
    " " +
    houseNumber +
    ", " +
    address.postalCode +
    ", " +
    address.city +
    ", " +
    address.countryCode
  );
};

export const formatPremiumWithFrequency = (
  premium: string | number,
  frequency: Frequency
) => {
  const frequencyFormatted = formatFrequency2(frequency).toLowerCase();

  return formatToEuro(premium) + " " + frequencyFormatted;
};

export const formatGender = (sex: "MALE" | "FEMALE"): string => {
  switch (sex) {
    case "MALE":
      return "Man";
    case "FEMALE":
      return "Vrouw";
    default:
      throw new Error("Invalid gender " + sex);
  }
};

export const formatFrequency2 = (frequency: GraphQLFrequency) => {
  if (frequency === GraphQLFrequency.Yearly) {
    return "Per jaar";
  } else if (frequency === GraphQLFrequency.Quarterly) {
    return "Per kwartaal";
  } else if (frequency === GraphQLFrequency.SemiAnnually) {
    return "Per half jaar";
  } else {
    return "Onbekend";
  }
};

export const formatPaymentMethod = (paymentMethod: PaymentMethod): string => {
  switch (paymentMethod) {
    case PaymentMethod.INVOICE:
      return "Factuur";
    case PaymentMethod.DIRECT_DEBIT:
      return "Automatische incasso";
    case PaymentMethod.PERIODIC_TRANSFER:
      return "Periodieke overschrijving";
    default:
      throw new Error(`Unknown payment method ${paymentMethod}`);
  }
};

export const formatPaymentMethod2 = (
  paymentMethod: GraphQLPaymentMethod
): string => {
  switch (paymentMethod) {
    case GraphQLPaymentMethod.Invoice:
      return "Factuur";
    case GraphQLPaymentMethod.DirectDebit:
      return "Automatische incasso";
    case GraphQLPaymentMethod.PeriodicTransfer:
      return "Periodieke overschrijving";
    default:
      throw new Error(`Unknown payment method ${paymentMethod}`);
  }
};

/**
 * Serializes a date that we define as valid (1234 AB or 1234AB) into format 1234 AB.
 * @param  postalCode The postal code in format 1234 AB or 1234AB
 */
export const serializePostalCode = (postalCode: string): string => {
  return postalCode;
};

export const formatState = (
  state:
    | "AcceptedPolicy"
    | "ActivePolicy"
    | "CancelledPolicy"
    | "DeceasedPolicy"
    | "PendingPolicy"
    | "DischargedPolicy"
    | "RejectedPolicy"
): string => {
  switch (state) {
    case "PendingPolicy":
      return "In afwachting";
    case "AcceptedPolicy":
      return "Geaccepteerd";
    case "RejectedPolicy":
      return "Afgewezen";
    case "ActivePolicy":
      return "Actief";
    case "CancelledPolicy":
      return "Opgezegd";
    case "DeceasedPolicy":
      return "Overleden";
    case "DischargedPolicy":
      return "Geroyeerd";
    default:
      throw new Error("Invalid state");
  }
};

export const formatPolicyState = (state: PolicyState): string => {
  switch (state) {
    case PolicyState.Pending:
      return "In afwachting";
    case PolicyState.Accepted:
      return "Geaccepteerd";
    case PolicyState.Rejected:
      return "Afgewezen";
    case PolicyState.Active:
      return "Actief";
    case PolicyState.Cancelled:
      return "Opgezegd";
    case PolicyState.Dead:
      return "Overleden";
    case PolicyState.Discharge:
      return "Geroyeerd";
  }
};

const numberFormatter = (label: number): string => {
  if (Math.abs(label) > 1000000000) {
    return label / 1000000000 + "B";
  }
  if (Math.abs(label) > 1000) {
    return label / 1000000 + "M";
  } else {
    return `${label}`;
  }
};

/**
 * This method formats a value into a formatted string depending on the value type provided.
 * @param label the number to formatPattern
 * @param valueType the value type of the number. e.g. 'NUMBER', 'CURRENCY', or 'PERCENTAGE'
 * @returns the string representation of the value
 */
export const valueFormatter = (
  label: number,
  valueType: "PERCENTAGE" | "CURRENCY" | "NUMBER"
): string => {
  if (valueType === "PERCENTAGE") return label + "%";
  else if (valueType === "CURRENCY") return `€ ${numberFormatter(label)}`;
  else if (valueType === "NUMBER") return numberFormatter(label);
  else throw new Error(`Unknow value type ${valueType}`);
};

/**
 * Parses an ISO 8601 date (YYYY-MM-DD) returned by the server to
 * a european formatted date (DD-MM-YYYY) that is displayed to the user.
 * @param  date The date to be parsed
 */
export const parseDate = (date: string): string => {
  const dateMoment = moment(date, "YYYY-MM-DD", true);
  if (!dateMoment.isValid())
    throw new Error(`Expected date in format YYYY-MM-DD, but got ${date}.`);
  return dateMoment.format("DD-MM-YYYY");
};

export const parseJSDate = (date: Date): string => {
  return moment(date).format("DD-MM-YYYY");
};

/**
 * Serializes a european formatted date (DD-MM-YYYY) that is displayed to the user to an
 * ISO 8601 date (YYYY-MM-DD) sent to the server.
 * @param  date The date to be serialized
 */
export const serializeDate = (date: string): string => {
  const dateMoment = moment(date, "DD-MM-YYYY", true);
  if (!dateMoment.isValid())
    throw new Error(`Expected date in format DD-MM-YYYY, but got ${date}.`);
  return dateMoment.format("YYYY-MM-DD");
};

export const parseDateTime = (dateTime: string): string => {
  const momentDateTime = moment(dateTime);

  if (!momentDateTime.isValid()) {
    throw new Error(`${dateTime} is not a valid date`);
  }

  return momentDateTime.format("DD-MM-YYYY HH:mm:ss");
};

/**
 * Parses a numeric amount returned by the server
 * to a comma separated amount (e.g. 24,55)
 * @param amount The amount to parse.
 * Source: https://codereview.stackexchange.com/questions/107495/number-to-german-decimal-format-converter-function
 */
export const parseAmount = (amount: number): string => {
  let result = amount.toFixed(2);
  result = result.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  const decimalIndex = result.lastIndexOf(".");
  result = result.replace(/,/g, ".");
  result =
    result.substring(0, decimalIndex) +
    "," +
    result.substring(decimalIndex + 1);
  return result;
};

/**
 *This method places the euro sign with a white space to the string representation of an amount
 */
export const formatPrice = (price: string): string => {
  return `€ ${price}`;
};

/**
 * Formats a number to a comma separated value and addds a euro sign to it
 */
export const formatToEuro = (amount: number | string) =>
  formatPrice(parseAmount(Number(amount)));

/**
 * Serializes a comma separated amount to a a numeric amount
 * sent to the server
 * @param amount The amount to serialize.
 */
export const serializeAmount = (amount: string): number => {
  if (!isAmount(amount)) {
    throw new Error(
      `Expected a valid formatted amount (such as 1.224,55) but got ${amount}`
    );
  }
  return parseFloat(amount.replace(/\./g, "").replace(",", "."));
};

/**
 * Capitalizes the first letter of a word.
 */
export const capitalizeFirstLetter = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

/**
 * Serializes an IBAN by replacing all the whitespaces.
 * This is required for sending an IBAN to the server.
 */
export const serializeIban = (iban: string): string => {
  if (!validateIban(iban)) {
    throw new Error(`Expected a valid IBAN but got ${iban}`);
  }
  return iban.replace(/\s+/g, "");
};

export const serializeSex = (sex: "MALE" | "FEMALE"): Sex => {
  if (sex === "MALE") return Sex.Male;
  else if (sex === "FEMALE") return Sex.Female;
  else throw new Error();
};

export const deserializeEventType = (eventType: EventType): string => {

  switch(eventType) {
    case EventType.AcceptedPolicy: return "Lid is geaccepteerd"
    case EventType.ActivatedPolicy: return "Lid is geactiveerd"
    case EventType.AddedAdditionalInsurance: return "Additionele verzekering toegevoegd"
    case EventType.AddedSignatureDate: return "Ondertekeningsdatum toegevoegd"
    case EventType.CancelledAdditionalInsurance: return "Aanvullende verzekering opgezegd"
    case EventType.CancelledPolicy: return "Lidmaatschap opgezegd";
    case EventType.DeceasedPolicy: return "Lid overleden";
    case EventType.DeletedAccount: return "Gekoppelde account verwijderd";
    case EventType.DischargedPolicy: return "Lidmaatschap geroyeerd";
    case EventType.IndexedPremium: return "Indexatie premie";
    case EventType.MigratedPerson: return "Lid gekoppeld aan account";
    case EventType.RegisteredCustomPolicy: return "Lid door bestuur aangemaakt";
    case EventType.RegisteredPolicy: return "Lid aangemeld via het aanmeld proces";
    case EventType.RejectedPolicy: return "Verzoek tot lidmaatschap afgewezen.";
    case EventType.ReportedDeath: return "Melding dat lid overleden is"
    case EventType.RequestedPackageUpdate: return "Verzoek voor pakket overstap van lid";
    case EventType.UpdatedAdditionalInsurance: return "Additionele verzekering aangepast";
    case EventType.UpdatedAddress: return "Adres aangepast";
    case EventType.UpdatedInsuredPerson: return "Lid aangepast";
    case EventType.UpdatedMandateId: return "Maandaat ID aangepast";
    case EventType.UpdatedMemberId: return "Lidnummer aangepast";
    case EventType.UpdatedPackage: return "Pakket aangepast";
    case EventType.UpdatedPayment: return "Betalingsgegevens aangepast";
    case EventType.UpdatedPolicy: return "Lidmaatschap aangepast";
    default: return eventType
  }




}