// @flow

import React from "react";
import IconButton from "material-ui/IconButton";
import ArrowBackIcon from "material-ui/svg-icons/navigation/arrow-back";
import { colors } from "../../styling";

/**
 * An back button containing an arrow to be used
 * in the app bar.
 */
const BackButton = (props: { onClick?: (event: any) => void }) => (
  <IconButton onClick={props.onClick}>
    <ArrowBackIcon color={colors.white} />
  </IconButton>
);

export default BackButton;
