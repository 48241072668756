import React from "react";
import { colors } from "../../../styling";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import { composeValidators, mustBePassword, required } from "utils/validator";
import { useForm } from "utils/useForm";
import { useMutation } from "@apollo/client";
import { gql } from "generated";
import {
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables,
} from "generated/graphql";
import { clientNew } from "api/apollo2";
import { ErrorText } from "components/error";
import { DialogContainer2 } from "components/dialog/container";
import { NavButtonContainer } from "components/dialog";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  infoTitle: {
    paddingTop: "10px",
    fontSize: "18px",
    color: colors.green,
  },
};

type Props = {
  handleClose: () => void;
};

type FormValues = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const UpdatePassword = (props: Props) => {
  const { handleSubmit, errors, registerField, setErrors } =
    useForm<FormValues>();

  const [updatePassword, { data, loading, error }] = useMutation<
    UpdatePasswordMutation,
    UpdatePasswordMutationVariables
  >(UPDATE_PASSWORD_MUTATION, { client: clientNew });

  const onSubmit = () =>
    handleSubmit((data: FormValues) => {
      if (data.confirmPassword !== data.newPassword) {
        const message = "Wachtwoorden moeten gelijk zijn";

        setErrors({
          newPassword: message,
          confirmPassword: message,
        });
        return;
      }

      updatePassword({
        variables: {
          input: {
            newPassword: data.newPassword,
            oldPassword: data.oldPassword,
          },
        },
      });
    });

  const onKeyDown = (e: React.KeyboardEvent<{}>) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <DialogContainer2>
      <span style={styles.infoTitle}>Vul uw oude wachtwoord in.</span>
      <TextField
        fullWidth
        type="password"
        onKeyDown={onKeyDown}
        {...registerField("oldPassword", { validation: required })}
        errorText={errors.oldPassword}
      />
      <span style={styles.infoTitle}>Kies een nieuw wachtwoord...</span>
      <TextField
        fullWidth
        type="password"
        onKeyDown={onKeyDown}
        {...registerField("newPassword", {
          validation: composeValidators(required, mustBePassword),
        })}
        errorText={errors.newPassword}
      />
      <span style={styles.infoTitle}>...en bevestig dit wachtwoord.</span>
      <TextField
        fullWidth
        type="password"
        onKeyDown={onKeyDown}
        {...registerField("confirmPassword", { validation: required })}
        errorText={errors.confirmPassword}
      />

      {data != null && data?.updatePassword?.account == null && (
        <ErrorText>
          Het huidige wachtwoord wat u heeft ingevuld is fout, probeer het
          opnieuw{" "}
        </ErrorText>
      )}
      {error && <ErrorText>Er is een fout opgetreden</ErrorText>}
      {data?.updatePassword?.account != null && (
        <span>
          <b>Uw wachtwoord is geupdate!</b>
        </span>
      )}
      <NavButtonContainer>
        <RaisedButton
          // @ts-ignore
          id="back"
          label="Annuleren"
          disabled={loading}
          primary={false}
          onClick={() => props.handleClose()}
        />
        <RaisedButton
          // @ts-ignore
          id="confirm"
          label="Bevestig"
          disabled={loading}
          primary={false}
          labelStyle={{ color: colors.white }}
          onClick={() => onSubmit()}
          backgroundColor={colors.green}
        />
      </NavButtonContainer>
    </DialogContainer2>
  );
};

export const UPDATE_PASSWORD_MUTATION = gql(`
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      account{
        id
      }
    }
  }
`);

export default UpdatePassword;
