// @flow

import React, { Component } from "react";
import HulpEnSteunLogo from "../../../images/HenS-logo-white.png";
import { image } from "../../shared";
import Divider from "material-ui/Divider";
import { withRouter, WithRouterProps } from "react-router";
import Header from "../../ledenPortaal/header/homeHeader";
import { colorsBCV } from "../../../styling";
import { FloatingFooter } from "../../../components/footer";
import { VerticalSeparator } from "../../../components/layout";
import Link from "../../link";
// @ts-ignore
import NewsBrief from "../../../files/NieuwsbriefHenS2024.pdf";
import { NotifyDeathButton } from "./components";
import { Button } from "@mui/material";

// @ts-ignore
// const NewsBrief = React.lazy(
//   () => import("../../../files/NieuwsbriefHenS2024.pdf")
// );

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    height: "100%",
    width: "100%",
  },
  topContainer: {
    height: 800,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(" + image + ")",
    backgroundSize: "100% 120%",
  },
  dimmer: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.4)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  middleContainer: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
  },
  bottomContainer: {
    width: "20%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    marginLeft: "10%",
    marginTop: "5%",
    marginBottom: "5%",
    marginRight: "10%",
  },
  logo: {
    height: "160px",
  },
  headerText: {
    color: "white",
    textAlign: "center",
  },
};

class Home extends Component<WithRouterProps> {
  render() {
    return (
      <div style={styles.container}>
        <Header />
        <FloatingFooter />
        <div style={styles.topContainer}>
          <div style={styles.dimmer}>
            <div
              style={{
                paddingTop: "80px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img style={styles.logo} src={HulpEnSteunLogo} alt="" />
              <h1 style={styles.headerText}>
                Uitvaartvereniging Hulp En Steun
              </h1>
              <div style={{ ...styles.middleContainer, ...styles.headerText }}>
                <h4>
                  "Hulp en Steun" is een Natura uitvaartvereniging die zich ten
                  doel stelt om zonder winstoogmerk en op waardige wijze de
                  begrafenis of de crematie van haar leden te laten verzorgen.
                  Door onderlinge waarborg worden de financiële lasten over de
                  leden verspreid.
                </h4>
              </div>
              <div style={{ maxWidth: "80%" }}>
                <Button href={NewsBrief} download color="warning">
                  Bekijk nieuwsbrief 2024
                </Button>
              </div>
              <br />
              <VerticalSeparator distance={4} />
            </div>
          </div>
        </div>

        <Divider />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              ...styles.middleContainer,
              marginTop: "100px",
              marginBottom: "100px",
              width: "40%",
            }}
          >
            <h2 style={{ color: colorsBCV.secondary }}>Sterfgeval melden?</h2>
            <div style={{ paddingBottom: "40px" }}>
              <p>
                In geval van overlijden dienen nabestaanden rechtstreeks contact
                op te nemen met van Bree Uitvaartzorg te Landgraaf.
              </p>
              <p>Telefoon: 045-5211337</p>
              <p>
                Website:{" "}
                <Link href="http://www.uitvaartzorgvanbree.nl/">
                  {" "}
                  http://www.uitvaartzorgvanbree.nl/{" "}
                </Link>{" "}
              </p>
            </div>
            <NotifyDeathButton />
          </div>
        </div>
      </div>
    );
  }
}

const DecoratedHome = withRouter(Home);

export default DecoratedHome;
