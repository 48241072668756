import React from "react";
import PageSection from "../../components/pageSection";
import Link from "../../../link";

const BoardCalendarBCB = () => (
  <PageSection title="Bestuurskalender">
    <p>
      Mocht u een agendapunt willen inbrengen voor een bestuursvergadering, mail
      dan naar:{" "}
      <Link href="mailto:bcb.secretaris@gmail.com?subject=agendapunt">
        bcb.secretaris@gmail.com
      </Link>
      , om dit punt aan te melden. Na afloop van de vergadering nemen wij
      contact met u op voor een terugkoppeling.
    </p>
  </PageSection>
);

export default BoardCalendarBCB;
