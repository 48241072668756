// @flow

import React from "react";
import "./dialog.css";

const NavButtonContainer = (props: any) => (
  <div className="navigationButtonContainer">{props.children}</div>
);
const NavButtonContainerRightAligned = (props: any) => (
  <div className="navigationButtonRightAligned">{props.children}</div>
);

export { NavButtonContainer, NavButtonContainerRightAligned };
