// @flow

import SectionTitle from "../components/sectionTitle";
import { Row } from "../components/row";
import TitleContainer from "../components/titleContainer";
import { FragmentType, gql, useFragment } from "generated";
import { ContactDetailsFragment } from "generated/graphql";
import { Stack } from "@mui/material";
import { withRouter, WithRouterProps } from "react-router";
import { insurerPaths } from "screens/routes";
import { RaisedButton } from "material-ui";
import { colors } from "styling";
import { LinkToExistingAccount } from "./linkToExistingAccount";
import { LinkToNewAccount } from "./linkToNewAccount";

type Props = {
  data: FragmentType<typeof contactDetailsFragment> | null;
  insuredPersonId: string;
  disabled: boolean;
} & WithRouterProps;

const ContactDetails = (props: Props) => {
  const data = useFragment<ContactDetailsFragment | null>(
    contactDetailsFragment,
    props.data
  );

  const account = data.account;
  let content;

  if (!account) {
    content = (
      <div>
        <p>Dit lid is nog niet gekoppeld aan een account</p>
        <div style={{ textAlign: "center" }}>
          <Stack direction="row" spacing={2}>
            <LinkToExistingAccount insuredPersonId={props.insuredPersonId} />
            <LinkToNewAccount insuredPerson={data} />
            {/* <GenerateMigrationCode insuredPersonId={insuredPersonId} /> */}
          </Stack>
        </div>
      </div>
    );
  } else {
    content = (
      <div>
        <Row name="Naam" value={account.fullName} />
        <Row name="Telefoonnummer" value={account.phone} />
        <Row name="E-mail" value={account.username} />
      </div>
    );
  }

  return (
    <div>
      <TitleContainer>
        <SectionTitle title="Accountinformatie" />
        {account && (
          <div>
            <RaisedButton
              label="Beheer account"
              data-test="show_update_account"
              backgroundColor={colors.green}
              labelStyle={{ color: "white" }}
              onClick={() =>
                props.router.push({
                  pathname: insurerPaths.accountsDetails.path(account.id),
                })
              }
            />
          </div>
        )}
      </TitleContainer>
      {content}
    </div>
  );
};

export const contactDetailsFragment = gql(`
  fragment ContactDetails on InsuredPerson {
    id
    account {
      id
      phone
      username
      fullName
    }
    ...InsuredPersonAccountDetails
  }
`);

export default withRouter(ContactDetails);
