// @flow

import React from "react";
import ContentRow from "./contentRow";
import KeyLabel from "./keyLabel";
import ValueLabel from "./valueLabel";

export const KeyValueRow = ({
  name,
  value,
}: {
  name: string;
  value: string;
}) => {
  return (
    <ContentRow>
      <KeyLabel>{name}</KeyLabel>
      <ValueLabel>{value}</ValueLabel>
    </ContentRow>
  );
};
