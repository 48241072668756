// @flow

import type { ActionsEligibility } from "../../actions";

export type EligibilityAnswer = {
  questionId: string;
  response: boolean;
  explanation: string;
};
export type State = {
  answers: Array<EligibilityAnswer>;
};

const initialState: State = {
  answers: [],
};

const StepperEligibility = (
  state: State = initialState,
  action: ActionsEligibility
): State => {
  switch (action.type) {
    case "ADD_ELIGIBILITY_STATE": {
      return {
        ...state,
        answers: action.answers.map((item) => {
          return {
            response: item.answer,
            questionId: item.id,
            explanation: item.explanation,
          };
        }),
      };
    }
    default:
      return state;
  }
};

export default StepperEligibility;
