// @flow
/**
 * Created by Joshua Scheidt on 31-01-2017.
 */

import React from "react";
import PolicyCard, { PolicyState } from "./policyCard";
import { colors } from "../../../styling";
import { gql } from "@apollo/client";
import { filter } from "graphql-anywhere";

const stylesList: { [key: string]: React.CSSProperties } = {
  gridRowContainer: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    width: "900px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "30px",
  },
  emptyScreen: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    color: colors.grey,
    fontSize: "40px",
    fontWeight: 300,
  },
};

type Props = {
  data: {
    policies: {
      edges: Array<{
        node: {
          state: PolicyState;
        };
      }>;
    };
  };
};

const PolicyList = ({ data }: Props) => {
  let rows: Array<React.ReactElement> = [];
  const policies = data.policies.edges;

  const filteredPolicies = policies.filter(
    (policy) => policy.node.state !== "DEAD"
  );

  const numPolicies = filteredPolicies.length;
  if (numPolicies === 0) {
    return (
      <div style={stylesList.emptyScreen}>
        U heeft op dit moment nog geen lidmaatschap bij ons.
      </div>
    );
  }

  for (let i = 0; i < numPolicies; ) {
    if (numPolicies - i > 1) {
      rows.push(
        <div key={i} style={stylesList.gridRowContainer}>
          <div style={{ flex: 1, paddingRight: "15px" }}>
            <PolicyCard
              cardInfo={filter(
                PolicyCard.fragments.cardInfo,
                filteredPolicies[i].node
              )}
            />
          </div>
          <div style={{ flex: 1, paddingLeft: "15px" }}>
            <PolicyCard
              cardInfo={filter(
                PolicyCard.fragments.cardInfo,
                filteredPolicies[i + 1].node
              )}
            />
          </div>
        </div>
      );
    } else {
      rows.push(
        <div key={i} style={stylesList.gridRowContainer}>
          <div style={{ flex: 1, paddingRight: "15px" }}>
            <PolicyCard
              cardInfo={filter(
                PolicyCard.fragments.cardInfo,
                filteredPolicies[i].node
              )}
            />
          </div>
          <div style={{ flex: 1, paddingRight: "15px" }} />
        </div>
      );
      break;
    }
    i += 2;
  }
  return <div>{rows}</div>;
};

PolicyList.fragments = {
  policyListInfo: gql`
    fragment PolicyListInfo on Account {
      policies {
        edges {
          node {
            state
            ...PolicyCard
          }
        }
      }
    }
    ${PolicyCard.fragments.cardInfo}
  `,
};

export default PolicyList;
