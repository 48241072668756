// @flow

/**
 * Contains objects related to calling the GraphQL API.
 */

import type { AddPolicyInput } from "./signup";
import activateAccountAPI from "./activateAccount";

import loginAPI from "./login";
import addQuoteAPI from "./addQuote";

import {
  getPackages as getPackagesAPI,
  addAccount as addAccountAPI,
  isEmailUnique as isEmailUniqueAPI,
  addPolicy as addPolicyAPI,
} from "./signup";
import { DocumentNode } from "graphql";
import { Frequency } from "generated/graphql";
import { clientNew } from "./apollo2";

export type { Package } from "./signup";

export type { AddQuote } from "./addQuote";

export const login = (username: string, password: string) => {
  return loginAPI(username, password, clientNew);
};

export const addQuote = (
  birthdate: string,
  packageId: string,
  frequency: Frequency
) => {
  return addQuoteAPI(birthdate, packageId, frequency, clientNew);
};

export const getPackages = (associationId: string) => {
  return getPackagesAPI(associationId, clientNew);
};
export const addPolicy = (
  input: AddPolicyInput,
  refetchQueries: Array<DocumentNode>
) => {
  return addPolicyAPI(input, refetchQueries, clientNew);
};

export const addAccount = (input: {
  username: string;
  password: string;
  associationId: string;
  firstName: string;
  lastName: string;
  namePreposition: string;
  phone: string;
  redirectUrl: string;
}) => {
  return addAccountAPI(input, clientNew);
};

export const isEmailUnique = (username: string) => {
  return isEmailUniqueAPI(username, clientNew);
};

export const activateAccount = (username: string, key: string) => {
  return activateAccountAPI(username, key, clientNew);
};

export type { AccountPermission } from "./login";

export { default as forgotPassword } from "./forgotPassword";
export { default as resetPassword } from "./resetPassword";

export { default as validateApolloResponse } from "./validateApolloResponse";
