import { useState } from "react";
import EditButton from "../components/editButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { textFieldErrorProps, useForm } from "utils/useForm";
import { composeValidators } from "components/form";
import { mustBeDate, required } from "utils/validator";
import { FragmentType, gql, useFragment } from "generated";
import { useMutationForApp } from "utils/withApollo";
import { parseDate, serializeDate } from "utils";
import { PolicyState } from "generated/graphql";

export const UpdateSignOffDateButton = (props: {
  data: FragmentType<typeof fragment>;
}) => {
  const data = useFragment(fragment, props.data);

  const policyId = data.id;

  const [show, setShow] = useState(false);
  const { registerField, handleSubmit, errors } = useForm<{
    signOffDate: string;
  }>({
    initialValues: { signOffDate: parseDate(data.signOffDate) },
  });
  const errorProps = textFieldErrorProps(errors);

  const [updateSignatureDate, { loading }] = useMutationForApp(MUTATION, {
    refetchQueries: "active",
    onCompleted: () => {
      setShow(false);
    },
  });

  if (data.signOffDate == null) return null;

  return (
    <>
      <EditButton onClick={() => setShow(true)} />
      <Dialog
        maxWidth={"sm"}
        fullWidth
        open={show}
        onClose={() => setShow(false)}
      >
        <DialogTitle>Pas {getText(data.state)} aan</DialogTitle>
        <DialogContent>
          <TextField
            label={getText(data.state)}
            fullWidth
            {...registerField("signOffDate", {
              validation: composeValidators(required, mustBeDate),
            })}
            {...errorProps("signOffDate")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShow(false)} variant="outlined">
            Annuleer
          </Button>
          <Button
            disabled={loading}
            onClick={() =>
              handleSubmit((data) => {
                updateSignatureDate({
                  variables: {
                    input: {
                      policyId,
                      signoffDate: serializeDate(data.signOffDate),
                    },
                  },
                });
              })
            }
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const fragment = gql(`
   fragment PolicySignOffButton on Policy {
        id
        state
        signOffDate
   } 
`);

const MUTATION = gql(`
mutation updatePolicyMutationSignOff($input: UpdatePolicyInput!) {
  updatePolicy(input: $input) {
    policy {
      id
      signOffDate
    }
  }
}
`);

const getText = (state: PolicyState) => {
  switch (state) {
    case PolicyState.Cancelled:
      return "opzegdatum";
    case PolicyState.Dead:
      return "overlijdensdatum";
    case PolicyState.Discharge:
      return "royementsdatum";
    case PolicyState.Rejected:
      return "afwijzingsdatum";
    default:
      return null;
  }
};
