//@flow

import React from "react";
import Header from "./signupStepperExistingAccount/header";
import Stepper from "../../screens/policySignup/signupStepperExistingAccount/signupStepperExistingAccount";
import Divider from "material-ui/Divider";

const styles: { [key: string]: React.CSSProperties } = {
  outerContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: "64px",
    flexDirection: "column",
  },
  textContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "200px",
    fontSize: "26px",
    fontWeight: "100",
    textAlign: "center",
  },
  stepperContainer: {
    width: "100%",
    minWidth: "680px",
    display: "flex",
    justifyContent: "center",
  },
};

const SignUpStepperPageExistingAccount = () => (
  <div>
    <Header />
    <div style={styles.outerContainer}>
      <div style={styles.textContainer}>
        Doorloop de volgende stappen om een nieuw lid toe te voegen aan uw
        account.
      </div>
      <Divider style={{ width: "100%" }} />
      <div style={{ paddingTop: "30px" }} />
      <div style={styles.stepperContainer}>
        <Stepper />
      </div>
    </div>
  </div>
);

export default SignUpStepperPageExistingAccount;
