// @flow

import React from "react";
import ExpandablePage from "../../components/expandablePageSection";
import Link from "../../../link";

const ProductPietersrade = () => (
  <ExpandablePage title="Product">
    <p>
      St. Pietersrade biedt twee pakketten aan, te weten: één voor begraven en
      één voor cremeren. De keuze van het pakket bepaalt de hoogte van de premie
      die betaald moet worden.
    </p>
    <p>
      Uw nabestaanden ontvangen een vergoeding in natura wanneer u als lid komt
      te overlijden. Dat wil zeggen dat er geen geld uitgekeerd wordt.
      Afhankelijk van de verzekering wordt een begrafenis- of een crematiepakket
      vergoed.
    </p>
    <p>
      Ga naar {/* @ts-ignore */}
      <Link href="/pakketten">pakketinformatie</Link> voor meer informatie over
      de pakketsamenstellingen.
    </p>
  </ExpandablePage>
);

export default ProductPietersrade;
