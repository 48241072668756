//@flow
import React from "react";
import { withRouter, WithRouterProps } from "react-router";
import Header from "../ledenPortaal/header/homeHeader";
import Existing from "./existing";
import Registered from "./registered";
import New from "./new";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import config, { AssociationType } from "../../config";

const styles: { [key: string]: React.CSSProperties } = {
  outerContainer: {
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "70px",
  },
  title: {
    width: "600px",
    textAlign: "center",
  },
  block: {
    width: "400px",
    paddingTop: "40px",
  },
};

type State = {
  state:
    | {
        type: "EXISTING";
      }
    | {
        type: "NEW";
      }
    | {
        type: "REGISTERED";
        email: string;
      };
};

type Props = WithRouterProps;

export class MigrationLoginPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      state: {
        type: "EXISTING",
      },
    };
  }

  render() {
    const { router } = this.props;
    const { state } = this.state;

    const goToMigrate = () => {
      router.push(`/account/koppel`);
    };

    let content;
    if (state.type === "EXISTING") {
      content = <Existing onConfirm={goToMigrate} />;
    } else if (state.type === "NEW") {
      content = (
        <New
          onRegistered={(email) => {
            this.setState({ state: { type: "REGISTERED", email } });
          }}
        />
      );
    } else {
      content = (
        <Registered email={state.email} onConfirm={() => goToMigrate()} />
      );
    }

    let radioButtons;

    if (state.type === "EXISTING" || state.type === "NEW") {
      radioButtons = (
        <RadioButtonGroup
          // @ts-ignore
          id="group"
          name="createClient"
          defaultSelected={state.type}
          onChange={(event: any) => {
            const { value } = event.target;
            if (value === "EXISTING") {
              this.setState({ state: { type: "EXISTING" } });
            } else if (value === "NEW") {
              this.setState({ state: { type: "NEW" } });
            }
          }}
        >
          <RadioButton
            // @ts-ignore
            id="existing"
            value="EXISTING"
            label="Bestaand"
            style={{ paddingBottom: "10px" }}
          />
          <RadioButton id="new" value="NEW" label="Nieuw" />
        </RadioButtonGroup>
      );
    }

    const contentForAssociation = (
      <div style={styles.outerContainer}>
        <h2 style={styles.title}>
          Bestaand lidmaatschap koppelen aan een account
        </h2>
        <div style={styles.title}>
          <p>
            Gebruik deze pagina om een bestaand lidmaatschap te koppelen aan uw
            account.
          </p>
          <p>
            Indien u nog geen account heeft dient u een nieuw account aan te
            maken door hieronder "Nieuw" aan te vinken en onderstaande stappen
            uit te voeren.
          </p>
          <p>
            Na het inloggen of aanmaken van een account kunt u de ontvangen
            codes invoeren om u bestaande lidmaatschap(pen) te koppelen aan uw
            account.
          </p>

          <p>
            {config.association === AssociationType.PIETERSRADE && (
              <b>
                Klik {/*eslint-disable-next-line*/}
                <a target="_blank" href="https://youtu.be/1urueau1N2Q">
                  HIER
                </a>{" "}
                om de instructievideo te bekijken.
              </b>
            )}
          </p>
        </div>
        <div style={styles.block}>
          {radioButtons}
          {content}
        </div>
      </div>
    );

    return (
      <div>
        <Header />
        {contentForAssociation}
      </div>
    );
  }
}

export default withRouter(MigrationLoginPage);
