// @flow

import { clientNew } from "api/apollo2";
import {
  Frequency,
  PremiumTableEntryForFrequencyQuery,
  PremiumTableEntryForFrequencyQueryVariables,
} from "generated/graphql";
import { gql } from "@apollo/client";
import { parseAmount } from "../../../../../utils";

export const query = gql`
  query premiumTableEntryForFrequency(
    $policyId: ID!
    $targetFrequency: Frequency!
  ) {
    node(id: $policyId) {
      ... on Policy {
        id
        premiumTableEntryForFrequency(targetFrequency: $targetFrequency) {
          id
          premium
        }
      }
    }
  }
`;

export const getPremiumForFrequency = async (input: {
  policyId: string;
  targetFrequency: Frequency;
}): Promise<string | null> => {
  const { targetFrequency, policyId } = input;
  const { data } = await clientNew.query<
    PremiumTableEntryForFrequencyQuery,
    PremiumTableEntryForFrequencyQueryVariables
  >({
    query,
    variables: { policyId, targetFrequency },
    fetchPolicy: "network-only",
  });

  return (
    data &&
    data.node &&
    // @ts-ignore
    data.node.premiumTableEntryForFrequency &&
    // @ts-ignore
    parseAmount(data.node.premiumTableEntryForFrequency.premium)
  );
};
