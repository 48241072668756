// @flow

import React, { Component } from "react";
import { gql } from "@apollo/client";

import TextField from "material-ui/TextField";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { colors } from "../../../styling";
import { isDate } from "../../../utils";
import Custom from "./custom";
import Standard from "./standard";
import { Frequency } from "generated/graphql";
import { formatFrequency2 } from "utils/formatter";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  button: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: colors.green,
    width: "200px",
    height: 40,
    backgroundColor: "white",
    color: colors.green,
    outline: "none",
  },
};

type Package = {
  id: string;
  name: string;
  insuredAmount: number;
  frequencies: Array<Frequency>;
};

type State = {
  selectedPackage: Package;
  birthdateError: string | null;
  birthdate: string;
  frequency: Frequency;
  state: "STANDARD" | "CUSTOM";
};

type Props = {
  data: {
    packages: Array<Package>;
  };
  validate: Function;
};
export default class Contribution extends Component<Props, State> {
  _custom: Custom;
  _standard: Standard;

  constructor(props: Props) {
    super(props);

    const packages = props.data.packages;

    if (packages.length === 0) throw new Error("Expected packages");

    const selectedPackage = packages[0];

    this.state = {
      birthdate: "",
      birthdateError: null,
      selectedPackage,
      frequency: selectedPackage.frequencies[0],
      state: "STANDARD",
    };
  }

  getInput(): {
    birthdate: string;
    packageId: string;
    frequency: Frequency;
    premium: number;
    lumpsum: number;
  } {
    const { birthdate, selectedPackage, frequency, state } = this.state;

    let premiumData;

    if (state === "CUSTOM") {
      premiumData = this._custom.getInput();
    } else {
      premiumData = this._standard.getInput();
    }
    return {
      birthdate,
      packageId: selectedPackage.id,
      frequency,
      ...premiumData,
    };
  }

  validateBirthdate(): boolean {
    const { birthdate } = this.state;

    let birthdateError = null;

    if (birthdate.length === 0) {
      birthdateError = "Geboortedatum is niet ingevuld.";
    } else if (!isDate(birthdate)) {
      birthdateError = "Geboortedatum is geen valide datum.";
    }

    this.setState({
      birthdateError,
    });
    return birthdateError == null;
  }

  /**
   * Validates the input
   * @returns true if the input is correct
   */
  validate(): boolean {
    const { state } = this.state;

    let correctInput = true;

    if (state === "STANDARD") {
      let standardCorrect = this._standard.validate();
      if (!standardCorrect) correctInput = false;
    } else if (state === "CUSTOM") {
      let customCorrect = this._custom.validate();
      if (!customCorrect) correctInput = false;
    }

    return correctInput;
  }

  render = () => {
    const { birthdateError, birthdate, selectedPackage, frequency, state } =
      this.state;

    const { packages } = this.props.data;

    const textfieldStyling = {
      floatingLabelFocusStyle: {
        color: colors.green,
      },
      underlineFocusStyle: {
        borderColor: colors.green,
      },
      fullWidth: true,
    };

    return (
      <div style={styles.container}>
        <TextField
          data-test="birthdate_input"
          floatingLabelText="Geboortedatum"
          hintText="DD-MM-JJJJ"
          errorText={birthdateError}
          onChange={(event: any) => {
            this.setState({
              birthdate: event.target.value,
            });
            this._standard.resetPremium();
          }}
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this.props.validate();
            }
          }}
          value={birthdate}
          {...textfieldStyling}
        />
        <h3>Kies een pakket</h3>
        <RadioButtonGroup
          name="Pakketten"
          valueSelected={selectedPackage.id}
          onChange={(event, value) => {
            const selectedPack = packages.filter((p) => p.id === value)[0];

            this.setState({
              selectedPackage: selectedPack,
              frequency: selectedPack.frequencies[0],
            });
            this._standard.resetPremium();
          }}
        >
          {packages.map((pack, i) => (
            <RadioButton
              data-test={pack.name}
              value={pack.id}
              label={pack.name}
              key={i}
              iconStyle={{ fill: colors.green }}
            />
          ))}
        </RadioButtonGroup>
        <div style={{ paddingTop: "30px" }} />
        <SelectField
          data-test="payment_frequency_select"
          floatingLabelText="Tijdsperiode van betaling"
          floatingLabelStyle={{ color: colors.green }}
          value={frequency}
          style={{ width: "100%" }}
          selectedMenuItemStyle={{ color: colors.green }}
          underlineFocusStyle={{ borderColor: colors.green }}
          onChange={(event, index, value) => {
            this.setState({ frequency: value });
            if (this._standard) {
              this._standard.resetPremium();
            }
          }}
        >
          {selectedPackage.frequencies.map((freq, index) => (
            <MenuItem
              value={freq}
              key={index}
              primaryText={formatFrequency2(freq)}
            />
          ))}
        </SelectField>
        <h3>Bepaal de contributie</h3>
        <RadioButtonGroup
          // @ts-ignore
          id="group"
          name="createClient"
          defaultSelected={state}
          onChange={(event: any) => {
            const { value } = event.target;
            if (value === "STANDARD") {
              this.setState({ state: "STANDARD" });
            } else if (value === "CUSTOM") {
              this.setState({ state: "CUSTOM" });
            }
          }}
        >
          <RadioButton
            // @ts-ignore
            id="standard"
            value="STANDARD"
            label="Standaard"
            style={{ paddingBottom: "10px" }}
            iconStyle={{ fill: colors.green }}
          />
          <RadioButton
            // @ts-ignore
            id="custom"
            value="CUSTOM"
            label="Aangepaste contributie opgeven"
            iconStyle={{ fill: colors.green }}
          />
        </RadioButtonGroup>
        {state === "CUSTOM" ? (
          <Custom
            ref={(ref) => (this._custom = ref)}
            validate={this.props.validate}
          />
        ) : (
          <div style={{ marginTop: "30px" }}>
            <Standard
              birthdate={birthdate}
              frequency={frequency}
              selectedPackage={selectedPackage}
              shouldCalculate={() => this.validateBirthdate()}
              ref={(ref) => (this._standard = ref)}
            />
          </div>
        )}
      </div>
    );
  };
}

export const fragment = gql`
  fragment ContributionFragment on Association {
    packages {
      id
      name
      insuredAmount
      frequencies
    }
  }
`;
