//@flow
/**
 * Created by Joshua Scheidt on 06-07-2017.
 */
import React from "react";
import { withRouter, WithRouterProps } from "react-router";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import { colorsBCV, colors } from "../../styling";
import { errorHandler } from "../../containers";
import { ErrorHandler } from "../../containers";
import { gql } from "@apollo/client";
import { isValidMigrationCode, parseDate } from "../../utils";
import config from "../../config";
import { Migratiecode, MigratiecodeCapitalized } from "./migrationCodeName";
import {
  graphql,
  withApollo,
  WithApolloClient,
} from "@apollo/client/react/hoc";
import {
  MigratePersonMutation,
  MigratePersonMutationVariables,
  ValidateMigrationCodeQuery,
  ValidateMigrationCodeQueryVariables,
} from "generated/graphql";
import { ApolloClient, FetchResult } from "@apollo/client";
import { NavButtonContainer } from "components/dialog";
import { DialogContainer2 } from "components/dialog/container";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "left",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "20px",
  },
  orangeLabel: {
    paddingTop: "10px",
    fontSize: "18px",
    color: colorsBCV.secondary,
  },
};

type PropsGraphQLMigratePerson = {
  migratePerson: (
    input: MigratePersonMutationVariables["input"]
  ) => Promise<FetchResult<MigratePersonMutation>>;
};

type WrapperProps = {
  handleClose: () => void;
  addMigrationCode: (code: { value: string; name?: string | null }) => void;
};
type Props = WrapperProps &
  ErrorHandler &
  WithApolloClient<{}> &
  PropsGraphQLMigratePerson &
  WithRouterProps;

type State = {
  value: string;
  name: string | null;
  birthdate: string | null;
  error: string | null;
  codeVerified: boolean;
};

export const MIGRATIONCODE_QUERY = gql`
  query validateMigrationCode($code: String!, $id: ID!) {
    assocation(id: $id) {
      migrationCode(code: $code) {
        activated
        insuredPerson {
          id
          fullName
          birthdate
        }
      }
    }
  }
`;

export const MIGRATE_PERSON_MUTATION = gql`
  mutation migratePerson($input: MigratePersonInput!) {
    migratePerson(input: $input) {
      viewer {
        id
        firstName
        namePreposition
        lastName
      }
    }
  }
`;

const validateMigrationCode = (client: ApolloClient<{}>, code: string) => {
  return client.query<
    ValidateMigrationCodeQuery,
    ValidateMigrationCodeQueryVariables
  >({
    query: MIGRATIONCODE_QUERY,
    variables: {
      code,
      id: config.associationId,
    },
  });
};

export class MigrationDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: "",
      name: null,
      birthdate: null,
      error: null,
      codeVerified: false,
    };
  }

  _checkInput = async () => {
    let { value, name, birthdate, error } = this.state;
    if (value === "") {
      error = `Er is geen ${Migratiecode} ingevuld.`;
    } else if (!isValidMigrationCode(value)) {
      error = `De ingevoerde ${Migratiecode} is niet geldig.`;
    } else error = null;
    if (!error) {
      const result = await validateMigrationCode(this.props.client, value);
      if (
        !(
          result &&
          result.data &&
          result.data.assocation &&
          result.data.assocation.migrationCode
        )
      ) {
        error = `De ingevoerde ${Migratiecode} is incorrect.`;
      } else if (
        result &&
        result.data &&
        result.data.assocation &&
        result.data.assocation.migrationCode &&
        result.data.assocation.migrationCode.activated
      ) {
        error = `De ingevoerde ${Migratiecode} is niet meer geldig.`;
      } else {
        if (
          result &&
          result.data &&
          result.data.assocation &&
          result.data.assocation.migrationCode &&
          result.data.assocation.migrationCode.insuredPerson
        ) {
          error = null;
          name = result.data.assocation.migrationCode.insuredPerson.fullName;
          birthdate =
            result.data.assocation.migrationCode.insuredPerson.birthdate;
          this.setState({
            value,
            name,
            birthdate,
            error,
            codeVerified: true,
          });
          return;
        }
      }
    }
    this.setState({
      value,
      error,
    });
  };

  _activateCode = async () => {
    const { value, name } = this.state;
    try {
      const response = await this.props.migratePerson({
        migrationCode: value,
      });
      if (!response.data.migratePerson) {
        this.props.error.display();
      } else {
        this.props.addMigrationCode({ value, name });
        this.props.handleClose();
      }
    } catch (e) {
      this.props.error.display();
    }
  };

  render() {
    const { birthdate } = this.state;

    let content;
    if (!this.state.codeVerified) {
      content = (
        <>
          <h2>{MigratiecodeCapitalized}</h2>
          <p>Verifieer uw {Migratiecode}.</p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "30px",
              width: "100%",
            }}
          >
            <TextField
              // @ts-ignore
              id="migrationCode"
              floatingLabelText={MigratiecodeCapitalized}
              fullWidth={true}
              value={this.state.value}
              errorText={this.state.error}
              errorStyle={{ color: "red" }}
              onChange={(event: any) => {
                this.setState({ value: event.target.value.toLowerCase() });
              }}
              onKeyDown={(event: any) => {
                if (event.key === "Enter") {
                  this._checkInput();
                }
              }}
            />
          </div>
          <NavButtonContainer>
            <RaisedButton
              label="ANNULEER"
              backgroundColor={colors.white}
              labelStyle={{ color: "black" }}
              onClick={() => this.props.handleClose()}
            />
            <RaisedButton
              // @ts-ignore
              id="verifyMigrationcode"
              label="VERIFIEER"
              backgroundColor={colorsBCV.secondary}
              labelStyle={{ color: colors.white }}
              onClick={this._checkInput}
            />
          </NavButtonContainer>
        </>
      );
    } else {
      content = (
        <>
          <h2>{MigratiecodeCapitalized}</h2>
          <p>Verifieer de volgende gegevens:</p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "30px",
              width: "100%",
            }}
          >
            <div style={styles.orangeLabel}>{MigratiecodeCapitalized}</div>
            <div style={{ paddingBottom: "10px" }}>{this.state.value}</div>
            <div style={styles.orangeLabel}>Naam</div>
            <div style={{ paddingBottom: "10px" }}>{this.state.name}</div>
            <div style={styles.orangeLabel}>Geboortedatum</div>
            <div style={{ paddingBottom: "10px" }}>
              {birthdate ? parseDate(birthdate) : "Onbekend"}
            </div>
          </div>
          <NavButtonContainer>
            <RaisedButton
              label="ANNULEER"
              backgroundColor={colors.white}
              labelStyle={{ color: "black" }}
              onClick={() => this.setState({ codeVerified: false })}
            />
            <RaisedButton
              label="VERIFIEER"
              // @ts-ignore
              id="addPerson"
              backgroundColor={colorsBCV.secondary}
              labelStyle={{ color: colors.white }}
              onClick={this._activateCode}
            />
          </NavButtonContainer>
        </>
      );
    }

    return <DialogContainer2>{content}</DialogContainer2>;
  }
}

export const withMutation = graphql<
  WrapperProps & WithApolloClient<{}>,
  MigratePersonMutation,
  MigratePersonMutationVariables,
  PropsGraphQLMigratePerson
>(MIGRATE_PERSON_MUTATION, {
  props: ({ mutate }) => ({
    migratePerson: (input) =>
      mutate({
        variables: { input },
        refetchQueries: [
          {
            query: MIGRATIONCODE_QUERY,
            variables: { code: input.migrationCode, id: config.associationId },
          },
        ],
      }),
  }),
});

export const Wrapper = withApollo<WrapperProps, {}>(
  withMutation(withRouter(errorHandler(MigrationDialog)))
);
export default Wrapper;
