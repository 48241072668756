import { EditButton } from "components/button/buttons";
import { UpdatePaymentDialog } from "components/payment/updatePaymentDialog";
import { IpGroupingFragment } from "generated/graphql";
import { useState } from "react";

export const UpdatePaymentButton = ({
  insuredPersonIds,
  paymentInfo,
}: {
  insuredPersonIds: string[];
  paymentInfo: IpGroupingFragment["payment"];
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <EditButton onClick={() => setOpen(true)} />
      <UpdatePaymentDialog
        open={open}
        handleClose={() => setOpen(false)}
        insuredPersonIds={insuredPersonIds}
        paymentInfo={{
          accountHolderName: paymentInfo.accountHolder,
          iban: paymentInfo.iban,
          bic: paymentInfo.bic,
        }}
        description="Pas de betalingsgegevens aan voor alle leden in de groep."
      />
    </>
  );
};
