// @flow
/**
 * Created by Dirk-Jan Rutten on 01/11/16.
 */

import React from "react";
import { gql } from "@apollo/client";
import { colors } from "../../../styling";
import moment from "moment";
import { parseDate } from "../../../utils";

moment.locale("nl");

const stylesCard: { [key: string]: React.CSSProperties } = {
  paperElement: {
    height: "100%",
    width: "100%",
    textAlign: "center",
    paddingTop: "20px",
    paddingLeft: "28px",
    paddingRight: "28px",
    paddingBottom: "28px",
    fontWeight: 300,
    fontSize: "16px",
  },
  personInfoRowContainer: {
    paddingTop: "12px",
    marginLeft: "10px",
    marginRight: "10px",
    textAlign: "left",
  },
  personInfoText: {
    display: "flex",
    alignItems: "flex-end",
  },
  policyInfoRowContainer: {
    paddingTop: "12px",
    marginLeft: "40px",
    marginRight: "10px",
    textAlign: "left",
  },
  policyInfoLabel: {
    display: "flex",
    alignItems: "center",
    color: colors.lightGrey,
  },
  icon: {
    width: "16px",
    height: "16px",
  },
};

type PolicyState =
  | "PENDING"
  | "ACTIVE"
  | "CANCELLED"
  | "DEAD"
  | "ACCEPTED"
  | "REJECTED"
  | "DISCHARGE";
type PropsCard = {
  data: {
    id: string;
    package: {
      id: string;
      name: string;
    };
    insuredPerson: {
      id: string;
      memberId: string;
    };
    signupDate: string;
    signOffDate: string | null;
    state: PolicyState;
  };
};

function toDutchPolicyState(state: PolicyState) {
  switch (state) {
    case "PENDING":
      return "In afwachting";
    case "ACCEPTED":
      return "Geaccepteerd";
    case "REJECTED":
      return "Afgewezen";
    case "ACTIVE":
      return "Actief";
    case "DEAD":
      return "Overleden";
    case "CANCELLED":
      return "Opgezegd";
    case "DISCHARGE":
      return "Geroyeerd";
    default:
      throw new Error(
        "Expected state PENDING, ACTIVE, CANCELLED, ACCEPTED, REJECTED, DISCHARGE or DEAD"
      );
  }
}

function propsMapper(props: PropsCard) {
  let infoArray = [
    { info: props.data.insuredPerson.memberId, text: "Lidnummer" },
    { info: props.data.package.name, text: "Pakket" },
    { info: parseDate(props.data.signupDate), text: "Lid sinds" },
    { info: toDutchPolicyState(props.data.state), text: "Status lidmaatschap" },
  ];

  const signOffDate = props.data.signOffDate;

  if (signOffDate) {
    infoArray.push({
      info: parseDate(signOffDate),
      text: "Afgemeld op",
    });
  }

  return infoArray.map(function (object) {
    let content = (
      <div style={{ flex: 1, ...stylesCard.personInfoText }}>{object.info}</div>
    );

    return (
      <div
        key={object.text}
        style={{ display: "flex", ...stylesCard.policyInfoRowContainer }}
      >
        <div style={{ flex: 1, ...stylesCard.policyInfoLabel }}>
          {object.text}
        </div>
        {content}
      </div>
    );
  });
}

export const policyInfoFragment = gql`
  fragment clientCheckPolicy on Policy {
    id
    package {
      id
      name
    }
    insuredPerson {
      id
      memberId
    }
    signupDate
    signOffDate
    state
  }
`;

/**
 * Represents a 'card' that contains the data for a single policy.
 */
const PolicyCardPolicyInfo = (props: PropsCard) => (
  <div>{propsMapper(props)}</div>
);

export default PolicyCardPolicyInfo;
