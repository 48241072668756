// @flow

import { ApolloClient } from "@apollo/client";
import { gql } from "generated";
import {
  AddQuoteMutation,
  AddQuoteMutationVariables,
  Frequency,
} from "generated/graphql";

export type AddQuote = AddQuoteMutation["addQuote"];

/**
 * Retrieve a quote
 * @param birthdate The birthdate of the person for which the quote is retrieved
 * in format YYYY-MM-DD.
 * @param packageId The id of the package associated with the quote.
 * @param frequency The frequency of payments. Must be YEARLY or QUARTERLY.
 * @param client the apollo client used to perform the request
 */
const addQuoteAPI = async (
  birthdate: string,
  packageId: string,
  frequency: Frequency,
  client: ApolloClient<any>
): Promise<AddQuoteMutation["addQuote"]> => {
  const response = await client.mutate<
    AddQuoteMutation,
    AddQuoteMutationVariables
  >({
    mutation: gql(`
        mutation addQuote($input: AddQuoteInput!) {
          addQuote(input: $input) {
            quote {
              id
              birthdate
              premium
              lumpsum
              frequency
              package {
                id
                name
                insuredAmount
              }
            }
            quoteExists
          }
        }
      `),
    variables: { input: { birthdate, packageId, frequency } },
  });
  if (response.data?.addQuote == null) {
    throw new Error("Expected a response");
  }

  return response.data.addQuote;
};

export default addQuoteAPI;
