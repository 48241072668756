import React from "react";
import { colorsBCV, colors } from "../../../../styling";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import config from "../../../../config";
import { errorHandler } from "../../../../containers";
import type { ErrorHandler } from "../../../../containers";
import { isEmail } from "../../../../utils";
import { isEmailUnique, login } from "../../../../api";
import { graphql } from "@apollo/client/react/hoc";
import {
  UpdateUsernameMemberMutation,
  UpdateUsernameMemberMutationVariables,
} from "generated/graphql";
import { gql } from "@apollo/client";
import { DialogContainer2 } from "components/dialog/container";
import {
  NavButtonContainer,
  NavButtonContainerRightAligned,
} from "components/dialog";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  infoTitle: {
    paddingTop: "10px",
    fontSize: "18px",
    color: colorsBCV.secondary,
  },
};

type PropsParent = {
  infoUsername: {
    id: string;
    username: string;
  };
  handleClose: () => void;
};

type PropsGraphQL = {
  updateUsername: (newUsername: string, redirectUrl: string) => Promise<any>;
};

type Props = PropsParent & ErrorHandler & PropsGraphQL;

type State = {
  newUsername: string;
  password: string;
  usernameError: string | null;
  passwordError: string | null;
  inputFinished: boolean;
};

export class UsernameDialog extends React.Component {
  state: State;
  props: Props;

  constructor(props: Props) {
    super(props);
    this.state = {
      newUsername: "",
      password: "",
      usernameError: null,
      passwordError: null,
      inputFinished: false,
    };
  }

  async _checkInput() {
    const { newUsername, password } = this.state;
    const { updateUsername, infoUsername, error } = this.props;
    const { username } = infoUsername;
    if (
      newUsername !== "" &&
      isEmail(newUsername) &&
      newUsername !== username &&
      password !== ""
    ) {
      this.setState({
        usernameError: null,
        passwordError: null,
      });

      try {
        const isUnique = await isEmailUnique(newUsername);
        if (!isUnique) {
          this.setState({ usernameError: "Dit e-mailadres is al in gebruik." });
          return;
        }
        const loginCredentials = await login(username, password);
        if (!loginCredentials) {
          this.setState({ passwordError: "Het wachtwoord is niet correct." });
          return;
        }

        this.setState({ inputFinished: true });

        await updateUsername(newUsername, config.redirectUrl + "/activeren");
      } catch (e) {
        error.display();
      }
    } else {
      if (newUsername === "")
        this.setState({
          usernameError: "Er is geen e-mailadres opgegeven.",
        });
      else if (!isEmail(newUsername)) {
        this.setState({
          usernameError: "Het opgegeven e-mailadres is ongeldig.",
        });
      } else if (newUsername === this.props.infoUsername.username) {
        this.setState({
          usernameError: "Het opgegeven e-mailadres is niet veranderd.",
        });
      } else {
        this.setState({
          usernameError: null,
        });
      }
      if (password === "") {
        this.setState({
          passwordError: "Er is geen wachtwoord opgegeven.",
        });
      } else {
        this.setState({
          passwordError: null,
        });
      }
    }
  }

  render() {
    let { handleClose, infoUsername } = this.props;
    let { newUsername, inputFinished, usernameError, passwordError } =
      this.state;

    let content;
    if (inputFinished) {
      content = (
        <DialogContainer2>
          <p style={{ textAlign: "left" }}>
            Een e-mail is verzonden naar {infoUsername.username} om uw
            e-mailadres te verifiëren. <br /> Uw e-mailadres zal gewijzigd
            worden zodra uw e-mail is geverifieerd.
          </p>
          <NavButtonContainerRightAligned>
            <RaisedButton
              // @ts-ignore
              id="confirmVerify"
              label="Bevestig"
              primary={true}
              onClick={() => {
                handleClose();
              }}
            />
          </NavButtonContainerRightAligned>
        </DialogContainer2>
      );
    } else {
      content = (
        <DialogContainer2>
          <span style={styles.infoTitle}>Wat is uw nieuwe e-mailadres?</span>
          <TextField
            // @ts-ignore
            id="newUsername"
            name="email"
            fullWidth={true}
            type="text"
            defaultValue={newUsername}
            onChange={(event: any) => {
              this.setState({ newUsername: event.target.value });
            }}
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._checkInput();
              }
            }}
            errorText={usernameError}
          />
          <span style={styles.infoTitle}>Bevestig met uw wachtwoord.</span>
          <TextField
            // @ts-ignore
            id="password"
            name="password"
            fullWidth={true}
            type="password"
            errorText={passwordError}
            onChange={(event: any) => {
              this.setState({ password: event.target.value });
            }}
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._checkInput();
              }
            }}
          />
          <NavButtonContainer>
            <RaisedButton
              // @ts-ignore
              id="back"
              label="Annuleer"
              primary={false}
              onClick={handleClose}
            />
            <RaisedButton
              // @ts-ignore
              id="confirm"
              label="Bevestig"
              backgroundColor={colorsBCV.secondary}
              labelColor={colors.white}
              onClick={() => {
                this._checkInput();
              }}
            />
          </NavButtonContainer>
        </DialogContainer2>
      );
    }

    return content;
  }
}

export const UsernameFragments = gql`
  fragment UsernameDialog on Account {
    id
    username
  }
`;

export const UPDATE_USERNAME_MUTATION = gql`
  mutation updateUsernameMember($input: UpdateUsernameInput!) {
    updateUsername(input: $input) {
      account {
        id
        username
      }
    }
  }
`;

export const withMutation = graphql<
  PropsParent,
  UpdateUsernameMemberMutation,
  UpdateUsernameMemberMutationVariables,
  PropsGraphQL
>(UPDATE_USERNAME_MUTATION, {
  props: ({ mutate }) => ({
    updateUsername: (newUsername, redirectUrl) =>
      mutate({ variables: { input: { newUsername, redirectUrl } } }),
  }),
});

const Wrapped = withMutation(errorHandler(UsernameDialog));

export default Wrapped;
