// @flow

import React from "react";
import DefaultPage from "../../components/defaultPage";
import PageSection from "../../components/pageSection";

const PietersradePackages = () => (
  <DefaultPage>
    <PageSection title="Pakketten">
      <p>
        Wij als St. Pietersrade leveren twee dienstenpakketten aan voor de
        uitvaart van u als lid. Dit zijn het begraven pakket en het cremeren
        pakket. Ieder van deze pakketten worden hieronder nader toegelicht.
      </p>
      <p>
        Overzicht van de inhoud van ons dienstenpakket behorende bij{" "}
        <b>BEGRAVEN</b>
        <sup>*</sup>.
      </p>
      <ol>
        <li>de algehele regeling van de formaliteiten in Nederland;</li>
        <li>de verstrekking van een waardige (standaard) rouwkist.</li>
        <li>
          het vervoer van de overledene naar het mortuarium binnen Nederland in
          een straal van 40 km met de plaats van overlijden als middelpunt;
        </li>
        <li>
          de verzorging en de opbaring van de overledene in het mortuarium (NB:
          bij thuisopbaring zijn meerkosten (koeling e.d.) verschuldigd;
        </li>
        <li>het verblijf in het mortuarium van Monuta (max. 4 dagen);</li>
        <li>
          300 standaard gedachtenisprentjes en 75 standaard rouwbrieven met
          bijbehorende porto.
        </li>
        <li>uittreksel uit het register van de burgerlijke stand;</li>
        <li>
          vier dragers met uitvaartleider en de rouwauto gedurende de gehele
          uitvaartplechtigheid;
        </li>
        <li>de grafdelvingskosten tot een bepaald maximum.</li>
      </ol>
      <br />
      <p>
        Inhoud van ons dienstenpakket behorende bij <b>CREMEREN</b>
        <sup>*</sup>:
        <br />
        <br />
        Omdat de kosten en dus ook de daarvoor te betalen premie c.q. inkoopsom
        hoger is dan bij begraven worden hierbij vergoed de bovengenoemde punten
        (minus punt 9) aangevuld met:
      </p>
      <ol>
        <li>
          de crematiekosten van het crematorium Schifferheide te Kerkrade of
          Imstenrade te Heerlen;
        </li>
        <li>
          de beschikking over de aula in het crematorium voor maximaal 1 uur;
        </li>
        <li>
          het verblijf van de urn gedurende maximaal een jaar in het
          crematorium;
        </li>
        <li>
          het uitstrooien van de as op het terrein van het crematorium of op
          zee.
        </li>
      </ol>
      <br />
      <p>
        <sup>*</sup>Additionele voorwaarden:
      </p>
      <ul>
        <li>
          Bij beëindiging van het lidmaatschap worden ingelegde premies niet
          terug betaald;
        </li>
        <li>
          Voor diensten uit te voeren genoemde pakketten waarvan geen of slechts
          gedeeltelijk gebruik wordt gemaakt geen financiële vergoeding zal
          worden gegeven;
        </li>
        <li>
          Indien een begrafenis of crematie op een zaterdag plaatsvindt, kan dit
          leiden tot hogere kosten. Deze hogere kosten worden door St.
          Pietersrade echter vergoed;
        </li>
        <li>
          Bij overlijden in het buitenland worden de kosten van vervoer en de
          formaliteiten door een buitenlandse onderneming niet door de
          vereniging vergoed.
        </li>
      </ul>
    </PageSection>
  </DefaultPage>
);

export default PietersradePackages;
