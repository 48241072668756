import { useQuery } from "@apollo/client";
import { gql } from "generated";
import { UpdateAccountMemberMutationVariables } from "generated/graphql";
import { useMutationForApp } from "utils/withApollo";

export const useAccountQuery = (accountId: string) => {
  const { loading, error, data } = useQuery(AccountQuery, {
    variables: {
      accountId,
    },
  });

  if (error) throw error;

  return {
    loading,
    data,
  };
};

const AccountQuery = gql(`
query accountNode($accountId: ID!) {
  account(id: $accountId) {
    id
    ...AccountDetailsInfo
    ...InsuredPeopleList
  }
}

`);

const updateAccountMutation = gql(`
mutation updateAccount($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    clientMutationId
  }
}`);

export const useUpdateAccountMutation = (
  accountId: string,
  variables: UpdateAccountMemberMutationVariables
) =>
  useMutationForApp(updateAccountMutation, {
    variables: {
      ...variables,
      input: {
        ...variables.input,
        accountId,
      },
    },
    onCompleted: () => window.location.reload(),
  });
