// @flow
import { ApolloClient, gql } from "@apollo/client";

// import { apolloClientPublic as client } from "./apollo";

type ActivateAccount = {
  activateAccount: {
    activated: boolean;
  };
};

/**
 * Call the activate account API
 * @param username the username of the account
 * @param activationKey The activation key contained in the email sent when the account was created.
 * @return Boolean flag indicating whether or not the account has been previously activated.
 * A value of false indicates that the account has been successfully activated.
 */
const activateAccountAPI = async (
  username: string,
  activationKey: string,
  client: ApolloClient<{}>
): Promise<boolean> => {
  const response = await client.mutate<ActivateAccount>({
    mutation: gql`
      mutation activate($input: ActivateAccountInput!) {
        activateAccount(input: $input) {
          activated
        }
      }
    `,
    variables: {
      input: {
        username,
        activationKey,
      },
    },
  });

  return response.data.activateAccount.activated;
};

export default activateAccountAPI;
