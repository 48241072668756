// @flow

import React from "react";

import config, { AssociationType } from "../../../config";
import Warning from "./warning";

const { association } = config;

const WarningPerAssociation = () => {
  switch (association) {
    case AssociationType.PIETERSRADE:
      return <Warning />;
    case AssociationType.BCB:
      return <Warning />;
    case AssociationType.HULP_EN_STEUN:
      return <Warning />;
    default:
      throw new Error(`Unknown association type ${association}`);
  }
};

export default WarningPerAssociation;
