// @flow
import React from "react";

/**
 * A button that is resued in the login screen.
 */
const LoginButton = (props: any) => (
  <button
    style={{
      backgroundColor: "transparent",
      color: "white",
      border: "1px solid white",
      width: 160,
      height: 30,
    }}
    {...props}
  />
);

export default LoginButton;
