// @flow

import React from "react";
import DeleteConfirmationDialog from "./deleteConfirmationDialog";
import { WithRouterProps } from "react-router";
import { WithApolloClient } from "@apollo/client/react/hoc";
import { ErrorHandler } from "containers";
import { PropsDeleteInsuredPersonMutation } from "./container";
import { Button, Dialog } from "@mui/material";

export type PropsParentsDeleteInsuredPerson = {
  insuredPersonId: string;
};

type Props = PropsDeleteInsuredPersonMutation &
  WithApolloClient<{}> &
  PropsParentsDeleteInsuredPerson &
  WithRouterProps &
  ErrorHandler;

type State = {
  open: boolean;
  loading: boolean;
};

export default class DeleteInsuredPersonContainer extends React.Component<
  Props,
  State
> {
  state: State = {
    open: false,
    loading: false,
  };

  closeDialog = () => this.setState({ open: false });
  openDialog = () => this.setState({ open: true });

  deletedInsuredPerson = async () => {
    const { router, error, deleteInsuredPerson, client } = this.props;

    try {
      this.setState({ loading: true, open: false });

      await deleteInsuredPerson();

      this.setState({ loading: false });
      router.replace("/bestuurder/leden");
      client.resetStore();
    } catch (e) {
      this.setState({ loading: false });
      error.display();
    }
  };

  render() {
    return (
      <div>
        <Button
          color="error"
          onClick={this.openDialog}
          disabled={this.state.loading}
        >
          Verwijder lid
        </Button>
        <Dialog open={this.state.open} onClose={this.closeDialog}>
          <DeleteConfirmationDialog
            onCancel={this.closeDialog}
            confirmDelete={this.deletedInsuredPerson}
          />
        </Dialog>
      </div>
    );
  }
}
