// @flow

import React from "react";
import { colors } from "../../styling";

type Props = {
  state: "PENDING" | "ACCEPTED";
};

const style: React.CSSProperties = {
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  color: colors.grey,
};

const EmptyScreen = ({ state }: Props) => (
  <h2 style={style}>
    Er zitten geen {state === "PENDING" ? "afwachtende" : "geaccepteerde"}{" "}
    lidmaatschappen in de database.
  </h2>
);

export default EmptyScreen;
