// @flow

import React from "react";
// $FlowFixMe
import { Editor } from "react-draft-wysiwyg";
// $FlowFixMe
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

type Props = {
  editorState: any;
  onEditorStateChange: (state: any) => void;
};

const EditorComponent = ({ editorState, onEditorStateChange }: Props) => (
  <Editor
    editorClassName="communication-editor"
    editorState={editorState}
    onEditorStateChange={onEditorStateChange}
    toolbar={{
      options: [
        "inline",
        "blockType",
        "fontSize",
        "list",
        "textAlign",
        "colorPicker",
        "link",
        "history",
      ],
      inline: {
        options: ["bold", "italic", "underline"],
      },
      blockType: {
        options: ["Normaal", "H1", "H2", "H3"],
      },
      list: {
        options: ["unordered", "ordered"],
      },
    }}
    localization={{ locale: "nl" }}
  />
);

export default EditorComponent;
