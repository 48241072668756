// @flow

import React from "react";
import { withRouter } from "react-router";

import TextField from "material-ui/TextField";
import LoginButton from "./button";
import { connect, ConnectedProps } from "react-redux";
import { updateUsername, updatePassword, LoginErrorEnum } from "../../actions";
import { State, Dispatch } from "../../reducers";

const styles: { [key: string]: React.CSSProperties } = {
  textfield: {
    color: "white",
    fontWeight: "normal",
  },
  loginTextfieldContainer: {
    height: 100,
  },
  error: {
    color: "red",
  },
  underlineFocus: {
    borderColor: "white",
  },
};

type StateProps = {
  username: string;
  password: string;
  loading: boolean;
  error: LoginErrorEnum | null;
};

type Props = {
  onLogin: (username: string, password: string) => void;
} & ReduxLoginConnectProps;

export const Login = (props: Props) => {
  const { onLogin, updateUsername, updatePassword, username, password, error } =
    props;

  let usernameError, passwordError, loginError;

  if (error) {
    switch (error) {
      case "FIELDS_MISSING":
        if (username.length === 0) usernameError = "Vul een gebruikersnaam in.";
        if (password.length === 0) passwordError = "Vul een wachtwoord in.";
        break;
      case "INVALID_EMAIL":
        usernameError = "De gebruikersnaam moet een email zijn.";
        break;
      case "INVALID_LOGIN":
        loginError = "Inloggen mislukt. Probeer het opnieuw.";
        break;
      default:
    }
  }

  return (
    <div>
      <div style={styles.loginTextfieldContainer}>
        <TextField
          // @ts-ignore
          id="username"
          floatingLabelText="E-mailadres"
          floatingLabelFixed={false}
          floatingLabelStyle={styles.textfield}
          underlineFocusStyle={styles.underlineFocus}
          inputStyle={styles.textfield}
          value={username}
          onChange={(event: any) => updateUsername(event.target.value)}
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              onLogin(username, password);
            }
          }}
          errorText={usernameError}
          errorStyle={styles.error}
        />
      </div>
      <div style={styles.loginTextfieldContainer}>
        <TextField
          // @ts-ignore
          id="password"
          floatingLabelText="Wachtwoord"
          floatingLabelFixed={false}
          floatingLabelStyle={styles.textfield}
          underlineFocusStyle={styles.underlineFocus}
          inputStyle={styles.textfield}
          value={password}
          onChange={(event: any) => updatePassword(event.target.value)}
          type="password"
          errorText={passwordError}
          errorStyle={styles.error}
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              onLogin(username, password);
            }
          }}
        />
      </div>
      <div id="error" style={{ ...styles.error, padding: "10px" }}>
        {loginError}
      </div>
      <LoginButton id="login" onClick={() => onLogin(username, password)}>
        Inloggen
      </LoginButton>
    </div>
  );
};

const dispatchToProps = (dispatch: Dispatch) => ({
  updateUsername: (username: string) => dispatch(updateUsername(username)),
  updatePassword: (password: string) => dispatch(updatePassword(password)),
});

const stateToProps = ({ login }: State): StateProps => ({
  loading: login.loading,
  error: login.error,
  username: login.username,
  password: login.password,
});

const loginConnect = connect(stateToProps, dispatchToProps);

type ReduxLoginConnectProps = ConnectedProps<typeof loginConnect>;

export default withRouter(loginConnect(Login));
