//@flow

import React, { Component } from "react";
import { filter } from "graphql-anywhere";
import CircularProgress from "material-ui/CircularProgress";
import { Step, Stepper, StepButton, StepLabel } from "material-ui/Stepper";
import config from "../../../config";
import Premium, {
  fragment as PremiumFragment,
} from "../signupStepperComponents/signupPremium";
import YourInfo from "../../../containers/policySignup/signupStepperComponents/signupYourInfo";
import { SignupAddress } from "../signupStepperComponents";
import Payment from "../../../containers/policySignup/signupStepperComponents/signupPayment";
import Account from "../../../containers/policySignup/signupStepperComponents/signupAccount";
import Overview, { fragment as overviewFragment } from "./signupOverview";
import EligibilityQuestions, {
  fragment as eligibilityFragment,
} from "../signupStepperComponents/signupEligibility";
import type { Dispatch, State as ReduxState } from "../../../reducers";
import { connect, ConnectedProps } from "react-redux";
import { setStepperIndex } from "../../../actions";
import { Error as ErrorComponent } from "../../../components/error";
import { gql } from "@apollo/client/core";
import { graphqlQuery } from "utils/withApollo";
import { SignupQuery, SignupQueryVariables } from "generated/graphql";
import { DataProps } from "@apollo/client/react/hoc";

type Association = {
  id: string;
  eligibilityQuestions: Array<{ id: string }>;
};

type Props = ConnectedProps<typeof connector> &
  DataProps<SignupQuery, SignupQueryVariables>;

const styles: { [key: string]: React.CSSProperties } = {
  stepContainer: {
    display: "flex",
    textAlign: "left",
    justifyContent: "center",
    minWidth: "300px",
  },
};

type State = {
  address: {
    postalCode: string;
    houseNumber: number;
    suffix: string | null;
    street: string;
    city: string;
    country: string;
  } | null;
};

const hasEligibilityQuestions = (association: any) =>
  association.eligibilityQuestions.length > 0;

class SignupStepperNewAccount extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      address: null,
    };
  }
  _backPressed = () => {
    const { index } = this.props;
    if (index > 0) this.props.setStepperIndex(index - 1);
  };

  _renderStep = (association: Association) => {
    const { index: step, setStepperIndex } = this.props;
    const { address } = this.state;

    const hasEligibility = hasEligibilityQuestions(association);

    const premium = <Premium data={filter(PremiumFragment, association)} />;
    const eligibility = (
      <EligibilityQuestions
        backPressed={this._backPressed}
        data={filter(eligibilityFragment, association)}
      />
    );
    const personal = <YourInfo backPressed={this._backPressed} />;
    const addressComp = (
      <SignupAddress
        backPressed={this._backPressed}
        nextPressed={(address) => {
          this.setState({
            address,
          });
          setStepperIndex(step + 1);
        }}
        inputAddress={this.state.address}
      />
    );
    const payment = <Payment backPressed={this._backPressed} />;
    const account = <Account backPressed={this._backPressed} />;

    let overview = null;

    if (address) {
      overview = (
        <Overview
          backPressed={this._backPressed}
          address={address}
          data={filter(overviewFragment, association)}
        />
      );
    }

    let steps = [premium];

    if (hasEligibility) {
      steps.push(eligibility);
    }

    steps.push(personal, addressComp, payment, account);
    if (overview) steps.push(overview);

    return steps[step];
  };

  _createStepLabels(hasEligibilityQuestions: boolean) {
    let titles = ["Contributie"];
    if (hasEligibilityQuestions) {
      titles.push("Gezondsheidsverklaring");
    }

    titles.push(
      "Persoonsgegevens",
      "Adres",
      "Betaalgegevens",
      "Account",
      "Overzicht"
    );

    const steps = titles.map((title, index) =>
      this._createStepLabelItem(index, title)
    );
    return steps;
  }

  _createStepLabelItem(index: number, title: string) {
    const { setStepperIndex } = this.props;
    return (
      <Step key={index}>
        <StepButton
          onClick={() => setStepperIndex(index)}
          // @ts-ignore
          iconContainerStyle={{ padding: "0px" }}
        >
          <StepLabel
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {title}
          </StepLabel>
        </StepButton>
      </Step>
    );
  }

  render() {
    const {
      index,
      data: { loading, error, node },
    } = this.props;

    if (loading) {
      return <CircularProgress />;
    } else if (error || !node) {
      return <ErrorComponent />;
    } else {
      if (node.__typename !== "Association") throw new Error();

      return (
        <div style={{ paddingBottom: "40px" }}>
          <Stepper activeStep={index} linear={true} orientation="horizontal">
            {this._createStepLabels(hasEligibilityQuestions(node))}
          </Stepper>
          <div style={styles.stepContainer}>{this._renderStep(node)}</div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state: ReduxState) => {
  const {
    signup: { stepper },
  } = state;
  return {
    index: stepper.stepperIndex,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setStepperIndex: (index: number) => dispatch(setStepperIndex(index)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const QUERY = gql`
  query Signup($associationId: ID!) {
    node(id: $associationId) {
      id
      ... on Association {
        eligibilityQuestions {
          id
        }
        ...SignupPremiumFragment
        ...EligibilityQuestionFragment
        ...FinalCardFragment
      }
    }
  }

  ${PremiumFragment}
  ${eligibilityFragment}
  ${overviewFragment}
`;

const queryHOC = graphqlQuery<
  ConnectedProps<typeof connector>,
  SignupQuery,
  SignupQueryVariables
>(QUERY, {
  options: () => ({ variables: { associationId: config.associationId } }),
});

export default connector(queryHOC(SignupStepperNewAccount));
