// @flow

import React from "react";
import { gql } from "@apollo/client";

import SectionTitle from "../../components/sectionTitle";
import ContentRow from "../../components/contentRow";
import Separator from "../../components/rowSeparator";
import KeyLabel from "../../components/keyLabel";
import ValueLabel from "../../components/valueLabel";
import EditButton from "../../components/editButton";
import { parseAmount, formatPrice } from "../../../../../utils";

import "./details.css";

const formatPremium = (premium: number) => formatPrice(parseAmount(premium));

type Props = {
  data: {
    premium: number;
    package: {
      name: string;
    };
  };
  onTapPackage: () => void;
};

const PackageDetails = (props: Props) => {
  const { premium } = props.data;
  const { name: packageName } = props.data.package;

  return (
    <div>
      <Title onTapPackage={props.onTapPackage} />
      <PackageInfo name={packageName} />
      <Separator />
      <PremiumInfo premium={formatPremium(premium)} />
    </div>
  );
};
export default PackageDetails;

export const packageDetailsFragment = gql`
  fragment PolicyPackageDetails on Policy {
    premium
    package {
      id
      name
    }
  }
`;

const Title = ({ onTapPackage }: { onTapPackage: () => void }) => (
  <div className="horizontal-align">
    <SectionTitle>Pakketinformatie</SectionTitle>
    <EditButton
      // @ts-ignore
      id="editPackageButton"
      onClick={onTapPackage}
      data-test="edit_package_button"
    />
  </div>
);

const PackageInfo = ({ name }: { name: string }) => (
  <ContentRow>
    <KeyLabel>Pakket</KeyLabel>
    <ValueLabel>{name}</ValueLabel>
  </ContentRow>
);

const PremiumInfo = ({ premium }: { premium: string }) => (
  <ContentRow>
    <KeyLabel>Contributie</KeyLabel>
    <ValueLabel>{premium}</ValueLabel>
  </ContentRow>
);
