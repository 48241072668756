// @flow

import React from "react";
import DefaultPage from "../components/defaultPage";
import PageSection from "../components/pageSection";

const PackagesHulpEnSteun = () => (
  <DefaultPage>
    <PageSection title="Pakketten">
      <p>
        Hulp en Steun is een kostende prijsvereniging. Dit betekent dat de
        waarde van het uitvaartpakket en de premies worden aangepast naarmate de
        kosten stijgen. U bent dus nooit onderverzekerd.
      </p>
      <p>
        <b>
          Hulp en Steun biedt een drietal soorten verzekeringsproducten aan:
        </b>
      </p>
      <ol>
        <li>
          <b>Pakket voor begraven</b>
        </li>
        <li>
          <b>Pakket voor crematie</b>
        </li>
        <li>
          <b>Aanvullend verzekeren</b>: Leden niet ouder dan 60 jaar met een
          naturaverzekering kunnen zich aanvullend verzekeren voor kosten die
          niet in de pakketten zitten tot een bedrag van € 5.000,-. De bedragen
          worden niet geïndexeerd.
        </li>
      </ol>

      <b>Pakket voor begraven</b>
      <ol>
        <li>
          Vervoer van de overledene naar het mortuarium binnen Nederland, indien
          het overlijden buiten de woonplaats heeft plaatsgevonden met een
          maximum van 50 km
        </li>
        <li>grafkist (verenigingsmodel)</li>
        <li>verzorging van de overledene</li>
        <li>opbaarkosten maximaal 4 dagen</li>
        <li>rouwauto</li>
        <li>4 dragers en een leider bij de uitvaartplechtigheid</li>
        <li>grafdelvings-kosten</li>
        <li>de algehele regeling van de formaliteiten in Nederland</li>
        <li>rouwdrukwerk: 50 rouwbrieven met porto</li>
        <li>
          200 gedachtenisprentjes conform de bij de vereniging gebruikelijke
          types
        </li>
        <li>door het bestuur vast te stellen vrij besteedbaar bedrag</li>
        <li>uittreksel gemeente</li>
      </ol>
      <br />
      <b>Pakket voor crematie</b>
      <ol>
        <li>
          vervoer van de overledene naar het mortuarium binnen Nederland, indien
          het overlijden buiten de woonplaats heeft plaatsgevonden met een
          maximum van 50 km
        </li>
        <li>crematiekist (verenigingsmodel)</li>
        <li>verzorging van de overledene</li>
        <li>opbaarkosten maximaal 4 dagen</li>
        <li>rouwauto</li>
        <li>4 dragers en een leider bij de uitvaartplechtigheid</li>
        <li>rouwdrukwerk: 50 rouwbrieven met porto</li>
        <li>
          200 gedachtenisprentjes conform de bij de vereniging gebruikelijke
          types
        </li>
        <li>de algehele regeling van formaliteiten in Nederland</li>
        <li>rouwauto naar het dichtstbijzijnde crematorium</li>
        <li>door het bestuur vast te stellen vrij besteedbaar bedrag</li>
        <li>
          de crematie, de eventuele asbijzetting in de algemene nis voor
          maximaal 1/2 jaar en de asverstrooiing zonder familie
        </li>
        <li>gebruik van de aula met muziek in het crematorium</li>
        <li>gebruik van de condoleanceruimte in het crematorium</li>
        <li>uittreksel gemeente</li>
      </ol>
    </PageSection>
  </DefaultPage>
);

export default PackagesHulpEnSteun;
