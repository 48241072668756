import React from "react";

type Props = {
  title: string;
};

const SectionTitle = (props: Props) => (
  <h4
    style={{
      marginTop: 10,
      marginBottom: 10,
    }}
  >
    {props.title}
  </h4>
);

export default SectionTitle;
