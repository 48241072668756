// @flow

import config, { AssociationType } from "../../config";

const { association } = config;

export const MigratiecodeCapitalized = (() => {
  switch (association) {
    case AssociationType.BCB:
    case AssociationType.HULP_EN_STEUN:
      return "Migratiecode";
    case AssociationType.PIETERSRADE:
      return "Toegangscode";
    default:
      throw new Error(`Unknown association type ${association}`);
  }
})();

export const Migratiecode = (() => {
  switch (association) {
    case AssociationType.BCB:
    case AssociationType.HULP_EN_STEUN:
      return "migratiecode";
    case AssociationType.PIETERSRADE:
      return "toegangscode";
    default:
      throw new Error(`Unknown association type ${association}`);
  }
})();
