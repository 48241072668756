// @flow

export enum PaymentMethod {
  DIRECT_DEBIT = "DIRECT_DEBIT",
  INVOICE = "INVOICE",
  PERIODIC_TRANSFER = "PERIODIC_TRANSFER",
}

export enum GraphQLPolicyStateType {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  DEAD = "DEAD",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  DISCHARGE = "DISCHARGE",
}

export enum GraphQLSearchType {
  POSTAL_CODE = "POSTAL_CODE",
  MEMBER_ID = "MEMBER_ID",
  ADDRESS = "ADDRESS",
}

export enum GraphQLMatchType {
  EXACT = "EXACT",
  STARTS_WITH = "STARTS_WITH",
}
