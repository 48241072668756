//@flow

import React, { Component } from "react";
import RaisedButton from "material-ui/RaisedButton";
import { colors } from "../../../../../../styling";
import PendingDialogAbstract from "./pendingDialogAbstract";
import { Dialog } from "@mui/material";

type PropsParent = { policyId: string };
type Props = PropsParent;
type State = { show: boolean; state: "ACCEPTED" | "REJECTED" };

class PendingState extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { show: false, state: "ACCEPTED" };
  }

  render() {
    const { policyId } = this.props;

    const handleClose = () => this.setState({ show: false });
    return (
      <div>
        <p>
          Dit lid heeft een lidmaatschap afgesloten. Accepteer of weiger deze
          aanvraag.
        </p>
        <div style={{ textAlign: "center" }}>
          <RaisedButton
            label="Weiger"
            onClick={() =>
              this.setState({
                show: true,
                state: "REJECTED",
              })
            }
            style={{ margin: 5 }}
          />
          <RaisedButton
            label="Accepteer"
            backgroundColor={colors.green}
            labelStyle={{ color: "white" }}
            onClick={() =>
              this.setState({
                show: true,
                state: "ACCEPTED",
              })
            }
            style={{ margin: 5 }}
          />
        </div>
        <Dialog
          open={this.state.show}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
        >
          <PendingDialogAbstract
            handleClose={handleClose}
            policyId={policyId}
            newState={this.state.state}
          />
        </Dialog>
      </div>
    );
  }
}

export default PendingState;
