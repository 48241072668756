//@flow

import React, { Component } from "react";
import RaisedButton from "material-ui/RaisedButton";
import { colors } from "../../../../../../styling";
import AcceptedDialog from "./acceptedDialog";
import { Dialog } from "@mui/material";

type Props = {
  policyId: string;
  signatureDate: string | null;
};

type State = {
  show: boolean;
};

export default class AcceptedState extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  _handleClose: () => void = () => {
    this.setState({ show: false });
  };

  render() {
    const { show } = this.state;
    const { policyId } = this.props;
    const disable = this.props.signatureDate == null;
    return (
      <div>
        <p>Is het SEPA formulier binnen voor dit lid? Activeer dan het lid.</p>
        <div style={{ textAlign: "center" }}>
          <RaisedButton
            label="Activeer"
            backgroundColor={colors.green}
            labelStyle={{ color: "white" }}
            onClick={() => {
              this.setState({ show: true });
            }}
            disabled={disable}
          />
        </div>
        <Dialog open={show}>
          <AcceptedDialog handleClose={this._handleClose} policyId={policyId} />
        </Dialog>
      </div>
    );
  }
}
