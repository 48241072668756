// @flow

import React from "react";
import BcbBoardPage from "./bcb";
import PietersradeBoardPage from "./pietersrade";

import config, { AssociationType } from "../../../config";

const { association } = config;

const BoardPage = () => {
  switch (association) {
    case AssociationType.BCB:
      return <BcbBoardPage />;
    case AssociationType.PIETERSRADE:
      return <PietersradeBoardPage />;

    default:
      throw new Error(`Unknown association type ${association}`);
  }
};

export default BoardPage;
