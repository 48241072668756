// @flow

import React from "react";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import { colorsBCV, colors } from "../../../../styling";
import { errorHandler, ErrorHandler } from "../../../../containers";
import { validatePhone } from "../../../../utils";
import { UPDATE_ACCOUNT_MUTATION } from "./mutation";
import { graphql } from "@apollo/client/react/hoc";
import {
  UpdateAccountMemberMutation,
  UpdateAccountMemberMutationVariables,
} from "generated/graphql";
import { DialogContainer2 } from "components/dialog/container";
import { NavButtonContainer } from "components/dialog";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  infoTitle: {
    fontSize: "18px",
    color: colorsBCV.secondary,
  },
};

type PropsGraphQL = {
  updateAccount: (phone: string) => Promise<any>;
};

type PropsParents = {
  accountId: string;
  handleClose: () => void;
};

type Props = PropsParents & PropsGraphQL & ErrorHandler;

type State = { newPhone: string; phoneError: string | null };

export class UpdatePhone extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      newPhone: "",
      phoneError: null,
    };
  }

  async _checkInput() {
    const { error, handleClose, updateAccount } = this.props;
    const { newPhone } = this.state;
    let phoneError = null;

    if (newPhone.length === 0) {
      phoneError = "Er is geen telefoonnummer ingevuld.";
    } else if (!validatePhone(newPhone)) {
      phoneError = "Het opgegeven telefoonnummer is onjuist.";
    }

    this.setState({
      phoneError,
    });

    if (phoneError == null) {
      try {
        await updateAccount(newPhone);
        this.setState({
          newPhone: "",
        });
        handleClose();
      } catch (e) {
        error.display();
      }
    }
  }

  render() {
    const { handleClose } = this.props;
    const { newPhone } = this.state;

    return (
      <DialogContainer2>
        <span style={styles.infoTitle}>Wat is uw nieuwe telefoonnummer?</span>
        <TextField
          // @ts-ignore
          id="phone"
          defaultValue={newPhone}
          errorText={this.state.phoneError}
          name="phone"
          fullWidth={true}
          type="text"
          onChange={(event: any) => {
            this.setState({ newPhone: event.target.value });
          }}
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this._checkInput();
            }
          }}
        />
        <NavButtonContainer>
          <RaisedButton
            // @ts-ignore
            id="back"
            label="Annuleren"
            primary={false}
            onClick={() => {
              this.setState({ newPhone: "", phoneError: null });
              handleClose();
            }}
          />
          <RaisedButton
            // @ts-ignore
            id="confirm"
            label="Bevestig"
            backgroundColor={colorsBCV.secondary}
            labelColor={colors.white}
            onClick={() => {
              this._checkInput();
            }}
          />
        </NavButtonContainer>
      </DialogContainer2>
    );
  }
}

export const withMutation = graphql<
  PropsParents,
  UpdateAccountMemberMutation,
  UpdateAccountMemberMutationVariables,
  PropsGraphQL
>(UPDATE_ACCOUNT_MUTATION, {
  props: ({ mutate, ownProps }) => {
    const { accountId } = ownProps;
    if (!accountId) {
      throw new Error(
        `Expected accountId to be passed in the props but got ${accountId}`
      );
    }
    return {
      updateAccount: (phone) =>
        mutate({
          variables: { input: { phone } },
          optimisticResponse: {
            // @ts-ignore
            user: {
              id: accountId,
              phone,
            },
          },
        }),
    };
  },
});

export default withMutation(errorHandler(UpdatePhone));
