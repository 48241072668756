// @flow

import React from "react";
import { Frequency } from "generated/graphql";
import { formatToEuro } from "../../../../utils";
import RaisedButton from "material-ui/RaisedButton";
import { colors } from "../../../../styling";
import TextField from "material-ui/TextField";
import { VerticalSeparator } from "../../../../components/layout";
import { FrequencySelector2 } from "../shared/frequency";
import { NavButtonContainer, Title } from "../../../../components/dialog";
import {
  composeValidators,
  mustBeNumber,
  mustBeLargerThanOrEqualTo,
  mustBeSmallerThanOrEqualTo,
  required,
  mustBeDate,
  mustBeAmount,
} from "../../../../utils/validator";
import {
  withAdditionalInsuranceQuoteMutation,
  withUpdateAdditionalInsurance,
} from "./graphql";
import type {
  AdditionalQuoteResult,
  PropsGraphQLAdditionalInsuranceQuote,
  PropsUpdateAdditionalInsurance,
} from "./graphql";
import { errorHandler, ErrorHandler } from "../../../../containers/index";
import {
  PremiumInputPicker,
  PremiumInput,
} from "./components/premiumInputPicker";
import {
  formatFrequency2,
  parseAmount,
  parseDate,
  parseJSDate,
  serializeAmount,
  serializeDate,
} from "utils/formatter";
import { Dialog } from "@mui/material";
import { DialogContainer2 } from "components/dialog/container";

const { green, white } = colors;

type State = {
  showUpdateAdditionalInsurance: boolean;
  insuredAmount: string;
  insuredAmountError: string | null;
  selectedFrequency: Frequency;
  result: AdditionalQuoteResult | null;
  loading: boolean;
  input: PremiumInput;
  premiumInput: string;
  premiumInputError: string | null;
  startDate: string;
  startDateError: string | null;
};

const insuredAmountValidator = (
  minInsuredAmount: number,
  maxInsuredAmount: number
) =>
  composeValidators(
    required,
    mustBeNumber,
    mustBeLargerThanOrEqualTo(minInsuredAmount),
    mustBeSmallerThanOrEqualTo(maxInsuredAmount)
  );
const premiumValidator = composeValidators(required, mustBeAmount);

export type PropsParentsUpdateAdditionalInsurance = {
  policyId: string;
  disabled: boolean;
  initialInsuredAmount: string | null;
  initialFrequency: Frequency | null;
  maxInsuredAmount: number;
  minInsuredAmount: number;
  endDate: string | null;
  startDate: string | null;
  premium: string | null;
};

type Props = PropsParentsUpdateAdditionalInsurance &
  PropsGraphQLAdditionalInsuranceQuote &
  PropsUpdateAdditionalInsurance &
  ErrorHandler;

class UpdateAdditionalInsuranceButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showUpdateAdditionalInsurance: false,
      insuredAmount: props.initialInsuredAmount
        ? props.initialInsuredAmount
        : "",
      insuredAmountError: null,
      selectedFrequency: props.initialFrequency
        ? props.initialFrequency
        : Frequency.Quarterly,
      result: null,
      loading: false,
      input: props.premium ? PremiumInput.CUSTOM : PremiumInput.STANDARD,
      premiumInput: props.premium ? parseAmount(Number(props.premium)) : "",
      premiumInputError: null,
      startDate: props.startDate ? parseDate(props.startDate) : parseJSDate(new Date()),
      startDateError: null,
    };
  }

  close = () => this.setState({ showUpdateAdditionalInsurance: false });

  calculatePremium = async (premium: number) => {
    const { insuredAmount, selectedFrequency, startDate } = this.state;

    this.setState({ loading: true });
    try {
      await this.props.updateAdditionalInsurance({
        policyId: this.props.policyId,
        insuredAmount: Number(insuredAmount),
        frequency: selectedFrequency,
        premium: premium,
        startDate: serializeDate(startDate),
      });
      window.location.reload();
    } catch (e) {
      this.props.error.display();
    } finally {
      this.setState({ loading: false });
      return;
    }
  };

  getQuote = async () => {
    const { insuredAmount, selectedFrequency } = this.state;
    this.setState({ loading: true });

    try {
      const { addAdditionalInsuranceQuoteFromPolicy } =
        await this.props.getQuote({
          policyId: this.props.policyId,
          insuredAmount: Number(insuredAmount),
          frequency: selectedFrequency,
        });

      return addAdditionalInsuranceQuoteFromPolicy;
    } catch (err) {
      this.props.error.display();
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      result,
      showUpdateAdditionalInsurance,
      insuredAmount,
      insuredAmountError,
      selectedFrequency,
      loading,
      input,
      premiumInput,
      premiumInputError,
    } = this.state;

    return (
      <div>
        <RaisedButton
          label="WIJZIG"
          backgroundColor={green}
          labelStyle={{ color: white }}
          onClick={() => this.setState({ showUpdateAdditionalInsurance: true })}
          disabled={this.props.disabled}
        />
        <Dialog open={showUpdateAdditionalInsurance} onClose={this.close}>
          <DialogContainer2>
            <Title>
              {`Additionele verzekering ${
                this.props.endDate == null && this.props.initialInsuredAmount
                  ? "wijzigen"
                  : "toevoegen"
              }`}{" "}
            </Title>
            <PremiumInputPicker
              selected={this.state.input}
              onChange={(input) => this.setState({ input, result: null })}
            />
            {input === PremiumInput.CUSTOM && (
              <TextField
                floatingLabelText={`Aangepaste premie die betaald moet worden ${formatFrequency2(
                  selectedFrequency
                ).toLocaleLowerCase()}`}
                name="premium"
                errorText={premiumInputError}
                value={premiumInput}
                fullWidth
                onChange={(event: any) =>
                  this.setState({
                    premiumInput: event.target.value,
                    premiumInputError: null,
                    result: null,
                  })
                }
              />
            )}
            <TextField
              floatingLabelText={"Verzekerd bedrag"}
              name="insuredAmount"
              errorText={insuredAmountError}
              value={insuredAmount}
              fullWidth
              onChange={(event: any) =>
                this.setState({
                  insuredAmount: event.target.value,
                  insuredAmountError: null,
                  result: null,
                })
              }
            />
            <TextField
              floatingLabelText={"Start datum"}
              name="startDate"
              errorText={this.state.startDateError}
              value={this.state.startDate}
              fullWidth
              onChange={(event: any) =>
                this.setState({
                  startDate: event.target.value,
                  startDateError: null,
                  result: null,
                })
              }
            />
            <FrequencySelector2
              frequency={selectedFrequency}
              setFrequency={(selectedFrequency) =>
                this.setState({ selectedFrequency, result: null })
              }
            />

            <VerticalSeparator distance={2} />
            {result && (
              <QuoteResult result={result} frequency={selectedFrequency} />
            )}

            <NavButtonContainer>
              <RaisedButton
                // @ts-ignore
                id="back"
                label="Annuleren"
                primary={false}
                onClick={this.close}
              />
              <RaisedButton
                // @ts-ignore
                id="confirm"
                label={
                  this.state.input === PremiumInput.CUSTOM
                    ? "Bevestig"
                    : result
                    ? "Bevestig"
                    : "Bereken premie"
                }
                backgroundColor={green}
                labelColor={white}
                disabled={
                  loading || (result != null && result.quoteExists === false)
                }
                onClick={async () => {
                  const insuredAmountError = insuredAmountValidator(
                    this.props.minInsuredAmount,
                    this.props.maxInsuredAmount
                  )(insuredAmount);

                  const startDateError = composeValidators(
                    required,
                    mustBeDate
                  )(this.state.startDate);

                  let premiumInputError = premiumValidator(premiumInput);
                  if (input === PremiumInput.STANDARD) premiumInputError = null;

                  if (
                    insuredAmountError != null ||
                    premiumInputError != null ||
                    startDateError != null
                  ) {
                    this.setState({
                      insuredAmountError,
                      premiumInputError,
                      startDateError,
                    });
                    return;
                  }

                  if (input === PremiumInput.CUSTOM) {
                    await this.calculatePremium(serializeAmount(premiumInput));
                  } else if (result != null) {
                    await this.calculatePremium(result.premium);
                  } else {
                    const addAdditionalInsuranceQuoteFromPolicy =
                      await this.getQuote();
                    this.setState({
                      insuredAmountError,
                      result: addAdditionalInsuranceQuoteFromPolicy,
                    });
                  }
                }}
              />
            </NavButtonContainer>
          </DialogContainer2>
        </Dialog>
      </div>
    );
  }
}

const UpdateAdditionalInsuranceButtonContainer = withUpdateAdditionalInsurance(
  withAdditionalInsuranceQuoteMutation(
    errorHandler(UpdateAdditionalInsuranceButton)
  )
);

const QuoteResult = ({
  result,
  frequency,
}: {
  result: AdditionalQuoteResult;
  frequency: Frequency;
}) => {
  if (result.quoteExists === false) {
    return (
      <div style={{ padding: "8px" }}>
        De premietabel bevat geen premie voor deze polis
      </div>
    );
  }

  return (
    <div style={{ padding: "8px" }}>
      Berekende premie van{" "}
      <b>
        {formatToEuro(result.premium)}{" "}
        {formatFrequency2(frequency).toLocaleLowerCase()}
      </b>
      , druk op bevestig om de additionele verzekeren te updaten.
    </div>
  );
};

export default UpdateAdditionalInsuranceButtonContainer;
