// @flow
import React from "react";

const RowSeparator = () => (
  <div
    style={{
      paddingTop: "8px",
    }}
  />
);

export default RowSeparator;
