import React, { Component } from "react";
import TextField from "material-ui/TextField";
import { colors } from "./../../styling/index";
import { parseJSDate, serializeDate } from "utils/formatter";
import { composeValidators, mustBeDate, required } from "utils/validator";

const styles: { [key: string]: React.CSSProperties } = {
  inputFieldDiv: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
};

type State = {
  signupDate: string;
  errorSignupDate: string | null;
};

type Props = {
  onEnter: Function;
};

export class PolicyInfo extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      signupDate: parseJSDate(new Date()),
      errorSignupDate: null,
    };
  }

  getInput(): {
    signupDate: string;
  } {
    const { signupDate } = this.state;

    return {
      signupDate: serializeDate(signupDate),
    };
  }

  /**
   * Test whether the components of this class are valid.
   * Within the class, we check for errors, thus return !error = valid.
   */
  validate(): boolean {
    const validator = composeValidators(required, mustBeDate);

    const error = validator(this.state.signupDate);

    this.setState({
      errorSignupDate: error,
    });

    return error == null;
  }

  render() {
    const { signupDate, errorSignupDate } = this.state;

    return (
      <div style={styles.inputFieldDiv}>
        <TextField
          value={signupDate}
          floatingLabelText="Inschrijfdatum"
          floatingLabelFocusStyle={{ color: colors.green }}
          underlineFocusStyle={{ borderColor: colors.green }}
          errorText={errorSignupDate}
          onChange={(event: any) =>
            this.setState({ signupDate: event.target.value })
          }
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this.props.onEnter();
            }
          }}
          fullWidth
        />
      </div>
    );
  }
}
