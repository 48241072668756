// @flow

import React from "react";
import { NavButtonContainer } from "../../../components/dialog";
import RaisedButton from "material-ui/RaisedButton";

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  disableNavigation: boolean;
  associationName: string;
};

const ConfirmMessage = (props: Props) => {
  const { onConfirm, onCancel, disableNavigation, associationName } = props;

  return (
    <div>
      <p>
        Druk op <b>BEVESTIG</b> om een overlijden te melden aan het bestuur van{" "}
        {associationName}.
      </p>
      <NavButtonContainer>
        <RaisedButton
          // @ts-ignore
          id="back"
          label="Annuleren"
          onClick={onCancel}
          disabled={disableNavigation}
        />
        <RaisedButton
          // @ts-ignore
          id="confirm"
          label="Bevestig"
          primary={true}
          disabled={disableNavigation}
          onClick={onConfirm}
        />
      </NavButtonContainer>
    </div>
  );
};

export default ConfirmMessage;
