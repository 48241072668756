// @flow
/**
 * Created by Dirk-Jan Rutten on 31/10/16.
 */

import React from "react";
import AanmeldingenCard, { RegistrationCardFragment } from "./registrationCard";
import { colors } from "../../styling";
import { gql } from "@apollo/client";
import { filter } from "graphql-anywhere";

const stylesList = {
  gridRowContainer: {
    textAlign: "center",
    justifyContent: "left",
    alignItems: "center",
    display: "flex",
    width: "900px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "30px",
  } as React.CSSProperties,
  emptyScreen: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    color: colors.grey,
    fontSize: "40px",
    fontWeight: 300,
  },
};

type Props = {
  data: {
    edges: Array<{
      node: {
        id: string;
      };
    }>;
  };
  state: "PENDING" | "ACCEPTED";
  onDetails: (policyId: string) => void;
};

/**
 * Component that displays a list of policies.
 */
const AanmeldingenList = ({ data, onDetails, state }: Props) => {
  let rows = [];
  const policies = data.edges;
  const numPolicies = policies.length;

  const onDetailsCard = (policyId: string) => onDetails(policyId);

  for (let i = 0; i < numPolicies; ) {
    if (numPolicies - i > 1) {
      rows.push(
        <div key={i} style={stylesList.gridRowContainer}>
          <div style={{ flex: 1, paddingRight: "15px" }}>
            <AanmeldingenCard
              aanmeldInfo={filter(RegistrationCardFragment, policies[i].node)}
              onDetails={onDetailsCard}
            />
          </div>
          <div style={{ flex: 1, paddingLeft: "15px" }}>
            <AanmeldingenCard
              aanmeldInfo={filter(
                RegistrationCardFragment,
                policies[i + 1].node
              )}
              onDetails={onDetailsCard}
            />
          </div>
        </div>
      );
    } else {
      rows.push(
        <div key={i} style={stylesList.gridRowContainer}>
          <div style={{ flex: 1, paddingRight: "15px" }}>
            <AanmeldingenCard
              aanmeldInfo={filter(RegistrationCardFragment, policies[i].node)}
              onDetails={onDetailsCard}
            />
          </div>
          <div style={{ flex: 1, paddingRight: "15px" }} />
        </div>
      );
      break;
    }

    i += 2;
  }

  return <div>{rows}</div>;
};

AanmeldingenList.fragments = {
  aanmeldList: gql`
    fragment AanmeldingenList on PolicyConnection {
      edges {
        node {
          ...RegistrationCardFragment
        }
      }
    }
    ${RegistrationCardFragment}
  `,
};

export default AanmeldingenList;
