// @flow

import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { showAlertDialog } from "../actions";
import { Action } from "../actions/alert";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  error: {
    display: (message?: string | null) => dispatch(showAlertDialog(message)),
  },
});
/**
 * This HOC is used to display a generic error message in the application.
 */
export const errorHandler = connect(null, mapDispatchToProps);

export type ErrorHandler = ConnectedProps<typeof errorHandler>;
