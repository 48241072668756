// @flow
/**
 * Created by Joshua Scheidt on 25-01-2017.
 */

import {
  addSignupEmail,
  addSignupFirstName,
  addSignupLastNamePrefix,
  addSignupLastName,
  addSignupGender,
  addSignupPassword,
  addSignupPasswordConfirm,
  addSignupInitials,
  setUserInfoState,
} from "../../../actions";
import { connect } from "react-redux";
import type { Dispatch, State } from "../../../reducers";
import SignupScreen from "../../../screens/policySignup/signupStepperComponents/signupYourInfo";

import type { UserState } from "../../../actions";

const mapStateToProps = (state: State) => {
  const {
    signup: {
      userInfo: { data, errors, isFetching },
    },
  } = state;
  return {
    firstName: data.firstName,
    lastNamePrefix: data.lastNamePrefix,
    lastName: data.lastName,
    gender: data.gender,
    initials: data.initials,
    errors,
    isFetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setEmail: (email: string) => dispatch(addSignupEmail(email)),
  setFirstName: (firstName: string) => dispatch(addSignupFirstName(firstName)),
  setLastNamePrefix: (lastNamePrefix: string) =>
    dispatch(addSignupLastNamePrefix(lastNamePrefix)),
  setLastName: (lastName: string) => dispatch(addSignupLastName(lastName)),
  setGender: (gender: "MALE" | "FEMALE") => dispatch(addSignupGender(gender)),
  setPassword: (password: string) => dispatch(addSignupPassword(password)),
  setPasswordConfirm: (password: string) =>
    dispatch(addSignupPasswordConfirm(password)),
  setInitials: (initials: string) => dispatch(addSignupInitials(initials)),
  // @ts-ignore
  nextPressed: (input: UserState) => dispatch(setUserInfoState(input)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const Wrapper = connector(SignupScreen);

export default Wrapper;
