import {
  Typography,
  Box,
  AppBar,
  CircularProgress,
  Toolbar,
  Button,
} from "@mui/material";
import { MenuButton, SideBar } from "components/header";

export const HeaderEventTimeline = ({
  loading,
  typeSelection,
  seeDescription,
  onToggleDescription,
}: {
  loading: boolean;
  typeSelection: any;
  seeDescription: boolean;
  onToggleDescription: () => void;
}) => {
  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <MenuButton />
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Mutatie Tijdslijn
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => onToggleDescription()}
          >
            beschrijving {!seeDescription ? "" : "niet "}tonen
          </Button>
          {typeSelection}
          {loading && <CircularProgress color="inherit" size={"1.5rem"} />}
        </Toolbar>
      </AppBar>
      <SideBar />
    </>
  );
};
