import RaisedButton from "material-ui/RaisedButton";

import { ErrorHandler, errorHandler } from "../../../../containers";
import { colors } from "../../../../styling";
import { VerticalSeparator } from "../../../../components/layout";
import TextField from "../../../../components/textfield";
import { NavButtonContainer } from "../../../../components/dialog";
import { required, composeValidators } from "../../../../components/form";
import { mustBeDate } from "utils/validator";
import { useForm } from "utils/useForm";
import { parseDate, serializeDate } from "utils";
import { UPDATE_INSURED_PERSON_MUTATION } from "./graphql";
import {
  UpdateInsuredPersonMutation,
  UpdateInsuredPersonMutationVariables,
} from "generated/graphql";
import { useMutationForApp } from "utils/withApollo";
import { DialogContainer2 } from "components/dialog/container";

const { green, white } = colors;

type Props = {
  insuredPersonId: string;
  birthdate: string;
  onClose: () => void;
} & ErrorHandler;

type FormData = {
  birthdate: string;
};

const BirtdateUpdate = (props: Props) => {
  const { errors, registerField, handleSubmit } = useForm<{
    birthdate: string;
  }>({
    initialValues: {
      birthdate: parseDate(props.birthdate),
    },
  });

  const [updateBirthdate, { loading }] = useMutationForApp<
    UpdateInsuredPersonMutation,
    UpdateInsuredPersonMutationVariables
  >(UPDATE_INSURED_PERSON_MUTATION, {
    onError: () => props.error.display(),
    onCompleted: () => window.location.reload(),
  });

  const onSubmit = (data: FormData) => {
    updateBirthdate({
      variables: {
        input: {
          insuredPersonIds: [props.insuredPersonId],
          birthdate: serializeDate(data.birthdate),
        },
      },
    });
  };

  return (
    <DialogContainer2>
      <TextField
        // @ts-ignore
        floatingLabelText={"Nieuwe geboortedatun"}
        hintText="Voer de nieuwe geboortedatum in"
        errorText={errors.birthdate}
        fullWidth
        {...registerField("birthdate", {
          validation: composeValidators(required, mustBeDate),
        })}
      />
      <VerticalSeparator distance={2} />
      <NavButtonContainer>
        <RaisedButton
          // @ts-ignore
          id="back"
          label="Annuleren"
          primary={false}
          onClick={props.onClose}
        />
        <RaisedButton
          // @ts-ignore
          id="confirm"
          label="Bevestig"
          backgroundColor={green}
          labelColor={white}
          onClick={() => handleSubmit(onSubmit)}
          disabled={loading}
        />
      </NavButtonContainer>
    </DialogContainer2>
  );
};

export default errorHandler(BirtdateUpdate);
