// @flow

import React from "react";
import { gql } from "@apollo/client";
import Cake from "material-ui/svg-icons/social/cake";
import { MaleIcon, FemaleIcon } from "../../../../../components/svgIcons";
import ContentRow from "../../components/contentRow";
import KeyLabel from "../../components/keyLabel";
import ValueLabel from "../../components/valueLabel";
import Separator from "../../components/rowSeparator";
import { parseDate } from "../../../../../utils";
import "./personal.css";
import { PropsWithChildren } from "react";

type Sex = "MALE" | "FEMALE";

type Props = {
  data: {
    insuredPerson: {
      id: string;
      memberId: string;
      fullName: string;
      sex: Sex;
      birthdate: string | null;
    };
  };
};

const convertBirthdateToText = (birthdate: string | null) =>
  birthdate ? parseDate(birthdate) : "De geboortedatum is niet bekend";

const MemberDetails = (props: Props) => {
  const { memberId, fullName, sex, birthdate } = props.data.insuredPerson;

  return (
    <div>
      <NameAndSexInfo sex={sex} name={fullName} />
      <Separator />
      <BirthdateInfo birthdate={convertBirthdateToText(birthdate)} />
      <Separator />
      <MemberIdInfo memberId={memberId} />
    </div>
  );
};

const memberDetailsFragment = gql`
  fragment MemberDetails on Policy {
    insuredPerson {
      id
      memberId
      fullName
      sex
      birthdate
    }
  }
`;

const NameAndSexInfo = (props: { sex: Sex; name: string }) => (
  <ImageRow>
    <SexIcon sex={props.sex} />
    <Spacer />
    <ValueLabel>{props.name}</ValueLabel>
  </ImageRow>
);

const BirthdateInfo = (props: { birthdate: string }) => (
  <ImageRow>
    <CakeIcon />
    <Spacer />
    <ValueLabel>{props.birthdate}</ValueLabel>
  </ImageRow>
);

const MemberIdInfo = (props: { memberId: string }) => (
  <ContentRow>
    <KeyLabel>Lidnummer</KeyLabel>
    <ValueLabel>{props.memberId}</ValueLabel>
  </ContentRow>
);

const ImageRow = (props: PropsWithChildren) => (
  <div className="imageRow">{props.children}</div>
);

const Spacer = () => <div className="spacer" />;

const SexIcon = (props: { sex: Sex }) => {
  const { sex } = props;
  const Icon = sex === "MALE" ? MaleIcon : FemaleIcon;
  return <Icon />;
};

const CakeIcon = () => <Cake />;

export default MemberDetails;
export { memberDetailsFragment };
