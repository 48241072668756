import React from "react";
import ExpandablePage from "../../components/expandablePageSection";
import Link from "../../../link";

const VermisteLeden = () => (
  <ExpandablePage title="Vermiste leden">
    <p>
      Om privacy redenen kunnen we hier helaas geen namen, adressen of
      lidnummers vermelden van leden die we niet meer kunnen bereiken. Echter,
      we hebben wel een paar vermiste leden, meestal als gevolg van het niet
      doorgeven van een adreswijziging. We ontdekken dat meestal doordat
      papieren post wordt teruggestuurd door de nieuwe bewoner van het oude
      adres. Het probleem dat dan ontstaat is dat wij als vereniging dan geen
      actueel adres hebben van ons lid, dus wij kunnen dat lid niet meer
      bereiken. Als vereniging mogen wij helaas geen gebruik maken van de
      gemeentelijke basisadministratie (GBA). Als dan ook nog eens geen
      contributie betaald wordt, dan ontstaat de situatie dat wij een lid als
      geroyeerd moeten gaan beschouwen, en ook dat kunnen wij niet meer melden
      door het ontbrekende adres.
    </p>
    <p>
      U kunt ons helpen door steeds alle wijzigingen aan ons door te geven, niet
      alleen wijzigingen in adres, maar ook wijzigingen in telefoonnummer of
      email-adres bijvoorbeeld. Zodoende kunnen wij u eventueel op andere
      manieren bereiken.
    </p>
    <p>
      Belangrijk: in de regel ontvangt u van ons eenmaal per jaar per papieren
      post de lidmaatschapskaart. Mocht u die aan het begin van het dit jaar
      niet ontvangen hebben, dan is het verstandig om u te melden bij onze
      secretaris om de adresgegevens te controleren, zodat we bij de volgende
      ronde (aan het begin van het volgende jaar) gebruik kunnen maken van de
      correcte adresgegevens. Contactgegevens secretaris: {/* @ts-ignore */}
      <Link href="mailto:bcb.secretaris@gmail.com?subject=secretaris">
        bcb.secretaris@gmail.com
      </Link>
    </p>
  </ExpandablePage>
);

export default VermisteLeden;
