import React, { PropsWithChildren } from "react";
import Header from "../../ledenPortaal/header/homeHeader";

const styles: { [key: string]: React.CSSProperties } = {
  contentContainer: {
    paddingTop: "64px",
    paddingBottom: "32px",
    height: "100%",
    width: "50%",
    textAlign: "left",
    margin: "auto",
  },
};

type PropType = PropsWithChildren;

const component = ({ children }: PropType) => {
  return (
    <div style={styles.container}>
      <Header />
      <div style={styles.contentContainer}>{children}</div>
    </div>
  );
};

export default component;
