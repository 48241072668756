import React from "react";
import PageSection from "../components/pageSection";
import Link from "../../link";

const ContactHulpEnSteun = () => (
  <PageSection title="Contact">
    <h3>Ledenadministratie</h3>
    <p>Voor wijzigingen en informatie over uitvaarverzekeringen</p>

    <p>Telefoon: 045-5311786</p>
    <p>
      E-mail:{" "}
      <Link href="mailto:ledenadministratie@hulpensteun.com">
        ledenadministratie@hulpensteun.com
      </Link>
    </p>
    <h3>Premieadministratie</h3>
    <p>Vragen over de betaling van uw premie</p>
    <p>Telefoon: 045-5326317 (na 18:00 uur)</p>
    <p>
      E-mail:{" "}
      <Link href="mailto:premieadministratie@hulpensteun.com">
        premieadministratie@hulpensteun.com
      </Link>
    </p>

    <h3>Secretariaat</h3>
    <p>
      Eldorado 75
      <br />
      6461 MC Kerkrade
    </p>
    <p>Telefoon: 06-46174821</p>
    <p>
      E-mail:{" "}
      <Link href="mailto:secretaris@hulpensteun.com">
        secretaris@hulpensteun.com
      </Link>
    </p>
  </PageSection>
);

export default ContactHulpEnSteun;
