// @flow

import React from "react";
import PageSection from "../components/pageSection";

const SterfgevalMeldenPietersrade = () => (
  <PageSection title="Sterfgeval melden?">
    <p>
      Sterfgevallen dienen zo spoedig mogelijk te worden gemeld via
      telefoonnummer: <b>045-5456368</b>
    </p>

    <p>
      Kies bij het bellen voor optie 1. U wordt dan 24 uur per dag doorverbonden
      naar Monuta.
    </p>

    <p>
      U hoeft zelf niets te regelen, na melding komt de uitvaartverzorger bij u
      thuis om, in overleg met u, de nodige voorzieningen te treffen.
    </p>

    <p>
      Zorg ervoor dat u de meest recente lidmaatschapskaart bij de hand heeft.
    </p>
  </PageSection>
);

export default SterfgevalMeldenPietersrade;
