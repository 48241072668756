// @flow

/**
 * The reducer classes are the methods within Redux which will performed the required actions. This will be done according to some type checking.
 * The types are received from the actions.
 * See the implementing classes for the actual methods, e.g. (type:CLOSE_NAVIGATIONBAR-> return false) in NavigationBar.
 *
 * If more reducers are added, also add them to this class in the combineReducers array.
 */

import { combineReducers } from "redux";
import premium, { type State as PremiumState } from "./premium";
import address, { type State as AddressState } from "./stepperAddress";
import payment, { type State as PaymentState } from "./stepperPayment";
import userInfo, { type State as UserInfoState } from "./stepperUserInfo";
import eligibility, {
  type State as EligibilityState,
} from "./stepperEligibility";
import account, { type State as AccountState } from "./stepperAccount";
import overview, { type State as OverviewState } from "./stepperOverview";
import stepper, { type State as StepperState } from "./stepper";
import type { SignupAction } from "../../actions";

const reducer = combineReducers({
  stepper,
  // @ts-ignore
  premium,
  // @ts-ignore
  address,
  // @ts-ignore
  payment,
  // @ts-ignore
  eligibility,
  // @ts-ignore
  userInfo,
  // @ts-ignore
  account,
  // @ts-ignore
  overview,
});

export type State = {
  stepper: StepperState;
  premium: PremiumState;
  eligibility: EligibilityState;
  userInfo: UserInfoState;
  address: AddressState;
  payment: PaymentState;
  account: AccountState;
  overview: OverviewState;
};

/**
 * This root signup reducer is used as a wrapper for the underlying regular
 * signup reducer that consists of a reducer for each of the individual stepper items,
 * and a reducer for managering the stepper itself.
 *
 * It has a single action, 'RESET_SIGNUP' that provides undefined as the
 * state argument, which will make sure that each sub reducer returns its default state.
 */
const rootSignupReducer = (state: State, action: SignupAction) => {
  if (action.type === "RESET_SIGNUP") {
    return reducer(undefined, action);
  } else {
    return reducer(state, action);
  }
};

export default rootSignupReducer;
