// @flow

import React from "react";

/**
 * This is the title component to the be used in the header.
 *
 * Use as follows:
 *
 * <AppBar title={<Title title="some title" />} />
 */
const Title = (props: { title: string }) => (
  <div
    style={{
      textAlign: "center",
      width: "100%",
      fontWeight: 300,
    }}
  >
    {props.title}
  </div>
);

export default Title;
