//@flow
import React from "react";
import TextField from "material-ui/TextField";
import { colors } from "../../styling";
import RaisedButton from "material-ui/RaisedButton";
import { isEmail } from "../../utils";
import { errorHandler } from "../../containers";
import type { ErrorHandler } from "../../containers";
import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import {
  CheckAccountQuery,
  CheckAccountQueryVariables,
} from "generated/graphql";

type State = {
  email: string;
  errorEmail: string | null;
};

const styles: { [key: string]: React.CSSProperties } = {
  inputFieldDiv: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
};

type PropsParent = {
  onConfirm: (accountId: string) => void;
};

//We need to retrieve the viewer id. If not, the store will crash and always give an
// error with the id of the first policy in the administration.
export const CHECK_ACCOUNT_QUERY = gql`
  query checkAccount($username: String!) {
    viewer {
      id
      account(username: $username) {
        id
      }
    }
  }
`;

type Props = ErrorHandler & PropsParent & WithApolloClient<{}>;

export class ExistingClient extends React.Component<Props, State> {
  state: State = {
    email: "",
    errorEmail: null,
  };

  _validate = async () => {
    const { onConfirm, client } = this.props;
    const { email } = this.state;

    if (email === "") {
      this.setState({ errorEmail: "Het e-mailadres mag niet leeg zijn." });
    } else if (!isEmail(email)) {
      this.setState({ errorEmail: "Het e-mailadres is niet geldig." });
    } else {
      try {
        const response = await client.query<
          CheckAccountQuery,
          CheckAccountQueryVariables
        >({
          query: CHECK_ACCOUNT_QUERY,
          variables: {
            username: email,
          },
        });

        const { account } = response.data.viewer;
        if (!account) {
          this.setState({
            errorEmail: "Er is geen account gekoppeld aan dit e-mailadres.",
          });
        } else {
          this.setState({ errorEmail: null });
          onConfirm(account.id);
        }
      } catch (e) {
        this.props.error.display();
      }
    }
  };

  render() {
    let { errorEmail } = this.state;
    return (
      <div style={styles.inputFieldDiv}>
        <TextField
          // @ts-ignore
          id="email"
          floatingLabelText="E-mailadres"
          floatingLabelFocusStyle={{ color: colors.green }}
          underlineFocusStyle={{ borderColor: colors.green }}
          onChange={(event: any) =>
            this.setState({
              email: event.target.value,
            })
          }
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this._validate();
            }
          }}
          errorText={errorEmail}
          fullWidth={true}
        />
        <div
          style={{
            paddingTop: "30px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RaisedButton
            // @ts-ignore
            id="verify"
            label="Volgende"
            backgroundColor={colors.green}
            labelColor={colors.white}
            onClick={() => this._validate()}
          />
        </div>
      </div>
    );
  }
}

const Wrapper = withApollo<PropsParent, {}>(errorHandler(ExistingClient));
export default Wrapper;
