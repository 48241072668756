import { useState } from "react";
import EditButton from "../components/editButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { textFieldErrorProps, useForm } from "utils/useForm";
import { composeValidators } from "components/form";
import { mustBeDate, required } from "utils/validator";
import { gql } from "generated";
import { useMutationForApp } from "utils/withApollo";
import { serializeDate } from "utils";

export const UpdateSignupDateButton = ({
  policyId,
  disabled,
  signupDate,
}: {
  policyId: string;
  disabled: boolean;
  signupDate: string;
}) => {
  const [show, setShow] = useState(false);
  const { registerField, handleSubmit, errors } = useForm<{
    signupDate: string;
  }>({
    initialValues: { signupDate },
  });
  const errorProps = textFieldErrorProps(errors);

  const [updateSignatureDate, { loading }] = useMutationForApp(MUTATION, {
    onCompleted: () => window.location.reload(),
  });
  return (
    <>
      <EditButton disabled={disabled} onClick={() => setShow(true)} />
      <Dialog
        maxWidth={"sm"}
        fullWidth
        open={show}
        onClose={() => setShow(false)}
      >
        <DialogTitle>Pas inschrijfdatum aan</DialogTitle>
        <DialogContent>
          <TextField
            label="Inschrijfdatum"
            fullWidth
            {...registerField("signupDate", {
              validation: composeValidators(required, mustBeDate),
            })}
            {...errorProps("signupDate")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShow(false)} variant="outlined">
            Annuleer
          </Button>
          <Button
            disabled={loading}
            onClick={() =>
              handleSubmit((data) => {
                updateSignatureDate({
                  variables: {
                    input: {
                      policyId,
                      signupDate: serializeDate(data.signupDate),
                    },
                  },
                });
              })
            }
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const MUTATION = gql(`
mutation updatePolicyMutation($input: UpdatePolicyInput!) {
  updatePolicy(input: $input) {
    policy {
      id
      premium
      frequency
      signupDate
    }
  }
}
`);
