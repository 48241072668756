// @flow

import { getBaseURL } from "../utils";
import { AssociationType, Config } from "./type";

const apiRoute = "/graphql";

const getAssocationType = (env: typeof process.env): AssociationType => {
  const key = "REACT_APP_ASSOCIATION_TYPE";

  const value = env[key];

  if (!value) {
    throw new Error(`You must set the environmental variable ${key}`);
  }

  const allowedAssociationTypes = Object.keys(AssociationType);

  if (!allowedAssociationTypes.includes(value)) {
    throw new Error(
      `The environmental variable ${key} must be one of the following values: ${String(
        allowedAssociationTypes
      )}`
    );
  }

  return value as AssociationType;
};

const generateConfig = (env: typeof process.env): Config => {
  const environment = env.NODE_ENV;

  switch (environment) {
    case "production":
      if (!env.REACT_APP_API_URL) {
        throw new Error(
          "You must set the environmental variable REACT_APP_API_URL in the form http://url.com (no trailing slash)"
        );
      }
      if (!env.REACT_APP_ASSOCATION_ID) {
        throw new Error(
          "You must set the environmental variable REACT_APP_ASSOCATION_ID"
        );
      }

      const urlProd: string = env.REACT_APP_API_URL;
      const associationIdProd: string = env.REACT_APP_ASSOCATION_ID;
      const associationProd = getAssocationType(env);
      const sentryDsn = env.REACT_APP_SENTRY_DSN;

      return {
        api: `${urlProd}${apiRoute}`,
        // eslint-disable-next-line
        redirectUrl: getBaseURL(location.href),
        associationId: `${associationIdProd}`,
        environment,
        logging: {
          sentry_dsn: sentryDsn ? sentryDsn : null,
        },
        association: associationProd,
      };
    case "development":
      const urlDev = "http://localhost:4000";
      const associationIdDev = "QXNzb2NpYXRpb246OA==";

      return {
        api: `${urlDev}${apiRoute}`,
        // eslint-disable-next-line
        redirectUrl: getBaseURL(location.href),
        associationId: `${associationIdDev}`,
        association: AssociationType.BCB,
        environment,
        //In development mode logging is an optional property. If not set then logging
        //will not be performed.
        logging: {
          sentry_dsn: process.env.REACT_APP_SENTRY_DSN
            ? process.env.REACT_APP_SENTRY_DSN
            : null,
        },
      };
    case "test":
      return {
        api: `mock_url_api`,
        redirectUrl: "XXXXXXXX",
        associationId: "QXNzb2NhdGlvbjox",
        association: AssociationType.BCB,
        environment,
        logging: {
          sentry_dsn: null,
        },
      };
    default:
      throw new Error(
        `Unrecognized environment ${JSON.stringify(environment)}`
      );
  }
};

export default generateConfig;
