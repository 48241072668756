// @flow

import React, { Component } from "react";
import RaisedButton from "material-ui/RaisedButton";
import SectionTitle from "../components/sectionTitle";
import { colors } from "../../../../styling";
import MemoUpdater from "./update";
import { gql } from "generated";
import { Dialog } from "@mui/material";

export type MemoDetailsType = {
  id: string;
  memo: string | null;
};

type Props = {
  data: MemoDetailsType;
  disabled: boolean;
};

type State = {
  show: boolean;
};

class MemoDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      show: false,
    };
    this._handleClose = this._handleClose.bind(this);
  }
  _handleClose: () => void = () => {
    this.setState({ show: false });
  };

  render() {
    const { show } = this.state;
    const { data } = this.props;
    const { memo, id } = data;

    let memoText;
    if (!memo || memo.length === 0) {
      memoText = "Er is geen notitie beschikbaar voor dit lid.";
    } else {
      memoText = memo;
    }
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 2 }}>
            <SectionTitle title="Notitie" />
          </div>
          <div
            style={{
              flex: 1,
              textAlign: "right",
            }}
          >
            <RaisedButton
              // @ts-ignore
              id="change"
              label="WIJZIG"
              backgroundColor={colors.green}
              labelStyle={{ color: "white" }}
              onClick={() => this.setState({ show: true })}
              disabled={this.props.disabled}
            />
          </div>
        </div>
        <p
          style={{
            fontSize: "18px",
            whiteSpace: "pre-wrap",
          }}
        >
          {memoText}
        </p>
        <Dialog open={show} fullWidth maxWidth="md" onClose={this._handleClose}>
          <MemoUpdater
            handleClose={this._handleClose}
            insuredPersonId={id}
            memo={memo}
          />
        </Dialog>
      </div>
    );
  }
}

export const memoDetailsFragment = gql(`
  fragment MemoDetails on InsuredPerson {
    id
    memo
  }
`);

export default MemoDetails;
