// @flow

import React from "react";
import Paper from "material-ui/Paper";
import { colors } from "../../../styling";
const style: React.CSSProperties = {
  position: "fixed",
  bottom: "20px",
  left: "170px",
  zIndex: 100,
  color: colors.red,
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "20px",
  paddingRight: "20px",
};

const ActivateAccountPrompt = () => (
  <Paper style={style} zDepth={1}>
    Uw account is nog niet geactiveerd!
  </Paper>
);

export default ActivateAccountPrompt;
