// @flow

import React from "react";
import config, { AssociationType } from "../../../config";
import InformationPageBCB from "./bcb";
import InformationPagePietersrade from "./pietersrade";
import InformationPageHulpEnSteun from "./hulpEnSteun";

const { association } = config;

const InformationPage = () => {
  switch (association) {
    case AssociationType.BCB:
      return <InformationPageBCB />;
    case AssociationType.PIETERSRADE:
      return <InformationPagePietersrade />;
    case AssociationType.HULP_EN_STEUN:
      return <InformationPageHulpEnSteun />;
    default:
      throw new Error(`Unknown assocation type ${association}`);
  }
};

export default InformationPage;
