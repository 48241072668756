// @flow

import React, { Component } from "react";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import CircularProgress from "material-ui/CircularProgress";

import { colorsBCV as colors } from "../../../styling";
import { addQuote } from "../../../api";

import {
  serializeDate,
  parseAmount,
  formatPrice,
  isValidBirthdate,
} from "../../../utils";
import { Frequency } from "generated/graphql";
import { formatFrequency2 } from "utils/formatter";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
  },
  button: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: colors.secondary,
    width: "200px",
    height: 40,
    backgroundColor: "white",
    color: colors.secondary,
    outline: "none",
  },
};

type Package = {
  id: string;
  name: string;
  insuredAmount: number;
  frequencies: Array<Frequency>;
};

type ResponseQuote =
  | {
      quoteExists: false;
    }
  | {
      quoteExists: true;
      contribution: number;
      lumpsum: number;
      frequency: Frequency;
    };

type State = {
  selectedPackage: Package | null;
  birthdateError: string | null;
  packageError: string | null;
  loading: boolean;
  birthdate: string;
  apiError: boolean;
  response: ResponseQuote | null;
};

type Props = {
  packages: Array<Package>;
};
export default class Contribution extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      birthdate: "",
      birthdateError: null,
      packageError: null,
      selectedPackage: null,
      apiError: false,
      response: null,
    };
    if (props.packages.length === 0) throw new Error("Expected packages");
  }

  async calculatePremium() {
    const { birthdate, selectedPackage } = this.state;

    const noBirthdate = birthdate.length === 0;
    const invalidBirthdate = !isValidBirthdate(birthdate);

    if (noBirthdate || invalidBirthdate || !selectedPackage) {
      let birthdateError, packageError;
      if (noBirthdate) birthdateError = "Vul uw geboortedatum in";
      else if (invalidBirthdate)
        birthdateError =
          "Er is geen valide geboortedatum opgegeven. Geef een datum uit het verleden op in format DD-MM-JJJJ";
      if (!selectedPackage) packageError = "Selecteer een pakket";

      this.setState({
        birthdateError,
        packageError,
      });
      return;
    }

    this.setState({ loading: true, response: null });
    try {
      const responseAPI = await addQuote(
        serializeDate(birthdate),
        selectedPackage.id,
        selectedPackage.frequencies[0]
      );

      const { quote, quoteExists } = responseAPI;

      let response: ResponseQuote;

      if (quoteExists === false) {
        response = {
          quoteExists: false,
        };
      } else {
        if (!quote)
          throw new Error("Expected GraphQL response to contain a quote.");

        response = {
          quoteExists: true,
          contribution: quote.premium,
          lumpsum: quote.lumpsum,
          frequency: quote.frequency,
        };
      }

      this.setState({
        response,
        loading: false,
        birthdateError: null,
        packageError: null,
      });
    } catch (e) {
      this.setState({ apiError: true });
    }
  }

  render = () => {
    const {
      loading,
      response,
      packageError,
      birthdateError,
      birthdate,
      selectedPackage,
      apiError,
    } = this.state;

    const { packages } = this.props;

    if (apiError === true) {
      return (
        <span style={{ color: "grey", textAlign: "center" }}>
          Er is een fout opgetreden, waardoor er geen prijs berekend kan worden.{" "}
          <br />
          <br />
          Neem contact op met ons als dit zich blijft voordoen.
        </span>
      );
    } else {
      let contentBottom;
      if (response) {
        if (response.quoteExists) {
          let lumpsumText;

          if (response.lumpsum > 0) {
            lumpsumText = (
              <p>
                De koopsom is{" "}
                <span style={{ color: colors.secondary }}>
                  {formatPrice(parseAmount(response.lumpsum))}
                </span>
              </p>
            );
          }

          contentBottom = (
            <div>
              <p>
                De contributie is{" "}
                <span style={{ color: colors.secondary, whiteSpace: "nowrap" }}>
                  {formatPrice(parseAmount(response.contribution))}
                </span>{" "}
                {formatFrequency2(response.frequency).toLocaleLowerCase()}
              </p>
              {lumpsumText}
            </div>
          );
        } else {
          contentBottom = (
            <p>
              Gegeven uw leeftijd is het niet mogelijk om een standaard
              contributie uit te rekenen. Neem alstublieft contact met ons op
              voor meer informatie.
            </p>
          );
        }
      }

      return (
        <div style={{ height: "360px" }}>
          <div style={styles.container}>
            <TextField
              fullWidth
              data-test="birthdate_input"
              floatingLabelText="Geboortedatum"
              hintText="DD-MM-JJJJ"
              errorText={birthdateError}
              onChange={(event: any) =>
                this.setState({
                  birthdate: event.target.value,
                  response: null,
                })
              }
              onKeyDown={(event: any) => {
                if (event.key === "Enter") {
                  this.calculatePremium();
                }
              }}
              value={birthdate}
            />
            <div style={{ paddingTop: "30px" }} />
            <span style={{ paddingBottom: "10px", textAlign: "center" }}>
              Kies een pakket:
            </span>
            <RadioButtonGroup
              name="Pakketten"
              valueSelected={selectedPackage && selectedPackage.id}
              onChange={(event, value) => {
                const selected = packages.filter((e) => e.id === value)[0];

                this.setState({ selectedPackage: selected, response: null });
              }}
            >
              {packages.map((pack, i) => (
                <RadioButton
                  value={pack.id}
                  label={pack.name}
                  key={pack.name}
                />
              ))}
            </RadioButtonGroup>
            {packageError ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                {packageError}
              </span>
            ) : null}
            {selectedPackage ? (
              <span style={{ paddingTop: "10px" }}>
                Dit pakket heeft een waarde van{" "}
                <span style={{ color: colors.secondary, whiteSpace: "nowrap" }}>
                  {formatPrice(parseAmount(selectedPackage.insuredAmount))}
                </span>
                .
              </span>
            ) : null}
            <div style={{ paddingTop: "30px" }} />
            <div style={{ display: "flex", justifyContent: "center" }}>
              {loading ? (
                <CircularProgress />
              ) : (
                <FlatButton
                  data-test="calculate_button"
                  style={styles.button}
                  onClick={() => this.calculatePremium()}
                >
                  Bereken
                </FlatButton>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "30px",
              }}
            >
              {contentBottom}
            </div>
          </div>
        </div>
      );
    }
  };
}
