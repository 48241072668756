// @flow

import React from "react";
import RaisedButton from "material-ui/RaisedButton";

const VariableButtons = (props: { onInsert: (value: string) => void }) => {
  const { onInsert } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <RaisedButton
        data-test="first_name_button"
        label="Voornaam"
        onClick={() => {
          onInsert("{voornaam}");
        }}
      />
      <div style={{ paddingLeft: "8px" }} />
      <RaisedButton
        data-test="last_name_button"
        label={"Achternaam"}
        onClick={() => onInsert("{achternaam}")}
      />
    </div>
  );
};

export default VariableButtons;
