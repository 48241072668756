// @flow
/**
 * Created by Florentijn Hogerwerf on 28/10/16.
 * Updated by Joshua Scheidt on 17/05/17
 */

import React from "react";
import ValueComponent from "../wrappers/valueComponent";
import { gql } from "@apollo/client";

type Props = {
  data: {
    totalCount: number;
  };
};

const PoliciesThisYear = (props: Props) => {
  return <ValueComponent value={props.data.totalCount} />;
};
export const PoliciesThisYearFragment = gql`
  fragment polsThisYear on PolicyConnection {
    totalCount
  }
`;

export default PoliciesThisYear;
