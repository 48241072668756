// @flow

import config, { AssociationType } from "../config";

const { association } = config;

let colors: {
  primary: string;
  secondary: string;
};

switch (association) {
  case AssociationType.BCB:
    colors = {
      primary: "#484848",
      secondary: "#EB8100",
    };
    break;

  case AssociationType.PIETERSRADE:
    colors = {
      primary: "#484848",
      secondary: "#800080",
    };
    break;
  case AssociationType.HULP_EN_STEUN:
    colors = {
      primary: "#484848",
      secondary: "#EB8100",
    };

    break;
  default:
    throw new Error(`Unknown assocation type ${association}`);
}

export default colors;
