// @flow
/**
 * Created by Ellissa Verseput & Jim Bemelen on 22/12/16.
 */

import React, { Component } from "react";
import CircularProgress from "material-ui/CircularProgress";

const style: { [key: string]: React.CSSProperties } = {
  container: {
    width: "100%",
    height: "100%",
  },
  loadingFull: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    position: "absolute",
  },
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

type PropType = {
  /**
   * Boolean indicating whether to render a loading indocator or not.
   */
  isFetching: boolean;

  /**
   * Displays a dialog the contains the error of a request for example.
   * If this is set then a dialog appears on the screen.
   */
  error?: any | null;

  fullScreen: boolean;

  /** The color of the loading indicator */
  color?: string;
} & React.PropsWithChildren;

/**
 * A component that renders its child components or a loading indicator.
 */
export default class DataLoader extends Component<PropType> {
  render() {
    let { children, isFetching, error, fullScreen } = this.props;

    if (error) {
      return <div>An error occurred,</div>;
    } else if (isFetching && fullScreen) {
      return (
        <div style={style.loadingFull}>
          {this.props.color ? (
            <CircularProgress color={this.props.color} />
          ) : (
            <CircularProgress />
          )}
        </div>
      );
    } else if (isFetching) {
      return (
        <div style={style.loading}>
          {this.props.color ? (
            <CircularProgress color={this.props.color} />
          ) : (
            <CircularProgress />
          )}
        </div>
      );
    } else return <div style={style.container}>{children}</div>;
  }
}
