// @flow

import React, { Component } from "react";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import { colors } from "../../../../../../styling";
import { serializeDate, isDate } from "../../../../../../utils";
import { errorHandler, ErrorHandler } from "../../../../../../containers";
import {
  DeceasePolicyMutation,
  DeceasePolicyMutationVariables,
} from "generated/graphql";
import { graphql } from "@apollo/client/react/hoc";
import { gql } from "generated";

const { green } = colors;

type PropsParent = {
  handleBack: () => void;
  handleClose: () => void;
  policyId: string;
};

type PropsGraphQLDecease = { deceasePolicy: (dateOfDeath: string) => void };
type Props = PropsParent & PropsGraphQLDecease & ErrorHandler;

type State = {
  dateOfDeath: string;
  dateError: string | null;
};

class ActiveDialogDecease extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dateOfDeath: "",
      dateError: null,
    };
  }

  _updateState = async () => {
    const { deceasePolicy, error } = this.props;
    const { dateOfDeath } = this.state;

    if (!dateOfDeath)
      this.setState({ dateError: "Voer een overlijdensdatum in." });
    else if (!isDate(dateOfDeath))
      this.setState({
        dateError: "Voer een geldige overlijdensdatum in (DD-MM-JJJJ).",
      });
    else {
      try {
        await deceasePolicy(serializeDate(dateOfDeath));
        // Reload to reset the cache.
        window.location.reload();
      } catch (e) {
        error.display();
      }
    }
  };

  render() {
    const { handleBack } = this.props;
    const { dateError, dateOfDeath } = this.state;
    return (
      <div style={{ padding: 30 }}>
        <h2>Update de status van het lid</h2>
        <span>
          Door op bevestig te klikken verandert de status van het lid van{" "}
          <strong>Actief</strong> naar <strong>Overleden</strong>
          , dit kan niet ongedaan worden gemaakt.
          <br />
          <br />
          Als u de status wilt veranderen druk op <i>bevestig</i>, anders druk
          op <i>annuleer</i>.
        </span>
        <TextField
          floatingLabelText={"Overlijdensdatum"}
          hintText="DD-MM-JJJJ"
          floatingLabelFocusStyle={{ color: green }}
          underlineFocusStyle={{ borderColor: green }}
          fullWidth
          errorText={dateError}
          value={dateOfDeath}
          onChange={(event: any) =>
            this.setState({ dateOfDeath: event.target.value })
          }
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this._updateState();
            }
          }}
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            paddingTop: "30px",
          }}
        >
          <RaisedButton
            // @ts-ignore
            id="back"
            label="Annuleer"
            primary={false}
            onClick={handleBack}
          />
          <RaisedButton
            // @ts-ignore
            id="confirm"
            label="Bevestig"
            backgroundColor={green}
            labelStyle={{ color: "white" }}
            onClick={this._updateState}
          />
        </div>
      </div>
    );
  }
}

//Decease
export const DECEASE_POLICY = gql(`
  mutation deceasePolicy($input: DeceasePolicyInput!) {
    deceasePolicy(input: $input) {
      policy {
        id
        dateOfDeath
        __typename
      }
    }
  }
`);

export const withMutationDecease = graphql<
  PropsParent,
  DeceasePolicyMutation,
  DeceasePolicyMutationVariables,
  PropsGraphQLDecease
>(DECEASE_POLICY, {
  props: ({ ownProps, mutate }) => ({
    deceasePolicy: (dateOfDeath: string) =>
      mutate({
        variables: {
          input: {
            policyId: ownProps.policyId,
            dateOfDeath,
          },
        },
      }),
  }),
});
const Wrapper = withMutationDecease(errorHandler(ActiveDialogDecease));
export default Wrapper;
