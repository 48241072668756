import { useApolloClient } from "@apollo/client";
import {
  AddToAccountMutation,
  AddToAccountMutationVariables,
} from "generated/graphql";
import { WithRouterProps } from "react-router";
import { ADD_TO_ACCOUNT_MUTATION } from "screens/accountDetails/components/addToAccount";
import { insurerPaths } from "screens/routes";
import { useMutationForApp } from "utils/withApollo";

export const useLinkPersonToAccount = (routerProps: WithRouterProps) => {
  const client = useApolloClient();

  return useMutationForApp<AddToAccountMutation, AddToAccountMutationVariables>(
    ADD_TO_ACCOUNT_MUTATION,
    {
      onCompleted: async (data) => {
        await client.resetStore();
        routerProps.router.push(
          insurerPaths.accountsDetails.path(
            data.addInsuredPersonToAccount.account.id!!
          )
        );
      },
    }
  );
};
