// @flow

import React from "react";
import config, { AssociationType } from "../../../config";
import HomePageBCB from "./bcb";
import PietersradeBCB from "./pietersrade";
import HomePageHenS from "./hulpEnSteun";

const { association } = config;

const HomePage = () => {
  switch (association) {
    case AssociationType.BCB:
      return <HomePageBCB />;
    case AssociationType.PIETERSRADE:
      return <PietersradeBCB />;
    case AssociationType.HULP_EN_STEUN:
      return <HomePageHenS />;
    default:
      throw new Error(`Unknown association type ${association}`);
  }
};

export default HomePage;
