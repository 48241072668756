// @flow

import { red500 } from "material-ui/styles/colors";
import React, { PropsWithChildren } from "react";

export const Error = () => (
  <span style={{ color: "grey", textAlign: "center" }}>
    Er is een fout opgetreden.
    <br />
    <br />
    Neem contact op met ons als dit probleem zich blijft voordoen.
  </span>
);

export const ErrorText = (props: PropsWithChildren) => {
  return <span style={{ color: red500 }}>{props.children}</span>;
};
