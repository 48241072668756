import * as React from "react";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

export default function SplitButton(props: {
  onDetailsAccount?: () => void;
  onDetailsMember: () => void;
  onDetailsGroup: () => void;
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<any>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <React.Fragment>
      <Button
        ref={anchorRef}
        onClick={handleToggle}
        endIcon={<ArrowDropDownIcon />}
      >
        Details
      </Button>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem onClick={(event) => props.onDetailsMember()}>
                    Details Lid
                  </MenuItem>
                  {props.onDetailsAccount && (
                    <MenuItem onClick={(event) => props.onDetailsAccount()}>
                      Details Account
                    </MenuItem>
                  )}
                  <MenuItem onClick={(event) => props.onDetailsGroup()}>
                    Details Groep
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
