import React from "react";
import ExpandablePage from "../../components/expandablePageSection";
import { pricePackageA, pricePackageB } from "./constants";

const QABCB = () => (
  <ExpandablePage title="Vraag en antwoord">
    <p>
      <strong>Vraag:</strong> Kan ik de pakketvergoeding behorend bij pakket A,
      B of C op mijn bankrekening gestort krijgen?
    </p>
    <p>
      <strong>Antwoord:</strong> Nee, dat gaat helaas niet (conform onze
      statuten). Als vereniging dienen wij (wettelijk) een vergoeding in natura
      te geven en dat doen we door de pakketvergoeding rechtstreeks over te
      maken naar de uitvaartondernemer. Wij maken het bedrag over zodra wij in
      het bezit zijn van het uittreksel van de overlijdensakte, de kopie-nota
      van de uitvaart en de akte van cessie.
    </p>
    <p>
      <strong>Vraag:</strong> Welke uitvaartondernemer kan ik kiezen?
    </p>
    <p>
      <strong>Antwoord:</strong> U kunt elke gewenste uitvaartondernemer kiezen.
      De kosten van de uitvaart worden vergoed ter hoogte van de
      pakketvergoeding behorend bij pakket A, B of C. Bespreek dit vooraf goed
      met uw uitvaartondernemer, zodat u niet achteraf voor vervelende
      financiële verrassingen komt te staan
    </p>
    <p>
      Wij hebben een samenwerkingsverband met{" "}
      <b>
        <a href="https://www.mok-uitvaart.nl">Mok Uitvaartverzorging</a>
      </b>{" "}
      te Heerlen en{" "}
      <b>
        <a href="https://www.paffenuitvaartverzorging.nl">
          Uitvaartverzorging Paffen
        </a>
      </b>{" "}
      te Kerkrade.
    </p>

    <p>
      <strong>Vraag:</strong> Ik heb met de uitvaartondernemer gesproken en de
      totale begrafenis/crematie is € 500 duurder dan de vergoeding, wie betaalt
      dat?
    </p>
    <p>
      <strong>Antwoord:</strong> Omdat de nabestaanden zelf bepalen hoe beperkt
      of uitgebreid een begrafenis/crematie zal worden verzorgd, zijn alle
      meerkosten (zoals altijd al) voor rekening van de nabestaanden, te
      verrekenen met de uitvaartondernemer.
    </p>
    <p>
      <strong>Vraag:</strong> Waaruit bestaat een sobere begrafenis of crematie
      eigenlijk?
    </p>
    <p>
      <strong>Antwoord: </strong>
      Een sobere begrafenis of crematie bestaat uit een aantal basiselementen,
      zoals een grafkist, verzorgen van de overledene, rouwauto, uitvaartleider
      en dragers, verblijf in het mortuarium, verrichten van formaliteiten,
      uittreksel uit de overlijdensakte en een aantal specifieke zaken voor de
      begrafenis c.q. de crematie. Dit sobere pakket hebben wij afgesproken met
      onze uitvaartondernemer tegen een speciaal ledentarief en is dus als
      zodanig nog steeds volledig gedekt binnen {pricePackageA} voor begraven en
      binnen {pricePackageB} voor crematie.
    </p>
    <p>
      <strong>Vraag:</strong> Ik heb de nieuwsbrief ontvangen, maar op dit adres
      woont geen lid meer of dit lid is overleden, hoe kan dat?
    </p>
    <p>
      <strong>Antwoord:</strong> Onze oprechte excuses dat wij als vereniging u
      onnodig hebben benaderd. Omdat wij zeker willen weten dat onze post alle
      leden bereikt, is onze adresselectie in sommige gevallen net iets te
      “ruim”, waardoor onze post soms onbedoeld aankomt. Nogmaals onze excuses
      voor het ongemak. U helpt ons door de post aan de secretaris te
      retourneren.
    </p>
    <p>
      <strong>Vraag:</strong> Hoeveel geld krijg ik terug als ik mijn
      lidmaatschap opzeg?
    </p>
    <p>
      <strong>Antwoord:</strong> Conform onze statuten kunnen wij als vereniging
      bij een voortijdige beëindiging van het lidmaatschap geen geld uitbetalen.
    </p>
    <p>
      <strong>Vraag:</strong> Ik zie dat er sprake is van 10 euro
      administratiekosten, waarom is dat?
    </p>
    <p>
      <strong>Antwoord:</strong> Als vereniging proberen wij met zijn allen de
      kosten te drukken, onder andere door onbezoldigde bestuursleden. Leden die
      de vereniging machtigen voor de contributie EN hun post digitaal ontvangen
      met de mail die helpen eraan mee dat de kosten worden gedrukt. Leden die
      op de ouderwetse manier blijven werken zorgen voor porto- en incassokosten
      en dat wordt dan weer afgedekt door 10 euro extra te betalen. Door de
      vereniging te machtigen voor incasso en door een account aan te maken op
      de website kunnen de kosten gedrukt worden en hoef je in de toekomst die
      10 euro niet meer te betalen.
    </p>
    <p>Laatste update: 5-4-2024</p>
  </ExpandablePage>
);

export default QABCB;
