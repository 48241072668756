import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { FragmentType, gql, useFragment } from "generated";
import { InsuredPeopleListFragment } from "generated/graphql";
import {
  formatAddress,
  formatGender,
  formatPaymentMethod2,
  formatPolicyState,
  formatPremiumWithFrequency,
  formatToEuro,
  parseDate,
} from "utils/formatter";
import { RemoveFromAccountButton } from "./removeFromAccount";
import { LinkMemberButton } from "./addToAccount";
import { Fragment, useState } from "react";
import { areListEqual } from "utils/utilities";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

type Props = {
  data: FragmentType<typeof InsuredPeopleFragment>;
  goToPolicy: (policyId: string) => void;
};

export const InsuredPeopleTable = (props: Props) => {
  const data = useFragment(InsuredPeopleFragment, props.data);

  const [open, setOpen] = useState<string[]>([]);

  const allIds = data.insuredPeople.map((ip) => ip.id);
  const isAllOpen = areListEqual(open, allIds);

  return (
    <Box>
      <Paper elevation={3}>
        <TableContainer>
          <EnhancedTableToolbar accountId={data.id} />
          <Table padding="normal" sx={{ minWidth: "650px" }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <OpenCloseButton
                    open={isAllOpen}
                    onClick={() => {
                      if (isAllOpen) setOpen([]);
                      else setOpen(allIds);
                    }}
                  />
                </TableCell>
                <TableCell>Lidnummer</TableCell>
                <TableCell>Naam</TableCell>
                <TableCell>Geboortedatum</TableCell>
                <TableCell>Geslacht</TableCell>
                <TableCell>IBAN</TableCell>
                <TableCell>Betalingsmethode</TableCell>
                <TableCell>Adress</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.insuredPeople.map((ip) => {
                const { payment, policies } = ip;

                const paymentMethod = payment?.paymentMethod;
                const isOpen = open.includes(ip.id);
                const openCell = () => {
                  if (isOpen) setOpen(open.filter((op) => op !== ip.id));
                  else setOpen([...open, ip.id]);
                };

                const tableCellProps = {
                  onClick: () => {
                    if (policies.length === 0) {
                    }
                    if (policies.length === 1) props.goToPolicy(policies[0].id);
                    else openCell();
                  },
                  sx: { borderBottom: 0 },
                };

                return (
                  <Fragment key={ip.id}>
                    <TableRow hover>
                      <TableCell sx={{ borderBottom: 0 }}>
                        <OpenCloseButton
                          open={isOpen}
                          onClick={() => openCell()}
                        />
                      </TableCell>

                      <TableCell {...tableCellProps}>{ip.memberId}</TableCell>
                      <TableCell {...tableCellProps}>{ip.fullName}</TableCell>
                      <TableCell {...tableCellProps}>
                        {parseDate(ip.birthdate)}{" "}
                        {ip.age ? ` (${ip.age} jaar)` : ""}
                      </TableCell>
                      <TableCell>{formatGender(ip.sex)}</TableCell>
                      <TableCell {...tableCellProps}>
                        {payment?.iban || "Niet gezet"}
                      </TableCell>
                      <TableCell {...tableCellProps}>
                        {paymentMethod
                          ? formatPaymentMethod2(paymentMethod)
                          : "Onbekend"}
                      </TableCell>
                      <TableCell {...tableCellProps}>
                        {ip.address ? formatAddress(ip.address) : ""}
                      </TableCell>
                      <TableCell sx={{ borderBottom: 0 }}>
                        <RemoveFromAccountButton
                          memberId={ip.memberId}
                          fullName={ip.fullName}
                          accountId={data.id}
                          insuredPersonId={ip.id}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={8}
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                      >
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                          <PolicyTable
                            policies={ip.policies}
                            goToPolicy={props.goToPolicy}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

const PolicyTable = ({
  policies,
  goToPolicy,
}: {
  policies: InsuredPeopleListFragment["insuredPeople"][0]["policies"];
  goToPolicy: (policyId: string) => void;
}) => {
  return (
    <Box sx={{ margin: 3, marginTop: 1, marginLeft: 20, marginRight: 30 }}>
      <Table padding="normal" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Polis</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Premie</TableCell>
            <TableCell>Inschrijfdatum</TableCell>
            <TableCell>Pakket</TableCell>
            <TableCell>Aanvullende verzekering</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {policies.map((pol, index) => {
            const {
              id,
              premium,
              signupDate,
              state,
              frequency,
              insuredAmount,
              additionalInsurance,
            } = pol;

            const tableCellProps = {
              onClick: () => goToPolicy(pol.id),
            };

            let packageName = "Geen pakket";

            if (pol.package) packageName = pol.package.name;
            else if (insuredAmount)
              packageName = "Verzekerd bedrag " + formatToEuro(insuredAmount);

            let additionalInsuranceText = "Nee";

            if (additionalInsurance) {
              if (additionalInsurance.endDate)
                additionalInsuranceText =
                  "Beëindigd op " + parseDate(additionalInsurance.endDate);
              else additionalInsuranceText = "Ja";
            }

            return (
              <TableRow key={id} hover>
                <TableCell {...tableCellProps}>Polis {index + 1}</TableCell>
                <TableCell {...tableCellProps}>
                  {formatPolicyState(state)}
                </TableCell>
                <TableCell {...tableCellProps}>
                  {formatPremiumWithFrequency(premium, frequency)}
                </TableCell>
                <TableCell {...tableCellProps}>
                  {parseDate(signupDate)}
                </TableCell>
                <TableCell {...tableCellProps}>{packageName}</TableCell>
                <TableCell {...tableCellProps}>
                  {additionalInsuranceText}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

const OpenCloseButton = ({
  onClick,
  open,
}: {
  onClick: () => void;
  open: boolean;
}) => {
  return (
    <Tooltip title="Bekijk polissen van lid">
      <IconButton size="small" onClick={() => onClick()}>
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    </Tooltip>
  );
};

function EnhancedTableToolbar(props: { accountId: string }) {
  return (
    <Toolbar>
      <Typography variant="h6" id="tableTitle" component="div">
        Leden in account
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
      <LinkMemberButton accountId={props.accountId} />
    </Toolbar>
  );
}

const InsuredPeopleFragment = gql(`
fragment InsuredPeopleList on Account {
    id
    insuredPeople {
      id
      memberId
      fullName
      sex
      birthdate
      age
      payment {
        iban
        accountHolder
        paymentMethod
      }
      address {
        id
        postalCode
        houseNumber
        suffix
        street
        city
        countryCode
      }
      policies {
          id
          state
          package {
            id
            name
          }
          signupDate
          insuredAmount
          signOffDate
          premium
          frequency
          additionalInsurance {
            id
            endDate
          }
      }
      
    }
}

`);
