import config, { AssociationType } from "../config";

var image;

if (config.association === AssociationType.HULP_EN_STEUN) {
  image = require("../images/sea.jpg");
} else image = require("../images/graan.jpg");

const imageExport = image;
export { imageExport as image };
