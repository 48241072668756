//@flow

import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import React from "react";
import InfoIcon from "material-ui/svg-icons/action/info-outline";
import IconButton from "material-ui/IconButton";
import { Frequency } from "generated/graphql";

type Package = {
  id: string;
  name: string;
  insuredAmount: number;
  frequencies: Array<Frequency>;
};
type Props = {
  onChange: (event: any, value: string) => void;
  packages: Array<Package> | null;
  selectedPackage: string | null;
};

const showPackages = (packages: Package[] | null) => {
  let buttons = [];
  if (packages != null) {
    for (let i = 0; i < packages.length; i++) {
      buttons[i] = (
        <RadioButton
          data-test={`package_select_${packages[i].id}`}
          value={packages[i].id}
          label={packages[i].name}
          key={i}
        />
      );
    }
  }
  return buttons;
};

const showInfoButtons = (packages: Package[] | null) => {
  let packetInfo;
  if (packages)
    packetInfo = packages.map((packageitem) => {
      return (
        "Met " +
        packageitem.name +
        " wordt er bij overlijden een bedrag van €" +
        packageitem.insuredAmount +
        " uitbetaald."
      );
    });

  let buttons = [];
  if (packetInfo != null) {
    for (let i = 0; i < packetInfo.length; i++) {
      buttons[i] = (
        <IconButton
          key={i}
          style={{ maxHeight: "24px", maxWidth: "24px" }}
          iconStyle={{ color: "white", transform: "translate(-12px, -12px)" }}
          tooltip={
            <div
              style={{
                width: "200px",
                textAlign: "left",
                whiteSpace: "pre-line",
              }}
            >
              {packetInfo[i]}
            </div>
          }
          tooltipPosition="bottom-right"
          tooltipStyles={{ transform: "translate(20px, -35px)" }}
        >
          <InfoIcon />
        </IconButton>
      );
    }
  }
  return buttons;
};

const PackagePicker = (props: Props) => {
  const { onChange, packages, selectedPackage } = props;
  return (
    <div style={{ paddingTop: "30px" }}>
      Welk pakket wilt u afsluiten voor het lid?
      <div
        style={{ display: "flex", flexDirection: "row", paddingTop: "15px" }}
      >
        <RadioButtonGroup
          name="packages"
          onChange={onChange}
          style={{ flex: 2 }}
          defaultSelected={selectedPackage}
        >
          {showPackages(packages)}
        </RadioButtonGroup>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            transformOrigin: "0% top 0px",
            width: "100%",
            height: "100%",
            padding: 0,
            margin: 0,
          }}
        >
          {showInfoButtons(packages)}
        </div>
      </div>
    </div>
  );
};

export default PackagePicker;
