import { Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";

type Props = {
  onClick: () => void;
};

type MuiButtonProps = React.ComponentProps<typeof Button>;

export const EditButton = (props: Props) => {
  return (
    <IconButton onClick={props.onClick}>
      <EditIcon />
    </IconButton>
  );
};

export const DownloadButton = (props: Props) => {
  return (
    <IconButton onClick={props.onClick}>
      <DownloadIcon />
    </IconButton>
  );
};

export const DialogCancelButton = (props: MuiButtonProps) => (
  <Button variant="outlined" {...props}>
    Annuleer
  </Button>
);
export const DialogConfirmButton = (props: MuiButtonProps) => (
  <Button {...props} />
);

export const FloatingAddButton = (props: { onClick: () => void }) => {
  return (
    <Fab
      sx={{
        position: "fixed",
        bottom: 50,
        right: 50,
        //Need to add this otherwise the FAB goes below the content on the screen.
        zIndex: 1,
      }}
      onClick={() => props.onClick()}
      color="primary"
    >
      <AddIcon />
    </Fab>
  );
};
