// @flow

import React from "react";
import { colorsBCV } from "../../../styling";

const style: { [key: string]: React.CSSProperties } = {
  titleContainer: {
    fontSize: "32px",
    paddingTop: "32px",
    color: colorsBCV.secondary,
  },
};

type PropType = {
  title: string;
};

const Title = ({ title }: PropType) => (
  <div style={style.titleContainer}>{title}</div>
);

export default Title;
