// @flow

import React from "react";
import AppBar from "material-ui/AppBar";
import { Title, MenuButton } from "../../components/header";
import { blueHiThere } from "../../styling";

const style: React.CSSProperties = {
  backgroundColor: blueHiThere,
  position: "fixed",
};

const Header = () => (
  <AppBar
    style={style}
    title={<Title title="Bestuur" />}
    iconElementLeft={<MenuButton />}
  />
);

export default Header;
