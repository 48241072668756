// @flow

import React, { Component } from "react";
import { colors } from "../../../styling";
import TextField from "material-ui/TextField";
import { isAmount, serializeAmount } from "../../../utils";

type State = {
  contribution: string;
  lumpsum: string;
  errorContribution: string | null;
  errorLumpsum: string | null;
};

type Props = {
  validate: Function;
};

export default class CustomContribution extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      contribution: "",
      lumpsum: "",
      errorContribution: null,
      errorLumpsum: null,
    };
  }

  getInput(): {
    premium: number;
    lumpsum: number;
  } {
    const { contribution, lumpsum } = this.state;
    return {
      premium: serializeAmount(contribution),
      lumpsum: serializeAmount(lumpsum),
    };
  }

  /**
   * Validates the input
   * @returns true if the input is correct
   */
  validate(): boolean {
    const { contribution, lumpsum } = this.state;

    let errorContribution,
      errorLumpsum = null;

    if (contribution === "") {
      errorContribution = "De contributie is niet ingevuld.";
    } else if (!isAmount(contribution)) {
      errorContribution = "De contributie is geen geldig bedrag.";
    }

    if (lumpsum === "") {
      errorLumpsum = "De koopsom is niet ingevuld.";
    } else if (!isAmount(lumpsum)) {
      errorLumpsum = "De koopsom is geen geldig bedrag.";
    }

    this.setState({
      errorLumpsum,
      errorContribution,
    });

    if (!errorContribution && !errorLumpsum) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { contribution, lumpsum, errorContribution, errorLumpsum } =
      this.state;

    const textfieldStyling = {
      floatingLabelFocusStyle: {
        color: colors.green,
      },
      underlineFocusStyle: {
        borderColor: colors.green,
      },
      fullWidth: true,
    };

    return (
      <div>
        <TextField
          value={contribution}
          floatingLabelText="Contributie"
          onChange={(event: any) =>
            this.setState({ contribution: event.target.value })
          }
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this.props.validate();
            }
          }}
          errorText={errorContribution}
          {...textfieldStyling}
        />
        <TextField
          value={lumpsum}
          floatingLabelText="Koopsom"
          onChange={(event: any) =>
            this.setState({ lumpsum: event.target.value })
          }
          errorText={errorLumpsum}
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this.props.validate();
            }
          }}
          {...textfieldStyling}
        />
      </div>
    );
  }
}
