// @flow

import { DocumentNode } from "graphql";
import { ApolloClient } from "@apollo/client";
import { Frequency } from "generated/graphql";
import { gql } from "generated/gql";
import config from "config";

export type Package = {
  id: string;
  name: string;
  insuredAmount: number;
  frequencies: Array<Frequency>;
};

export const getPackages = async (
  associationId: string,
  client: ApolloClient<{}>
): Promise<Array<Package>> => {
  const response = await client.query<{ packages: Package[] }>({
    query: gql(`
      query retrievePackages($associationId: ID!) {
        packages(associationId: $associationId) {
          id
          name
          insuredAmount
          frequencies
        }
      }
    `),
    variables: { associationId },
  });

  const { data } = response;

  return data.packages;
};

export const isEmailUnique = async (
  username: string,
  client: ApolloClient<{}>
): Promise<boolean> => {
  const response = await client.query({
    query: gql(`
      query checkUsernameAvailable($username: String!, $associationId: ID!) {
        checkUsernameAvailable(username: $username, associationId: $associationId)
      }
    `),
    variables: { username, associationId: config.associationId },
    fetchPolicy: "network-only",
  });

  const { data } = response;

  return data.checkUsernameAvailable;
};

type AddAccountInput = {
  username: string;
  password: string;
  associationId: string;
  firstName: string;
  lastName: string;
  namePreposition: string;
  phone: string;
  redirectUrl: string;
};

export const addAccount = async (
  input: AddAccountInput,
  client: ApolloClient<{}>
): Promise<any> => {
  await client.mutate({
    mutation: gql(`
      mutation addAccount($input: AddAccountInput!) {
        addAccount(input: $input) {
          clientMutationId
        }
      }
    `),
    variables: { input },
  });
};

export type AddPolicyInput = {
  quoteId: string;
  sex: string;
  firstName: string;
  namePreposition: string;
  lastName: string;
  initials: string;
  address: {
    postalCode: string;
    houseNumber: number;
    suffix: string | null;
    street: string;
    city: string;
    country: string;
  };
  payment: {
    iban: string;
    accountName: string;
  };
  eligibility: Array<{
    questionId: string;
    response: boolean;
    explanation: string | null;
  }>;
};

/**
 * Create a new policy
 * @param input the data used to create the policy.
 * @param refetchQueries An array of queries that need to be refetched
 * after the mutation.
 * @param client the apollo client used to perform the request
 */
export const addPolicy = async (
  input: AddPolicyInput,
  refetchQueries: Array<DocumentNode>,
  clientUser: ApolloClient<{}>
) => {
  await clientUser.mutate({
    mutation: gql(`
      mutation addPolicy($input: AddPolicyInput!) {
        addPolicy(input: $input) {
          policy {
            id
          }
        }
      }
    `),
    // @ts-ignore
    variables: { input },
    refetchQueries: refetchQueries.map((query) => ({
      query,
    })),
  });
};
