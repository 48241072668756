// @flow

import AppBar from "material-ui/AppBar";
import { withRouter, WithRouterProps } from "react-router";
import { Title, BackButton } from "../../../components/header";
import { colorsBCV } from "../../../styling";

type Props = WithRouterProps;

const Header = (props: Props) => (
  <AppBar
    title={<Title title="Aanmelden nieuw lid" />}
    style={{ backgroundColor: colorsBCV.primary }}
    iconElementLeft={<BackButton onClick={props.router.goBack} />}
  />
);

export default withRouter(Header);
