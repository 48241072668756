// @flow

import React from "react";
import Header from "./header";
import { SideBar } from "../../components/header";
import BestuurHeader from "./headerChart";
import Divider from "material-ui/Divider";
import PoliciesThisYear, {
  PoliciesThisYearFragment,
} from "./components/policiesThisYear";
import PoliciesPerDate, {
  PoliciesPerDateFragment,
} from "./components/policiesSoldPerDate";
import InterestRate, {
  InterestCurveFragment,
} from "./components/interestRate10-30Line";
import FundingRatio, {
  FundingRatioFragment,
} from "./components/fundingRatioLine";
import AssetPortfolio, {
  AssetPortfolioFragment,
} from "./components/assetPortfolio";
import BalanceSheet, { BalanceSheetFragment } from "./components/balanceSheet";
import EmptyContainer from "./components/emptyContainer";
import { gql } from "@apollo/client";
import { filter } from "graphql-anywhere";
import { errorHandler } from "../../containers";
import type { ErrorHandler } from "../../containers";
import CircularProgress from "material-ui/CircularProgress";
import { colors } from "../../styling";
import TitleContainer from "./wrappers/titleContainer";
import RaisedButton from "material-ui/RaisedButton";
import moment from "moment";
import { DataProps, graphql } from "@apollo/client/react/hoc";
moment.locale("nl");

const styles: { [key: string]: React.CSSProperties } = {
  gridRowContainer: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  cellContainer: {
    height: "260px",
    border: "1px solid rgb(235, 235, 235)",
    margin: "-1px", //Used to offset the border
    //The "width: 0" makes sure that the charts of recharts can be used in combination with flexbox.
    // This looks like a hacky solution.
    // The following link gives the issue where we found this solution: https://github.com/recharts/recharts/issues/172
    width: 0,
  },
  doubleCellContainer: {
    height: "522px",
    border: "1px solid rgb(235, 235, 235)",
    margin: "-1px", //Used to offset the border
    //The "width: 0" makes sure that the charts of recharts can be used in combination with flexbox.
    // This looks like a hacky solution.
    // The following link gives the issue where we found this solution: https://github.com/recharts/recharts/issues/172
    width: 0,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    position: "absolute",
  },
};
type DataGraphQL = {
  viewer?: {
    id: string;
    fundingRatio: any | null;
    assetPortfolio: any | null;
    fundingRatios: any | null;
    interestCurves: any | null;
    balanceSheet: any | null;
    policies: any | null;
    policyCount: any | null;
  };
};

type Props = ErrorHandler & DataProps<DataGraphQL>;

export class FinancialOverview extends React.Component<Props> {
  render() {
    let { loading, error, viewer, refetch } = this.props.data;

    const fetchRecords = () => {
      const body = document.body;
      if (!body) throw new Error("Body is not defined");
      body.scrollTop = 0;
      refetch();
    };

    let content;
    if (error) {
      content = (
        <div style={{ ...styles.center, flexDirection: "column" }}>
          <p style={{ fontSize: "24px", textAlign: "center" }}>
            <span>Oeps, er is iets fout gegaan.</span>
            <br />
            <span>
              Ververs de pagina en probeer het opnieuw. Als deze fout zich
              blijft voordoen, neem dan contact met ons op.
            </span>
          </p>
          <RaisedButton
            // @ts-ignore
            id="retry"
            label="PROBEER OPNIEUW"
            labelStyle={{ color: "white" }}
            backgroundColor={colors.green}
            onClick={() => fetchRecords()}
          />
        </div>
      );
    } else if (loading) {
      content = (
        <div
          style={{ ...styles.gridRowContainer, width: "100%", height: "100%" }}
        >
          <CircularProgress color={colors.green} />
        </div>
      );
    } else {
      if (viewer != null)
        content = (
          <div>
            <BestuurHeader />
            <div style={styles.gridRowContainer}>
              <div style={{ flex: 1, ...styles.cellContainer }}>
                <TitleContainer title="Beleggingsportefeuille">
                  {viewer.assetPortfolio == null ? (
                    <EmptyContainer />
                  ) : (
                    <AssetPortfolio
                      data={filter(
                        AssetPortfolioFragment,
                        viewer.assetPortfolio
                      )}
                    />
                  )}
                </TitleContainer>
              </div>
              <div style={{ flex: 1, ...styles.cellContainer }}>
                <TitleContainer title="Dekkingsgraad">
                  {viewer.fundingRatios == null ||
                  viewer.fundingRatios.length === 0 ? (
                    <EmptyContainer />
                  ) : (
                    // $FlowFixMe
                    <FundingRatio data={viewer.fundingRatios} />
                  )}
                </TitleContainer>
              </div>
              <div style={{ flex: 1, ...styles.cellContainer }}>
                <TitleContainer title="Rente curves">
                  {viewer.interestCurves == null ||
                  viewer.interestCurves.length === 0 ? (
                    <EmptyContainer />
                  ) : (
                    // $FlowFixMe
                    <InterestRate data={viewer.interestCurves} />
                  )}
                </TitleContainer>
              </div>
            </div>
            <div style={styles.gridRowContainer}>
              <div style={{ flex: 2, ...styles.doubleCellContainer }}>
                <TitleContainer title="Balans">
                  {viewer.balanceSheet == null ? (
                    <EmptyContainer />
                  ) : (
                    <BalanceSheet
                      data={filter(BalanceSheetFragment, viewer.balanceSheet)}
                    />
                  )}
                </TitleContainer>
              </div>
              <div style={{ flex: 1, ...styles.doubleCellContainer }}>
                <div
                  style={{ ...styles.cellContainer, flex: 1, width: "100%" }}
                >
                  <TitleContainer title="Aantal leden">
                    {viewer.policyCount == null ||
                    viewer.policyCount.length === 0 ? (
                      <EmptyContainer />
                    ) : (
                      // $FlowFixMe
                      <PoliciesPerDate data={viewer.policyCount} />
                    )}
                  </TitleContainer>
                </div>
                <Divider
                  style={{
                    paddingTop: "1px",
                    color: "rgb(235, 235, 235)",
                    backgroundColor: "rgb(235, 235, 235)",
                  }}
                />
                <div
                  style={{ flex: 1, ...styles.cellContainer, width: "100%" }}
                >
                  <TitleContainer title="Nieuwe leden dit jaar">
                    {viewer.policies == null ? (
                      <EmptyContainer />
                    ) : (
                      <PoliciesThisYear
                        data={filter(PoliciesThisYearFragment, viewer.policies)}
                      />
                    )}
                  </TitleContainer>
                </div>
              </div>
            </div>
          </div>
        );
      else this.props.error.display();
    }
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Header />
        <SideBar />
        {content}
      </div>
    );
  }
}

const calculationMethod = "FISCAL";
const firstDay = moment().startOf("year").format("YYYY-MM-DD");
const oneYearAgo = moment().subtract(1, "years").format("YYYY-MM-DD");

export const QUERY = gql`
  query FinancialOverview {
    viewer{
      id
      interestCurves(calculationMethod:${calculationMethod}) {
        ...interestCurves
      }
      fundingRatios(calculationMethod:${calculationMethod}) {
        ...fundingRatio
      }
      assetPortfolio {
        ...assetPortfolio
      }
      balanceSheet(calculationMethod:${calculationMethod}) {
        ...balanceSheet
      }
      policies(startDate:"${firstDay}") {
        ...polsThisYear
      }
      policyCount(startDate:"${oneYearAgo}") {
        ...policiesPerDate
      }
    }
  }
  ${InterestCurveFragment}
  ${FundingRatioFragment}
  ${AssetPortfolioFragment}
  ${BalanceSheetFragment}
  ${PoliciesThisYearFragment}
  ${PoliciesPerDateFragment}
`;
const withData = errorHandler(
  graphql<ErrorHandler, DataGraphQL, {}, DataProps<DataGraphQL>>(QUERY)(
    FinancialOverview
  )
);

export default withData;
