// @flow

import React from "react";
import { colorsBCV } from "../../styling";
import { withRouter } from "react-router";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    backgroundColor: "white",
    border: "1px solid",
    borderColor: colorsBCV.secondary,
    color: colorsBCV.secondary,
    height: 30,
    fontSize: "14px",
  },
  title: {
    fontSize: 30,
    color: "grey",
    fontWeight: "100",
  },
};

type Props = {
  router: {
    push: (route: string) => void;
  };
};

const pageNotFound = ({ router }: Props) => (
  <div style={styles.container}>
    <div style={styles.innerContainer}>
      <span style={styles.title}>Oeps... Deze pagina bestaat niet.</span>
      <div style={{ paddingTop: "15px", paddingBottom: "15px" }} />
      <button style={styles.button} onClick={() => router.push("/")}>
        Terug naar home
      </button>
    </div>
  </div>
);

export default withRouter(pageNotFound);
