// @flow

import { graphql } from "@apollo/client/react/hoc";
import { gql } from "generated";
import {
  DeleteAccountMutation,
  DeleteAccountMutationVariables,
} from "generated/graphql";

export const DELETE_ACCOUNT = gql(`
  mutation deleteAccount($input: DeleteAccountInput!) {
    deleteAccount(input: $input) {
      deletedAccountId
      __typename
    }
  }
`);

type Props = {
  accountId: string;
  goBack: () => void;
};

export type PropsGraphQLDeleteAccount = {
  deleteAccount: () => Promise<any>;
};

export const withMutation = graphql<
  Props,
  DeleteAccountMutation,
  DeleteAccountMutationVariables,
  PropsGraphQLDeleteAccount
>(DELETE_ACCOUNT, {
  props: (props) => {
    const { mutate, ownProps } = props;
    return {
      deleteAccount: () =>
        mutate!!({
          variables: { input: { accountId: ownProps.accountId } },
        }),
    };
  },
  options: ({ goBack }) => ({
    onCompleted: () => goBack(),
  }),
});
