import { gql } from "generated";
import { HeaderEventTimeline } from "./components/header";
import { useQueryForApp } from "utils/withApollo";
import { EventsTable } from "./components/table";
import {
  EventTypeSelectionDialogButton,
  allEventTypes,
} from "./components/EventTypeSelectionDialog";
import { useState } from "react";
import { TablePagination } from "@mui/material";
import { WithRouterProps, withRouter } from "react-router";
import { insurerPaths } from "screens/routes";
import { pushWithPreviousPath } from "utils/routing";
import { FilterActiveAlert, InfoAlert } from "./components/alerts";
import { getInitialState, useUpdateQueryParam } from "./queryParams";

export const EventTimelineScreen = withRouter((props: WithRouterProps) => {
  const [selection, setSelection] = useState(
    () => getInitialState(props).filter
  );

  const [first, setFirst] = useState(() => getInitialState(props).first);
  const [page, setPage] = useState(() => getInitialState(props).page);

  const [seeDescription, setSeeDescription] = useState(
    () => getInitialState(props).seeDescription
  );

  const { data, loading } = useQueryForApp(query, {
    variables: {
      first,
      offset: page * first,
      types: selection,
    },
  });

  useUpdateQueryParam(page, first, selection, seeDescription, props);

  const filterActive = selection.length < allEventTypes.length;

  return (
    <>
      <HeaderEventTimeline
        loading={loading}
        seeDescription={seeDescription}
        onToggleDescription={() => setSeeDescription(!seeDescription)}
        typeSelection={
          <EventTypeSelectionDialogButton
            selection={selection}
            setSelection={(select) => {
              setSelection(select);
              setPage(0);
            }}
          />
        }
      />
      {selection.length === allEventTypes.length && <InfoAlert />}
      {filterActive && <FilterActiveAlert count={selection.length} />}
      {data?.getEvents && (
        <EventsTable
          data={data.getEvents.events}
          goToPolicy={(policyId) =>
            pushWithPreviousPath(
              props,
              insurerPaths.policyDetailsPage.path(policyId)
            )
          }
          loading={loading}
          paginationComponent={
            <TablePagination
              page={page}
              rowsPerPage={first}
              onRowsPerPageChange={(e) =>
                setFirst(parseInt(e.target.value, 10))
              }
              onPageChange={(e, newPage) => setPage(newPage)}
              count={data.getEvents.totalCount}
              component="div"
            />
          }
          seeDescription={seeDescription}
        />
      )}
    </>
  );
});

const query = gql(`
query eventsTimeline($first: Int!, $offset: Int!, $types: [EventType!]) {
  getEvents(first: $first, offset: $offset, types: $types) {
    events {
      id
      description
      type
      date
      creator {
        id
        fullName
      }
      insuredPerson {
        id
        memberId
        fullName
        policies {
          id
        }
      }
    }
    totalCount
   
  }
}
`);
