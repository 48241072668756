// @flow

import React, { Component } from "react";
import { NavButtonContainer, Title } from "../../../../components/dialog";
import RaisedButton from "material-ui/RaisedButton";
import { errorHandler, ErrorHandler } from "../../../../containers";
import { gql } from "@apollo/client";
import config from "../../../../config";
import { graphql } from "@apollo/client/react/hoc";
import {
  AddMigrationCodeMutation,
  AddMigrationCodeMutationVariables,
} from "generated/graphql";

type transferFragmentType = {
  id: string;
  insuredPerson: {
    id: string;
  };
};

type TransferMembershipInput = {
  redirectUrl: string;
  insuredPersonId: string;
};

type PropsParent = {
  handleClose: () => void;
  data: transferFragmentType;
};

type PropsGraphQL = {
  transferMembership: (input: TransferMembershipInput) => Promise<any>;
};

type Props = PropsParent & ErrorHandler & PropsGraphQL;

type State = {
  loading: boolean;
  completed: boolean;
};

export class TransferMembershipDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      completed: false,
      loading: false,
    };
  }

  render() {
    const { completed } = this.state;
    const { handleClose } = this.props;

    let content;
    if (!completed)
      content = (
        <ComponentConfirm
          // @ts-ignore
          id="componentConfirm"
          handleClose={handleClose}
          onTransferMembership={() => this._transferMembership()}
          loading={false}
        />
      );
    else
      content = (
        // @ts-ignore
        <ComponentCompleted id="componentCompleted" handleClose={handleClose} />
      );

    return (
      <div>
        <Title>Lidmaatschap Overzetten</Title>
        {content}
      </div>
    );
  }

  async _transferMembership() {
    const { transferMembership, error, data } = this.props;
    try {
      this.setState({ loading: true });
      await transferMembership({
        redirectUrl: `${config.redirectUrl}/migreer`,
        insuredPersonId: data.insuredPerson.id,
      });
      this.setState({
        loading: false,
        completed: true,
      });
    } catch (e) {
      this.setState({
        loading: false,
        completed: false,
      });
      error.display();
    }
  }
}

const Separator = () => <div style={{ paddingTop: "24px" }} />;

export const ComponentConfirm = (props: {
  handleClose: () => void;
  onTransferMembership: () => Promise<void>;
  loading: boolean;
}) => {
  return (
    <div>
      <p>
        Druk op <b>BEVESTIG</b> als u het lidmaatschap wilt overzetten naar een
        ander account.
      </p>
      <p>
        Na bevestiging wordt een migratiecode verzonden naar uw emailadres. Met
        deze migratiecode kunt u het lidmaatschap overzetten naar een ander
        account. Dit kan een bestaand of nieuw account zijn.
      </p>
      <p>
        Het lidmaatschap blijft gekoppeld aan het huidige account totdat de
        migratiecode is geactiveerd.
      </p>
      <Separator />
      <NavButtonContainer>
        <RaisedButton
          // @ts-ignore
          id="back"
          label="Annuleren"
          primary={false}
          onClick={props.handleClose}
        />
        <RaisedButton
          // @ts-ignore
          id="confirm"
          label="Bevestig"
          primary={true}
          onClick={props.onTransferMembership}
          disabled={props.loading}
        />
      </NavButtonContainer>
    </div>
  );
};

export const ComponentCompleted = (props: { handleClose: () => void }) => (
  <div>
    <p>
      Er is een email verstuurd met de migratiecode. Volg de instructies in de
      mail om uw lidmaatschap over te zetten naar een ander account.
    </p>
    <Separator />
    <RaisedButton
      // @ts-ignore
      id="close"
      label="Sluiten"
      primary={false}
      onClick={props.handleClose}
    />
  </div>
);

export const ADD_MIGRATIONCODE_MUTATION = gql`
  mutation addMigrationCode($input: AddMigrationCodeInput!) {
    addMigrationCode(input: $input) {
      migrationCode
    }
  }
`;

export const withMutation = graphql<
  PropsParent,
  AddMigrationCodeMutation,
  AddMigrationCodeMutationVariables,
  PropsGraphQL
>(ADD_MIGRATIONCODE_MUTATION, {
  props: ({ mutate }) => ({
    transferMembership: (input) => mutate({ variables: { input } }),
  }),
});

export const TransferFragment = gql`
  fragment TransferDialogDef on Policy {
    id
    insuredPerson {
      id
    }
  }
`;

const Wrapped = withMutation(errorHandler(TransferMembershipDialog));

export default Wrapped;
