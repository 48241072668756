// @flow

import { gql, FragmentType, useFragment } from "generated";
import React, { useState } from "react";
import { colors } from "../../styling";
import IconButton from "material-ui/IconButton";
import Pencil from "material-ui/svg-icons/content/create";
import UpdatePassword from "./dialogs/passwordDialog";
import { Dialog } from "@mui/material";

const styles: { [key: string]: React.CSSProperties } = {
  userInfo: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    justifyContent: "left",
    alignItems: "left",
  },
  infoTitle: {
    paddingTop: "10px",
    fontSize: "18px",
    color: colors.green,
  },
  buttonDiv: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
};

type Props = {
  infoPersonal: FragmentType<typeof personalInfoFragment>;
};

const PersonalDetails = (props: Props) => {
  const infoPersonal = useFragment(personalInfoFragment, props.infoPersonal);

  const [openPassword, setOpenPassword] = useState(false);

  return (
    <div style={styles.userInfo}>
      <div style={styles.infoTitle}>Email</div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>{infoPersonal.username}</div>
      </div>

      <div style={styles.infoTitle}>Wachtwoord</div>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ flex: 1 }}>************</div>
        <div style={{ ...styles.buttonDiv, flex: 1 }}>
          <IconButton
            title="Wijzig wachtwoord"
            onClick={() => {
              setOpenPassword(true);
            }}
          >
            <Pencil color={colors.green} />
          </IconButton>
        </div>
      </div>
      <Dialog open={openPassword} onClose={() => setOpenPassword(false)}>
        <UpdatePassword handleClose={() => setOpenPassword(false)} />
      </Dialog>
    </div>
  );
};

const personalInfoFragment = gql(`
    fragment PersonInfo on Account {
      id
      username
    }
  `);

export default PersonalDetails;
