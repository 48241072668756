// @flow

import React from "react";
import Snackbar from "material-ui/Snackbar";

const ConfirmSnackbar = (props: { open: boolean; onClose: () => void }) => (
  <Snackbar
    style={{ textAlign: "center" }}
    open={props.open}
    message={"Het bericht is verzonden"}
    autoHideDuration={10000}
    onRequestClose={props.onClose}
  />
);

export default ConfirmSnackbar;
