import { UpdateAddressDialog } from "components/address/updateAddressDialog";
import { EditButton } from "components/button/buttons";
import { useState } from "react";

export const UpdateAddressEditButton = (props: {
  insuredPersonIds: string[];
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <EditButton onClick={() => setOpen(true)} />
      <UpdateAddressDialog
        open={open}
        handleClose={() => setOpen(false)}
        insuredPersonIds={props.insuredPersonIds}
        description="Pas het adres aan voor alle leden in de groep."
      />
    </>
  );
};
