// @flow

import React from "react";
import config, { AssociationType } from "../../../config";

import BCBlogo from "../../../images/BCB-logo.png";
import PietersradeLogo from "../../../images/Pietersrade-logo.png";
import HenSLogo from "../../../images/HenS-logo-white.png";

const { association } = config;

const HeaderLogo = () => {
  switch (association) {
    case AssociationType.BCB:
      return (
        <img
          style={{
            width: "60px",
            height: "44px",
          }}
          src={BCBlogo}
          alt=""
        />
      );
    case AssociationType.PIETERSRADE:
      return (
        <img
          style={{
            width: "80px",
          }}
          src={PietersradeLogo}
          alt=""
        />
      );

    case AssociationType.HULP_EN_STEUN:
      return <img style={{ height: "44px" }} src={HenSLogo} alt="" />;

    default:
      throw new Error(`Unknown association type ${association}`);
  }
};

export default HeaderLogo;
