// @flow

import React from "react";
import RaisedButton from "material-ui/RaisedButton";

type Props = {
  onAddFile: (file: File) => void;
};

const AttachmentSelect = (props: Props) => {
  const { onAddFile } = props;

  return (
    <div>
      <input
        id="upload-input"
        style={{
          display: "none",
        }}
        type="file"
        onChange={(e) => {
          const files = e.target.files;
          if (files == null) {
            throw new Error("Files is null");
          }

          onAddFile(files[0]);
        }}
      />
      <label htmlFor="upload-input">
        <RaisedButton containerElement="span" label="Bijlage toevoegen" />
      </label>
    </div>
  );
};

export default AttachmentSelect;
