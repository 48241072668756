/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n      mutation activate($input: ActivateAccountInput!) {\n        activateAccount(input: $input) {\n          activated\n        }\n      }\n    ": types.ActivateDocument,
    "\n        mutation addQuote($input: AddQuoteInput!) {\n          addQuote(input: $input) {\n            quote {\n              id\n              birthdate\n              premium\n              lumpsum\n              frequency\n              package {\n                id\n                name\n                insuredAmount\n              }\n            }\n            quoteExists\n          }\n        }\n      ": types.AddQuoteDocument,
    "\n      mutation forgot($input: ForgotPasswordInput!) {\n        forgotPassword(input: $input) {\n          exists\n        }\n      }\n    ": types.ForgotDocument,
    "\nquery auth($username: String!, $password: String!, $associationId: ID!) {\n  authenticate(username: $username, password: $password, associationId: $associationId) {\n    value\n    permissions\n    association {\n      id\n    }\n  }\n}\n": types.AuthDocument,
    "\n      mutation reset($input: ResetPasswordInput!) {\n        resetPassword(input: $input) {\n          active\n        }\n      }\n    ": types.ResetDocument,
    "\n      query retrievePackages($associationId: ID!) {\n        packages(associationId: $associationId) {\n          id\n          name\n          insuredAmount\n          frequencies\n        }\n      }\n    ": types.RetrievePackagesDocument,
    "\n      query checkUsernameAvailable($username: String!, $associationId: ID!) {\n        checkUsernameAvailable(username: $username, associationId: $associationId)\n      }\n    ": types.CheckUsernameAvailableDocument,
    "\n      mutation addAccount($input: AddAccountInput!) {\n        addAccount(input: $input) {\n          clientMutationId\n        }\n      }\n    ": types.AddAccountDocument,
    "\n      mutation addPolicy($input: AddPolicyInput!) {\n        addPolicy(input: $input) {\n          policy {\n            id\n          }\n        }\n      }\n    ": types.AddPolicyDocument,
    "\n  query completeAddress(\n    $postalCode: String!\n    $houseNumber: Int!\n    $suffix: String\n  ) {\n    completeAddress(\n      postalCode: $postalCode\n      houseNumber: $houseNumber\n      suffix: $suffix\n    ) {\n      address {\n        city\n        street\n        postalCode\n        country\n        houseNumber\n        suffix\n      }\n      unknownSuffix\n      unknownPostalCode\n      unknownHouseNumber\n    }\n  }\n": types.CompleteAddressDocument,
    "\n  mutation updateAddress($input: UpdateAddressInput!) {\n    updateAddress(input: $input) {\n      addresses {\n        id\n        postalCode\n        houseNumber\n        suffix\n        street\n        city\n        countryCode\n      }\n    }\n  }\n": types.UpdateAddressDocument,
    "\n      query retrieveEligibilityQuestions($associationId: ID!) {\n        assocation(id: $associationId) {\n          eligibilityQuestions {\n            question\n            id\n          }\n        }\n      }\n    ": types.RetrieveEligibilityQuestionsDocument,
    "\n  mutation updateToDirectDebit($input: UpdateToDirectDebitInput!) {\n    updateToDirectDebit(input: $input) {\n      payments {\n        id\n        iban\n        paymentMethod\n        bic\n        signatureDate\n        mandateId\n        accountHolder\n      }\n    }\n  }\n": types.UpdateToDirectDebitDocument,
    "\n  mutation updateToInvoice($input: UpdateToInvoiceInput!) {\n    updateToInvoice(input: $input) {\n      payments {\n        id\n        paymentMethod\n        iban\n        bic\n        accountHolder\n        signatureDate\n        mandateId\n      }\n    }\n  }\n": types.UpdateToInvoiceDocument,
    "\nfragment AccountDetailsInfo on Account {\n    id\n    username\n    verified\n    fullName\n    firstName\n    lastName\n    namePreposition\n    phone\n}\n": types.AccountDetailsInfoFragmentDoc,
    "\nmutation addToAccount($input: AddInsuredPersonToAccountInput!) {\n    addInsuredPersonToAccount(input: $input) {\n      clientMutationId\n      account {\n          id\n      }\n    }\n  }": types.AddToAccountDocument,
    "\nquery findMember($memberId: ID!) {\n    insuredPersonForMemberId(memberId: $memberId) {\n      id\n      memberId\n      fullName\n      birthdate\n      account {\n        id\n        username\n      }\n    }\n}": types.FindMemberDocument,
    "\n  mutation deleteAccount($input: DeleteAccountInput!) {\n    deleteAccount(input: $input) {\n      deletedAccountId\n      __typename\n    }\n  }\n": types.DeleteAccountDocument,
    "\nfragment InsuredPeopleList on Account {\n    id\n    insuredPeople {\n      id\n      memberId\n      fullName\n      sex\n      birthdate\n      age\n      payment {\n        iban\n        accountHolder\n        paymentMethod\n      }\n      address {\n        id\n        postalCode\n        houseNumber\n        suffix\n        street\n        city\n        countryCode\n      }\n      policies {\n          id\n          state\n          package {\n            id\n            name\n          }\n          signupDate\n          insuredAmount\n          signOffDate\n          premium\n          frequency\n          additionalInsurance {\n            id\n            endDate\n          }\n      }\n      \n    }\n}\n\n": types.InsuredPeopleListFragmentDoc,
    "\nmutation removeFromAccount($input: RemoveInsuredPersonFromAccountInput!) {\n    removeInsuredPersonFromAccount(input: $input) {\n      clientMutationId\n    }\n}": types.RemoveFromAccountDocument,
    "\nmutation updateAccountUsername($input: UpdateUsernameInput!) {\n  updateUsername(input: $input) {\n    clientMutationId\n  }\n}\n": types.UpdateAccountUsernameDocument,
    "\nquery accountNode($accountId: ID!) {\n  account(id: $accountId) {\n    id\n    ...AccountDetailsInfo\n    ...InsuredPeopleList\n  }\n}\n\n": types.AccountNodeDocument,
    "\nmutation updateAccount($input: UpdateAccountInput!) {\n  updateAccount(input: $input) {\n    clientMutationId\n  }\n}": types.UpdateAccountDocument,
    "\nquery accountsOverview($first: Int!, $offset: Int!, $query: String, $findGhostAccounts: Boolean!) {\n    accounts(first: $first, offset: $offset, query: $query, findGhostAccounts: $findGhostAccounts) {\n      accounts {\n        id\n        username\n        verified\n        fullName\n        phone\n      }\n      totalCount\n      insuredPersonCount\n    }\n}": types.AccountsOverviewDocument,
    "\n  query searchAdministration(\n    $query: String!\n    $after: String\n    $states: [PolicyState!]\n    $searchType: SearchType\n    $matchType: MatchType\n  ) {\n    viewer {\n      id\n      association {\n        id\n        membershipCardTemplateKey\n      }\n      search(\n        query: $query\n        first: 10\n        after: $after\n        states: $states\n        searchType: $searchType\n        matchType: $matchType\n      ) {\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n        totalCount\n        ...search\n      }\n    }\n  }\n  \n": types.SearchAdministrationDocument,
    "\nmutation createDump($input: CreateDumpInput!) {\n  createDump(input: $input) {\n    url\n  }\n}\n": types.CreateDumpDocument,
    "\nmutation generateMembershipCards {\n  generateMembershipCard(input: {}) {\n    urls\n  }\n}": types.GenerateMembershipCardsDocument,
    "\n  fragment card on InsuredPerson {\n    id\n    account {\n      id\n    }\n    policies {\n      id\n      ...policy\n    }\n    ...user\n    ...multiPoliciesDialog\n  }\n\n  \n  \n  \n": types.CardFragmentDoc,
    "\n  fragment multiPoliciesDialog on InsuredPerson {\n    id\n    memberId\n    fullName\n    policies {\n      id\n      ...multiPolicyCard\n    }\n  }\n  \n": types.MultiPoliciesDialogFragmentDoc,
    "\n  fragment multiPolicyCard on Policy {\n    id\n    package {\n      id\n      name\n    }\n    premium\n    frequency\n    signupDate\n    state\n  }\n": types.MultiPolicyCardFragmentDoc,
    "\n  fragment policy on Policy {\n    id\n    package {\n      id\n      name\n    }\n    premium\n    frequency\n    signupDate\n    state\n  }\n": types.PolicyFragmentDoc,
    "\n  fragment user on InsuredPerson {\n    id\n    memberId\n    fullName\n    age\n    birthdate\n    sex\n    payment {\n      id\n      iban\n    }\n    address {\n      id\n      houseNumber\n      postalCode\n      suffix\n      street\n      city\n      countryCode\n    }\n  }\n": types.UserFragmentDoc,
    "\n  fragment search on SearchResultConnection {\n    edges {\n      node {\n        ... on InsuredPerson {\n          id\n          ...card\n        }\n      }\n    }\n  }\n  \n": types.SearchFragmentDoc,
    "\n  mutation updatePassword($input: UpdatePasswordInput!) {\n    updatePassword(input: $input) {\n      account{\n        id\n      }\n    }\n  }\n": types.UpdatePasswordDocument,
    "\n  query QueryForUser {\n    viewer {\n      id\n      ...ProfileInfoBestuurder\n      ...PersonInfo\n    }\n  }\n": types.QueryForUserDocument,
    "\n    fragment PersonInfo on Account {\n      id\n      username\n    }\n  ": types.PersonInfoFragmentDoc,
    "\n  fragment ProfileInfoBestuurder on Account {\n    id\n    fullName\n  }": types.ProfileInfoBestuurderFragmentDoc,
    "\nmutation newAccount($input: AddAccountInput!) {\n  addAccount(input: $input) {\n    account {\n      id\n      username\n    }\n  }\n}\n": types.NewAccountDocument,
    "\n  query checkAccount($username: String!) {\n    viewer {\n      id\n      account(username: $username) {\n        id\n      }\n    }\n  }\n": types.CheckAccountDocument,
    "\n  fragment ContributionFragment on Association {\n    packages {\n      id\n      name\n      insuredAmount\n      frequencies\n    }\n  }\n": types.ContributionFragmentFragmentDoc,
    "\n  mutation addPolicyCustom($input: AddCustomPolicyInput!) {\n    addCustomPolicy(input: $input) {\n      policy {\n        id\n      }\n    }\n  }\n": types.AddPolicyCustomDocument,
    "\n  query PolicySignup {\n    viewer {\n      id\n      association {\n        id\n        eligibilityQuestions {\n          id\n          question\n        }\n        ...ContributionFragment\n      }\n    }\n  }\n  \n": types.PolicySignupDocument,
    "\n  query memberIdQuery(\n    $memberId: String\n    $lastName: String\n    $birthdate: Date\n    $after: String\n  ) {\n    viewer {\n      id\n      verifyMember(\n        memberId: $memberId\n        lastName: $lastName\n        birthdate: $birthdate\n        first: 50\n        after: $after\n      ) {\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n        totalCount\n        edges {\n          node {\n            id\n          }\n        }\n        ...PolicyListFragment\n      }\n    }\n  }\n  \n": types.MemberIdQueryDocument,
    "\n  fragment PolicyCardFragment on Policy {\n    id\n    insuredPerson {\n      id\n      association {\n        id\n        name\n      }\n    }\n    state\n    ...clientCheckUser\n    ...clientCheckPolicy\n    ...ReportDeathFragment\n  }\n  \n  \n  \n": types.PolicyCardFragmentFragmentDoc,
    "\n  fragment clientCheckPolicy on Policy {\n    id\n    package {\n      id\n      name\n    }\n    insuredPerson {\n      id\n      memberId\n    }\n    signupDate\n    signOffDate\n    state\n  }\n": types.ClientCheckPolicyFragmentDoc,
    "\n  fragment clientCheckUser on Policy {\n    id\n    insuredPerson {\n      id\n      lastName\n      initials\n      birthdate\n      sex\n    }\n  }\n": types.ClientCheckUserFragmentDoc,
    "\n  fragment PolicyListFragment on PolicyConnection {\n    edges {\n      node {\n        ... on Policy {\n          id\n          ...PolicyCardFragment\n        }\n      }\n    }\n  }\n  \n": types.PolicyListFragmentFragmentDoc,
    "\n  fragment ReportDeathFragment on Policy {\n    id\n    insuredPerson {\n      id\n      association {\n        id\n        name\n      }\n    }\n  }\n": types.ReportDeathFragmentFragmentDoc,
    "\n  mutation reportDeath($input: ReportDeathInput!) {\n    reportDeath(input: $input) {\n      clientMutationId\n    }\n  }\n": types.ReportDeathDocument,
    "\n      mutation sendMail(\n        $attachmentKeys: [ID!]\n        $subject: String\n        $body: String!\n      ) {\n        sendMail(\n          attachmentKeys: $attachmentKeys\n          subject: $subject\n          body: $body\n        )\n      }\n    ": types.SendMailDocument,
    "\nquery uploadUrl($filename: String!, $bucketType: BucketType!) {\n  getUploadUrl(filename: $filename, bucketType: $bucketType) {\n    url\n    key\n  }\n}\n": types.UploadUrlDocument,
    "\n  fragment assetPortfolio on AssetPortfolio {\n    id\n    date\n    currency\n    assets {\n      edges {\n        node {\n          id\n          name\n          value\n          category\n        }\n      }\n    }\n  }\n": types.AssetPortfolioFragmentDoc,
    "\n  fragment balanceSheet on BalanceSheet {\n    id\n    date\n    currency\n    asset {\n      name\n      value\n      children {\n        edges {\n          node {\n            name\n            value\n          }\n        }\n      }\n    }\n    liability {\n      name\n      value\n      children {\n        edges {\n          node {\n            name\n            value\n          }\n        }\n      }\n    }\n  }\n": types.BalanceSheetFragmentDoc,
    "\n  fragment fundingRatio on FundingRatio {\n    id\n    date\n    value\n  }\n": types.FundingRatioFragmentDoc,
    "\n  fragment interestCurves on InterestCurve {\n    id\n    date\n    rate_10: rate(maturity: 10) {\n      rate\n      id\n    }\n    rate_30: rate(maturity: 30) {\n      rate\n      id\n    }\n  }\n": types.InterestCurvesFragmentDoc,
    "\n  fragment policiesPerDate on PolicyCount {\n    date\n    count\n  }\n": types.PoliciesPerDateFragmentDoc,
    "\n  fragment polsThisYear on PolicyConnection {\n    totalCount\n  }\n": types.PolsThisYearFragmentDoc,
    "\nquery eventsTimeline($first: Int!, $offset: Int!, $types: [EventType!]) {\n  getEvents(first: $first, offset: $offset, types: $types) {\n    events {\n      id\n      description\n      type\n      date\n      creator {\n        id\n        fullName\n      }\n      insuredPerson {\n        id\n        memberId\n        fullName\n        policies {\n          id\n        }\n      }\n    }\n    totalCount\n   \n  }\n}\n": types.EventsTimelineDocument,
    "\nquery StatutesData($associationId: ID!) {\n    node(id: $associationId) {\n        id\n        ...on Association {\n            statutesUrl\n            sepaUrl\n        }\n    }\n}\n": types.StatutesDataDocument,
    "\n\nfragment IpGrouping on InsuredPeopleGrouping {\n    insuredPeople {\n        id\n    }\n    phone\n    account {\n        id\n        username\n    }\n    address {\n      id\n      postalCode\n      suffix\n      houseNumber\n      suffix\n      street\n      city\n      countryCode\n    }\n    payment {\n      iban\n      bic\n      accountHolder\n      paymentMethod\n    }\n    insuredPeople {\n        id\n        phone\n    }\n    premium {\n        policy {\n            yearPremium\n            termPremium {\n                premium\n                frequency\n            }\n        }\n        additionalInsurance {\n            yearPremium\n            termPremium {\n                premium\n                frequency\n            }\n        }\n    }\n  }": types.IpGroupingFragmentDoc,
    "\nfragment InsuredPeopleGroupingList on InsuredPeopleGrouping {\n    phone\n    account { id }\n    insuredPeople {\n        id\n        memberId\n        phone\n        fullName\n        sex\n        phone\n        birthdate\n        age\n        account {\n            id\n            username\n            phone\n        }\n        policies {\n            id\n            state\n            package {\n              id\n              name\n            }\n            signupDate\n            insuredAmount\n            signOffDate\n            premium\n            frequency\n            additionalInsurance {\n              id\n              startDate\n              endDate\n              premium\n              insuredAmount\n              frequency\n            }\n      }\n    }\n      \n}": types.InsuredPeopleGroupingListFragmentDoc,
    "\nquery insuredPersonGroup($insuredPersonId: ID!) {\n  viewer {\n    association {\n      id\n      membershipCardTemplateKey\n    }\n  }\n    insuredPeopleGroup(id: $insuredPersonId) {\n      insuredPeople {\n        id\n    }\n      ...InsuredPeopleGroupingList\n      ...IpGrouping\n    }\n  }\n  ": types.InsuredPersonGroupDocument,
    "\nmutation generateMembershipCard($input: GenerateMembershipCardInput!) {\n  generateMembershipCard(input: $input) {\n    urls\n  }\n}": types.GenerateMembershipCardDocument,
    "\n  query UserQuery {\n    viewer {\n      id\n      verified\n      ...ProfileInfo\n      ...PersonalInfo\n    }\n  }\n  \n  \n": types.UserQueryDocument,
    "\n  mutation updateAccountMember($input: UpdateAccountInput!) {\n    updateAccount(input: $input) {\n      user {\n        id\n        lastName\n      }\n    }\n  }\n": types.UpdateAccountMemberDocument,
    "\n  fragment UsernameDialog on Account {\n    id\n    username\n  }\n": types.UsernameDialogFragmentDoc,
    "\n  mutation updateUsernameMember($input: UpdateUsernameInput!) {\n    updateUsername(input: $input) {\n      account {\n        id\n        username\n      }\n    }\n  }\n": types.UpdateUsernameMemberDocument,
    "\n  fragment PersonalInfo on Account {\n    id\n    username\n    phone\n    ...UsernameDialog\n  }\n  \n": types.PersonalInfoFragmentDoc,
    "\n  fragment ProfileInfo on Account {\n    id\n    firstName\n    lastName\n  }\n": types.ProfileInfoFragmentDoc,
    "\n  query UserQueryHeader {\n    viewer {\n      id\n      firstName\n      lastName\n    }\n  }\n": types.UserQueryHeaderDocument,
    "\n    fragment PolicyCard on Policy {\n      id\n      signOffDate\n      premium\n      lumpsum\n      frequency\n      package {\n        id\n        name\n        insuredAmount\n      }\n      insuredPerson {\n        id\n        memberId\n        fullName\n        birthdate\n        sex\n        association {\n          id\n          name\n        }\n      }\n      signupDate\n      state\n      ...DialogComponents\n    }\n    \n  ": types.PolicyCardFragmentDoc,
    "\n  fragment AddressDialog on Policy {\n    insuredPerson {\n      id\n    }\n  }\n": types.AddressDialogFragmentDoc,
    "\n    fragment AddressInfo on Policy {\n      id\n      insuredPerson {\n        id\n        address {\n          id\n          postalCode\n          houseNumber\n          suffix\n          street\n          city\n        }\n      }\n    }\n  ": types.AddressInfoFragmentDoc,
    "\n  fragment DetailsPolicyFragment on Policy {\n    ...MemberPaymentDetails\n    ...AddressInfo\n    ...PolicyPackageDetails\n    ...MemberDetails\n    additionalInsurance {\n      id\n      insuredAmount\n      premium\n      lumpsum\n      startDate\n      endDate\n      frequency\n    }\n  }\n  \n  \n  \n  \n": types.DetailsPolicyFragmentFragmentDoc,
    "\n  fragment PolicyPackageDetails on Policy {\n    premium\n    package {\n      id\n      name\n    }\n  }\n": types.PolicyPackageDetailsFragmentDoc,
    "\n  fragment MemberPaymentDetails on Policy {\n    id\n    frequency\n    insuredPerson {\n      id\n      payment {\n        id\n        iban\n        paymentMethod\n        accountHolder\n      }\n    }\n  }\n": types.MemberPaymentDetailsFragmentDoc,
    "\n  fragment MemberDetails on Policy {\n    insuredPerson {\n      id\n      memberId\n      fullName\n      sex\n      birthdate\n    }\n  }\n": types.MemberDetailsFragmentDoc,
    "\n  fragment DialogComponents on Policy {\n    id\n    insuredPerson {\n      id\n    }\n    ...TransferDialogDef\n    ...DetailsPolicyFragment\n    ...AddressDialog\n    ...UpdatePackage\n  }\n\n  \n  \n  \n  \n": types.DialogComponentsFragmentDoc,
    "\n  mutation addMigrationCode($input: AddMigrationCodeInput!) {\n    addMigrationCode(input: $input) {\n      migrationCode\n    }\n  }\n": types.AddMigrationCodeDocument,
    "\n  fragment TransferDialogDef on Policy {\n    id\n    insuredPerson {\n      id\n    }\n  }\n": types.TransferDialogDefFragmentDoc,
    "\n  fragment UpdatePackage on Policy {\n    id\n    ...PackageDialog\n  }\n  \n": types.UpdatePackageFragmentDoc,
    "\n  fragment PackageDialog on Policy {\n    id\n    upgradeablePackages {\n      id\n      name\n      insuredAmount\n    }\n    insuredPerson {\n      id\n      association {\n        id\n        statutesUrl\n        eligibilityQuestions {\n          id\n          question\n        }\n      }\n    }\n  }\n": types.PackageDialogFragmentDoc,
    "\n  mutation requestPackageUpdate($input: RequestPackageUpdateInput!) {\n    requestPackageUpdate(input: $input) {\n      clientMutationId\n    }\n  }\n": types.RequestPackageUpdateDocument,
    "\n  fragment TransferDialog on Policy {\n    id\n    insuredPerson {\n      id\n    }\n  }\n": types.TransferDialogFragmentDoc,
    "\n    fragment PolicyListInfo on Account {\n      policies {\n        edges {\n          node {\n            state\n            ...PolicyCard\n          }\n        }\n      }\n    }\n    \n  ": types.PolicyListInfoFragmentDoc,
    "\n  query myPolicies {\n    viewer {\n      id\n      verified\n      username\n      phone\n      fullName\n      ...PolicyListInfo\n    }\n  }\n  \n": types.MyPoliciesDocument,
    "\n  query validateMigrationCode($code: String!, $id: ID!) {\n    assocation(id: $id) {\n      migrationCode(code: $code) {\n        activated\n        insuredPerson {\n          id\n          fullName\n          birthdate\n        }\n      }\n    }\n  }\n": types.ValidateMigrationCodeDocument,
    "\n  mutation migratePerson($input: MigratePersonInput!) {\n    migratePerson(input: $input) {\n      viewer {\n        id\n        firstName\n        namePreposition\n        lastName\n      }\n    }\n  }\n": types.MigratePersonDocument,
    "\nmutation addAdditionalInsuranceQuote($input: AddAdditionalInsuranceQuoteForPolicyInput!) {\n  addAdditionalInsuranceQuoteFromPolicy(input: $input) {\n      premium\n      quoteExists\n  }\n}\n": types.AddAdditionalInsuranceQuoteDocument,
    "\nmutation updateAdditionalInsurance($input: UpdateAdditionalInsuranceInput!) {\n  updateAdditionalInsurance(input: $input) {\n    clientMutationId\n  }\n}\n": types.UpdateAdditionalInsuranceDocument,
    "\nmutation updateEndDate($input: CancelAdditionalInsuranceInput!) {\n  cancelAdditionalInsurance(input: $input) {\n    clientMutationId\n  }\n}\n": types.UpdateEndDateDocument,
    "\n  fragment AddressDetails on InsuredPerson {\n    id\n    address {\n      id\n      postalCode\n      houseNumber\n      suffix\n      street\n      city\n      countryCode\n    }\n  }\n": types.AddressDetailsFragmentDoc,
    "\n  mutation updateUsername($input: UpdateUsernameInput!) {\n    updateUsername(input: $input) {\n      user{\n        id\n        username\n      }\n    }\n  }\n": types.UpdateUsernameDocument,
    "\nmutation generateMigrationCode($input: AddMigrationCodeInput!) {\n    addMigrationCode(input: $input) {\n      migrationCode\n    }\n  }\n": types.GenerateMigrationCodeDocument,
    "\n  fragment ContactDetails on InsuredPerson {\n    id\n    account {\n      id\n      phone\n      username\n      fullName\n    }\n    ...InsuredPersonAccountDetails\n  }\n": types.ContactDetailsFragmentDoc,
    "\nquery searchAccountByUsername($username: String!) {\n  accountForUsername(username: $username) {\n    id\n    fullName\n  }\n}": types.SearchAccountByUsernameDocument,
    "\nfragment InsuredPersonAccountDetails on InsuredPerson {\n  id\n  initials\n  firstName\n  lastName\n  phone\n  namePreposition\n}": types.InsuredPersonAccountDetailsFragmentDoc,
    "\n  mutation deleteInsuredPerson($input: DeleteInsuredPersonInput!) {\n    deleteInsuredPerson(input: $input) {\n      deletedInsuredPersonId\n      __typename\n    }\n  }\n": types.DeleteInsuredPersonDocument,
    "\nfragment DangerZoneFragment on Policy {\n  id\n  insuredPerson {\n    id\n  }\n  ...ReactivatePolicy\n}": types.DangerZoneFragmentFragmentDoc,
    "\nmutation reactivePolicy($input: ReactivatePolicyInput!) {\n    reactivatePolicy(input: $input) {\n        clientMutationId\n    }\n}\n": types.ReactivePolicyDocument,
    "\nfragment ReactivatePolicy on Policy {\n    id\n    state   \n}": types.ReactivatePolicyFragmentDoc,
    "\n  fragment Eligibility on Policy {\n    eligibility {\n      id\n      question {\n        id\n        question\n      }\n      response\n      explanation\n    }\n  }\n": types.EligibilityFragmentDoc,
    "\nfragment EventEntry on Event {\n    id\n    date\n    description\n    creator {\n        id\n        fullName\n    }\n}\n": types.EventEntryFragmentDoc,
    "\nfragment HistoryPerson on InsuredPerson {\n    id\n    events {\n        id\n        ...EventEntry\n    }\n}\n": types.HistoryPersonFragmentDoc,
    "\n  fragment MemoDetails on InsuredPerson {\n    id\n    memo\n  }\n": types.MemoDetailsFragmentDoc,
    "\nmutation updateMemo($input: UpdateInsuredPersonInput!) {\n  updateInsuredPerson(input: $input) {\n    insuredPeople {\n      id\n      memo\n    }\n  }\n}\n": types.UpdateMemoDocument,
    "\n  mutation updatePackageQuote($input: UpdatePackageQuoteInput!) {\n    updatePackageQuote(input: $input) {\n      quote {\n        id\n        birthdate\n        premium\n        lumpsum\n        frequency\n      }\n      quoteExists\n    }\n  }\n": types.UpdatePackageQuoteDocument,
    "\n  fragment PackageDetails on Policy {\n    id\n    premium\n    lumpsum\n    frequency\n    package {\n      id\n      name\n    }\n    insuredAmount\n    ...UpdatePackageInsurer\n    ...UpdateFrequencyInsurer\n  }\n": types.PackageDetailsFragmentDoc,
    "\nfragment UpgradeablePackages on Policy {\n  id\n  upgradeablePackages {\n    id\n    name\n  }\n}\n": types.UpgradeablePackagesFragmentDoc,
    "\n  fragment UpdatePackageInsurer on Policy {\n    id\n    insuredPerson {\n      id\n      association {\n        id\n        eligibilityQuestions {\n          id\n          question\n        }\n      }\n    }\n    ...UpgradeablePackages\n  }\n": types.UpdatePackageInsurerFragmentDoc,
    "\n  query premiumTableEntryForFrequency(\n    $policyId: ID!\n    $targetFrequency: Frequency!\n  ) {\n    node(id: $policyId) {\n      ... on Policy {\n        id\n        premiumTableEntryForFrequency(targetFrequency: $targetFrequency) {\n          id\n          premium\n        }\n      }\n    }\n  }\n": types.PremiumTableEntryForFrequencyDocument,
    "\nmutation updateFrequencyMutation($input: UpdatePolicyInput!) {\n  updatePolicy(input: $input) {\n    policy {\n      id\n      premium\n      frequency\n    }\n  }\n}\n": types.UpdateFrequencyMutationDocument,
    "\nfragment UpdateFrequencyInsurer on Policy {\n  id\n  frequency\n  premium\n  package {\n    id\n    frequencies\n  }\n}": types.UpdateFrequencyInsurerFragmentDoc,
    "\n  mutation updatePackagePolicy($input: UpdatePackagePolicyInput!) {\n    updatePackagePolicy(input: $input) {\n      updatedPolicy {\n        id\n      }\n      oldPolicy {\n        id\n        __typename\n        premium\n        lumpsum\n        frequency\n        package {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.UpdatePackagePolicyDocument,
    "\n  fragment PaymentDetails on InsuredPerson {\n    id\n    payment {\n      id\n      bic\n      iban\n      mandateId\n      accountHolder\n      signatureDate\n      paymentMethod\n    }\n  }\n": types.PaymentDetailsFragmentDoc,
    "\n    mutation updateMandateId($paymentId: ID!, $mandateId: String!) {\n        updateMandateId(input: {\n            paymentId: $paymentId,\n            mandateId: $mandateId\n        }) {\n            payment {\n                id\n                mandateId\n            }\n        }\n    }\n": types.UpdateMandateIdDocument,
    "\n  mutation addSignatureDate($input: AddSignatureDateInput!) {\n    addSignatureDate(input: $input) {\n      payment {\n        id\n        signatureDate\n      }\n    }\n  }\n": types.AddSignatureDateDocument,
    "\nmutation updateInsuredPerson($input: UpdateInsuredPersonInput!) {\n  updateInsuredPerson(input: $input) {\n    insuredPeople {\n      id\n      firstName\n      initials\n      namePreposition\n      lastName\n      sex\n      phone\n      birthdate\n    }\n  }\n}": types.UpdateInsuredPersonDocument,
    "\n  fragment PersonDetails on Policy {\n    id\n    state\n    signupDate\n    signOffDate\n    ...PolicySignOffButton\n    __typename\n    insuredPerson {\n      id\n      firstName\n      initials\n      namePreposition\n      phone\n      lastName\n      sex\n      memberId\n      birthdate\n      age\n      payment {\n        id\n        signatureDate\n      }\n      ...personDialogFragment\n    }\n  }\n": types.PersonDetailsFragmentDoc,
    "\nmutation updateMemberId($input: UpdateMemberIdInput!) {\n  updateMemberId(input: $input) {\n    exists\n  }\n}": types.UpdateMemberIdDocument,
    "\n   fragment PolicySignOffButton on Policy {\n        id\n        state\n        signOffDate\n   } \n": types.PolicySignOffButtonFragmentDoc,
    "\nmutation updatePolicyMutationSignOff($input: UpdatePolicyInput!) {\n  updatePolicy(input: $input) {\n    policy {\n      id\n      signOffDate\n    }\n  }\n}\n": types.UpdatePolicyMutationSignOffDocument,
    "\nmutation updatePolicyMutation($input: UpdatePolicyInput!) {\n  updatePolicy(input: $input) {\n    policy {\n      id\n      premium\n      frequency\n      signupDate\n    }\n  }\n}\n": types.UpdatePolicyMutationDocument,
    "\n  mutation activatePolicy($input: ActivatePolicyInput!) {\n    activatePolicy(input: $input) {\n      policy {\n        id\n        __typename\n        signupDate\n      }\n      validDate\n    }\n  }\n": types.ActivatePolicyDocument,
    "\n  mutation deceasePolicy($input: DeceasePolicyInput!) {\n    deceasePolicy(input: $input) {\n      policy {\n        id\n        dateOfDeath\n        __typename\n      }\n    }\n  }\n": types.DeceasePolicyDocument,
    "\n  mutation cancelPolicy($input: CancelPolicyInput!) {\n    cancelPolicy(input: $input) {\n      policy {\n        id\n        cancellationDate\n        state\n        signOffDate\n        __typename\n      }\n    }\n  }\n": types.CancelPolicyDocument,
    "\n  mutation dischargePolicy($input: DischargePolicyInput!) {\n    dischargePolicy(input: $input) {\n      policy {\n        id\n        dischargeDate\n        __typename\n      }\n    }\n  }\n": types.DischargePolicyDocument,
    "\n  mutation rejectPolicy($input: RejectPolicyInput!) {\n    rejectPolicy(input: $input) {\n      policy {\n        id\n        __typename\n        rejectionDate\n      }\n    }\n  }\n": types.RejectPolicyDocument,
    "\n  fragment personDialogFragment on InsuredPerson {\n    id\n    firstName\n    initials\n    namePreposition\n    lastName\n    sex\n  }\n": types.PersonDialogFragmentFragmentDoc,
    "\n  query singlePolicy($id: ID!) {\n    viewer {\n      id\n      association {\n        id\n        additionalInsurancePossible\n        maxInsuredAmountAdditionalInsurance\n        minInsuredAmountAdditionalInsurance\n      }\n      policy(id: $id) {\n         id\n         __typename\n         ...DangerZoneFragment\n         insuredPerson{\n           id\n           ...ContactDetails\n           ...InsuredPersonAccountDetails\n           ...AddressDetails\n           ...MemoDetails\n           ...PaymentDetails\n           ...HistoryPerson\n         }\n         additionalInsurance {\n          id\n          insuredAmount\n          premium\n          lumpsum\n          startDate\n          endDate\n          frequency\n        }\n         ...PackageDetails\n         ...PersonDetails\n         ...Eligibility\n      }\n    }\n  }\n": types.SinglePolicyDocument,
    "\n  fragment EligibilityQuestionFragment on Association {\n    id\n    eligibilityQuestions {\n      question\n      id\n    }\n  }\n": types.EligibilityQuestionFragmentFragmentDoc,
    "\n  fragment SignupPremiumFragment on Association {\n    packages(state: ACTIVE) {\n      id\n      name\n      insuredAmount\n      frequencies\n    }\n  }\n": types.SignupPremiumFragmentFragmentDoc,
    "\n  fragment FinalCardFragment on Association {\n    id\n    statutesUrl\n    name\n  }\n": types.FinalCardFragmentFragmentDoc,
    "\n  query AddPolicySignup {\n    viewer {\n      id\n      association {\n        id\n        eligibilityQuestions {\n          id\n        }\n        ...SignupPremiumFragment\n        ...EligibilityQuestionFragment\n        ...FinalCardFragment\n      }\n    }\n  }\n\n  \n  \n  \n": types.AddPolicySignupDocument,
    "\n  query Signup($associationId: ID!) {\n    node(id: $associationId) {\n      id\n      ... on Association {\n        eligibilityQuestions {\n          id\n        }\n        ...SignupPremiumFragment\n        ...EligibilityQuestionFragment\n        ...FinalCardFragment\n      }\n    }\n  }\n\n  \n  \n  \n": types.SignupDocument,
    "\n  query Acceptance {\n    viewer {\n      id\n      policiesAccepted: policies(state: ACCEPTED, first: 50) {\n        ...AanmeldingenList\n        totalCount\n      }\n      policiesPending: policies(state: PENDING, first: 50) {\n        ...AanmeldingenList\n        totalCount\n      }\n    }\n  }\n  \n": types.AcceptanceDocument,
    "\n  fragment RegistrationCardFragment on Policy {\n    id\n    ...Userinfo\n    ...Lidmaatschapinfo\n  }\n  \n  \n": types.RegistrationCardFragmentFragmentDoc,
    "\n    fragment Lidmaatschapinfo on Policy {\n      id\n      package {\n        id\n        name\n      }\n      premium\n      frequency\n    }\n  ": types.LidmaatschapinfoFragmentDoc,
    "\n    fragment Userinfo on Policy {\n      id\n      insuredPerson {\n        id\n        fullName\n        sex\n        birthdate\n        account {\n          id\n          username\n        }\n      }\n    }\n  ": types.UserinfoFragmentDoc,
    "\n    fragment AanmeldingenList on PolicyConnection {\n      edges {\n        node {\n          ...RegistrationCardFragment\n        }\n      }\n    }\n    \n  ": types.AanmeldingenListFragmentDoc,
    "\nmutation updateAssociation($input: UpdateAssociationInput!) {\n    updateAssociation(input: $input) {\n      clientMutationId\n    }\n  }": types.UpdateAssociationDocument,
    "\nquery associationDetails {\n    viewer {\n      association {\n        id\n        name\n        membershipCardTemplateKey\n        memberShipCardDownloadUrl\n      }\n    }\n  }\n": types.AssociationDetailsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation activate($input: ActivateAccountInput!) {\n        activateAccount(input: $input) {\n          activated\n        }\n      }\n    "): (typeof documents)["\n      mutation activate($input: ActivateAccountInput!) {\n        activateAccount(input: $input) {\n          activated\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n        mutation addQuote($input: AddQuoteInput!) {\n          addQuote(input: $input) {\n            quote {\n              id\n              birthdate\n              premium\n              lumpsum\n              frequency\n              package {\n                id\n                name\n                insuredAmount\n              }\n            }\n            quoteExists\n          }\n        }\n      "): (typeof documents)["\n        mutation addQuote($input: AddQuoteInput!) {\n          addQuote(input: $input) {\n            quote {\n              id\n              birthdate\n              premium\n              lumpsum\n              frequency\n              package {\n                id\n                name\n                insuredAmount\n              }\n            }\n            quoteExists\n          }\n        }\n      "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation forgot($input: ForgotPasswordInput!) {\n        forgotPassword(input: $input) {\n          exists\n        }\n      }\n    "): (typeof documents)["\n      mutation forgot($input: ForgotPasswordInput!) {\n        forgotPassword(input: $input) {\n          exists\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery auth($username: String!, $password: String!, $associationId: ID!) {\n  authenticate(username: $username, password: $password, associationId: $associationId) {\n    value\n    permissions\n    association {\n      id\n    }\n  }\n}\n"): (typeof documents)["\nquery auth($username: String!, $password: String!, $associationId: ID!) {\n  authenticate(username: $username, password: $password, associationId: $associationId) {\n    value\n    permissions\n    association {\n      id\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation reset($input: ResetPasswordInput!) {\n        resetPassword(input: $input) {\n          active\n        }\n      }\n    "): (typeof documents)["\n      mutation reset($input: ResetPasswordInput!) {\n        resetPassword(input: $input) {\n          active\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query retrievePackages($associationId: ID!) {\n        packages(associationId: $associationId) {\n          id\n          name\n          insuredAmount\n          frequencies\n        }\n      }\n    "): (typeof documents)["\n      query retrievePackages($associationId: ID!) {\n        packages(associationId: $associationId) {\n          id\n          name\n          insuredAmount\n          frequencies\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query checkUsernameAvailable($username: String!, $associationId: ID!) {\n        checkUsernameAvailable(username: $username, associationId: $associationId)\n      }\n    "): (typeof documents)["\n      query checkUsernameAvailable($username: String!, $associationId: ID!) {\n        checkUsernameAvailable(username: $username, associationId: $associationId)\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation addAccount($input: AddAccountInput!) {\n        addAccount(input: $input) {\n          clientMutationId\n        }\n      }\n    "): (typeof documents)["\n      mutation addAccount($input: AddAccountInput!) {\n        addAccount(input: $input) {\n          clientMutationId\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation addPolicy($input: AddPolicyInput!) {\n        addPolicy(input: $input) {\n          policy {\n            id\n          }\n        }\n      }\n    "): (typeof documents)["\n      mutation addPolicy($input: AddPolicyInput!) {\n        addPolicy(input: $input) {\n          policy {\n            id\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query completeAddress(\n    $postalCode: String!\n    $houseNumber: Int!\n    $suffix: String\n  ) {\n    completeAddress(\n      postalCode: $postalCode\n      houseNumber: $houseNumber\n      suffix: $suffix\n    ) {\n      address {\n        city\n        street\n        postalCode\n        country\n        houseNumber\n        suffix\n      }\n      unknownSuffix\n      unknownPostalCode\n      unknownHouseNumber\n    }\n  }\n"): (typeof documents)["\n  query completeAddress(\n    $postalCode: String!\n    $houseNumber: Int!\n    $suffix: String\n  ) {\n    completeAddress(\n      postalCode: $postalCode\n      houseNumber: $houseNumber\n      suffix: $suffix\n    ) {\n      address {\n        city\n        street\n        postalCode\n        country\n        houseNumber\n        suffix\n      }\n      unknownSuffix\n      unknownPostalCode\n      unknownHouseNumber\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateAddress($input: UpdateAddressInput!) {\n    updateAddress(input: $input) {\n      addresses {\n        id\n        postalCode\n        houseNumber\n        suffix\n        street\n        city\n        countryCode\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateAddress($input: UpdateAddressInput!) {\n    updateAddress(input: $input) {\n      addresses {\n        id\n        postalCode\n        houseNumber\n        suffix\n        street\n        city\n        countryCode\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      query retrieveEligibilityQuestions($associationId: ID!) {\n        assocation(id: $associationId) {\n          eligibilityQuestions {\n            question\n            id\n          }\n        }\n      }\n    "): (typeof documents)["\n      query retrieveEligibilityQuestions($associationId: ID!) {\n        assocation(id: $associationId) {\n          eligibilityQuestions {\n            question\n            id\n          }\n        }\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateToDirectDebit($input: UpdateToDirectDebitInput!) {\n    updateToDirectDebit(input: $input) {\n      payments {\n        id\n        iban\n        paymentMethod\n        bic\n        signatureDate\n        mandateId\n        accountHolder\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateToDirectDebit($input: UpdateToDirectDebitInput!) {\n    updateToDirectDebit(input: $input) {\n      payments {\n        id\n        iban\n        paymentMethod\n        bic\n        signatureDate\n        mandateId\n        accountHolder\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateToInvoice($input: UpdateToInvoiceInput!) {\n    updateToInvoice(input: $input) {\n      payments {\n        id\n        paymentMethod\n        iban\n        bic\n        accountHolder\n        signatureDate\n        mandateId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateToInvoice($input: UpdateToInvoiceInput!) {\n    updateToInvoice(input: $input) {\n      payments {\n        id\n        paymentMethod\n        iban\n        bic\n        accountHolder\n        signatureDate\n        mandateId\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment AccountDetailsInfo on Account {\n    id\n    username\n    verified\n    fullName\n    firstName\n    lastName\n    namePreposition\n    phone\n}\n"): (typeof documents)["\nfragment AccountDetailsInfo on Account {\n    id\n    username\n    verified\n    fullName\n    firstName\n    lastName\n    namePreposition\n    phone\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation addToAccount($input: AddInsuredPersonToAccountInput!) {\n    addInsuredPersonToAccount(input: $input) {\n      clientMutationId\n      account {\n          id\n      }\n    }\n  }"): (typeof documents)["\nmutation addToAccount($input: AddInsuredPersonToAccountInput!) {\n    addInsuredPersonToAccount(input: $input) {\n      clientMutationId\n      account {\n          id\n      }\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery findMember($memberId: ID!) {\n    insuredPersonForMemberId(memberId: $memberId) {\n      id\n      memberId\n      fullName\n      birthdate\n      account {\n        id\n        username\n      }\n    }\n}"): (typeof documents)["\nquery findMember($memberId: ID!) {\n    insuredPersonForMemberId(memberId: $memberId) {\n      id\n      memberId\n      fullName\n      birthdate\n      account {\n        id\n        username\n      }\n    }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation deleteAccount($input: DeleteAccountInput!) {\n    deleteAccount(input: $input) {\n      deletedAccountId\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation deleteAccount($input: DeleteAccountInput!) {\n    deleteAccount(input: $input) {\n      deletedAccountId\n      __typename\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment InsuredPeopleList on Account {\n    id\n    insuredPeople {\n      id\n      memberId\n      fullName\n      sex\n      birthdate\n      age\n      payment {\n        iban\n        accountHolder\n        paymentMethod\n      }\n      address {\n        id\n        postalCode\n        houseNumber\n        suffix\n        street\n        city\n        countryCode\n      }\n      policies {\n          id\n          state\n          package {\n            id\n            name\n          }\n          signupDate\n          insuredAmount\n          signOffDate\n          premium\n          frequency\n          additionalInsurance {\n            id\n            endDate\n          }\n      }\n      \n    }\n}\n\n"): (typeof documents)["\nfragment InsuredPeopleList on Account {\n    id\n    insuredPeople {\n      id\n      memberId\n      fullName\n      sex\n      birthdate\n      age\n      payment {\n        iban\n        accountHolder\n        paymentMethod\n      }\n      address {\n        id\n        postalCode\n        houseNumber\n        suffix\n        street\n        city\n        countryCode\n      }\n      policies {\n          id\n          state\n          package {\n            id\n            name\n          }\n          signupDate\n          insuredAmount\n          signOffDate\n          premium\n          frequency\n          additionalInsurance {\n            id\n            endDate\n          }\n      }\n      \n    }\n}\n\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation removeFromAccount($input: RemoveInsuredPersonFromAccountInput!) {\n    removeInsuredPersonFromAccount(input: $input) {\n      clientMutationId\n    }\n}"): (typeof documents)["\nmutation removeFromAccount($input: RemoveInsuredPersonFromAccountInput!) {\n    removeInsuredPersonFromAccount(input: $input) {\n      clientMutationId\n    }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updateAccountUsername($input: UpdateUsernameInput!) {\n  updateUsername(input: $input) {\n    clientMutationId\n  }\n}\n"): (typeof documents)["\nmutation updateAccountUsername($input: UpdateUsernameInput!) {\n  updateUsername(input: $input) {\n    clientMutationId\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery accountNode($accountId: ID!) {\n  account(id: $accountId) {\n    id\n    ...AccountDetailsInfo\n    ...InsuredPeopleList\n  }\n}\n\n"): (typeof documents)["\nquery accountNode($accountId: ID!) {\n  account(id: $accountId) {\n    id\n    ...AccountDetailsInfo\n    ...InsuredPeopleList\n  }\n}\n\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updateAccount($input: UpdateAccountInput!) {\n  updateAccount(input: $input) {\n    clientMutationId\n  }\n}"): (typeof documents)["\nmutation updateAccount($input: UpdateAccountInput!) {\n  updateAccount(input: $input) {\n    clientMutationId\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery accountsOverview($first: Int!, $offset: Int!, $query: String, $findGhostAccounts: Boolean!) {\n    accounts(first: $first, offset: $offset, query: $query, findGhostAccounts: $findGhostAccounts) {\n      accounts {\n        id\n        username\n        verified\n        fullName\n        phone\n      }\n      totalCount\n      insuredPersonCount\n    }\n}"): (typeof documents)["\nquery accountsOverview($first: Int!, $offset: Int!, $query: String, $findGhostAccounts: Boolean!) {\n    accounts(first: $first, offset: $offset, query: $query, findGhostAccounts: $findGhostAccounts) {\n      accounts {\n        id\n        username\n        verified\n        fullName\n        phone\n      }\n      totalCount\n      insuredPersonCount\n    }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query searchAdministration(\n    $query: String!\n    $after: String\n    $states: [PolicyState!]\n    $searchType: SearchType\n    $matchType: MatchType\n  ) {\n    viewer {\n      id\n      association {\n        id\n        membershipCardTemplateKey\n      }\n      search(\n        query: $query\n        first: 10\n        after: $after\n        states: $states\n        searchType: $searchType\n        matchType: $matchType\n      ) {\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n        totalCount\n        ...search\n      }\n    }\n  }\n  \n"): (typeof documents)["\n  query searchAdministration(\n    $query: String!\n    $after: String\n    $states: [PolicyState!]\n    $searchType: SearchType\n    $matchType: MatchType\n  ) {\n    viewer {\n      id\n      association {\n        id\n        membershipCardTemplateKey\n      }\n      search(\n        query: $query\n        first: 10\n        after: $after\n        states: $states\n        searchType: $searchType\n        matchType: $matchType\n      ) {\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n        totalCount\n        ...search\n      }\n    }\n  }\n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation createDump($input: CreateDumpInput!) {\n  createDump(input: $input) {\n    url\n  }\n}\n"): (typeof documents)["\nmutation createDump($input: CreateDumpInput!) {\n  createDump(input: $input) {\n    url\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation generateMembershipCards {\n  generateMembershipCard(input: {}) {\n    urls\n  }\n}"): (typeof documents)["\nmutation generateMembershipCards {\n  generateMembershipCard(input: {}) {\n    urls\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment card on InsuredPerson {\n    id\n    account {\n      id\n    }\n    policies {\n      id\n      ...policy\n    }\n    ...user\n    ...multiPoliciesDialog\n  }\n\n  \n  \n  \n"): (typeof documents)["\n  fragment card on InsuredPerson {\n    id\n    account {\n      id\n    }\n    policies {\n      id\n      ...policy\n    }\n    ...user\n    ...multiPoliciesDialog\n  }\n\n  \n  \n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment multiPoliciesDialog on InsuredPerson {\n    id\n    memberId\n    fullName\n    policies {\n      id\n      ...multiPolicyCard\n    }\n  }\n  \n"): (typeof documents)["\n  fragment multiPoliciesDialog on InsuredPerson {\n    id\n    memberId\n    fullName\n    policies {\n      id\n      ...multiPolicyCard\n    }\n  }\n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment multiPolicyCard on Policy {\n    id\n    package {\n      id\n      name\n    }\n    premium\n    frequency\n    signupDate\n    state\n  }\n"): (typeof documents)["\n  fragment multiPolicyCard on Policy {\n    id\n    package {\n      id\n      name\n    }\n    premium\n    frequency\n    signupDate\n    state\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment policy on Policy {\n    id\n    package {\n      id\n      name\n    }\n    premium\n    frequency\n    signupDate\n    state\n  }\n"): (typeof documents)["\n  fragment policy on Policy {\n    id\n    package {\n      id\n      name\n    }\n    premium\n    frequency\n    signupDate\n    state\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment user on InsuredPerson {\n    id\n    memberId\n    fullName\n    age\n    birthdate\n    sex\n    payment {\n      id\n      iban\n    }\n    address {\n      id\n      houseNumber\n      postalCode\n      suffix\n      street\n      city\n      countryCode\n    }\n  }\n"): (typeof documents)["\n  fragment user on InsuredPerson {\n    id\n    memberId\n    fullName\n    age\n    birthdate\n    sex\n    payment {\n      id\n      iban\n    }\n    address {\n      id\n      houseNumber\n      postalCode\n      suffix\n      street\n      city\n      countryCode\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment search on SearchResultConnection {\n    edges {\n      node {\n        ... on InsuredPerson {\n          id\n          ...card\n        }\n      }\n    }\n  }\n  \n"): (typeof documents)["\n  fragment search on SearchResultConnection {\n    edges {\n      node {\n        ... on InsuredPerson {\n          id\n          ...card\n        }\n      }\n    }\n  }\n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updatePassword($input: UpdatePasswordInput!) {\n    updatePassword(input: $input) {\n      account{\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updatePassword($input: UpdatePasswordInput!) {\n    updatePassword(input: $input) {\n      account{\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query QueryForUser {\n    viewer {\n      id\n      ...ProfileInfoBestuurder\n      ...PersonInfo\n    }\n  }\n"): (typeof documents)["\n  query QueryForUser {\n    viewer {\n      id\n      ...ProfileInfoBestuurder\n      ...PersonInfo\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment PersonInfo on Account {\n      id\n      username\n    }\n  "): (typeof documents)["\n    fragment PersonInfo on Account {\n      id\n      username\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ProfileInfoBestuurder on Account {\n    id\n    fullName\n  }"): (typeof documents)["\n  fragment ProfileInfoBestuurder on Account {\n    id\n    fullName\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation newAccount($input: AddAccountInput!) {\n  addAccount(input: $input) {\n    account {\n      id\n      username\n    }\n  }\n}\n"): (typeof documents)["\nmutation newAccount($input: AddAccountInput!) {\n  addAccount(input: $input) {\n    account {\n      id\n      username\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query checkAccount($username: String!) {\n    viewer {\n      id\n      account(username: $username) {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query checkAccount($username: String!) {\n    viewer {\n      id\n      account(username: $username) {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ContributionFragment on Association {\n    packages {\n      id\n      name\n      insuredAmount\n      frequencies\n    }\n  }\n"): (typeof documents)["\n  fragment ContributionFragment on Association {\n    packages {\n      id\n      name\n      insuredAmount\n      frequencies\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation addPolicyCustom($input: AddCustomPolicyInput!) {\n    addCustomPolicy(input: $input) {\n      policy {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation addPolicyCustom($input: AddCustomPolicyInput!) {\n    addCustomPolicy(input: $input) {\n      policy {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PolicySignup {\n    viewer {\n      id\n      association {\n        id\n        eligibilityQuestions {\n          id\n          question\n        }\n        ...ContributionFragment\n      }\n    }\n  }\n  \n"): (typeof documents)["\n  query PolicySignup {\n    viewer {\n      id\n      association {\n        id\n        eligibilityQuestions {\n          id\n          question\n        }\n        ...ContributionFragment\n      }\n    }\n  }\n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query memberIdQuery(\n    $memberId: String\n    $lastName: String\n    $birthdate: Date\n    $after: String\n  ) {\n    viewer {\n      id\n      verifyMember(\n        memberId: $memberId\n        lastName: $lastName\n        birthdate: $birthdate\n        first: 50\n        after: $after\n      ) {\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n        totalCount\n        edges {\n          node {\n            id\n          }\n        }\n        ...PolicyListFragment\n      }\n    }\n  }\n  \n"): (typeof documents)["\n  query memberIdQuery(\n    $memberId: String\n    $lastName: String\n    $birthdate: Date\n    $after: String\n  ) {\n    viewer {\n      id\n      verifyMember(\n        memberId: $memberId\n        lastName: $lastName\n        birthdate: $birthdate\n        first: 50\n        after: $after\n      ) {\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n        totalCount\n        edges {\n          node {\n            id\n          }\n        }\n        ...PolicyListFragment\n      }\n    }\n  }\n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PolicyCardFragment on Policy {\n    id\n    insuredPerson {\n      id\n      association {\n        id\n        name\n      }\n    }\n    state\n    ...clientCheckUser\n    ...clientCheckPolicy\n    ...ReportDeathFragment\n  }\n  \n  \n  \n"): (typeof documents)["\n  fragment PolicyCardFragment on Policy {\n    id\n    insuredPerson {\n      id\n      association {\n        id\n        name\n      }\n    }\n    state\n    ...clientCheckUser\n    ...clientCheckPolicy\n    ...ReportDeathFragment\n  }\n  \n  \n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment clientCheckPolicy on Policy {\n    id\n    package {\n      id\n      name\n    }\n    insuredPerson {\n      id\n      memberId\n    }\n    signupDate\n    signOffDate\n    state\n  }\n"): (typeof documents)["\n  fragment clientCheckPolicy on Policy {\n    id\n    package {\n      id\n      name\n    }\n    insuredPerson {\n      id\n      memberId\n    }\n    signupDate\n    signOffDate\n    state\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment clientCheckUser on Policy {\n    id\n    insuredPerson {\n      id\n      lastName\n      initials\n      birthdate\n      sex\n    }\n  }\n"): (typeof documents)["\n  fragment clientCheckUser on Policy {\n    id\n    insuredPerson {\n      id\n      lastName\n      initials\n      birthdate\n      sex\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PolicyListFragment on PolicyConnection {\n    edges {\n      node {\n        ... on Policy {\n          id\n          ...PolicyCardFragment\n        }\n      }\n    }\n  }\n  \n"): (typeof documents)["\n  fragment PolicyListFragment on PolicyConnection {\n    edges {\n      node {\n        ... on Policy {\n          id\n          ...PolicyCardFragment\n        }\n      }\n    }\n  }\n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ReportDeathFragment on Policy {\n    id\n    insuredPerson {\n      id\n      association {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment ReportDeathFragment on Policy {\n    id\n    insuredPerson {\n      id\n      association {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation reportDeath($input: ReportDeathInput!) {\n    reportDeath(input: $input) {\n      clientMutationId\n    }\n  }\n"): (typeof documents)["\n  mutation reportDeath($input: ReportDeathInput!) {\n    reportDeath(input: $input) {\n      clientMutationId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n      mutation sendMail(\n        $attachmentKeys: [ID!]\n        $subject: String\n        $body: String!\n      ) {\n        sendMail(\n          attachmentKeys: $attachmentKeys\n          subject: $subject\n          body: $body\n        )\n      }\n    "): (typeof documents)["\n      mutation sendMail(\n        $attachmentKeys: [ID!]\n        $subject: String\n        $body: String!\n      ) {\n        sendMail(\n          attachmentKeys: $attachmentKeys\n          subject: $subject\n          body: $body\n        )\n      }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery uploadUrl($filename: String!, $bucketType: BucketType!) {\n  getUploadUrl(filename: $filename, bucketType: $bucketType) {\n    url\n    key\n  }\n}\n"): (typeof documents)["\nquery uploadUrl($filename: String!, $bucketType: BucketType!) {\n  getUploadUrl(filename: $filename, bucketType: $bucketType) {\n    url\n    key\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment assetPortfolio on AssetPortfolio {\n    id\n    date\n    currency\n    assets {\n      edges {\n        node {\n          id\n          name\n          value\n          category\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment assetPortfolio on AssetPortfolio {\n    id\n    date\n    currency\n    assets {\n      edges {\n        node {\n          id\n          name\n          value\n          category\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment balanceSheet on BalanceSheet {\n    id\n    date\n    currency\n    asset {\n      name\n      value\n      children {\n        edges {\n          node {\n            name\n            value\n          }\n        }\n      }\n    }\n    liability {\n      name\n      value\n      children {\n        edges {\n          node {\n            name\n            value\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment balanceSheet on BalanceSheet {\n    id\n    date\n    currency\n    asset {\n      name\n      value\n      children {\n        edges {\n          node {\n            name\n            value\n          }\n        }\n      }\n    }\n    liability {\n      name\n      value\n      children {\n        edges {\n          node {\n            name\n            value\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment fundingRatio on FundingRatio {\n    id\n    date\n    value\n  }\n"): (typeof documents)["\n  fragment fundingRatio on FundingRatio {\n    id\n    date\n    value\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment interestCurves on InterestCurve {\n    id\n    date\n    rate_10: rate(maturity: 10) {\n      rate\n      id\n    }\n    rate_30: rate(maturity: 30) {\n      rate\n      id\n    }\n  }\n"): (typeof documents)["\n  fragment interestCurves on InterestCurve {\n    id\n    date\n    rate_10: rate(maturity: 10) {\n      rate\n      id\n    }\n    rate_30: rate(maturity: 30) {\n      rate\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment policiesPerDate on PolicyCount {\n    date\n    count\n  }\n"): (typeof documents)["\n  fragment policiesPerDate on PolicyCount {\n    date\n    count\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment polsThisYear on PolicyConnection {\n    totalCount\n  }\n"): (typeof documents)["\n  fragment polsThisYear on PolicyConnection {\n    totalCount\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery eventsTimeline($first: Int!, $offset: Int!, $types: [EventType!]) {\n  getEvents(first: $first, offset: $offset, types: $types) {\n    events {\n      id\n      description\n      type\n      date\n      creator {\n        id\n        fullName\n      }\n      insuredPerson {\n        id\n        memberId\n        fullName\n        policies {\n          id\n        }\n      }\n    }\n    totalCount\n   \n  }\n}\n"): (typeof documents)["\nquery eventsTimeline($first: Int!, $offset: Int!, $types: [EventType!]) {\n  getEvents(first: $first, offset: $offset, types: $types) {\n    events {\n      id\n      description\n      type\n      date\n      creator {\n        id\n        fullName\n      }\n      insuredPerson {\n        id\n        memberId\n        fullName\n        policies {\n          id\n        }\n      }\n    }\n    totalCount\n   \n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery StatutesData($associationId: ID!) {\n    node(id: $associationId) {\n        id\n        ...on Association {\n            statutesUrl\n            sepaUrl\n        }\n    }\n}\n"): (typeof documents)["\nquery StatutesData($associationId: ID!) {\n    node(id: $associationId) {\n        id\n        ...on Association {\n            statutesUrl\n            sepaUrl\n        }\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n\nfragment IpGrouping on InsuredPeopleGrouping {\n    insuredPeople {\n        id\n    }\n    phone\n    account {\n        id\n        username\n    }\n    address {\n      id\n      postalCode\n      suffix\n      houseNumber\n      suffix\n      street\n      city\n      countryCode\n    }\n    payment {\n      iban\n      bic\n      accountHolder\n      paymentMethod\n    }\n    insuredPeople {\n        id\n        phone\n    }\n    premium {\n        policy {\n            yearPremium\n            termPremium {\n                premium\n                frequency\n            }\n        }\n        additionalInsurance {\n            yearPremium\n            termPremium {\n                premium\n                frequency\n            }\n        }\n    }\n  }"): (typeof documents)["\n\nfragment IpGrouping on InsuredPeopleGrouping {\n    insuredPeople {\n        id\n    }\n    phone\n    account {\n        id\n        username\n    }\n    address {\n      id\n      postalCode\n      suffix\n      houseNumber\n      suffix\n      street\n      city\n      countryCode\n    }\n    payment {\n      iban\n      bic\n      accountHolder\n      paymentMethod\n    }\n    insuredPeople {\n        id\n        phone\n    }\n    premium {\n        policy {\n            yearPremium\n            termPremium {\n                premium\n                frequency\n            }\n        }\n        additionalInsurance {\n            yearPremium\n            termPremium {\n                premium\n                frequency\n            }\n        }\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment InsuredPeopleGroupingList on InsuredPeopleGrouping {\n    phone\n    account { id }\n    insuredPeople {\n        id\n        memberId\n        phone\n        fullName\n        sex\n        phone\n        birthdate\n        age\n        account {\n            id\n            username\n            phone\n        }\n        policies {\n            id\n            state\n            package {\n              id\n              name\n            }\n            signupDate\n            insuredAmount\n            signOffDate\n            premium\n            frequency\n            additionalInsurance {\n              id\n              startDate\n              endDate\n              premium\n              insuredAmount\n              frequency\n            }\n      }\n    }\n      \n}"): (typeof documents)["\nfragment InsuredPeopleGroupingList on InsuredPeopleGrouping {\n    phone\n    account { id }\n    insuredPeople {\n        id\n        memberId\n        phone\n        fullName\n        sex\n        phone\n        birthdate\n        age\n        account {\n            id\n            username\n            phone\n        }\n        policies {\n            id\n            state\n            package {\n              id\n              name\n            }\n            signupDate\n            insuredAmount\n            signOffDate\n            premium\n            frequency\n            additionalInsurance {\n              id\n              startDate\n              endDate\n              premium\n              insuredAmount\n              frequency\n            }\n      }\n    }\n      \n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery insuredPersonGroup($insuredPersonId: ID!) {\n  viewer {\n    association {\n      id\n      membershipCardTemplateKey\n    }\n  }\n    insuredPeopleGroup(id: $insuredPersonId) {\n      insuredPeople {\n        id\n    }\n      ...InsuredPeopleGroupingList\n      ...IpGrouping\n    }\n  }\n  "): (typeof documents)["\nquery insuredPersonGroup($insuredPersonId: ID!) {\n  viewer {\n    association {\n      id\n      membershipCardTemplateKey\n    }\n  }\n    insuredPeopleGroup(id: $insuredPersonId) {\n      insuredPeople {\n        id\n    }\n      ...InsuredPeopleGroupingList\n      ...IpGrouping\n    }\n  }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation generateMembershipCard($input: GenerateMembershipCardInput!) {\n  generateMembershipCard(input: $input) {\n    urls\n  }\n}"): (typeof documents)["\nmutation generateMembershipCard($input: GenerateMembershipCardInput!) {\n  generateMembershipCard(input: $input) {\n    urls\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UserQuery {\n    viewer {\n      id\n      verified\n      ...ProfileInfo\n      ...PersonalInfo\n    }\n  }\n  \n  \n"): (typeof documents)["\n  query UserQuery {\n    viewer {\n      id\n      verified\n      ...ProfileInfo\n      ...PersonalInfo\n    }\n  }\n  \n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateAccountMember($input: UpdateAccountInput!) {\n    updateAccount(input: $input) {\n      user {\n        id\n        lastName\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateAccountMember($input: UpdateAccountInput!) {\n    updateAccount(input: $input) {\n      user {\n        id\n        lastName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UsernameDialog on Account {\n    id\n    username\n  }\n"): (typeof documents)["\n  fragment UsernameDialog on Account {\n    id\n    username\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateUsernameMember($input: UpdateUsernameInput!) {\n    updateUsername(input: $input) {\n      account {\n        id\n        username\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateUsernameMember($input: UpdateUsernameInput!) {\n    updateUsername(input: $input) {\n      account {\n        id\n        username\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PersonalInfo on Account {\n    id\n    username\n    phone\n    ...UsernameDialog\n  }\n  \n"): (typeof documents)["\n  fragment PersonalInfo on Account {\n    id\n    username\n    phone\n    ...UsernameDialog\n  }\n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ProfileInfo on Account {\n    id\n    firstName\n    lastName\n  }\n"): (typeof documents)["\n  fragment ProfileInfo on Account {\n    id\n    firstName\n    lastName\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UserQueryHeader {\n    viewer {\n      id\n      firstName\n      lastName\n    }\n  }\n"): (typeof documents)["\n  query UserQueryHeader {\n    viewer {\n      id\n      firstName\n      lastName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment PolicyCard on Policy {\n      id\n      signOffDate\n      premium\n      lumpsum\n      frequency\n      package {\n        id\n        name\n        insuredAmount\n      }\n      insuredPerson {\n        id\n        memberId\n        fullName\n        birthdate\n        sex\n        association {\n          id\n          name\n        }\n      }\n      signupDate\n      state\n      ...DialogComponents\n    }\n    \n  "): (typeof documents)["\n    fragment PolicyCard on Policy {\n      id\n      signOffDate\n      premium\n      lumpsum\n      frequency\n      package {\n        id\n        name\n        insuredAmount\n      }\n      insuredPerson {\n        id\n        memberId\n        fullName\n        birthdate\n        sex\n        association {\n          id\n          name\n        }\n      }\n      signupDate\n      state\n      ...DialogComponents\n    }\n    \n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AddressDialog on Policy {\n    insuredPerson {\n      id\n    }\n  }\n"): (typeof documents)["\n  fragment AddressDialog on Policy {\n    insuredPerson {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment AddressInfo on Policy {\n      id\n      insuredPerson {\n        id\n        address {\n          id\n          postalCode\n          houseNumber\n          suffix\n          street\n          city\n        }\n      }\n    }\n  "): (typeof documents)["\n    fragment AddressInfo on Policy {\n      id\n      insuredPerson {\n        id\n        address {\n          id\n          postalCode\n          houseNumber\n          suffix\n          street\n          city\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment DetailsPolicyFragment on Policy {\n    ...MemberPaymentDetails\n    ...AddressInfo\n    ...PolicyPackageDetails\n    ...MemberDetails\n    additionalInsurance {\n      id\n      insuredAmount\n      premium\n      lumpsum\n      startDate\n      endDate\n      frequency\n    }\n  }\n  \n  \n  \n  \n"): (typeof documents)["\n  fragment DetailsPolicyFragment on Policy {\n    ...MemberPaymentDetails\n    ...AddressInfo\n    ...PolicyPackageDetails\n    ...MemberDetails\n    additionalInsurance {\n      id\n      insuredAmount\n      premium\n      lumpsum\n      startDate\n      endDate\n      frequency\n    }\n  }\n  \n  \n  \n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PolicyPackageDetails on Policy {\n    premium\n    package {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment PolicyPackageDetails on Policy {\n    premium\n    package {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MemberPaymentDetails on Policy {\n    id\n    frequency\n    insuredPerson {\n      id\n      payment {\n        id\n        iban\n        paymentMethod\n        accountHolder\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment MemberPaymentDetails on Policy {\n    id\n    frequency\n    insuredPerson {\n      id\n      payment {\n        id\n        iban\n        paymentMethod\n        accountHolder\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MemberDetails on Policy {\n    insuredPerson {\n      id\n      memberId\n      fullName\n      sex\n      birthdate\n    }\n  }\n"): (typeof documents)["\n  fragment MemberDetails on Policy {\n    insuredPerson {\n      id\n      memberId\n      fullName\n      sex\n      birthdate\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment DialogComponents on Policy {\n    id\n    insuredPerson {\n      id\n    }\n    ...TransferDialogDef\n    ...DetailsPolicyFragment\n    ...AddressDialog\n    ...UpdatePackage\n  }\n\n  \n  \n  \n  \n"): (typeof documents)["\n  fragment DialogComponents on Policy {\n    id\n    insuredPerson {\n      id\n    }\n    ...TransferDialogDef\n    ...DetailsPolicyFragment\n    ...AddressDialog\n    ...UpdatePackage\n  }\n\n  \n  \n  \n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation addMigrationCode($input: AddMigrationCodeInput!) {\n    addMigrationCode(input: $input) {\n      migrationCode\n    }\n  }\n"): (typeof documents)["\n  mutation addMigrationCode($input: AddMigrationCodeInput!) {\n    addMigrationCode(input: $input) {\n      migrationCode\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment TransferDialogDef on Policy {\n    id\n    insuredPerson {\n      id\n    }\n  }\n"): (typeof documents)["\n  fragment TransferDialogDef on Policy {\n    id\n    insuredPerson {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UpdatePackage on Policy {\n    id\n    ...PackageDialog\n  }\n  \n"): (typeof documents)["\n  fragment UpdatePackage on Policy {\n    id\n    ...PackageDialog\n  }\n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PackageDialog on Policy {\n    id\n    upgradeablePackages {\n      id\n      name\n      insuredAmount\n    }\n    insuredPerson {\n      id\n      association {\n        id\n        statutesUrl\n        eligibilityQuestions {\n          id\n          question\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment PackageDialog on Policy {\n    id\n    upgradeablePackages {\n      id\n      name\n      insuredAmount\n    }\n    insuredPerson {\n      id\n      association {\n        id\n        statutesUrl\n        eligibilityQuestions {\n          id\n          question\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation requestPackageUpdate($input: RequestPackageUpdateInput!) {\n    requestPackageUpdate(input: $input) {\n      clientMutationId\n    }\n  }\n"): (typeof documents)["\n  mutation requestPackageUpdate($input: RequestPackageUpdateInput!) {\n    requestPackageUpdate(input: $input) {\n      clientMutationId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment TransferDialog on Policy {\n    id\n    insuredPerson {\n      id\n    }\n  }\n"): (typeof documents)["\n  fragment TransferDialog on Policy {\n    id\n    insuredPerson {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment PolicyListInfo on Account {\n      policies {\n        edges {\n          node {\n            state\n            ...PolicyCard\n          }\n        }\n      }\n    }\n    \n  "): (typeof documents)["\n    fragment PolicyListInfo on Account {\n      policies {\n        edges {\n          node {\n            state\n            ...PolicyCard\n          }\n        }\n      }\n    }\n    \n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query myPolicies {\n    viewer {\n      id\n      verified\n      username\n      phone\n      fullName\n      ...PolicyListInfo\n    }\n  }\n  \n"): (typeof documents)["\n  query myPolicies {\n    viewer {\n      id\n      verified\n      username\n      phone\n      fullName\n      ...PolicyListInfo\n    }\n  }\n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query validateMigrationCode($code: String!, $id: ID!) {\n    assocation(id: $id) {\n      migrationCode(code: $code) {\n        activated\n        insuredPerson {\n          id\n          fullName\n          birthdate\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query validateMigrationCode($code: String!, $id: ID!) {\n    assocation(id: $id) {\n      migrationCode(code: $code) {\n        activated\n        insuredPerson {\n          id\n          fullName\n          birthdate\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation migratePerson($input: MigratePersonInput!) {\n    migratePerson(input: $input) {\n      viewer {\n        id\n        firstName\n        namePreposition\n        lastName\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation migratePerson($input: MigratePersonInput!) {\n    migratePerson(input: $input) {\n      viewer {\n        id\n        firstName\n        namePreposition\n        lastName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation addAdditionalInsuranceQuote($input: AddAdditionalInsuranceQuoteForPolicyInput!) {\n  addAdditionalInsuranceQuoteFromPolicy(input: $input) {\n      premium\n      quoteExists\n  }\n}\n"): (typeof documents)["\nmutation addAdditionalInsuranceQuote($input: AddAdditionalInsuranceQuoteForPolicyInput!) {\n  addAdditionalInsuranceQuoteFromPolicy(input: $input) {\n      premium\n      quoteExists\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updateAdditionalInsurance($input: UpdateAdditionalInsuranceInput!) {\n  updateAdditionalInsurance(input: $input) {\n    clientMutationId\n  }\n}\n"): (typeof documents)["\nmutation updateAdditionalInsurance($input: UpdateAdditionalInsuranceInput!) {\n  updateAdditionalInsurance(input: $input) {\n    clientMutationId\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updateEndDate($input: CancelAdditionalInsuranceInput!) {\n  cancelAdditionalInsurance(input: $input) {\n    clientMutationId\n  }\n}\n"): (typeof documents)["\nmutation updateEndDate($input: CancelAdditionalInsuranceInput!) {\n  cancelAdditionalInsurance(input: $input) {\n    clientMutationId\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AddressDetails on InsuredPerson {\n    id\n    address {\n      id\n      postalCode\n      houseNumber\n      suffix\n      street\n      city\n      countryCode\n    }\n  }\n"): (typeof documents)["\n  fragment AddressDetails on InsuredPerson {\n    id\n    address {\n      id\n      postalCode\n      houseNumber\n      suffix\n      street\n      city\n      countryCode\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateUsername($input: UpdateUsernameInput!) {\n    updateUsername(input: $input) {\n      user{\n        id\n        username\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateUsername($input: UpdateUsernameInput!) {\n    updateUsername(input: $input) {\n      user{\n        id\n        username\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation generateMigrationCode($input: AddMigrationCodeInput!) {\n    addMigrationCode(input: $input) {\n      migrationCode\n    }\n  }\n"): (typeof documents)["\nmutation generateMigrationCode($input: AddMigrationCodeInput!) {\n    addMigrationCode(input: $input) {\n      migrationCode\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ContactDetails on InsuredPerson {\n    id\n    account {\n      id\n      phone\n      username\n      fullName\n    }\n    ...InsuredPersonAccountDetails\n  }\n"): (typeof documents)["\n  fragment ContactDetails on InsuredPerson {\n    id\n    account {\n      id\n      phone\n      username\n      fullName\n    }\n    ...InsuredPersonAccountDetails\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery searchAccountByUsername($username: String!) {\n  accountForUsername(username: $username) {\n    id\n    fullName\n  }\n}"): (typeof documents)["\nquery searchAccountByUsername($username: String!) {\n  accountForUsername(username: $username) {\n    id\n    fullName\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment InsuredPersonAccountDetails on InsuredPerson {\n  id\n  initials\n  firstName\n  lastName\n  phone\n  namePreposition\n}"): (typeof documents)["\nfragment InsuredPersonAccountDetails on InsuredPerson {\n  id\n  initials\n  firstName\n  lastName\n  phone\n  namePreposition\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation deleteInsuredPerson($input: DeleteInsuredPersonInput!) {\n    deleteInsuredPerson(input: $input) {\n      deletedInsuredPersonId\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation deleteInsuredPerson($input: DeleteInsuredPersonInput!) {\n    deleteInsuredPerson(input: $input) {\n      deletedInsuredPersonId\n      __typename\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment DangerZoneFragment on Policy {\n  id\n  insuredPerson {\n    id\n  }\n  ...ReactivatePolicy\n}"): (typeof documents)["\nfragment DangerZoneFragment on Policy {\n  id\n  insuredPerson {\n    id\n  }\n  ...ReactivatePolicy\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation reactivePolicy($input: ReactivatePolicyInput!) {\n    reactivatePolicy(input: $input) {\n        clientMutationId\n    }\n}\n"): (typeof documents)["\nmutation reactivePolicy($input: ReactivatePolicyInput!) {\n    reactivatePolicy(input: $input) {\n        clientMutationId\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment ReactivatePolicy on Policy {\n    id\n    state   \n}"): (typeof documents)["\nfragment ReactivatePolicy on Policy {\n    id\n    state   \n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Eligibility on Policy {\n    eligibility {\n      id\n      question {\n        id\n        question\n      }\n      response\n      explanation\n    }\n  }\n"): (typeof documents)["\n  fragment Eligibility on Policy {\n    eligibility {\n      id\n      question {\n        id\n        question\n      }\n      response\n      explanation\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment EventEntry on Event {\n    id\n    date\n    description\n    creator {\n        id\n        fullName\n    }\n}\n"): (typeof documents)["\nfragment EventEntry on Event {\n    id\n    date\n    description\n    creator {\n        id\n        fullName\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment HistoryPerson on InsuredPerson {\n    id\n    events {\n        id\n        ...EventEntry\n    }\n}\n"): (typeof documents)["\nfragment HistoryPerson on InsuredPerson {\n    id\n    events {\n        id\n        ...EventEntry\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MemoDetails on InsuredPerson {\n    id\n    memo\n  }\n"): (typeof documents)["\n  fragment MemoDetails on InsuredPerson {\n    id\n    memo\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updateMemo($input: UpdateInsuredPersonInput!) {\n  updateInsuredPerson(input: $input) {\n    insuredPeople {\n      id\n      memo\n    }\n  }\n}\n"): (typeof documents)["\nmutation updateMemo($input: UpdateInsuredPersonInput!) {\n  updateInsuredPerson(input: $input) {\n    insuredPeople {\n      id\n      memo\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updatePackageQuote($input: UpdatePackageQuoteInput!) {\n    updatePackageQuote(input: $input) {\n      quote {\n        id\n        birthdate\n        premium\n        lumpsum\n        frequency\n      }\n      quoteExists\n    }\n  }\n"): (typeof documents)["\n  mutation updatePackageQuote($input: UpdatePackageQuoteInput!) {\n    updatePackageQuote(input: $input) {\n      quote {\n        id\n        birthdate\n        premium\n        lumpsum\n        frequency\n      }\n      quoteExists\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PackageDetails on Policy {\n    id\n    premium\n    lumpsum\n    frequency\n    package {\n      id\n      name\n    }\n    insuredAmount\n    ...UpdatePackageInsurer\n    ...UpdateFrequencyInsurer\n  }\n"): (typeof documents)["\n  fragment PackageDetails on Policy {\n    id\n    premium\n    lumpsum\n    frequency\n    package {\n      id\n      name\n    }\n    insuredAmount\n    ...UpdatePackageInsurer\n    ...UpdateFrequencyInsurer\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment UpgradeablePackages on Policy {\n  id\n  upgradeablePackages {\n    id\n    name\n  }\n}\n"): (typeof documents)["\nfragment UpgradeablePackages on Policy {\n  id\n  upgradeablePackages {\n    id\n    name\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UpdatePackageInsurer on Policy {\n    id\n    insuredPerson {\n      id\n      association {\n        id\n        eligibilityQuestions {\n          id\n          question\n        }\n      }\n    }\n    ...UpgradeablePackages\n  }\n"): (typeof documents)["\n  fragment UpdatePackageInsurer on Policy {\n    id\n    insuredPerson {\n      id\n      association {\n        id\n        eligibilityQuestions {\n          id\n          question\n        }\n      }\n    }\n    ...UpgradeablePackages\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query premiumTableEntryForFrequency(\n    $policyId: ID!\n    $targetFrequency: Frequency!\n  ) {\n    node(id: $policyId) {\n      ... on Policy {\n        id\n        premiumTableEntryForFrequency(targetFrequency: $targetFrequency) {\n          id\n          premium\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query premiumTableEntryForFrequency(\n    $policyId: ID!\n    $targetFrequency: Frequency!\n  ) {\n    node(id: $policyId) {\n      ... on Policy {\n        id\n        premiumTableEntryForFrequency(targetFrequency: $targetFrequency) {\n          id\n          premium\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updateFrequencyMutation($input: UpdatePolicyInput!) {\n  updatePolicy(input: $input) {\n    policy {\n      id\n      premium\n      frequency\n    }\n  }\n}\n"): (typeof documents)["\nmutation updateFrequencyMutation($input: UpdatePolicyInput!) {\n  updatePolicy(input: $input) {\n    policy {\n      id\n      premium\n      frequency\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment UpdateFrequencyInsurer on Policy {\n  id\n  frequency\n  premium\n  package {\n    id\n    frequencies\n  }\n}"): (typeof documents)["\nfragment UpdateFrequencyInsurer on Policy {\n  id\n  frequency\n  premium\n  package {\n    id\n    frequencies\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updatePackagePolicy($input: UpdatePackagePolicyInput!) {\n    updatePackagePolicy(input: $input) {\n      updatedPolicy {\n        id\n      }\n      oldPolicy {\n        id\n        __typename\n        premium\n        lumpsum\n        frequency\n        package {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updatePackagePolicy($input: UpdatePackagePolicyInput!) {\n    updatePackagePolicy(input: $input) {\n      updatedPolicy {\n        id\n      }\n      oldPolicy {\n        id\n        __typename\n        premium\n        lumpsum\n        frequency\n        package {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PaymentDetails on InsuredPerson {\n    id\n    payment {\n      id\n      bic\n      iban\n      mandateId\n      accountHolder\n      signatureDate\n      paymentMethod\n    }\n  }\n"): (typeof documents)["\n  fragment PaymentDetails on InsuredPerson {\n    id\n    payment {\n      id\n      bic\n      iban\n      mandateId\n      accountHolder\n      signatureDate\n      paymentMethod\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation updateMandateId($paymentId: ID!, $mandateId: String!) {\n        updateMandateId(input: {\n            paymentId: $paymentId,\n            mandateId: $mandateId\n        }) {\n            payment {\n                id\n                mandateId\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation updateMandateId($paymentId: ID!, $mandateId: String!) {\n        updateMandateId(input: {\n            paymentId: $paymentId,\n            mandateId: $mandateId\n        }) {\n            payment {\n                id\n                mandateId\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation addSignatureDate($input: AddSignatureDateInput!) {\n    addSignatureDate(input: $input) {\n      payment {\n        id\n        signatureDate\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation addSignatureDate($input: AddSignatureDateInput!) {\n    addSignatureDate(input: $input) {\n      payment {\n        id\n        signatureDate\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updateInsuredPerson($input: UpdateInsuredPersonInput!) {\n  updateInsuredPerson(input: $input) {\n    insuredPeople {\n      id\n      firstName\n      initials\n      namePreposition\n      lastName\n      sex\n      phone\n      birthdate\n    }\n  }\n}"): (typeof documents)["\nmutation updateInsuredPerson($input: UpdateInsuredPersonInput!) {\n  updateInsuredPerson(input: $input) {\n    insuredPeople {\n      id\n      firstName\n      initials\n      namePreposition\n      lastName\n      sex\n      phone\n      birthdate\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PersonDetails on Policy {\n    id\n    state\n    signupDate\n    signOffDate\n    ...PolicySignOffButton\n    __typename\n    insuredPerson {\n      id\n      firstName\n      initials\n      namePreposition\n      phone\n      lastName\n      sex\n      memberId\n      birthdate\n      age\n      payment {\n        id\n        signatureDate\n      }\n      ...personDialogFragment\n    }\n  }\n"): (typeof documents)["\n  fragment PersonDetails on Policy {\n    id\n    state\n    signupDate\n    signOffDate\n    ...PolicySignOffButton\n    __typename\n    insuredPerson {\n      id\n      firstName\n      initials\n      namePreposition\n      phone\n      lastName\n      sex\n      memberId\n      birthdate\n      age\n      payment {\n        id\n        signatureDate\n      }\n      ...personDialogFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updateMemberId($input: UpdateMemberIdInput!) {\n  updateMemberId(input: $input) {\n    exists\n  }\n}"): (typeof documents)["\nmutation updateMemberId($input: UpdateMemberIdInput!) {\n  updateMemberId(input: $input) {\n    exists\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n   fragment PolicySignOffButton on Policy {\n        id\n        state\n        signOffDate\n   } \n"): (typeof documents)["\n   fragment PolicySignOffButton on Policy {\n        id\n        state\n        signOffDate\n   } \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updatePolicyMutationSignOff($input: UpdatePolicyInput!) {\n  updatePolicy(input: $input) {\n    policy {\n      id\n      signOffDate\n    }\n  }\n}\n"): (typeof documents)["\nmutation updatePolicyMutationSignOff($input: UpdatePolicyInput!) {\n  updatePolicy(input: $input) {\n    policy {\n      id\n      signOffDate\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updatePolicyMutation($input: UpdatePolicyInput!) {\n  updatePolicy(input: $input) {\n    policy {\n      id\n      premium\n      frequency\n      signupDate\n    }\n  }\n}\n"): (typeof documents)["\nmutation updatePolicyMutation($input: UpdatePolicyInput!) {\n  updatePolicy(input: $input) {\n    policy {\n      id\n      premium\n      frequency\n      signupDate\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation activatePolicy($input: ActivatePolicyInput!) {\n    activatePolicy(input: $input) {\n      policy {\n        id\n        __typename\n        signupDate\n      }\n      validDate\n    }\n  }\n"): (typeof documents)["\n  mutation activatePolicy($input: ActivatePolicyInput!) {\n    activatePolicy(input: $input) {\n      policy {\n        id\n        __typename\n        signupDate\n      }\n      validDate\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation deceasePolicy($input: DeceasePolicyInput!) {\n    deceasePolicy(input: $input) {\n      policy {\n        id\n        dateOfDeath\n        __typename\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation deceasePolicy($input: DeceasePolicyInput!) {\n    deceasePolicy(input: $input) {\n      policy {\n        id\n        dateOfDeath\n        __typename\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation cancelPolicy($input: CancelPolicyInput!) {\n    cancelPolicy(input: $input) {\n      policy {\n        id\n        cancellationDate\n        state\n        signOffDate\n        __typename\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation cancelPolicy($input: CancelPolicyInput!) {\n    cancelPolicy(input: $input) {\n      policy {\n        id\n        cancellationDate\n        state\n        signOffDate\n        __typename\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation dischargePolicy($input: DischargePolicyInput!) {\n    dischargePolicy(input: $input) {\n      policy {\n        id\n        dischargeDate\n        __typename\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation dischargePolicy($input: DischargePolicyInput!) {\n    dischargePolicy(input: $input) {\n      policy {\n        id\n        dischargeDate\n        __typename\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation rejectPolicy($input: RejectPolicyInput!) {\n    rejectPolicy(input: $input) {\n      policy {\n        id\n        __typename\n        rejectionDate\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation rejectPolicy($input: RejectPolicyInput!) {\n    rejectPolicy(input: $input) {\n      policy {\n        id\n        __typename\n        rejectionDate\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment personDialogFragment on InsuredPerson {\n    id\n    firstName\n    initials\n    namePreposition\n    lastName\n    sex\n  }\n"): (typeof documents)["\n  fragment personDialogFragment on InsuredPerson {\n    id\n    firstName\n    initials\n    namePreposition\n    lastName\n    sex\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query singlePolicy($id: ID!) {\n    viewer {\n      id\n      association {\n        id\n        additionalInsurancePossible\n        maxInsuredAmountAdditionalInsurance\n        minInsuredAmountAdditionalInsurance\n      }\n      policy(id: $id) {\n         id\n         __typename\n         ...DangerZoneFragment\n         insuredPerson{\n           id\n           ...ContactDetails\n           ...InsuredPersonAccountDetails\n           ...AddressDetails\n           ...MemoDetails\n           ...PaymentDetails\n           ...HistoryPerson\n         }\n         additionalInsurance {\n          id\n          insuredAmount\n          premium\n          lumpsum\n          startDate\n          endDate\n          frequency\n        }\n         ...PackageDetails\n         ...PersonDetails\n         ...Eligibility\n      }\n    }\n  }\n"): (typeof documents)["\n  query singlePolicy($id: ID!) {\n    viewer {\n      id\n      association {\n        id\n        additionalInsurancePossible\n        maxInsuredAmountAdditionalInsurance\n        minInsuredAmountAdditionalInsurance\n      }\n      policy(id: $id) {\n         id\n         __typename\n         ...DangerZoneFragment\n         insuredPerson{\n           id\n           ...ContactDetails\n           ...InsuredPersonAccountDetails\n           ...AddressDetails\n           ...MemoDetails\n           ...PaymentDetails\n           ...HistoryPerson\n         }\n         additionalInsurance {\n          id\n          insuredAmount\n          premium\n          lumpsum\n          startDate\n          endDate\n          frequency\n        }\n         ...PackageDetails\n         ...PersonDetails\n         ...Eligibility\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment EligibilityQuestionFragment on Association {\n    id\n    eligibilityQuestions {\n      question\n      id\n    }\n  }\n"): (typeof documents)["\n  fragment EligibilityQuestionFragment on Association {\n    id\n    eligibilityQuestions {\n      question\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SignupPremiumFragment on Association {\n    packages(state: ACTIVE) {\n      id\n      name\n      insuredAmount\n      frequencies\n    }\n  }\n"): (typeof documents)["\n  fragment SignupPremiumFragment on Association {\n    packages(state: ACTIVE) {\n      id\n      name\n      insuredAmount\n      frequencies\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment FinalCardFragment on Association {\n    id\n    statutesUrl\n    name\n  }\n"): (typeof documents)["\n  fragment FinalCardFragment on Association {\n    id\n    statutesUrl\n    name\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AddPolicySignup {\n    viewer {\n      id\n      association {\n        id\n        eligibilityQuestions {\n          id\n        }\n        ...SignupPremiumFragment\n        ...EligibilityQuestionFragment\n        ...FinalCardFragment\n      }\n    }\n  }\n\n  \n  \n  \n"): (typeof documents)["\n  query AddPolicySignup {\n    viewer {\n      id\n      association {\n        id\n        eligibilityQuestions {\n          id\n        }\n        ...SignupPremiumFragment\n        ...EligibilityQuestionFragment\n        ...FinalCardFragment\n      }\n    }\n  }\n\n  \n  \n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Signup($associationId: ID!) {\n    node(id: $associationId) {\n      id\n      ... on Association {\n        eligibilityQuestions {\n          id\n        }\n        ...SignupPremiumFragment\n        ...EligibilityQuestionFragment\n        ...FinalCardFragment\n      }\n    }\n  }\n\n  \n  \n  \n"): (typeof documents)["\n  query Signup($associationId: ID!) {\n    node(id: $associationId) {\n      id\n      ... on Association {\n        eligibilityQuestions {\n          id\n        }\n        ...SignupPremiumFragment\n        ...EligibilityQuestionFragment\n        ...FinalCardFragment\n      }\n    }\n  }\n\n  \n  \n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Acceptance {\n    viewer {\n      id\n      policiesAccepted: policies(state: ACCEPTED, first: 50) {\n        ...AanmeldingenList\n        totalCount\n      }\n      policiesPending: policies(state: PENDING, first: 50) {\n        ...AanmeldingenList\n        totalCount\n      }\n    }\n  }\n  \n"): (typeof documents)["\n  query Acceptance {\n    viewer {\n      id\n      policiesAccepted: policies(state: ACCEPTED, first: 50) {\n        ...AanmeldingenList\n        totalCount\n      }\n      policiesPending: policies(state: PENDING, first: 50) {\n        ...AanmeldingenList\n        totalCount\n      }\n    }\n  }\n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment RegistrationCardFragment on Policy {\n    id\n    ...Userinfo\n    ...Lidmaatschapinfo\n  }\n  \n  \n"): (typeof documents)["\n  fragment RegistrationCardFragment on Policy {\n    id\n    ...Userinfo\n    ...Lidmaatschapinfo\n  }\n  \n  \n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment Lidmaatschapinfo on Policy {\n      id\n      package {\n        id\n        name\n      }\n      premium\n      frequency\n    }\n  "): (typeof documents)["\n    fragment Lidmaatschapinfo on Policy {\n      id\n      package {\n        id\n        name\n      }\n      premium\n      frequency\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment Userinfo on Policy {\n      id\n      insuredPerson {\n        id\n        fullName\n        sex\n        birthdate\n        account {\n          id\n          username\n        }\n      }\n    }\n  "): (typeof documents)["\n    fragment Userinfo on Policy {\n      id\n      insuredPerson {\n        id\n        fullName\n        sex\n        birthdate\n        account {\n          id\n          username\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment AanmeldingenList on PolicyConnection {\n      edges {\n        node {\n          ...RegistrationCardFragment\n        }\n      }\n    }\n    \n  "): (typeof documents)["\n    fragment AanmeldingenList on PolicyConnection {\n      edges {\n        node {\n          ...RegistrationCardFragment\n        }\n      }\n    }\n    \n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updateAssociation($input: UpdateAssociationInput!) {\n    updateAssociation(input: $input) {\n      clientMutationId\n    }\n  }"): (typeof documents)["\nmutation updateAssociation($input: UpdateAssociationInput!) {\n    updateAssociation(input: $input) {\n      clientMutationId\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery associationDetails {\n    viewer {\n      association {\n        id\n        name\n        membershipCardTemplateKey\n        memberShipCardDownloadUrl\n      }\n    }\n  }\n"): (typeof documents)["\nquery associationDetails {\n    viewer {\n      association {\n        id\n        name\n        membershipCardTemplateKey\n        memberShipCardDownloadUrl\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;