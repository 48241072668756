import { Alert, CircularProgress, TablePagination } from "@mui/material";
import { CenterInScreen } from "components/layout";
import { gql } from "generated";
import {
  AccountsOverviewQuery,
  AccountsOverviewQueryVariables,
} from "generated/graphql";
import { useEffect, useState } from "react";
import { withRouter, WithRouterProps } from "react-router";
import { insurerPaths } from "screens/routes";
import { useEffectWithDelay } from "utils/reactExtensions";
import { useLazyQueryForApp } from "utils/withApollo";
import { AccountsTable } from "./components/AccountsTable";
import { AddAccountButton } from "./components/AddAccountButton";
import { NavBarSearchField } from "../../components/textfield/SearchField";
import { Header } from "./header";
import {
  getInitialState,
  useUpdateQueryParam,
} from "./hooks/updateQueryParams";
import { FilterGhostAccounts } from "./components/filterGhostAccounts";

export const AccountPageContent = withRouter((props: WithRouterProps) => {
  const initialState = () => getInitialState(props);

  const [first, setFirst] = useState(() => initialState().first);
  const [page, setPage] = useState(() => initialState().page);
  const [query, setQuery] = useState(() => initialState().query);

  const [ghostAccountsOn, setGhostAccountsOn] = useState(
    () => initialState().ghostAccountOn
  );
  const offset = page * first;

  const [fetch, { data, loading, called }] = useLazyQueryForApp<
    AccountsOverviewQuery,
    AccountsOverviewQueryVariables
  >(accountsQuery);

  const refetch = () => {
    fetch({
      variables: { offset, first, query, findGhostAccounts: ghostAccountsOn },
    });
  };

  useUpdateQueryParam(page, first, query, ghostAccountsOn, props);

  // eslint-disable-next-line
  useEffect(() => refetch(), []);
  useEffectWithDelay(
    () => {
      if (page === 0) refetch();
      else setPage(0);
    },
    500,
    [query]
  );
  useEffectWithDelay(() => refetch(), 1000, [page, first, ghostAccountsOn]);

  const goToAccount = (accountId: string) => {
    props.router.push({
      pathname: insurerPaths.accountsDetails.path(accountId),
      query: {
        // eslint-disable-next-line
        prevPath: location.pathname + String(location.search),
      },
    });
  };

  const mainLoading = !called || (loading && !data);
  let content = null;
  if (mainLoading) {
    content = (
      <CenterInScreen>
        <CircularProgress />
      </CenterInScreen>
    );
  } else {
    content = (
      <>
        <Alert severity="info">
          Er zijn {data.accounts.insuredPersonCount} leden gekoppeld aan de
          accounts in de tabel.
        </Alert>
        <AccountsTable
          data={data}
          loading={loading}
          goToAccount={goToAccount}
          paginationComponent={
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, 25]}
              component="div"
              count={data.accounts.totalCount}
              rowsPerPage={first}
              onRowsPerPageChange={(e) =>
                setFirst(parseInt(e.target.value, 10))
              }
              page={page}
              onPageChange={(e, newPage) => setPage(newPage)}
            />
          }
        />
      </>
    );
  }

  return (
    <>
      <Header
        loading={loading}
        extraButton={
          <FilterGhostAccounts
            ghostAccountsOn={ghostAccountsOn}
            toggle={() => setGhostAccountsOn(!ghostAccountsOn)}
          />
        }
        searchField={
          <NavBarSearchField
            value={query}
            onChange={(e: any) => setQuery(e.target.value)}
          />
        }
      />
      <AddAccountButton goToAccount={goToAccount} />
      {content}
    </>
  );
});

const accountsQuery = gql(`
query accountsOverview($first: Int!, $offset: Int!, $query: String, $findGhostAccounts: Boolean!) {
    accounts(first: $first, offset: $offset, query: $query, findGhostAccounts: $findGhostAccounts) {
      accounts {
        id
        username
        verified
        fullName
        phone
      }
      totalCount
      insuredPersonCount
    }
}`);
