import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DialogConfirmButton } from "components/button/buttons";
import { useState } from "react";

export const HelpButton = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button color="inherit" variant="text" onClick={() => setOpen(true)}>
        Help
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Lid groepering</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deze pagina bevat een overzicht van de leden met hetzelfde adres en
            betalingsgegevens als het gekozen lid.
            <br />
            <br />
            Het is mogelijk om via deze pagina het adres, telefoonnummer en
            betalingsgegevens aan te passen voor alle leden in de groep via het
            potloodje bij het betreffende veld.
            <br />
            <br />
            Om de details van een lid te zien dient er geklikt te worden op een
            rij in de tabel "Leden in Groep".
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogConfirmButton onClick={() => setOpen(false)}>
            Ok
          </DialogConfirmButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
