// @flow

import React from "react";
import ExpandablePage from "../../components/expandablePageSection";

const Kinderen = () => (
  <ExpandablePage title="Kinderen">
    <p>Wij bieden aangepaste tarieven aan voor kinderen:</p>
    <ul>
      <li>
        Uw kinderen van 0 tot en met 11 jaar zijn gratis verzekerd via de polis
        van hun ouders (na aanmelding).
      </li>
      <li>
        Voor kinderen in de leeftijdsgroep van 12 tot en met 17 jaar geldt een
        verlaagd tarief.
      </li>
    </ul>
  </ExpandablePage>
);

export default Kinderen;
