//@flow

import React, { Component } from "react";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { connect, ConnectedProps } from "react-redux";
import type { State } from "../../../reducers";

const styles: { [key: string]: React.CSSProperties } = {
  valueSelecter: {
    color: "grey",
    textAlign: "left",
    alignItems: "left",
    justifyContent: "left",
  },
};

type Answer = {
  id: string;
  answer: boolean;
  explanation: string;
};
type Question = {
  id: string;
  question: string;
};
type IsError = {
  isError: boolean;
};
type EligibilityItem = Question & Answer & IsError;

type Props = {
  questions: Array<Question>;
  onRef: (input?: EligibilityQuestionsComponent | null) => void;
} & ReduxProps;

type InternalState = {
  answers: Array<EligibilityItem>;
};

const arraysEqual = (a: any, b: any) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

const initializeEligibilityItems = (
  questions: Array<Question>,
  eligibility: Array<{
    questionId: string;
    response: boolean;
    explanation: string;
  }>
) => {
  return questions.map((question) => {
    for (var i = 0; i < eligibility.length; i++) {
      if (eligibility[i].questionId === question.id) {
        return {
          ...question,
          answer: eligibility[i].response,
          explanation: eligibility[i].explanation,
          isError: false,
        };
      }
    }
    return { ...question, answer: true, explanation: "", isError: false };
  });
};

export class EligibilityQuestionsComponent extends Component<
  Props,
  InternalState
> {
  constructor(props: Props) {
    super(props);
    const answers = initializeEligibilityItems(
      props.questions,
      props.eligibility
    );
    this.state = { answers };
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillReceiveProps(props: Props) {
    const { questions } = props;
    const questionsOld = this.props.questions;
    if (!arraysEqual(questions, questionsOld)) {
      const answers = initializeEligibilityItems(
        questions,
        this.props.eligibility
      );
      this.setState({ answers });
    }
  }

  validateAnswers() {
    const { answers } = this.state;
    let isError = false;
    let answersNew = answers.map((item) => {
      if (item.answer === true && !item.explanation) {
        item.isError = true;
        isError = true;
      }
      return item;
    });
    this.setState({ answers });
    if (isError) throw new Error("Answers Invalid");
    else
      return answersNew.map((item) => {
        const { id, answer, explanation } = item;
        return { id, answer, explanation };
      });
  }

  _setAnswer(questionId: string, answer: boolean) {
    let answers = this.state.answers;
    let answerItem = answers.find((answerItem) => {
      return questionId === answerItem.id;
    });
    if (answerItem) {
      answerItem.answer = answer;
      answerItem.isError = false;
      this.setState({ answers });
    } else throw new Error("Unable to find item for questionId");
  }

  _setExplanation(questionId: string, explanation: string) {
    let answers = this.state.answers;
    let answer = answers.find((itemAnswer) => {
      return questionId === itemAnswer.id;
    });

    if (answer) {
      answer.explanation = explanation;
      answer.isError = false;
      this.setState({ answers });
    } else throw new Error("unable to find item for questionId");
  }

  _createQuestion(item: EligibilityItem) {
    const errorText = item.isError ? "Vul een toelichting in." : "";
    const textField = item.answer ? (
      <TextField
        data-test={`eligibility_explanation_field_${item.id}`}
        floatingLabelText="Toelichting"
        defaultValue={item.explanation}
        style={{ width: "100%" }}
        floatingLabelStyle={styles.valueSelecter}
        inputStyle={styles.valueSelecter}
        onChange={(event, answer) => {
          this._setExplanation(item.id, answer);
        }}
        errorText={errorText}
        errorStyle={{ color: "red" }}
      />
    ) : null;
    const getMenuItems = () => {
      let menuItems = [];
      menuItems.push(<MenuItem value={true} primaryText={"Ja"} key={1} />);
      menuItems.push(<MenuItem value={false} primaryText={"Nee"} key={0} />);
      return menuItems;
    };

    return (
      <div key={item.id}>
        <p>{item.question}</p>
        <SelectField
          data-test={`eligibility_select_${item.id}`}
          value={item.answer}
          onChange={(event, index, value) => {
            this._setAnswer(item.id, value);
          }}
          floatingLabelText="Antwoord"
          floatingLabelStyle={styles.valueSelecter}
          labelStyle={styles.valueSelecter}
          style={{ width: "100%" }}
          errorStyle={{ color: "red" }}
        >
          {getMenuItems()}
        </SelectField>
        {textField}
      </div>
    );
  }

  render() {
    const { answers } = this.state;
    const questionComponents = answers.map((item) => {
      return this._createQuestion(item);
    });

    return <div>{questionComponents}</div>;
  }
}

const mapStateToProps = (state: State) => {
  return {
    eligibility: state.signup.eligibility.answers,
  };
};

const connector = connect(mapStateToProps);
const Wrapper = connector(EligibilityQuestionsComponent);

type ReduxProps = ConnectedProps<typeof connector>;

// const Wrapped = (props: PropsParent) => <Wrapper {...props} />;
export default Wrapper;
