//@flow

import { Dispatch } from "../../reducers";
import { increaseStepperIndex } from "./index";

type Answer = {
  id: string;
  answer: boolean;
  explanation: string;
};

export type EligibilityState = Array<Answer>;

type ActionAddEligibility = {
  type: "ADD_ELIGIBILITY_STATE";
  answers: Array<Answer>;
};

export type Action = ActionAddEligibility;

const addEligibilityState = (answers: EligibilityState): Action => {
  return {
    type: "ADD_ELIGIBILITY_STATE",
    answers,
  };
};

export const setEligibilityState =
  (input: EligibilityState) => (dispatch: Dispatch) => {
    dispatch(addEligibilityState(input));
    dispatch(increaseStepperIndex());
  };
