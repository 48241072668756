//@flow

type ActionSignupReset = {
  type: "RESET_SIGNUP";
};

export type Action = ActionSignupReset;

export const resetSignupState = (): Action => ({
  type: "RESET_SIGNUP",
});
