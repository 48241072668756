// @flow

import React from "react";
import { connect } from "react-redux";
import MenuIcon from "material-ui/svg-icons/navigation/menu";
import IconButton from "material-ui/IconButton";
import { openNavigationBar } from "../../actions";
import { colors } from "../../styling";
import { Dispatch } from "../../reducers";

type Props = {
  dispatch: Dispatch;
};

const MenuButton = ({ dispatch, ...other }: Props) => (
  <IconButton
    data-test="menu_button"
    onClick={() => dispatch(openNavigationBar())}
  >
    <MenuIcon color={colors.white} />
  </IconButton>
);

export default connect()(MenuButton);
