// @flow

import React from "react";
import Title from "./title";
import Content from "./content";
import { PropsWithChildren } from "react";

type PropType = {
  title: string;
} & PropsWithChildren;
const PageSection = ({ title, children }: PropType) => (
  <div>
    <Title title={title} />
    <Content>{children}</Content>
  </div>
);

export default PageSection;
