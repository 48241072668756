//@flow

/**
 * Created by Joshua Scheidt on 26-01-2017.
 */

import React, { Component } from "react";
import TextField from "material-ui/TextField";
import { colors } from "./../../../styling/index";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import { capitalizeFirstLetter } from "../../../utils";
import { SignUpScreenReduxProps } from "../../../containers/policySignup/signupStepperComponents/signupAccount";

const styles: { [key: string]: React.CSSProperties } = {
  instructionTitle: {
    color: colors.green,
    textTransform: "uppercase",
    fontSize: "18px",
    fontWeight: "bold",
  },
  instructionTitleGrey: {
    color: "grey",
    textTransform: "uppercase",
    fontSize: "18px",
    fontWeight: "bold",
  },
  valueSelecter: {
    color: "grey",
    textAlign: "left",
    alignItems: "left",
    justifyContent: "left",
  },
  inputFieldDiv: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  maleIcon: { width: "32px", height: "90px" },
  femaleIcon: { width: "40px", height: "90px" },
};

type Props = {
  backPressed(): void;
} & SignUpScreenReduxProps;

type AccountErrors = {
  errorFirstName: string;
  errorLastName: string;
  errorPhone: string;
  errorEmail: string;
  errorPassword: string;
  errorPasswordConfirm: string;
};

const getErrorMessages = (errors: Props["errors"]): AccountErrors => {
  let errorMessages = {
    errorFirstName: "",
    errorLastName: "",
    errorPhone: "",
    errorEmail: "",
    errorPassword: "",
    errorPasswordConfirm: "",
  };

  if (errors.indexOf("EMPTY_EMAIL_ERROR") >= 0)
    errorMessages.errorEmail = "E-mailadres is niet ingevuld.";
  else if (errors.indexOf("INVALID_EMAIL_ERROR") >= 0)
    errorMessages.errorEmail = "Ongeldig e-mailadres.";
  else if (errors.indexOf("DUPLICATE_EMAIL_ERROR") >= 0)
    errorMessages.errorEmail = "Deze gebruikersnaam is al in gebruik.";

  if (errors.indexOf("EMPTY_FIRST_NAME_ERROR") >= 0)
    errorMessages.errorFirstName = "Roepnaam is niet ingevuld.";

  if (errors.indexOf("EMPTY_LAST_NAME_ERROR") >= 0)
    errorMessages.errorLastName = "Achternaam is niet ingevuld.";

  if (errors.indexOf("EMPTY_PHONE_ERROR") !== -1)
    errorMessages.errorPhone = "Telefoonnummer is niet ingevuld.";
  else if (errors.indexOf("INVALID_PHONE_ERROR") !== -1)
    errorMessages.errorPhone =
      "Het nummer bevat letters of verkeerde leestekens.";

  if (errors.indexOf("EMPTY_PASSWORD_ERROR") >= 0)
    errorMessages.errorPassword = "Wachtwoord is niet ingevuld.";
  else if (errors.indexOf("INVALID_PASSWORD") !== -1)
    errorMessages.errorPassword =
      "Het nieuwe wachtwoord moet minstens 8 tekens bevatten, waarvan minstens 1 letter en 1 cijfer.";

  if (errors.indexOf("EMPTY_PASSWORD_CONFIRM_ERROR") >= 0)
    errorMessages.errorPasswordConfirm =
      "Controle wachtwoord is niet ingevuld.";
  else if (errors.indexOf("INCONSISTENT_PASSWORD_ERROR") >= 0)
    errorMessages.errorPasswordConfirm = "De wachtwoorden zijn niet gelijk.";

  return errorMessages;
};

class YourInfoStep extends Component<Props> {
  constructor(props: Props) {
    super(props);
    //$FlowFixMe
    this._nextPressed = this._nextPressed.bind(this);
  }

  _nextPressed() {
    const input = {
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      phone: this.props.phone,
      email: this.props.email,
      password: this.props.password,
      passwordConfirm: this.props.passwordConfirm,
    };
    this.props.nextPressed(input);
  }

  render() {
    const {
      firstName,
      prefix,
      lastName,
      phone,
      email,
      password,
      passwordConfirm,
    } = this.props;

    let errors = getErrorMessages(this.props.errors);

    return (
      <div style={{ maxWidth: "350px" }}>
        <div style={styles.inputFieldDiv}>
          De volgende gegevens worden gebruikt voor het creëren van uw online
          account.
          <div>
            <TextField
              data-test="account_first_name_textfield"
              value={firstName}
              floatingLabelText="Wat is uw roepnaam?"
              floatingLabelStyle={styles.valueSelecter}
              inputStyle={styles.valueSelecter}
              onChange={(event: any) =>
                this.props.setFirstName(
                  capitalizeFirstLetter(event.target.value)
                )
              }
              errorText={errors.errorFirstName}
              errorStyle={{ color: "red" }}
              fullWidth={true}
              onKeyDown={(event: any) => {
                if (event.key === "Enter") {
                  this._nextPressed();
                }
              }}
            />
          </div>
          <div>
            <TextField
              data-test="account_prefix_textfield"
              value={prefix}
              floatingLabelText="Tussenvoegsel (optioneel)."
              floatingLabelStyle={styles.valueSelecter}
              inputStyle={styles.valueSelecter}
              onChange={(event: any) =>
                this.props.setLastNamePrefix(event.target.value)
              }
              fullWidth={true}
              onKeyDown={(event: any) => {
                if (event.key === "Enter") {
                  this._nextPressed();
                }
              }}
            />
          </div>
          <div>
            <TextField
              data-test="account_last_name_textfield"
              value={lastName}
              floatingLabelText="Wat is uw achternaam?"
              floatingLabelStyle={styles.valueSelecter}
              inputStyle={styles.valueSelecter}
              onChange={(event: any) =>
                this.props.setLastName(
                  capitalizeFirstLetter(event.target.value)
                )
              }
              errorText={errors.errorLastName}
              errorStyle={{ color: "red" }}
              fullWidth={true}
              onKeyDown={(event: any) => {
                if (event.key === "Enter") {
                  this._nextPressed();
                }
              }}
            />
          </div>
          <div>
            <TextField
              data-test="account_phone_textfield"
              value={phone}
              floatingLabelText="Wat is uw telefoonnummer?"
              floatingLabelStyle={styles.valueSelecter}
              inputStyle={styles.valueSelecter}
              onChange={(event: any) => this.props.setPhone(event.target.value)}
              errorText={errors.errorPhone}
              errorStyle={{ color: "red" }}
              fullWidth={true}
              onKeyDown={(event: any) => {
                if (event.key === "Enter") {
                  this._nextPressed();
                }
              }}
            />
          </div>
          <div>
            <TextField
              data-test="account_mail_textfield"
              value={email}
              floatingLabelText="Wat is uw e-mailadres?"
              floatingLabelStyle={styles.valueSelecter}
              inputStyle={styles.valueSelecter}
              onChange={(event: any) => this.props.setEmail(event.target.value)}
              errorText={errors.errorEmail}
              errorStyle={{ color: "red" }}
              fullWidth={true}
              onKeyDown={(event: any) => {
                if (event.key === "Enter") {
                  this._nextPressed();
                }
              }}
            />
          </div>
          <div>
            <TextField
              data-test="account_pass_textfield"
              value={password}
              floatingLabelText="Kies uw wachtwoord."
              floatingLabelStyle={styles.valueSelecter}
              inputStyle={styles.valueSelecter}
              onChange={(event: any) =>
                this.props.setPassword(event.target.value)
              }
              errorText={errors.errorPassword}
              errorStyle={{ color: "red" }}
              type="password"
              fullWidth={true}
              onKeyDown={(event: any) => {
                if (event.key === "Enter") {
                  this._nextPressed();
                }
              }}
            />
          </div>
          <div>
            <TextField
              data-test="account_pass_repeat_textfield"
              value={passwordConfirm}
              floatingLabelText="Bevestig uw wachtwoord."
              floatingLabelStyle={styles.valueSelecter}
              inputStyle={styles.valueSelecter}
              onChange={(event: any) =>
                this.props.setPasswordConfirm(event.target.value)
              }
              errorText={errors.errorPasswordConfirm}
              errorStyle={{ color: "red" }}
              type="password"
              fullWidth={true}
              onKeyDown={(event: any) => {
                if (event.key === "Enter") {
                  this._nextPressed();
                }
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "20px",
            }}
          >
            <div style={{ flex: 1, textAlign: "center" }}>
              <FlatButton
                label="Vorige"
                disableTouchRipple={true}
                disableFocusRipple={true}
                onClick={this.props.backPressed}
              />
            </div>
            <div style={{ flex: 1, textAlign: "center" }}>
              <RaisedButton
                label="Volgende"
                disableTouchRipple={true}
                disableFocusRipple={true}
                primary={true}
                onClick={this._nextPressed}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default YourInfoStep;
