import React from "react";
import PageSection from "../components/pageSection";
import Link from "../../link";

const ContactBCB = () => (
  <PageSection title="Contact">
  <React.Fragment>
    <p>
      Indien u wijzigingen wilt doorgeven kunt u contact opnemen met de secretaris{" "}
      <Link href="mailto:bcb.secretaris@gmail.com">bcb.secretaris@gmail.com</Link>.
      De secretaris is telefonisch bereikbaar op 06 - 30 70 53 16.
    </p>
    <p>
      Voor financiële vragen kunt u contact opnemen met de penningmeester{" "}
      <Link href="mailto:bcb.penningmeester@gmail.com">bcb.penningmeester@gmail.com</Link>.
    </p>
    <p>
      Ons postadres is:
      <br />
      Postbus 500
      <br />
      6160 AM Geleen
    </p>
  </React.Fragment>
  </PageSection>
);

export default ContactBCB;
