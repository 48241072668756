// @flow

import React from "react";
import TextField from "material-ui/TextField";
import { MigratiecodeCapitalized } from "./migrationCodeName";

type Props = {
  code: { value: string; name?: string | null };
};

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "row",
  },
};

const Separator = () => <div style={{ width: "16px" }} />;

const MigrationCode = (props: Props) => (
  <div style={styles.container}>
    <TextField
      disabled={true}
      value={props.code.value}
      floatingLabelText={MigratiecodeCapitalized}
    />
    <Separator />
    <TextField
      disabled={true}
      value={props.code.name}
      floatingLabelText={"Polishouder"}
    />
  </div>
);

export default MigrationCode;
