// @flow

import { isPostalCode } from "../../utils";

enum PostalCodeError {
  NO_POSTAL_CODE = "NO_POSTAL_CODE",
  INVALID_POSTAL_CODE = "INVALID_POSTAL_CODE",
}

const validatePostalCode = (postalCode: string): PostalCodeError | null => {
  if (postalCode.length === 0) {
    return PostalCodeError.NO_POSTAL_CODE;
  } else if (!isPostalCode(postalCode)) {
    return PostalCodeError.INVALID_POSTAL_CODE;
  }
};

const formatPostalCodeError = (error: PostalCodeError): string => {
  switch (error) {
    case PostalCodeError.NO_POSTAL_CODE:
      return "Postcode is niet ingevuld.";
    case PostalCodeError.INVALID_POSTAL_CODE:
      return "Postcode is onjuist. Gebruik format 1234 AA.";
    default:
      throw new Error(`Unrecognized error code ${error}`);
  }
};

const getDomesticPostalCodeError = (postalCode: string): string | null => {
  const postalCodeErrors = validatePostalCode(postalCode);
  return postalCodeErrors && formatPostalCodeError(postalCodeErrors);
};

const getForeignPostalCodeError = (postalCode: string): string | null => {
  const postalCodeErrors = validatePostalCode(postalCode);
  if (postalCodeErrors && postalCodeErrors === PostalCodeError.NO_POSTAL_CODE) {
    return formatPostalCodeError(postalCodeErrors);
  }
};

export { getDomesticPostalCodeError, getForeignPostalCodeError };
