import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { isEmailUnique } from "api";
import { composeValidators } from "components/form";
import { gql } from "generated";
import { useState } from "react";
import { accountActivationRedirectUrl } from "utils/redirect";
import { textFieldErrorProps, useForm } from "utils/useForm";
import { mustBeEmail, required } from "utils/validator";
import { useMutationForApp } from "utils/withApollo";
import { EditButton } from "../../../components/button/buttons";

type Props = {
  accountId: string;
};

type Form = {
  email: string;
};

export const UpdateAccountEmailButton = (props: Props) => {
  const [open, setOpen] = useState(false);

  const { values, registerField, handleSubmit, errors, setErrors } =
    useForm<Form>();

  const errorsPerField = textFieldErrorProps(errors);

  const [updateUsername, { loading }] = useMutationForApp(mutation, {
    variables: {
      input: {
        accountId: props.accountId,
        newUsername: values.email,
        redirectUrl: accountActivationRedirectUrl(),
      },
    },
    onCompleted: () => window.location.reload(),
  });

  const close = () => {
    if (!loading) setOpen(false);
  };
  return (
    <>
      <EditButton onClick={() => setOpen(true)} />
      <Dialog maxWidth="sm" fullWidth open={open} onClose={close}>
        <DialogTitle>Pas email aan</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Zodra de email is aangepast zal er een activatie mail worden
            verstuurd waarmee het email adres geverifieerd kan worden.
          </DialogContentText>
          <TextField
            fullWidth
            {...registerField("email", {
              validation: composeValidators(required, mustBeEmail),
            })}
            {...errorsPerField("email")}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            Annuleer
          </Button>
          <Button
            disabled={loading}
            onClick={() =>
              handleSubmit(async (data) => {
                const emailUnique = await isEmailUnique(data.email);

                if (!emailUnique) {
                  setErrors({ email: "Dit e-mailadres is al in gebruik." });
                } else updateUsername();
              })
            }
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mutation = gql(`
mutation updateAccountUsername($input: UpdateUsernameInput!) {
  updateUsername(input: $input) {
    clientMutationId
  }
}
`);
