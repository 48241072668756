import React from "react";
import { filter } from "graphql-anywhere";
import { errorHandler, ErrorHandler } from "../../containers";
import Header from "./header";
import PersonalDetails from "./components/person";
import ContactDetails from "./components/contact";
import AddressDetails from "./components/address";
import PackageDetails from "./components/package";
import PaymentDetails, { paymentDetailsFragment } from "./components/payment";
import { AdditionalInsuranceInfo } from "./components/additionalInsurance";
import MemoDetails, { memoDetailsFragment } from "./components/memo";
import DangerZone from "./components/dangerZone";
import History from "./components/history/index";

import EligibilityDetails, {
  fragment as eligibilityFragment,
} from "./components/eligibility";
import Loader from "../loader";
import { colors } from "../../styling";
import { gql } from "generated";
import { useQuery } from "@apollo/client";
import { WithRouterProps } from "react-router";
import {
  SinglePolicyQuery,
  SinglePolicyQueryVariables,
} from "generated/graphql";

const styles: { [key: string]: React.CSSProperties } = {
  outerContainer: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "100px",
  },
  allInfoHolder: {
    width: "600px",
    textAlign: "left",
    justifyContent: "left",
    alignItems: "left",
    lineHeight: 1.5,
  },
};

type Props = {
  policyId: string;
} & ErrorHandler &
  WithRouterProps;

const nonEditableTypes = [
  "CancelledPolicy",
  "DeceasedPolicy",
  "DischargedPolicy",
  "RejectedPolicy",
];

const ChangeLidmaatschapScreen = (props: Props) => {
  const { loading, error, data } = useQuery<
    SinglePolicyQuery,
    SinglePolicyQueryVariables
  >(USER_QUERY, {
    variables: {
      id: props.params.id,
    },
  });

  const viewer = data?.viewer;

  let content;
  if (viewer && viewer.policy) {
    const { policy, association } = viewer;
    let buttonsDisabled = nonEditableTypes.includes(viewer.policy.__typename);

    content = (
      <div style={styles.outerContainer}>
        <div
          style={{
            ...styles.allInfoHolder,
            alignItems: "center",
            paddingLeft: "25px",
            paddingRight: "25px",
          }}
        >
          <PersonalDetails data={viewer.policy} disabled={buttonsDisabled} />

          <ContactDetails
            data={policy.insuredPerson}
            disabled={buttonsDisabled}
            insuredPersonId={viewer.policy.insuredPerson.id}
          />

          <AddressDetails
            data={viewer.policy.insuredPerson}
            disabled={buttonsDisabled}
          />

          <PackageDetails data={viewer.policy} disabled={buttonsDisabled} />
          {association.additionalInsurancePossible && (
            <div>
              <AdditionalInsuranceInfo
                data={policy.additionalInsurance}
                disabled={buttonsDisabled}
                policyId={viewer.policy.id}
                maxInsuredAmount={
                  association.maxInsuredAmountAdditionalInsurance
                }
                minInsuredAmount={
                  association.minInsuredAmountAdditionalInsurance
                }
              />
              <div style={{ paddingTop: "30px" }} />
            </div>
          )}

          <PaymentDetails
            data={filter(paymentDetailsFragment, viewer.policy.insuredPerson)}
            disabled={buttonsDisabled}
            policyId={viewer.policy.id}
          />
          <div style={{ paddingTop: "30px" }} />

          <EligibilityDetails
            data={filter(eligibilityFragment, viewer.policy)}
          />
          <div style={{ paddingTop: "30px" }} />
          <MemoDetails
            data={filter(memoDetailsFragment, viewer.policy.insuredPerson)}
            disabled={buttonsDisabled}
          />
          <div style={{ paddingTop: "30px" }} />
          <History data={policy.insuredPerson} />
          <div style={{ paddingTop: "30px" }} />
          <DangerZone data={viewer.policy} />
        </div>
      </div>
    );
  } else {
    content = (
      <div style={styles.outerContainer}>
        We hebben het bijbehorende lid niet kunnen vinden.
      </div>
    );
  }
  return (
    <div>
      <Header insuredPersonId={data?.viewer?.policy?.insuredPerson?.id} />
      <Loader
        isFetching={loading}
        error={error}
        fullScreen={true}
        color={colors.green}
      >
        <div>{content}</div>
      </Loader>
    </div>
  );
};

export const USER_QUERY = gql(`
  query singlePolicy($id: ID!) {
    viewer {
      id
      association {
        id
        additionalInsurancePossible
        maxInsuredAmountAdditionalInsurance
        minInsuredAmountAdditionalInsurance
      }
      policy(id: $id) {
         id
         __typename
         ...DangerZoneFragment
         insuredPerson{
           id
           ...ContactDetails
           ...InsuredPersonAccountDetails
           ...AddressDetails
           ...MemoDetails
           ...PaymentDetails
           ...HistoryPerson
         }
         additionalInsurance {
          id
          insuredAmount
          premium
          lumpsum
          startDate
          endDate
          frequency
        }
         ...PackageDetails
         ...PersonDetails
         ...Eligibility
      }
    }
  }
`);

export default errorHandler(ChangeLidmaatschapScreen);
