// @flow

import React from "react";
import TextFieldMaterialUI from "material-ui/TextField";
import muiThemeable from "material-ui/styles/muiThemeable";
import { MuiTheme } from "material-ui/styles";

const getDefaultStyling = (color: string) => ({
  floatingLabelFocusStyle: { color },
  underlineFocusStyle: { borderColor: color },
  errorStyle: { color: "red" },
});

const TextField = (props: { muiTheme?: MuiTheme }) => {
  const color = props.muiTheme.palette.primary1Color;
  return <TextFieldMaterialUI {...getDefaultStyling(color)} {...props} />;
};

const WrappedTextField = muiThemeable()(TextField);
// @ts-ignore
WrappedTextField.displayName = "TextField";

export default WrappedTextField;
