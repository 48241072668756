// @flow
/**
 * Created by Dirk-Jan Rutten on 01/11/16.
 */

import React from "react";
import { gql } from "@apollo/client";
import Person from "material-ui/svg-icons/social/person";
import Cake from "material-ui/svg-icons/social/cake";
import Gender from "material-ui/svg-icons/notification/wc";
import { colors } from "../../../styling";
import moment from "moment";
moment.locale("nl");

const stylesCard: { [key: string]: React.CSSProperties } = {
  paperElement: {
    height: "100%",
    width: "100%",
    textAlign: "center",
    paddingTop: "20px",
    paddingLeft: "28px",
    paddingRight: "28px",
    paddingBottom: "28px",
    fontWeight: 300,
    fontSize: "16px",
  },
  personInfoRowContainer: {
    paddingTop: "12px",
    marginLeft: "10px",
    marginRight: "10px",
    textAlign: "left",
  },
  personInfoText: {
    display: "flex",
    alignItems: "flex-end",
  },
  policyInfoRowContainer: {
    paddingTop: "12px",
    marginLeft: "40px",
    marginRight: "10px",
    textAlign: "left",
  },
  policyInfoLabel: {
    display: "flex",
    alignItems: "center",
    color: colors.lightGrey,
  },
  icon: {
    width: "16px",
    height: "16px",
  },
};

type PropsCard = {
  data: {
    id: string;
    insuredPerson: {
      id: string;
      initials: string;
      lastName: string;
      birthdate: string | null;
      sex: "MALE" | "FEMALE";
    };
  };
};

function toDutch(sex: "MALE" | "FEMALE") {
  if (sex === "MALE") {
    return "Man";
  } else {
    return "Vrouw";
  }
}

const convertBirthdateToText = (birthdate: string | null) => {
  if (!birthdate) {
    return "De geboortedatum is niet bekend";
  } else {
    return moment(birthdate, "YYYY-MM-DD", true).format("LL");
  }
};

function propsMapper(props: PropsCard) {
  const { initials, lastName, birthdate, sex } = props.data.insuredPerson;

  const name = initials + " " + lastName;

  let infoArray = [
    {
      info: name,
      icon: <Person style={stylesCard.icon} color={"lightgray"} />,
    },
    {
      info: convertBirthdateToText(birthdate),
      icon: <Cake style={stylesCard.icon} color={"lightgray"} />,
    },
    {
      info: toDutch(sex),
      icon: <Gender style={stylesCard.icon} color={"lightgray"} />,
    },
  ];

  return infoArray.map((object) => {
    let content;
    if (object.info === name)
      content = (
        <div
          style={{
            flex: 1,
            fontSize: "18px",
            color: colors.green,
            ...stylesCard.personInfoText,
            height: "40px",
            fontWeight: "bold",
          }}
        >
          {object.info}
        </div>
      );
    else
      content = (
        <div style={{ flex: 1, ...stylesCard.personInfoText }}>
          {object.info}
        </div>
      );

    return (
      <div
        key={object.info}
        style={{ display: "flex", ...stylesCard.personInfoRowContainer }}
      >
        <div style={{ width: "30px", ...stylesCard.personInfoText }}>
          {object.icon}
        </div>
        {content}
      </div>
    );
  });
}

export const userInfoFragment = gql`
  fragment clientCheckUser on Policy {
    id
    insuredPerson {
      id
      lastName
      initials
      birthdate
      sex
    }
  }
`;

const PolicyCardUserInfo = (props: PropsCard) => (
  <div>{propsMapper(props)}</div>
);

export default PolicyCardUserInfo;
