import React, { Component } from "react";
import { colorsBCV } from "../../../../styling";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import { errorHandler } from "../../../../containers";
import type { ErrorHandler } from "../../../../containers";
import { isValidPassword } from "../../../../utils";
import { login } from "../../../../api";
import { gql } from "generated";
import { graphql } from "@apollo/client/react/hoc";
import {
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables,
} from "generated/graphql";
import { DialogContainer2 } from "components/dialog/container";
import { NavButtonContainer } from "components/dialog";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  infoTitle: {
    paddingTop: "10px",
    fontSize: "18px",
    color: colorsBCV.secondary,
  },
};

type PropsParent = {
  handleClose: () => void;
  username: string;
};

type PropsGraphQL = {
  updatePassword: (oldPassword: string, newPassword: string) => Promise<any>;
};
type Props = PropsParent & ErrorHandler & PropsGraphQL;

type State = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  errorOldPassField: string | null;
  errorNewPassField: string | null;
  errorConfirmPassField: string | null;
};

export class UpdatePassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      errorOldPassField: null,
      errorNewPassField: null,
      errorConfirmPassField: null,
    };
  }

  async _updatePassword() {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    const { updatePassword, handleClose, error } = this.props;
    if (
      oldPassword.length === 0 ||
      newPassword.length === 0 ||
      confirmPassword.length === 0
    ) {
      let errorOldPassField,
        errorNewPassField,
        errorConfirmPassField = null;
      if (oldPassword.length === 0)
        errorOldPassField = "Vul uw huidige wachtwoord in.";
      if (newPassword.length === 0)
        errorNewPassField = "Vul een nieuw wachtwoord in.";
      if (confirmPassword.length === 0)
        errorConfirmPassField = "Vul de bevestiging in.";

      this.setState({
        errorOldPassField,
        errorNewPassField,
        errorConfirmPassField,
      });
      return;
    }
    try {
      const loginCredentials = await login(this.props.username, oldPassword);
      if (!loginCredentials) {
        this.setState({
          errorOldPassField: "Het wachtwoord is niet correct.",
          errorNewPassField: null,
          errorConfirmPassField: null,
        });
        return;
      }

      if (!isValidPassword(newPassword)) {
        this.setState({
          errorOldPassField: null,
          errorNewPassField:
            "Het nieuwe wachtwoord moet minstens 8 tekens bevatten, waarvan minstens 1 letter en 1 cijfer.",
          errorConfirmPassField: null,
        });
        return;
      }

      if (newPassword !== confirmPassword) {
        const error = "Het nieuwe wachtwoord en de bevestiging zijn anders.";
        this.setState({
          errorOldPassField: null,
          errorNewPassField: error,
          errorConfirmPassField: error,
        });
        return;
      }

      if (oldPassword === newPassword) {
        const error = "Uw oude en nieuwe wachtwoord moeten verschillend zijn.";
        this.setState({
          errorOldPassField: error,
          errorNewPassField: error,
          errorConfirmPassField: null,
        });
        return;
      }

      this.setState({
        errorOldPassField: null,
        errorNewPassField: null,
        errorConfirmPassField: null,
      });

      await updatePassword(oldPassword, newPassword);
      handleClose();
    } catch (e) {
      error.display();
    }
  }

  render() {
    const {
      oldPassword,
      newPassword,
      confirmPassword,
      errorOldPassField,
      errorNewPassField,
      errorConfirmPassField,
    } = this.state;

    const { handleClose } = this.props;

    return (
      <DialogContainer2>
        <span style={styles.infoTitle}>Vul uw oude wachtwoord in.</span>
        <TextField
          // @ts-ignore
          id="oldPassField"
          name="oldPassField"
          defaultValue={oldPassword}
          fullWidth
          type="password"
          errorText={errorOldPassField}
          onChange={(event: any) =>
            this.setState({ oldPassword: event.target.value })
          }
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this._updatePassword();
            }
          }}
        />
        <span style={styles.infoTitle}>Kies een nieuw wachtwoord...</span>
        <TextField
          // @ts-ignore
          id="newPassField"
          name="newPassField"
          defaultValue={newPassword}
          fullWidth
          type="password"
          onChange={(event: any) =>
            this.setState({ newPassword: event.target.value })
          }
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this._updatePassword();
            }
          }}
          errorText={errorNewPassField}
        />
        <span style={styles.infoTitle}>...en bevestig dit wachtwoord.</span>
        <TextField
          // @ts-ignore
          id="confirmPassField"
          name="confirmPassField"
          defaultValue={confirmPassword}
          fullWidth
          type="password"
          onChange={(event: any) =>
            this.setState({
              confirmPassword: event.target.value,
            })
          }
          onKeyDown={(event: any) => {
            if (event.key === "Enter") {
              this._updatePassword();
            }
          }}
          errorText={errorConfirmPassField}
        />
        <NavButtonContainer>
          <RaisedButton
            // @ts-ignore
            id="back"
            label="Annuleren"
            primary={false}
            onClick={handleClose}
          />
          <RaisedButton
            // @ts-ignore
            id="confirm"
            label="Bevestig"
            primary
            onClick={() => this._updatePassword()}
          />
        </NavButtonContainer>
      </DialogContainer2>
    );
  }
}

export const UPDATE_PASSWORD_MUTATION = gql(`
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      account{
        id
      }
    }
  }
`);

export const withMutation = graphql<
  PropsParent,
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables,
  PropsGraphQL
>(UPDATE_PASSWORD_MUTATION, {
  props: ({ mutate }) => ({
    updatePassword: (oldPassword: string, newPassword: string) =>
      mutate({ variables: { input: { oldPassword, newPassword } } }),
  }),
});

const Wrapped = withMutation(errorHandler(UpdatePassword));
export default Wrapped;
