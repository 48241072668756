import { createContext } from "react";
import { useContext } from "react";
import { PropsWithChildren, useState } from "react";
import { AlertDialog } from "screens/alertDialog/index";

const Context = createContext<() => void>(() => {});

export const ErrorHandlerProvider = (props: PropsWithChildren) => {
  const [show, setShow] = useState(false);

  return (
    <Context.Provider value={() => setShow(true)}>
      <AlertDialog show={show} hide={() => setShow(false)} />
      {props.children}
    </Context.Provider>
  );
};

export const ErrorHandlerConsumer = Context.Consumer;

export const useErrorHandler = () => useContext(Context);
