// @flow

import React from "react";
import config, { AssociationType } from "../../config";

import BCBlogo from "../../images/BCB-logo.png";
import PietersradeLogo from "../../images/Pietersrade-logo.png";
import HulpEnSteunLogo from "../../images/HenS-logo-white.png";

const { association } = config;

const LoginLogo = () => {
  switch (association) {
    case AssociationType.BCB:
      return (
        <img
          style={{
            width: "170px",
            height: "120px",
            paddingLeft: "40px",
            paddingTop: "32px",
          }}
          src={BCBlogo}
          alt=""
        />
      );
    case AssociationType.PIETERSRADE:
      return (
        <img
          style={{
            width: "300px",
            paddingBottom: "30px",
          }}
          src={PietersradeLogo}
          alt=""
        />
      );

    case AssociationType.HULP_EN_STEUN:
      return (
        <img
          style={{
            height: "200px",
            paddingBottom: "30px",
          }}
          src={HulpEnSteunLogo}
          alt=""
        />
      );

    default:
      throw new Error(`Unknown association type ${association}`);
  }
};

export default LoginLogo;
