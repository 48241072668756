// @flow

import React from "react";
import { serializeDate } from "../../../../utils";
import RaisedButton from "material-ui/RaisedButton";
import { colors } from "../../../../styling";
import TextField from "material-ui/TextField";
import { VerticalSeparator } from "../../../../components/layout";
import { NavButtonContainer, Title } from "../../../../components/dialog";
import {
  composeValidators,
  required,
  mustBeDate,
  mustBeAfterDate,
} from "../../../../utils/validator";
import { errorHandler, ErrorHandler } from "../../../../containers/index";
import EditButton from "../components/editButton";

import { withEndAdditionalInsurance } from "./graphql";
import type { PropsUpdateEndDateAdditionalInsurance } from "./graphql";
import { DialogContainer2 } from "components/dialog/container";
import { Dialog } from "@mui/material";

const { green, white } = colors;

export type PropsParentsUpdateEndDate = {
  policyId: string;
  startDate: string;
  disabled: boolean;
};

type Props = PropsParentsUpdateEndDate &
  PropsUpdateEndDateAdditionalInsurance &
  ErrorHandler;
type State = {
  openDialog: boolean;
  endDate: string;
  loading: boolean;
};
export class UpdateEndDateButton extends React.Component<Props, State> {
  state: State = {
    openDialog: false,
    endDate: "",
    loading: false,
  };

  render() {
    const { openDialog, endDate, loading } = this.state;

    const close = () => this.setState({ openDialog: false });
    const validator = composeValidators(
      required,
      mustBeDate,
      mustBeAfterDate(new Date(this.props.startDate))
    );

    const error = validator(endDate);

    return (
      <div>
        <EditButton
          onClick={() => this.setState({ openDialog: true })}
          disabled={this.props.disabled}
        />
        <Dialog open={openDialog} onClose={close}>
          <DialogContainer2>
            <Title>{`Additionele verzekering beëindigen`} </Title>
            <p>
              Voeg een eind datum toe en beeindig de additionele verzekering
            </p>
            <TextField
              floatingLabelText={"Eind datum verzekering (DD-MM-JJJJ)"}
              name="end_date"
              errorText={error}
              value={endDate}
              fullWidth
              onChange={(event: any) =>
                this.setState({ endDate: event.target.value })
              }
            />
            <VerticalSeparator distance={2} />
            <NavButtonContainer>
              <RaisedButton
                // @ts-ignore
                id="back"
                label="Annuleren"
                primary={false}
                onClick={close}
              />
              <RaisedButton
                // @ts-ignore
                id="confirm"
                label={"Bevestig"}
                backgroundColor={green}
                labelColor={white}
                disabled={error != null || loading}
                onClick={async () => {
                  this.setState({ loading: true });

                  try {
                    await this.props.updateEndDate({
                      policyId: this.props.policyId,
                      cancellationDate: serializeDate(endDate),
                    });
                    window.location.reload();
                    return;
                  } catch (err) {
                    this.props.error.display();
                  } finally {
                    this.setState({ loading: false });
                  }
                }}
              />
            </NavButtonContainer>
          </DialogContainer2>
        </Dialog>
      </div>
    );
  }
}

export default withEndAdditionalInsurance(errorHandler(UpdateEndDateButton));
