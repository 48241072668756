// @flow

import React from "react";
import LinearProgress from "material-ui/LinearProgress";

const Loading = () => (
  <div style={{ textAlign: "center" }}>
    <h2>Het bericht wordt verzonden...</h2>
    <LinearProgress mode="indeterminate" />
  </div>
);

export default Loading;
