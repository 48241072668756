import React from "react";
import ExpandablePage from "../../components/expandablePageSection";

const TasksBoard = () => (
  <ExpandablePage title="Taken BCB">
    <p>Het takenpakket van BCB bestaat uit:</p>
    <ul>
      <li>
        Het tegen een zo laag mogelijke prijs aanbieden van een
        pakketvergoeding, die aangewend kan worden voor hetzij een begrafenis,
        hetzij een crematie.
      </li>
      <li>
        Daaruit voortvloeiend hebben wij ons tot doel gesteld dit tegen een zo
        laag mogelijk contributie te realiseren, echter ook rekening houdend met
        de algemene prijsontwikkelingen op het gebied van uitvaart.
      </li>
      <li>
        Om dat te realiseren werken wij met een aantal vrijwilligers waarvan een
        klein deel een beperkte vergoeding krijgt. Op deze manier houden wij de
        administratieve kosten heel erg laag.
      </li>
      <li>
        Wij werken samen met de Rabobank en Kleynen Consultants om met onze
        (defensieve) beleggingen een zo hoog mogelijk rendement te realiseren
      </li>
      <li>
        Wij hebben een samenwerkingsverband met{" "}
        <b>
          <a href="https://www.mok-uitvaart.nl">Mok Uitvaartverzorging</a>
        </b>{" "}
        te Heerlen en{" "}
        <b>
          <a href="https://www.paffenuitvaartverzorging.nl">
            Uitvaartverzorging Paffen
          </a>
        </b>{" "}
        te Kerkrade
      </li>
    </ul>
  </ExpandablePage>
);

/*
 <b>
                <a href="https://www.mok-uitvaart.nl">Mok Uitvaartverzorging</a>
              </b>{" "}
              te Heerlen en{" "}
              <b>
                <a href="https://www.paffenuitvaartverzorging.nl">
                  Uitvaartverzorging Paffen
                </a>
              </b>{" "}
              */

export default TasksBoard;
