//@flow
import React from "react";
import { withRouter, WithRouterProps } from "react-router";
import Header from "./header";
import { SideBar } from "../../components/header";
import Existing from "./existing";
import Registered from "./registered";
import New from "./new";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";

const styles: { [key: string]: React.CSSProperties } = {
  outerContainer: {
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "70px",
  },
  title: {
    width: "600px",
    textAlign: "center",
  },
  block: {
    width: "400px",
  },
};

type State = {
  state:
    | {
        type: "EXISTING";
      }
    | {
        type: "NEW";
      }
    | {
        type: "REGISTERED";
        email: string;
        accountId: string;
      };
};

type Props = WithRouterProps;

export class RegisterAccount extends React.Component<Props, State> {
  state: State = {
    state: {
      type: "EXISTING",
    },
  };

  render() {
    const { router } = this.props;
    const { state } = this.state;

    const goToMemberSignup = (accountId: string) => {
      // TODO: Remove
      router.push(`/bestuurder/inschrijven/${accountId}/lid`);
    };

    let content;
    if (state.type === "EXISTING") {
      content = <Existing onConfirm={goToMemberSignup} />;
    } else if (state.type === "NEW") {
      content = (
        <New
          onRegistered={(accountId, email) => {
            this.setState({ state: { type: "REGISTERED", email, accountId } });
          }}
        />
      );
    } else {
      content = (
        <Registered
          email={state.email}
          onConfirm={() => goToMemberSignup(state.accountId)}
        />
      );
    }

    let radioButtons;

    if (state.type === "EXISTING" || state.type === "NEW") {
      radioButtons = (
        <RadioButtonGroup
          // @ts-ignore
          id="group"
          name="createClient"
          defaultSelected={state.type}
          onChange={(event: any) => {
            const { value } = event.target;
            if (value === "EXISTING") {
              this.setState({ state: { type: "EXISTING" } });
            } else if (value === "NEW") {
              this.setState({ state: { type: "NEW" } });
            }
          }}
        >
          <RadioButton
            // @ts-ignore
            id="existing"
            value="EXISTING"
            label="Bestaand"
            style={{ paddingBottom: "10px" }}
          />
          {/* @ts-ignore */}
          <RadioButton id="new" value="NEW" label="Nieuw" />
        </RadioButtonGroup>
      );
    }

    return (
      <div>
        <Header />
        <SideBar />
        <div style={styles.outerContainer}>
          <h2 style={styles.title}>
            Registreer een nieuw lid voor een bestaand of nieuw account.
          </h2>
          <div style={styles.block}>
            {radioButtons}
            {content}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RegisterAccount);
