// @flow

import { FragmentType, gql, useFragment } from "generated";
import React from "react";
import { colors } from "../../styling";

const styles: { [key: string]: React.CSSProperties } = {
  allInfoHolder: {
    width: "500px",
    textAlign: "left",
    justifyContent: "left",
    alignItems: "left",
    lineHeight: 1.5,
  },
  infoAndPic: { display: "flex", flexDirection: "row" },
  greeting: { fontSize: "36px" },
  userInfo: {
    paddingBottom: "24px",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    justifyContent: "left",
    alignItems: "left",
  },
  infoTitle: {
    paddingTop: "10px",
    fontSize: "18px",
    color: colors.green,
  },
};

type Props = {
  infoProfile: FragmentType<typeof profileInfoFragment>;
};

const ProfileInfo = ({ infoProfile }: Props) => {
  const data = useFragment(profileInfoFragment, infoProfile);

  let currenthour = new Date().getHours();
  let message = "";
  if (currenthour < 6) message = "Goedenacht";
  else if (currenthour < 12) message = "Goedemorgen";
  else if (currenthour < 18) message = "Goedemiddag";
  else message = "Goedenavond";

  return (
    <div style={{ ...styles.allInfoHolder }}>
      <div style={styles.greeting}>{message}</div>
      <div
        style={{
          ...styles.infoAndPic,
          paddingTop: "15px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: "24px" }}>Accountoverzicht</div>
        <div style={{ ...styles.userInfo, flex: 1 }}>
          <div style={styles.infoTitle}>Naam</div>
          {data.fullName}
        </div>
      </div>
    </div>
  );
};

export const profileInfoFragment = gql(`
  fragment ProfileInfoBestuurder on Account {
    id
    fullName
  }`);

export default ProfileInfo;
