// @flow

import React, { Component } from "react";
import { colors } from "../../../styling";
import { addQuote } from "../../../api";
import { serializeDate, parseAmount, formatPrice } from "../../../utils";

import CircularProgress from "material-ui/CircularProgress";
import RaisedButton from "material-ui/RaisedButton";
import { Frequency } from "generated/graphql";
import { formatFrequency2 } from "utils/formatter";

type ResponseState =
  | {
      quoteExists: false;
    }
  | {
      quoteExists: true;
      contribution: number;
      lumpsum: number;
      frequency: Frequency;
    };

type State = {
  loading: boolean;
  apiError: boolean;
  response: ResponseState | null;
  notCalculated: boolean;
};

type Props = {
  birthdate: string;
  frequency: Frequency;
  selectedPackage: {
    id: string;
    name: string;
  };
  shouldCalculate: () => boolean;
};

export default class CustomContribution extends Component<Props, State> {
  state: State = {
    loading: false,
    apiError: false,
    response: null,
    notCalculated: false,
  };

  resetPremium(): void {
    this.setState({
      response: null,
    });
  }

  getInput(): {
    premium: number;
    lumpsum: number;
  } {
    const { response } = this.state;

    if (!response || !response.quoteExists) {
      throw new Error(`Expected a valid quote but got ${String(response)}`);
    }

    const { contribution, lumpsum } = response;
    return {
      premium: Number(contribution),
      lumpsum: Number(lumpsum),
    };
  }

  /**
   * Validates the input
   * @returns true if the input is correct.
   */
  validate(): boolean {
    const { response } = this.state;
    if (response != null) {
      this.setState({
        notCalculated: false,
      });
      return true;
    } else {
      this.setState({
        notCalculated: true,
      });
      return false;
    }
  }

  async calculatePremium() {
    const { birthdate, frequency, selectedPackage } = this.props;
    try {
      const responseAPI = await addQuote(
        serializeDate(birthdate),
        selectedPackage.id,
        frequency
      );
      const { quote, quoteExists } = responseAPI;
      let response: ResponseState;

      if (quoteExists === false) {
        response = {
          quoteExists: false,
        };
      } else {
        if (!quote)
          throw new Error("Expected GraphQL response to contain a quote.");

        response = {
          quoteExists: true,
          contribution: quote.premium,
          lumpsum: quote.lumpsum,
          frequency: quote.frequency,
        };
      }

      this.setState({
        response,
        loading: false,
      });
    } catch (e) {
      this.setState({ apiError: true });
    }
  }

  render() {
    const { apiError, response, loading } = this.state;
    const { frequency, shouldCalculate } = this.props;

    if (apiError === true) {
      return (
        <span style={{ color: "grey", textAlign: "center" }}>
          Er is een fout opgetreden, waardoor er geen prijs berekend kan worden.
          <br />
          <br />
          Neem contact op met ons als dit zich blijft voordoen.
        </span>
      );
    } else {
      let contentBottom;
      if (response) {
        if (response.quoteExists) {
          contentBottom = (
            <div>
              <p>
                De contributie is <Amount amount={response.contribution} />{" "}
                {formatFrequency2(frequency).toLocaleLowerCase()}.
              </p>
              <p>
                De koopsom is <Amount amount={response.lumpsum} />
              </p>
            </div>
          );
        } else {
          contentBottom = (
            <p>
              Gegeven de leeftijd van het lid is het niet mogelijk om een
              standaard contributie uit te rekenen. Geef een aangepaste
              contributie op.
            </p>
          );
        }
      }
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {loading ? (
            <CircularProgress color={colors.green} />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RaisedButton
                label="Bereken"
                data-test="quote_calculate"
                backgroundColor={colors.green}
                labelColor={colors.white}
                style={{ width: "30%" }}
                onClick={() => {
                  if (shouldCalculate()) {
                    this.calculatePremium();
                    this.setState({
                      notCalculated: false,
                    });
                  }
                }}
              />
              {this.state.notCalculated ? (
                <p style={{ color: "red", fontSize: "12px" }}>
                  Er is nog geen contributie berekend.
                </p>
              ) : null}
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "30px",
            }}
          >
            {contentBottom}
          </div>
        </div>
      );
    }
  }
}

const Amount = (props: { amount: number }) => (
  <span style={{ color: colors.green }}>
    {formatPrice(parseAmount(props.amount))}
  </span>
);
