//@flow

import React from "react";
import { PackagePicker } from "../../../../../components/contribution";
import { Subtitle } from "../../../../../components/dialog";
import { formatToEuro } from "../../../../../utils/formatter";

type Package = {
  id: string;
  name: string;
  insuredAmount: number;
};

export const PackagePickerContainer = (props: {
  onSelectPackage: (pack: Package) => void;
  packages: Array<Package>;
  selectedPackage: Package | null;
}) => {
  const { onSelectPackage, packages, selectedPackage } = props;

  return (
    <div>
      <Subtitle>Kies een nieuw pakket</Subtitle>
      <PackagePicker
        // @ts-ignore
        id="packPicker"
        packages={packages}
        onSelectPackage={onSelectPackage}
        selectedPackage={selectedPackage}
      />
      {selectedPackage ? (
        <InsuredAmount
          // @ts-ignore
          id="insured"
          insuredAmount={selectedPackage.insuredAmount}
        />
      ) : null}
      {/* @ts-ignore */}
      {packages.length <= 0 ? <NoAvailablePackages id="noPackages" /> : null}
    </div>
  );
};

export const InsuredAmount = ({ insuredAmount }: { insuredAmount: number }) => (
  <p>Dit pakket heeft een waarde van {formatToEuro(insuredAmount)}</p>
);

export const NoAvailablePackages = () => (
  <p>
    Er zijn geen andere pakketten beschikbaar waartussen gewisseld kan worden.
  </p>
);
