// @flow

import React, { Component } from "react";
import RaisedButton from "material-ui/RaisedButton";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import { colors } from "../../../../../../styling";
import ActiveDialogDecease from "./activeDialogDecease";
import DischargeDialog from "./dischargeDialog";
import CancelDialog from "./cancelDialog";
import { NavButtonContainer } from "components/dialog";
import { DialogContainer2 } from "components/dialog/container";

const { green } = colors;

type PropsParent = { handleClose(): void; policyId: string };
type Props = PropsParent;

type State = {
  state:
    | "CANCEL"
    | "CANCEL_CONFIRM"
    | "DISCHARGE"
    | "DISCHARGE_CONFIRM"
    | "DECEASE"
    | "DECEASE_CONFIRM";
  dateOfDeath: string;
};

class ActiveDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { state: "CANCEL", dateOfDeath: "" };
    this._getMainContent = this._getMainContent.bind(this);
  }

  _updateState = async () => {
    const { state } = this.state;

    switch (state) {
      case "CANCEL":
        this.setState({ state: "CANCEL_CONFIRM" });
        break;
      case "DISCHARGE":
        this.setState({ state: "DISCHARGE_CONFIRM" });
        break;
      case "DECEASE":
        this.setState({ state: "DECEASE_CONFIRM" });
        break;
      default:
        throw new Error("Unknown policy state");
    }
  };

  _getMainContent() {
    const { handleClose } = this.props;
    return (
      <DialogContainer2>
        <h2>Update de status van het lid</h2>
        <p>
          Update de status van het lid wanneer het lid is overleden, heeft
          opgezegd of is geroyeerd door één van de onderstaande opties te
          selecteren.
        </p>
        <RadioButtonGroup
          defaultSelected={this.state.state}
          name="PolicyStateSelection"
          onChange={(event: any) => {
            const { value } = event.target;
            if (
              value === "CANCEL" ||
              value === "DISCHARGE" ||
              value === "DECEASE"
            ) {
              this.setState({ state: value });
            } else {
              throw new Error("Unknown state");
            }
          }}
        >
          <RadioButton
            value="CANCEL"
            label="Afmelden"
            data-test="status_cancel_button"
            style={{ marginBottom: 16 }}
            iconStyle={{ fill: colors.green }}
          />
          <RadioButton
            value="DISCHARGE"
            data-test="status_discharge_button"
            label="Royeren"
            style={{ marginBottom: 16 }}
            iconStyle={{ fill: colors.green }}
          />
          <RadioButton
            value="DECEASE"
            label="Overleden"
            style={{ marginBottom: 16 }}
            iconStyle={{ fill: colors.green }}
          />
        </RadioButtonGroup>
        <NavButtonContainer>
          <RaisedButton
            // @ts-ignore
            id="back"
            label="Annuleren"
            primary={false}
            onClick={handleClose}
          />
          <RaisedButton
            // @ts-ignore
            id="confirm"
            data-test="confirm_state_selection_button"
            label="Bevestig"
            backgroundColor={green}
            labelStyle={{ color: "white" }}
            onClick={() => this._updateState()}
          />
        </NavButtonContainer>
      </DialogContainer2>
    );
  }

  render() {
    const { state } = this.state;
    const { policyId, handleClose } = this.props;

    let content;
    if (state === "DECEASE_CONFIRM")
      content = (
        <ActiveDialogDecease
          policyId={policyId}
          handleClose={handleClose}
          handleBack={() => this.setState({ state: "DECEASE" })}
        />
      );
    else if (state === "CANCEL_CONFIRM")
      content = (
        <CancelDialog
          policyId={policyId}
          handleClose={handleClose}
          handleBack={() => this.setState({ state: "CANCEL" })}
        />
      );
    else if (state === "DISCHARGE_CONFIRM")
      content = (
        <DischargeDialog
          policyId={policyId}
          handleClose={handleClose}
          handleBack={() => this.setState({ state: "DISCHARGE" })}
        />
      );
    else {
      content = this._getMainContent();
    }

    return <div data-test="update_status_dialog">{content}</div>;
  }
}

export default ActiveDialog;
