// @flow

import React, { Component } from "react";
import { getPackages, Package } from "../../../api";
import config from "../../../config";
import CircularProgress from "material-ui/CircularProgress";
import Contribution from "./contribution";

type State = {
  state:
    | {
        type: "PACKAGE_ERROR";
      }
    | {
        type: "PACKAGE_LOADING";
      }
    | {
        type: "PACKAGE_RETRIEVED";
        packages: Array<Package>;
      };
};
export default class PackageLoader extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      state: {
        type: "PACKAGE_LOADING",
      },
    };
  }
  //$FlowFixMe
  async componentDidMount() {
    this.setState({
      state: {
        type: "PACKAGE_LOADING",
      },
    });

    try {
      const packages = await getPackages(config.associationId);
      if (packages.length === 0) {
        this.setState({
          state: {
            type: "PACKAGE_ERROR",
          },
        });
      } else {
        this.setState({
          state: {
            type: "PACKAGE_RETRIEVED",
            packages,
          },
        });
      }
    } catch (err) {
      this.setState({
        state: {
          type: "PACKAGE_ERROR",
        },
      });
    }
  }

  render = () => {
    const { state } = this.state;
    switch (state.type) {
      case "PACKAGE_ERROR":
        return (
          <span style={{ color: "grey", textAlign: "center" }}>
            Er is een fout opgetreden, waardoor er geen prijs berekend kan
            worden. <br />
            <br />
            Neem contact op met ons als dit zich blijft voordoen.
          </span>
        );
      case "PACKAGE_LOADING":
        return <CircularProgress />;
      case "PACKAGE_RETRIEVED":
        return <Contribution packages={state.packages} />;
      default:
        // @ts-ignore
        throw new Error(`Unrecognized type ${state.type}`);
    }
  };
}
