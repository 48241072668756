// @flow

import { withApollo } from "@apollo/client/react/hoc";
import { errorHandler } from "containers";
import { withRouter } from "react-router";
import { withMutation } from "./container";

import DeleteInsuredPerson, {
  PropsParentsDeleteInsuredPerson,
} from "./presentation";

const Component = withApollo<PropsParentsDeleteInsuredPerson, any>(
  withMutation(withRouter(errorHandler(DeleteInsuredPerson)))
);

export default Component;
