//@flow

import { Dispatch } from "../../reducers";
import { validateIban } from "../../utils";
import { increaseStepperIndex } from "./index";

export type SignupPaymentError =
  | "EMPTY_IBAN_ERROR"
  | "INVALID_IBAN_ERROR"
  | "EMPTY_ACCOUNT_NAME_ERROR";

type ActionAddSignupIban = {
  type: "ADD_SIGNUP_IBAN";
  iban: string;
};

type ActionAddSignupAccountName = {
  type: "ADD_SIGNUP_ACCOUNTNAME";
  accountName: string;
};

type ActionUpdateErrorsPayment = {
  type: "UPDATE_ERRORS_SIGNUP_PAYMENT";
  errors: Array<SignupPaymentError>;
};

export type PaymentState = {
  iban: string;
  accountName: string;
};

type ActionSetPaymentState = {
  type: "SET_SIGNUP_PAYMENT";
  input: PaymentState;
};

export type Action =
  | ActionAddSignupIban
  | ActionAddSignupAccountName
  | ActionSetPaymentState
  | ActionUpdateErrorsPayment;

export const addSignupIban = (iban: string): Action => ({
  type: "ADD_SIGNUP_IBAN",
  iban,
});

export const addSignupAccountName = (accountName: string): Action => ({
  type: "ADD_SIGNUP_ACCOUNTNAME",
  accountName,
});

export const updatePaymentErrors = (
  errors: Array<SignupPaymentError>
): Action => {
  return {
    type: "UPDATE_ERRORS_SIGNUP_PAYMENT",
    errors,
  };
};

export const setPaymentState =
  (input: PaymentState) => (dispatch: Dispatch) => {
    let errors: Array<SignupPaymentError> = [];

    if (!input.iban) errors.push("EMPTY_IBAN_ERROR");
    else if (!validateIban(input.iban)) errors.push("INVALID_IBAN_ERROR");

    if (!input.accountName) errors.push("EMPTY_ACCOUNT_NAME_ERROR");

    if (errors.length > 0) {
      dispatch(updatePaymentErrors(errors));
    } else {
      dispatch(increaseStepperIndex());
    }
  };
