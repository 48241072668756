// @flow

import React from "react";

import Button from "../../components/actionButton";

type Props = {
  onTapLapse: () => void;
};

const LapseMembership = (props: Props) => (
  <div>
    <LapseButton onTapLapse={props.onTapLapse} />
  </div>
);

export default LapseMembership;

type LapseButtonProps = {
  onTapLapse: () => void;
};

const LapseButton = ({ onTapLapse }: LapseButtonProps) => (
  <Button label="lidmaatschap opzeggen" onClick={onTapLapse} />
);
