// @flow

import React from "react";

import Button from "../../components/actionButton";

type Props = {
  onTapTransfer: () => void;
};

const TransferMembership = (props: Props) => {
  const { onTapTransfer } = props;

  return (
    <div>
      <TransferButton onTapTransfer={onTapTransfer} />
    </div>
  );
};
export default TransferMembership;

type TransferButtonProps = {
  onTapTransfer: () => void;
};

const TransferButton = ({ onTapTransfer }: TransferButtonProps) => (
  <Button
    // @ts-ignore
    id="transferButton"
    label="lidmaatschap overzetten"
    onClick={onTapTransfer}
  />
);
