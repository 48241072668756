import {
  Box,
  Grid,
  ListItemText,
  ListItem,
  Tooltip,
  IconButton,
  ListItemButton,
  Typography,
} from "@mui/material";
import { FragmentType, gql, useFragment } from "generated";
import {
  formatAddress,
  formatPaymentMethod2,
  formatPremiumWithFrequency,
  formatToEuro,
} from "utils/formatter";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { WithRouterProps, withRouter } from "react-router";
import { insurerPaths } from "screens/routes";
import { pushWithPreviousPath } from "utils/routing";
import { UpdateAddressEditButton } from "./updates/updateAddress";
import { UpdatePaymentButton } from "./updates/updatePayment";
import { UpdateGroupPhoneButton } from "./updates/UpdatePhone";

type Props = {
  data: FragmentType<typeof fragment>;
} & WithRouterProps;

export const GroupInfo = withRouter((props: Props) => {
  const { address, payment, premium, phone, account, insuredPeople } =
    useFragment(fragment, props.data);

  const insuredPersonIds = insuredPeople.map((ip) => ip.id);

  const updatePaymentButton = (
    <UpdatePaymentButton
      insuredPersonIds={insuredPersonIds}
      paymentInfo={payment}
    />
  );
  const dataList = [];

  let phoneText;
  if (phone == null) {
    if (insuredPeople.some((ip) => ip.phone != null))
      phoneText = "De leden in de groep hebben geen eenduidig telefoonnummer";
    else phoneText = "Niet gezet";
  } else phoneText = phone;

  address &&
    dataList.push({
      key: "Adres",
      value: formatAddress(address),
      edit: <UpdateAddressEditButton insuredPersonIds={insuredPersonIds} />,
    });
  dataList.push({
    key: "Telefoonnummer",
    value: phoneText,
    edit: <UpdateGroupPhoneButton insuredPersonIds={insuredPersonIds} />,
  });
  if (account)
    dataList.push({
      item: (
        <ListItemButton
          onClick={() =>
            pushWithPreviousPath(
              props,
              insurerPaths.accountsDetails.path(account.id)
            )
          }
        >
          <ListItemText
            sx={{ textAlign: "center" }}
            primary={"Email"}
            secondary={account.username}
          />
          <Tooltip title="Beheer account">
            <IconButton>
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
        </ListItemButton>
      ),
    });
  if (payment) {
    payment.iban &&
      dataList.push({
        key: "Iban",
        value: payment.iban,
        edit: updatePaymentButton,
      });
    dataList.push({
      key: "Betalingsmethode",
      value: formatPaymentMethod2(payment.paymentMethod),
      edit: updatePaymentButton,
    });
  }

  const premiumStatsList = [];

  if (premium) {
    premium.policy?.termPremium &&
      premiumStatsList.push({
        key: "Premie",
        value: formatPremiumWithFrequency(
          premium.policy.termPremium.premium,
          premium.policy.termPremium.frequency
        ),
      });
    premium.policy &&
      premiumStatsList.push({
        key: "Jaarpremie",
        value: formatToEuro(premium.policy.yearPremium),
      });

    premium.additionalInsurance?.termPremium &&
      premiumStatsList.push({
        key: "AV Premie",
        value: formatPremiumWithFrequency(
          premium.additionalInsurance.termPremium.premium,
          premium.additionalInsurance.termPremium.frequency
        ),
      });
    premium.additionalInsurance &&
      premiumStatsList.push({
        key: "AV Jaarpremie",
        value: formatToEuro(premium.additionalInsurance.yearPremium),
      });
  }

  return (
    <Box
      display="flex"
      justifyContent={"center"}
      paddingTop={2}
      paddingBottom={6}
    >
      <Box display="flex" flexDirection="column">
        <GridList list={dataList} xs={4} /> <Box paddingTop={3} />
        <Typography variant="h5" textAlign="center">
          <b>Premie statistieken</b>
        </Typography>
        {/* @ts-ignore */}
        <GridList list={premiumStatsList} xs={12 / premiumStatsList.length} />
      </Box>
    </Box>
  );
});

const GridList = ({
  list,
  xs,
}: {
  xs: number;
  list: (
    | { key: string; value: string; edit: JSX.Element }
    | { item: JSX.Element }
  )[];
}) => {
  return (
    <Grid container spacing={2} width="900px">
      {/* @ts-ignore */}
      {list.map(({ key, value, edit, item }, index) => (
        <Grid item xs={xs} key={index}>
          {item ? (
            item
          ) : (
            <ListItem>
              <ListItemText
                sx={{ textAlign: "center" }}
                primary={key}
                secondary={value}
              />
              {edit}
            </ListItem>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

const fragment = gql(`

fragment IpGrouping on InsuredPeopleGrouping {
    insuredPeople {
        id
    }
    phone
    account {
        id
        username
    }
    address {
      id
      postalCode
      suffix
      houseNumber
      suffix
      street
      city
      countryCode
    }
    payment {
      iban
      bic
      accountHolder
      paymentMethod
    }
    insuredPeople {
        id
        phone
    }
    premium {
        policy {
            yearPremium
            termPremium {
                premium
                frequency
            }
        }
        additionalInsurance {
            yearPremium
            termPremium {
                premium
                frequency
            }
        }
    }
  }`);
