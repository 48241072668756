// @flow

import React from "react";
import ExpandablePage from "../../components/expandablePageSection";

const Financien = () => (
  <ExpandablePage title="Financiën">
    <p>
      Nieuwe leden kunnen alleen nog betalen via automatische incasso. De
      contributie voor uw lidmaatschap wordt één keer per kwartaal, per half
      jaar, of één keer per jaar geïncasseerd van uw rekening. De betaaldata die
      we hanteren zijn altijd omstreeks 25 januari, 25 april, 25 juli en 25
      oktober.
    </p>
    <p>
      Bij bestaande leden die via acceptgiro betalen wordt een bedrag van €2,50
      aan administratiekosten in rekening gebracht per incasso.
    </p>
  </ExpandablePage>
);

export default Financien;
