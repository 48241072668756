// @flow
import React from "react";
import { Frequency, Frequency as GraphQLFrequency } from "generated/graphql";
import { colors } from "../../../../styling";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { formatFrequency2 } from "utils/formatter";

export const FrequencySelector = ({
  frequency,
  setFrequency,
  frequencies,
}: {
  frequency: Frequency;
  setFrequency: (frequency: Frequency) => void;
  frequencies?: Frequency[];
}) => {
  const frequenciesToRender = frequencies
    ? frequencies
    : [Frequency.Yearly, Frequency.SemiAnnually, Frequency.Quarterly];

  return (
    <SelectField
      data-test="update_frequency_select"
      floatingLabelText="Betalingsfrequentie"
      floatingLabelStyle={{ color: colors.green }}
      value={frequency}
      style={{ width: "100%" }}
      selectedMenuItemStyle={{ color: colors.green }}
      underlineFocusStyle={{ borderColor: colors.green }}
      onChange={(event, index, value) => {
        setFrequency(value);
      }}
    >
      {frequenciesToRender.map((freq, index) => (
        <MenuItem
          value={freq}
          key={index}
          primaryText={formatFrequency2(freq)}
        />
      ))}
    </SelectField>
  );
};

export const FrequencySelector2 = ({
  frequency,
  setFrequency,
  frequencies,
}: {
  frequency: GraphQLFrequency;
  setFrequency: (frequency: GraphQLFrequency) => void;
  frequencies?: GraphQLFrequency[];
}) => {
  const frequenciesToRender = frequencies
    ? frequencies
    : [
        GraphQLFrequency.Yearly,
        GraphQLFrequency.SemiAnnually,
        GraphQLFrequency.Quarterly,
      ];

  return (
    <SelectField
      data-test="update_frequency_select"
      floatingLabelText="Betalingsfrequentie"
      floatingLabelStyle={{ color: colors.green }}
      value={frequency}
      style={{ width: "100%" }}
      selectedMenuItemStyle={{ color: colors.green }}
      underlineFocusStyle={{ borderColor: colors.green }}
      onChange={(event, index, value) => {
        setFrequency(value);
      }}
    >
      {frequenciesToRender.map((freq, index) => (
        <MenuItem
          value={freq}
          key={index}
          primaryText={formatFrequency2(freq)}
        />
      ))}
    </SelectField>
  );
};
