// @flow

import React, { Component } from "react";
import { colors } from "../../../../../styling";
import { parseAmount, formatPrice } from "../../../../../utils";
import { gql } from "@apollo/client";

import CircularProgress from "material-ui/CircularProgress";
import RaisedButton from "material-ui/RaisedButton";
import { clientNew } from "api/apollo2";

type Response =
  | {
      quoteExists: false;
    }
  | {
      quoteExists: true;
      contribution: number;
      lumpsum: number;
      frequency: "YEARLY" | "QUARTERLY";
    };

type State = {
  loading: boolean;
  apiError: boolean;
  response: Response | null;
  notCalculated: boolean;
};

type PropsParents = {
  policyId: string;
  selectedPackageId: string;
};

type Result = {
  updatePackageQuote: {
    quote: {
      id: string;
      birthdate: string;
      premium: number;
      lumpsum: number;
      frequency: "YEARLY" | "QUARTERLY";
    };
    quoteExists: boolean;
  } | null;
};

type Props = PropsParents;

export const UpdatePackageQuoteMutationDef = gql`
  mutation updatePackageQuote($input: UpdatePackageQuoteInput!) {
    updatePackageQuote(input: $input) {
      quote {
        id
        birthdate
        premium
        lumpsum
        frequency
      }
      quoteExists
    }
  }
`;

class StandardContribution extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      apiError: false,
      response: null,
      notCalculated: false,
    };
  }

  resetPremium(): void {
    this.setState({
      response: null,
    });
  }

  getInput(): {
    premium: number;
    lumpsum: number;
  } {
    const { response } = this.state;

    if (!response || !response.quoteExists) {
      throw new Error(`Expected a valid quote but got ${String(response)}`);
    }

    const { contribution, lumpsum } = response;
    return {
      premium: Number(contribution),
      lumpsum: Number(lumpsum),
    };
  }

  /**
   * Validates the input
   * @returns true if the input is correct.
   */
  validate(): boolean {
    const { response } = this.state;
    if (response != null) {
      this.setState({
        notCalculated: false,
      });
      return true;
    } else {
      this.setState({
        notCalculated: true,
      });
      return false;
    }
  }

  async calculatePremium() {
    const { selectedPackageId, policyId } = this.props;
    try {
      const responseAPI = await clientNew.mutate<Result>({
        mutation: UpdatePackageQuoteMutationDef,
        variables: {
          input: {
            policyId: policyId,
            packageId: selectedPackageId,
          },
        },
      });
      const deepResponse = responseAPI?.data?.updatePackageQuote;
      let response: Response;
      if (deepResponse == null || deepResponse.quoteExists === false) {
        response = {
          quoteExists: false,
        };
      } else {
        if (!deepResponse.quote)
          throw new Error("Expected GraphQL response to contain a quote.");

        response = {
          quoteExists: true,
          contribution: deepResponse.quote.premium,
          lumpsum: deepResponse.quote.lumpsum,
          frequency: deepResponse.quote.frequency,
        };
      }

      this.setState({
        response,
        loading: false,
      });
    } catch (e) {
      this.setState({ apiError: true });
    }
  }

  render() {
    const { apiError, response, loading } = this.state;

    if (apiError === true) {
      return (
        <span style={{ color: "grey", textAlign: "center" }}>
          Er is een fout opgetreden, waardoor er geen prijs berekend kan worden.
          <br />
          <br />
          Neem contact op met ons als dit zich blijft voordoen.
        </span>
      );
    } else {
      let contentBottom;
      if (response) {
        if (response.quoteExists) {
          contentBottom = (
            <p>
              De contributie is{" "}
              <span style={{ color: colors.green }}>
                {formatPrice(parseAmount(response.contribution))}
              </span>{" "}
              {response.frequency === "YEARLY" ? "per jaar" : "per kwartaal"}.
            </p>
          );
        } else {
          contentBottom = (
            <p>
              Gegeven de leeftijd van het lid is het niet mogelijk om een
              standaard contributie uit te rekenen. Geef een aangepaste
              contributie op.
            </p>
          );
        }
      }
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {loading ? (
            <CircularProgress color={colors.green} />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RaisedButton
                label="Bereken"
                backgroundColor={colors.green}
                labelColor={colors.white}
                style={{ width: "30%" }}
                onClick={() => {
                  this.calculatePremium();
                  this.setState({
                    notCalculated: false,
                  });
                }}
              />
              {this.state.notCalculated ? (
                <p style={{ color: "red" }}>
                  Er is nog geen contributie berekend.
                </p>
              ) : null}
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "30px",
            }}
          >
            {contentBottom}
          </div>
        </div>
      );
    }
  }
}

export default StandardContribution;
