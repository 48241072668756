// @flow

import React from "react";
import { gql } from "@apollo/client";
import { colors } from "../../../../../styling/index";
import MultiPolicyCard, { MultiPolicyCardFragment } from "./multiPolicyCard";
import { filter } from "graphql-anywhere";
import { DialogContainer2 } from "components/dialog/container";
import { NavButtonContainer } from "components/dialog";
import { Button } from "@mui/material";
import { WithRouterProps, withRouter } from "react-router";
import { pushWithPreviousPath } from "utils/routing";
import { insurerPaths } from "screens/routes";

const styles: { [key: string]: React.CSSProperties } = {
  emptyScreen: {
    color: colors.grey,
    fontSize: "40px",
    fontWeight: 300,
  },
  container: {
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingBottom: "30px",
  },
};

type Props = {
  data: {
    id: string;
    memberId: string;
    fullName: string;
    policies: Array<{
      id: string;
    }>;
  };
  handleClose: () => void;
} & WithRouterProps;

export const MultiPoliciesDialogFragment = gql`
  fragment multiPoliciesDialog on InsuredPerson {
    id
    memberId
    fullName
    policies {
      id
      ...multiPolicyCard
    }
  }
  ${MultiPolicyCardFragment}
`;

class MultiPoliciesDialog extends React.Component<Props> {
  getCardList() {
    const { data } = this.props;
    if (data == null) return null;

    let rows: Array<React.ReactElement> = [];
    const numPolicies = data.policies.length;
    if (numPolicies === 0) {
      return (
        <div style={styles.emptyScreen}>We hebben geen leden kunnen vinden</div>
      );
    }
    for (let i = 0; i < numPolicies; i++) {
      rows.push(
        <div key={i} style={{ paddingBottom: "20px" }}>
          <MultiPolicyCard
            data={filter(MultiPolicyCardFragment, data.policies[i])}
            onDetails={() =>
              pushWithPreviousPath(
                this.props,
                insurerPaths.policyDetailsPage.path(data.policies[i].id)
              )
            }
          />
        </div>
      );
    }
    return <div>{rows}</div>;
  }

  render() {
    return (
      <DialogContainer2>
        <h2>Lidmaatschappen {this.props.data.fullName}</h2>
        <div>{this.getCardList()}</div>
        <NavButtonContainer>
          <Button onClick={() => this.props.handleClose()}>ANNULEER</Button>
        </NavButtonContainer>
      </DialogContainer2>
    );
  }
}

export default withRouter(MultiPoliciesDialog);
