import { Box, Button, Stack, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

export class ErrorBoundary extends React.Component<
  PropsWithChildren<{}>,
  { hasError: boolean }
> {
  state = { hasError: false };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export const ErrorPage = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      <Stack spacing={2}>
        <Typography variant="h5">
          Er is een fout opgetreden. Ververs de pagina of neem contact op met
          ons als dit zich blijft voordoen.
        </Typography>
        <Box display="flex" justifyContent="center">
          <Button onClick={() => window.location.reload()}>
            Ververs pagina
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

/*
 container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flexDirection: "column"
  },*/
