// @flow
import React, { Component } from "react";
import SectionTitle from "../../components/sectionTitle";
import KeyLabel from "../../components/keyLabel";
import ValueLabel from "../../components/valueLabel";
import ContentRow from "../../components/contentRow";
import RowSeparator from "../../components/rowSeparator";
import EditButton from "../../components/editButton";
import { gql } from "@apollo/client";
import "./details.css";
import { DocumentNode } from "graphql";

type Props = {
  data: {
    id: string;
    insuredPerson: {
      id: string;
      address: {
        id: string;
        postalCode: string;
        houseNumber: number;
        suffix: string;
        street: string;
        city: string;
      };
    };
  };
  addressComponent: () => void;
};

class AddressInfo extends Component<Props> {
  static fragments: {
    addressInfo: DocumentNode;
  };

  render() {
    const { data, addressComponent } = this.props;
    const { postalCode, houseNumber, suffix, street, city } =
      data.insuredPerson.address;

    return (
      <div>
        <div className="horizontal-align">
          <SectionTitle>Adres</SectionTitle>
          <EditButton onClick={addressComponent} />
        </div>
        <ContentRow>
          <KeyLabel>Postcode</KeyLabel>
          <ValueLabel>{postalCode}</ValueLabel>
        </ContentRow>
        <RowSeparator />
        <ContentRow>
          <KeyLabel>Huisnummer</KeyLabel>
          <ValueLabel>
            {houseNumber}
            {suffix}
          </ValueLabel>
        </ContentRow>
        <RowSeparator />
        <ContentRow>
          <KeyLabel>Straatnaam</KeyLabel>
          <ValueLabel>{street}</ValueLabel>
        </ContentRow>
        <RowSeparator />
        <ContentRow>
          <KeyLabel>Woonplaats</KeyLabel>
          <ValueLabel>{city}</ValueLabel>
        </ContentRow>
      </div>
    );
  }
}

AddressInfo.fragments = {
  addressInfo: gql`
    fragment AddressInfo on Policy {
      id
      insuredPerson {
        id
        address {
          id
          postalCode
          houseNumber
          suffix
          street
          city
        }
      }
    }
  `,
};

export default AddressInfo;
