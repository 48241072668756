//@flow
/**
 * Created by Joshua Scheidt on 19-10-2016.
 */

import React, { Component } from "react";
import { withRouter, WithRouterProps } from "react-router";
import { connect } from "react-redux";
import { closeNavigationBar, logout } from "../../actions";
import type { Dispatch, State } from "../../reducers";
import Drawer from "material-ui/Drawer";
import AppBar from "material-ui/AppBar";
import IconButton from "material-ui/IconButton";
import MenuItem from "material-ui/MenuItem";
import CloseIcon from "material-ui/svg-icons/navigation/close";
import LogoutIcon from "material-ui/svg-icons/navigation/cancel";
import BusinessIcon from "material-ui/svg-icons/editor/multiline-chart";
import AdministrationIcon from "material-ui/svg-icons/social/people";
import MyProfileIcon from "material-ui/svg-icons/social/person";
import CheckIcon from "material-ui/svg-icons/maps/person-pin-circle";
import RegistrationIcon from "material-ui/svg-icons/action/done";
import CommunicationIcon from "material-ui/svg-icons/communication/email";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsIcon from "material-ui/svg-icons/action/settings";
import TimelineIcon from "@mui/icons-material/Timeline";
import { blueHiThere } from "../../styling/index";
import type { AccountPermission } from "../../api";
import { insurerPaths } from "screens/routes";

const styles: { [key: string]: React.CSSProperties } = {
  menuItem: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  userImage: {
    width: 60,
    height: 60,
    borderRadius: "50%",
  },
  profileBox: {
    fontSize: 12,
    color: "white",
    paddingTop: 40,
    paddingBottom: 30,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  drawer: {
    backgroundColor: "#36425C",
  },
  itemBox: {
    fontSize: 12,
    color: "#72819B",
    paddingTop: 20,
    height: "15%",
    justifyContent: "center",
    alignItems: "center",
    verticalAlign: "middle",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    color: "#72819B",
    width: "40px",
    height: "40px",
  },
  itemBoxSelected: {
    fontSize: 12,
    color: "white",
    paddingTop: 20,
    height: "15%",
    justifyContent: "center",
    alignItems: "center",
    verticalAlign: "middle",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  iconSelected: {
    color: "white",
    width: "40px",
    height: "40px",
  },
  divider: {
    opacity: 0.2,
  },
  appBar: {
    backgroundColor: blueHiThere,
  },
};

type PropsReduxState = {
  /**
   * open: defines whether the navigation bar is opened or not.
   * @type: boolean
   */
  open: boolean;
  permissions: Array<AccountPermission>;
};

type PropsReduxDispatch = {
  closeNavigationBar: () => void;
  onLogout: () => void;
};

type Props = PropsReduxState & PropsReduxDispatch & WithRouterProps;

class SideBar extends Component<Props> {
  _checkPermission(perm: AccountPermission) {
    const permissions = this.props.permissions;
    return permissions.includes(perm);
  }

  render() {
    const styleForRoute = (route: string) => {
      if (this.props.router.isActive(route)) {
        return styles.iconSelected;
      } else return styles.icon;
    };
    //Todo find a better way to highlight the elements
    let profile = (
      <div style={styles.itemBox}>
        <MyProfileIcon style={styles.icon} />
        Profiel
      </div>
    );

    let overview = (
      <div style={styles.itemBox}>
        <BusinessIcon style={styles.icon} />
        Bestuur
      </div>
    );
    let administration = (
      <div style={styles.itemBox}>
        <AdministrationIcon style={styles.icon} />
        Administratie
      </div>
    );

    let accounts = (
      <div style={styles.itemBox}>
        <ManageAccountsIcon style={styleForRoute(insurerPaths.accountsPage)} />
        Accounts
      </div>
    );
    let ledenchecker = (
      <div style={styles.itemBox}>
        <CheckIcon style={styles.icon} />
        Ledenchecker
      </div>
    );
    let registration = (
      <div style={styles.itemBox}>
        <RegistrationIcon style={styles.icon} />
        Aanvragen
      </div>
    );

    let timeline = (
      <div style={styles.itemBox}>
        <TimelineIcon style={styles.icon} />
        Tijdslijn
      </div>
    );

    let settings = (
      <div style={styles.itemBox}>
        <SettingsIcon style={styles.icon} />
        Instellingen
      </div>
    );

    const communicationActive = this.props.router.isActive(
      "/bestuurder/communicatie"
    );

    let communication = (
      <div
        style={communicationActive ? styles.itemBoxSelected : styles.itemBox}
      >
        <CommunicationIcon
          style={communicationActive ? styles.iconSelected : styles.icon}
        />
        Communicatie
      </div>
    );

    let logoutElement = (
      <div style={styles.itemBox}>
        <LogoutIcon style={styles.icon} />
        Uitloggen
      </div>
    );

    if (this.props.router.isActive("/bestuurder")) {
      profile = (
        <div style={styles.itemBoxSelected}>
          <MyProfileIcon style={styles.iconSelected} />
          Profiel
        </div>
      );
    } else if (this.props.router.isActive("/bestuurder/dashboard")) {
      overview = (
        <div style={styles.itemBoxSelected}>
          <BusinessIcon style={styles.iconSelected} />
          Bestuur
        </div>
      );
    } else if (this.props.router.isActive("/bestuurder/leden")) {
      administration = (
        <div style={styles.itemBoxSelected}>
          <AdministrationIcon style={styles.iconSelected} />
          Administratie
        </div>
      );
    } else if (this.props.router.isActive("/bestuurder/leden-checker")) {
      ledenchecker = (
        <div style={styles.itemBoxSelected}>
          <CheckIcon style={styles.iconSelected} />
          Ledenchecker
        </div>
      );
    } else if (this.props.router.isActive("/bestuurder/aanmeldingen")) {
      registration = (
        <div style={styles.itemBoxSelected}>
          <RegistrationIcon style={styles.iconSelected} />
          Aanvragen
        </div>
      );
    } else if (this.props.router.isActive(insurerPaths.settingsPage)) {
      settings = (
        <div style={styles.itemBoxSelected}>
          <SettingsIcon style={styles.iconSelected} />
          Instellingen
        </div>
      );
    } else if (this.props.router.isActive(insurerPaths.timeline)) {
      timeline = (
        <div style={styles.itemBoxSelected}>
          <TimelineIcon style={styles.iconSelected} />
          Tijdslijn
        </div>
      );
    }
    return (
      <Drawer
        width={150}
        // @ts-ignore
        openPrimary={true}
        open={this.props.open}
        containerStyle={styles.drawer}
      >
        <div data-test="sidebar">
          <AppBar
            data-test="sidebar"
            style={styles.appBar}
            showMenuIconButton={false}
            iconElementRight={
              <IconButton onClick={this.props.closeNavigationBar}>
                <CloseIcon />
              </IconButton>
            }
          />

          {this._checkPermission("FINANCE") ? (
            <MenuItem
              style={styles.menuItem}
              onClick={() => {
                this.props.router.push("/bestuurder/dashboard");
                this.props.closeNavigationBar();
              }}
            >
              {overview}
            </MenuItem>
          ) : null}
          {this._checkPermission("ADMINISTRATION") ? (
            <MenuItem
              style={styles.menuItem}
              onClick={() => {
                this.props.router.push("/bestuurder/aanmeldingen");
                this.props.closeNavigationBar();
              }}
            >
              {registration}
            </MenuItem>
          ) : null}

          {this._checkPermission("ADMINISTRATION") ? (
            <MenuItem
              style={styles.menuItem}
              onClick={() => {
                this.props.router.push("/bestuurder/leden");
                this.props.closeNavigationBar();
              }}
            >
              {administration}
            </MenuItem>
          ) : null}
          {this._checkPermission("ADMINISTRATION") ? (
            <MenuItem
              style={styles.menuItem}
              onClick={() => {
                this.props.router.push(insurerPaths.accountsPage);
                this.props.closeNavigationBar();
              }}
            >
              {accounts}
            </MenuItem>
          ) : null}

          {this._checkPermission("MEMBER_CHECK") ? (
            <MenuItem
              style={styles.menuItem}
              onClick={() => {
                this.props.router.push("/bestuurder/leden-checker");
                this.props.closeNavigationBar();
              }}
            >
              {ledenchecker}
            </MenuItem>
          ) : null}

          {this._checkPermission("ADMINISTRATION") ? (
            <MenuItem
              style={styles.menuItem}
              onClick={() => {
                this.props.router.push("/bestuurder/communicatie");
                this.props.closeNavigationBar();
              }}
            >
              {communication}
            </MenuItem>
          ) : null}
          {this._checkPermission("ADMINISTRATION") ? (
            <MenuItem
              style={styles.menuItem}
              onClick={() => {
                this.props.router.push(insurerPaths.timeline);
                this.props.closeNavigationBar();
              }}
            >
              {timeline}
            </MenuItem>
          ) : null}
          <MenuItem
            style={styles.menuItem}
            onClick={() => {
              this.props.router.push("/bestuurder");
              this.props.closeNavigationBar();
            }}
          >
            {profile}
          </MenuItem>

          <MenuItem
            style={styles.menuItem}
            onClick={() => {
              this.props.router.push(insurerPaths.settingsPage);
              this.props.closeNavigationBar();
            }}
          >
            {settings}
          </MenuItem>
          <MenuItem
            style={styles.menuItem}
            onClick={() => {
              this.props.onLogout();
            }}
          >
            {logoutElement}
          </MenuItem>
        </div>
      </Drawer>
    );
  }
}

const mapStateToProps = ({ navbar, login }: State): PropsReduxState => ({
  open: navbar,
  permissions: login.permissions,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsReduxDispatch => ({
  closeNavigationBar: () => dispatch(closeNavigationBar()),
  // @ts-ignore
  onLogout: () => dispatch(logout()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(SideBar));
