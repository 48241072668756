// @flow

const ISO3166countryCodes = [
  { country: "Afghanistan", code: "AF" },
  { country: "Åland", code: "AX" },
  { country: "Albanië", code: "AL" },
  { country: "Algerije", code: "DZ" },
  { country: "Amerikaanse Maagdeneilanden", code: "VI" },
  { country: "Amerikaans-Samoa", code: "AS" },
  { country: "Andorra", code: "AD" },
  { country: "Angola", code: "AO" },
  { country: "Anguilla", code: "AI" },
  { country: "Antarctica", code: "AQ" },
  { country: "Antigua en Barbuda", code: "AG" },
  { country: "Argentinië", code: "AR" },
  { country: "Armenië", code: "AM" },
  { country: "Aruba", code: "AW" },
  { country: "Australië", code: "AU" },
  { country: "Azerbeidzjan", code: "AZ" },
  { country: "Bahama's", code: "BS" },
  { country: "Bahrein", code: "BH" },
  { country: "Bangladesh", code: "BD" },
  { country: "Barbados", code: "BB" },
  { country: "België", code: "BE" },
  { country: "Belize", code: "BZ" },
  { country: "Benin", code: "BJ" },
  { country: "Bermuda", code: "BM" },
  { country: "Bhutan", code: "BT" },
  { country: "Bolivia", code: "BO" },
  { country: "Caribisch Nederland", code: "BQ" },
  { country: "Bosnië en Herzegovina", code: "BA" },
  { country: "Botswana", code: "BW" },
  { country: "Bouveteiland", code: "BV" },
  { country: "Brazilië", code: "BR" },
  { country: "Britse Maagdeneilanden", code: "VG" },
  { country: "Brits Indische Oceaanterritorium", code: "IO" },
  { country: "Brunei", code: "BN" },
  { country: "Bulgarije", code: "BG" },
  { country: "Burkina Faso", code: "BF" },
  { country: "Burundi", code: "BI" },
  { country: "Cambodja", code: "KH" },
  { country: "Canada", code: "CA" },
  { country: "Centraal-Afrikaanse Republiek", code: "CF" },
  { country: "Chili", code: "CL" },
  { country: "China", code: "CN" },
  { country: "Christmaseiland", code: "CX" },
  { country: "Cocoseilanden", code: "CC" },
  { country: "Colombia", code: "CO" },
  { country: "Comoren", code: "KM" },
  { country: "Congo-Brazzaville", code: "CG" },
  { country: "Congo-Kinshasa", code: "CD" },
  { country: "Cookeilanden", code: "CK" },
  { country: "Costa Rica", code: "CR" },
  { country: "Cuba", code: "CU" },
  { country: "Curaçao", code: "CW" },
  { country: "Cyprus", code: "CY" },
  { country: "Denemarken", code: "DK" },
  { country: "Djibouti", code: "DJ" },
  { country: "Dominica", code: "DM" },
  { country: "Dominicaanse Republiek", code: "DO" },
  { country: "Duitsland", code: "DE" },
  { country: "Ecuador", code: "EC" },
  { country: "Egypte", code: "EG" },
  { country: "El Salvador", code: "SV" },
  { country: "Equatoriaal-Guinea", code: "GQ" },
  { country: "Eritrea", code: "ER" },
  { country: "Estland", code: "EE" },
  { country: "Ethiopië", code: "ET" },
  { country: "Faeröer", code: "FO" },
  { country: "Falklandeilanden", code: "FK" },
  { country: "Fiji", code: "FJ" },
  { country: "Filipijnen", code: "PH" },
  { country: "Finland", code: "FI" },
  { country: "Frankrijk", code: "FR" },
  { country: "Franse Zuidelijke en Antarctische Gebieden", code: "TF" },
  { country: "Frans-Guyana", code: "GF" },
  { country: "Frans-Polynesië", code: "PF" },
  { country: "Gabon", code: "GA" },
  { country: "Gambia", code: "GM" },
  { country: "Georgië", code: "GE" },
  { country: "Ghana", code: "GH" },
  { country: "Gibraltar", code: "GI" },
  { country: "Grenada", code: "GD" },
  { country: "Griekenland", code: "GR" },
  { country: "Groenland", code: "GL" },
  { country: "Guadeloupe", code: "GP" },
  { country: "Guam", code: "GU" },
  { country: "Guatemala", code: "GT" },
  { country: "Guernsey", code: "GG" },
  { country: "Guinee", code: "GN" },
  { country: "Guinee-Bissau", code: "GW" },
  { country: "Guyana", code: "GY" },
  { country: "Haïti", code: "HT" },
  { country: "Heard en McDonaldeilanden", code: "HM" },
  { country: "Honduras", code: "HN" },
  { country: "Hongarije", code: "HU" },
  { country: "Hongkong", code: "HK" },
  { country: "Ierland", code: "IE" },
  { country: "IJsland", code: "IS" },
  { country: "India", code: "IN" },
  { country: "Indonesië", code: "ID" },
  { country: "Irak", code: "IQ" },
  { country: "Iran", code: "IR" },
  { country: "Israël", code: "IL" },
  { country: "Italië", code: "IT" },
  { country: "Ivoorkust", code: "CI" },
  { country: "Jamaica", code: "JM" },
  { country: "Japan", code: "JP" },
  { country: "Jemen", code: "YE" },
  { country: "Jersey", code: "JE" },
  { country: "Jordanië", code: "JO" },
  { country: "Kaaimaneilanden", code: "KY" },
  { country: "Kaapverdië", code: "CV" },
  { country: "Kameroen", code: "CM" },
  { country: "Kazachstan", code: "KZ" },
  { country: "Kenia", code: "KE" },
  { country: "Kirgizië", code: "KG" },
  { country: "Kiribati", code: "KI" },
  { country: "Kleine afgelegen eilanden van de Verenigde Staten", code: "UM" },
  { country: "Koeweit", code: "KW" },
  { country: "Kroatië", code: "HR" },
  { country: "Laos", code: "LA" },
  { country: "Lesotho", code: "LS" },
  { country: "Letland", code: "LV" },
  { country: "Libanon", code: "LB" },
  { country: "Liberia", code: "LR" },
  { country: "Libië", code: "LY" },
  { country: "Liechtenstein", code: "LI" },
  { country: "Litouwen", code: "LT" },
  { country: "Luxemburg", code: "LU" },
  { country: "Macau", code: "MO" },
  { country: "Macedonië", code: "MK" },
  { country: "Madagaskar", code: "MG" },
  { country: "Malawi", code: "MW" },
  { country: "Maldiven", code: "MV" },
  { country: "Maleisië", code: "MY" },
  { country: "Mali", code: "ML" },
  { country: "Malta", code: "MT" },
  { country: "Man", code: "IM" },
  { country: "Marokko", code: "MA" },
  { country: "Marshalleilanden", code: "MH" },
  { country: "Martinique", code: "MQ" },
  { country: "Mauritanië", code: "MR" },
  { country: "Mauritius", code: "MU" },
  { country: "Mayotte", code: "YT" },
  { country: "Mexico", code: "MX" },
  { country: "Micronesia", code: "FM" },
  { country: "Moldavië", code: "MD" },
  { country: "Monaco", code: "MC" },
  { country: "Mongolië", code: "MN" },
  { country: "Montenegro", code: "ME" },
  { country: "Montserrat", code: "MS" },
  { country: "Mozambique", code: "MZ" },
  { country: "Myanmar", code: "MM" },
  { country: "Namibië", code: "NA" },
  { country: "Nauru", code: "NR" },
  { country: "Nederland", code: "NL" },
  { country: "Nepal", code: "NP" },
  { country: "Nicaragua", code: "NI" },
  { country: "Nieuw-Caledonië", code: "NC" },
  { country: "Nieuw-Zeeland", code: "NZ" },
  { country: "Niger", code: "NE" },
  { country: "Nigeria", code: "NG" },
  { country: "Niue", code: "NU" },
  { country: "Noordelijke Marianen", code: "MP" },
  { country: "Noord-Korea", code: "KP" },
  { country: "Noorwegen", code: "NO" },
  { country: "Norfolk", code: "NF" },
  { country: "Oeganda", code: "UG" },
  { country: "Oekraïne", code: "UA" },
  { country: "Oezbekistan", code: "UZ" },
  { country: "Oman", code: "OM" },
  { country: "Oostenrijk", code: "AT" },
  { country: "Oost-Timor", code: "TL" },
  { country: "Pakistan", code: "PK" },
  { country: "Palau", code: "PW" },
  { country: "Palestina", code: "PS" },
  { country: "Panama", code: "PA" },
  { country: "Papoea-Nieuw-Guinea", code: "PG" },
  { country: "Paraguay", code: "PY" },
  { country: "Peru", code: "PE" },
  { country: "Pitcairneilanden", code: "PN" },
  { country: "Polen", code: "PL" },
  { country: "Portugal", code: "PT" },
  { country: "Puerto Rico", code: "PR" },
  { country: "Qatar", code: "QA" },
  { country: "Réunion", code: "RE" },
  { country: "Roemenië", code: "RO" },
  { country: "Rusland", code: "RU" },
  { country: "Rwanda", code: "RW" },
  { country: "Saint-Barthélemy", code: "BL" },
  { country: "Saint Kitts en Nevis", code: "KN" },
  { country: "Saint Lucia", code: "LC" },
  { country: "Saint-Pierre en Miquelon", code: "PM" },
  { country: "Saint Vincent en de Grenadines", code: "VC" },
  { country: "Salomonseilanden", code: "SB" },
  { country: "Samoa", code: "WS" },
  { country: "San Marino", code: "SM" },
  { country: "Saoedi-Arabië", code: "SA" },
  { country: "Sao Tomé en Principe", code: "ST" },
  { country: "Senegal", code: "SN" },
  { country: "Servië", code: "RS" },
  { country: "Seychellen", code: "SC" },
  { country: "Sierra Leone", code: "SL" },
  { country: "Singapore", code: "SG" },
  { country: "Sint-Helena, Ascension en Tristan da Cunha", code: "SH" },
  { country: "Sint-Maarten", code: "MF" },
  { country: "Sint Maarten", code: "SX" },
  { country: "Slovenië", code: "SI" },
  { country: "Slowakije", code: "SK" },
  { country: "Soedan", code: "SD" },
  { country: "Somalië", code: "SO" },
  { country: "Spanje", code: "ES" },
  { country: "Spitsbergen en Jan Mayen", code: "SJ" },
  { country: "Sri Lanka", code: "LK" },
  { country: "Suriname", code: "SR" },
  { country: "Swaziland", code: "SZ" },
  { country: "Syrië", code: "SY" },
  { country: "Tadzjikistan", code: "TJ" },
  { country: "Taiwan", code: "TW" },
  { country: "Tanzania", code: "TZ" },
  { country: "Thailand", code: "TH" },
  { country: "Togo", code: "TG" },
  { country: "Tokelau", code: "TK" },
  { country: "Tonga", code: "TO" },
  { country: "Trinidad en Tobago", code: "TT" },
  { country: "Tsjaad", code: "TD" },
  { country: "Tsjechië", code: "CZ" },
  { country: "Tunesië", code: "TN" },
  { country: "Turkije", code: "TR" },
  { country: "Turkmenistan", code: "TM" },
  { country: "Turks- en Caicoseilanden", code: "TC" },
  { country: "Tuvalu", code: "TV" },
  { country: "Uruguay", code: "UY" },
  { country: "Vanuatu", code: "VU" },
  { country: "Vaticaanstad", code: "VA" },
  { country: "Venezuela", code: "VE" },
  { country: "Verenigde Arabische Emiraten", code: "AE" },
  { country: "Verenigde Staten", code: "US" },
  { country: "Verenigd Koninkrijk", code: "GB" },
  { country: "Vietnam", code: "VN" },
  { country: "Wallis en Futuna", code: "WF" },
  { country: "Westelijke Sahara", code: "EH" },
  { country: "Wit-Rusland", code: "BY" },
  { country: "Zambia", code: "ZM" },
  { country: "Zimbabwe", code: "ZW" },
  { country: "Zuid-Afrika", code: "ZA" },
  { country: "Zuid-Georgia en de Zuidelijke Sandwicheilanden", code: "GS" },
  { country: "Zuid-Korea", code: "KR" },
  { country: "Zuid-Soedan", code: "SS" },
  { country: "Zweden", code: "SE" },
  { country: "Zwitserland", code: "CH" },
];

const getCountryNameFromCode = (code: string): string | undefined => {
  const country = ISO3166countryCodes.find((e) => e.code === code);
  return country && country.country;
};

export { ISO3166countryCodes as countryCodes, getCountryNameFromCode };
