// @flow

import React from "react";
import ExpandablePage from "../../components/expandablePageSection";

const Opzeggen = () => (
  <ExpandablePage title="Opzeggen lidmaatschap">
    <p>
      U kunt uw lidmaatschap opzeggen per:
      <ul>
        <li>1 januari voor jaarbetalers</li>
        <li>1 januari en 1 juli voor halfjaar betalers</li>
        <li>1 januari, 1 april, 1 juli en 1 oktober voor kwartaalbetalers</li>
      </ul>
      <p>
        Opzeggingen dienen uiterlijk 1 maand vóór bovengenoemde data te worden
        doorgegeven.
      </p>
      <p>
        Bij beëindiging van het lidmaatschap worden ingelegde premies niet
        terugbetaald.
      </p>
    </p>
  </ExpandablePage>
);

export default Opzeggen;
