import {
  AppBar,
  Box,
  CircularProgress,
  Toolbar,
  Typography,
} from "@mui/material";
import { MenuButton } from "components/header";
import SideBar from "components/header/sideBar";

export const Header = (props: {
  searchField: any;
  loading: boolean;
  extraButton: any;
}) => {
  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <MenuButton />
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Account administratie
          </Typography>
          {props.searchField}
          <Box sx={{ flexGrow: 1 }} />
          {props.extraButton}
          {props.loading && (
            <CircularProgress color="inherit" size={"1.5rem"} />
          )}
        </Toolbar>
      </AppBar>
      <SideBar />
    </>
  );
};
