import React, { Component } from "react";
import { gql } from "@apollo/client";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import Divider from "material-ui/Divider";
import MaleIcon from "../../../images/Male.png";
import FemaleIcon from "../../../images/Female.png";
import CakeIcon from "material-ui/svg-icons/social/cake";
import { colorsBCV } from "../../../styling";
import { parseDate, parseAmount, formatPrice } from "../../../utils";
import DialogComponents, { DialogComponentFragment } from "./policyDialogs";
import { filter } from "graphql-anywhere";
import { DocumentNode } from "graphql";
import { Frequency } from "generated/graphql";
import { formatFrequency2 } from "utils/formatter";
import { Dialog } from "@mui/material";

const styles: { [key: string]: React.CSSProperties } = {
  paperElement: {
    padding: "20px",
    fontWeight: 300,
    fontSize: "16px",
  },
  outerContainer: {
    display: "flex",
    flexDirection: "row",
  },
  iconContainer: {
    width: "10%",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  dataContainer: {
    width: "85%",
  },
  firstLinesHeight: {
    height: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  heSheStyle: {
    width: "10px",
    height: "25px",
  },
  policyInfoRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "5px",
  },
  policyInfoLeftSide: {
    color: "lightGrey",
    flex: 1,
  },
  policyInfoRightSide: {
    color: "grey",
    flex: 1,
  },
};

const convertFrequencyToText = (freq: Frequency | null) =>
  freq
    ? formatFrequency2(freq).toLocaleLowerCase()
    : "(betalingsfrequentie is niet bekend)";

const convertBirthdateToText = (birthdate: string | null) =>
  birthdate ? parseDate(birthdate) : "De geboortedatum is niet bekend";

export type PolicyState =
  | "PENDING"
  | "ACTIVE"
  | "CANCELLED"
  | "DEAD"
  | "ACCEPTED"
  | "REJECTED"
  | "DISCHARGE";
type Props = {
  cardInfo: {
    id: string;
    package: {
      id: string;
      name: string;
      insuredAmount: number;
    };
    premium: number;
    lumpsum: number;
    frequency: Frequency;
    insuredPerson: {
      id: string;
      memberId: string;
      fullName: string;
      birthdate: string | null;
      sex: "MALE" | "FEMALE";
    };
    state: PolicyState;
    signupDate: string;
    signOffDate: string | null;
  };
};

type State = {
  show: boolean;
};

class PolicyCard extends Component<Props, State> {
  static fragments: {
    cardInfo: DocumentNode;
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  _handleClose() {
    this.setState({ show: false });
  }

  _changeState(state: PolicyState) {
    switch (state) {
      case "PENDING":
        return "In afwachting";
      case "ACCEPTED":
        return "Geaccepteerd";
      case "REJECTED":
        return "Afgewezen";
      case "ACTIVE":
        return "Actief";
      case "DEAD":
        return "Overleden";
      case "CANCELLED":
        return "Opgezegd";
      case "DISCHARGE":
        return "Geroyeerd";
      default:
        throw new Error(
          "Expected state PENDING, ACTIVE, CANCELLED, ACCEPTED, REJECTED, DISCHARGE or DEAD"
        );
    }
  }

  render() {
    const polState = this.props.cardInfo.state;
    const disabled =
      polState === "CANCELLED" ||
      polState === "DEAD" ||
      polState === "DISCHARGE" ||
      polState === "REJECTED";
    const policyInfo = this.props.cardInfo;

    const name = policyInfo.insuredPerson.fullName;

    return (
      <Paper style={styles.paperElement} zDepth={1}>
        <div style={styles.outerContainer}>
          <div style={styles.iconContainer}>
            <div style={styles.firstLinesHeight}>
              {policyInfo.insuredPerson.sex === "MALE" ? (
                <img
                  src={MaleIcon}
                  style={styles.heSheStyle}
                  title="Geslacht: man"
                  alt=""
                />
              ) : (
                <img
                  src={FemaleIcon}
                  style={styles.heSheStyle}
                  title="Geslacht: vrouw"
                  alt=""
                />
              )}
            </div>
            <div title="Geboortedatum" style={styles.firstLinesHeight}>
              <CakeIcon />
            </div>
          </div>
          <div style={styles.dataContainer}>
            <div style={styles.firstLinesHeight}>
              <div
                style={{
                  fontSize: "24px",
                  color: colorsBCV.secondary,
                  flex: 1,
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              >
                {name}
              </div>
              <div style={{ color: colorsBCV.secondary, flex: 1 }}>
                <RaisedButton
                  label="Wijzig"
                  data-test={`update_member_${policyInfo.id}_button`}
                  backgroundColor={colorsBCV.secondary}
                  labelStyle={{ color: "white" }}
                  onClick={() => this.setState({ show: true })}
                  disabled={disabled}
                />
              </div>
            </div>
            <div style={styles.firstLinesHeight}>
              <div style={{ textAlign: "left", width: "100%" }}>
                {convertBirthdateToText(policyInfo.insuredPerson.birthdate)}
              </div>
            </div>
            <div style={{ textAlign: "left", paddingTop: "5px", width: "90%" }}>
              <div style={{ color: colorsBCV.secondary, paddingBottom: "5px" }}>
                Lidmaatschap
              </div>
              <Divider />
              <div style={styles.policyInfoRow}>
                <div style={styles.policyInfoLeftSide}>Lidnummer</div>
                <div style={styles.policyInfoRightSide}>
                  {policyInfo.insuredPerson.memberId}
                </div>
              </div>
              <div style={styles.policyInfoRow}>
                <div style={styles.policyInfoLeftSide}>Pakket</div>
                <div style={styles.policyInfoRightSide}>
                  {policyInfo.package.name}
                </div>
              </div>
              <div style={styles.policyInfoRow}>
                <div style={styles.policyInfoLeftSide}>Contributie</div>
                <div style={styles.policyInfoRightSide}>
                  {formatPrice(parseAmount(policyInfo.premium))}{" "}
                  {convertFrequencyToText(policyInfo.frequency)}
                </div>
              </div>
              <div style={styles.policyInfoRow}>
                <div style={styles.policyInfoLeftSide}>Lid sinds</div>
                <div style={styles.policyInfoRightSide}>
                  {parseDate(policyInfo.signupDate)}
                </div>
              </div>
              <div style={styles.policyInfoRow}>
                <div style={styles.policyInfoLeftSide}>Status lidmaatschap</div>
                <div style={styles.policyInfoRightSide}>
                  {this._changeState(policyInfo.state)}
                </div>
              </div>
              {policyInfo.signOffDate && (
                <div style={styles.policyInfoRow}>
                  <div style={styles.policyInfoLeftSide}>Afgemeld op</div>
                  <div style={styles.policyInfoRightSide}>
                    {parseDate(policyInfo.signOffDate)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Dialog
          open={this.state.show}
          onClose={() => this._handleClose()}
          fullWidth
          maxWidth="sm"
          scroll="paper"
        >
          <DialogComponents
            data={filter(PolicyCard.fragments.cardInfo, policyInfo)}
            handleClose={() => this._handleClose()}
          />
        </Dialog>
      </Paper>
    );
  }
}

PolicyCard.fragments = {
  cardInfo: gql`
    fragment PolicyCard on Policy {
      id
      signOffDate
      premium
      lumpsum
      frequency
      package {
        id
        name
        insuredAmount
      }
      insuredPerson {
        id
        memberId
        fullName
        birthdate
        sex
        association {
          id
          name
        }
      }
      signupDate
      state
      ...DialogComponents
    }
    ${DialogComponentFragment}
  `,
};

export default PolicyCard;
