// @flow

import React from "react";
import ExpandablePage from "../../components/expandablePageSection";

const WerkgebiedPietersrade = () => (
  <ExpandablePage title="Werkgebied van de vereniging">
    <p>
      De verzorging van de gehele begrafenis of crematie volgens het
      dienstenpakket wordt in opdracht van onze vereniging uitgevoerd door
      Monuta. De uitkering is in natura en daardoor waardevast.
    </p>
    <p>Bij verhuizen blijven uw lidmaatschap en rechten behouden.</p>
  </ExpandablePage>
);

export default WerkgebiedPietersrade;
