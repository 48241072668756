// @flow

import { MutationHookOptions } from "@apollo/client";
import { gql } from "generated";
import {
  UpdatePackagePolicyMutation,
  UpdatePackagePolicyMutationVariables,
} from "generated/graphql";
import { useMutationForApp } from "utils/withApollo";

export const updatePackagePolicyMutation = gql(`
  mutation updatePackagePolicy($input: UpdatePackagePolicyInput!) {
    updatePackagePolicy(input: $input) {
      updatedPolicy {
        id
      }
      oldPolicy {
        id
        __typename
        premium
        lumpsum
        frequency
        package {
          id
          name
        }
      }
    }
  }
`);

export function useUpdatePackagePolicyMutation(
  options?: MutationHookOptions<
    UpdatePackagePolicyMutation,
    UpdatePackagePolicyMutationVariables
  >
) {
  return useMutationForApp<
    UpdatePackagePolicyMutation,
    UpdatePackagePolicyMutationVariables
  >(updatePackagePolicyMutation, options);
}
