// @flow

import { gql } from "generated";
import { clientNew } from "./apollo2";

/**
 * API for resetting an account's password
 * @param username The username for which the forgot password call is performed.
 * @param resetPasswordKey The reset password key that was provided in the reset password email.
 * @param newPassword The new password.
 * @returns a boolean indicating whether or not a reset password is available for the username.
 * A value of true indicates that the password has successfully been reset.
 */
const resetPassword = async (
  username: string,
  resetPasswordKey: string,
  newPassword: string
): Promise<boolean> => {
  const response = await clientNew.mutate({
    mutation: gql(`
      mutation reset($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
          active
        }
      }
    `),
    variables: {
      input: {
        username,
        resetPasswordKey,
        newPassword,
      },
    },
  });

  return response.data.resetPassword.active;
};

export default resetPassword;
