// @flow

import React, { Component } from "react";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import { colors } from "../../../../../../styling";
import { serializeDate, isDate } from "../../../../../../utils";
import { errorHandler, ErrorHandler } from "../../../../../../containers";
import { ACCEPTANCE_QUERY } from "../../../../../registrationPage";
import CircularProgress from "material-ui/CircularProgress";
import {
  ActivatePolicyMutation,
  ActivatePolicyMutationVariables,
} from "generated/graphql";
import { graphql } from "@apollo/client/react/hoc";
import { gql } from "generated";
import { FetchResult } from "@apollo/client";
import { DialogContainer2 } from "components/dialog/container";
import { NavButtonContainer } from "components/dialog";

const { green } = colors;

type PropsParent = {
  handleClose: () => void;
  policyId: string;
};

type PropsGraphQL = {
  activatePolicy: (
    signUpDate: string
  ) => Promise<FetchResult<ActivatePolicyMutation>>;
};
type Props = PropsParent & PropsGraphQL & ErrorHandler;

type State = {
  dateInput: string;
  dateError: string | null;
  loading: boolean;
};

export class AcceptedDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { dateInput: "", dateError: null, loading: false };
  }

  _activatePolicy = async () => {
    const { activatePolicy, error } = this.props;
    const { dateInput } = this.state;

    if (!dateInput)
      this.setState({ dateError: "Voer een activeringsdatum in." });
    else if (!isDate(dateInput))
      this.setState({
        dateError: "Voer een geldige activeringsdatum in (DD-MM-JJJJ).",
      });
    else {
      try {
        this.setState({
          loading: true,
        });
        const { data } = await activatePolicy(dateInput);
        const { validDate, policy } = data.activatePolicy;
        if (validDate === false) {
          this.setState({
            dateError:
              "De activeringsdatum kan niet voor de huidige datum zijn.",
            loading: false,
          });
        } else {
          if (!policy) {
            // If the data is valid but there is no policy then an error has occured.
            error.display();
            this.setState({
              loading: false,
            });
          } else {
            // Reload to reset the cache.
            window.location.reload();
          }
        }

        //As soon as the mutation has been handled the cache is updated
        //which causes the acceptDialog to be wiped by react and replaced
        //with the change status button. In order to prevent an error message
        //stating that the state should not be updated after an unmount
        //we do not set the loading state to false.
      } catch (e) {
        error.display();
        this.setState({
          loading: false,
        });
      }
    }
  };

  render() {
    const { handleClose } = this.props;
    const { dateInput, dateError, loading } = this.state;

    let content;
    if (loading) {
      content = <CircularProgress color={colors.green} />;
    } else {
      content = (
        <div>
          <span>
            Activeer het lid door de activeringsdatum van het lidmaatschap in te
            voeren. Dit is de datum waarop het lidmaatschap aanvangt.
          </span>
          <TextField
            id="dateInput"
            floatingLabelText={"Activeringsdatum"}
            hintText="DD-MM-JJJJ"
            floatingLabelFocusStyle={{ color: green }}
            underlineFocusStyle={{ borderColor: green }}
            fullWidth
            errorText={dateError}
            value={dateInput}
            onChange={(event: any) =>
              this.setState({ dateInput: event.target.value, dateError: null })
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._activatePolicy();
              }
            }}
          />
        </div>
      );
    }
    return (
      <DialogContainer2>
        <h2>Activeer het lid</h2>
        {content}
        <NavButtonContainer>
          <RaisedButton
            // @ts-ignore
            id="back"
            label="Annuleren"
            primary={false}
            disabled={loading}
            onClick={handleClose}
          />
          <RaisedButton
            // @ts-ignore
            id="confirm"
            label="Bevestig"
            disabled={loading}
            backgroundColor={green}
            labelStyle={{ color: "white" }}
            onClick={this._activatePolicy}
          />
        </NavButtonContainer>
      </DialogContainer2>
    );
  }
}

export const ACTIVATE_POLICY_MUTATION = gql(`
  mutation activatePolicy($input: ActivatePolicyInput!) {
    activatePolicy(input: $input) {
      policy {
        id
        __typename
        signupDate
      }
      validDate
    }
  }
`);

export const withMutationAccept = graphql<
  PropsParent,
  ActivatePolicyMutation,
  ActivatePolicyMutationVariables,
  PropsGraphQL
>(ACTIVATE_POLICY_MUTATION, {
  props: ({ ownProps, mutate }) => ({
    activatePolicy: (signUpDate: string) =>
      mutate({
        refetchQueries: [{ query: ACCEPTANCE_QUERY }],
        variables: {
          input: {
            policyId: ownProps.policyId,
            signUpDate: serializeDate(signUpDate),
          },
        },
      }),
  }),
});

const Wrapper = withMutationAccept(errorHandler(AcceptedDialog));

export default Wrapper;
