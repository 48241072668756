// @flow

import React, { Component } from "react";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import { isEmail } from "../../utils";
import { forgotPassword } from "../../api";
import config from "../../config";
import { errorHandler, ErrorHandler } from "../../containers";
import { NavButtonContainer } from "components/dialog";
import { DialogContainer2 } from "components/dialog/container";

type Props = {
  onPressClose: () => void;
} & ErrorHandler;

type State = {
  error: string | null;
  email: string;
};

export class ForgotPassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
      email: "",
    };
  }

  _forgotPassword = async () => {
    const { email } = this.state;
    if (!isEmail(email)) {
      return this.setState({
        error: "U moet een valide email invoeren",
      });
    }

    try {
      const exists = await forgotPassword(
        email,
        `${config.redirectUrl}/herstel-wachtwoord`
      );
      if (exists === false) {
        return this.setState({
          error: "De ingevoerde email bestaat niet.",
        });
      } else {
        this.props.onPressClose();
      }
    } catch (e) {
      this.props.error.display();
    }
  };

  render = () => (
    <DialogContainer2>
      <div style={{ textAlign: "center" }}>
        Bent u uw wachtwoord kwijt?
        <br />
        Vul de email van uw account in om het wachtwoord te resetten.
      </div>
      <TextField
        // @ts-ignore
        id="email"
        floatingLabelText="Email"
        errorText={this.state.error}
        floatingLabelFixed={false}
        fullWidth
        onChange={(event: any) => this.setState({ email: event.target.value })}
        onKeyDown={(event: any) => {
          if (event.key === "Enter") {
            this._forgotPassword();
          }
        }}
      />
      <NavButtonContainer>
        <RaisedButton
          // @ts-ignore
          id="close"
          label="SLUIT"
          primary={true}
          onClick={this.props.onPressClose}
        />
        <RaisedButton
          // @ts-ignore
          id="send"
          label="VERSTUUR"
          primary={true}
          onClick={this._forgotPassword}
        />
      </NavButtonContainer>
    </DialogContainer2>
  );
}

export default errorHandler(ForgotPassword);
