//@flow

/**
 * Created by Joshua Scheidt on 26-01-2017.
 */

import React, { Component } from "react";
import TextField from "material-ui/TextField";
import { colors } from "./../../../styling/index";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import type { SignupPaymentError, PaymentState } from "../../../actions";

const styles: { [key: string]: React.CSSProperties } = {
  instructionTitle: {
    color: colors.green,
    textTransform: "uppercase",
    fontSize: "18px",
    fontWeight: "bold",
  },
  instructionTitleGrey: {
    color: "grey",
    textTransform: "uppercase",
    fontSize: "18px",
    fontWeight: "bold",
  },
  valueSelecter: {
    color: "grey",
    textAlign: "left",
    alignItems: "left",
    justifyContent: "left",
  },
  inputFieldDiv: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
};

type ErrorMessages = {
  errorIban: string;
  errorAccountName: string;
};

type Props = {
  nextPressed(input: PaymentState): void;
  backPressed(): void;
  setIban(iban: string): void;
  setAccountName(accountName: string): void;
  iban: string;
  accountName: string;
  errors: Array<SignupPaymentError>;
};

const getErrorMessages = (errors: Array<SignupPaymentError>): ErrorMessages => {
  let errorObject = {
    errorIban: "",
    errorAccountName: "",
  };

  if (errors.indexOf("EMPTY_IBAN_ERROR") !== -1)
    errorObject.errorIban = "IBAN is niet ingevuld.";
  else if (errors.indexOf("INVALID_IBAN_ERROR") !== -1)
    errorObject.errorIban = "De opgegeven IBAN is ongeldig.";

  if (errors.indexOf("EMPTY_ACCOUNT_NAME_ERROR") !== -1)
    errorObject.errorAccountName = "Rekeninghouder naam niet ingevuld.";

  return errorObject;
};

class YourInfoStep extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this._handleNext = this._handleNext.bind(this);
  }

  _handleNext() {
    const { iban, accountName } = this.props;
    const input = { iban, accountName };
    this.props.nextPressed(input);
  }

  render() {
    const { iban, accountName, errors } = this.props;
    const { errorIban, errorAccountName } = getErrorMessages(errors);
    return (
      <div style={{ maxWidth: "350px" }}>
        <p>Vul de betalingsgegevens in waarmee de contributie betaald wordt.</p>
        <div style={styles.inputFieldDiv}>
          <TextField
            data-test="iban_textfield"
            value={iban}
            hintText="NL99BANK0123456789"
            floatingLabelText="IBAN nummer"
            floatingLabelStyle={styles.valueSelecter}
            inputStyle={styles.valueSelecter}
            onChange={(event: any) =>
              this.props.setIban(event.target.value.toUpperCase())
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._handleNext();
              }
            }}
            errorText={errorIban}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />
          <TextField
            data-test="iban_accountholder_textfield"
            defaultValue={accountName}
            floatingLabelText="Naam van de rekeninghouder"
            floatingLabelStyle={styles.valueSelecter}
            inputStyle={styles.valueSelecter}
            onChange={(event: any) =>
              this.props.setAccountName(event.target.value)
            }
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                this._handleNext();
              }
            }}
            errorText={errorAccountName}
            errorStyle={{ color: "red" }}
            fullWidth={true}
          />

          <div
            style={{ display: "flex", flexDirection: "row", paddingTop: 20 }}
          >
            <div style={{ flex: 1, textAlign: "center" }}>
              <FlatButton
                label="Vorige"
                disableTouchRipple={true}
                disableFocusRipple={true}
                onClick={this.props.backPressed}
              />
            </div>
            <div style={{ flex: 1, textAlign: "center" }}>
              <RaisedButton
                label="Volgende"
                disableTouchRipple={true}
                disableFocusRipple={true}
                primary={true}
                onClick={this._handleNext}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default YourInfoStep;
