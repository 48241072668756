// @flow
/**
 * Created by Joshua Scheidt on 5-10-2016. Ellissa Verseput on 18/11/2016
 */

import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";
import React, { PropsWithChildren } from "react";
import { colorArray } from "../../../styling";

const renderActiveShape = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: any) => {
  const RADIAN = Math.PI / 180;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (innerRadius + (outerRadius - innerRadius) / 2) * cos;
  const sy = cy + (innerRadius + (outerRadius - innerRadius) / 2) * sin;

  return (
    <g>
      <text
        x={sx}
        y={sy}
        textAnchor="middle"
        fontSize={12}
        fill="white"
        dominantBaseline="central"
      >
        {" "}
        {`${(percent * 100).toFixed(0)}%`}{" "}
      </text>
    </g>
  );
};

type Props = PropsWithChildren<{
  data: Array<{
    name: string;
    value: number;
  }>;
}>;

const PieChartComponent = (props: Props) => {
  let { data } = props;

  //Remove 0-values
  for (let i = 0; i < data.length; i++) {
    if (data[i].value === 0) {
      data.splice(i, 1);
      i--;
    }
  }

  return (
    // @ts-ignore
    <ResponsiveContainer height={190}>
      {/* @ts-ignore */}
      <PieChart>
        {/* @ts-ignore */}
        <Pie
          data={data}
          labelLine={false}
          innerRadius="50%"
          label={renderActiveShape}
        >
          {data.map((entry, index) => (
            <Cell key={index} fill={colorArray[index % colorArray.length]} />
          ))}
        </Pie>
        <Legend
          verticalAlign="bottom"
          height={20}
          align="center"
          iconSize={15}
          wrapperStyle={{ fontSize: 10 }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
