// @flow
import moment from "moment";
import MobileDetect from "mobile-detect";
import { serializeDate } from "./formatter";

var md = new MobileDetect(window.navigator.userAgent);

// Source: http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
const EMAIL_REGEXP =
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*)?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*)?$/;
const HOUSENUM_REGEXP = /^\d+$/;
const HOUSESUFFIX_REGEXP = /^\w*$/;
const PHONE_REGEXP = /^([0-9]|\(|\)|\*|\+|#|-)*$/;
const INITIALS_REGEXP = /[^a-z]/i;
const AMOUNT_REGEXP = /^([0-9]{1,3}(\d{3}|(?:\.[0-9]{3}))*(?:,[0-9]{1,2})?)$/;
const PASSWORD_REGEXP = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/;
const MIGRATIONCODE_REGEXP = /^[0-9a-z]+$/;

/**
 * Validates an email.
 * @param email the email to validate
 * @returns Boolean indicating whether the email is valid or not
 */
export const isEmail = (email: string): boolean => {
  return EMAIL_REGEXP.test(email);
};

export const isEmpty = (value?: string | null): boolean => {
  return !value || value.length === 0;
};

/**
 * Validates that a date-string is a valid date in format DD-MM-YYYY
 * @returns Boolean indicating whether the date-string is valid
 */
export const isDate = (date: string): boolean => {
  return moment(date, "DD-MM-YYYY", true).isValid();
};

/** Validates that a date-string in format DD-MM-YYY is a valid birthdate */
export const isValidBirthdate = (dateString: string) => {
  if (!isDate(dateString)) {
    return false;
  }
  const date = new Date(serializeDate(dateString));
  const today = new Date();
  return date <= today;
};

/**
 * Validates a phone number. This function checks whether the
 * input string contains a combination of the following characters
 * *+#()1234567890.
 * @param phone the phone number to validate
 * @returns Boolean indicating whether the phone number is valid or not
 */
export const validatePhone = (phone: string) => {
  return PHONE_REGEXP.test(phone);
};

/**
 * Prepare an IBAN for mod 97 computation by moving the first 4 chars to the end and transforming the letters to
 * numbers (A = 10, B = 11, ..., Z = 35), as specified in ISO13616.
 *
 * @param {string} iban the IBAN
 * @returns {string} the prepared IBAN
 */
const iso13616Prepare = (iban: string): string => {
  iban = iban.substr(4) + iban.substr(0, 4);

  const A = "A".charCodeAt(0),
    Z = "Z".charCodeAt(0);

  return iban
    .split("")
    .map(function (n) {
      const code = n.charCodeAt(0);
      if (code >= A && code <= Z) {
        // A = 10, B = 11, ... Z = 35
        return code - A + 10;
      } else {
        return n;
      }
    })
    .join("");
};

/**
 * Calculates the MOD 97 10 of the passed IBAN as specified in ISO7064.
 *
 * @param iban
 * @returns {number}
 */
const iso7064Mod97_10 = (iban: string): number => {
  let remainder = iban,
    block;

  while (remainder.length > 2) {
    block = remainder.slice(0, 9);
    remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length);
  }

  return parseInt(remainder, 10) % 97;
};

/**
 * Validates an iban is valid. Note, this function
 * removes the all the whitespace before validation.
 * @param ibanInput the iban to validate
 * @returns Boolean indicating whether the iban is valid or not
 */
export const validateIban = (ibanInput: string): boolean => {
  let iban = ibanInput.replace(/\s+/g, "");
  const IBAN_IDENTIFICATION_REGEXP = /^([0-9]|[A-Z])*$/;
  const IBAN_COUNTRY_REGEXP = /^([A-Z])*$/;
  const IBAN_CHECKSUM_REGEXP = /^([0-9])*$/;

  const validCountry = IBAN_COUNTRY_REGEXP.test(iban.substring(0, 2));
  const validCheckSum = IBAN_CHECKSUM_REGEXP.test(iban.substring(2, 4));
  const validIdentification =
    IBAN_IDENTIFICATION_REGEXP.test(iban.substring(4)) &&
    iban.substring(4).length <= 30;

  if (!validCountry || !validCheckSum || !validIdentification) return false;

  iban = iso13616Prepare(iban);
  const remainder = iso7064Mod97_10(iban);
  return remainder === 1;
};

/**
 * Validates a person's initials. It checks that the initials are formatted
 * without periods e.g. JB or jb etc.
 * @param initials the initial to validate
 * @returns Boolean indicating whether the initials are valid or not
 */
export const validateInitials = (initials: string) => {
  return !INITIALS_REGEXP.test(initials);
};

/**
 * Validates a postal code is represented in the format 6211 ER (note the whitespace and
 * uppercase letters).
 * @param postal the postal code to validate
 * @returns Boolean indicating whether the postal code is valid or not
 */
export const isPostalCode = (postal: string): boolean => {
  const postalTrim = postal.replace(/\s/g, "");
  const POSTAL_REGEXP = /^([1-9][0-9]{3}[A-Z]{2})$/;
  return POSTAL_REGEXP.test(postalTrim);
};

/**
 * Validates a house number
 * @param houseNum the house number to validate
 * @returns Boolean indicating whether the house number is valid or not
 */
export const isHouseNum = (houseNum: string): boolean => {
  return HOUSENUM_REGEXP.test(houseNum);
};

/**
 * Validates a house number suffix. This method checks
 * that the input suffic does not contains illegal characters such
 * as ".", "," or "@" etc.
 * @param houseSuf the house number suffix to validate
 * @returns Boolean indicating whether the house number suffix is valid or not
 */
export const isHouseSuffix = (houseSuf: string): boolean => {
  return HOUSESUFFIX_REGEXP.test(houseSuf);
};

/**
 * Validates that an input string is a money value that is comma separated.
 * @param amount the amount to validate
 * @returns Boolean indicating whether the amount is indeed an amount or not
 */
export const isAmount = (amount: string): boolean => {
  return AMOUNT_REGEXP.test(amount);
};

export const isNumber = (value: string): boolean => !isNaN(Number(value));
/**
 * Gives an indication on whether it is a mobile device (phone or tablet) or not.
 * Source: "https://hgoebl.github.io/mobile-detect.js/"
 */
export const isMobileOrTable = (): boolean => {
  const isMobile = md.mobile() ? true : false;
  const isTablet = md.tablet() ? true : false;

  if (isMobile || isTablet) return true;
  else return false;
};

/**
 * Validates that an input string is a valid password.
 * @param password the password to be checked
 * @returns Boolean indicating whether the password is valid
 */
export const isValidPassword = (password: string): boolean => {
  return PASSWORD_REGEXP.test(password);
};

/**
 * Validates that the input string is a valid migration code.
 * @param code the migration code to be checked
 * @returns Boolean indicating whether the migration code is valid.
 */
export const isValidMigrationCode = (code: string): boolean => {
  return MIGRATIONCODE_REGEXP.test(code);
};
