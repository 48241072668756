// @flow

import React from "react";
import SvgIcon from "material-ui/SvgIcon";

const FemaleIcon = (props: any) => (
  <SvgIcon {...props}>
    <path d="M14,4 C14,2.89 13.11,2 12,2 C10.89,2 10,2.89 10,4 C10,5.11 10.89,6 12,6 C13.11,6 14,5.11 14,4 Z" />
    <path d="M13.5,22 L13.5,16 L16.5,16 L13.96,8.37 C13.68,7.55 12.92,7 12.06,7 L11.94,7 C11.08,7 10.31,7.55 10.04,8.37 L7.5,16 L10.5,16 L10.5,22 L13.5,22 L13.5,22 Z" />
  </SvgIcon>
);

export default FemaleIcon;
