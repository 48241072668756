// @flow
/**
 * Created by Florentijn Hogerwerf, Jim Bemelen on 24/10/16.
 * Updated by Joshua Scheidt on 15/08/17
 */

import React from "react";

import LineChart from "../charts/lineChart";
import { gql } from "@apollo/client";
import { parseDate } from "../../../utils";

type PolicyCount = {
  date: string;
  count: number;
};

type Props = {
  data: Array<PolicyCount>;
};

const transformData = (data: Array<PolicyCount>): Array<any> => {
  let array = [];
  for (var i = 0; i < data.length; i++) {
    array[i] = {
      name: parseDate(data[i].date),
      value: data[i].count,
    };
  }
  return array;
};

const PoliciesPerDate = (props: Props) => {
  return (
    <LineChart
      valueFormatter={(value: number) => value.toString()}
      xAxisInterval={91}
      data={transformData(props.data)}
    />
  );
};
export const PoliciesPerDateFragment = gql`
  fragment policiesPerDate on PolicyCount {
    date
    count
  }
`;

export default PoliciesPerDate;
