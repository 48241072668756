import React, { Component } from "react";
import { withRouter, WithRouterProps } from "react-router";
import Drawer from "material-ui/Drawer";
import MenuItem from "material-ui/MenuItem";
import Avatar from "material-ui/Avatar";
import Divider from "material-ui/Divider";
import MyProfileIcon from "material-ui/svg-icons/social/person";
import MyPoliciesIcon from "material-ui/svg-icons/action/assignment";
import LogoutIcon from "material-ui/svg-icons/navigation/cancel";
import { colorsBCV } from "../../../styling";
import PersonIcon from "material-ui/svg-icons/social/person";
import Loader from "../../loader";
import { gql } from "generated";
import { graphqlQuery } from "utils/withApollo";
import {
  UserQueryHeaderQuery,
  UserQueryHeaderQueryVariables,
} from "generated/graphql";
import { DataProps } from "@apollo/client/react/hoc";

const MAX_SCREEN_WIDTH_AUTO_CLOSE = 1024;

const styles: { [key: string]: React.CSSProperties } = {
  menuItem: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  userImage: {
    width: 60,
    height: 60,
    borderRadius: "50%",
  },
  profileBox: {
    fontSize: 12,
    color: "black",
    paddingTop: 40,
    paddingBottom: 30,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  drawer: {
    backgroundColor: "white",
  },
  itemBox: {
    fontSize: 12,
    color: colorsBCV.primary,
    paddingTop: 20,
    height: "15%",
    justifyContent: "center",
    alignItems: "center",
    verticalAlign: "middle",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    color: colorsBCV.primary,
    width: "40px",
    height: "40px",
  },
  itemBoxSelected: {
    fontSize: 12,
    color: colorsBCV.secondary,
    paddingTop: 20,
    height: "15%",
    justifyContent: "center",
    alignItems: "center",
    verticalAlign: "middle",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  iconSelected: {
    color: colorsBCV.secondary,
    width: "40px",
    height: "40px",
  },
  divider: {
    opacity: 0.2,
  },
  appBar: {
    backgroundColor: colorsBCV.primary,
  },
};

type PropsParents = {
  open: boolean;
  closeNavigationBar: () => void;
  logout: () => void;
};

type Props = DataProps<UserQueryHeaderQuery, UserQueryHeaderQueryVariables> &
  PropsParents &
  WithRouterProps;

class NavigationBar extends Component<Props> {
  render() {
    let { loading, error, viewer } = this.props.data;

    let { router, closeNavigationBar, logout, open } = this.props;

    let myProfile = (
      <div style={styles.itemBox}>
        <MyProfileIcon style={styles.icon} />
        Mijn profiel
      </div>
    );

    let myPolicies = (
      <div style={styles.itemBox}>
        <MyPoliciesIcon style={styles.icon} />
        Mijn lidmaatschappen
      </div>
    );

    let logoutComponent = (
      <div style={styles.itemBox}>
        <LogoutIcon style={styles.icon} />
        Uitloggen
      </div>
    );

    if (router.isActive("/account")) {
      myProfile = (
        <div style={styles.itemBoxSelected}>
          <MyProfileIcon style={styles.iconSelected} />
          Mijn profiel
        </div>
      );
    }

    if (router.isActive("/account/lidmaatschappen")) {
      myPolicies = (
        <div style={styles.itemBoxSelected}>
          <MyPoliciesIcon style={styles.iconSelected} />
          Mijn lidmaatschappen
        </div>
      );
    }

    const smallScreenDrawerProps =
      // eslint-disable-next-line
      screen.width <= MAX_SCREEN_WIDTH_AUTO_CLOSE
        ? {
            docked: false,
            onRequestChange: closeNavigationBar,
            openPrimary: false,
            open,
          }
        : {
            open: true,
          };

    return (
      <Loader isFetching={loading} error={error} fullScreen={true}>
        {viewer ? (
          <Drawer
            width={150}
            containerStyle={styles.drawer}
            {...smallScreenDrawerProps}
          >
            <div style={styles.profileBox}>
              <Avatar icon={<PersonIcon />} size={75} />
              <p>
                {viewer.firstName}
                <br />
                {viewer.lastName}
              </p>
            </div>

            <Divider style={styles.divider} />

            <MenuItem
              style={styles.menuItem}
              onClick={() => {
                router.push("/account");
                closeNavigationBar();
              }}
            >
              {myProfile}
            </MenuItem>

            <MenuItem
              style={styles.menuItem}
              onClick={() => {
                router.push("/account/lidmaatschappen");
                closeNavigationBar();
              }}
            >
              {myPolicies}
            </MenuItem>

            <MenuItem style={styles.menuItem} onClick={() => logout()}>
              {logoutComponent}
            </MenuItem>
          </Drawer>
        ) : null}
      </Loader>
    );
  }
}

const USER_QUERY = gql(`
  query UserQueryHeader {
    viewer {
      id
      firstName
      lastName
    }
  }
`);

/**
 * Export the decorated class. The decoration adds the router.push prop to the component (used to change the current route).
 */
const ComponentWithRouter = withRouter(NavigationBar);
const withData = graphqlQuery<
  PropsParents,
  UserQueryHeaderQuery,
  UserQueryHeaderQueryVariables
>(USER_QUERY)(ComponentWithRouter);

export default withData;
