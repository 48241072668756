import PageSection from "../../components/pageSection";
import {
  pricePackageANumeric,
  pricePackageBNumeric,
  pricePackageCNumeric,
} from "../../informationPage/bcb/constants";

const PackagesBCB = () => (
  <PageSection title="Pakketten">
    <p>
      Bij de oprichting (1926) van de vereniging is het volgende bepaald: "Het
      doel van de vereniging is om op basis van vrijwilligheid, onderlinge
      solidariteit en nabuurschap en/of geïnspireerd vanuit een christelijke
      visie, onderlinge hulp te verlenen op het gebied van de uitvaart en een
      overledene op de meest waardige wijze, overeenkomstig zijn/haar wensen,
      naar zijn/haar laatste rustplaats te brengen, mede om te voorkomen dat de
      verzorging van een stoffelijk overschot van de mens een voorwerp van
      winstbejag wordt." Op basis hiervan hebben wij met onze uitvaartondernemer
      bepaald wat een sobere begrafenis of crematie kost, zie onderstaand.
    </p>
    <p>Bij BCB bestaan (stand 01-01-2024) drie naturavergoedingen:</p>
    <ul>
      <li>een naturavergoeding pakket A van € {pricePackageANumeric};</li>
      <li>een naturavergoeding pakket B van € {pricePackageBNumeric};</li>
      <li>een naturavergoeding pakket C van € {pricePackageCNumeric}.</li>
    </ul>
    <p>
      Alle drie de pakketten zijn gebaseerd op dezelfde gedachte, namelijk een
      vergoeding die vrij besteed kan worden bij een uitvaartondernemer. Dit
      bedrag wordt niet uitgekeerd, maar kan worden aangewend om via een
      uitvaartondernemer een begrafenis of crematie te laten verzorgen. De
      uitvaartondernemer van uw keuze kan zich bij ons melden met het uittreksel
      van de overlijdensakte, de kopie-nota van de uitvaart en de akte van
      cessie.
    </p>
    <p>
      Nabestaanden hebben de vrijheid om dit bedrag naar eigen inzicht te
      besteden. Mocht een begrafenis of crematie duurder worden dan de
      vergoeding van de vereniging, dan zal dit door de nabestaanden betaald
      dienen te worden.
    </p>
    <p>
      Wij hebben een samenwerkingsverband met{" "}
      <b>
        <a href="https://www.mok-uitvaart.nl">Mok Uitvaartverzorging</a>
      </b>{" "}
      te Heerlen en{" "}
      <b>
        <a href="https://www.paffenuitvaartverzorging.nl">
          Uitvaartverzorging Paffen
        </a>
      </b>{" "}
      te Kerkrade.
    </p>
  </PageSection>
);

export default PackagesBCB;
