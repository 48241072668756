// @flow

import React from "react";

const style: React.CSSProperties = {
  color: "gray",
};

type Props = {
  children?: any;
  style?: React.CSSProperties | null;
};

const keyLabel = (props: Props) => (
  <span style={{ ...props.style, ...style }}>{props.children}</span>
);

export default keyLabel;
