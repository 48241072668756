// @flow

type CloseNavbarAction = { type: "CLOSE_NAVIGATIONBAR" };
type OpenNavbarAction = { type: "OPEN_NAVIGATIONBAR" };

export type Action = CloseNavbarAction | OpenNavbarAction;

/**
 * Closes the side navigation bar.
 */
export const closeNavigationBar = (): CloseNavbarAction => ({
  type: "CLOSE_NAVIGATIONBAR",
});

/**
 * Opens the side navigation bar.
 */
export const openNavigationBar = (): OpenNavbarAction => ({
  type: "OPEN_NAVIGATIONBAR",
});
