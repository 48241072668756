import React from "react";
import Header from "./header";
import { SideBar } from "../../components/header";
import Loader from "../loader";
import PersonalDetails from "./personalDetails";
import ProfileDetails from "./profileDetails";
import { gql } from "generated";
import { useQuery } from "@apollo/client";
import { QueryForUserQuery } from "generated/graphql";

const styles: { [key: string]: React.CSSProperties } = {
  outerContainer: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "100px",
  },
  allInfoHolder: {
    width: "500px",
    textAlign: "left",
    justifyContent: "left",
    alignItems: "left",
    lineHeight: 1.5,
  },
};

const ProfilePage = () => {
  const { loading, error, data } = useQuery<QueryForUserQuery>(USER_QUERY);

  const viewer = data?.viewer;
  return (
    <div style={{ paddingBottom: "20px" }}>
      <Header />
      <SideBar />
      <Loader isFetching={loading} error={error} fullScreen={true}>
        {viewer ? (
          <div style={styles.outerContainer}>
            <div
              style={{
                ...styles.allInfoHolder,
                alignItems: "center",
                paddingLeft: "75px",
              }}
            >
              <ProfileDetails infoProfile={viewer} />
              <PersonalDetails infoPersonal={viewer} />
            </div>
          </div>
        ) : null}
      </Loader>
    </div>
  );
};

export const USER_QUERY = gql(`
  query QueryForUser {
    viewer {
      id
      ...ProfileInfoBestuurder
      ...PersonInfo
    }
  }
`);
export default ProfilePage;
