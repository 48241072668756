//@flow

import config, { AssociationType } from "./config";

const setWebsiteTitle = (title: string) => {
  document.title = title;
};

const setFavIcon = (name: string) => {
  let favicon = document.querySelector('link[rel="icon"]')!!;

  favicon = document.createElement("link");
  // @ts-ignore
  favicon.rel = "icon";
  // @ts-ignore
  favicon.href = name;

  //$FlowFixMe
  document.head.appendChild(favicon);
};

const { association } = config;

switch (association) {
  case AssociationType.BCB:
    setWebsiteTitle("BCB");
    setFavIcon("favicon_bcb.ico");
    break;
  case AssociationType.PIETERSRADE:
    setWebsiteTitle("St. Pietersrade");
    setFavIcon("favicon_pietersrade.ico");
    break;
  case AssociationType.HULP_EN_STEUN:
    setWebsiteTitle("Hulp En Steun");
    setFavIcon("h_en_s_favicon.ico");
    break;
  default:
    throw new Error(`Unknown association type ${association}`);
}
