// @flow
import Cookies from "js-cookie";

const PERMISSIONS_KEY = "permissions";
const TOKEN_KEY = "token";

type AccountPermission =
  | "USER"
  | "ADMINISTRATION"
  | "MEMBER_CHECK"
  | "FINANCE"
  | "PRODUCT_MANAGER";

/**
 * Checks if anybody is logged in
 * @return True if a user is logged in, false if there isn't
 */
export const loggedIn = (): boolean => {
  return !!Cookies.get(TOKEN_KEY) && !!Cookies.get(PERMISSIONS_KEY);
};
/**
 * Retrieve the access token of the current session. If there is
 * no session then null is returned.
 * @returns the access token of the current session or null.
 */
export const getToken = (): string | undefined => {
  return Cookies.get(TOKEN_KEY);
};

/**
 * Retrieve the permissions of the user of the current sessions. If there is no session then null is returned.
 * @returns the array of permissions
 */
export const getPermissions = (): Array<AccountPermission> | null => {
  let permissions = Cookies.get(PERMISSIONS_KEY);
  if (!permissions) return null;
  return JSON.parse(permissions);
};

/**
 * Store the access token to start a session.
 * @param token The token to be stored.
 * @param permission The permissions associated with the token
 */
export const storeToken = (
  token: string,
  permissions: Array<AccountPermission>
): void => {
  Cookies.set(TOKEN_KEY, token);
  Cookies.set(PERMISSIONS_KEY, JSON.stringify(permissions));
};

/**
 * Removes the current token thereby ending the current session.
 */
export const clearToken = (): void => {
  Cookies.remove(TOKEN_KEY);
  Cookies.remove(PERMISSIONS_KEY);
};
