// @flow

import React, { Component } from "react";
import { colors } from "../../styling";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import { errorHandler } from "../../containers";
import type { ErrorHandler } from "../../containers";
import { ToDirectDebit } from "./toDirectDebit";
import { UpdateToInvoice } from "./toInvoice";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export type PropsPaymentInfo = {
  iban: string;
  bic: string;
  accountHolderName: string;
};

type PropsParent = {
  open: boolean;
  insuredPersonIds: string[];
  handleClose(): void;
  paymentInfo?: PropsPaymentInfo | null;
  description?: string;
};

type Props = PropsParent & ErrorHandler;

type State = {
  chosen: "DIRECTDEBIT" | "INVOICE";
};

class PaymentDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      chosen: "DIRECTDEBIT",
    };
  }

  render() {
    let content;
    if (this.state.chosen === "DIRECTDEBIT") {
      content = (
        <ToDirectDebit
          insuredPersonIds={this.props.insuredPersonIds}
          handleClose={this.props.handleClose}
          paymentInfo={this.props.paymentInfo}
        />
      );
    } else if (this.state.chosen === "INVOICE") {
      content = (
        <UpdateToInvoice
          insuredPersonIds={this.props.insuredPersonIds}
          handleClose={this.props.handleClose}
          paymentInfo={this.props.paymentInfo}
        />
      );
    } else content = null;

    return (
      <Dialog onClose={this.props.handleClose} open={this.props.open}>
        <DialogTitle>Update betaalgegevens</DialogTitle>
        <DialogContent>
          {this.props.description && (
            <>
              <DialogContentText>{this.props.description}</DialogContentText>
              <br />
            </>
          )}
          <RadioButtonGroup
            // @ts-ignore
            id="group"
            name="choosePaymentMethod"
            defaultSelected={this.state.chosen}
            onChange={(event: any) => {
              this.setState({ chosen: event.target.value });
            }}
            style={{ width: "210px" }}
          >
            <RadioButton
              id="directDebit"
              value="DIRECTDEBIT"
              label="Automatisch incasso"
              style={{ paddingBottom: "10px" }}
              iconStyle={{ fill: colors.green }}
            />
            <RadioButton
              id="invoice"
              data-test="invoice_selector"
              value="INVOICE"
              label="Factuur"
              style={{ paddingBottom: "10px" }}
              iconStyle={{ fill: colors.green }}
            />
          </RadioButtonGroup>
          {content}
        </DialogContent>
      </Dialog>
    );
  }
}

export const UpdatePaymentDialog = errorHandler(PaymentDialog);
