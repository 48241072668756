// @flow

import type { ActionsPayment, SignupPaymentError } from "../../actions";

export type State = {
  data: {
    iban: string;
    accountName: string;
    signatureDate: string;
  };
  errors: Array<SignupPaymentError>;
};

const initialState: State = {
  data: {
    iban: "",
    accountName: "",
    signatureDate: "",
  },
  errors: [],
};

const StepperPayment = (
  state: State = initialState,
  action: ActionsPayment
): State => {
  switch (action.type) {
    case "ADD_SIGNUP_IBAN": {
      return {
        ...state,
        data: {
          ...state.data,
          iban: action.iban,
        },
        errors: state.errors.filter(
          (element) =>
            ["EMPTY_IBAN_ERROR", "INVALID_IBAN_ERROR"].indexOf(element) === -1
        ),
      };
    }
    case "ADD_SIGNUP_ACCOUNTNAME": {
      return {
        ...state,
        data: {
          ...state.data,
          accountName: action.accountName,
        },
        errors: state.errors.filter(
          (element) => ["EMPTY_ACCOUNT_NAME_ERROR"].indexOf(element) === -1
        ),
      };
    }
    case "UPDATE_ERRORS_SIGNUP_PAYMENT": {
      return {
        ...state,
        errors: action.errors,
      };
    }
    default:
      return state;
  }
};

export default StepperPayment;
