import { Component } from "react";
import { errorHandler } from "containers";
import { ErrorHandler } from "containers";
import { parseJSDate, serializeDate, serializeIban } from "utils";
import { VerticalSeparator } from "components/layout";
import {
  required,
  mustBeDate,
  mustBeIban,
  composeValidators,
} from "components/form";
import TextField from "components/textfield";
import { PropsPaymentInfo } from "./updatePaymentDialog";
import { gql } from "generated";
import { graphql } from "@apollo/client/react/hoc";
import {
  UpdateToDirectDebitMutation,
  UpdateToDirectDebitMutationVariables,
} from "generated/graphql";
import { DialogActions } from "@mui/material";
import {
  DialogCancelButton,
  DialogConfirmButton,
} from "components/button/buttons";
const ibanValidator = composeValidators(required, mustBeIban);
const signatureDateValidator = composeValidators(required, mustBeDate);
const nameValidator = required;

type PropsParent = {
  insuredPersonIds: string[];
  handleClose(): void;
  paymentInfo: PropsPaymentInfo | null;
};

type PropsGraphQL = {
  updatePayment: (input: {
    iban: string;
    accountHolder: string;
    signatureDate: string;
    bic: string | null;
  }) => Promise<any>;
};

type Props = PropsParent & PropsGraphQL & ErrorHandler;

type State = {
  newIBAN: string;
  newName: string;
  ibanError: string | null;
  nameError: string | null;
  signatureDate: string;
  signatureDateError: string | null;
  confirm: boolean;
  bic: string;
  loading: boolean;
};

class DirectDebit extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const payInfo = props.paymentInfo;

    this.state = {
      newIBAN: payInfo ? payInfo.iban : "",
      newName: payInfo ? payInfo.accountHolderName : "",
      signatureDate: parseJSDate(new Date()),
      bic: payInfo ? payInfo.bic : "",
      ibanError: null,
      nameError: null,
      signatureDateError: null,
      confirm: false,
      loading: false,
    };
  }

  async _checkInput() {
    let { newIBAN, newName, signatureDate } = this.state;

    const ibanError = ibanValidator(newIBAN);
    const nameError = nameValidator(newName);
    const signatureDateError = signatureDateValidator(signatureDate);
    this.setState({
      ibanError,
      nameError,
      signatureDateError,
    });

    if (ibanError != null || nameError != null || signatureDateError != null)
      return;

    this.setState({ confirm: true });
  }

  _performMutation = async () => {
    let { newIBAN, newName, signatureDate, bic } = this.state;
    let { handleClose, updatePayment, error } = this.props;

    this.setState({
      loading: true,
    });
    try {
      await updatePayment({
        iban: serializeIban(newIBAN),
        accountHolder: newName,
        signatureDate: serializeDate(signatureDate),
        bic: bic ? (bic.length === 0 ? null : bic) : null,
      });
      this.setState({ loading: false });
      handleClose();
    } catch (err) {
      console.log(err);
      error.display();
      this.setState({ loading: false });
    }
  };

  render() {
    const onPressEnter = (event: any) => {
      if (event.key === "Enter") {
        this._checkInput();
      }
    };

    let { handleClose } = this.props;
    let content;
    if (!this.state.confirm) {
      content = (
        <div>
          <TextField
            data-test="iban_textfield"
            // @ts-ignore
            floatingLabelText={"IBAN"}
            value={this.state.newIBAN}
            name="iban"
            fullWidth={true}
            errorText={this.state.ibanError}
            onChange={(event: any) => {
              this.setState({
                newIBAN: event.target.value.toUpperCase(),
                confirm: false,
              });
            }}
            onKeyDown={onPressEnter}
          />
          <TextField
            data-test="bic_textfield"
            // @ts-ignore
            floatingLabelText={"BIC code"}
            hintText="Voer optioneel een nieuwe bic code in"
            value={this.state.bic}
            name="bic"
            fullWidth={true}
            onChange={(event: any) => {
              this.setState({
                bic: event.target.value,
              });
            }}
            onKeyDown={onPressEnter}
          />
          <TextField
            data-test="accountholder_textfield"
            // @ts-ignore
            floatingLabelText={"Rekeninghouder"}
            value={this.state.newName}
            name="rekeninghouder"
            fullWidth={true}
            errorText={this.state.nameError}
            onChange={(event: any) => {
              this.setState({ newName: event.target.value, confirm: false });
            }}
            onKeyDown={onPressEnter}
          />
          <TextField
            data-test="signature_date_textfield"
            // @ts-ignore
            floatingLabelText={"Ondertekeningsdatum"}
            hintText="DD-MM-JJJJ"
            value={this.state.signatureDate}
            name="signatureDate"
            fullWidth={true}
            errorText={this.state.signatureDateError}
            onChange={(event: any) => {
              this.setState({
                signatureDate: event.target.value,
                confirm: false,
              });
            }}
            onKeyDown={onPressEnter}
          />
        </div>
      );
    } else {
      content = (
        <p style={{ textAlign: "center" }}>
          Weet u zeker dat u de betalingsgegevens wilt wijzigen?
        </p>
      );
    }

    return (
      <div>
        {content}
        <VerticalSeparator distance={2} />
        <DialogActions>
          <DialogCancelButton
            onClick={() => {
              if (!this.state.confirm) {
                handleClose();
              } else {
                this.setState({ confirm: false });
              }
            }}
          />
          <DialogConfirmButton
            onClick={() => {
              if (!this.state.confirm) {
                this._checkInput();
              } else {
                this._performMutation();
              }
            }}
          >
            Bevestig
          </DialogConfirmButton>
        </DialogActions>
      </div>
    );
  }
}

export const updatePaymentMutation = gql(`
  mutation updateToDirectDebit($input: UpdateToDirectDebitInput!) {
    updateToDirectDebit(input: $input) {
      payments {
        id
        iban
        paymentMethod
        bic
        signatureDate
        mandateId
        accountHolder
      }
    }
  }
`);

type Input = {
  iban: string;
  accountHolder: string;
  signatureDate: string;
  bic: string | null;
};

const withMutation = graphql<
  PropsParent,
  UpdateToDirectDebitMutation,
  UpdateToDirectDebitMutationVariables,
  PropsGraphQL
>(updatePaymentMutation, {
  props: ({ mutate, ownProps }) => ({
    updatePayment: (input: Input) =>
      mutate({
        variables: {
          input: {
            insuredPersonIds: ownProps.insuredPersonIds,
            ...input,
          },
        },
      }),
  }),
  options: {
    onCompleted: () => window.location.reload(),
  },
});

export const ToDirectDebit = withMutation(errorHandler(DirectDebit));
