// @flow

import CircularProgress from "material-ui/CircularProgress";

import config from "../../../config";
import { gql } from "generated";
import { useQuery } from "@apollo/client";
import {
  StatutesDataQuery,
  StatutesDataQueryVariables,
} from "generated/graphql";

const { associationId } = config;

type Node = {
  statutesUrl: string;
  sepaUrl: string;
};

const QUERY = gql(`
query StatutesData($associationId: ID!) {
    node(id: $associationId) {
        id
        ...on Association {
            statutesUrl
            sepaUrl
        }
    }
}
`);

type Props = {
  render: (node: Node) => any;
  errorMessage: string;
};

const Component = (props: Props) => {
  const { loading, error, data } = useQuery<
    StatutesDataQuery,
    StatutesDataQueryVariables
  >(QUERY, { variables: { associationId } });

  const node = data?.node;

  if (loading) {
    return <CircularProgress />;
  } else if (error || !node) {
    return (
      <p style={{ color: "grey" }}>
        {props.errorMessage}
        <br />
        <br />
        Neem contact op met ons als dit zich blijft voordoen.
      </p>
    );
  } else {
    if (node.__typename !== "Association") throw new Error();
    return props.render(node);
  }
};

export default Component;
