import { gql } from "generated/gql";
import { WithRouterProps } from "react-router";
import { useQueryForApp } from "utils/withApollo";

export const InsuredPeopleGroupQuery = gql(`
query insuredPersonGroup($insuredPersonId: ID!) {
  viewer {
    association {
      id
      membershipCardTemplateKey
    }
  }
    insuredPeopleGroup(id: $insuredPersonId) {
      insuredPeople {
        id
    }
      ...InsuredPeopleGroupingList
      ...IpGrouping
    }
  }
  `);

export const useInsuredPeopleQuery = (router: WithRouterProps) =>
  useQueryForApp(InsuredPeopleGroupQuery, {
    variables: {
      insuredPersonId: router.params.id,
    },
    fetchPolicy: "network-only",
  });
